import React from 'react';
import './AssetsDetailCondensedTable.scss';

export default function AssetsDetailsMeasureTable({
  data: { columnNames, dataTable },
}) {
  return (
    <div className='assets-detail-condensed-table'>
      <table className='assets-table'>
        <thead>
          <tr className='table-row table-header'>
            {columnNames.map((columName) => {
              return (
                <th className='table-cell' key={columName}>
                  {columName}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {dataTable.map((columnValue, k1) => {
            return (
              <tr className='table-row' key={`${columnValue}${k1}`}>
                {columnValue.map((column, k2) => (
                  <td className='table-cell' key={`${column}${k2}`}>
                    {column}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
