import React from 'react';

import Input from 'lib/components/input/Input';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Button from 'lib/components/button/Button';
import useNewPasswordForm from '../../useNewPasswordForm';

import { ReactComponent as LockIcon } from 'assets/images/lock.svg';

function NewPasswordForm(props) {
  const {
    state,
    onChangePassword,
    onChangeConfirmPassword,
    onSubmit,
  } = useNewPasswordForm(props);

  return (
    <div className='new-password-form'>
      <form onSubmit={onSubmit} noValidate={true} autoComplete='off'>
        <Input
          type='password'
          name='password'
          value={state.password.value}
          errors={state.password.errors}
          onChange={onChangePassword}
          placeholder='New Password'
          renderLeftInsert={() => <LockIcon fill='#6320ee' />}
        />
        <Input
          type='password'
          name='confirmPassword'
          value={state.confirmPassword.value}
          errors={state.confirmPassword.errors}
          onChange={onChangeConfirmPassword}
          placeholder='Repeat Password'
          renderLeftInsert={() => <LockIcon fill='#6320ee' />}
        />

        <ErrorBag errors={state.formErrors} />

        <Button type='submit' disabled={state.loading}>
          Create password
        </Button>
      </form>
    </div>
  );
}

export default NewPasswordForm;
