import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const PAGE_ACTIONS = {
  APP_LOADS_ASSET_DETAIL: 'APP_LOADS_ASSET_DETAIL',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_STATE = {
  asset: null,
};

const REDUCER_CONFIG = {
  [PAGE_ACTIONS.APP_LOADS_ASSET_DETAIL]: (state, action) => {
    return {
      ...state,
      asset: action.payload,
    };
  },
  [PAGE_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  REDUCER_CONFIG,
  INITIAL_STATE,
);
