import classNames from 'classnames';
import PropTypes from 'prop-types';
import { renderIcon } from '../timelineHelpers';
import './TimelineItem.scss';

const TimelineItem = ({ title, children, className, ...props }) => {
  return (
    <li
      className={classNames('timeline-item', className)}
      data-testid='TimelineItem.container'
    >
      <span className='item-icon'>{renderIcon(props)}</span>
      {title && <h5 className='item-title'>{title}</h5>}
      {children}
    </li>
  );
};

TimelineItem.defaultProps = {
  completed: false,
  failed: false,
};

TimelineItem.propTypes = {
  completed: PropTypes.bool,
  failed: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  title: PropTypes.string,
  children: PropTypes.node,
};

export default TimelineItem;
