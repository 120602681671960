import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import Textarea from 'lib/components/textarea/Textarea';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import AddButton from 'lib/components/add-button/AddButton';
import EditButton from 'lib/components/edit-button/EditButton';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import useCompaniesForm from 'modules/companies/useCompaniesForm';

import { ReactComponent as EmailIcon } from 'assets/images/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/phone-in-talk.svg';

import './CompaniesForm.scss';

const CompaniesForm = (props) => {
  const { state, ...vm } = useCompaniesForm(props);
  const isEditCompany = !!state.id;
  const SubmitButton = isEditCompany ? EditButton : AddButton;
  const submitText = isEditCompany ? 'Save changes' : 'Create company';

  return (
    <div data-testid='CompaniesForm.container'>
      <form
        noValidate
        className='companies-form'
        data-testid='CompaniesForm.form'
      >
        <div className='form-row'>
          <Input
            name='name'
            type='text'
            value={state.name.value}
            errors={state.name.errors}
            onChange={vm.userTypesName}
            charsLeft={state.name.charsLeft}
            label='Name'
            data-testid='CompaniesForm.name'
          />
        </div>
        <div className='form-row'>
          <CollapsibleRadio
            label='Status'
            options={vm.statusOptions}
            value={state.status.value}
            onChange={vm.userSetsStatus}
            errors={state.status.errors}
            data-testid='CompaniesForm.status'
          />
        </div>
        <div className='form-row'>
          <Textarea
            name='about'
            type='text'
            value={state.about.value}
            errors={state.about.errors}
            onChange={vm.userTypesAbout}
            charsLeft={state.about.charsLeft}
            label='About the company'
            data-testid='CompaniesForm.about'
          />
        </div>
        <div className='form-row'>
          <Input
            name='address'
            type='text'
            value={state.address.value}
            errors={state.address.errors}
            onChange={vm.userTypesAddress}
            label='Address'
            data-testid='CompaniesForm.address'
          />
        </div>
        <div className='form-row'>
          <Input
            name='city'
            type='text'
            value={state.city.value}
            errors={state.city.errors}
            onChange={vm.userTypesCity}
            label='City'
            data-testid='CompaniesForm.city'
          />
        </div>
        <div className='form-row zip-code'>
          <Input
            name='zip-code'
            type='text'
            value={state.zipCode.value}
            errors={state.zipCode.errors}
            onChange={vm.userTypesZipCode}
            label='ZIP / Postal code'
            data-testid='CompaniesForm.zipCode'
          />
        </div>
        <div className='form-row'>
          <Input
            name='state'
            type='text'
            value={state.state.value}
            errors={state.state.errors}
            onChange={vm.userTypesState}
            label='State'
            data-testid='CompaniesForm.state'
          />
        </div>
        <div className='form-row'>
          <Select
            name='country'
            options={vm.countryOptions}
            onChange={vm.userSetsCountry}
            isSearchable
            placeholder='Select'
            label='Country'
            value={state.country.value}
            errors={state.country.errors}
            data-testid='CompaniesForm.country'
          />
        </div>
        <h4>Contact info</h4>
        {state.emails.map((email, index) => (
          <div className='form-row contact-field' key={index}>
            <Input
              name={`company-email-${index}`}
              type='text'
              renderLeftInsert={() => <EmailIcon />}
              value={email?.value}
              errors={email?.errors}
              onChange={(e) => vm.userTypesEmail(e, index)}
              label='Company email'
              data-testid={`CompaniesForm.companyEmail${index}`}
            />
            {index > 0 && (
              <DeleteButton
                data-testid={`CompaniesForm.deleteCompanyEmail${index}`}
                className='delete-contact-field'
                onClick={() => vm.userRemovesEmail(index)}
              />
            )}
          </div>
        ))}
        <Button
          type='button'
          className='medium-button add-contact-field'
          onClick={vm.userAddsEmail}
          data-testid='CompaniesForm.addCompanyEmail'
        >
          Add another email
        </Button>
        {state.phones.map((phone, index) => (
          <div className='form-row contact-field' key={index}>
            <Input
              name={`company-phone-${index}`}
              type='text'
              renderLeftInsert={() => <PhoneIcon />}
              value={phone?.value}
              errors={phone?.errors}
              onChange={(e) => vm.userTypesPhone(e, index)}
              label='Company phone'
              data-testid={`CompaniesForm.companyPhone${index}`}
            />
            {index > 0 && (
              <DeleteButton
                data-testid={`CompaniesForm.deleteCompanyPhone${index}`}
                className='delete-contact-field'
                onClick={() => vm.userRemovesPhone(index)}
              />
            )}
          </div>
        ))}
        <Button
          type='button'
          className='medium-button add-contact-field'
          onClick={vm.userAddsPhone}
          data-testid='CompaniesForm.addCompanyPhone'
        >
          Add another phone
        </Button>
        <div className='form-row'>
          <Textarea
            name='custom-config'
            type='text'
            value={state.customConfig.value}
            errors={state.customConfig.errors}
            onChange={vm.userTypesCustomConfig}
            label='Custom Configuration JSON'
            data-testid='CompaniesForm.customConfig'
          />
        </div>
        {!isEditCompany && (
          <>
            <h4>Company administrator</h4>
            <div className='form-row'>
              <Input
                name='admin-name'
                type='text'
                value={state.adminName.value}
                errors={state.adminName.errors}
                onChange={vm.userTypesAdminName}
                label='User name'
                data-testid='CompaniesForm.adminName'
              />
            </div>
            <div className='form-row'>
              <Input
                name='admin-email'
                type='text'
                renderLeftInsert={() => <EmailIcon />}
                value={state.adminEmail.value}
                errors={state.adminEmail.errors}
                onChange={vm.userTypesAdminEmail}
                label='User email'
                data-testid='CompaniesForm.adminEmail'
              />
              <p className='form-info'>
                If an existing e-mail is used, the user will be assigned as the
                company administrator.
              </p>

              <p className='form-info'>
                A new e-mail will send instructions to create a new
                administrator account.
              </p>
            </div>
          </>
        )}

        <div className='form-row submit-container'>
          <ErrorBag errors={state.errors} />
          <SubmitButton
            type='submit'
            disabled={state.loading}
            onClick={vm.userSubmitsForm}
            data-testid='CompaniesForm.submit'
          >
            {submitText}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default CompaniesForm;
