const INSPECTION_TYPE_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

const INSPECTION_TYPE_STATUS_LABEL = {
  [INSPECTION_TYPE_STATUS.ACTIVE]: 'Active',
  [INSPECTION_TYPE_STATUS.DISABLED]: 'Disabled',
};

export const getStatusLabel = (status) =>
  INSPECTION_TYPE_STATUS_LABEL[status] || '';

export default INSPECTION_TYPE_STATUS;
