import { useState, useEffect } from 'react';
import request from 'lib/request';

let cache;
let isBusy = false;
let onHoldRequests = [];

function setBusy(value, err) {
  isBusy = value;
  if (isBusy) {
    return;
  }

  if (cache) {
    onHoldRequests.forEach((req) => req.resolve(cache));
  } else {
    onHoldRequests.forEach((req) => req.reject(err));
  }

  onHoldRequests = [];
}

function getOptions() {
  return new Promise((resolve, reject) => {
    if (cache) {
      resolve(cache);
    } else if (isBusy) {
      onHoldRequests.push({ resolve, reject });
    } else {
      setBusy(true);
      request
        .get('/inspection-plan-options')
        .then(({ questionOptions }) => {
          cache = Object.keys(questionOptions).reduce((acc, key) => {
            acc[key] = questionOptions[key].map(({ label, id }) => ({
              value: id,
              label,
            }));
            return acc;
          }, {});
          cache.dynamicAction = [
            {
              label: 'None',
              value: false,
            },
            ...cache.dynamicAction,
          ];

          cache.dependencyAction = [
            {
              label: 'None',
              value: false,
            },
            ...cache.dependencyAction,
          ];
          resolve(cache);
          setBusy(false);
        })
        .catch((err) => {
          reject(err);
          setBusy(false, err);
        });
    }
  });
}

export default function useQuestionOptions() {
  const [questionOptions, setQuestionOptions] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    getOptions()
      .then((data) => {
        setQuestionOptions(data);
      })
      .catch((error) => {
        setQuestionOptions({});
        setError(error);
      });
  }, []);

  return { questionOptions, error };
}
