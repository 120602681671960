import SourcesForm from '../sources-form/SourcesForm';
import './SourcesAddPage.scss';

const SourcesAddPage = (props) => {
  return (
    <div className='sources-add-page container-m'>
      <div className='row'>
        <h2>New source</h2>
      </div>
      <div className='row'>
        <SourcesForm {...props} />
      </div>
    </div>
  );
};

export default SourcesAddPage;
