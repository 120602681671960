import classNames from 'classnames';
import { RESULT_STATUS } from 'modules/inspection-plans/inspectionPlansBulkResultsReducer';
import STATUS_ICON from './statusIconConfig';

import { Link } from 'react-router-dom';

const PlanResult = ({ title, link, children }) => (
  <div className='result-container'>
    <h5 className='title'>
      <Link to={link}>{title}</Link>
    </h5>
    <ul>{children}</ul>
  </div>
);

const STATUS_LABEL = {
  [RESULT_STATUS.SUCCESS]: 'created',
  [RESULT_STATUS.FAILED]: 'failed to be created',
  [RESULT_STATUS.ERROR]: 'failed to be created',
  [RESULT_STATUS.SKIPPED]: 'not created due to no matching tags',
  [RESULT_STATUS.UNKNOWN]: 'has an unknown status',
  [RESULT_STATUS.NO_TAGS]: 'not created due to no matching tags',
  [RESULT_STATUS.AUTO_PLAN]: 'not created due to pre-existing plan',
  [RESULT_STATUS.USER_PLAN]: 'not created due to pre-existing user-edited plan',
  [RESULT_STATUS.WRONG_TYPE]: 'not created due to wrong inspection type target',
  [RESULT_STATUS.NEEDS_REVIEW]:
    'not created due to existing plan needing review',
};

const PlanResultItem = ({ name, status, link }) => {
  const element = (
    <>
      {STATUS_ICON[status]} {name} {STATUS_LABEL[status]}
    </>
  );
  return (
    <li className={classNames('plan-result-item', status)}>
      {link ? <Link to={link}>{element}</Link> : element}
    </li>
  );
};
PlanResult.Item = PlanResultItem;

export default PlanResult;
