import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportQuestionGroupAttributesPage.scss';

const attributeLabels = {
  name: 'Question Group Name',
  company_attribute: 'Company Attribute',
  attributeName: 'Attribute Name',
  attributeType: 'Attribute Type',
  attributeValue: 'Attribute Value',
  matchAnyValues: 'Match any values',
};

const attributeErrorsValidations = {
  company_attribute: {
    'company_attribute.notFound': () => 'Company Attribute not found',
    'matchAnyValues.unique': () =>
      'Attribute cannot match both by any value and specific values at once',
  },
  questionGroup: {
    'questionGroup.notFound': () => 'Question group not found',
  },
};

export default function ImportQuestionGroupAttribtutesPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='import-question-group-attributes-page'>
      <h4>Import question group attributes</h4>
      <p className='form-info'>
        Before importing question group attributes, please make sure you
        structure your data like in the example CSV to minimize importing
        errors.
      </p>
      <div className='document'>
        <a
          href='documents/sample-question-group-attributes.csv'
          target='_blank'
          download
        >
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport
        type='question-group-attributes'
        onUploadInfo={list.uploadInfo}
      />
      <ImportResult
        list={list}
        errorValidations={attributeErrorsValidations}
        columnLabels={attributeLabels}
      />
    </div>
  );
}
