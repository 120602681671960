import { useState } from 'react';
import './AnswerDetail.scss';
import { AnswerDetailValuesFunctions } from './AnswerDetailValuesFunctions';
import useDebugViews from 'useDebugViews';
import Button from 'lib/components/button/Button';

export default function AnswerDetailValuesDebug({ question, inspection }) {
  const isDebugViewAnswer = useDebugViews(
    useDebugViews.VIEWS.INSP_RSLT_Q_QUANT_ANSWER,
  ).isDebugView;

  const [isDebugOpen, setIsDebugOpen] = useState(false);

  if (!isDebugViewAnswer) {
    return null;
  }

  if (question?.answerType !== 'quantitative_input') {
    return null;
  }
  const rawAnswersArray = AnswerDetailValuesFunctions.getAnswerValuesArray(
    question,
    inspection,
  );
  const answerValuesArray = AnswerDetailValuesFunctions.convertRawAnswers(
    rawAnswersArray,
    question,
  );

  return (
    <div className='qm-answer-detail-values-debug-wrap'>
      <Button
        onClick={() => setIsDebugOpen(!isDebugOpen)}
        className='medium-button'
      >
        Debug Info
      </Button>

      {isDebugOpen && (
        <div className='qm-answer-detail-values-debug'>
          {(() => {
            let lastAnswerIndex = 0;
            let dimIdx = -1;

            return answerValuesArray?.map((av, avIdx) => {
              const tv = AnswerDetailValuesFunctions.getTargetValueData(
                question,
                inspection,
                av,
              );
              const diff = AnswerDetailValuesFunctions.getAnswerDeviation(
                av,
                tv,
              );

              if (av.answerIndex === lastAnswerIndex) {
                dimIdx++;
              } else {
                dimIdx = 0;
              }

              lastAnswerIndex = av.answerIndex;

              const output = (
                <div className='avItem' key={avIdx}>
                  <div>
                    <span>Msrmt/Dim:</span>
                    <br />
                    {av.answerIndex + 1}/{dimIdx + 1}
                  </div>
                  <div>
                    <span>Val/Unit:</span>
                    <br />
                    {av.answerValue}/
                    {av.answerUnit ?? `${tv.targetValueUnit}(?)`}
                  </div>
                  <div>
                    <span>EMTR/Dim:</span>
                    <br />
                    {av.answerExpectedMeasureTableResult}/
                    {av.answerExpectedMeasureTableResultDimension}
                  </div>
                  <div>
                    <span>QParams:</span>
                    <br />
                    Upper/{question?.upperToleranceValue}/
                    {question?.upperToleranceRule}/
                    {question?.upperToleranceWeight}
                    <br />
                    Lower/{question?.lowerToleranceValue}/
                    {question?.lowerToleranceRule}/
                    {question?.lowerToleranceWeight}
                  </div>
                  <div>
                    <span>Target/Min/Max/Unit:</span>
                    <br />
                    {tv.targetValue}/{tv.targetValueMin}/{tv.targetValueMax}/
                    {tv.targetValueUnit}
                  </div>
                  <div>
                    <span>Deviation/%:</span>
                    <br />
                    {Number.isFinite(diff.dAbsolute) ? diff.dAbsolute : '-'}/
                    {Number.isFinite(diff.dPercentage)
                      ? `${diff.dPercentage}%`
                      : '-'}
                  </div>
                  <div>
                    <span>DeviationMsg:</span>
                    <br />
                    {diff.diffMsg?.length ? diff.diffMsg : '-'}
                    <br />
                    {diff.errMsg?.length ? diff.errMsg : '-'}
                  </div>
                </div>
              );

              return output;
            });
          })()}
        </div>
      )}
    </div>
  );
}
