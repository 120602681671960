import React from 'react';
import classNames from 'classnames';

import Table from 'lib/components/table/Table';
import Checkbox from 'lib/components/checkbox/Checkbox';
import Button from 'lib/components/button/Button';

import './SelectableTable.scss';

function formatSelectedEntriesText(num) {
  return num === 1 ? '1 entry selected' : `${num} entries selected`;
}

const SelectableTable = ({
  userClicksOnRow = null,
  onSelectRow = null,
  onSelectPage = null,
  onSelectAll = null,
  onClearSelection = null,
  selectedRows = [],
  selectedPages = [],
  isAllSelected = false,
  disabledRows = [],
  className,
  columns = [],
  ...props
}) => {
  const isHeaderChecked = isAllSelected || selectedPages.includes(props.page);
  const dataIds = props.data?.map((i) => i.id);
  const showSelectAll =
    !isAllSelected && selectedRows.length < props.totalEntries;

  const columnsList = [
    {
      title: (
        <Checkbox
          checked={isHeaderChecked}
          onChange={() => onSelectPage(dataIds, props.page)}
          className='outline'
          disabled={isAllSelected}
        />
      ),
      className: 'select-cell',
      headerClassName: 'select-cell',
      render: (record) => {
        const isDisabled = (disabledRows || []).includes(record.id);

        return (
          <div
            className='checkbox-wrapper'
            onClick={(ev) => ev.stopPropagation()}
          >
            <Checkbox
              disabled={isDisabled || isAllSelected}
              checked={
                isAllSelected || (selectedRows || []).includes(record.id)
              }
              onChange={() => onSelectRow && onSelectRow(record.id)}
              className='outline'
            />
          </div>
        );
      },
    },
    ...columns,
  ];

  return (
    <div className='selectable-table-container'>
      {!!selectedRows.length && (
        <div className='selection-info-container'>
          <div className='info'>
            {isAllSelected || props.totalEntries === selectedRows.length
              ? `All ${formatSelectedEntriesText(props.totalEntries)}`
              : formatSelectedEntriesText(selectedRows.length)}
          </div>
          <div className='actions'>
            {showSelectAll && (
              <Button
                onClick={() => onSelectAll && onSelectAll(props.totalEntries)}
                className='link-button'
              >
                Select all {props.totalEntries} entries
              </Button>
            )}
            <Button
              onClick={() => onClearSelection && onClearSelection()}
              className='link-button'
            >
              Clear selection
            </Button>
          </div>
        </div>
      )}
      <Table
        columns={columnsList}
        onRowClick={userClicksOnRow}
        className={classNames('selectable-table', className, {
          'selected-within': selectedRows.length,
        })}
        {...props}
      />
    </div>
  );
};

export default SelectableTable;
