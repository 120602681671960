import classNames from 'classnames';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import ResourceSelectButton from 'lib/components/resource-select-button/ResourceSelectButton';
import Select from 'lib/components/select/Select';
import Switch from 'lib/components/switch/Switch';
import Textarea from 'lib/components/textarea/Textarea';

import './InspectionPlansForm.scss';

import { reduceLocation } from 'lib/dataTransform';
import { MAX_FIELD_CHARS } from 'modules/inspection-plans/inspectionPlansFormReducer';
import './InspectionPlansForm.scss';
import { INSPECTION_PLAN_STATUS } from 'config/inspectionPlanStatus';
import Button from 'lib/components/button/Button';

const InspectionPlansFormNewPlan = ({ vm }) => {
  if (!vm?.planForm) {
    return null;
  }

  const { doOpenResourcesModal } = vm.modals;

  const linkedResource = vm?.linkedAsset?.id
    ? vm.linkedAsset
    : vm.planForm.linkedResource;

  const onChangePlanFormValue = (k, v) => {
    vm.setPlanForm({
      ...vm.planForm,
      [k]: { ...vm.planForm[k], value: v, errors: [] },
    });
  };

  const onChangePlanAqlLevel = (v) => {
    const newAqlValues = { aqlLevel: { ...vm.planForm.aqlLevel, value: v } };
    if (v === null) {
      newAqlValues.aqlMajor = { ...vm.planForm.aqlMajor, value: null };
      newAqlValues.aqlMinor = { ...vm.planForm.aqlMinor, value: null };
      newAqlValues.aqlFunctional = {
        ...vm.planForm.aqlFunctional,
        value: null,
      };
      newAqlValues.criticalDefect = {
        ...vm.planForm.criticalDefect,
        value: null,
      };
    }

    vm.setPlanForm({ ...vm.planForm, ...newAqlValues });
  };

  // TODO: This should be a feature of the input component itself
  const calcInputCharsLeft = (k, v) => {
    let rslt = null;
    if (MAX_FIELD_CHARS[k]) {
      rslt = v?.length ? MAX_FIELD_CHARS[k] - v.length : MAX_FIELD_CHARS[k];
    }
    return rslt;
  };

  return (
    <>
      <div className='form-row name-input-container'>
        <Input
          name='name'
          type='text'
          charsLeft={calcInputCharsLeft('name', vm.planForm.name.value)}
          label='Name'
          value={vm.planForm.name.value}
          errors={vm.planForm.name.errors}
          autoComplete='off'
          onChange={(e) => onChangePlanFormValue('name', e.target.value)}
          data-testid='InspectionPlansForm.name'
          maxLength={MAX_FIELD_CHARS['name']}
        />
      </div>
      <div className='form-row description-textarea-container'>
        <Textarea
          name='description'
          charsLeft={calcInputCharsLeft(
            'description',
            vm.planForm.description.value,
          )}
          label='Description'
          value={vm.planForm.description.value}
          errors={vm.planForm.description.errors}
          autoComplete='off'
          onChange={(e) => onChangePlanFormValue('description', e.target.value)}
          data-testid='InspectionPlansForm.description'
          maxLength={MAX_FIELD_CHARS['description']}
        />
      </div>
      <div className='form-row status-container'>
        <CollapsibleRadio
          name='status'
          label='Status'
          value={vm.planForm.status.value}
          options={vm.planStatusOptions}
          errors={vm.planForm.status.errors}
          onChange={(v) => onChangePlanFormValue('status', v)}
          data-testid='InspectionPlansForm.status'
        />
      </div>
      <div className='form-row validity-container half'>
        <p className='form-label'>Validity</p>
        <div className='flex'>
          <Input
            name='validity'
            type='text'
            value={vm.planForm.validity.value}
            autoComplete='off'
            onChange={(e) =>
              onChangePlanFormValue(
                'validity',
                e.target.value?.length
                  ? Math.max(Number(e.target.value.replace(/[^0-9]/g, '')), 1)
                  : '',
              )
            }
            data-testid='InspectionPlansForm.validityValue'
          />
          <Select
            name='validity-range'
            onChange={(v) => onChangePlanFormValue('validityRange', v)}
            value={vm.planForm.validityRange.value}
            options={vm.planOptions.validityRange}
            data-testid='InspectionPlansForm.validityRange'
          />
        </div>
        <ErrorBag
          data-testid='InspectionPlansForm.validityErrors'
          errors={[
            ...vm.planForm.validity.errors,
            ...vm.planForm.validityRange.errors,
          ]}
        />
      </div>
      <div className='form-row aql-container'>
        <div className='flex'>
          <div>
            <Select
              label='AQL Level'
              name='aql-level'
              className='aql-level'
              onChange={(v) => onChangePlanAqlLevel(v)}
              value={vm.planForm.aqlLevel?.value}
              isClearable
              options={vm.planOptions.aqlLevel}
              data-testid='InspectionPlansForm.aqlLevel'
              errors={vm.planForm.aqlLevel.errors}
            />
          </div>
          {!!vm.planForm.aqlLevel?.value && (
            <>
              <div>
                <Select
                  label='Major'
                  name='aql-major'
                  className='aql-major'
                  onChange={(v) => onChangePlanFormValue('aqlMajor', v)}
                  value={vm.planForm.aqlMajor?.value}
                  options={vm.planOptions.majorDefect}
                  data-testid='InspectionPlansForm.majorDefect'
                  errors={vm.planForm.aqlMajor.errors}
                />
              </div>
              <div>
                <Select
                  label='Minor'
                  name='aql-minor'
                  className='aql-minor'
                  onChange={(v) => onChangePlanFormValue('aqlMinor', v)}
                  value={vm.planForm.aqlMinor?.value}
                  options={vm.planOptions.minorDefect}
                  data-testid='InspectionPlansForm.minorDefect'
                  errors={vm.planForm.aqlMinor.errors}
                />
              </div>
              <div>
                <Select
                  label='Functional'
                  name='aql-functional'
                  className='aql-functional'
                  onChange={(v) => onChangePlanFormValue('aqlFunctional', v)}
                  value={vm.planForm.aqlFunctional?.value}
                  options={vm.planOptions.functionalDefect}
                  data-testid='InspectionPlansForm.functionalDefect'
                  errors={vm.planForm.aqlFunctional.errors}
                />
              </div>
              <div className='defects-container'>
                <p className='form-label'>Critical defects allowed</p>
                <div className='flex'>
                  <Input
                    name='critical-defects-input'
                    className={classNames('critical-defects-input', {
                      'is-invalid': vm.planForm.criticalDefect.errors.length,
                    })}
                    type='text'
                    value={vm.planForm.criticalDefect.value ?? ''}
                    autoComplete='off'
                    onChange={(e) => {
                      const parsedV = e.target.value?.length
                        ? Number(e.target.value)
                        : null;

                      onChangePlanFormValue(
                        'criticalDefect',
                        `${parsedV}` === e.target.value
                          ? parsedV
                          : e.target.value,
                      );
                    }}
                    data-testid='InspectionPlansForm.criticalDefectValue'
                  />
                  <Switch
                    offLabel='#'
                    onLabel='%'
                    name='critical-defects-switch'
                    className='critical-defects-switch'
                    onChange={() =>
                      onChangePlanFormValue(
                        'criticalDefectRule',
                        vm.planForm.criticalDefectRule.value === '%'
                          ? 'Exact'
                          : '%',
                      )
                    }
                    checked={vm.planForm.criticalDefectRule.value === '%'}
                    data-testid='InspectionPlansForm.criticalDefectRule'
                  />
                </div>
                <ErrorBag
                  data-testid='InspectionPlansForm.criticalDefectErrors'
                  errors={[
                    ...vm.planForm.criticalDefect.errors,
                    ...vm.planForm.criticalDefectRule.errors,
                  ]}
                />
              </div>
            </>
          )}
        </div>
        <p className='field-description'>
          For more information on how AQL is calculated within Milspec, please
          refer to this{' '}
          <a href='/documents/mil_std_105e_aql_normal.pdf' target='_blank'>
            document
          </a>
          .
        </p>
      </div>

      <div className='form-row connect-resource-container'>
        <ResourceSelectButton
          label='Connect to Asset / Source'
          name='connect-resource'
          placeholder='Select Asset / Source'
          disabled={
            vm?.linkedAsset?.id ||
            INSPECTION_PLAN_STATUS.DEACTIVATED === vm?.planForm?.status?.value
          }
          onClick={() => doOpenResourcesModal()}
          title={
            linkedResource?.name ? (
              <span>
                <span>{linkedResource.name}</span>
                {!!(
                  linkedResource?.id || linkedResource?.externalId?.length
                ) && (
                  <>
                    {' '}
                    <span>
                      ({!!linkedResource?.externalId?.length && 'External '}ID:{' '}
                      {linkedResource?.externalId?.length
                        ? linkedResource?.externalId
                        : linkedResource?.id}
                      )
                    </span>
                  </>
                )}
              </span>
            ) : undefined
          }
          subtitle={
            linkedResource?.location
              ? reduceLocation(
                  // XXX: Create generic attr listing f with separator
                  linkedResource.location,
                  'city',
                  'country',
                )
              : null
          }
          thumbUrl={
            linkedResource?.image?.url ??
            linkedResource?.images?.[0]?.url ??
            null
          }
          data-testid='InspectionPlansForm.resourceSelectButton'
        />
      </div>
      <div className='form-row inspection-type-container half'>
        <Select
          label='Inspection type'
          name='inspection-type'
          isSearchable={true}
          onChange={(v) => vm.doSetInspectionType(v)}
          errors={vm.planForm.type.errors}
          value={vm.planForm.type.value}
          options={vm.getInspectionTypes()}
          data-testid='InspectionPlansForm.inspectionType'
        />
      </div>
      <div className='form-row groups-update-btn'>
        <Button
          type='button'
          onClick={vm.doLoadDefaultQuestionGroups}
          data-testid='InspectionPlansForm.updateGroups'
        >
          Load Default Groups
        </Button>
        <p className='field-description'>
          Loading Default Groups will reset all groups and questions to the
          default ones belonging to the selected Source, Asset and Type
        </p>
      </div>
    </>
  );
};
export default InspectionPlansFormNewPlan;
