import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { INSPECTION_ORIGIN } from 'config/inspectionConfig';
import {
  EDITABLE_STATUS_LIST,
  INSPECTION_RESULT,
  INSPECTION_STATUS,
} from 'config/inspectionStatus';
import {
  OPEN_INSPECTOR_DETAIL_ROLES,
  WORK_OBJECT_WORKFLOW_ACTIONS_ROLES,
} from 'modules/work-objects/workObjectsPermissions';

import { reduceLocation } from 'lib/dataTransform';
import { castDateTimeToSimpleDate, formatDate } from 'lib/dateHelpers';

import Avatar, { AVATAR_SIZES } from 'lib/components/avatar/Avatar';
import Button from 'lib/components/button/Button';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import InspectionUsageDecisions from 'lib/components/inspection-usage-decisions/InspectionUsageDecisions';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Timeline from 'lib/components/timeline/Timeline';
import InspectionPreviousDecisions from 'lib/inspection-previous-decisions/InspectionPreviousDecisions';
import {
  PUBLISH_INSPECTION_ROLES,
  SCHEDULE_INSPECTION_ROLES,
} from 'modules/inspections/inspectionsPermissions';
import ReInspectModal from '../re-inspect-modal/ReInspectModal';


import { ReactComponent as VerticalShuffleSolid } from 'assets/images/vertical-shuffle-solid.svg';

import { ReactComponent as Pencil } from 'assets/images/pencil-solid.svg';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import './WorkObjectWorkflow.scss';


const renderNameAndExtId = (v) => {
  return v?.name
    ? `${v.name}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const InspectionPlanName = ({ plan, origin, className }) => {
  if (origin === INSPECTION_ORIGIN.EXTERNAL) {
    return (
      <>
        <span className='text-separator'> - </span>
        <span className='step-subtitle'>(Third party)</span>
      </>
    );
  }

  if (plan?.name) {
    return (
      <>
        <span className='text-separator'> - </span>
        <Link className={className} to={`/inspection-plans/${plan.id}`}>
          {plan.name}
        </Link>
      </>
    );
  }

  return (
    <>
      <span className='text-separator'> - </span>
      <span className='step-subtitle'>(Plan inherited from asset)</span>
    </>
  );
};

const renderSubtitle = (pair) => {
  const { inspection } = pair;
  if (inspection?.usageDecisions?.length) {
    return 'Decision needed';
  }

  if (inspection?.result === INSPECTION_RESULT.FAILED) {
    return 'Inspection failed';
  }

  if (inspection?.result === INSPECTION_RESULT.PASSED) {
    return 'Inspection passed';
  }

  if (inspection?.status === INSPECTION_RESULT.SKIPPED) {
    if (inspection?.result === INSPECTION_RESULT.VALIDITY_PERIOD) {
      return `Inspection skipped - valid until ${formatDate(
        inspection.validityInspection?.validUntil,
      )}`;
    }
    return 'Inspection skipped';
  }

  if (
    pair.inspection?.inspectionWindowStartDate &&
    pair.inspection?.inspectionWindowEndDate &&
    !pair.inspection?.inspectionDate
  ) {
    return `Requested between ${formatDate(
      castDateTimeToSimpleDate(pair.inspection.inspectionWindowStartDate),
    )} and ${formatDate(
      castDateTimeToSimpleDate(pair.inspection.inspectionWindowEndDate),
    )}`;
  }

  if (
    pair.inspectionWindowStartDate &&
    pair.inspectionWindowEndDate &&
    !pair.inspection?.inspectionDate
  ) {
    return `Requested between ${formatDate(
      castDateTimeToSimpleDate(pair.inspectionWindowStartDate),
    )} and ${formatDate(
      castDateTimeToSimpleDate(pair.inspectionWindowEndDate),
    )}`;
  }
  return null;
};

const AddInspectionButton = ({
  history,
  workObjectId,
  partialId,
  stepId,
  position,
  order,
  isInternalDisabled = false,
  isThirdPartyDisabled = false,
}) => (
  <ProtectedComponent allowed={WORK_OBJECT_WORKFLOW_ACTIONS_ROLES}>
    <DropdownMenu
      appendTo={document.body}
      containerClassName={classNames('timeline-new-subitem-dropdown', position)}
      items={[
        <MenuItem
          label={'New inspection'}
          onClick={() =>
            history.push(
              `/inspections/add?workObjectId=${workObjectId}&partialId=${partialId}&stepId=${stepId}&order=${order}`,
            )
          }
          disabled={isInternalDisabled}
        />,
        <MenuItem
          label={'New third party inspection'}
          onClick={() =>
            history.push(
              `/inspections/add-third-party?workObjectId=${workObjectId}&partialId=${partialId}&stepId=${stepId}&order=${order}`,
            )
          }
          disabled={isThirdPartyDisabled}
        />,
      ]}
    >
      <Timeline.NewSubitem className={position} label='Add inspection' />
    </DropdownMenu>
  </ProtectedComponent>
);

const isSkipableInspection = (inspection) =>
  inspection?.isSkipable &&
  inspection?.inspection &&
  ![
    INSPECTION_STATUS.IN_PROGRESS,
    INSPECTION_STATUS.FINISHED,
    INSPECTION_STATUS.SKIPPED,
  ].includes(inspection.inspection?.status);

const PartialWorkflow = ({ partial, workObject, actions, history }) => {
  const isEditable =
    workObject?.userCanEdit && !actions.isWorkObjectActionsDisabled;
  const activeStepOrder = partial?.stepOrder;
  return (
    <div className='partial-workflow'>
      <Timeline>
        {(partial.partialWorkObjectSteps || []).map((step, stepIndex) => (
          <Timeline.Item
            completed={partial?.stepOrder > step?.order}
            key={step?.id || stepIndex}
            icon={step?.order}
          >
            <p className='step-title'>
              <Link
                className='timeline-link'
                to={`/sources/${step?.source?.id}`}
              >
                {renderNameAndExtId(step?.source)}
              </Link>
            </p>
            <p className='step-subtitle'>
              {reduceLocation(step?.source?.location, 'city', 'country')}
            </p>
            {!step.inspectionPairs.length && (
              <>
                <Timeline.Subitem className='empty-subitem'>
                  <div className='subitem-container'>
                    <div className='subitem-header'>
                      <p>No inspection will take place at this location.</p>
                    </div>
                  </div>
                  {isEditable && (
                    <AddInspectionButton
                      history={history}
                      isInternalDisabled={activeStepOrder > step?.order}
                      position={'same'}
                      order={1}
                      stepId={step?.id}
                      workObjectId={workObject?.id}
                      partialId={partial?.id}
                    />
                  )}
                </Timeline.Subitem>
              </>
            )}
            {(step?.inspectionPairs || []).map((pair, pairIndex) => {
              const showPreviousDecisions =
                !!pair?.inspection?.actualDecisions?.length;

              const showResultsButton =
                pair?.inspection?.result &&
                pair.inspection?.result !== INSPECTION_RESULT.VALIDITY_PERIOD;

              const showPreviousResultsButton =
                pair?.inspection?.result === INSPECTION_RESULT.VALIDITY_PERIOD;

              const showUsageDecisions =
                !!pair?.inspection?.usageDecisions?.length;

              const addAvailableOrder = actions.getAddInspectionOrder(
                step?.inspectionPairs,
              );

              const showAddInspectionAfter = isEditable;

              const showAddInspectionBefore = isEditable && pair?.order === 1;

              const isInternalAfterDisabled = !(
                activeStepOrder <= step?.order &&
                addAvailableOrder <= pair.order
              );
              const isInternalBeforeDisabled = !(addAvailableOrder === 0);
              const subtitle = renderSubtitle(pair);
              const inspector = pair?.inspection?.inspector;

              const isPublishVisible =
                partial.isReadyForInspection &&
                pair.isScheduled &&
                !pair.inspection;

              const isScheduleVisible =
                pair.isScheduled &&
                (!pair.inspection ||
                  EDITABLE_STATUS_LIST.includes(pair.inspection?.status));

              const scheduleButtonLabel =
                pair.inspectionWindowStartDate && pair.inspectionWindowEndDate
                  ? 'Change window'
                  : 'Schedule';

              return (
                <Timeline.Subitem
                  key={pair?.id || pairIndex}
                  failed={pair?.inspection?.result === INSPECTION_RESULT.FAILED}
                  completed={
                    pair?.inspection?.result === INSPECTION_RESULT.PASSED
                  }
                  icon={
                    !!pair?.inspection?.usageDecisions?.length && (
                      <VerticalShuffleSolid />
                    )
                  }
                >
                  {showAddInspectionBefore && (
                    <AddInspectionButton
                      history={history}
                      position={'prev'}
                      order={pair.order}
                      stepId={step?.id}
                      workObjectId={workObject?.id}
                      isInternalDisabled={isInternalBeforeDisabled}
                      partialId={partial?.id}
                    />
                  )}
                  <div className='subitem-container'>
                    <div className='subitem-header'>
                      <p className='step-title'>
                        <span>
                          {!!pair?.inspection?.id ? (
                            <Link
                              className='timeline-link'
                              to={`/inspections/${pair?.inspection?.id}`}
                            >
                              {pair?.inspectionType?.name}
                            </Link>
                          ) : (
                            pair?.inspectionType?.name
                          )}
                          <InspectionPlanName
                            className='timeline-link'
                            plan={pair?.inspectionPlan}
                            origin={pair?.origin}
                          />
                        </span>
                      </p>
                      {/* XXX: QM-3445: Disable custom qty UI until change requests are implemented.
                      <p className='step-subtitle step-subtitle-block'>
                        {`${Number.isFinite(pair?.customInspectionQty) ? 'Custom' : 'Default'} Qty:`}
                        {' '}
                        {!!Number.isFinite(pair.customInspectionQty) ? (
                          <>
                            {pair.customInspectionQty}{!!pair.customInspectionQtyIsRelative && '%'}
                            {!!pair.customInspectionQtyIsRelative ? (<>
                              {' '}
                              ({Math.round(partial.quantity * pair.customInspectionQty / 100)})
                            </>) : (pair.customInspectionQty > partial.quantity ? (<>
                              {' '}
                              ({partial.quantity} in Partial)
                            </>) : null)}
                          </>
                        ) : (
                          <>
                            {partial.quantity}
                          </>
                        )}
                        {!!workObject?.userCanEdit && !actions.isWorkObjectActionsDisabled && (
                          <Button
                            className='link-button edit-custom-qty-button'
                            onClick={() =>
                              actions.userOpensCustomQtyModal(pair.id)
                            }
                          >
                            <Pencil />
                          </Button>
                        )}
                      </p> */}
                      {subtitle && <p className='step-subtitle'>{subtitle}</p>}

                      {!!pair?.inspection?.parentInspectionId && (
                        <p className='reinspection-label'>
                          <StatusPill variant={STATUS_PILL_VARIANT.WARNING}>
                            Reinspection
                          </StatusPill>
                        </p>
                      )}
                    </div>
                    {!!inspector && (
                      <div className='inspector-container'>
                        <div className='inspection-info'>
                          {!!inspector.name && (
                            <Avatar
                              size={AVATAR_SIZES.S}
                              url={inspector.profilePhoto?.url}
                              placeholder={inspector.name}
                            />
                          )}
                          <div className='inspection-info-content'>
                            <p className='step-title'>
                              <ProtectedComponent
                                allowed={OPEN_INSPECTOR_DETAIL_ROLES}
                                renderNotAllowed={() => inspector.name}
                              >
                                <Link
                                  className='timeline-link'
                                  to={`/users/${inspector.id}`}
                                >
                                  {inspector.name}
                                </Link>
                              </ProtectedComponent>
                            </p>
                            <p className='step-subtitle'>
                              {formatDate(
                                pair?.inspection?.finishedAt ||
                                pair?.inspection?.inspectionDate,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {workObject?.userCanEdit && isSkipableInspection(pair) && (
                      <ProtectedComponent
                        allowed={WORK_OBJECT_WORKFLOW_ACTIONS_ROLES}
                      >
                        <Button
                          className='medium-button outline'
                          onClick={() =>
                            actions.userSkipsInspection(pair?.inspection?.id)
                          }
                          data-testid='WorkObjectsDetailPage.skipInspection'
                          disabled={actions.isWorkObjectActionsDisabled}
                        >
                          Skip inspection
                        </Button>
                      </ProtectedComponent>
                    )}
                    {showResultsButton && (
                      <Button
                        className='medium-button outline'
                        onClick={() =>
                          history.push(
                            pair.inspection?.origin ===
                              INSPECTION_ORIGIN.EXTERNAL ||
                              !!pair?.inspection?.isThirdParty
                              ? `/inspections/${pair.inspection.id}`
                              : `/inspections/${pair.inspection.id}/results`,
                          )
                        }
                      >
                        View results
                      </Button>
                    )}
                    {showPreviousResultsButton && (
                      <Button
                        className='medium-button outline'
                        onClick={() =>
                          history.push(
                            pair.inspection?.origin ===
                              INSPECTION_ORIGIN.EXTERNAL ||
                              !!pair?.inspection?.validityInspection
                                ?.isThirdParty
                              ? `/inspections/${pair.inspection?.validityInspection?.id}`
                              : `/inspections/${pair.inspection?.validityInspection?.id}/results`,
                          )
                        }
                      >
                        View previous results
                      </Button>
                    )}
                    {isPublishVisible && (
                      <ProtectedComponent allowed={PUBLISH_INSPECTION_ROLES}>
                        <Button
                          onClick={() =>
                            actions.userPublishesInspection(partial.id, pair.id)
                          }
                          className='medium-button outline'
                        >
                          Publish
                        </Button>
                      </ProtectedComponent>
                    )}
                    {isScheduleVisible && (
                      <ProtectedComponent allowed={SCHEDULE_INSPECTION_ROLES}>
                        <Button
                          className='medium-button'
                          onClick={() =>
                            actions.userOpensPairWindowModal(pair.id)
                          }
                        >
                          {scheduleButtonLabel}
                        </Button>
                      </ProtectedComponent>
                    )}

                  </div>
                  {showPreviousDecisions && (
                    <InspectionPreviousDecisions {...pair} />
                  )}
                  {showUsageDecisions && (
                    <InspectionUsageDecisions actions={actions} {...pair} />
                  )}
                  {showAddInspectionAfter && (
                    <AddInspectionButton
                      history={history}
                      isInternalDisabled={isInternalAfterDisabled}
                      position={'next'}
                      order={pair.order + 1}
                      stepId={step?.id}
                      workObjectId={workObject?.id}
                      partialId={partial?.id}
                    />
                  )}
                </Timeline.Subitem>
              );
            })}
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

function renderLocationTitle(step, idx, steps) {
  const isSource = idx === 0;
  const isDestination = idx === steps.length - 1;
  const hasLocation = !!step.source;

  const titleMissing = isSource
    ? 'No source selected'
    : isDestination
      ? 'No destination selected'
      : 'No location selected';

  const subtitleMissing = isSource
    ? 'Source is required'
    : isDestination
      ? 'Destination is required'
      : 'Location is required';

  return (
    <>
      <p className='step-title'>
        {hasLocation ? (
          <Link className='timeline-link' to={`/sources/${step.source.id}`}>
            {renderNameAndExtId(step.source)}
          </Link>
        ) : (
          titleMissing
        )}
      </p>
      <p className='step-subtitle'>
        {hasLocation ? (
          reduceLocation(step?.source?.location, 'city', 'country')
        ) : (
          <ErrorBag errors={[subtitleMissing]} />
        )}
      </p>
    </>
  );
}

const WorkObjectWorkFlow = ({ data, actions, ...props }) => {
  const hasPartials = !!data.workObject?.partialWorkObjects?.length;
  const showOriginalWorkflow =
    !hasPartials ||
    (hasPartials &&
      data.currentTab === data.workObject.partialWorkObjects.length);
  const showPartialWorkflow =
    hasPartials && data.currentTab < data.workObject.partialWorkObjects.length;

  const steps = data.workObject?.workObjectSteps || [];

  return (
    <div className='work-object-workflow'>
      <h2>Workflow</h2>
      {showOriginalWorkflow && (
        <Timeline>
          {steps.map((step, stepIndex) => (
            <Timeline.Item key={step?.id || stepIndex} icon={step?.order}>
              {renderLocationTitle(step, stepIndex, steps)}
              {!step.inspectionPairs.length && (
                <Timeline.Subitem className='empty-subitem'>
                  <p>No inspection will take place at this location.</p>
                </Timeline.Subitem>
              )}
              {(step?.inspectionPairs || []).map(
                (inspection, inspectionIndex) => (
                  <Timeline.Subitem key={inspection?.id || inspectionIndex}>
                    <p className='step-title'>
                      {!!inspection?.originalWorkflowStepInspectionPair
                        ?.inspection?.id ? (
                        <Link
                          className='timeline-link'
                          to={`/inspections/${inspection?.originalWorkflowStepInspectionPair?.inspection?.id}`}
                        >
                          {
                            inspection?.originalWorkflowStepInspectionPair
                              ?.inspectionType?.name
                          }
                        </Link>
                      ) : (
                        inspection?.originalWorkflowStepInspectionPair
                          ?.inspectionType?.name
                      )}
                      <InspectionPlanName
                        origin={inspection?.origin}
                        plan={
                          inspection?.originalWorkflowStepInspectionPair
                            ?.inspectionPlan
                        }
                      />
                    </p>
                    {/* XXX: QM-3445: Disable custom qty UI until change requests are implemented. 
                    {!!Number.isFinite(inspection?.originalWorkflowStepInspectionPair?.customInspectionQty) && (
                      <p className='step-subtitle step-subtitle-block'>
                        Custom Qty: {inspection?.originalWorkflowStepInspectionPair.customInspectionQty}{!!inspection?.originalWorkflowStepInspectionPair.customInspectionQtyIsRelative && '%'}
                        {!!inspection?.originalWorkflowStepInspectionPair.customInspectionQtyIsRelative ? (<>
                          {' '}
                          ({Math.round(data.workObject.quantity * inspection?.originalWorkflowStepInspectionPair.customInspectionQty / 100)})
                        </>) : (inspection?.originalWorkflowStepInspectionPair.customInspectionQty > data.workObject.quantity ? (<>
                          {' '}
                          ({data.workObject.quantity} in Work Object)
                        </>) : null)}
                      </p>
                    )} */}
                  </Timeline.Subitem>
                ),
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      )}
      {showPartialWorkflow && (
        <PartialWorkflow
          history={props.history}
          partial={data.workObject?.partialWorkObjects[data.currentTab]}
          workObject={data.workObject}
          actions={actions}
        />
      )}
      <ReInspectModal
        data={{
          reInspectAql: data.reInspectAql,
          aqlOptions: data.currentReInspectOptions,
        }}
        isOpen={data.isReInspectModalOpen}
        onRequestClose={actions.userCancelsReInspectModal}
        onAqlChange={actions.userSetsReInspectAql}
        onSubmit={actions.userSubmitsReInspect}
      />
    </div>
  );
};

export default WorkObjectWorkFlow;
