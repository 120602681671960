import UsersForm from '../users-form/UsersForm';
import './UsersEditPage.scss';

const UsersEditPage = (props) => {
  return (
    <div className='users-add-page container-m'>
      <div className='row'>
        <h1>Edit user</h1>
      </div>
      <div className='row'>
        <UsersForm {...props} />
      </div>
    </div>
  );
};

export default UsersEditPage;
