import React from 'react';

import { Link } from 'react-router-dom';

const WorkObjectLink = React.memo(
  ({ inspection }) => {
    let output = null;
    const woId = inspection?.workObjectId ?? null;
    const woPartOrd =
      inspection?.stepPair?.partialStep?.partialWorkObject?.order > 1
        ? inspection?.stepPair?.partialStep?.partialWorkObject?.order
        : null;

    if (woId) {
      const woLink = `/work-objects/${woId}${
        woPartOrd ? `?currentTab=${woPartOrd - 1}` : ''
      }`;
      const woLabel = `${woId}${woPartOrd ? `-${woPartOrd}` : ''}`;

      output = (
        <Link to={woLink}>
          <span className='bold'>{woLabel}</span>
        </Link>
      );
    }

    return output;
  },
  (a, b) =>
    a?.workObjectId === b?.workObjectId &&
    a?.stepPair?.partialStep?.partialWorkObject?.order ===
      b?.stepPair?.partialStep?.partialWorkObject?.order,
);

export { WorkObjectLink };
