import makeReducer from 'lib/makeReducer';

import { INSPECTION_STATUS } from 'config/inspectionStatus';

const getInitialModalState = () => ({
  list: [],
  search: '',
  sortBy: 'id',
  page: 1,
  pageSize: 10,
  sortOrder: 'ASC',
  isModalOpen: false,
  count: 0,
  errors: [],
});

const getInitialLinkableResourceModalState = () => ({
  ...getInitialModalState(),
  assets: [],
  sources: [],
  assetsCount: 0,
  sourcesCount: 0,
  tab: 'assets',
});

const INITIAL_FORM_STATE = {
  id: null,
  linkedResource: {
    value: null,
    errors: [],
  },
  source: {
    value: null,
    errors: [],
  },
  status: {
    value: INSPECTION_STATUS.DRAFT,
    errors: [],
  },
  initialStatus: '',
  type: {
    value: null,
    errors: [],
  },
  plan: {
    value: null,
    errors: [],
  },
  date: {
    value: '',
    errors: [],
  },
  inspectionWindow: {
    value: ['', ''],
    errors: [],
  },
  inspector: {
    value: null,
    errors: [],
  },
  inspectorAutoset: {
    shouldAutoset: true,
    isAutoset: false,
  },

  typeOptions: [],

  availableInspectors: getInitialModalState(),
  availablePlans: getInitialModalState(),
  availableSources: getInitialModalState(),
  linkableResources: getInitialLinkableResourceModalState(),

  loading: false,
  isAttachedInspection: false,
  isDirty: false,
};

export const INSPECTIONS_FORM_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',

  APP_LOADS_INSPECTION: 'APP_LOADS_INSPECTION',
  APP_LOADS_SOURCES: 'APP_LOADS_SOURCES',
  APP_LOADS_ASSETS: 'APP_LOADS_ASSETS',
  APP_LOADS_TYPES: 'APP_LOADS_TYPES',
  APP_LOADS_PLANS: 'APP_LOADS_PLANS',
  APP_LOADS_INSPECTORS: 'APP_LOADS_INSPECTORS',
  APP_LOADS_WORK_OBJECT_ATTACHED_DATA: 'APP_LOADS_WORK_OBJECT_ATTACHED_DATA',

  USER_CHANGES_DATE: 'USER_CHANGES_DATE',
  USER_CHANGES_WINDOW: 'USER_CHANGES_WINDOW',
  USER_CHANGES_STATUS: 'USER_CHANGES_STATUS',

  USER_SELECTS_INSPECTION_TYPE: 'USER_SELECTS_INSPECTION_TYPE',

  USER_OPENS_RESOURCES_MODAL: 'USER_OPENS_RESOURCES_MODAL',
  USER_CANCELS_RESOURCES_MODAL: 'USER_CANCELS_RESOURCES_MODAL',
  USER_SELECTS_RESOURCE: 'USER_SELECTS_RESOURCE',
  USER_SEARCHES_RESOURCES: 'USER_SEARCHES_RESOURCES',
  USER_SORTS_RESOURCES: 'USER_SORTS_RESOURCES',
  USER_CHANGES_RESOURCES_MODAL_TAB: 'USER_CHANGES_RESOURCES_MODAL_TAB',
  USER_SETS_RESOURCES_PAGE: 'USER_SETS_RESOURCES_PAGE',

  USER_OPENS_SOURCES_MODAL: 'USER_OPENS_SOURCES_MODAL',
  USER_CANCELS_SOURCES_MODAL: 'USER_CANCELS_SOURCES_MODAL',
  USER_SELECTS_SOURCE: 'USER_SELECTS_SOURCE',
  USER_SEARCHES_SOURCES: 'USER_SEARCHES_SOURCES',
  USER_SORTS_SOURCES: 'USER_SORTS_SOURCES',
  USER_SETS_SOURCES_PAGE: 'USER_SETS_SOURCES_PAGE',

  USER_OPENS_PLANS_MODAL: 'USER_OPENS_PLANS_MODAL',
  USER_CANCELS_PLANS_MODAL: 'USER_CANCELS_PLANS_MODAL',
  USER_SELECTS_PLAN: 'USER_SELECTS_PLAN',
  USER_SEARCHES_PLANS: 'USER_SEARCHES_PLANS',
  USER_SORTS_PLANS: 'USER_SORTS_PLANS',
  USER_SETS_PLANS_PAGE: 'USER_SETS_PLANS_PAGE',

  USER_OPENS_INSPECTORS_MODAL: 'USER_OPENS_INSPECTORS_MODAL',
  USER_CANCELS_INSPECTORS_MODAL: 'USER_CANCELS_INSPECTORS_MODAL',
  USER_SELECTS_INSPECTOR: 'USER_SELECTS_INSPECTOR',
  USER_SEARCHES_INSPECTORS: 'USER_SEARCHES_INSPECTORS',
  USER_SORTS_INSPECTORS: 'USER_SORTS_INSPECTORS',
  USER_SETS_INSPECTORS_PAGE: 'USER_SETS_INSPECTORS_PAGE',
  APP_AUTOSETS_INSPECTOR: 'APP_AUTOSETS_INSPECTOR',

  USER_CLEARS_DATE: 'USER_CLEARS_DATE',
  USER_CLEARS_INSPECTOR: 'USER_CLEARS_INSPECTOR',
};

const setValue = (key, state, value) => ({
  ...state,
  [key]: {
    value,
    errors: [],
  },
  isDirty: true,
});

const INSPECTIONS_REDUCER_CONFIG = {
  [INSPECTIONS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      availableSources: { ...state.availableSources, isModalOpen: false },
      availablePlans: { ...state.availablePlans, isModalOpen: false },
      linkableResources: { ...state.linkableResources, isModalOpen: false },
      availableInspectors: { ...state.availableInspectors, isModalOpen: false },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_INSPECTION]: (state, action) => {
    const inspection = action.payload;
    return {
      ...state,
      ...inspection,
      initialStatus: inspection.status.value,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CHANGES_DATE]: (state, action) => {
    return setValue('date', state, action.payload);
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CHANGES_WINDOW]: (state, action) => {
    return setValue('inspectionWindow', state, action.payload);
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CHANGES_STATUS]: (state, action) => {
    return setValue('status', state, action.payload);
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SELECTS_INSPECTION_TYPE]: (state, action) => {
    return setValue('type', state, action.payload);
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_SOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        sources: action.payload.data,
        sourcesCount: action.payload.sourcesCount,
      },
      availableSources: {
        ...state.availableSources,
        list: action.payload.data,
        count: action.payload.sourcesCount,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_ASSETS]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        assets: action.payload.data,
        assetsCount: action.payload.assetsCount,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_PLANS]: (state, action) => {
    return {
      ...state,
      availablePlans: {
        ...state.availablePlans,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_WORK_OBJECT_ATTACHED_DATA]: (
    state,
    action,
  ) => {
    return {
      ...state,
      ...action.payload,
      isAttachedInspection: true,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_LOADS_TYPES]: (state, action) => {
    return {
      ...state,
      typeOptions: action.payload,
    };
  },
  /* Linkable Resources Modal */
  [INSPECTIONS_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: getInitialLinkableResourceModalState(),
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SELECTS_RESOURCE]: (state, action) => {
    return {
      ...state,
      linkedResource: {
        value: action.payload,
        errors: [],
      },
      type: {
        value: null,
        errors: [],
      },
      plan: {
        value: null,
        errors: [],
      },
      source: {
        value: null,
        errors: [],
      },
      inspector: {
        value: null,
        errors: [],
      },
      inspectorAutoset: { ...INITIAL_FORM_STATE.inspectorAutoset },
      linkableResources: getInitialLinkableResourceModalState(),
      isDirty: true,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SORTS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        ...action.payload,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CHANGES_RESOURCES_MODAL_TAB]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: '',
        sortBy: 'id',
        sortOrder: 'ASC',
        tab: action.payload,
        page: 1,
        pageSize: 10,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SETS_RESOURCES_PAGE]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        page: action.payload,
      },
    };
  },
  /* End Linkable Resources Modal */
  /* Sources Modal */
  [INSPECTIONS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL]: (state) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL]: (state) => {
    return {
      ...state,
      availableSources: getInitialModalState(),
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SELECTS_SOURCE]: (state, action) => {
    return {
      ...state,
      source: {
        value: action.payload,
        errors: [],
      },
      inspector: {
        value: null,
        errors: [],
      },
      availableSources: getInitialModalState(),
      inspectorAutoset: { ...INITIAL_FORM_STATE.inspectorAutoset },
      isDirty: true,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SORTS_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        ...action.payload,
      },
    };
  },
  /* End Sources Modal */
  /* Inspection Plans Modal */
  [INSPECTIONS_FORM_ACTIONS.USER_OPENS_PLANS_MODAL]: (state) => {
    return {
      ...state,
      availablePlans: {
        ...state.availablePlans,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CANCELS_PLANS_MODAL]: (state) => {
    return {
      ...state,
      availablePlans: getInitialModalState(),
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SELECTS_PLAN]: (state, action) => {
    return {
      ...state,
      plan: {
        value: action.payload,
        errors: [],
      },
      availablePlans: getInitialModalState(),
      isDirty: true,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_PLANS]: (state, action) => {
    return {
      ...state,
      availablePlans: {
        ...state.availablePlans,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SORTS_PLANS]: (state, action) => {
    return {
      ...state,
      availablePlans: {
        ...state.availablePlans,
        ...action.payload,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SETS_PLANS_PAGE]: (state, action) => {
    return {
      ...state,
      availablePlans: {
        ...state.availablePlans,
        page: action.payload,
      },
    };
  },
  /* End Inspection Plans Modal */
  /* Inspectors Modal */
  [INSPECTIONS_FORM_ACTIONS.USER_OPENS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CANCELS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: getInitialModalState(),
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SELECTS_INSPECTOR]: (state, action) => {
    return {
      ...state,
      inspector: {
        value: action.payload,
        errors: [],
      },
      availableInspectors: getInitialModalState(),
      isDirty: true,
      inspectorAutoset: { ...state.inspectorAutoset, isAutoset: false },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SORTS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        ...action.payload,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SETS_INSPECTORS_PAGE]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        page: action.payload,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_AUTOSETS_INSPECTOR]: (state, action) => {
    return {
      ...state,
      inspector: {
        value: action.payload,
        errors: [],
      },
      inspectorAutoset: {
        shouldAutoset: false,
        isAutoset: true,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        page: action.payload,
      },
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      loading: true,
      isDirty: false,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CLEARS_DATE]: (state) => {
    return setValue('date', state, '');
  },
  [INSPECTIONS_FORM_ACTIONS.USER_CLEARS_INSPECTOR]: (state) => {
    return {
      ...state,
      inspector: {
        value: null,
        errors: [],
      },
      isDirty: true,
      inspectorAutoset: { ...state.inspectorAutoset, isAutoset: false },
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTIONS_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
