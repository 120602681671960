import {
  userStorage,
  tokenStorage,
  companyStorage,
} from './lib/localStorageService';

export const APP_ACTIONS = {
  USER_CHANGES_PROFILE: 'USER_CHANGES_PROFILE',
  USER_LOGS_OUT: 'USER_LOGS_OUT',
  USER_LOGS_IN: 'USER_LOGS_IN',
  USER_CHANGES_COMPANY: 'USER_CHANGES_COMPANY',
  OTHER_TAB_CHANGES_STORAGE: 'OTHER_TAB_CHANGES_STORAGE',
};

const refreshAppState = () => ({
  currentUser: userStorage.get() || null,
  token: tokenStorage.get() || '',
  company: companyStorage.get() || null,
});

export const INITIAL_APP_STATE = refreshAppState();

export const appReducer = (state, action) => {
  switch (action.type) {
    case APP_ACTIONS.USER_LOGS_IN:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        company: action.payload.company,
        token: action.payload.token,
      };
    case APP_ACTIONS.USER_LOGS_OUT:
      return {
        ...state,
        currentUser: {},
        company: null,
        token: '',
      };
    case APP_ACTIONS.USER_CHANGES_PROFILE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case APP_ACTIONS.USER_CHANGES_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case APP_ACTIONS.OTHER_TAB_CHANGES_STORAGE:
      return refreshAppState();

    default:
      return state;
  }
};

export default appReducer;
