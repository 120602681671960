import React from 'react';
import MeasurementTableField from './MeasurementTableField';

function MeasurementTableForm({ columns, data }) {
  if (columns.length === 0) return null;

  return (
    <table>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.name}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, index) => (
            <tr key={index}>
              {row.map((val, colIndex) => (
                <td key={`${index}-${colIndex}`}>
                  <MeasurementTableField
                    row={index}
                    col={colIndex}
                    value={val}
                    props={columns[colIndex]}
                  />
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default MeasurementTableForm;
