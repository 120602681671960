import { useEffect, useReducer, useState } from 'react';
import debounce from 'lodash.debounce';
import useNavigationPrompt from 'lib/useNavigationPrompt';
import {
  reducer,
  initialState,
  WORKFLOWS_FORM_ACTIONS,
} from './workflowsFormReducer';
import workflowsService from './workflowsService';
import workflowsFormValidator from './workflowsFormValidator';
import { workflowsToFormState } from './dataTransform';

const IS_DUPLICATED_SOURCES_ALLOWED = true;

const useInspectionTypesForm = (props) => {
  const [sourcesOptions, setSourcesOptions] = useState({
    sources: [],
    count: 0,
  });
  const [inspectionTypesOptions, setInspectionTypesOptions] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useNavigationPrompt(state.isDirty);

  const { sortBy, sortOrder, search, page, pageSize } = state.availableSources;
  const sourcesParams = {
    order: { [sortBy]: sortOrder },
    search,
    page,
    pageSize,
    filters: { status: 'active' },
  };

  const getSources = debounce(
    (params) => {
      return workflowsService
        .getSources(params)
        .then((response) => {
          const selectedSources = state.workflowSteps.map(
            (item) => item.source?.value?.value,
          );
          const availableSources = IS_DUPLICATED_SOURCES_ALLOWED
            ? response.data
            : response.data?.filter(
              (source) => !selectedSources.includes(source.id),
            );
          setSourcesOptions({
            sources: availableSources,
            count: response.count,
          });
        })
        .catch(() => {
          dispatch({
            type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
            payload: {
              errors: ['An error occured while fetching sources'],
            },
          });
        });
    },
    400,
    { leading: true },
  );

  const getInspectionTypes = () => {
    return workflowsService
      .getInspectionTypes()
      .then((response) => {
        setInspectionTypesOptions(
          response.data?.map((source) => ({
            value: source.id,
            label: source.name,
          })),
        );
      })
      .catch(() => {
        dispatch({
          type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching inspection type'],
          },
        });
      });
  };

  const getInspectionPlans = (params) => {
    return workflowsService
      .getInspectionPlans(params)
      .then((response) => response)
      .catch(() => {
        dispatch({
          type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching inspection plan'],
          },
        });
      });
  };

  const getAllrelatedInspectionPlans = (workflow) => {
    workflow?.workflowSteps?.forEach((step, stepIndex) => {
      step?.inspectionPairs?.forEach((inspection, inspectionIndex) => {
        const inspectionType = inspection?.inspectionType?.value;
        if (inspectionType?.value) {
          const filters = {
            filters: {
              inspectionType: inspectionType.value,
              status: 'published',
            },
          };

          appLoadsInspectionPlans(filters, { stepIndex, inspectionIndex });
        }
      });
    });
    return workflow;
  };

  const getWorkflow = () => {
    const id = props.match?.params?.id;
    return id
      ? workflowsService
        .getWorkflow({ id })
        .then((workflow) => {
          dispatch({
            type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
            payload: workflowsToFormState(workflow),
          });
          return workflowsToFormState(workflow);
        })
        .then((workflow) => getAllrelatedInspectionPlans(workflow))
        .catch((e) => {
          dispatch({
            type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
      : Promise.resolve();
  };

  useEffect(() => {
    Promise.all([getWorkflow(), getInspectionTypes()]);

    //cleanup state after unmounting
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.INITIAL_STATE,
    });
  }, []);

  useEffect(() => {
    getSources(sourcesParams);
  }, [
    state.availableSources.sortBy,
    state.availableSources.sortOrder,
    state.availableSources.search,
    state.availableSources.page,
  ]);

  const userTypesName = (e) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_TYPES_NAME,
      payload: e.target.value || '',
    });
  };

  const userTypesExternalId = (e) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_TYPES_EXTERNAL_ID,
      payload: e.target.value || '',
    });
  };

  const userTypesDescription = (e) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_TYPES_DESCRIPTION,
      payload: e.target.value || '',
    });
  };

  const userAddsSource = () => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_ADDS_SOURCE,
    });
  };

  const userRemovesSource = (index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_REMOVES_SOURCE,
      payload: index,
    });
  };

  const userAddsInspectionPair = (index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_ADDS_INSPECTION_PAIR,
      payload: index,
    });
  };

  const userRemovesInspectionPair = (index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_REMOVES_INSPECTION_PAIR,
      payload: { ...index },
    });
  };

  const userSetsSource = () => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE,
    });
  };

  const appLoadsInspectionPlans = (filters, index) => {
    dispatch({
      type: WORKFLOWS_FORM_ACTIONS.APP_IS_LOADING_INSPECTION_PLANS,
      payload: { ...index },
    });
    return getInspectionPlans(filters).then((result) =>
      dispatch({
        type: WORKFLOWS_FORM_ACTIONS.APP_LOADS_INSPECTION_PLANS,
        payload: { ...index, inspectionPlans: result.data },
      }),
    );
  };

  const userSetsSourceInspectionType = (inspectionType, index) => {
    const filters = {
      filters: { inspectionType: inspectionType.value, status: 'published' },
    };
    return Promise.all([
      appLoadsInspectionPlans(filters, index),
      dispatch({
        type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_TYPE,
        payload: { ...index, inspectionType },
      }),
    ]);
  };

  const userSetsSourceInspectionPlan = (inspectionPlan, index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_PLAN,
      payload: { ...index, inspectionPlan },
    });
  };

  const userSetsSourceInspectionOrigin = (inspectionOrigin, index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_ORIGIN,
      payload: { ...index, inspectionOrigin },
    });
  };

  const userSetsRequiredInspection = (isRequired, index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_REQUIRED_INSPECTION,
      payload: { ...index, isRequired },
    });
  };

  const userSetsScheduledInspection = (isScheduled, index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_SCHEDULED_INSPECTION,
      payload: { ...index, isScheduled },
    });
  };

  const userSetsCustomInspectionQty = (customInspectionQty, indexParams) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_CUSTOM_INSPECTION_QUANTITY,
      payload: { ...indexParams, customInspectionQty },
    });
  };

  const userSetsCustomInspectionQtyIsRelative = (customInspectionQtyIsRelative, indexParams) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_CUSTOM_INSPECTION_QUANTITY_IS_RELATIVE,
      payload: { ...indexParams, customInspectionQtyIsRelative },
    });
  };

  const userOpensSourcesModal = (ev, index) => {
    ev.preventDefault();
    return getSources(sourcesParams).then(() => {
      return dispatch({
        type: WORKFLOWS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL,
        payload: index,
      });
    });
  };

  const userClearsSource = (index) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_CLEARS_SOURCE,
      payload: index,
    });
  };

  const userTogglesSource = (source) => {
    dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_TOGGLES_SOURCE,
      payload: source,
    });
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE,
    });
  };

  const userCancelsSourcesModal = () => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL,
    });
  };

  const userSearchesSources = (ev) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SEARCHES_SOURCES,
      payload: ev.target.value,
    });
  };

  const userSortsSources = (col, order) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SORTS_SOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSetsSourcesPage = (page) => {
    return dispatch({
      type: WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE,
      payload: page,
    });
  };

  const userSubmitsForm = (e) => {
    e.preventDefault();
    return dispatch({ type: WORKFLOWS_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  useEffect(() => {
    if (state.loading) {
      workflowsFormValidator(state)
        .then((data) =>
          workflowsService
            .saveWorkflow(data)
            .then((response) =>
              props.history.push(`/workflows/${response.id}`),
            ),
        )
        .catch((e) => {
          dispatch({
            type: WORKFLOWS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .then(() =>
          dispatch({
            type: WORKFLOWS_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    }
  }, [state.loading]);

  useEffect(() => {
    if (!state.loading) {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.loading]);

  return {
    state,
    getSources,
    getInspectionTypes,
    getInspectionPlans,
    getWorkflow,
    sourcesOptions,
    inspectionTypesOptions,
    userTypesName,
    userTypesExternalId,
    userTypesDescription,
    userAddsSource,
    userRemovesSource,
    userAddsInspectionPair,
    userRemovesInspectionPair,
    userSetsSource,
    userClearsSource,
    userSetsSourceInspectionType,
    userSetsSourceInspectionPlan,
    userSetsSourceInspectionOrigin,
    userSetsRequiredInspection,
    userSetsScheduledInspection,
    userSetsCustomInspectionQty,
    userSetsCustomInspectionQtyIsRelative,
    userOpensSourcesModal,
    userTogglesSource,
    userCancelsSourcesModal,
    userSearchesSources,
    userSortsSources,
    userSetsSourcesPage,
    userSubmitsForm,
  };
};
export default useInspectionTypesForm;
