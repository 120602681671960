import { DEFECT_WEIGHT } from 'config/defects';
import makeReducer from 'lib/makeReducer';

export const DEFECT_FILTERS = {
  ALL: 'all',
  ERRORS: 'errors',
  CRITICAL: DEFECT_WEIGHT.CRITICAL,
  MAJOR: DEFECT_WEIGHT.MAJOR,
  MINOR: DEFECT_WEIGHT.MINOR,
  FUNCTIONAL: DEFECT_WEIGHT.FUNCTIONAL,
};
const MAX_CHARS = {
  notes: 1000,
};

const getInitialTakeDecisionModalState = () => ({
  notes: {
    value: '',
    charsLeft: MAX_CHARS['notes'],
    errors: [],
  },
  action: null,
  selectedDecision: '',
  isModalOpen: false,
  isDirty: false,
});

const INITIAL_STATE = {
  inspection: null,
  loading: true,
  errors: [],
  defectFilter: DEFECT_FILTERS.ERRORS,
  planOptions: null,
  takeDecisionModal: getInitialTakeDecisionModalState(),
  currentReInspect: '',
  currentReInspectOptions: [],
  reInspectAql: { value: null, errors: [] },
  isInspectionActionLoading: false,
  isReInspectLoading: false,
  isReInspectModalOpen: false,
  photosModal: { photos: [], initialIndex: 0 },
  isPhotosModalOpen: false,
};

export const INSPECTIONS_RESULT_ACTIONS = {
  APP_LOADS_OPTIONS: 'APP_LOADS_OPTIONS',
  APP_LOADS_INSPECTION: 'APP_LOADS_INSPECTION',
  USER_CHANGES_FILTER: 'USER_CHANGES_FILTER',
  USER_TAKES_INSPECTION_ACTION: 'USER_TAKES_INSPECTION_ACTION',
  USER_CANCELS_INSPECTION_ACTION: 'USER_CANCELS_INSPECTION_ACTION',
  USER_TYPES_INSPECTION_ACTION_NOTE: 'USER_TYPES_INSPECTION_ACTION_NOTE',
  USER_CONFIRMS_INSPECTION_ACTION: 'USER_CONFIRMS_INSPECTION_ACTION',
  USER_OPENS_RE_INSPECT_MODAL: 'USER_OPENS_RE_INSPECT_MODAL',
  USER_SETS_RE_INSPECT_AQL: 'USER_SETS_RE_INSPECT_AQL',
  USER_CLICKS_PHOTO: 'USER_CLICKS_PHOTO',
  USER_CLOSES_PHOTOS_MODAL: 'USER_CLOSES_PHOTOS_MODAL',
  USER_CANCELS_RE_INSPECT_MODAL: 'USER_CANCELS_RE_INSPECT_MODAL',
  USER_SUBMITS_RE_INSPECT: 'USER_SUBMITS_RE_INSPECT',
  APP_FINISHES_TAKING_INSPECTION_ACTION:
    'APP_FINISHES_TAKING_INSPECTION_ACTION',
  APP_SUBMITS_RE_INSPECT_SUCCESSFULLY: 'APP_SUBMITS_RE_INSPECT_SUCCESSFULLY',
  APP_FINISHES_RE_INSPECT_SUBMISSION: 'APP_FINISHES_RE_INSPECT_SUBMISSION',
  SET_ERRORS: 'SET_ERRORS',
  RESET_STATE: 'RESET_STATE',
};

const INSPECTIONS_RESULT_CONFIG = {
  [INSPECTIONS_RESULT_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      loading: false,
      errors: action.payload,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.APP_LOADS_OPTIONS]: (state, action) => {
    return {
      ...state,
      planOptions: action.payload,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.APP_LOADS_INSPECTION]: (state, action) => {
    return {
      ...state,
      inspection: action.payload,
      loading: false,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CHANGES_FILTER]: (state, action) => {
    return {
      ...state,
      defectFilter: action.payload,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_TAKES_INSPECTION_ACTION]: (
    state,
    action,
  ) => {
    return {
      ...state,
      takeDecisionModal: {
        ...state.takeDecisionModal,
        action: action.payload,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CANCELS_INSPECTION_ACTION]: (state) => {
    return {
      ...state,
      takeDecisionModal: getInitialTakeDecisionModalState(),
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_TYPES_INSPECTION_ACTION_NOTE]: (
    state,
    action,
  ) => {
    const max = MAX_CHARS['notes'];
    if (max && action.payload.length > max) {
      return state;
    }
    return {
      ...state,
      takeDecisionModal: {
        ...state.takeDecisionModal,
        notes: {
          value: action.payload,
          errors: [],
          charsLeft: max && max - action.payload.length,
        },
        isDirty: true,
      },
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CONFIRMS_INSPECTION_ACTION]: (state) => {
    return {
      ...state,
      takeDecisionModal: getInitialTakeDecisionModalState(),
      isInspectionActionLoading: true,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_OPENS_RE_INSPECT_MODAL]: (state, action) => {
    return {
      ...state,
      isReInspectModalOpen: true,
      currentReInspect: action.payload.inspectionID,
      currentReInspectOptions: action.payload.reInspectionOptions,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_SETS_RE_INSPECT_AQL]: (state, action) => {
    return {
      ...state,
      reInspectAql: { value: action.payload, errors: [] },
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CLICKS_PHOTO]: (state, action) => {
    return {
      ...state,
      isPhotosModalOpen: true,
      photosModal: {
        photos: action.payload.photos,
        initialIndex: action.payload.currentIndex,
      },
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CLOSES_PHOTOS_MODAL]: (state) => {
    return {
      ...state,
      isPhotosModalOpen: false,
      photosModal: {
        photos: [],
        initialIndex: 0,
      },
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_CANCELS_RE_INSPECT_MODAL]: (state) => {
    return {
      ...state,
      reInspectAql: { value: null, errors: [] },
      isReInspectModalOpen: false,
      currentReInspect: '',
      currentReInspectOptions: [],
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.USER_SUBMITS_RE_INSPECT]: (state) => {
    return {
      ...state,
      isReInspectLoading: true,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.APP_FINISHES_TAKING_INSPECTION_ACTION]: (
    state,
  ) => {
    return {
      ...state,
      isInspectionActionLoading: false,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.APP_SUBMITS_RE_INSPECT_SUCCESSFULLY]: (state) => {
    return {
      ...state,
      reInspectAql: { value: null, errors: [] },
      isReInspectModalOpen: false,
      currentReInspect: '',
      currentReInspectOptions: [],
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.APP_FINISHES_RE_INSPECT_SUBMISSION]: (state) => {
    return {
      ...state,
      isReInspectLoading: false,
    };
  },
  [INSPECTIONS_RESULT_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTIONS_RESULT_CONFIG,
  INITIAL_STATE,
);
