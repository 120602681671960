import { useReducer, useEffect } from 'react';
import {
  ASSETS_FORM_ACTIONS,
  initialState,
  reducer,
} from './assetsFormReducer';
import assetsService from './assetsService';
import { useDropzone } from 'react-dropzone';
import useQueryParams from 'lib/useQueryParams';
import { useContext } from 'react';
import {
  MeasurementContext,
  MEASUREMENT_ACTIONS,
} from 'modules/measurement-table/MeasurementTableContext';
import assetsFormValidator from './assetsFormValidator';
import { useMemo } from 'react';
import { assetsToFormState } from './dataTransform';
import { companyStorage } from 'lib/localStorageService';
import debounce from 'lodash.debounce';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const getAvailableOwners = debounce(
  (params, dispatch) => {
    return assetsService
      .getAssetOwners(params)
      .then((response) => {
        dispatch({
          type: ASSETS_FORM_ACTIONS.APP_LOADS_AVAILABLE_OWNERS,
          payload: {
            data: response.data,
            count: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: ASSETS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching asset owners'],
          },
        });
      });
  },
  400,
  { leading: true },
);

const getCompany = (dispatch) => {
  return assetsService
    .getCompany(companyStorage.get()?.id)
    .then((response) => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.APP_LOADS_COMPANY_ATTRIBUTES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching company attributes'],
        },
      });
    });
};

const getProductTypes = (dispatch) => {
  return assetsService
    .getProductTypes()
    .then((response) => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.APP_LOADS_TYPE_OPTIONS,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching asset product types'],
        },
      });
    });
};

const getAssetStatuses = (dispatch) => {
  return assetsService
    .getAssetStatuses()
    .then((response) => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.APP_LOADS_STATUS_OPTIONS,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: ASSETS_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching asset status options'],
        },
      });
    });
};

const getDefaultStatus = (statuses) => {
  const defaultStatus = statuses?.find((status) => status?.meaning === 'Draft');
  return defaultStatus ? defaultStatus?.value : '';
};

const useAssetsForm = (props) => {
  const { getParams } = useQueryParams(props.location);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [measureTable, measurementsDispatch] = useContext(MeasurementContext);
  const treshold = 7;

  useNavigationPrompt(!!state.isDirty);

  const id = props.match?.params?.id;

  const getAsset = (dispatch) => {
    return assetsService
      .getAssetDetail(id)
      .then((results) => {
        measurementsDispatch({
          type: MEASUREMENT_ACTIONS.SET_DATA,
          data: {
            ...results.measureTable,
            customMeasurements: results.customMeasurements,
          },
        });

        dispatch({
          type: ASSETS_FORM_ACTIONS.RESET_STATE,
          payload: assetsToFormState(state, results),
        });
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          props.history.push(`/assets`);
        } else {
          dispatch({
            type: ASSETS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        }
      });
  };

  useEffect(() => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.APP_LOADS_MEASURE_TABLE,
      payload: measureTable,
    });
  }, [
    measureTable.columnNames,
    measureTable.columnValues,
    measureTable.customMeasurements,
  ]);

  const displayImageUploadError = () => {
    const { imageUploadFailed } = getParams();
    if (imageUploadFailed) {
      dispatch({
        type: ASSETS_FORM_ACTIONS.APP_FAILS_IMAGE_UPLOAD,
      });
    }
  };

  useEffect(() => {
    Promise.all([
      getCompany(dispatch),
      getProductTypes(dispatch),
      getAssetStatuses(dispatch),
    ]);
  }, []);

  useEffect(() => {
    const { availableCompanyAttributes, statusOptions, typeOptions } = state;
    if (
      id &&
      // if state is already fetched should not override it
      !state?.id &&
      availableCompanyAttributes &&
      statusOptions.list &&
      typeOptions.list
    ) {
      getAsset(dispatch).then(displayImageUploadError);
    }
  }, [
    state.availableCompanyAttributes,
    state.statusOptions,
    state.typeOptions,
  ]);

  const { sortBy, sortOrder, search } = state.availableOwners;
  const ownersParams = {
    order: { [sortBy]: sortOrder },
    filters: { status: 'active' },
    search,
    page: state.availableOwners.page,
    pageSize: state.availableOwners.pageSize,
  };

  useEffect(() => {
    if (state.availableOwners.isModalOpen) {
      void getAvailableOwners(ownersParams, dispatch);
    }
  }, [
    state.availableOwners.sortBy,
    state.availableOwners.sortOrder,
    state.availableOwners.search,
    state.availableOwners.page,
    state.availableOwners.isModalOpen,
  ]);

  useEffect(() => {
    // Need to set the default asset status as the id is different for each company
    if (!state.status.value) {
      dispatch({
        type: ASSETS_FORM_ACTIONS.RESET_STATE,
        payload: {
          status: {
            value: getDefaultStatus(state.statusOptions.list),
            errors: [],
          },
        },
      });
    }
  }, [state.statusOptions, state.status.value]);

  const saveAsset = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SUBMITS_FORM,
    });
  };

  useEffect(() => {
    if (state.isSaving) {
      assetsFormValidator(state)
        .then((data) =>
          assetsService
            .saveAsset(data.id, data)
            .then((asset) => {
              const promises = [];
              const newImages = state.images.values.filter((i) => !i.id);
              if (newImages.length) {
                promises.push(assetsService.saveImages(asset.id, newImages));
              }
              const newDocuments = state.referenceDocuments.list.filter(
                (doc) => !doc.file.id,
              );
              if (newDocuments.length) {
                newDocuments.forEach((document) =>
                  promises.push(
                    assetsService.saveReferenceDocuments(
                      asset.id,
                      document.value,
                      document.file,
                    ),
                  ),
                );
              }
              return Promise.all(promises).then(() => asset);
            })
            .then((asset) => props.history.push(`/assets/${asset.id}`)),
        )
        .catch((e) => {
          dispatch({
            type: ASSETS_FORM_ACTIONS.RESET_STATE,
            payload: {
              ...e.payload,
              isSaving: false,
            },
          });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        });
    }
  }, [state.isSaving]);

  const dispatchInput = (key, payload) =>
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_CHANGES_INPUT,
      key,
      payload,
    });

  const userTypesName = (e) => {
    return dispatchInput('name', e.target.value);
  };

  const userTypesExternalId = (e) => {
    return dispatchInput('externalId', e.target.value);
  };

  const userTypesDescription = (e) => {
    return dispatchInput('description', e.target.value);
  };

  const isImageListChanged = () => {
    const originalList = JSON.stringify(state.initialImages);
    const newList = JSON.stringify(state.images.values);
    return originalList !== newList;
  };

  /* IMAGES */
  const onImageDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      return dispatch({
        type: ASSETS_FORM_ACTIONS.USER_ADDS_IMAGE,
        payload: {
          values: state.images.values,
          errors: [
            'Maximum allowed size for images is 5 MB and the accepted MIME types are image/jpeg, image/png.',
            'A maximum of 5 images is permitted.',
          ],
        },
      });
    }

    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_ADDS_IMAGE,
      payload: {
        values: [...state.images.values, ...acceptedFiles],
        errors: [],
      },
    });
  };

  const dropzoneConfig = {
    multiple: true,
    accept: 'image/jpeg, image/png, image/jpg',
    maxSize: 5 * 1024 * 1024,
    maxFiles: 5 - state.images.values.length,
    onDrop: onImageDrop,
  };
  const { open: openImageDropzone, getInputProps: getImageInputProps } =
    useDropzone(dropzoneConfig);
  const userClicksUploadImage = openImageDropzone;

  const userRemovesImage = (index) => {
    const values = [...state.images.values];
    values.splice(index, 1);
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_REMOVES_IMAGE,
      payload: {
        values,
        errors: [],
      },
    });
  };

  const userCancelsImageChanges = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_CANCELS_IMAGE_CHANGES,
    });
  };

  const getImagePreview = (image) => {
    return (image.path && URL.createObjectURL(image)) || image.url;
  };

  const userSelectsStatus = (status) => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SELECTS_STATUS,
      payload: status,
    });
  };
  const userSelectsProductType = (productType) => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SELECTS_PRODUCT_TYPE,
      payload: productType,
    });
  };
  const getProductType = useMemo(() => {
    return state.typeOptions.list.length > treshold
      ? state.typeOptions.list.filter(
          (type) => type.value === state.type.value,
        )[0]
      : state.type.value;
  }, [state.type.value, state.type.value?.value, state.typeOptions.list]);

  const getProductStatus = useMemo(() => {
    return state.statusOptions.list.length > treshold
      ? state.statusOptions.list.filter(
          (status) => status.value === state.status.value,
        )[0]
      : state.status.value;
  }, [state.status.value, state.status.value?.value, state.statusOptions.list]);

  const userClicksOwnerSelect = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_OPENS_OWNERS_MODAL,
    });
  };
  const userCancelsOwnersModal = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_CANCELS_OWNERS_MODAL,
    });
  };
  const userSearchesOwners = (ev) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SEARCHES_OWNERS,
      payload: ev.target.value,
    });
  };
  const userSelectsOwners = (owner) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SELECTS_OWNERS,
      payload: owner,
    });
  };
  const userSortsOwners = (col, order) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SORTS_OWNERS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };
  const userTogglesNewOwner = (owner) => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_TOGGLES_NEW_OWNER,
      payload: owner,
    });
  };
  const isOwnerListChanged = () => {
    const originalList = state.initialOwners.map((o) => o.id).sort();
    const newList = state.owners.list.map((o) => o.id).sort();
    return JSON.stringify(originalList) !== JSON.stringify(newList);
  };
  const userAssignsOwners = () => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_ASSIGNS_NEW_OWNERS,
    });
  };
  const userCancelsOwnerChanges = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_CANCELS_OWNER_CHANGES,
    });
  };
  const userSetsOwnersPage = (page) => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SETS_OWNERS_PAGE,
      payload: page,
    });
  };
  const userRemovesOwner = (owner) => {
    return dispatch({
      type: ASSETS_FORM_ACTIONS.USER_REMOVES_OWNER,
      payload: owner,
    });
  };
  const userUploadDocument = (fileArray) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_ADDS_DOCUMENT,
      payload: fileArray,
    });
  };
  const userDeletesDocument = (index) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_DELETES_DOCUMENT,
      payload: index,
    });
  };
  const userTypesDocumentName = (v, id) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_TYPES_DOCUMENT_NAME,
      payload: { value: v, id },
    });
  };
  const userAddsCustomField = () => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_ADDS_CUSTOM_FIELD,
    });
  };
  const userTypesCustomFieldName = (e, id) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_NAME,
      payload: { value: e.target.value, id },
    });
  };
  const userTypesCustomFieldValue = (e, id) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_VALUE,
      payload: { value: e.target.value, id },
    });
  };
  const userDeletesCustomField = (id) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_DELETES_CUSTOM_FIELD,
      payload: id,
    });
  };
  const userSelectsCustomAttribute = (id, e) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SELECTS_CUSTOM_ATTRIBUTE,
      payload: {
        id,
        value: e,
      },
    });
  };
  const userSelectsCompanyAttribute = (id, e) => {
    dispatch({
      type: ASSETS_FORM_ACTIONS.USER_SELECTS_COMPANY_ATTRIBUTE,
      payload: {
        id,
        value: e,
      },
    });
  };

  const getCustomAttribute = (id) => {
    return state.customAttributes.list[id]?.options.find(
      (option) => option.label === state.customAttributes.list[id].value,
    );
  };

  const getOwnersForDisplay = () =>
    state.availableOwners.list.map((owner) => ({
      ...owner,
      selected: !!state.availableOwners.selected.find((s) => s.id === owner.id),
    }));

  return {
    state,
    treshold,
    userTypesName,
    userTypesExternalId,
    userTypesDescription,
    getImageInputProps,
    getImagePreview,

    isImageListChanged,
    userCancelsImageChanges,
    userRemovesImage,
    userClicksUploadImage,

    userSelectsProductType,
    getProductType,

    userSelectsStatus,
    getProductStatus,

    getCustomAttribute,

    isOwnerListChanged,
    getOwnersForDisplay,
    userClicksOwnerSelect,
    userCancelsOwnersModal,
    userSearchesOwners,
    userSelectsOwners,
    userSortsOwners,
    userTogglesNewOwner,
    userCancelsOwnerChanges,
    userAssignsOwners,
    userRemovesOwner,
    userSetsOwnersPage,

    userUploadDocument,
    userDeletesDocument,
    userTypesDocumentName,

    userAddsCustomField,
    userTypesCustomFieldName,
    userTypesCustomFieldValue,
    userDeletesCustomField,

    userSelectsCustomAttribute,
    userSelectsCompanyAttribute,

    saveAsset,
  };
};

export default useAssetsForm;
