import { cloneDeep } from 'lodash';
import makeReducer from 'lib/makeReducer';

export const MAX_FIELD_CHARS = {
  name: 50,
  about: 255,
};

const INITIAL_CONTACT_FIELD = {
  value: '',
  errors: [],
};

const INITIAL_FORM_STATE = {
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  status: {
    value: 'active',
    errors: [],
  },
  about: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.about,
  },
  address: {
    value: '',
    errors: [],
  },
  city: {
    value: '',
    errors: [],
  },
  zipCode: {
    value: '',
    errors: [],
  },
  state: {
    value: '',
    errors: [],
  },
  country: {
    value: null,
    errors: [],
  },
  phones: [{ ...INITIAL_CONTACT_FIELD }],
  emails: [{ ...INITIAL_CONTACT_FIELD }],
  adminName: {
    value: '',
    errors: [],
  },
  adminEmail: {
    value: '',
    errors: [],
  },
  customConfig: {
    value: '',
    errors: [],
  },
  errors: [],
  loading: false,
  id: null,
  isDirty: false,
};

export const COMPANIES_FORM_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_SETS_COUNTRY: 'USER_SETS_COUNTRY',
  USER_ADDS_PHONE: 'USER_ADDS_PHONE',
  USER_ADDS_EMAIL: 'USER_ADDS_EMAIL',
  USER_REMOVES_PHONE: 'USER_REMOVES_PHONE',
  USER_REMOVES_EMAIL: 'USER_REMOVES_EMAIL',
  USER_TYPES_PHONE: 'USER_TYPES_PHONE',
  USER_TYPES_EMAIL: 'USER_TYPES_EMAIL',
  INITIAL_STATE: 'INITIAL_STATE',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
};

const setValue = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: max && max - action.payload.length,
    },
    isDirty: true,
  };
};
const removeArrayValue = (key, state, action) => {
  const newValues = cloneDeep(state[key]);
  newValues.splice(action.payload, 1);

  return {
    ...state,
    [key]: newValues,
    isDirty: true,
  };
};

const addContactField = (state, key) => {
  const newValues = cloneDeep(state[key]);
  newValues.push({ ...INITIAL_CONTACT_FIELD });

  return {
    ...state,
    [key]: newValues,
  };
};

const userTypesInArrayObject = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.value.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: [
      ...state[key].map((element, index) => {
        if (index === action.payload.index) {
          return {
            ...element,
            value: action.payload.value,
            errors: [],
            charsLeft: max && max - action.payload.value.length,
          };
        }

        return {
          ...element,
        };
      }),
    ],
    isDirty: true,
  };
};

const COMPANIES_FORM_REDUCER_CONFIG = {
  [COMPANIES_FORM_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return setValue(action.key, state, action);
  },
  [COMPANIES_FORM_ACTIONS.USER_REMOVES_PHONE]: (state, action) => {
    return removeArrayValue('phones', state, action);
  },
  [COMPANIES_FORM_ACTIONS.USER_REMOVES_EMAIL]: (state, action) => {
    return removeArrayValue('emails', state, action);
  },
  [COMPANIES_FORM_ACTIONS.USER_ADDS_PHONE]: (state) => {
    return addContactField(state, 'phones');
  },
  [COMPANIES_FORM_ACTIONS.USER_ADDS_EMAIL]: (state) => {
    return addContactField(state, 'emails');
  },
  [COMPANIES_FORM_ACTIONS.USER_TYPES_PHONE]: (state, action) => {
    return userTypesInArrayObject('phones', state, action);
  },
  [COMPANIES_FORM_ACTIONS.USER_TYPES_EMAIL]: (state, action) => {
    return userTypesInArrayObject('emails', state, action);
  },
  [COMPANIES_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return { ...state, loading: true, isDirty: false };
  },
  [COMPANIES_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [COMPANIES_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [COMPANIES_FORM_ACTIONS.INITIAL_STATE]: () => {
    return { ...INITIAL_FORM_STATE };
  },
};

export const { reducer, initialState } = makeReducer(
  COMPANIES_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
