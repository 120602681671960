import { useState } from 'react';

const useCsvImport = () => {
  const [done, setDone] = useState(null);
  const [globalError, setGlobalError] = useState(null);
  const [errors, setErrors] = useState(null);
  const [timestamps, setTimestamps] = useState(null);
  const [file, setFile] = useState(null);

  function uploadInfo(data) {
    if (data) {
      if (data.errors) {
        if (Array.isArray(data.errors)) {
          setErrors(data.errors);
        } else {
          setErrors([data.errors]);
        }
      } else {
        setErrors(null);
      }

      if (data.done) {
        setDone(data.done);
      } else {
        setDone(null);
      }

      if (data.file) {
        setFile(data.file);
      } else {
        setFile(null);
      }

      if (data.globalError) {
        setGlobalError(data.globalError);
      } else {
        setGlobalError(null);
      }
      setTimestamps(data.timestamps);
    } else {
      setDone(null);
      setErrors(null);
      setGlobalError(null);
      setTimestamps(null);
    }
  }

  const hasErrors = errors?.length > 0 || globalError;

  return { uploadInfo, done, hasErrors, globalError, errors, timestamps, file };
};

export default useCsvImport;
