import useUsersForm from '../../useUsersForm';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import Button from 'lib/components/button/Button';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import AddButton from 'lib/components/add-button/AddButton';
import EditButton from 'lib/components/edit-button/EditButton';
import Radio from 'lib/components/radio/Radio';
import Avatar, { AVATAR_SIZES } from 'lib/components/avatar/Avatar';
import UserStatusSelect from '../user-status-select/UserStatusSelect';

import DeleteIcon from 'assets/images/trash.png';

import './UsersForm.scss';
import ROLES from 'config/roles';

const PERFORM_WEB_INSPECTIONS_OPTIONS = [
  {
    label: 'Disabled',
    value: false,
  },
  {
    label: 'Enabled',
    value: true,
  },
];

const UsersForm = (props) => {
  const vm = useUsersForm(props);
  const SubmitButton = vm.state.id ? EditButton : AddButton;
  const submitText = vm.state.id ? 'Save changes' : 'Create user';

  const isRolesInputVisible = !vm.isOwnProfile;
  const isStatusInputVisible = !vm.isOwnProfile;
  const isWebInspectionInputVisible =
    !vm.isOwnProfile &&
    vm.state.roles?.value.some((role) => role.value === ROLES.INSPECTOR);
  const isTagsInputVisible = vm.canShowTags();

  return (
    <div className='users-form'>
      <form noValidate>
        <div className='form-row name-input-container'>
          <Input
            name='name'
            type='text'
            charsLeft={vm.state.name.charsLeft}
            label='Name'
            value={vm.state.name.value}
            errors={vm.state.name.errors}
            autoComplete='off'
            onChange={vm.userTypesName}
            data-testid='UsersForm.name'
          />
        </div>
        <div className='form-row photo-input-container'>
          <p className='form-label'>Profile Image</p>
          <input {...vm.getInputProps()} />
          <div className='photo-controls'>
            <Avatar
              size={AVATAR_SIZES.XL}
              url={vm.getPreview()}
              placeholder={vm.state.name.value}
              data-testid='UsersForm.profileImage'
            />

            <span
              className={`remove-photo-button ${
                !vm.getPreview() ? 'hidden' : ''
              }`}
              onClick={vm.userRemovesPhoto}
            ></span>
            <Button
              type='button'
              onClick={vm.userPressesChangePhoto}
              className='medium-button'
              data-testid='UsersForm.changePhoto'
            >
              Change
            </Button>

            {vm.state.id && vm.photoIsDifferent() && (
              <Button
                type='button'
                onClick={vm.userPressesCancelChangePhoto}
                className='medium-button'
                data-testid='UsersForm.cancelChangePhoto'
              >
                Cancel
              </Button>
            )}
          </div>

          <ErrorBag errors={vm.state.photo.errors} />
        </div>
        {isRolesInputVisible && (
          <div className='form-row roles-select-container'>
            <Select
              isMulti={true}
              isClearable={false}
              isSearchable={false}
              value={vm.state.roles.value}
              onChange={vm.userSetsRoles}
              placeholder={''}
              name='roles'
              label='Roles'
              options={vm.rolesOptions}
              errors={vm.state.roles.errors}
              data-testid='UsersForm.roles'
            />
            <p className='field-description'>
              Roles determine what type of access a user gets in the Exacture
              platform.
            </p>
          </div>
        )}
        {isWebInspectionInputVisible && (
          <div className='form-row perform-web-inspections-container'>
            <Radio
              label='Perform inspections online'
              options={PERFORM_WEB_INSPECTIONS_OPTIONS}
              value={vm.state.canPerformWebAppInspections}
              onChange={vm.userSetsPerformInspections}
              data-testid='UsersForm.performInspections'
            />
            <p className='field-description'>
              Enable to allow this user to perform inspections using the
              Exacture web interface
            </p>
          </div>
        )}
        {isTagsInputVisible && (
          <div className='form-row tags-select-container'>
            <Select
              isMulti={true}
              isClearable={false}
              isCreatable={true}
              isSearchable={true}
              value={vm.state.tags.value}
              onChange={vm.userSetsTags}
              placeholder={''}
              onInputChange={(inputValue) =>
                inputValue.length <= 50 ? inputValue : inputValue.substr(0, 50)
              }
              name='tags'
              label='Tags'
              options={vm.tagsOptions}
              errors={vm.state.tags.errors}
              data-testid='UsersForm.tags'
            />

            <p className='field-description'>
              Tags are used to tie one or more sources to one or more users.
            </p>
            {!vm.state.tags.value.length && (
              <p className='field-description'>
                An un-tagged user will be linked with all sources and other
                inspectors, coordinators and vendors
              </p>
            )}
          </div>
        )}

        <div className='form-row'>
          <Input
            label='City'
            name='city'
            type='text'
            value={vm.state.city.value}
            errors={vm.state.city.errors}
            autoComplete='off'
            onChange={vm.userTypesCity}
            data-testid='UsersForm.city'
          />
        </div>
        <div className='form-row country-select-container'>
          <Select
            name='country'
            label='Country'
            value={vm.state.country.value}
            errors={vm.state.country.errors}
            options={vm.countryOptions}
            isClearable={true}
            onChange={vm.userSetsCountry}
            data-testid='UsersForm.country'
          />
        </div>
        <div className='form-row position-input-container'>
          <Input
            name='position'
            type='text'
            charsLeft={vm.state.position.charsLeft}
            label='Position'
            value={vm.state.position.value}
            errors={vm.state.position.errors}
            onChange={vm.userTypesPosition}
            autoComplete='off'
            data-testid='UsersForm.position'
          />
          <p className='field-description'>
            The position this user has within the company is only informational
            data.
          </p>
        </div>

        {isStatusInputVisible && (
          <div className='form-row status-input-container'>
            <UserStatusSelect
              options={vm.getStatuses()}
              value={vm.state.status}
              onChange={vm.userSetsStatus}
              isSearchable={false}
              menuPlacement='auto'
              label='Status'
              className='status-select'
              data-testid='UsersForm.status'
            />
            <p className='field-description'>
              If the status is Draft, you can only change the status to
              Deactivated.
            </p>
            <br />
            <p className='field-description'>
              If the status is Deactivated, you can only change status to Draft.
              This will trigger the system to send the password reset
              instructions email to the user.
            </p>
            <br />
            <p className='field-description'>
              If the status is Active, you can only change status to Deactivated
              and thus removing the possibility for the user to log in with set
              credentials.
            </p>
          </div>
        )}
        <div className='form-row company-input-container'>
          <Input
            name='company'
            type='text'
            charsLeft={vm.state.company.charsLeft}
            label='Company'
            value={vm.state.company.value}
            errors={vm.state.company.errors}
            onChange={vm.userTypesCompany}
            autoComplete='off'
            data-testid='UsersForm.company'
          />
          <p className='field-description'>
            The company this user works for is only informational data.
          </p>
        </div>
        <div className='form-row emails-container'>
          {vm.state.emails.map((email, index) => {
            return (
              <div key={index} className='multi-value-field'>
                <Input
                  name={`email-${index}`}
                  type='email'
                  value={email.value}
                  errors={email.errors}
                  onChange={(e) => vm.userTypesEmail(e, index)}
                  autoComplete='off'
                  label='Email'
                  data-testid={`UsersForm.email${index}`}
                />
                {index !== 0 && (
                  <button
                    type='button'
                    className='secondary trash-button'
                    onClick={() => vm.userRemovesEmailField(index)}
                    data-testid={`UsersForm.removeEmail${index}`}
                  >
                    <img src={DeleteIcon} alt='delete' />
                    Delete
                  </button>
                )}
              </div>
            );
          })}
          {vm.state.emails.length < 4 && (
            <Button
              type='button'
              className='medium-button'
              onClick={vm.userAddsEmailField}
              data-testid='UsersForm.addEmail'
            >
              Add another email
            </Button>
          )}
        </div>
        <div className='form-row phones-container'>
          {vm.state.phones.map((phone, index) => {
            return (
              <div key={index} className='multi-value-field'>
                <Input
                  name={`phone-${index}`}
                  type='text'
                  value={phone.value}
                  errors={phone.errors}
                  onChange={(e) => vm.userTypesPhone(e, index)}
                  autoComplete='off'
                  label='Phone'
                  data-testid={`UsersForm.phone${index}`}
                />
                {index !== 0 && (
                  <button
                    type='button'
                    className='secondary trash-button'
                    onClick={() => vm.userRemovesPhoneField(index)}
                    data-testid={`UsersForm.removePhone${index}`}
                  >
                    <img src={DeleteIcon} alt='delete' />
                    Delete
                  </button>
                )}
              </div>
            );
          })}
          {vm.state.phones.length < 5 && (
            <Button
              type='button'
              className='medium-button'
              onClick={vm.userAddsPhoneField}
              data-testid='UsersForm.addPhone'
            >
              Add another phone
            </Button>
          )}
        </div>
        <div className='form-row submit'>
          <ErrorBag errors={vm.state.errors} />

          <SubmitButton
            disabled={vm.state.loading}
            onClick={vm.userSubmitsForm}
            data-testid='UsersForm.submit'
          >
            {submitText}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

export default UsersForm;
