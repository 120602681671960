import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import ROLES from 'config/roles';

import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';

import './Menu.scss';

function Menu({ items }) {
  return (
    <div className='menuContainer'>
      {items.map((menu) => {
        const classes = classNames('menuButton', {
          disabled: menu.disabled,
        });

        // if allowed is not defined, all roles are allowed
        const allowed = menu.allowed || Object.values(ROLES);

        return (
          <ProtectedComponent allowed={allowed} key={menu.label}>
            <NavLink
              key={menu.label}
              className={classes}
              activeClassName='selected'
              to={menu.link}
              exact={menu.link === '/'}
            >
              {menu.label}
            </NavLink>
          </ProtectedComponent>
        );
      })}
    </div>
  );
}

export default withRouter(Menu);
