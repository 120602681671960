import React from 'react';
import './index.scss';
import './App.scss';
import { INITIAL_APP_STATE } from './appReducer';
import useApp from './useApp';
import AdminPanel from 'modules/admin/components/admin-panel/AdminPanel';
import AuthPanel from 'modules/auth/components/auth-panel/AuthPanel';
import UnsubscribeRoutes from 'modules/unsubscribe/routes';
import { ConfirmationContextProvider } from 'lib/components/useConfirm/ConfirmationContextProvider';
import useDebugViews from 'useDebugViews';

export const AppContext = React.createContext({
  appState: INITIAL_APP_STATE,
});

AppContext.displayName = 'AppContext';

function App() {
  const { appState, isLoggedIn, isUnsubscribeLink, logout } = useApp();
  const { debugViewsList } = useDebugViews();

  return (
    <AppContext.Provider value={{ appState, isLoggedIn, logout }}>
      <ConfirmationContextProvider>
        <div className='App'>
          {isLoggedIn() ? (
            <AdminPanel />
          ) : isUnsubscribeLink ? (
            <UnsubscribeRoutes />
          ) : (
            <AuthPanel />
          )}
          {!!debugViewsList?.length && (
            <div className='debug-views-notice'>
              Debug views ON:{' '}
              {debugViewsList.map((v, idx) => `${idx !== 0 ? ', ' : ''}${v}`)}
            </div>
          )}
        </div>
      </ConfirmationContextProvider>
    </AppContext.Provider>
  );
}

export default App;
