import { Route } from 'react-router';
import ProtectedComponent from '../protected-component/ProtectedComponent';

const ProtectedRoute = ({ allowed, ...props }) => {
  const renderNotAllowed = () => (
    <Route
      path={props.path}
      render={({ history }) => {
        history.push('/');
        return null;
      }}
    />
  );

  return (
    <ProtectedComponent allowed={allowed} renderNotAllowed={renderNotAllowed}>
      <Route {...props} />
    </ProtectedComponent>
  );
};

export default ProtectedRoute;
