import makeReducer from 'lib/makeReducer';

export const SOURCES_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  APP_LOADS_RELATED_WORK_OBJECTS: 'APP_LOADS_RELATED_WORK_OBJECTS',
  APP_LOADS_RELATED_INSPECTIONS: 'APP_LOADS_RELATED_INSPECTIONS',
  APP_LOADS_RELATED_INSPECTION_PLANS: 'APP_LOADS_RELATED_INSPECTION_PLANS',
  APP_LOADS_VALID_INSPECTIONS: 'APP_LOADS_VALID_INSPECTIONS',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_DETAIL_STATE = {
  source: false,
  workObjects: { count: 0, data: [] },
  inspections: { count: 0, data: [] },
  inspectionPlans: { count: null, data: [] },
  errors: [],
  loading: true,
};

const SOURCES_DETAIL_REDUCER_CONFIG = {
  [SOURCES_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      source: action.payload,
      loading: false,
    };
  },
  [SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS]: (state, action) => {
    return {
      ...state,
      workObjects: action.payload,
    };
  },
  [SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTIONS]: (state, action) => {
    return {
      ...state,
      inspections: action.payload,
    };
  },
  [SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTION_PLANS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      inspectionPlans: action.payload,
    };
  },
  [SOURCES_DETAIL_ACTIONS.APP_LOADS_VALID_INSPECTIONS]: (state, action) => {
    return {
      ...state,
      validInspections: action.payload,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  SOURCES_DETAIL_REDUCER_CONFIG,
  INITIAL_DETAIL_STATE,
);
