import { cloneDeep } from 'lodash';
import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';

import { inspectionToResultState } from './dataTransform';
import {
  DEFECT_FILTERS,
  INSPECTIONS_RESULT_ACTIONS,
  initialState,
  reducer,
} from './inspectionsResultReducer';
import { inspectionReInspectValidator } from './inspectionsResultValidator';
import inspectionsService from './inspectionsService';

const questionToDefectWeights = (question) =>
  question.actualDefects.map(
    (defect) =>
      defect.weight ||
      question.defects.find((d) => d.id === defect.defectId)?.weight,
  );

const FILTER_CONFIG = {
  [DEFECT_FILTERS.ALL]: () => true,
  [DEFECT_FILTERS.ERRORS]: (question) =>
    question.actualErrorWeight !== null ||
    questionToDefectWeights(question).length,
  [DEFECT_FILTERS.CRITICAL]: (question) =>
    question.actualErrorWeight === 'critical' ||
    questionToDefectWeights(question).includes('critical'),
  [DEFECT_FILTERS.MAJOR]: (question) =>
    question.actualErrorWeight === 'major' ||
    questionToDefectWeights(question).includes('major'),
  [DEFECT_FILTERS.MINOR]: (question) =>
    question.actualErrorWeight === 'minor' ||
    questionToDefectWeights(question).includes('minor'),
  [DEFECT_FILTERS.FUNCTIONAL]: (question) =>
    question.actualErrorWeight === 'functional' ||
    questionToDefectWeights(question).includes('functional'),
};

const inspectionParams = {
  select: [
    'id',
    'parentInspectionId',
    'inspectionDate',
    'finishedAt',
    'externalId',
    'showId',
    'validityValue',
    'validityRange',
    'validUntil',
    'aqlLevel',
    'criticalDefectValue',
    'criticalDefectRule',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'status',
    'result',
    'workObjectId',
    'majorDefectsCountAll',
    'minorDefectsCountAll',
    'functionalDefectsCountAll',
    'criticalDefectsCountAll',
    'minorDefectAllowed',
    'majorDefectAllowed',
    'functionalDefectAllowed',
    'criticalDefectAllowed',
    'summary',
    'isThirdParty',
    'partialQuantity',
    'quantityUOM',
    'quantity',
  ],
  relations: [
    'asset.images',
    'asset.customMeasurements',
    'source',
    'locationSource',
    'inspectionPlan',
    'inspector',
    'inspectionType',
    'inspectionType.usageDecisions',
    'questionGroups',
    'questionGroups.questions.customExpectedUom',
    'actualDecisions',
    'usageDecisions',
    'stepPair.partialStep.partialWorkObject.parentWorkObject.vendor',
    'inProgressAssetSnapshot',
    'inProgressAssetSnapshot.customMeasurements',
    'woCustomFields',
  ],
};
function fetchInspection(id, options, dispatch) {
  return inspectionsService
    .getInspection(id, inspectionParams)
    .then((inspection) =>
      dispatch({
        type: INSPECTIONS_RESULT_ACTIONS.APP_LOADS_INSPECTION,
        payload: inspectionToResultState(inspection, options),
      }),
    )
    .catch(() =>
      dispatch({
        type: INSPECTIONS_RESULT_ACTIONS.SET_ERRORS,
        payload: ['Could not load inspection results.'],
      }),
    );
}

function fetchPlanOptions(dispatch) {
  return inspectionsService
    .getInspectionPlanOptions()
    .then((options) =>
      dispatch({
        type: INSPECTIONS_RESULT_ACTIONS.APP_LOADS_OPTIONS,
        payload: options,
      }),
    )
    .catch(() =>
      dispatch({
        type: INSPECTIONS_RESULT_ACTIONS.SET_ERRORS,
        payload: ['Could not load inspection plan options.'],
      }),
    );
}

export default function useInspectionsResult({ id }) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id) {
      fetchPlanOptions(dispatch);
    }
  }, [id]);

  useEffect(() => {
    if (
      state.planOptions &&
      (!state.isReInspectLoading || !state.isInspectionActionLoading)
    ) {
      fetchInspection(id, state.planOptions, dispatch);
    }
  }, [
    state.planOptions,
    state.isReInspectLoading,
    state.isInspectionActionLoading,
  ]);

  const filterOptions = [
    {
      label: 'All',
      value: DEFECT_FILTERS.ALL,
    },
    {
      label: 'All errors',
      value: DEFECT_FILTERS.ERRORS,
    },
    {
      label: 'Critical',
      value: DEFECT_FILTERS.CRITICAL,
      disabled: !state.inspection?.criticalDefectsCountAll,
    },
    {
      label: 'Major',
      value: DEFECT_FILTERS.MAJOR,
      disabled: !state.inspection?.majorDefectsCountAll,
    },
    {
      label: 'Minor',
      value: DEFECT_FILTERS.MINOR,
      disabled: !state.inspection?.minorDefectsCountAll,
    },
    {
      label: 'Functional',
      value: DEFECT_FILTERS.FUNCTIONAL,
      disabled: !state.inspection?.functionalDefectsCountAll,
    },
  ];

  const userChangesFilter = (value) => {
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_CHANGES_FILTER,
      payload: value,
    });
  };

  const getDisplayQuestionGroups = () => {
    return state.inspection.questionGroups
      .map((group) => ({
        ...group,
        questions: group.questions
          .filter(FILTER_CONFIG[state.defectFilter])
          .sort((a, b) => a.order - b.order),
      }))
      .filter((group) => group.questions.length)
      .sort((a, b) => a.order - b.order);
  };

  function downloadResponse(fileName = 'report.csv') {
    return (response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  }

  const userClicksPrint = () => {
    history.push(`/inspections/${state.inspection.id}/results/print`);
  };

  const userClicksDownloadCSV = () => {
    inspectionsService
      .getReport(state.inspection.id, 'csv')
      .then(downloadResponse(`Inspection-${id}.csv`))
      .catch(() => {
        dispatch({
          type: INSPECTIONS_RESULT_ACTIONS.SET_ERRORS,
          payload: [
            'An error occured while downloading the CSV report. Please try again later.',
          ],
        });
      });
  };

  const userClicksDownloadCAP = () => {
    inspectionsService
      .getCAP(state.inspection.id)
      .then(downloadResponse(`Corrective-Action-Form-${id}.xlsx`))
      .catch(() => {
        dispatch({
          type: INSPECTIONS_RESULT_ACTIONS.SET_ERRORS,
          payload: [
            'An error occured while downloading the Corrective Action Form. Please try again later.',
          ],
        });
      });
  };

  const userTakesInspectionAction = (action) => {
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_TAKES_INSPECTION_ACTION,
      payload: action,
    });
  };

  const userCancelsInspectionAction = (action) => {
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_CANCELS_INSPECTION_ACTION,
      payload: action,
    });
  };

  const userOpensReInspectModal = (inspectionId) => {
    return dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_OPENS_RE_INSPECT_MODAL,
      payload: inspectionId,
    });
  };

  const userCancelsReInspectModal = () => {
    return dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_CANCELS_RE_INSPECT_MODAL,
    });
  };

  const userSubmitsReInspect = (e) => {
    e.preventDefault();
    return dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_SUBMITS_RE_INSPECT,
    });
  };

  const userTypesActionNote = (ev) => {
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_TYPES_INSPECTION_ACTION_NOTE,
      payload: ev.target.value,
    });
  };

  const userSetsReInspectAql = (aql) =>
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_SETS_RE_INSPECT_AQL,
      payload: aql,
    });

  const userClicksPhoto = (photos, currentIndex) =>
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_CLICKS_PHOTO,
      payload: { photos, currentIndex },
    });

  const userClosesPhotosModal = () =>
    dispatch({
      type: INSPECTIONS_RESULT_ACTIONS.USER_CLOSES_PHOTOS_MODAL,
    });

  const userConfirmsInspectionAction = () => {
    const { notes, action } = state.takeDecisionModal;
    inspectionsService
      .performInspectionAction(action, notes.value)
      .then(() =>
        dispatch({
          type: INSPECTIONS_RESULT_ACTIONS.USER_CONFIRMS_INSPECTION_ACTION,
        }),
      )
      .finally(() =>
        dispatch({
          type: INSPECTIONS_RESULT_ACTIONS.APP_FINISHES_TAKING_INSPECTION_ACTION,
        }),
      );
  };

  useEffect(() => {
    if (state.isReInspectLoading) {
      const stateClone = cloneDeep(state);
      inspectionReInspectValidator(stateClone)
        .then((data) =>
          inspectionsService.performInspectionAction({
            inspectionId: data.currentReInspect,
            id: data.reInspectAql.value?.value,
          }),
        )
        .then(() =>
          dispatch({
            type: INSPECTIONS_RESULT_ACTIONS.APP_SUBMITS_RE_INSPECT_SUCCESSFULLY,
          }),
        )
        .catch((e) => {
          dispatch({
            type: INSPECTIONS_RESULT_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: INSPECTIONS_RESULT_ACTIONS.APP_FINISHES_RE_INSPECT_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.isReInspectLoading]);

  return {
    state,
    filterOptions,
    userChangesFilter,
    getDisplayQuestionGroups,
    userClicksDownloadCSV,
    userClicksDownloadCAP,
    userClicksPrint,
    userTakesInspectionAction,
    userCancelsInspectionAction,
    userOpensReInspectModal,
    userCancelsReInspectModal,
    userSubmitsReInspect,
    userTypesActionNote,
    userSetsReInspectAql,
    userClicksPhoto,
    userClosesPhotosModal,
    userConfirmsInspectionAction,
  };
}
