import React from 'react';
import classNames from 'classnames';
import { parseISO } from 'date-fns';

import INSPECTION_STATUS, {
  INSPECTION_RESULT,
  getInspectionStatusLabel,
} from 'config/inspectionStatus';
import { INSPECTOR_TYPE } from 'config/inspectionConfig';

import Checkbox from 'lib/components/checkbox/Checkbox';
import InspectionsResultPill from 'lib/components/inspections-result-pill/InspectionsResultPill';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import Table from 'lib/components/table/Table';
import ThirdPartyPill from '../third-party-pill/ThirdPartyPill';
import Tooltip from '../tooltip/Tooltip';

import {
  castDateTimeToSimpleDate,
  formatDate,
  formatDuration,
} from 'lib/dateHelpers';

import './InspectionsTable.scss';
import { Link } from 'react-router-dom';
import StatusPill, { STATUS_PILL_VARIANT } from '../status-pill/StatusPill';

const InspectionsTable = ({
  userClicksOnRow,
  userChecksRow,
  userChecksHeader,
  selectedRows = [],
  disabledRows = [],
  selectable = false,
  className,
  actions,
  overflowX = false,
  ...props
}) => {
  const isHeaderChecked =
    selectedRows.length && selectedRows.length === (props.data || []).length;
  const isPartiallyChecked = selectedRows.length && !isHeaderChecked;

  const inspectionsListColumns = [
    {
      title: (
        <Checkbox
          indeterminate={isPartiallyChecked}
          checked={isHeaderChecked}
          onChange={userChecksHeader}
          className='outline'
        />
      ),
      className: 'select-cell',
      headerClassName: 'select-cell',
      render: (record) => {
        const isDisabled = (disabledRows || []).includes(record.id);
        const readableStatus = getInspectionStatusLabel(
          record.statusOrResult,
        ).toLowerCase();
        const tooltipText = `Cannot edit ${readableStatus} inspection`;
        const checkbox = (
          <Checkbox
            disabled={isDisabled}
            checked={(selectedRows || []).includes(record.id)}
            onChange={() => userChecksRow(record)}
            className='outline'
          />
        );

        return (
          <div
            className='checkbox-wrapper'
            onClick={(ev) => ev.stopPropagation()}
          >
            {isDisabled ? (
              <Tooltip placement='top' overlay={tooltipText}>
                {checkbox}
              </Tooltip>
            ) : (
              checkbox
            )}
          </div>
        );
      },
    },
    {
      title: 'ID',
      sortId: 'id',
      className: 'id-cell',
      render: (record) => {
        return (
          <Link
            to={`/inspections/${record.id}${
              record.status === INSPECTION_STATUS.FINISHED &&
              !record.isThirdParty
                ? '/results'
                : ''
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            {record.id}
          </Link>
        );
      },
    },
    {
      key: 'target',
      title: 'Name',
      sortId: 'name',
      className: 'name-cell',

      render: (record) =>
        record.asset ? (
          <div>
            <div className='primary-text'>{record['asset.name']}</div>
            <div className='secondary-text'>
              {record['asset.externalId'] ? (
                <>Ext. ID: {record['asset.externalId']}</>
              ) : record['assetId'] ? (
                <>ID: {record['assetId']}</>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <div className='primary-text'>{record['source.name']}</div>
            <div className='secondary-text'>
              {record['source.externalId'] ? (
                <>Ext. ID: {record['source.externalId']}</>
              ) : record['sourceId'] ? (
                <>ID: {record['sourceId']}</>
              ) : null}
            </div>
          </div>
        ),
    },
    {
      key: 'type',
      sortId: 'inspectionType.type',
      title: 'Type',
      className: 'type-cell',
      render: (record) => (record.asset ? 'Asset' : 'Source'),
    },
    {
      key: 'location',
      sortId: ['source.name'],
      title: 'Source / External ID',
      className: 'location-cell',
      render: (record) =>
        record.asset ? (
          <div>
            <div className='primary-text'>
              {record.locationSource?.name ?? ''}
            </div>
            <div className='secondary-text'>
              {record.locationSource?.externalId ?? '-'}
            </div>
          </div>
        ) : (
          <div>
            <div className='primary-text'>{record.source?.name ?? ''}</div>
            <div className='secondary-text'>
              {record.source?.externalId ?? '-'}
            </div>
          </div>
        ),
    },
    {
      key: 'work-object',
      sortId: 'workObjectExternalId',
      title: ['Work Object', 'Deadline'],
      className: 'work-object-cell',
      render: (record) => (
        <>
          <div className='primary-text'>
            {record.workObjectExternalId ?? ''}
          </div>
          {record.workObjectDeadline && (
            <div className='secondary-text'>
              {formatDate(record.workObjectDeadline)}
            </div>
          )}
        </>
      ),
    },
    {
      title: ['Inspection', 'Date'],
      sortId: ['inspectionType.name', 'lastInspectionDate'],
      textAlign: 'left',
      render: (record) => (
        <div>
          <div className='primary-text'>{record.inspectionType.name}</div>
          <div className='secondary-text'>
            {!!record.finishedAt
              ? formatDate(record.finishedAt)
              : formatDate(record.inspectionDate)}
            {record.isThirdParty && <ThirdPartyPill />}
          </div>
          <div className='secondary-text reinspecton-label'>
            {!!record.parentInspectionId && (
              <StatusPill variant={STATUS_PILL_VARIANT.WARNING}>
                Reinspection
              </StatusPill>
            )}
          </div>
        </div>
      ),
    },
    {
      title: ['Inspection window start / end'],
      sortId: 'inspectionWindowStartDate',
      hideByDefault: true,
      key: 'inspection-window',
      textAlign: 'center',
      render: (record) =>
        record.inspectionWindowStartDate && record.inspectionWindowEndDate ? (
          <div>
            <div className='primary-text'>
              {formatDate(
                castDateTimeToSimpleDate(record.inspectionWindowStartDate),
              )}
            </div>
            <div className='primary-text'>
              {formatDate(
                castDateTimeToSimpleDate(record.inspectionWindowEndDate),
              )}
            </div>
          </div>
        ) : (
          '-'
        ),
    },
    {
      key: 'inspection-plan',
      hideByDefault: true,
      title: 'Inspection plan',
      className: 'inspection-plan-cell',
      headerClassName: 'inspection-plan-cell',
      render: (record) =>
        record.isThirdParty ? 'Third party' : record.inspectionPlan?.name,
    },
    {
      key: 'valid-until',
      hideByDefault: true,
      title: 'Valid until',
      className: 'valid-until-cell',
      headerClassName: 'valid-until-cell',
      render: (record) =>
        record.validUntil ? formatDate(parseISO(record.validUntil)) : '',
    },
    {
      title: 'Inspector',
      sortId: 'inspector.name',
      className: 'avatar-container',
      textAlign: 'left',
      render: (record) => {
        if (record.inspector) {
          return <NamedAvatar user={record.inspector} />;
        }
        if (
          record.inspectorType === INSPECTOR_TYPE.EXTERNAL &&
          record.thirdPartyInspectorName &&
          record.thirdPartyInspectingCompany
        ) {
          return (
            <NamedAvatar
              user={{
                name: record.thirdPartyInspectorName,
                companyName: (
                  <>
                    {record.thirdPartyInspectingCompany}
                    <ThirdPartyPill />
                  </>
                ),
              }}
            />
          );
        }
        return <div className='unassigned'>Unassigned</div>;
      },
    },
    {
      key: 'result',
      title: 'Result',
      sortId: 'statusOrResult',
      textAlign: 'right',
      className: 'result-cell',
      headerClassName: 'result-cell',
      render: (record) => {
        const isResultPillVisible = Object.values(INSPECTION_RESULT).includes(
          record.statusOrResult,
        );
        return (
          <span className='pill-container'>
            {isResultPillVisible && (
              <InspectionsResultPill status={record.statusOrResult} />
            )}
          </span>
        );
      },
    },
    {
      key: 'decision-point',
      hideByDefault: true,
      title: 'Current decision point',
      textAlign: 'right',
      className: 'decision-point-cell',
      headerClassName: 'decision-point-cell',
      render: (record) => record.currentDecisionPoint,
    },
    {
      title: 'Time per unit inspected',
      textAlign: 'right',
      className: 'time-per-unit-cell',
      headerClassName: 'time-per-unit-cell',
      render: (record) => formatDuration(record.timePerUnit),
    },
    {
      title: 'Last Taken Decision',
      textAlign: 'left',
      className: 'last-taken-decision-cell',
      headerClassName: 'last-taken-decision-cell',
      render: (record) => <>{record.lastUsageDecision}</>,
    },
  ];

  return (
    <Table
      columns={inspectionsListColumns}
      onRowClick={userClicksOnRow}
      overflowX={overflowX}
      actions={actions}
      className={classNames('inspections-table', className, {
        selectable,
        'selected-within': selectedRows.length,
      })}
      {...props}
    />
  );
};

export default InspectionsTable;
