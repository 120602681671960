import { formatDateTime } from 'lib/dateHelpers';
import Avatar from 'lib/components/avatar/Avatar';

import RENDER_INSPECTIONS from './activities/inspections';
import RENDER_WORK_OBJECTS from './activities/work-objects';

import './ActivityTimeline.scss';

const RENDER_CONFIG = {
  ...RENDER_WORK_OBJECTS,
  ...RENDER_INSPECTIONS,
};

export const renderLatestActivity = (data) => {
  const author = <strong>{data?.performedBy?.name}</strong>;
  return RENDER_CONFIG[data?.actions?.type]?.(author, data) ?? data.message;
};

const ActivityItem = ({ activity }) => {
  const { performedBy, createdAt, message } = activity;

  return (
    <div className='latest-activity-item'>
      <Avatar
        url={performedBy?.profilePhoto?.url}
        placeholder={performedBy?.name}
      />
      <div className='activity-info-container'>
        <p className='activity-description'>
          {renderLatestActivity(activity) ?? message}
        </p>
        <p className='activity-timestamp'>{formatDateTime(createdAt)}</p>
      </div>
    </div>
  );
};

const ActivityTimeline = (props) => {
  return (
    <section className='latest-activity'>
      <h2>Latest Activity</h2>
      {(props.data || [])
        // TODO: Remove this limit when BE finds a solution to limit the results
        .slice(0, 5)
        .map((activity) => (
          <ActivityItem activity={activity} key={activity?.id} />
        ))}
    </section>
  );
};

export default ActivityTimeline;
