import ROLES from 'config/roles';
import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import TagMatchingPage from './components/tag-matching-page/TagMatchingPage';

export default function PlaygroundRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={[ROLES.QM_ADMIN, ROLES.CLIENT_ADMIN]}
        path='/playground'
        exact
        render={(props) => <TagMatchingPage {...props} />}
      />
    </>
  );
}
