import AddButton from 'lib/components/add-button/AddButton';
import EditButton from 'lib/components/edit-button/EditButton';
import LinkableResourcesModal from 'lib/components/linkable-resources-modal/LinkableResourcesModal';
import DefectsModal from '../../../../lib/components/defects-modal/DefectsModal';
import QuestionGroupsModal from '../question-groups-modal/QuestionGroupsModal';

import useInspectionPlansFormNew from '../../useInspectionPlansFormNew';

import './InspectionPlansForm.scss';
import InspectionPlansFormNewGroups from './InspectionPlansFormNewGroups';
import InspectionPlansFormNewPlan from './InspectionPlansFormNewPlan';
import ErrorBag from 'lib/components/error-bag/ErrorBag';

// TODO: Rewrites left:
// ----- fetchOnHoldInspectionCount
// ----- prepareStateForValidation
// ----- uploadQuestionDocuments
// ----- userSubmitsForm
// ----- isResourceDisabled
// TODO: What's left:
// ----- Validation retouch
// ----- Show global errors
// ----- Show server errors
// ----- Translate form to API object
// ----- Actual submission to API
// ----- Upload docs
// ----- Loader handling
//
// TODO: Long run refactor:
// ----- Completely separate error state from data object
// ----- Separate data from form presentation - {value,label} pairs
//       should only exist around their presentation components
// ----- Cast all data to API-ready model so we can completely
//       remove the toForm, toDetail, toPOST data transforms
// ----- Use local state to handle question-level and group-level changes and memoize components

const InspectionPlansFormNew = (props) => {
  const vm = useInspectionPlansFormNew({
    id: props.match?.params?.id,
  });

  const SubmitButton = () => {
    const Btn = vm.planForm?.id ? EditButton : AddButton;
    const label = vm.planForm?.id ? 'Save changes' : 'Create inspection plan';

    return (
      <Btn
        type='button'
        // disabled={vm.state.loading}
        onClick={vm.doSaveInspectionPlan}
        data-testid='InspectionPlansForm.submit'
      >
        {label}
      </Btn>
    );
  };

  const {
    defectsModalParams,
    setDefectsModalParams,
    defectsModalData,
    defectsModalSelected,
    doToggleDefectSelect,
    doCreateDefect,
    questionGroupModalParams,
    setQuestionGroupModalParams,
    questionGroupModalData,
    resourcesModalParams,
    setResourcesModalParams,
    getInitialResourcesModalParams,
    resourcesModalData,
  } = vm.modals;

  return (
    <>
      <div className='inspection-plans-form'>
        {vm?.planForm?.errors?.length && (
          <ErrorBag errors={vm.planForm.errors} />
        )}
        <form noValidate>
          <InspectionPlansFormNewPlan vm={vm} />
          <InspectionPlansFormNewGroups vm={vm} />
          <SubmitButton />
        </form>
        {questionGroupModalParams.isModalOpen && (
          <QuestionGroupsModal
            data-testid='InspectionPlansForm.questionGroupsModal'
            data={questionGroupModalData.list}
            isOpen={questionGroupModalParams.isModalOpen}
            onRequestClose={() =>
              setQuestionGroupModalParams({
                ...questionGroupModalParams,
                isModalOpen: false,
              })
            }
            onRowClick={(qgt) => {
              vm.doAddQuestionGroup(qgt);
              setQuestionGroupModalParams({
                ...questionGroupModalParams,
                isModalOpen: false,
              });
            }}
            onSearchChange={(e) =>
              setQuestionGroupModalParams({
                ...questionGroupModalParams,
                search: e.target.value,
                page: 1,
              })
            }
            search={questionGroupModalParams.search}
            setSortBy={(col, order) =>
              setQuestionGroupModalParams({
                ...questionGroupModalParams,
                sortBy: col,
                sortOrder: order,
              })
            }
            sortBy={questionGroupModalParams.sortBy}
            sortOrder={questionGroupModalParams.sortOrder}
            setPage={(v) =>
              setQuestionGroupModalParams({
                ...questionGroupModalParams,
                page: v,
              })
            }
            page={questionGroupModalParams.page}
            pageSize={questionGroupModalParams.pageSize}
            count={questionGroupModalData.count}
          />
        )}
        {defectsModalParams.isModalOpen && (
          <DefectsModal
            data-testid='InspectionPlansForm.defectsModal'
            data={defectsModalData.list}
            count={defectsModalData.count}
            isCreatable
            onCreateClick={() => doCreateDefect()}
            isOpen={defectsModalParams.isModalOpen}
            onRequestClose={() =>
              setDefectsModalParams({
                ...defectsModalParams,
                isModalOpen: false,
              })
            }
            onRowClick={(v) => doToggleDefectSelect(v)}
            onSubmit={() => {
              vm.doSetDefectsList(
                defectsModalSelected,
                defectsModalParams.groupIndex,
                defectsModalParams.questionIndex,
              );
              setDefectsModalParams({
                ...defectsModalParams,
                isModalOpen: false,
              });
            }}
            onSearchChange={(e) =>
              setDefectsModalParams({
                ...defectsModalParams,
                search: e.target.value,
                page: 1,
              })
            }
            search={defectsModalParams.search}
            pageSize={defectsModalParams.pageSize}
            setPage={(v) =>
              setDefectsModalParams({ ...defectsModalParams, page: v })
            }
            page={defectsModalParams.page}
            setSortBy={(col, order) =>
              setDefectsModalParams({
                ...defectsModalParams,
                sortBy: col,
                sortOrder: order,
              })
            }
            sortBy={defectsModalParams.sortBy}
            sortOrder={defectsModalParams.sortOrder}
            errors={defectsModalData.errors}
          />
        )}
        {resourcesModalParams.isModalOpen && (
          <LinkableResourcesModal
            data-testid='InspectionPlansForm.linkableResourcesModal'
            data={resourcesModalData}
            isOpen={resourcesModalParams.isModalOpen}
            onRequestClose={() =>
              setResourcesModalParams({
                ...resourcesModalParams,
                isModalOpen: false,
              })
            }
            onRowClick={(resource) => {
              vm.doSetLinkedResource(resource);
              setResourcesModalParams({
                ...resourcesModalParams,
                isModalOpen: false,
              });
            }}
            onSearchChange={(e) =>
              setResourcesModalParams({
                ...resourcesModalParams,
                search: e.target.value,
                page: 1,
              })
            }
            search={resourcesModalParams.search}
            setSortBy={(col, order) =>
              setResourcesModalParams({
                ...resourcesModalParams,
                sortBy: col,
                sortOrder: order,
              })
            }
            setPage={(v) =>
              setResourcesModalParams({ ...resourcesModalParams, page: v })
            }
            page={resourcesModalParams.page}
            pageSize={resourcesModalParams.pageSize}
            sortBy={resourcesModalParams.sortBy}
            sortOrder={resourcesModalParams.sortOrder}
            onTabChange={(v) =>
              setResourcesModalParams({
                ...getInitialResourcesModalParams(),
                isModalOpen: true,
                tab: v,
              })
            }
            tab={resourcesModalParams.tab}
          />
        )}
      </div>
    </>
  );
};
export default InspectionPlansFormNew;
