import makeReducer from 'lib/makeReducer';

export const DASHBOARD_ACTIONS = {
  APP_LOADS_METRICS: 'APP_LOADS_METRICS',
  APP_LOADS_LIST: 'APP_LOADS_LIST',
  SET_ERRORS: 'SET_ERRORS',
  UPDATE_CALCULATED_METRICS: 'UPDATE_CALCULATED_METRICS',
};

function getInitialListState() {
  return {
    data: [],
    count: false,
    errors: [],
  };
}

const INITIAL_STATE = {
  upcomingInspections: getInitialListState(),
  latestInspections: getInitialListState(),
  timePerUnitInspections: getInitialListState(),
  pendingUsageDecisions: getInitialListState(),
  assetsMissingPlans: getInitialListState(),
  metrics: {
    data: {},
    errors: [],
  },
  calculatedMetrics: {},
};

const DASHBOARD_REDUCER_CONFIG = {
  [DASHBOARD_ACTIONS.APP_LOADS_METRICS]: (state, action) => {
    return {
      ...state,
      metrics: action.payload,
    };
  },
  [DASHBOARD_ACTIONS.UPDATE_CALCULATED_METRICS]: (state, action) => {
    return {
      ...state,
      calculatedMetrics: {
        ...state.calculatedMetrics,
        ...action.payload,
      },
    };
  },
  [DASHBOARD_ACTIONS.APP_LOADS_LIST]: (state, action) => {
    return {
      ...state,
      [action.key]: action.payload,
    };
  },
  [DASHBOARD_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  DASHBOARD_REDUCER_CONFIG,
  INITIAL_STATE,
);
