import SourcesForm from '../sources-form/SourcesForm';
import './SourcesEditPage.scss';

const SourcesEditPage = (props) => {
  return (
    <div className='sources-edit-page container-m'>
      <div className='row'>
        <h2>Edit source</h2>
      </div>
      <div className='row'>
        <SourcesForm {...props} />
      </div>
    </div>
  );
};

export default SourcesEditPage;
