import Button from 'lib/components/button/Button';
import './InspectionUsageDecisions.scss';
import { ReactComponent as WarningCircleOutline } from 'assets/images/warning-circle-outline.svg';

const RE_INSPECT = 'Re-inspect';

const renderInspectionErrors = (inspection) => {
  const errors = [];
  if (!!inspection?.criticalErrors) {
    errors.push(`${inspection?.criticalErrors} critical`);
  }
  if (!!inspection?.majorErrors) {
    errors.push(`${inspection?.majorErrors} major`);
  }
  if (!!inspection?.minorErrors) {
    errors.push(`${inspection?.minorErrors} minor`);
  }
  return errors.length ? (
    <>
      has <strong>{errors.join(', ')}</strong> errors and
    </>
  ) : null;
};

/**
 * @deprecated
 *
 * This RE_INSPECT group seems to be an abandoned idea.
 * // TODO: Clean-up code and model from group - it's not used anywhere
 */
const reInspectActions = (decisions) =>
  decisions?.filter((decision) => decision?.group === RE_INSPECT);

/** @deprecated */
const hasReInspectActions = (decisions) => {
  const reInspections = reInspectActions(decisions);
  return !!reInspections?.length;
};

const InspectionUsageDecisions = (props) => {
  const { inspection, actions, inspectionType } = props;
  return (
    <div className='decision-container'>
      <div className='decision-message'>
        <WarningCircleOutline className='alert-icon' />
        <p>
          {inspection?.inspectionType?.name || inspectionType?.name}{' '}
          {renderInspectionErrors(inspection)} needs a decision
        </p>
      </div>
      <div className='decision-action-buttons'>
        {inspection?.usageDecisions
          // .filter((filteredDecision) => filteredDecision?.group !== RE_INSPECT)
          .map((decision, index) => (
            <Button
              className='medium-button'
              key={decision?.id || index}
              onClick={() => actions.userTakesInspectionAction(decision)}
              disabled={actions?.isWorkObjectActionsDisabled}
            >
              {decision?.name}
            </Button>
          ))}
        {/** @deprecated */}
        {hasReInspectActions(inspection?.usageDecisions) && (
          <Button
            className='medium-button'
            onClick={() =>
              actions.userOpensReInspectModal({
                inspectionID: inspection?.id,
                reInspectionOptions: reInspectActions(
                  inspection?.usageDecisions,
                ),
              })
            }
            disabled={actions?.isWorkObjectActionsDisabled}
          >
            Re-inspect
          </Button>
        )}
      </div>
    </div>
  );
};

export default InspectionUsageDecisions;
