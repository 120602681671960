import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import testId from './fixtures/testid';

function DynamicModifierWidget({
  isInModal,
  data,
  onChange,
  questionOptions,
  modifierSelectPlacement,
  isDynamicCriteriaDisabled,
}) {
  return (
    <>
      <div className='form-label'>Dynamic modifier</div>
      <div className='flex'>
        <div>
          <Select
            data-testid={testId.dynamicActionSelect}
            isInModal={isInModal}
            className='modifier-action'
            onChange={(value) => onChange('dynamicAction', value)}
            value={data.dynamicAction?.value}
            options={questionOptions.dynamicAction}
            menuPlacement={modifierSelectPlacement}
          />
        </div>
        {data.dynamicAction?.value?.value !== false && (
          <>
            <div>
              <Input
                data-testid={testId.dynamicCriteriaInput}
                className='modifier-value'
                onChange={(ev) => onChange('dynamicCriteria', ev.target.value)}
                disabled={isDynamicCriteriaDisabled}
                value={
                  isDynamicCriteriaDisabled ? '' : data.dynamicCriteria?.value
                }
                errors={data.dynamicCriteria?.errors}
              />
            </div>
            <div>
              <Select
                data-testid={testId.dynamicRuleSelect}
                isInModal={isInModal}
                className='modifier-rule'
                options={questionOptions.dynamicRule}
                onChange={(value) => onChange('dynamicRule', value)}
                value={data.dynamicRule?.value}
                errors={data.dynamicRule?.errors}
                menuPlacement={modifierSelectPlacement}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DynamicModifierWidget;
