import { useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';
import useSelectableTable from 'lib/components/selectable-table/useSelectableTable';
import {
  entityToSelectOption,
  getAssetFilters,
  getSourceFilters,
  optionsToFormState,
} from './dataTransform';
import COUNTRIES from 'config/countries.json';
import {
  reducer,
  initialState,
  AUTO_BUILD_PLAN_FORM_ACTIONS,
} from './autoBuildPlanFormReducer';
import inspectionPlansService from './inspectionPlansService';
import autoBuildPlanFormValidator from './autoBuildPlanFormValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';
import { entityToGroupedSelect } from 'lib/dataTransform';
import tagsService from 'lib/tagsService';

const getLinkableAssets = debounce(
  (params, dispatch) => {
    return inspectionPlansService
      .getAssets(params)
      .then((response) => {
        dispatch({
          type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_RESOURCES,
          payload: {
            assets: response.data.map((asset) => ({
              ...asset,
              resourceType: 'asset',
            })),
            assetsCount: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
          payload: {
            linkableResources: {
              assets: [],
              sources: [],
              errors: ['An error occured while fetching assets / sources'],
              sortBy: 'id',
              sortOrder: 'ASC',
              search: '',
              isModalOpen: false,
            },
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getLinkableSources = debounce(
  (params, dispatch) => {
    return inspectionPlansService
      .getSources(params)
      .then((response) => {
        dispatch({
          type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_RESOURCES,
          payload: {
            sources: response.data.map((source) => ({
              ...source,
              resourceType: 'source',
            })),
            sourcesCount: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
          payload: {
            linkableResources: {
              assets: [],
              sources: [],
              errors: ['An error occured while fetching assets / sources'],
              sortBy: 'id',
              sortOrder: 'ASC',
              search: '',
              isModalOpen: false,
            },
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getInspectionTypes = debounce((params, dispatch) => {
  return inspectionPlansService
    .getInspectionTypes(params)
    .then((response) => {
      dispatch({
        type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_TYPES,
        payload: response.data.map(entityToSelectOption('name', 'id')),
      });
    })
    .catch(() => {
      dispatch({
        type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
        payload: {
          type: {
            value: null,
            errors: ['An error occured while fetching inspection types'],
          },
        },
      });
    });
}, 400);

const fetchCustomAttributeOptions = () => {
  return inspectionPlansService
    .getCustomAttributes({
      select: ['id', 'name'],
      pageSize: 0,
      // filters: { includeAnyOptions: true },
    })
    .then((res) =>
      res.data.map(entityToGroupedSelect('name', 'id', 'options')),
    );
};

const fetchCompanyAttributeOptions = () => {
  return inspectionPlansService
    .getCompanyAttributes({
      select: ['id', 'name'],
      pageSize: 0,
      // filters: { includeAnyOptions: true },
    })
    .then((res) =>
      res.data.map(entityToGroupedSelect('name', 'id', 'options')),
    );
};

const fetchAssetStatusOptions = () => {
  return inspectionPlansService
    .getAssetStatuses()
    .then((res) => res.data.map(entityToSelectOption('name', 'meaning')));
};

const fetchAssetTypeOptions = () => {
  return inspectionPlansService
    .getAssetTypes()
    .then((res) => res.data.map(entityToSelectOption('name', 'id')));
};

const fetchSourceTagOptions = () => {
  return tagsService
    .getTags()
    .then((res) => res.data.map(entityToSelectOption('label', 'id')));
};

const fetchSourceTypeOptions = () => {
  return inspectionPlansService
    .getSourceTypes()
    .then((res) => res.data.map(entityToSelectOption('name', 'id')));
};

const fetchAssetSourceInspectionTypeOptions = () => {
  return inspectionPlansService
    .getInspectionTypes({
      select: ['id', 'name', 'type'],
    })
    .then((res) => {
      const output = { asset: [], source: [] };
      res?.data?.forEach((item) => {
        if (['asset', 'source'].includes(item?.type))
          output[item.type].push(entityToSelectOption('name', 'id')(item));
      });

      return output;
    });
};

const getOptions = (dispatch) => {
  return Promise.all([
    inspectionPlansService.getInspectionPlanOptions(),
    fetchCompanyAttributeOptions(),
    fetchCustomAttributeOptions(),
    fetchAssetStatusOptions(),
    fetchAssetTypeOptions(),
    fetchSourceTagOptions(),
    fetchSourceTypeOptions(),
    fetchAssetSourceInspectionTypeOptions(),
  ])
    .then(
      ([
        planOptions,
        companyAttributeOptions,
        customAttributeOptions,
        assetStatusOptions,
        assetTypeOptions,
        sourceTagOptions,
        sourceTypeOptions,
        inspectionTypeOptions,
      ]) => {
        dispatch({
          type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_OPTIONS,
          payload: {
            planOptions: optionsToFormState(planOptions),
            filterOptions: {
              assetStatus: assetStatusOptions,
              companyAttribute: companyAttributeOptions,
              customAttribute: customAttributeOptions,
              assetType: assetTypeOptions,
              sourceTag: sourceTagOptions,
              sourceType: sourceTypeOptions,
              sourceCountry: COUNTRIES.map((country) => ({
                label: country,
                value: country,
              })),
              assetInspectionType: inspectionTypeOptions?.asset,
              sourceInspectionType: inspectionTypeOptions?.source,
            },
          },
        });
      },
    )
    .catch(() => {
      dispatch({
        type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: [
            'An error occured while fetching form options. Please try again.',
          ],
        },
      });
    });
};
const getAutoBuildProgress = (dispatch, params) => {
  return inspectionPlansService
    .getAutoBuildProgress(params)
    .then((response) => {
      dispatch({
        type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_AUTO_BUILD_PROGRESS,
        payload: response,
      });
      return response;
    })
    .catch(() => {
      dispatch({
        type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: [
            'An error occured while fetching auto build progress. Please reload this page.',
          ],
        },
      });
    });
};

const useAutoBuildPlanForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const assetTable = useSelectableTable();
  const sourceTable = useSelectableTable();

  useNavigationPrompt(state.isDirty);

  const dispatchInput = (key, payload) =>
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_INPUT,
      key,
      payload,
    });

  const dispatchBulkParam = (key, index, value) =>
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_BULK_PARAM,
      payload: { key, index, value },
    });

  const userAddsNewBulkParam = () =>
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_ADDS_NEW_BULK_PARAM,
    });

  const userRemovesBulkParam = (index) =>
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_REMOVES_BULK_PARAM,
      payload: index,
    });

  const userTogglesOverridePlans = (e) =>
    dispatchInput('overridePlans', e?.target?.checked);
  const userTogglesOverrideUserPlans = (e) =>
    dispatchInput('overrideUserPlans', e?.target?.checked);
  const isOverrideUserPlansDisabled = !state.overridePlans.value;

  const userTypesValidity = (e, index) => {
    const { value } = e.target;
    const validity = Math.max(Number(value.replace(/[^0-9]/g, '')), 1);
    return dispatchBulkParam('validity', index, value !== '' ? validity : '');
  };

  const userSelectsValidityRange = (range, index) => {
    return dispatchBulkParam('validityRange', index, range);
  };

  const userSubmitsForm = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    return dispatch({ type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  useEffect(() => {
    if (state.loading) {
      autoBuildPlanFormValidator(state)
        .then((data) =>
          inspectionPlansService.saveAutoBuildPlan(data).then(() =>
            dispatch({
              type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_SUBMITS_SUCCESSFULLY,
            }),
          ),
        )
        .catch((e) => {
          dispatch({
            type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() => {
          dispatch({
            type: AUTO_BUILD_PLAN_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          });
          document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
        });
    }
  }, [state.loading]);

  useEffect(() => {
    getInspectionTypes({}, dispatch);
    getOptions(dispatch);
  }, [state.isSubmissionFinished]);

  useEffect(() => {
    let ticker;
    let unmounted = false;
    let errorsCount = 0;

    function checkAutoBuildStatus() {
      getAutoBuildProgress(dispatch, { isSummary: 'true' })
        .then((response) => {
          if (unmounted) {
            return;
          }
          if (response?.status === 'active') {
            ticker = setTimeout(checkAutoBuildStatus, 2000);
          } else if (response?.status === 'finished') {
            getAutoBuildProgress(dispatch);
          } else if ([null, undefined, {}, []].includes(response)) {
            return
          } else {
            dispatch({
              type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
              payload: {
                errors: [
                  'An error occured while fetching auto build progress. Please reload this page.',
                ],
              },
            });
          }
        })
        .catch(() => {
          if (unmounted) {
            return;
          }
          ++errorsCount;
          if (errorsCount < 5) {
            ticker = setTimeout(checkAutoBuildStatus, 5000);
          } else {
            dispatch({
              type: AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE,
              payload: {
                errors: [
                  'An error occured while fetching auto build progress. Please reload this page.',
                ],
              },
            });
          }
        });
    }

    checkAutoBuildStatus();

    return () => {
      unmounted = true;
      if (ticker) {
        clearTimeout(ticker);
      }
    };
  }, [state.isSubmissionFinished]);

  const linkableResourcesParams = {
    order: {
      [state.linkableResources.sortBy]: state.linkableResources.sortOrder,
    },
    pageSize: state.linkableResources.pageSize,
    page: state.linkableResources.page,
    search: state.linkableResources.search,
  };

  const linkableAssetsParams = {
    ...linkableResourcesParams,
    filters: getAssetFilters(state.filters),
  };

  const linkableSourcesParams = {
    ...linkableResourcesParams,
    filters: getSourceFilters(state.filters),
  };

  useEffect(() => {
    if (state.linkableResources.isModalOpen) {
      state.linkableResources.tab === 'assets'
        ? getLinkableAssets(linkableAssetsParams, dispatch)
        : getLinkableSources(linkableSourcesParams, dispatch);
    }
  }, [
    state.linkableResources.search,
    state.linkableResources.sortBy,
    state.linkableResources.sortOrder,
    state.linkableResources.isModalOpen,
    state.linkableResources.tab,
    state.linkableResources.page,
    state.filters,
  ]);

  const getLinkableResourcesList = () => ({
    assets: state.linkableResources.assets,
    assetsCount: state.linkableResources.assetsCount,
    sources: state.linkableResources.sources,
    sourcesCount: state.linkableResources.sourcesCount,
  });

  const getLinkableResourcesSelected = () => ({
    assets: assetTable.selectedItems,
    totalAssets: assetTable.totalItemsSelected,
    sources: sourceTable.selectedItems,
    totalSources: sourceTable.totalItemsSelected,
  });

  const getIsAllSelected = () => ({
    assets: assetTable.isAllSelected,
    sources: sourceTable.isAllSelected,
  });

  const getSelectedPages = () => ({
    assets: assetTable.selectedPages,
    sources: sourceTable.selectedPages,
  });

  const userTogglesSelectItem = (item) => ({
    assets: () => assetTable.toggleSelectItem(item),
    sources: () => sourceTable.toggleSelectItem(item),
  });

  const userTogglesSelectPage = (items, page) => ({
    assets: () => assetTable.toggleSelectCurrentPage(items, page),
    sources: () => sourceTable.toggleSelectCurrentPage(items, page),
  });

  const userTogglesSelectAll = () => ({
    assets: assetTable.toggleSelectAll,
    sources: sourceTable.toggleSelectAll,
  });

  const userClearsSelection = () => ({
    assets: assetTable.clearSelection,
    sources: sourceTable.clearSelection,
  });

  const userSetsInspectionType = (type, index) => {
    return dispatchBulkParam('type', index, type);
  };

  const userSearchesInspectionType = (string) => {
    return dispatchInput('typeSearch', string);
  };

  const userSelectsAqlLevel = (option, index) => {
    if (option === null) {
      dispatchBulkParam('aqlMajor', index, null);
      dispatchBulkParam('aqlMinor', index, null);
      dispatchBulkParam('criticalDefect', index, '');
    }
    return dispatchBulkParam('aqlLevel', index, option);
  };
  const userSelectsAqlMajor = (option, index) => {
    return dispatchBulkParam('aqlMajor', index, option);
  };
  const userSelectsAqlMinor = (option, index) => {
    return dispatchBulkParam('aqlMinor', index, option);
  };
  const userSelectsAqlFunctional = (option, index) => {
    return dispatchBulkParam('aqlFunctional', index, option);
  };

  const userTypesCriticalDefect = (e, index) => {
    const { value } = e.target;
    return dispatchBulkParam('criticalDefect', index, value);
  };

  const getCriticalDefectRuleValue = (index) => {
    return state.bulkParams[index].criticalDefectRule.value === '%';
  };

  const userChangesCriticalDefectRule = (index) => {
    const newValue =
      state.bulkParams[index].criticalDefectRule.value === '%' ? 'Exact' : '%';
    return dispatchBulkParam('criticalDefectRule', index, newValue);
  };

  const userOpensResourcesModal = () => {
    assetTable.setSelectedItems(state.linkedResources.assets.selected);
    assetTable.setSelectedPages(state.linkedResources.assets.selectedPages);
    assetTable.setIsAllSelected(state.linkedResources.assets.isAllSelected);
    assetTable.setAllSelectedCount(state.linkedResources.assets.totalSelected);
    sourceTable.setSelectedItems(state.linkedResources.sources.selected);
    sourceTable.setSelectedPages(state.linkedResources.sources.selectedPages);
    sourceTable.setIsAllSelected(state.linkedResources.sources.isAllSelected);
    sourceTable.setAllSelectedCount(
      state.linkedResources.sources.totalSelected,
    );

    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL,
    });
  };

  const userCancelsResourcesModal = () => {
    assetTable.resetChanges();
    sourceTable.resetChanges();
    return dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL,
    });
  };

  const userChangesResourcesModalTab = (tab) => {
    return dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_RESOURCES_MODAL_TAB,
      payload: tab,
    });
  };

  const userSelectsResources = () => {
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SELECTS_RESOURCES,
      payload: {
        assets: {
          selected: assetTable.selectedItems,
          selectedPages: assetTable.selectedPages,
          isAllSelected: assetTable.isAllSelected,
          totalSelected: assetTable.totalItemsSelected,
        },
        sources: {
          selected: sourceTable.selectedItems,
          selectedPages: sourceTable.selectedPages,
          isAllSelected: sourceTable.isAllSelected,
          totalSelected: sourceTable.totalItemsSelected,
        },
      },
    });
  };

  const userSearchesResources = (ev) => {
    return dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SEARCHES_RESOURCES,
      payload: ev.target.value,
    });
  };

  const userSortsResources = (col, order) => {
    return dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SORTS_RESOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSetsResourcesPage = (page) => {
    return dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_RESOURCES_PAGE,
      payload: page,
    });
  };

  const getFilterOptions = (filter) => {
    return [
      {
        label: 'All',
        value: 'all',
      },
      ...state.filterOptions[filter],
    ];
  };

  const getFilterValue = (filter) => {
    const val = state.filters[filter];

    if (val.length === 0) {
      return {
        label: 'All',
        value: 'all',
      };
    }

    if (val.length > 1) {
      return {
        label: 'Multiple',
        value: '',
      };
    }

    return val[0];
  };

  const setFilter = (filter, value) => {
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_FILTER,
      key: filter,
      payload: value,
    });

    assetTable.isAllSelected && assetTable.clearSelection();
    sourceTable.isAllSelected && sourceTable.clearSelection();
  };

  const setBoolFilter = (filter, value) => {
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_BOOL_FILTER,
      key: filter,
      payload: value,
    });
  };

  const setTextFilter = (filter, value) => {
    dispatch({
      type: AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_TEXT_FILTER,
      key: filter,
      payload: value,
    });
  };

  return {
    state,

    getFilterOptions,
    getFilterValue,
    setFilter,
    setBoolFilter,
    setTextFilter,

    getLinkableResourcesList,
    getLinkableResourcesSelected,
    getIsAllSelected,
    getSelectedPages,
    userTogglesSelectItem,
    userTogglesSelectPage,
    userTogglesSelectAll,
    userClearsSelection,
    getCriticalDefectRuleValue,

    userTogglesOverridePlans,
    userTogglesOverrideUserPlans,
    isOverrideUserPlansDisabled,
    userTypesValidity,
    userSelectsValidityRange,
    userSelectsAqlLevel,
    userSelectsAqlMajor,
    userSelectsAqlMinor,
    userSelectsAqlFunctional,
    userTypesCriticalDefect,
    userSetsInspectionType,
    userSearchesInspectionType,
    userChangesCriticalDefectRule,
    userAddsNewBulkParam,
    userRemovesBulkParam,
    userOpensResourcesModal,
    userCancelsResourcesModal,
    userSearchesResources,
    userSortsResources,
    userSetsResourcesPage,
    userSelectsResources,
    userChangesResourcesModalTab,

    userSubmitsForm,
  };
};

export default useAutoBuildPlanForm;
