import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportQuestionsPage.scss';

const questionColumnLabels = {
  id: 'ID',
  externalId: 'External ID',
  'name.0.language': 'Question language',
  'name.0.text': 'Question text',
  defects: 'Defects',
  printOnReport: 'Print on report',
  aqlLevel: 'AQL Level',
  minorDefect: 'Minor defect value',
  majorDefect: 'Major defect value',
  functionalDefect: 'Functional defect value',
  criticalDefectValue: 'Critical defect value',
  criticalDefectRule: 'Critical defect rule',
  name: 'Question name',
  photoRequired: 'Photo required',
  answerType: 'Answer type',
  type: 'Question type',
  dynamicAction: 'Dynamic action',
  dynamicCriteria: 'Dynamic criteria',
  questionWeight: 'Question weight',
  answer: 'Answer',
  dynamicRule: 'Dynamic rule',
  sampleQty: 'Sample size quantity',
  sampleRule: 'Sample size rule',
  lowerToleranceRule: 'Lower tolerance rule',
  lowerToleranceValue: 'Lower tolerance value',
  lowerToleranceWeight: 'Lower tolerance weight',
  upperToleranceValue: 'Upper tolerance rule',
  upperToleranceRule: 'Upper tolerance value',
  upperToleranceWeight: 'Upper tolerance weight',
  expectedMeasureTableResult: 'Expected measure table result',
  customExpectedUom: 'Custom expected UOM',
  customExpectedMeasureValue: 'Custom expected measure value',
  expectedBarcodeUOM: 'Expected UOM',
  otherMeasure: 'Other expected measure',
};

// TODO: Remove declarations that are covered by __default__
const questionErrorValidations = {
  externalId: {
    'string.base': () => 'External ID must be a string',
  },
  defects: {
    'defects.invalid': () => 'Defect with specified External ID was not found',
    'defects.defectWeight': () =>
      'Number of Defect IDs does not match number of Defect Weights',
    'defects.defectWeight.missing': () =>
      'Number of Defect IDs does not match number of Defect Weights',
  },
  'defects.0.weight': {
    'any.only': () => 'Defects weight invalid value',
  },
  details: {},
  printOnReport: {
    'any.only': () => 'Print on report invalid value',
    'any.required': () => 'Print on report is required',
  },
  aqlLevel: {
    'any.only': () => 'AQL Level invalid value',
  },
  criticalDefectValue: {
    'number.less': () => 'Critical defect value cannot exceed 100%',
    'number.positive': () => 'Critical defect value must be positive',
    'number.integer': () => 'Critical defect value must be an integer',
  },
  name: {
    'defect.unique': () => 'Question name already exists',
    'any.required': () => 'Question name is required',
    'question.unique': () => 'Question name should be unique',
  },
  'question.name': {
    'name.text.unique': () => 'Question already exists',
  },
  questionWeight: {
    'any.only': () => 'Invalid question weight value',
    'any.required': () => 'Question weight is required',
  },
  answer: {
    'answer.invalid': () =>
      'Answer must be valid when multiple choice or barcode input is selected',
    'answer.answerMultipleChoice.missing': () =>
      'Answer name is required when answer type is multiple choice or barcode input',
    'customExpectedUom.invalid': () => 'Custom expected UOM is invalid',
  },
  'tools.0.name': {
    'string.pattern.base': () =>
      'Tools name should contain alphanumeric characters',
  },
  answerMultipleChoice: {
    'answerMultipleChoice.notFound': () =>
      'Answer must be valid when multiple choice or barcode input is selected',
  },
  answerType: {
    'any.only': () => 'Answer type invalid value',
    'any.required': () => 'Answer type is required',
  },
  type: {
    'any.only': () => 'Question type invalid value',
    'any.required': () => 'Question type is required field',
    __default__: ({ errorCode }) => errorCode.replace(/\.$/, ''),
  },
  dynamicAction: {
    'any.only': () => 'Dynamic action invalid value',
  },
  dynamicCriteria: {
    'number.integer': () => 'Dynamic criteria must be an integer',
    'number.greater': () =>
      'Dynamic criteria must be an integer greater than zero',
    'number.base': () => 'Dynamic criteria must be a number',
    'any.required': () => 'Dynamic criteria required field',
  },
  dynamicRule: {
    'any.only': () => 'Dynamic rule invalid value',
    'any.required': () => 'Dynamic rule required field',
  },
  sampleQty: {
    'number.min': () => 'Sample quantity should not be negative',
    'number.max': () => 'Sample quantity should not be greater than 100%',
    'any.required': () => 'Sample quantity is required',
    'number.integer': () => 'Sample quantity should be an integer',
    'number.base': () => 'Sample quantity should be a number',
  },
  sampleRule: {
    'number.max': () => 'Sample size value exceeded',
    'any.only': () => 'Sample size rule invalid value',
    'any.required': () => 'Sample size rule is required',
  },
  lowerToleranceRule: {
    'any.required': () => 'Lower tolerance rule is required',
    'any.only': () => 'Lower tolerance rule invalid value',
    'object.unknown': () => 'Only allowed decimal values',
  },
  lowerToleranceValue: {
    'number.base': () => 'Lower tolerance value must be a number',
    'number.min': () => 'Lower tolerance value must not be less than zero',
    'number.max': () => 'Lower tolerance value exceeded maximum',
  },
  lowerToleranceWeight: {
    'any.only': () => 'Lower tolerance weight invalid value',
    'any.required': () => 'Lower tolerance weight is required',
  },
  upperToleranceValue: {
    'number.min': () => 'Upper tolerance value must not be less than zero',
    'number.max': () => 'Upper tolerance value exceeded maximum',
    'number.base': () => 'Upper tolerance value should be a number',
    'any.only': () => 'Upper tolerance value invalid value',
  },
  upperToleranceRule: {
    'any.only': () => 'Upper tolerance rule invalid value',
    'any.required': () => 'Upper tolerance rule is required',
    'object.unknown': () => 'Only allowed decimal values',
  },
  upperToleranceWeight: {
    'any.only': () => 'Upper tolerance weight invalid value',
    'any.required': () => 'Upper tolerance weight is required',
    'object.unknown': () => 'Only allowed decimal values',
  },
  expectedMeasureTableResult: {
    'any.only': () => 'Expected measure table result invalid value',
  },
  customExpectedUom: {
    'customExpectedUom.required': () => 'Custom UOM is required',
    'customExpectedMeasureValue.required': () =>
      'Custom expected measure value is required',
  },
  customExpectedMeasureValue: {
    'number.min': () =>
      'Custom expected measure value should be greater than 0',
    'number.base': () => 'Custom expected measure value should be a number',
  },
  expectedBarcodeUOM: {
    'any.required': () => 'UOM is required for barcode input questions',
  },
  id: {
    'id.notFound': () => 'Question with provided Exacture ID not found',
  },
  otherMeasure: {
    'any.only': () => 'Other expected measure has an invalid value',
  },
  question: {
    'question.inconsistentValues': () =>
      'Question has inconsistent values between its translations',
  },
};

export default function ImportQuestionsPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-questions-page'>
      <h4>Import Questions</h4>
      <p className='form-info'>
        Before importing questions, please make sure you structure your data
        like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a
          href='documents/sample-question-templates.csv'
          target='_blank'
          download
        >
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='question-templates' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={questionErrorValidations}
        columnLabels={questionColumnLabels}
      />
    </div>
  );
}
