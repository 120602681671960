import { useReducer } from 'react';
import authService from '../../lib/authService';

import loginReducer, {
  INITIAL_LOGIN_STATE,
  LOGIN_ACTIONS,
} from './loginReducer';
import loginValidator from './loginValidator';

function useLoginForm() {
  const [state, dispatch] = useReducer(loginReducer, INITIAL_LOGIN_STATE);

  const onChangePassword = (e) =>
    dispatch({
      type: LOGIN_ACTIONS.USER_TYPES_PASSWORD,
      payload: e.target.value,
    });

  const onChangeEmail = (e) =>
    dispatch({ type: LOGIN_ACTIONS.USER_TYPES_EMAIL, payload: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: LOGIN_ACTIONS.FORM_LOADING, payload: true });

    return loginValidator({
      email: state.email.value,
      password: state.password.value,
    })
      .then(authService.login)
      .catch((e) => {
        dispatch({
          type: LOGIN_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
        dispatch({ type: LOGIN_ACTIONS.FORM_LOADING, payload: false });
      });
  };

  return { state, onChangePassword, onChangeEmail, onSubmit };
}

export default useLoginForm;
