import SettingsMenu from '../settings-menu/SettingsMenu';

import './SettingsPage.scss';

export default function SettingsPage({ children }) {
  return (
    <div className='settings-page'>
      <div className='row container-m settings-page-header'>
        <h2>Settings</h2>
      </div>
      <div className='row container-m settings-page-body'>
        <SettingsMenu />
        <div className='settings-content'>{children}</div>
      </div>
    </div>
  );
}
