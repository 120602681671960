import classNames from 'classnames';

import { noop } from 'lib/funcHelpers';
import Button from 'lib/components/button/Button';
import ErrorBag from '../error-bag/ErrorBag';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';

import './Radio.scss';

const RadioOption = ({
  hideCheckbox,
  label,
  selected,
  disabled = false,
  onClick,
  ...props
}) => (
  <Button
    className={classNames('option', {
      selected,
      disabled,
    })}
    onClick={!disabled ? onClick : undefined}
    role='radio'
    aria-checked={selected.toString()}
    {...props}
  >
    {hideCheckbox ? null : selected ? (
      <CheckCircleSolid className='radio-check checked' />
    ) : (
      <div className='radio-check unchecked' />
    )}
    {label}
  </Button>
);

const Radio = ({
  options = [],
  value,
  label,
  hideCheckbox,
  errors = [],
  onChange = noop,
  disabled = false,
  ...props
}) => {
  return (
    <>
      {label && (
        <label className='form-label' htmlFor={props.name}>
          {label}
        </label>
      )}
      <div
        className={classNames('qm-radio-container', {
          'is-invalid': errors.length,
          'hide-checkbox': hideCheckbox,
          disabled,
        })}
        role='radiogroup'
        data-testid='Radio.container'
      >
        {options.map((option) => {
          const isSelected = option.value === value;
          return (
            <RadioOption
              key={option.value}
              label={option.label}
              name={props.name}
              selected={isSelected}
              disabled={disabled || option.disabled}
              hideCheckbox={hideCheckbox}
              onClick={(ev) => {
                ev.preventDefault();
                !isSelected && !disabled && onChange(option.value);
              }}
            />
          );
        })}
      </div>
      <ErrorBag errors={errors} />
    </>
  );
};

export default Radio;
