import { makeListReducer, SORT_ORDER } from 'lib/list-helper/makeListReducer';

export const USER_LIST_PAGE_ACTIONS = {
  USER_STARTS_EXPORT_USERS: 'USER_STARTS_EXPORT_USERS',
  APP_FINISHES_EXPORT_USERS: 'APP_FINISHES_EXPORT_USERS',
};

const INITIAL_USER_LIST_STATE = {
  sortBy: 'id',
  sortOrder: SORT_ORDER.DESC,
  isPreparingExport: false,
};

const REDUCER_CONFIG = {
  [USER_LIST_PAGE_ACTIONS.USER_STARTS_EXPORT_USERS]: (state) => ({
    ...state,
    isPreparingExport: true,
  }),
  [USER_LIST_PAGE_ACTIONS.APP_FINISHES_EXPORT_USERS]: (state) => ({
    ...state,
    isPreparingExport: false,
  }),
};

export const { reducer, initialState } = makeListReducer(
  REDUCER_CONFIG,
  INITIAL_USER_LIST_STATE,
);
