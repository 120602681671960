/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useReducer } from 'react';

import {
  INSPECTOR_TYPE,
  INSPECTOR_TYPE_LABEL,
  INSPECTION_TARGET_LABEL,
  INSPECTION_TARGET,
} from 'config/inspectionConfig';
import {
  getInspectionStatusLabel,
  INSPECTION_RESULT,
} from 'config/inspectionStatus';

import useConfirm from 'lib/components/useConfirm/useConfirm';
import useNavigationPrompt from 'lib/useNavigationPrompt';
import useRoles from 'lib/useRoles';
import useQueryParams from 'lib/useQueryParams';
import useListSelectModal from 'lib/modal-helper/useListSelectModal';
import { SELECT_MODAL_ACTIONS } from 'lib/modal-helper/listSelectModalReducer';
import { entityToSelectOption } from 'lib/dataTransform';

import {
  reducer,
  initialState,
  INSPECTION_EXTERNAL_FORM_ACTIONS,
  MAX_FILE_SIZE,
} from './inspectionThirdPartyFormReducer';
import { EDIT_THIRD_PARTY_ANYTIME_ROLES } from './inspectionsPermissions';
import {
  thirdPartyInspectionToFormState,
  workObjectAtachedToExternalFormState,
} from './dataTransform';
import inspectionsExternalFormValidator from './inspectionsThirdPartyFormValidator';
import inspectionsService from './inspectionsService';

const inspectorTypeOptions = [
  {
    label: INSPECTOR_TYPE_LABEL[INSPECTOR_TYPE.INTERNAL],
    value: INSPECTOR_TYPE.INTERNAL,
  },
  {
    label: INSPECTOR_TYPE_LABEL[INSPECTOR_TYPE.EXTERNAL],
    value: INSPECTOR_TYPE.EXTERNAL,
  },
];

const inspectionTargetOptions = [
  {
    label: INSPECTION_TARGET_LABEL[INSPECTION_TARGET.ASSET],
    value: INSPECTION_TARGET.ASSET,
  },
  {
    label: INSPECTION_TARGET_LABEL[INSPECTION_TARGET.SOURCE],
    value: INSPECTION_TARGET.SOURCE,
  },
];

const inspectionResultOptions = [
  {
    label: 'None',
    value: null,
  },
  {
    label: getInspectionStatusLabel(INSPECTION_RESULT.PASSED),
    value: INSPECTION_RESULT.PASSED,
  },
  {
    label: getInspectionStatusLabel(INSPECTION_RESULT.FAILED),
    value: INSPECTION_RESULT.FAILED,
  },
];

const fetchInspectors = async (search, pageSize, page, sortBy, sortOrder) =>
  inspectionsService.getInspectors({
    search,
    pageSize,
    page,
    order: { [sortBy]: sortOrder },
    filters: {
      status: 'active',
    },
  });

const fetchSources = async (search, pageSize, page, sortBy, sortOrder) => {
  return inspectionsService.getSources({
    search: search || undefined,
    pageSize,
    page,
    order: { [sortBy]: sortOrder },
  });
};

const fetchAssets = async (search, pageSize, page, sortBy, sortOrder) =>
  inspectionsService.getAssets({
    search,
    pageSize,
    page,
    order: { [sortBy]: sortOrder },
  });

const fetchInspectionTypes = async (dispatch) =>
  inspectionsService.getInspectionTypes().then((res) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_TYPE_OPTIONS,
      payload: res.data,
    });
  });

const fetchWorkObject = (params, dispatch) => {
  return inspectionsService
    .getWorkObject(params.id)
    .then((response) => {
      dispatch({
        type:
          INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_WORK_OBJECT_ATTACHED_DATA,
        payload: workObjectAtachedToExternalFormState(
          response,
          params.partialId,
          params.stepId,
        ),
      });
    })
    .catch(() => {
      dispatch({
        type: INSPECTION_EXTERNAL_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching work objects'],
        },
      });
    });
};

const useInspectionThirdPartyForm = ({
  history,
  location,
  match,
  inspection,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAllowed } = useRoles();
  const { confirm } = useConfirm();
  const { getParams } = useQueryParams(location, history);

  useNavigationPrompt(state.isDirty);
  const id = match?.params?.id;

  useEffect(() => {
    if (inspection) {
      dispatch({
        type: INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_INSPECTION,
        payload: thirdPartyInspectionToFormState(inspection),
      });
    } else if (id) {
      return inspectionsService
        .getInspection(id)
        .then((results) => {
          dispatch({
            type: INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_INSPECTION,
            payload: thirdPartyInspectionToFormState(results),
          });
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            history.push(`/inspections`);
          } else {
            dispatch({
              type: INSPECTION_EXTERNAL_FORM_ACTIONS.APP_SETS_ERRORS,
              payload: e.payload,
            });
          }
        });
    }

    if (getParams()?.workObjectId) {
      const { workObjectId, partialId, stepId } = getParams();
      fetchWorkObject({ id: workObjectId, partialId, stepId }, dispatch);
    }
  }, []);

  const userSelectsInspector = (inspector) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'inspector',
      payload: inspector,
    });
  };
  const inspectorsModal = useListSelectModal(
    fetchInspectors,
    userSelectsInspector,
  );

  const userSelectsSource = (source) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'source',
      payload: source,
    });
  };
  const sourcesModal = useListSelectModal(fetchSources, userSelectsSource);

  const userSelectsAsset = (asset) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'asset',
      payload: asset,
    });
  };
  const assetsModal = useListSelectModal(fetchAssets, userSelectsAsset);

  const userSetsInspectorType = (inspectorType) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INSPECTOR_TYPE,
      payload: inspectorType,
    });
  };

  const userSetsInspectionTarget = (inspectionTarget) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INSPECTION_TARGET,
      payload: inspectionTarget,
    });
  };

  const userTypesInspectorName = (ev) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'thirdPartyInspectorName',
      payload: ev.target.value,
    });
  };

  const userTypesInspectingCompany = (ev) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'thirdPartyInspectingCompany',
      payload: ev.target.value,
    });
  };

  const userSetsInspectionDate = (date) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'inspectionDate',
      payload: date,
    });
  };

  const userSetsInspectionWindow = (range) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'inspectionWindow',
      payload: range,
    });
  };

  const userSetsValidUntil = (date) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'validUntil',
      payload: date,
    });
  };

  const userSetsInspectionType = (type) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'inspectionType',
      payload: type,
    });
  };

  const userSetsInspectionResult = (result) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT,
      key: 'inspectionResult',
      payload: result,
    });
  };

  const userClicksOpenInspectorModal = () =>
    inspectorsModal.dispatch({
      type: SELECT_MODAL_ACTIONS.OPEN_MODAL,
    });
  const userClicksOpenAssetModal = () =>
    assetsModal.dispatch({
      type: SELECT_MODAL_ACTIONS.OPEN_MODAL,
    });
  const userClicksOpenSourceModal = () =>
    sourcesModal.dispatch({
      type: SELECT_MODAL_ACTIONS.OPEN_MODAL,
    });

  const userUploadsInspectionReport = ([file]) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_UPLOADS_REPORT,
      payload: file,
    });
  };

  const userUploadsDocuments = (fileArray) => {
    const filteredArray = Array.from(fileArray).filter(
      (file) => file.size < MAX_FILE_SIZE,
    );

    const errors =
      filteredArray.length < fileArray.length
        ? ['Maximum allowed size for documents is 50MB']
        : [];

    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_UPLOADS_DOCUMENTS,
      payload: {
        list: filteredArray,
        errors,
      },
    });
  };

  const userTypesDocumentName = (ev, index) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_TYPES_DOCUMENT_NAME,
      payload: ev.target.value,
      key: index,
    });
  };

  const userDeletesDocument = (index) => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_DELETES_DOCUMENT,
      payload: index,
    });
  };

  const userResetsDocuments = () => {
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_RESETS_DOCUMENTS,
    });
  };

  const userSubmitsForm = async () => {
    if (
      !isAllowed(EDIT_THIRD_PARTY_ANYTIME_ROLES) &&
      state.inspectionReport.file
    ) {
      const acceptedWarning = await confirm({
        body:
          'Only administrators can modify details of this inspection' +
          ' after the report has been submitted. Continue?',
        title: 'Submit inspection report?',
        confirmText: 'Submit',
      });

      if (!acceptedWarning) return;
    }
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SUBMITS_FORM,
    });
  };

  const isReportChanged = () => {
    const originalReport = JSON.stringify(state.initialInspectionReport);
    const newReport = JSON.stringify(state.inspectionReport);
    return !!state.initialInspectionReport && originalReport !== newReport;
  };

  const isDocumentListChanged = () => {
    const originalList = JSON.stringify(state.initialDocuments);
    const newList = JSON.stringify(state.documents.list);
    return originalList !== newList;
  };

  const userResetsReport = () =>
    dispatch({
      type: INSPECTION_EXTERNAL_FORM_ACTIONS.USER_RESETS_REPORT,
    });

  useEffect(() => {
    fetchInspectionTypes(dispatch);
  }, []);

  useEffect(() => {
    if (!state.isSubmitting) {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
      return;
    }

    const { partialId, stepId, order } = getParams();
    const request = partialId
      ? 'saveAttachedExternalInspection'
      : 'saveExternalInspection';

    inspectionsExternalFormValidator(state)
      .then((validState) =>
        inspectionsService[request](
          partialId
            ? {
                inspection: validState,
                partialId,
                stepId,
                order,
              }
            : validState,
        ),
      )
      .then((result) => (partialId ? result.inspection : result))
      .then((inspection) => {
        const fileUploads = [];

        if (state.inspectionReport.file && !state.inspectionReport.file.url) {
          fileUploads.push(
            inspectionsService.saveExternalInspectionReport(
              inspection.id,
              state.inspectionReport.file,
              state.inspectionReport.value,
            ),
          );
        }

        fileUploads.push(
          state.documents.list
            .filter((doc) => !doc.file.url)
            .map((doc) =>
              inspectionsService.saveExternalInspectionDocument(
                inspection.id,
                doc.file,
                doc.value,
              ),
            ),
        );

        return Promise.all(fileUploads).then(() => inspection);
      })
      .then((inspection) => {
        return partialId
          ? history.goBack()
          : history.push(`/inspections/${inspection.id}`);
      })
      .catch((err) => {
        dispatch({
          type: INSPECTION_EXTERNAL_FORM_ACTIONS.APP_SETS_ERRORS,
          payload: err.payload,
        });
      });
  }, [state.isSubmitting]);

  const getInspectionTypeOptions = useCallback(() => {
    return state.inspectionTypeOptions
      .filter((val) => val.type === state.inspectionTarget.value)
      .map(entityToSelectOption('name', 'id'));
  }, [state.inspectionTypeOptions, state.inspectionTarget.value]);

  const isResourceSelectDisabled =
    state.isAttachedInspection || !!state.workObjectId;

  /** When adding an inspection to a work object, an actual inspection entity
   * is not immediately created and so some fields are unavailable.
   */
  const isScheduledInspection = () => {
    if (!state.partial || state.id) {
      // We are either editing an already existing inspection
      // or creating a manual inspection
      return false;
    }

    const {
      order: currentOrder,
      stepOrder: currentStepOrder,
      partialWorkObjectSteps,
    } = state.partial;
    const { order: insertOrder, stepId } = getParams();
    const insertStep = partialWorkObjectSteps.find(
      (step) => step.id === stepId,
    );

    const isFutureStep = insertStep.order > currentStepOrder;
    const isFutureOrder =
      insertStep.order === currentStepOrder && insertOrder > currentOrder;

    return isFutureStep || isFutureOrder;
  };

  const isReportDisabled =
    state.inspectionResult.value === null || isScheduledInspection();
  const isValidUntilDisabled =
    state.inspectionResult.value !== INSPECTION_RESULT.PASSED;

  return {
    state,
    isResourceSelectDisabled,
    isScheduledInspection,
    isReportDisabled,
    isValidUntilDisabled,

    inspectorTypeOptions,
    inspectionTargetOptions,
    inspectionResultOptions,
    getInspectionTypeOptions,

    userSetsInspectorType,
    userTypesInspectorName,
    userTypesInspectingCompany,

    userSelectsInspector,
    userSetsInspectionTarget,
    userSetsInspectionDate,
    userSetsInspectionWindow,
    userSetsValidUntil,
    userSetsInspectionResult,
    userSetsInspectionType,

    userUploadsInspectionReport,
    userResetsReport,
    isReportChanged,

    userUploadsDocuments,
    userTypesDocumentName,
    userDeletesDocument,
    userResetsDocuments,
    isDocumentListChanged,

    userClicksOpenInspectorModal,
    inspectorsModal,
    userClicksOpenAssetModal,
    assetsModal,
    userClicksOpenSourceModal,
    sourcesModal,

    userSubmitsForm,
  };
};

export default useInspectionThirdPartyForm;
