import Table from 'lib/components/table/Table';
import { getTypeLabel } from 'config/inspectionTypeType';
import InspectionTypesStatusPill from '../inspection-types-status-pill/InspectionTypesStatusPill';
import './InspectionTypesTable.scss';

const inspectionTypesListColumns = [
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
  {
    title: 'Name / Target',
    sortId: 'name',
    className: 'name-cell',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.name}</div>
        <div className='secondary-text'>{getTypeLabel(record?.type)}</div>
      </div>
    ),
  },
  {
    title: 'Inspections done',
    className: 'roles-cell',
    sortId: 'inspectionsDone',
    textAlign: 'right',
    render: (record) => record.inspectionsDone,
  },
  {
    title: 'Status',
    className: 'status-cell',
    sortId: 'status',
    textAlign: 'right',
    render: (record) => (
      <span className='pill-container'>
        <InspectionTypesStatusPill status={record.status} />
      </span>
    ),
  },
];

const InspectionTypesTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={inspectionTypesListColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='inspection-types-table'
    {...props}
  />
);

export default InspectionTypesTable;
