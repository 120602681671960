import makeReducer from 'lib/makeReducer';

export const INSPECTION_PLANS_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  APP_LOADS_OPTIONS: 'APP_LOADS_OPTIONS',
  RESET_STATE: 'RESET_STATE',

  USER_CLICKS_NEEDS_REVIEW: 'USER_CLICKS_NEEDS_REVIEW',
  USER_TYPES_REVIEW_NOTES: 'USER_TYPES_REVIEW_NOTES',
  USER_CANCELS_NEEDS_REVIEW: 'USER_CANCELS_NEEDS_REVIEW',
  USER_SUBMITS_NEEDS_REVIEW: 'USER_SUBMITS_NEEDS_REVIEW',
  APP_FAILS_NEEDS_REVIEW: 'APP_FAILS_NEEDS_REVIEW',
};

const getInitialNotesModalState = () => ({
  charsLeft: 1000,
  notes: '',
  isModalOpen: false,
});

const INITIAL_DETAIL_STATE = {
  plan: false,
  errors: [],
  loading: true,
  planOptions: false,
  questionOptions: false,

  notesModal: getInitialNotesModalState(),
};

const INSPECTION_PLANS_DETAIL_REDUCER_CONFIG = {
  [INSPECTION_PLANS_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      plan: action.payload,
      loading: false,
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.APP_LOADS_OPTIONS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.USER_CLICKS_NEEDS_REVIEW]: (state) => {
    return {
      ...state,
      notesModal: {
        ...state.notesModal,
        isModalOpen: true,
      },
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.USER_CANCELS_NEEDS_REVIEW]: (state) => {
    return {
      ...state,
      notesModal: getInitialNotesModalState(),
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.APP_FAILS_NEEDS_REVIEW]: (state) => {
    return {
      ...state,
      notesModal: {
        ...state.notesModal,
        errors: ['An error occurred while performing this operation.'],
      },
    };
  },
  [INSPECTION_PLANS_DETAIL_ACTIONS.USER_TYPES_REVIEW_NOTES]: (
    state,
    action,
  ) => {
    const notes = action.payload.substring(0, 1000);
    return {
      ...state,
      notesModal: {
        ...state.notesModal,
        notes,
        charsLeft: 1000 - notes.length,
      },
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_PLANS_DETAIL_REDUCER_CONFIG,
  INITIAL_DETAIL_STATE,
);
