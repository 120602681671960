import { castDateTimeToSimpleDate } from 'lib/dateHelpers';
import makeReducer from 'lib/makeReducer';

export const WORK_OBJECTS_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  USER_CLICKS_ON_TAB: 'USER_CLICKS_ON_TAB',

  USER_OPENS_INSPECTION_MODAL: 'USER_OPENS_INSPECTION_MODAL',
  USER_CANCELS_INSPECTION_MODAL: 'USER_CANCELS_INSPECTION_MODAL',

  USER_TYPES_PARTIAL_QUANTITY: 'USER_TYPES_PARTIAL_QUANTITY',
  USER_SETS_PARTIAL_DELIVERY_DATE: 'USER_SETS_PARTIAL_DELIVERY_DATE',
  USER_SUBMITS_PARTIAL: 'USER_SUBMITS_PARTIAL',

  USER_OPENS_RE_INSPECT_MODAL: 'USER_OPENS_RE_INSPECT_MODAL',
  USER_SETS_RE_INSPECT_AQL: 'USER_SETS_RE_INSPECT_AQL',
  USER_SUBMITS_RE_INSPECT: 'USER_SUBMITS_RE_INSPECT',
  USER_CANCELS_RE_INSPECT_MODAL: 'USER_CANCELS_RE_INSPECT_MODAL',
  APP_SUBMITS_RE_INSPECT_SUCCESSFULLY: 'APP_SUBMITS_RE_INSPECT_SUCCESSFULLY',
  APP_FINISHES_RE_INSPECT_SUBMISSION: 'APP_FINISHES_RE_INSPECT_SUBMISSION',

  USER_OPENS_PAIR_WINDOW_MODAL: 'USER_OPENS_PAIR_WINDOW_MODAL',
  USER_CLOSES_PAIR_WINDOW_MODAL: 'USER_CLOSES_PAIR_WINDOW_MODAL',
  USER_CHANGES_PAIR_WINDOW_RANGE: 'USER_CHANGES_PAIR_WINDOW_RANGE',
  SET_PAIR_WINDOW_MODAL_ERRORS: 'SET_PAIR_WINDOW_MODAL_ERRORS',

  USER_OPENS_CUSTOM_QTY_MODAL: 'USER_OPENS_CUSTOM_QTY_MODAL',
  USER_CLOSES_CUSTOM_QTY_MODAL: 'USER_CLOSES_CUSTOM_QTY_MODAL',
  USER_CHANGES_CUSTOM_QTY_FORM: 'USER_CHANGES_CUSTOM_QTY_FORM',
  SET_CUSTOM_QTY_MODAL_ERRORS: 'SET_CUSTOM_QTY_MODAL_ERRORS',

  USER_ACCEPTS_PARTIAL: 'USER_ACCEPTS_PARTIAL',
  APP_FINISHES_ACCEPTING_PARTIAL: 'APP_FINISHES_ACCEPTING_PARTIAL',

  USER_REJECTS_PARTIAL: 'USER_REJECTS_PARTIAL',
  APP_FINISHES_REJECTING_PARTIAL: 'APP_FINISHES_REJECTING_PARTIAL',

  USER_UPDATES_PARTIAL: 'USER_UPDATES_PARTIAL',
  APP_FINISHES_UPDATING_PARTIAL: 'APP_FINISHES_UPDATING_PARTIAL',

  USER_TAKES_INSPECTION_ACTION: 'USER_TAKES_INSPECTION_ACTION',
  APP_FINISHES_TAKING_INSPECTION_ACTION:
    'APP_FINISHES_TAKING_INSPECTION_ACTION',

  USER_CONFIRMS_INSPECTION_ACTION: 'USER_CONFIRMS_INSPECTION_ACTION',
  USER_TYPES_INSPECTION_ACTION_NOTE: 'USER_TYPES_INSPECTION_ACTION_NOTE',
  USER_CANCELS_INSPECTION_ACTION: 'USER_CANCELS_INSPECTION_ACTION',

  USER_SKIPS_INSPECTION: 'USER_SKIPS_INSPECTION',
  APP_FINISHES_SKIPPING_INSPECTION: 'APP_FINISHES_SKIPPING_INSPECTION',

  USER_PUBLISHES_INSPECTION: 'USER_PUBLISHES_INSPECTION',
  APP_FINISHES_PUBLISHING_INSPECTION: 'APP_FINISHES_PUBLISHING_INSPECTION',

  APP_SUBMITS_PARTIAL_SUCCESSFULLY: 'APP_SUBMITS_PARTIAL_SUCCESSFULLY',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',

  RESET_STATE: 'RESET_STATE',
};

const MAX_CHARS = {
  notes: 1000,
};

const getInitialTakeDecisionModalState = () => ({
  notes: {
    value: '',
    charsLeft: MAX_CHARS['notes'],
    errors: [],
  },
  action: null,
  selectedDecision: '',
  isModalOpen: false,
  isDirty: false,
});

const getInitialPairWindowModalState = () => ({
  value: ['', ''],
  pairId: null,
  errors: [],
  isModalOpen: false,
  isDirty: false,
});

const getInitialCustomQtyModalState = () => ({
  customInspectionQty: '',
  customInspectionQtyIsRelative: false,
  pairId: null,
  errors: [],
  isModalOpen: false,
  isDirty: false,
});

const INITIAL_DETAIL_STATE = {
  workObject: null,
  errors: [],
  loading: true,
  partialQuantity: { value: 1, errors: [] },
  deliveryDate: { value: '', errors: [] },
  currentReInspect: '',
  currentReInspectOptions: [],
  reInspectAql: { value: null, errors: [] },
  takeDecisionModal: getInitialTakeDecisionModalState(),
  pairWindowModal: getInitialPairWindowModalState(),
  customQtyModal: getInitialCustomQtyModalState(),
  isInspectionModalOpen: false,
  isEditingPartial: false,
  isReInspectModalOpen: false,
  isPartialLoading: false,
  isAcceptPartialsLoading: false,
  isRejectPartialsLoading: false,
  isSkipInspectionLoading: false,
  isUpdatingPartialLoading: false,
  isReInspectLoading: false,
  isInspectionActionLoading: false,
  isPartialDirty: false,
  currentTab: 0,
};

const WORK_OBJECTS_DETAIL_REDUCER_CONFIG = {
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      workObject: action.payload,
      loading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_INSPECTION_MODAL]: (
    state,
    action,
  ) => {
    return {
      ...state,
      isInspectionModalOpen: true,
      isEditingPartial: action.payload.isEditing,
      partialQuantity: {
        value: action.payload.isEditing
          ? state?.workObject?.partialWorkObjects[state.currentTab]?.quantity ??
          1
          : state?.workObject?.remainingQuantity ?? 1,
        errors: [],
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_RE_INSPECT_MODAL]: (
    state,
    action,
  ) => {
    return {
      ...state,
      isReInspectModalOpen: true,
      currentReInspect: action.payload.inspectionID,
      currentReInspectOptions: action.payload.reInspectionOptions,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_TYPES_PARTIAL_QUANTITY]: (
    state,
    action,
  ) => {
    const partialData = action.payload?.partialData;
    const maxQuantity = partialData?.isEditing
      ? Number(state.workObject?.remainingQuantity) +
      Number(partialData?.partialDefaultQuantity || 0)
      : Number(state.workObject?.remainingQuantity);
    const validQuantity =
      Number(action.payload.value) <= maxQuantity
        ? action.payload.value
        : maxQuantity;
    return {
      ...state,
      partialQuantity: { value: validQuantity, errors: [] },
      isPartialDirty: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_SETS_PARTIAL_DELIVERY_DATE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      deliveryDate: { value: action.payload, errors: [] },
      isPartialDirty: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_SETS_RE_INSPECT_AQL]: (state, action) => {
    return {
      ...state,
      reInspectAql: { value: action.payload, errors: [] },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_INSPECTION_MODAL]: (state) => {
    return {
      ...state,
      partialQuantity: { ...state.partialQuantity, value: 0 },
      deliveryDate: { value: '', errors: [] },
      isInspectionModalOpen: false,
      isEditingPartial: false,
      isPartialDirty: false,
      errors: [],
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_RE_INSPECT_MODAL]: (state) => {
    return {
      ...state,
      reInspectAql: { value: null, errors: [] },
      isReInspectModalOpen: false,
      currentReInspect: '',
      currentReInspectOptions: [],
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_SUBMITS_PARTIAL]: (state) => {
    return {
      ...state,
      isPartialLoading: true,
      isPartialDirty: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_SUBMITS_RE_INSPECT]: (state) => {
    return {
      ...state,
      isReInspectLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_ACCEPTS_PARTIAL]: (state) => {
    return {
      ...state,
      isAcceptPartialsLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_ACCEPTING_PARTIAL]: (state) => {
    return {
      ...state,
      isAcceptPartialsLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_REJECTS_PARTIAL]: (state) => {
    return {
      ...state,
      isRejectPartialsLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_REJECTING_PARTIAL]: (state) => {
    return {
      ...state,
      isRejectPartialsLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_UPDATES_PARTIAL]: (state) => {
    return {
      ...state,
      isUpdatingPartialLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_UPDATING_PARTIAL]: (state) => {
    return {
      ...state,
      isUpdatingPartialLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_TAKES_INSPECTION_ACTION]: (
    state,
    action,
  ) => {
    return {
      ...state,
      takeDecisionModal: {
        ...state.takeDecisionModal,
        action: action.payload,
        isModalOpen: true,
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CANCELS_INSPECTION_ACTION]: (state) => {
    return {
      ...state,
      takeDecisionModal: getInitialTakeDecisionModalState(),
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_TYPES_INSPECTION_ACTION_NOTE]: (
    state,
    action,
  ) => {
    const max = MAX_CHARS['notes'];
    if (max && action.payload.length > max) {
      return state;
    }
    return {
      ...state,
      takeDecisionModal: {
        ...state.takeDecisionModal,
        notes: {
          value: action.payload,
          errors: [],
          charsLeft: max && max - action.payload.length,
        },
        isDirty: true,
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CONFIRMS_INSPECTION_ACTION]: (state) => {
    return {
      ...state,
      takeDecisionModal: getInitialTakeDecisionModalState(),
      isInspectionActionLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_TAKING_INSPECTION_ACTION]: (
    state,
  ) => {
    return {
      ...state,
      isInspectionActionLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_SKIPS_INSPECTION]: (state) => {
    return {
      ...state,
      isSkipInspectionLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_SKIPPING_INSPECTION]: (state) => {
    return {
      ...state,
      isSkipInspectionLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_PUBLISHES_INSPECTION]: (state) => {
    return {
      ...state,
      isPublishInspectionLoading: true,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_PUBLISHING_INSPECTION]: (state) => {
    return {
      ...state,
      isPublishInspectionLoading: false,
    };
  },

  [WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_PAIR_WINDOW_MODAL]: (
    state,
    action,
  ) => {
    const pairId = action.payload;

    const pair = state.workObject.partialWorkObjects[
      state.currentTab
    ]?.partialWorkObjectSteps
      .map((s) => s.inspectionPairs)
      .flat()
      .find((p) => p.id === pairId);

    if (!pair) {
      return state;
    }

    return {
      ...state,
      pairWindowModal: {
        ...state.pairWindowModal,
        isModalOpen: true,
        value: [
          pair.inspectionWindowStartDate
            ? castDateTimeToSimpleDate(pair.inspectionWindowStartDate)
            : '',
          pair.inspectionWindowEndDate
            ? castDateTimeToSimpleDate(pair.inspectionWindowEndDate)
            : '',
        ],
        pairId,
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CLOSES_PAIR_WINDOW_MODAL]: (state) => {
    return {
      ...state,
      pairWindowModal: {
        ...state.pairWindowModal,
        isModalOpen: false,
        value: ['', ''],
        errors: [],
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CHANGES_PAIR_WINDOW_RANGE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      pairWindowModal: {
        ...state.pairWindowModal,
        value: action.payload,
        errors: [],
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.SET_PAIR_WINDOW_MODAL_ERRORS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      pairWindowModal: {
        ...state.pairWindowModal,
        errors: action.payload,
      },
    };
  },


  [WORK_OBJECTS_DETAIL_ACTIONS.USER_OPENS_CUSTOM_QTY_MODAL]: (
    state,
    action,
  ) => {
    const pairId = action.payload;

    const pair = state.workObject.partialWorkObjects[
      state.currentTab
    ]?.partialWorkObjectSteps
      .map((s) => s.inspectionPairs)
      .flat()
      .find((p) => p.id === pairId);

    if (!pair) {
      return state;
    }

    return {
      ...state,
      customQtyModal: {
        ...getInitialCustomQtyModalState(),
        isModalOpen: true,
        customInspectionQty: `${Number.isFinite(pair.customInspectionQty) ? pair.customInspectionQty : ''}`,
        customInspectionQtyIsRelative: !!pair.customInspectionQtyIsRelative,
        pairId,
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CLOSES_CUSTOM_QTY_MODAL]: (state) => {
    return {
      ...state,
      customQtyModal: {
        ...getInitialCustomQtyModalState(),
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CHANGES_CUSTOM_QTY_FORM]: (
    state,
    action,
  ) => {
    return {
      ...state,
      customQtyModal: {
        ...state.customQtyModal,
        customInspectionQty: action.payload.customInspectionQty ?? state.customQtyModal.customInspectionQty,
        customInspectionQtyIsRelative: action.payload.customInspectionQtyIsRelative ?? state.customQtyModal.customInspectionQtyIsRelative,
        errors: [],
      },
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.SET_CUSTOM_QTY_MODAL_ERRORS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      customQtyModal: {
        ...state.customQtyModal,
        errors: action.payload,
      },
    };
  },

  [WORK_OBJECTS_DETAIL_ACTIONS.USER_CLICKS_ON_TAB]: (state, action) => {
    return {
      ...state,
      currentTab: action.payload,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_SUBMITS_PARTIAL_SUCCESSFULLY]: (state) => {
    return {
      ...state,
      partialQuantity: { ...state.partialQuantity, value: 0 },
      deliveryDate: { value: '', errors: [] },
      isInspectionModalOpen: false,
      isEditingPartial: false,
      errors: [],
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_SUBMITS_RE_INSPECT_SUCCESSFULLY]: (
    state,
  ) => {
    return {
      ...state,
      reInspectAql: { value: null, errors: [] },
      isReInspectModalOpen: false,
      currentReInspect: '',
      currentReInspectOptions: [],
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      isPartialLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.APP_FINISHES_RE_INSPECT_SUBMISSION]: (state) => {
    return {
      ...state,
      isReInspectLoading: false,
    };
  },
  [WORK_OBJECTS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  WORK_OBJECTS_DETAIL_REDUCER_CONFIG,
  INITIAL_DETAIL_STATE,
);
