import { AppContext } from 'App';
import { ReactComponent as WarningCircleOutline } from 'assets/images/warning-circle-outline.svg';
import INSPECTION_STATUS from 'config/inspectionStatus';
import Breadcrumbs from 'lib/components/breadcrumbs/Breadcrumbs';
import Defect from 'lib/components/defect/Defect';
import InfoBox, { INFO_BOX_VARIANT } from 'lib/components/info-box/InfoBox';
import InspectionsResultPill from 'lib/components/inspections-result-pill/InspectionsResultPill';
import QuestionGroup from 'lib/components/question-group/QuestionGroup';
import Radio from 'lib/components/radio/Radio';
import Table from 'lib/components/table/Table';
import { formatDate } from 'lib/dateHelpers';
import _cloneDeep from 'lodash/cloneDeep';
import { inspectionToResultState } from 'modules/inspections/dataTransform';
import { DEFECT_FILTERS } from 'modules/inspections/inspectionsResultReducer';
import inspectionsService from 'modules/inspections/inspectionsService';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AnswerDetail from '../answer-detail/AnswerDetail';
import PhotosModal from '../photos-modal/PhotosModal';
import './InspectionsResultPage.scss';

const inspectionParams = {
  select: [
    'aqlLevel',
    'assetId',
    'criticalDefectAllowed',
    'criticalDefectRule',
    'criticalDefectValue',
    'criticalDefectsCountAll',
    'externalId',
    'finishedAt',
    'functionalDefect',
    'functionalDefectAllowed',
    'functionalDefectsCountAll',
    'id',
    'inspectionDate',
    'isThirdParty',
    'majorDefect',
    'majorDefectAllowed',
    'majorDefectsCountAll',
    'minorDefect',
    'minorDefectAllowed',
    'minorDefectsCountAll',
    'parentInspectionId',
    'partialQuantity',
    'quantity',
    'quantityUOM',
    'result',
    'showId',
    'sourceId',
    'status',
    'statusOrResult',
    'summary',
    'validUntil',
    'validityRange',
    'validityValue',
    'workObjectDeadline',
    'workObjectExternalId',
    'workObjectId',
  ],
  relations: [
    'actualDecisions',
    'asset.customMeasurements',
    'asset.images',
    'inProgressAssetSnapshot',
    'inProgressAssetSnapshot.customMeasurements',
    'inspectionPlan',
    'inspectionType',
    'inspectionType.usageDecisions',
    'inspector',
    'locationSource',
    'questionGroups',
    'questionGroups.questions.customExpectedUom',
    'source',
    'stepPair.partialStep.partialWorkObject',
    'usageDecisions',
  ],
  filters: {
    type: '',
    inspectionTypeId: '',
    status: '',
    atSource: '',
    atSourceCity: '',
    lastUsageDecision: '',
    inspectorId: '',
  },
};

const getCombinedResult = (inspections) => {
  let output = null;

  if (inspections?.length) {
    output = 'passed';

    for (let i = 0; i < inspections.length; i++) {
      if (inspections[i]?.result === 'failed') {
        output = 'failed';
        break;
      } else if (
        inspections[i]?.result !== 'passed' &&
        inspections[i]?.status !== 'finished'
      ) {
        output = null;
      }
    }
  }
  return output;
};

const getTotalSampleSize = (row) => {
  let rsltSampleSize = 0;

  row?.questionGroups?.forEach((qg) =>
    qg?.questions?.forEach((q) => {
      if (Number.isFinite(q?.sampleSize) && q.sampleSize > rsltSampleSize) {
        rsltSampleSize = q.sampleSize;
      }
    }),
  );

  return rsltSampleSize;
};

const getIsDefectAqlRejected = (inspection, defectType) => {
  let isReject = false;

  let allowedCount = null;
  let actualCount = null;

  if (['critical', 'major', 'minor', 'functional'].includes(defectType)) {
    allowedCount = inspection[`${defectType}DefectAllowed`];
    actualCount = inspection[`${defectType}DefectsCountAll`];
  } else if (defectType === 'functionalPlusMajor') {
    allowedCount =
      Number(inspection.majorDefectAllowed) +
      Number(inspection.functionalDefectAllowed) +
      1;
    actualCount =
      Number(inspection.majorDefectsCountAll) +
      Number(inspection.functionalDefectsCountAll);
  } else if (defectType === 'severe') {
    isReject = true;
  } else if (defectType === 'informational') {
    isReject = false;
  }

  if (allowedCount !== null && actualCount !== null) {
    isReject = actualCount > allowedCount;
  }

  return isReject;
};

const combinedDefectsSummaryDataInitial = {
  critical: {
    key: 'critical',
    weight: 'critical',
    label: 'Critical',
    count: 0,
    aqlRejected: false,
  },
  major: {
    key: 'major',
    weight: 'major',
    label: 'Major',
    count: 0,
    aqlRejected: false,
  },
  minor: {
    key: 'minor',
    weight: 'minor',
    label: 'Minor',
    count: 0,
    aqlRejected: false,
  },
  functional: {
    key: 'functional',
    weight: 'functional',
    label: 'Functional',
    count: 0,
    aqlRejected: false,
  },
  functionalPlusMajor: {
    key: 'functionalPlusMajor',
    weight: 'major',
    label: 'Functional & Major',
    count: 0,
    aqlRejected: false,
  },
};

const getCombinedDefectsSummaryData = (inspections) => {
  const output = _cloneDeep(combinedDefectsSummaryDataInitial);

  inspections?.forEach((inspection) => {
    if (
      inspection.status === 'finished' &&
      ['passed', 'failed'].includes(inspection.result)
    ) {
      Object.values(output).forEach((defData) => {
        if (defData.key !== 'functionalPlusMajor') {
          defData.count += inspection[`${defData.key}DefectsCountAll`] ?? 0;
        } else {
          defData.count +=
            (inspection.functionalDefectsCountAll ?? 0) +
            (inspection.majorDefectsCountAll ?? 0);
        }

        if (!defData.aqlRejected) {
          defData.aqlRejected = getIsDefectAqlRejected(inspection, defData.key);
        }
      });
    }
  });

  return output;
};

const questionToDefectWeights = (question) =>
  question.actualDefects.map(
    (defect) =>
      defect.weight ||
      question.defects.find((d) => d.id === defect.defectId)?.weight,
  );

const filterOptions = {
  all: {
    key: DEFECT_FILTERS.ALL,
    label: 'All',
    value: DEFECT_FILTERS.ALL,
    filter: () => true,
  },
  errors: {
    key: DEFECT_FILTERS.ERRORS,
    label: 'All errors',
    value: DEFECT_FILTERS.ERRORS,
    filter: (question) =>
      question.actualErrorWeight !== null ||
      questionToDefectWeights(question).length,
  },
  critical: {
    key: DEFECT_FILTERS.CRITICAL,
    label: 'Critical',
    value: DEFECT_FILTERS.CRITICAL,
    filter: (question) =>
      question.actualErrorWeight === 'critical' ||
      questionToDefectWeights(question).includes('critical'),
  },
  major: {
    key: DEFECT_FILTERS.MAJOR,
    label: 'Major',
    value: DEFECT_FILTERS.MAJOR,
    filter: (question) =>
      question.actualErrorWeight === 'major' ||
      questionToDefectWeights(question).includes('major'),
  },
  minor: {
    key: DEFECT_FILTERS.MINOR,
    label: 'Minor',
    value: DEFECT_FILTERS.MINOR,
    filter: (question) =>
      question.actualErrorWeight === 'minor' ||
      questionToDefectWeights(question).includes('minor'),
  },
  functional: {
    key: DEFECT_FILTERS.FUNCTIONAL,
    label: 'Functional',
    value: DEFECT_FILTERS.FUNCTIONAL,
    filter: (question) =>
      question.actualErrorWeight === 'functional' ||
      questionToDefectWeights(question).includes('functional'),
  },
};

const getCombinedFilters = (combinedDefectsSummaryData) =>
  Object.values(filterOptions).map((fo) => {
    let output = _cloneDeep(fo);
    if (
      output.key === 'errors' &&
      ['critical', 'major', 'minor', 'functional'].every(
        (defKey) => !(combinedDefectsSummaryData[defKey].count > 0),
      )
    ) {
      output.disabled = true;
    } else if (
      ['critical', 'major', 'minor', 'functional'].includes(output.key) &&
      !(combinedDefectsSummaryData[output.key].count > 0)
    ) {
      output.disabled = true;
    }

    return output;
  });

const inspectionsListColumns = [
  {
    title: 'Information',
    key: 'id',
    className: 'col-info',
    render: (row) => (
      <div className='col-inspection-block'>
        <div className='info-block info-block-id'>
          <Link
            className='inspection-id'
            to={`/inspections/${row.id}${
              row.status === INSPECTION_STATUS.FINISHED && !row.isThirdParty
                ? '/results'
                : ''
            }`}
            onClick={(e) => e.stopPropagation()}
            target='_blank'
          >
            {row.id}
          </Link>
          {!!row.statusOrResult && (
            <InspectionsResultPill status={row.statusOrResult} />
          )}
          <div className='inspection-name'>
            {row.inspectionType?.name ?? 'Inspection'}
          </div>
        </div>
        <div className='info-block info-block-aql'>
          AQL Level: {row?.aqlLevel ?? '-'}, Qty: {row?.quantity}
          {row?.quantityUOM?.length ? ` ${row?.quantityUOM}` : null}, Sample
          Size: {getTotalSampleSize(row)}
          {row?.quantityUOM?.length ? ` ${row?.quantityUOM}` : null}
        </div>
        <div className='info-block info-block-date'>
          {!!formatDate(row.finishedAt) ? 'Performed' : 'Scheduled'}:
          {!!row.finishedAt
            ? formatDate(row.finishedAt)
            : formatDate(row.inspectionDate)}{' '}
          {!!row?.inspector?.name &&
            `${formatDate(row.finishedAt) ? 'by' : 'for'} ${
              row?.inspector?.name
            }`}{' '}
        </div>
      </div>
    ),
  },
  {
    title: (
      <div className='defects-title-cell'>
        <div>Defects</div>
        <div className='defects-block-layout'>
          {Object.values(combinedDefectsSummaryDataInitial).map((def, i) => (
            <div key={i}>{def.label}</div>
          ))}
        </div>
      </div>
    ),
    className: 'col-defects',
    render: (row) => {
      return (
        row.status === 'finished' && (
          <div className='defects-block-layout'>
            {Object.values(getCombinedDefectsSummaryData([row])).map(
              (
                defData,
                i, // TODO: accepted max - add logic here
              ) => (
                <div key={i}>
                  <Defect
                    count={defData.count}
                    weight={defData.weight}
                    isAqlRejected={defData.aqlRejected}
                    iconOnly
                  />
                </div>
              ),
            )}
          </div>
        )
      );
    },
  },
];

export default function InspectionsResultPageMulti({ ids }) {
  const location = useLocation();
  const { appState } = useContext(AppContext);

  const isEnableInspectionFunctionalPlusMajor =
    !!appState?.company?.customConfig?.enableInspectionFunctionalPlusMajor;
  const [inspectionsData, setInspectionsData] = useState(null);
  const [questionsFilterValue, setQuestionsFilterValue] = useState('errors');
  const [photosModalData, setPhotosModalData] = useState(null);
  const [isMissingAssetSnapshots, setIsMissingAssetSnapshots] = useState(false);

  const doOpenPhotosModal = (photos, idx) => {
    if (photos?.length) {
      setPhotosModalData({
        photos,
        initialIndex: idx,
      });
    }
  };

  useEffect(() => {
    let _isMissingAssetSnapshots = false;
    inspectionsService
      .getInspections({
        ...inspectionParams,
        filters: {
          ids,
        },
      })
      .then((resInsp) => {
        if (resInsp?.data?.length) {
          inspectionsService
            .getInspectionPlanOptions()
            .then((resOpts) => {
              const augmentedInspectionsData = Object.keys(resOpts)?.length
                ? resInsp.data.map((insp) => {
                    _isMissingAssetSnapshots =
                      _isMissingAssetSnapshots ||
                      (!!insp?.asset && !insp?.inProgressAssetSnapshot);
                    return inspectionToResultState(insp, resOpts);
                  })
                : resInsp.data;
              setInspectionsData(augmentedInspectionsData);
              setIsMissingAssetSnapshots(_isMissingAssetSnapshots);
            })
            .catch((e) => {
              console.log('Could not fetch options', e);
              setInspectionsData(resInsp.data);
              setIsMissingAssetSnapshots(_isMissingAssetSnapshots);
            });
        }
      })
      .catch((e) => {
        console.log('Could not fetch inspections', e);
        setInspectionsData(null);
        setIsMissingAssetSnapshots(false);
      });
  }, [JSON.stringify(ids)]);

  const combinedResult = getCombinedResult(inspectionsData);
  const combinedDefectsSummaryData =
    getCombinedDefectsSummaryData(inspectionsData);
  const breadcrumbs = [
    {
      name: 'Inspections',
      link: '/inspections',
    },
    {
      name: `Combined Inspections Results`,
      link: `${location.pathname}${location.search}`,
    },
  ];

  const combinedFilters = getCombinedFilters(combinedDefectsSummaryData);

  const filteredInspectionsData = useMemo(() => {
    let output = inspectionsData
      ? inspectionsData.filter((inspection) => inspection.status === 'finished')
      : [];

    if (
      output?.length &&
      questionsFilterValue &&
      questionsFilterValue !== filterOptions.all.key
    ) {
      output = output
        .map((inspection) => ({
          ...inspection,
          questionGroups: inspection?.questionGroups
            ?.map((qg) => ({
              ...qg,
              questions: qg?.questions
                ?.filter((q) => {
                  return filterOptions[questionsFilterValue].filter(q);
                })
                .sort((a, b) => a.order - b.order),
            }))
            .filter((qg) => !!qg?.questions?.length)
            .sort((a, b) => a.order - b.order),
        }))
        .filter((inspection) => !!inspection?.questionGroups?.length);
    }

    return output;
  }, [inspectionsData, questionsFilterValue]);

  return (
    <div className='inspection-results-page inspection-results-page-multi container-m'>
      <Breadcrumbs data={breadcrumbs} />
      <div className='page-header-container'>
        <div className='page-header'>
          <h2>
            Combined Inspections Results
            {!!combinedResult && (
              <InspectionsResultPill status={combinedResult} />
            )}
          </h2>
          <div className='defect-summary'>
            {Object.values(combinedDefectsSummaryData)
              ?.filter(
                (defData) =>
                  defData.count > 0 &&
                  (defData.key !== 'functionalPlusMajor' ||
                    isEnableInspectionFunctionalPlusMajor),
              )
              ?.map((defData, i) => (
                <Defect
                  key={i}
                  count={defData.count}
                  weight={defData.weight}
                  label={defData.label}
                  isAqlRejected={defData.aqlRejected}
                />
              ))}
          </div>
        </div>
      </div>
      <Table
        className='inspection-results-table'
        data={inspectionsData?.length ? inspectionsData : []}
        columns={inspectionsListColumns}
      />
      <h4>Questions</h4>
      <Radio
        options={combinedFilters}
        onChange={(v) => setQuestionsFilterValue(v)}
        value={questionsFilterValue ?? filterOptions.all.key}
        hideCheckbox
      />
      {isMissingAssetSnapshots && (
        <InfoBox
          className='no-asset-snapshot-warning'
          icon={<WarningCircleOutline />}
          variant={INFO_BOX_VARIANT.WARNING}
        >
          This inspection doesn't contain the historical record of the Asset at
          the time the inspection was started. Quantitative answer differences
          will be shown compared to the current Asset values.
        </InfoBox>
      )}
      <div className='questions-container'>
        {filteredInspectionsData?.map((inspection, inspectionIdx) => (
          <div className='inspection-wrap' key={inspectionIdx}>
            <div className='inspection-title'>
              <h5>
                Inspection ID: {inspection.id}:{' '}
                {inspection?.inspectionType?.name ?? 'Inspection'}
              </h5>
              <h6>
                Asset: {inspection?.asset?.name ?? inspection?.source?.name}
              </h6>
            </div>
            <div className='inspection-groups-wrap'>
              {inspection?.questionGroups?.map((qg, qgIdx) => (
                <QuestionGroup key={qgIdx} hideOrder data={qg}>
                  {qg?.questions?.map((q, qIdx) => (
                    <AnswerDetail
                      initialOpen={true}
                      key={qIdx}
                      data={q}
                      inspection={inspection}
                      onPhotoClick={doOpenPhotosModal}
                    />
                  ))}
                </QuestionGroup>
              ))}
            </div>
          </div>
        ))}
      </div>

      <PhotosModal
        onCancel={() => setPhotosModalData(null)}
        isOpen={!!photosModalData?.photos?.length}
        data={{
          photos: photosModalData?.photos?.length ? photosModalData.photos : [],
          initialIndex: photosModalData?.initialIndex ?? 0,
        }}
      />
    </div>
  );
}
