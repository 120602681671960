import ROLES from 'config/roles';

export const VIEW_ALL_INSPECTION_TYPES_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const ADD_INSPECTION_TYPES_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];
