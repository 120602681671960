import SOURCE_STATUS from 'config/sourceStatus';
import makeReducer from 'lib/makeReducer';
import { cloneDeep } from 'lodash';

export const MAX_FIELD_CHARS = {
  name: 50,
  zipCode: 10,
  state: 100,
  address: 255,
  district: 255,
  city: 255,
  externalId: 50,
};

const getInitialModalState = () => ({
  list: [],
  count: 0,
  sortBy: 'id',
  sortOrder: 'ASC',
  page: 1,
  pageSize: 10,
  search: '',
  selected: [],
  isModalOpen: false,
});

const INITIAL_FORM_STATE = {
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  externalId: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.externalId,
  },
  type: {
    value: null,
    errors: [],
  },
  tags: {
    value: [],
    errors: [],
  },
  status: {
    value: SOURCE_STATUS.DRAFT,
    errors: [],
  },
  address: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.address,
  },
  zipCode: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.zipCode,
  },
  district: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.district,
  },
  city: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.city,
  },
  state: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.state,
  },
  country: {
    value: '',
    errors: [],
  },
  images: { values: [], errors: [] },
  initialImages: [],
  availableOwners: getInitialModalState(),
  owners: [],
  initialOwners: [],
  errors: [],
  loading: false,
  id: null,
  map: {
    zoom: 1,
    center: {
      lat: 0,
      lng: 0,
    },
  },
  isDirty: false,
};

export const SOURCES_FORM_ACTIONS = {
  USER_TYPES_NAME: 'USER_TYPES_NAME',
  USER_TYPES_EXTERNAL_ID: 'USER_TYPES_EXTERNAL_ID',
  USER_SELECTS_TYPE: 'USER_SELECTS_TYPE',
  USER_SELECTS_STATUS: 'USER_SELECTS_STATUS',
  USER_TYPES_ADDRESS: 'USER_TYPES_ADDRESS',
  USER_TYPES_ZIPCODE: 'USER_TYPES_ZIPCODE',
  USER_TYPES_DISTRICT: 'USER_TYPES_DISTRICT',
  USER_TYPES_CITY: 'USER_TYPES_CITY',
  USER_TYPES_STATE: 'USER_TYPES_STATE',
  USER_SELECTS_COUNTRY: 'USER_SELECTS_COUNTRY',
  USER_SETS_TAGS: 'USER_SETS_TAGS',
  RESET_STATE: 'RESET_STATE',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  USER_OPENS_OWNERS_MODAL: 'USER_OPENS_OWNERS_MODAL',
  USER_ADDS_IMAGE: 'USER_ADDS_IMAGE',
  USER_REMOVES_IMAGE: 'USER_REMOVES_IMAGE',
  USER_CANCELS_IMAGE_CHANGES: 'USER_CANCELS_IMAGE_CHANGES',
  APP_FAILS_IMAGE_UPLOAD: 'APP_FAILS_IMAGE_UPLOAD',
  USER_TOGGLES_NEW_OWNER: 'USER_TOGGLES_NEW_OWNER',
  APP_LOADS_OWNERS: 'APP_LOADS_OWNERS',
  USER_SEARCHES_OWNERS: 'USER_SEARCHES_OWNERS',
  USER_SORTS_OWNERS: 'USER_SORTS_OWNERS',
  USER_ASSIGNS_NEW_OWNERS: 'USER_ASSIGNS_NEW_OWNERS',
  USER_SETS_OWNERS_PAGE: 'USER_SETS_OWNERS_PAGE',
  USER_CANCELS_OWNER_CHANGES: 'USER_CANCELS_OWNER_CHANGES',
  USER_CANCELS_OWNERS_MODAL: 'USER_CANCELS_OWNERS_MODAL',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  APP_LOADS_GEOCODE: 'APP_LOADS_GEOCODE',
  APP_FAILS_GEOCODE: 'APP_FAILS_GEOCODE',
  USER_SETS_MARKER: 'USER_SETS_MARKER',
  USER_MOVES_MAP: 'USER_MOVES_MAP',
};

const setTextValue = (key, state, action) => {
  if (action.payload.length > MAX_FIELD_CHARS[key]) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: MAX_FIELD_CHARS[key] - action.payload.length,
    },
    isDirty: true,
  };
};

const setSelectValue = (key, state, action) => {
  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
    },
    isDirty: true,
  };
};

const SOURCES_FORM_REDUCER_CONFIG = {
  [SOURCES_FORM_ACTIONS.USER_TYPES_NAME]: (state, action) => {
    return setTextValue('name', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_EXTERNAL_ID]: (state, action) => {
    return setTextValue('externalId', state, action);
  },
  [SOURCES_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [SOURCES_FORM_ACTIONS.USER_ADDS_IMAGE]: (state, action) => {
    return { ...state, images: { ...action.payload }, isDirty: true };
  },
  [SOURCES_FORM_ACTIONS.USER_REMOVES_IMAGE]: (state, action) => {
    return { ...state, images: { ...action.payload }, isDirty: true };
  },
  [SOURCES_FORM_ACTIONS.USER_CANCELS_IMAGE_CHANGES]: (state) => {
    return {
      ...state,
      images: {
        values: state.initialImages,
        errors: [],
      },
    };
  },
  [SOURCES_FORM_ACTIONS.APP_FAILS_IMAGE_UPLOAD]: (state) => {
    return {
      ...state,
      images: {
        values: state.images.values,
        errors: [
          'An error occured while uploading the images. Please try again.',
        ],
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SELECTS_TYPE]: (state, action) => {
    return setSelectValue('type', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_SELECTS_STATUS]: (state, action) => {
    return setSelectValue('status', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_ADDRESS]: (state, action) => {
    return setTextValue('address', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_ZIPCODE]: (state, action) => {
    return setTextValue('zipCode', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_DISTRICT]: (state, action) => {
    return setTextValue('district', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_CITY]: (state, action) => {
    return setTextValue('city', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_TYPES_STATE]: (state, action) => {
    return setTextValue('state', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_SELECTS_COUNTRY]: (state, action) => {
    return setSelectValue('country', state, action);
  },
  [SOURCES_FORM_ACTIONS.USER_SETS_TAGS]: (state, action) => {
    return {
      ...state,
      tags: { errors: [], value: action.payload },
      isDirty: true,
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return { ...state, loading: true, isDirty: false };
  },
  [SOURCES_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [SOURCES_FORM_ACTIONS.USER_OPENS_OWNERS_MODAL]: (state) => {
    const selected = cloneDeep(state.owners.map((owner) => owner.value));

    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        isModalOpen: true,
        selected,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SORTS_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        ...action.payload,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.APP_LOADS_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.APP_LOADS_GEOCODE]: (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        center: action.payload,
        zoom: 13,
        errors: [],
      },
      coordinates: action.payload.lat && action.payload.lng ? {
        x: action.payload.lat,
        y: action.payload.lng,
      } : null,
    };
  },
  [SOURCES_FORM_ACTIONS.APP_FAILS_GEOCODE]: (state, action) => {
    return {
      ...state,
      map: {
        ...state.map,
        errors: [action.payload],
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SETS_MARKER]: (state, action) => {
    return {
      ...state,
      coordinates: {
        x: action.payload.lat,
        y: action.payload.lng,
      },
      isDirty: true,
    };
  },
  [SOURCES_FORM_ACTIONS.USER_MOVES_MAP]: (state, action) => {
    const { zoom, center } = action.payload;
    if (
      zoom === state.map.zoom &&
      center.lat === state.map.center.lat &&
      center.lng === state.map.center.lng
    ) {
      return state;
    }
    return {
      ...state,
      map: {
        ...state.map,
        ...action.payload,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SEARCHES_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        search: action.payload,
        page: 1,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_SETS_OWNERS_PAGE]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        page: action.payload,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_TOGGLES_NEW_OWNER]: (state, action) => {
    const newSelected = cloneDeep(state.availableOwners.selected);
    const idx = newSelected.findIndex(
      (owner) => owner.id === action.payload.id,
    );

    if (idx !== -1) {
      newSelected.splice(idx, 1);
    } else {
      newSelected.push(action.payload);
    }
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        selected: newSelected,
      },
    };
  },
  [SOURCES_FORM_ACTIONS.USER_REMOVES_OWNER]: (state, action) => {
    return {
      ...state,
      owners: [
        ...state.owners.filter((owner) => owner.value.id !== action.payload.id),
      ],
      isDirty: true,
    };
  },
  [SOURCES_FORM_ACTIONS.USER_CANCELS_OWNER_CHANGES]: (state) => {
    return {
      ...state,
      owners: state.initialOwners.map((owner) => ({
        value: owner,
        errors: [],
      })),
    };
  },
  [SOURCES_FORM_ACTIONS.USER_ASSIGNS_NEW_OWNERS]: (state) => {
    const newOwners = cloneDeep(state.availableOwners.selected);
    return {
      ...state,
      owners: newOwners.map((owner) => ({ value: owner, errors: [] })),
      availableOwners: getInitialModalState(),
      isDirty: true,
    };
  },
  [SOURCES_FORM_ACTIONS.USER_CANCELS_OWNERS_MODAL]: (state) => {
    return {
      ...state,
      availableOwners: getInitialModalState(),
    };
  },
};

export const { reducer, initialState } = makeReducer(
  SOURCES_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
