const INSPECTION_TYPE_TYPE = {
  ASSET: 'asset',
  SOURCE: 'source',
};

const INSPECTION_TYPE_TYPE_LABEL = {
  [INSPECTION_TYPE_TYPE.ASSET]: 'Asset',
  [INSPECTION_TYPE_TYPE.SOURCE]: 'Source',
};

export const getTypeLabel = (type) => INSPECTION_TYPE_TYPE_LABEL[type] || '';

export default INSPECTION_TYPE_TYPE;
