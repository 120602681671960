import React from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import './Dropdown.scss';

function Dropdown(
  { children, className, containerClassName, content, ...props },
  ref,
) {
  return (
    <Tippy
      trigger='click'
      interactive={true}
      className={classNames('qm-dropdown', className)}
      placement='bottom'
      content={content}
      {...props}
    >
      <span
        className={classNames('qm-dropdown-container', containerClassName)}
        data-testid='Dropdown.container'
        ref={ref}
      >
        {children}
      </span>
    </Tippy>
  );
}

export default React.forwardRef(Dropdown);
