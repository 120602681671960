import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import SOURCE_STATUS, { getSourceStatusLabel } from 'config/sourceStatus';

const SOURCE_STATUS_TO_PILL_VARIANT = {
  [SOURCE_STATUS.DRAFT]: STATUS_PILL_VARIANT.WARNING,
  [SOURCE_STATUS.ACTIVE]: STATUS_PILL_VARIANT.SUCCESS,
  [SOURCE_STATUS.DEACTIVATED]: STATUS_PILL_VARIANT.ERROR,
};

const SourceStatusPill = ({ status = SOURCE_STATUS.DRAFT }) => {
  const variant = SOURCE_STATUS_TO_PILL_VARIANT[status];

  return (
    <StatusPill variant={variant}>{getSourceStatusLabel(status)}</StatusPill>
  );
};

export default SourceStatusPill;
