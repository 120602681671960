import debounce from 'lodash.debounce';

import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';

import {
  reducer,
  initialState,
  SETTINGS_QUESTIONS_ACTIONS,
} from './settingsQuestionsListReducer';
import settingsService from './settingsService';
import { questionToDetailState } from 'lib/components/question-detail/dataTransform';
import useQuestionOptions from 'lib/questionOptionsService';
import { useEffect } from 'react';
import parseQueryString from 'lib/parseQueryString';
import { downloadResponse } from 'lib/download';

const fetchQuestionTemplates = debounce(
  (params, options, dispatch) => {
    return settingsService
      .getQuestionTemplates(params)
      .then((response) => {
        dispatch({
          type: LIST_ACTIONS.APP_LOADS_DATA,
          payload: {
            data: response.data.map((question) =>
              questionToDetailState(question, options),
            ),
            count: response.count,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: LIST_ACTIONS.SET_ERRORS,
          payload: e.message,
        });
      })
      .finally(() => {
        dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
      });
  },
  { leading: 400, trailing: 400 },
);

const useSettingsQuestionsList = ({ location, history }) => {
  const { questionOptions } = useQuestionOptions();
  const { dispatch, state, ...list } = useQueryList(
    location,
    history,
    reducer,
    initialState,
  );

  useEffect(() => {
    if (Object.keys(questionOptions).length) {
      fetchQuestionTemplates(
        parseQueryString(location.search),
        questionOptions,
        dispatch,
      );
    }
  }, [questionOptions, location.search]);

  const userClicksEditQuestion = (id) => {
    history.push(`/settings/questions/${id}/edit`);
  };

  const userClicksAddQuestion = () => {
    history.push('/settings/questions/add');
  };

  const userClicksExportQuestions = () => {
    dispatch({
      type: SETTINGS_QUESTIONS_ACTIONS.APP_STARTS_EXPORTING_QUESTIONS,
    });
    settingsService
      .exportSettingsQuestions()
      .then((data) => {
        downloadResponse(data, 'questions.csv');
      })
      .finally(() =>
        dispatch({
          type: SETTINGS_QUESTIONS_ACTIONS.APP_FINISHES_EXPORTING_QUESTIONS,
        }),
      );
  };

  const userClicksExportQuestionDefects = () => {
    dispatch({
      type: SETTINGS_QUESTIONS_ACTIONS.APP_STARTS_EXPORTING_QUESTIONS,
    });
    settingsService
      .exportSettingsQuestionDefects()
      .then((data) => {
        downloadResponse(data, 'question-defects.csv');
      })
      .finally(() =>
        dispatch({
          type: SETTINGS_QUESTIONS_ACTIONS.APP_FINISHES_EXPORTING_QUESTIONS,
        }),
      );
  };

  return {
    ...list,
    state,
    dispatch,
    userClicksAddQuestion,
    userClicksEditQuestion,
    userClicksExportQuestions,
    userClicksExportQuestionDefects,
  };
};

export default useSettingsQuestionsList;
