import * as yup from 'yup';
import _get from 'lodash.get';
import { validator } from 'lib/validator';
import { API_ERROR_CODES, ERROR_TEMPLATES } from 'config/errorCodes';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';

yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (item) => _get(item, path)?.toLowerCase();
    const filtered = list.map(mapper).filter((val) => val !== '');
    const set = [...new Set(filtered)];
    const isUnique = filtered.length === set.length;
    if (isUnique) {
      return true;
    }

    const fieldIndex = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `${this.path}[${fieldIndex}].${path}`,
      message,
      params: {
        originalValue: _get(list[fieldIndex], path),
      },
    });
  });
});

export const companyAttributeOptionSchema = yup.object().shape({
  isRemovable: yup.boolean(),
  name: yup.string().when('isRemovable', {
    is: (rem) => !rem,
    then: yup.string().required('Saved option cannot be removed'),
  }),
});

export const companyAttributesListSchema = yup.object().shape({
  label: yup.object().shape({
    value: yup.string().required(ERROR_TEMPLATES.required),
  }),
  options: yup
    .array()
    .of(companyAttributeOptionSchema)
    .unique('Option needs to be unique', 'name'),
});

const apiPathToFormPath = (path) =>
  path.replace('company-attributes.name', 'label');

const companyAttributesErrors = {
  ...API_ERROR_CODES,
  'company-attributes.unique':
    'Company attribute name must be unique within the company',
};

export const processCompanyAttributesAPIError = makeAPIBodyErrorProcessor({
  errorCodes: companyAttributesErrors,
  transformPath: apiPathToFormPath,
});

const settingsCompanyAttributesListValidator = (state) =>
  validator(state, companyAttributesListSchema);

export default settingsCompanyAttributesListValidator;
