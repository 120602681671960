import { useCallback } from 'react';
import useList from 'lib/list-helper/useList';
import {
  reducer,
  initialState,
  SELECT_MODAL_ACTIONS,
} from './listSelectModalReducer';
import { noop } from 'lib/funcHelpers';

const useListSelectModal = (fetchData = noop, onSelect = noop) => {
  const { state, dispatch, sortBy, setPage, setSearch } = useList(
    reducer,
    initialState,
    fetchData,
  );

  const onRequestClose = useCallback(() => {
    dispatch({
      type: SELECT_MODAL_ACTIONS.CLOSE_MODAL,
    });
  }, [dispatch]);

  const onRowClick = (object) => {
    dispatch({
      type: SELECT_MODAL_ACTIONS.CLOSE_MODAL,
    });
    onSelect(object);
  };

  const getModalProps = () => ({
    data: state.data,
    isOpen: state.isOpen,
    sortBy: state.sortBy,
    setSortBy: sortBy,
    sortOrder: state.sortOrder,
    setPage,
    page: state.page,
    count: state.count,
    onSearchChange: setSearch,
    pageSize: state.pageSize,
    onRequestClose,
    onRowClick,
  });

  return {
    state,
    dispatch,
    sortBy,
    setPage,
    setSearch,
    getModalProps,
    onSelect,
  };
};

export default useListSelectModal;
