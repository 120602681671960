import INSPECTION_STATUS, {
  REASSIGNABLE_STATUS_LIST,
} from 'config/inspectionStatus';
import {
  ANSWER_TYPE,
  DEPENDENCY,
  MULTIPLE_CHOICE_ANSWER_TYPES,
  PHOTO_REQUIRED,
} from 'config/questionOptions';

import { getTranslation } from 'lib/dataTransform';
import { castDateTimeToSimpleDate } from 'lib/dateHelpers';
import { INSPECTION_TARGET } from 'config/inspectionConfig';
import { MAX_FIELD_CHARS } from './performInspectionReducer';
import { fileToFormObject } from 'lib/components/document-upload/dataTransform';

import { REASSIGN_INSPECTION_ROLES } from './inspectionsPermissions';

export const resourceToFormState = (resource, type) => ({
  ...resource,
  resourceType: type,
});

export const workObjectAtachedToFormState = (data, partialId, stepId) => {
  // Find partial work object in an work object and get data related to it
  const partialWorkObject = (data?.partialWorkObjects || []).find(
    (partial) => partial?.id === partialId,
  );

  const step = (partialWorkObject?.partialWorkObjectSteps || []).find(
    (step) => step.id === stepId,
  );

  const locationSource = step ? step.source : null;

  return {
    partial: partialWorkObject,
    linkedResource: {
      value: data.asset
        ? { ...data.asset, resourceType: 'asset' }
        : { ...data.source, resourceType: 'source' },
      errors: [],
    },
    source: {
      value: locationSource ? locationSource : null,
      errors: [],
    },
  };
};

export const workObjectAtachedToExternalFormState = (
  data,
  partialId,
  stepId,
) => {
  // Find partial work object in an work object and get data related to it
  const partialWorkObject = (data?.partialWorkObjects || []).find(
    (partial) => partial?.id === partialId,
  );

  const step = (partialWorkObject?.partialWorkObjectSteps || []).find(
    (step) => step.id === stepId,
  );

  const locationSource = step ? step.source : null;

  return {
    partial: partialWorkObject,
    inspectionTarget: {
      value: data.asset ? INSPECTION_TARGET.ASSET : INSPECTION_TARGET.SOURCE,
      errors: [],
    },
    asset: {
      value: data.asset || null,
      errors: [],
    },
    source: {
      value: data.asset ? locationSource : data.source,
      errors: [],
    },
  };
};

export const formStatetoPOSTParams = (state) => ({
  status: state.status.value,

  asset:
    state.linkedResource.value?.resourceType === 'asset'
      ? {
          id: state.linkedResource.value.id,
        }
      : undefined,
  // asset inspection site (source)
  locationSource:
    state.linkedResource.value?.resourceType === 'asset'
      ? {
          id: state.source.value?.id,
        }
      : undefined,

  source:
    state.linkedResource.value?.resourceType === 'source'
      ? {
          id: state.linkedResource.value.id,
        }
      : undefined,

  inspectionType: { id: state.type.value.value },
  inspectionPlan: { id: state.plan.value.id },
  inspectionDate: state.date.value || undefined,
  inspectionWindowStartDate: state.inspectionWindow.value[0] || null,
  inspectionWindowEndDate: state.inspectionWindow.value[1] || null,
  inspector: state.inspector.value
    ? { id: state.inspector.value.id }
    : undefined,
});

export const thirdPartyInspectionToFormState = (res) => {
  const isAssetInspection = !!res.asset;

  return {
    id: res.id,
    inspectionType: {
      value: {
        value: res.inspectionType.id,
        label: res.inspectionType.name,
      },
      errors: [],
    },
    inspector: {
      value: res.inspector,
      errors: [],
    },
    inspectorType: {
      value: res.inspectorType,
      errors: [],
    },
    source: {
      value: isAssetInspection ? res.locationSource : res.source,
      errors: [],
    },
    asset: {
      value: isAssetInspection ? res.asset : null,
      errors: [],
    },
    inspectionDate: {
      value: res.inspectionDate,
      errors: [],
    },
    inspectionWindow: {
      value: [
        res.inspectionWindowStartDate
          ? castDateTimeToSimpleDate(res.inspectionWindowStartDate)
          : '',
        res.inspectionWindowEndDate
          ? castDateTimeToSimpleDate(res.inspectionWindowEndDate)
          : '',
      ],
      errors: [],
    },
    validUntil: {
      value: !!res.validUntil ? castDateTimeToSimpleDate(res.validUntil) : '',
      errors: [],
    },
    thirdPartyInspectorName: {
      value: res.thirdPartyInspectorName,
      errors: [],
    },
    thirdPartyInspectingCompany: {
      value: res.thirdPartyInspectingCompany,
      errors: [],
    },
    inspectionTarget: {
      value: isAssetInspection
        ? INSPECTION_TARGET.ASSET
        : INSPECTION_TARGET.SOURCE,
      errors: [],
    },
    inspectionReport: {
      value: res.externalReports?.[0]?.documentName,
      file: res.externalReports?.[0],
      errors: [],
    },
    initialInspectionReport: res.externalReports?.[0]
      ? {
          value: res.externalReports[0].documentName,
          file: res.externalReports[0],
          errors: [],
        }
      : null,
    documents: {
      list: res.referenceDocuments.map(fileToFormObject),
      errors: [],
    },
    initialDocuments: res.referenceDocuments.map(fileToFormObject),
    workObjectId: res.workObjectId,
    inspectionResult: {
      value: res.result,
      errors: [],
    },
  };
};

export const thirdPartyFormStateToPOSTParams = (state) => {
  const isAssetInspection =
    state.inspectionTarget.value === INSPECTION_TARGET.ASSET;
  const isFinished = state.inspectionResult.value !== null;
  const isPassed = state.inspectionResult.value === 'passed';

  return {
    id: state.id,
    inspectionType: {
      id: state.inspectionType.value?.value,
    },
    inspectorType: state.inspectorType.value,
    source: isAssetInspection
      ? undefined
      : {
          id: state.source.value?.id,
        },
    locationSource: isAssetInspection
      ? {
          id: state.source.value?.id,
        }
      : undefined,
    asset: isAssetInspection
      ? {
          id: state.asset.value?.id,
        }
      : undefined,
    inspectionDate: state.inspectionDate.value || null,
    inspectionWindowStartDate: state.inspectionWindow.value[0] || null,
    inspectionWindowEndDate: state.inspectionWindow.value[1] || null,
    validUntil: isPassed ? state.validUntil.value || null : null,
    inspector: state.inspector.value,
    thirdPartyInspectorName: state.thirdPartyInspectorName.value,
    thirdPartyInspectingCompany: state.thirdPartyInspectingCompany.value,
    result: state.inspectionResult.value,
    referenceDocuments: state.documents.list
      .filter((doc) => doc.file.url)
      .map((doc) => doc.file),
    externalReports:
      isFinished && state.inspectionReport.file?.url
        ? [state.inspectionReport.file]
        : [],
  };
};

export const listToBulkInspectorPOSTState = (state) => {
  return {
    ids: [...state.selectedInspections.map((i) => i.id)],
    body: {
      inspector: state.availableInspectors.selectedInspector
        ? {
            id: state.availableInspectors.selectedInspector.id,
          }
        : undefined,
      inspectionDate: state.inspectionDate || undefined,
    },
  };
};

export const updateDeleteParams = (state) => ({
  inspectionDate: state.date.value || null,
  inspector: state.inspector.value ? { id: state.inspector.value.id } : null,
});

export const inspectionToFormState = (data) => {
  return {
    id: data.id,
    linkedResource: {
      value: data.asset
        ? { ...data.asset, resourceType: 'asset' }
        : { ...data.source, resourceType: 'source' },
      errors: [],
    },
    source: {
      value: data.asset ? data.locationSource : null,
      errors: [],
    },
    status: {
      value: data.status,
      errors: [],
    },
    type: {
      value: data.inspectionType
        ? { label: data.inspectionType.name, value: data.inspectionType.id }
        : null,
      errors: [],
    },
    plan: {
      value: data.inspectionPlan,
      errors: [],
    },
    date: {
      value: data.inspectionDate
        ? castDateTimeToSimpleDate(data.inspectionDate)
        : '',
      errors: [],
    },
    inspectionWindow: {
      value: [
        data.inspectionWindowStartDate
          ? castDateTimeToSimpleDate(data.inspectionWindowStartDate)
          : '',
        data.inspectionWindowEndDate
          ? castDateTimeToSimpleDate(data.inspectionWindowEndDate)
          : '',
      ],
      errors: [],
    },
    workObjectId: data.workObjectId,
    inspector: {
      value: data.inspector ? data.inspector : null,
      errors: [],
    },
  };
};

export const inspectionToPerformInspectionState = (inspection) => ({
  ...inspection,
  summary: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS['summary'],
  },
  questionGroups: inspection.questionGroups.map((group) => ({
    ...group,
    questions: group.questions
      .filter(
        (question) =>
          !isQuantitativeQuestionUnavailable(question, inspection.asset),
      )
      .map((question) => {
        const answerOptions = (question.answer?.options || []).map((opt) => ({
          label: getTranslation(opt.label).display,
          value: opt.order,
        }));

        let actualAnswer;
        switch (question.answerType) {
          case ANSWER_TYPE.MULTIPLE_CHOICE:
            // multiple-choice questions have signature `object | null`
            actualAnswer = null;
            break;
          default:
            // All other types are considered strings
            actualAnswer = '';
        }

        const defectOptions = question.defects?.length
          ? [
              { label: 'None', value: null },
              ...question.defects.map((defect) => ({
                value: defect.id,
                label: getTranslation(defect.name).display,
              })),
            ]
          : [];

        return {
          ...question,
          actualAnswer: {
            value: actualAnswer,
            errors: [],
          },
          photos: {
            value: [],
            errors: [],
          },
          actualDocuments: {
            value: [],
            errors: [],
          },
          questionNotes: {
            value: question.questionNotes ?? '',
            errors: [],
          },
          defectNotes: {
            value: question.defectNotes ?? '',
            errors: [],
          },
          actualDefect: {
            value: question.actualDefect,
            errors: [],
          },
          defectCount: {
            value: '1',
            errors: [],
          },
          defectiveUnits: {
            value: '1',
            errors: [],
          },
          answerOptions,
          defectOptions,
          dependencyAction: question.dependencyAction ?? null,
        };
      }),
  })),
});

/**
 * Check whether `inspection` is editable / selectable
 * @param {{status: string, statusOrResult: string}} inspection Inspection to be checked
 * @param {string[]} roles Currently logged in user roles
 * @returns
 */
export const getIsInspectionSelectable = (inspection, roles) => {
  const status = inspection.status || inspection.statusOrResult;
  const userCanReassign = roles.some((r) =>
    REASSIGN_INSPECTION_ROLES.includes(r),
  );

  const canReassignInProgress =
    status === INSPECTION_STATUS.IN_PROGRESS && userCanReassign;

  const isOtherwiseReassignable = REASSIGNABLE_STATUS_LIST.filter(
    (s) => s !== INSPECTION_STATUS.IN_PROGRESS,
  ).includes(status);

  return canReassignInProgress || isOtherwiseReassignable;
};

export const updateInspector = (state) => {
  const data = { inspector: null };
  if (state.inspector.value) {
    data.inspector = { id: state.inspector.value.id };
  }
  return data;
};

export const updateDate = (state) => {
  const data = { inspectionDate: null };
  if (state.inspectionDate.value) {
    data.inspectionDate = state.inspectionDate.value;
  }
  return data;
};

function isQuestionHiddenByDependency(group, question, asset) {
  if (!question.dependencyAction) {
    return false;
  }
  let retVal;
  const depQuestion = group.questions.find(
    (q) => Number(q.id) === question.dependencyQuestionId,
  );

  if (!depQuestion) {
    console.error('Dependency question not found!', question);
    retVal = true;
  } else {
    // TODO: Optimization opportunity: This recursive call
    // can be optimized away if we loop once over the whole group
    // and keep track of each question's hidden state; that way
    // we will have a precalculated dependency graph at each dependency
    const depIsHidden = isQuestionHidden(group, depQuestion, asset);
    const depIsAnswered = !!depQuestion.actualAnswer.value;
    const depHasDefects = !!depQuestion.actualDefect.value;

    switch (question.dependencyCriteria) {
      case DEPENDENCY.IS_ANSWERED:
        retVal = depIsAnswered && !depIsHidden;
        break;
      case DEPENDENCY.IS_NOT_ANSWERED:
        retVal = !depIsAnswered || depIsHidden;
        break;
      case DEPENDENCY.HAS_DEFECTS:
        retVal = depIsAnswered && !depIsHidden && depHasDefects;
        break;
      case DEPENDENCY.NO_DEFECTS:
        retVal = depIsAnswered && !depIsHidden && !depHasDefects;
        break;
      case DEPENDENCY.SPECIFIC_ANSWER:
        retVal =
          depIsAnswered &&
          !depIsHidden &&
          depQuestion.actualAnswer?.value?.value ===
            question.dependencyAnswerOption;
        break;
      default:
        throw new Error(
          `Unknown dependency criteria ${question.dependencyCriteria}`,
        );
      // retVal = false;
    }
  }

  return question.dependencyAction === 'show' ? !retVal : retVal;
}

function isQuantitativeQuestionUnavailable(question, asset) {
  const hiddenByMissingOtherMeasure =
    !!question.otherMeasure &&
    !(asset?.customMeasurements || []).some(
      (m) => m.measure === question.otherMeasure,
    );

  return hiddenByMissingOtherMeasure;
}

export function isQuestionHidden(group, question, asset) {
  return (
    isQuestionHiddenByDependency(group, question, asset) ||
    isQuantitativeQuestionUnavailable(question, asset)
  );
}

export function getDerivedAnswerProperties(group, question, asset) {
  const isAnswered = !!question.actualAnswer.value;
  const isHidden = isQuestionHidden(group, question, asset);
  const isQuestionPhotoRequired =
    question.photoRequired === PHOTO_REQUIRED.REQUIRED;
  const isQuestionImageCapture =
    question.type === ANSWER_TYPE.DEVICE_IMAGE_CAPTURE;

  const isPhotoRequired =
    !isHidden &&
    ((isAnswered && isQuestionPhotoRequired) || isQuestionImageCapture);

  return {
    isAnswered,
    isHidden,
    isPhotoRequired,
  };
}

export const internalInspectionToPOSTState = (state) => {
  const { inspection } = state;
  const { asset } = inspection;
  return {
    id: inspection.id,
    result: inspection.result || undefined,
    questions: inspection.questionGroups
      .map((group) =>
        group.questions.map((q) => ({
          ...q,
          ...getDerivedAnswerProperties(group, q, asset),
        })),
      )
      .flat()
      .filter((q) => !q.isHidden)
      .map((question) => {
        const answer = MULTIPLE_CHOICE_ANSWER_TYPES.includes(
          question.answerType,
        )
          ? question.actualAnswer?.value?.value
          : question.actualAnswer?.value;

        return {
          id: question.id,
          answer: answer || null,
          questionNotes: question.questionNotes.value || undefined,
          defects: question.actualDefect.value
            ? [
                {
                  defect: {
                    id:
                      question.actualDefect.value.value ??
                      question.actualDefect.value,
                  },
                  qty: question.defectCount.value,
                  defectiveUnits: question.defectiveUnits.value,
                  notes: question.defectNotes.value || undefined,
                },
              ]
            : undefined,
        };
      }),
    summary: inspection?.summary?.value || undefined,
  };
};

const questionToResultState = (question, questionOptions) => {
  return {
    ...question,
    type: questionOptions.type.find((opt) => opt.id === question.type)?.label,
    actualDefects: question.actualDefects.map(formatOtherActualDefectLabel),
  };
};

const questionGroupToResultState = (group, questionOptions) => ({
  ...group,
  questions: group.questions.map((q) =>
    questionToResultState(q, questionOptions),
  ),
});

export const inspectionToResultState = (inspection, planOptions) => {
  const labelById = (planKey, optionsKey = planKey) =>
    (planOptions[optionsKey] || []).find(
      (opt) => opt.id === inspection.inspectionPlan[planKey],
    )?.label ?? null;
  return {
    ...inspection,
    questionGroups: inspection.questionGroups.map((group) =>
      questionGroupToResultState(group, planOptions.questionOptions),
    ),
    inspectionPlan: {
      ...inspection.inspectionPlan,
      aqlLevel: labelById('aqlLevel'),
      majorDefect: labelById('majorDefect'),
      minorDefect: labelById('minorDefect'),
      validityRange: labelById('validityRange'),
    },
  };
};

const otherDefectRegex =
  /^Other(?:[\s/-]+(Minor|Major|Functional|Informational|Critical|Severe))?$/i;
export const formatOtherActualDefectLabel = (actualDefect) => {
  const label =
    actualDefect.defect?.name.find((txt) => txt.language === 'en')?.text ||
    actualDefect?.defect.name[0].text;

  const isOther = otherDefectRegex.test(label) || '';
  const notes = actualDefect.notes;

  if (isOther && !!notes) {
    const label = notes.replace(/\s+/g, ' ').trim();
    return {
      ...actualDefect,
      notes: '',
      defect: {
        ...actualDefect.defect,
        name: actualDefect.defect.name.map((name) =>
          name.language === 'en'
            ? {
                ...name,
                text: `${label} (Other)`,
              }
            : name,
        ),
      },
    };
  }
  return actualDefect;
};
