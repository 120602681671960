import React from 'react';
import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';
import './SettingsTypesForm.scss';

const SettingsTypesForm = ({ addType, onChange, onSubmit, cancelForm }) => {
  return (
    <div className='settings-types-form'>
      <Input
        placeholder='Type name'
        type='text'
        value={addType.name.value}
        errors={addType.name.errors}
        autoComplete='off'
        onChange={onChange}
        className='types-input'
        data-testid='SettingsTypesForm.value'
      />
      <div className='action-buttons'>
        <Button
          type='button'
          data-testid='SettingsTypesForm.saveButton'
          className='medium-button'
          onClick={onSubmit}
        >
          Save
        </Button>
        <Button
          type='button'
          onClick={cancelForm}
          className='link-button'
          data-testid='SettingsTypesForm.cancelButton'
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default SettingsTypesForm;
