import checkIcon from 'assets/images/check.svg';
import timesIcon from 'assets/images/close-outline.svg';

export function renderIcon(props) {
  if (props?.completed) {
    return (
      <img className='completed-item-icon' src={checkIcon} alt='completed' />
    );
  } else if (props?.failed) {
    return <img className='failed-item-icon' src={timesIcon} alt='failed' />;
  }
  return props.icon;
}
