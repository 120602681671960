import { useReducer } from 'react';
import ConfirmationContext from './ConfirmationContext';
import ConfirmationDialog from './ConfirmationDialog';
import { reducer, initialState } from './confirmDialogReducer';

export const ConfirmationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConfirmationContext.Provider value={[state, dispatch]}>
      {children}
      <ConfirmationDialog />
    </ConfirmationContext.Provider>
  );
};
