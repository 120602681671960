import useQueryParams from './useQueryParams';

export default function useQueryFilters(history, location, config) {
  const { getParams, setParams } = useQueryParams(history, location);

  const values = Object.keys(config).reduce(
    (acc, key) => ({ ...acc, [key]: getParams()[key] ?? '' }),
    {},
  );

  const updateFilter = (key, value) => {
    setParams({
      ...values,
      [key]: value,
      page: 1,
    });
  };

  const getProps = (key) =>
    config[key]
      ? {
          onChange: (option) => updateFilter(key, option.value),
          value: config[key].find((opt) => opt.value === values[key]) || '',
          options: config[key] || [],
        }
      : {};

  return {
    values,
    getProps,
    updateFilter,
  };
}
