import { SORT_ORDER } from 'lib/list-helper/makeListReducer';
import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import { processAssetsAPIError } from './assetsFormValidator';
import {
  companyAttributesToFormState,
  transformDataToPOSTParams,
} from './dataTransform';

const log = makeLogger('assetsService');

export const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const assetListParamsToGETParams = (params) => {
  if (params.missingPlans) {
    params.filters = { missingPlans: true };
    delete params.missingPlans;
  }

  return queryParamsToGETParams(params);
};

const defaultAssetsListParams = {
  page: 1,
  pageSize: 10,
  select: ['name', 'externalId', 'shortDescription', 'measureTable', 'showId'],
  relations: ['status', 'type', 'images', 'inspectionPlans'],
};

const defaultRelatedWorkObjectsParams = {
  page: 1,
  pageSize: 5,
  order: { lastActivity: SORT_ORDER.DESC },
  select: [
    'id',
    'asset',
    'source',
    'quantity',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
    'deadline',
    'workObject.externalId',
  ],
  relations: ['assetImg'],
};

const defaultRelatedInspectionsParams = {
  page: 1,
  pageSize: 5,
  select: [
    'asset.name',
    'source.name',
    'inspectionType.name',
    'inspector.name',
    'statusOrResult',
    'inspectionDate',
    'finishedAt',
    'isThirdParty',
    'inspectorType',
    'thirdPartyInspectorName',
    'thirdPartyInspectingCompany',
  ],
  relations: ['asset', 'source', 'inspectionType', 'inspector.profilePhoto'],
};

const exportAssets = () => {
  return request
    .get('/assets/export')
    .then((res) => {
      log('Asset export successfully fetched');
      return res;
    })
    .catch((err) => {
      log('Asset export failed with error: ', err);
      return Promise.reject(err);
    });
};

const exportAssetRefDocs = () => {
  return request
    .get('/assets/export/reference-documents')
    .then((res) => {
      log('Asset reference documents export successfully fetched');
      return res;
    })
    .catch((err) => {
      log('Asset reference documents export failed with error: ', err);
      return Promise.reject(err);
    });
};

const getAssets = (params) => {
  log('Attempting to fetch assets with params', params);

  return request
    .get('/assets', {
      params: assetListParamsToGETParams({
        ...defaultAssetsListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Assets successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the assets for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultInspectionPlansListParams = {
  page: 1,
  pageSize: 10,
  select: [
    'name',
    'description',
    'useAqlLevel',
    'inspectionType.name',
    'publishedAtWithAql',
    'publishedAt',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'aqlLevel',
    'status',
  ],
  relations: ['linkedResource'],
};

const getInspectionPlans = (params) => {
  log('Attempting to fetch inspection plans for an assets with params', params);

  return request
    .get('/inspection-plans', {
      params: queryParamsToGETParams({
        ...defaultInspectionPlansListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspection plans for an assets successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection plans for an assets with the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const assignInspectionPlanToAsset = (id, assetId) =>
  request
    .put(`/inspection-plans/${id}`, {
      linkedResource: assetId
        ? {
            id: assetId,
            type: 'asset',
          }
        : null,
    })
    .then((results) => {
      return results;
    })
    .catch((e) => {
      return Promise.reject(e);
    });

const paramsAssetDetails = {
  select: ['name', 'shortDescription', 'externalId', 'measureTable', 'showId'],
  relations: [
    'status',
    'company',
    'customFields',
    'companyFields',
    'companyFields.attribute',
    'companyFields.option',
    'type',
    'status',
    'referenceDocuments',
    'customMeasurements',
    'images',
    'owners',
  ],
};

const getAssetDetail = (id) =>
  request
    .get(`/assets/${id}`, {
      params: paramsAssetDetails,
    })
    .catch((e) => {
      return Promise.reject(e);
    });

const getRelatedWorkObjects = ({ byAssetId, ...params }) => {
  log('Attempting to fetch related work objects with params', params);
  const filters = { byAssetId };
  return request
    .get('/work-objects/', {
      params: queryParamsToGETParams({
        filters,
        ...defaultRelatedWorkObjectsParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Related work objects successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching related work objects!', params, err);
      return Promise.reject(err);
    });
};

const getRelatedInspections = ({ byAssetId, params }) => {
  log('Attempting to fetch related inspections with params', params);
  const filters = { byAssetId };

  return request
    .get('/inspections', {
      params: queryParamsToGETParams({
        filters,
        ...defaultRelatedInspectionsParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspections successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspections for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getProductTypes = () => {
  return request
    .get('/asset-types', { params: { select: ['name'] } })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getAssetStatuses = () => {
  return request
    .get('/asset-statuses', { params: { select: ['name', 'meaning'] } })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const defaultAssetOwnersParams = {
  select: ['name', 'companyName'],
  relations: ['profilePhoto', 'roles'],
};

const getAssetOwners = (params) =>
  request
    .get('/asset-owners', {
      params: { ...defaultAssetOwnersParams, ...params },
    })
    .then((response) => response)
    .catch((e) => Promise.reject(e));

const defaultCompaniesParams = {
  select: ['name'],
  relations: ['companyAttributes.options'],
};

const getCompany = (companyId = 1) =>
  request
    .get(`/companies/${companyId}`, { params: defaultCompaniesParams })
    .then((response) =>
      companyAttributesToFormState(response.companyAttributes),
    )
    .catch((e) => Promise.reject(e));

const saveAsset = (id, data) => {
  const method = id ? 'put' : 'post';
  const endpoint = id ? `/assets/${id}` : '/assets';
  return request[method](endpoint, transformDataToPOSTParams(data))
    .then((response) => response)
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
        case 'asset.owner_change_forbidden':
        case 'asset.externalid_change_forbidden':
          return Promise.reject({
            payload: processAssetsAPIError(e.response.data.details, data),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveReferenceDocuments = (assetId, name, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);

  return request
    .post(`/assets/${assetId}/upload-reference-documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      log('Document uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the document', e);
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};
const saveImages = (assetId, images) => {
  const formData = new FormData();
  images.map((img) => formData.append('files', img));

  return request
    .post(`/assets/${assetId}/upload-images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      log('Images uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the images', e);
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const assetsService = {
  exportAssets,
  exportAssetRefDocs,
  getAssets,
  getInspectionPlans,
  assignInspectionPlanToAsset,
  getAssetDetail,
  getRelatedWorkObjects,
  getRelatedInspections,
  getProductTypes,
  getAssetStatuses,
  getAssetOwners,
  getCompany,
  saveAsset,
  saveReferenceDocuments,
  saveImages,
};

export default assetsService;
