import { useEffect, useReducer } from 'react';

import { REVIEWABLE_PLAN_STATUS_LIST } from 'config/inspectionPlanStatus';

import useQueryParams from 'lib/useQueryParams';

import {
  reducer,
  initialState,
  INSPECTION_PLANS_DETAIL_ACTIONS,
} from './inspectionPlansDetailReducer';

import {
  optionsToFormState,
  inspectionPlanToDetailState,
  questionOptionsToFormState,
} from './dataTransform';

import inspectionPlansService from './inspectionPlansService';

const useInspectionPlansDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const id = props.match?.params?.id;
  const { getParams, setParams } = useQueryParams(
    props.location,
    props.history,
  );

  const { 'needs-review': needsReviewQuery } = getParams();

  const dispatchPlan = (plan) => {
    dispatch({
      type: INSPECTION_PLANS_DETAIL_ACTIONS.APP_LOADS_DATA,
      payload: inspectionPlanToDetailState(
        plan,
        state.planOptions,
        state.questionOptions,
      ),
    });
  };

  const getInspectionPlan = () => {
    if (id) {
      inspectionPlansService
        .getInspectionPlan(id)
        .then(dispatchPlan)
        .catch((e) => {
          if (e.response?.status === 403) {
            props.history.push(`/inspection-plans`);
          } else {
            dispatch({
              type: INSPECTION_PLANS_DETAIL_ACTIONS.RESET_STATE,
              payload: e.payload,
            });
          }
        });
    }
  };

  const transitionToNeedsReview = (id, notes) => {
    inspectionPlansService
      .postNeedsReview(id, notes)
      .then(() => {
        dispatch({
          type: INSPECTION_PLANS_DETAIL_ACTIONS.USER_CANCELS_NEEDS_REVIEW,
        });
      })
      .then(getInspectionPlan)
      .catch((e) => {
        dispatch({
          type: INSPECTION_PLANS_DETAIL_ACTIONS.APP_FAILS_NEEDS_REVIEW,
          payload: e,
        });
      });
  };

  const getInspectionPlanOptions = () => {
    return inspectionPlansService
      .getInspectionPlanOptions()
      .then((response) => {
        dispatch({
          type: INSPECTION_PLANS_DETAIL_ACTIONS.APP_LOADS_OPTIONS,
          payload: {
            planOptions: optionsToFormState(response),
            questionOptions: questionOptionsToFormState(response),
          },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTION_PLANS_DETAIL_ACTIONS.RESET_STATE,
          payload: {
            errors: [
              'An error occured while fetching plan options. Please try again.',
            ],
          },
        });
      });
  };

  const userClicksOnEdit = () =>
    props.history.push(`/inspection-plans/${state.plan.id}/edit`);
  const userClicksOnPrint = (lang = 'en') =>
    props.history.push(
      `/inspection-plans/${state.plan.id}/print?language=${lang}`,
    );

  const userClicksOnNeedsReview = () => {
    setParams({ 'needs-review': true });
    dispatch({
      type: INSPECTION_PLANS_DETAIL_ACTIONS.USER_CLICKS_NEEDS_REVIEW,
    });
  };

  const userSubmitsNotesModal = () => {
    transitionToNeedsReview(id, state.notesModal.notes, dispatch);
  };

  const userCancelsNotesModal = () => {
    dispatch({
      type: INSPECTION_PLANS_DETAIL_ACTIONS.USER_CANCELS_NEEDS_REVIEW,
    });
    setParams({ 'needs-review': undefined });
  };

  const userTypesReviewNotes = (ev) => {
    dispatch({
      type: INSPECTION_PLANS_DETAIL_ACTIONS.USER_TYPES_REVIEW_NOTES,
      payload: ev.target.value,
    });
  };

  useEffect(() => {
    getInspectionPlanOptions();
  }, []);

  const isNeedsReviewVisible = REVIEWABLE_PLAN_STATUS_LIST.includes(
    state.plan?.status,
  );

  useEffect(() => {
    if (needsReviewQuery && isNeedsReviewVisible) {
      dispatch({
        type: INSPECTION_PLANS_DETAIL_ACTIONS.USER_CLICKS_NEEDS_REVIEW,
      });
    }
  }, [state.plan]);

  // Inspection plan needs planOptions already dispatched to state
  // Nice to have: prettier action chaining syntax
  useEffect(() => {
    if (state.planOptions) {
      getInspectionPlan();
    }
  }, [state.planOptions]);

  return {
    state,
    isNeedsReviewVisible,
    userClicksOnEdit,
    userClicksOnPrint,
    userClicksOnNeedsReview,
    userSubmitsNotesModal,
    userCancelsNotesModal,
    userTypesReviewNotes,
  };
};

export default useInspectionPlansDetail;
