import * as _get from 'lodash.get';
import { MAX_FIELD_CHARS } from './companiesFormReducer';

const cleanEmptyValues = (arr) => arr.filter((item) => item?.value !== '');

const customConfigStringToJson = (v) => {
  if (![null, undefined, '', '{}'].includes(v)) {
    try {
      const rslt = JSON.parse(v);
      return Object.keys(rslt).length ? rslt : {};
    } catch (e) {
      console.warn('Unable to parse JSON string', e, v);
    }
  }

  return {};
};

export const companyStateToPOSTParams = (state) => {
  return {
    name: state.name.value,
    status: state.status.value,
    about: state.about.value,
    phones: cleanEmptyValues(state.phones).map((phone, idx) => ({
      phone: phone.value,
      id: phone.id,
      isPreferred: idx === 0,
    })),
    emails: cleanEmptyValues(state.emails).map((email, idx) => ({
      id: email.id,
      email: email.value,
      isPreferred: idx === 0,
    })),
    location: {
      address: state.address.value,
      city: state.city.value,
      zipCode: state.zipCode.value,
      state: state.state.value,
      country: state.country.value?.value,
    },
    clientAdmin: {
      email: state.adminEmail.value,
      name: state.adminName.value,
    },
    customConfig: customConfigStringToJson(state.customConfig.value),
  };
};

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) || '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

const customConfigJsonToString = (v) => {
  if (![null, undefined].includes(v)) {
    try {
      const rslt = JSON.stringify(v, null, 2);
      return rslt !== '{}' ? rslt : '';
    } catch (e) {
      console.warn('Unable to stringify JSON', e, v);
    }
  }

  return '';
};

export const companiesToFormState = (company) => ({
  name: setStringValue('name', company),
  status: { value: company?.status, errors: [] },
  about: setStringValue('about', company),
  address: setStringValue('location.address', company, 'address'),
  city: setStringValue('location.city', company, 'city'),
  zipCode: setStringValue('location.zipCode', company, 'zipCode'),
  state: setStringValue('location.state', company, 'state'),
  country: {
    value: {
      value: company?.location?.country || '',
      label: company?.location?.country || '',
    },
    errors: [],
  },
  phones: (company?.phones || []).map((phone) => ({
    value: phone?.phone || '',
    id: phone?.id,
    errors: [],
  })),
  emails: (company?.emails || []).map((email) => ({
    value: email?.email || '',
    id: email?.id,
    errors: [],
  })),
  id: company?.id,
  customConfig: {
    value: customConfigJsonToString(company?.customConfig),
    errors: [],
  },
});
