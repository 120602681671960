import Table from 'lib/components/table/Table';

import './WorkflowsTable.scss';

const tableColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <span>
        {record.id} {!!record?.externalId && `/ ${record.externalId}`}
      </span>
    ),
  },
  {
    title: 'Name',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Description',
    sortId: 'description',
    render: (record) => record.description,
  },
  {
    title: 'Default Source',
    sortId: 'defaultSource',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.defaultSource}</div>
        {!!record.defaultSourceExternalId && (
          <div className='secondary-text'>
            Ext. ID: {record.defaultSourceExternalId}
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Default Destination',
    sortId: 'defaultDestination',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.defaultDestination}</div>
        {!!record.defaultDestinationExternalId && (
          <div className='secondary-text'>
            Ext. ID: {record.defaultDestinationExternalId}
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Work objects done',
    sortId: 'workObjectsDone',
    textAlign: 'right',
    render: (record) => record.workObjectsDone || 0,
  },
];

const WorkflowsTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={tableColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='workflows-table'
    {...props}
  />
);

export default WorkflowsTable;
