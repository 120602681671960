import { useEffect, useState } from 'react';
import Tabs from 'lib/components/tabs/Tabs';
import './InspectionPlansAddPage.scss';
import AutoBuildPlanForm from '../auto-build-plan-form/AutoBuildPlanForm';
import InspectionPlansFormNew from '../inspection-plans-form/InspectionPlansFormNew';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryParams from 'lib/useQueryParams';

const TABS = {
  AUTO_BUILD: 'AUTO_BUILD',
  CUSTOM_PLAN: 'CUSTOM_PLAN',
};

const InspectionPlansAddPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { getParams } = useQueryParams(location, history);

  const { linkedResourceId, linkedResourceType } = getParams();

  const [currentTab, setCurrentTab] = useState(TABS.AUTO_BUILD);
  const isAutoBuildPlan = currentTab === TABS.AUTO_BUILD;
  const isCustomPlan = currentTab === TABS.CUSTOM_PLAN;

  useEffect(() => {
    if (linkedResourceId && linkedResourceType) setCurrentTab(TABS.CUSTOM_PLAN);
  }, [linkedResourceId, linkedResourceType]);

  return (
    <div className='inspection-plans-add-page container-m'>
      <div className='row'>
        <h2>New inspection plan</h2>
      </div>
      <div className='row inspection-plan-tabs'>
        <Tabs showSeparator>
          <Tabs.Item
            active={isAutoBuildPlan}
            onClick={() => setCurrentTab(TABS.AUTO_BUILD)}
          >
            Auto build plan
          </Tabs.Item>
          <Tabs.Item
            active={isCustomPlan}
            onClick={() => setCurrentTab(TABS.CUSTOM_PLAN)}
          >
            Custom plan
          </Tabs.Item>
        </Tabs>
      </div>
      <div className='row'>
        {isCustomPlan && <InspectionPlansFormNew {...props} />}
        {isAutoBuildPlan && <AutoBuildPlanForm {...props} />}
      </div>
    </div>
  );
};

export default InspectionPlansAddPage;
