import StatusPill from 'lib/components/status-pill/StatusPill';

export default function UsageDecisionsDetailTable({ data, ...props }) {
  return (
    <div className='usage-decisions-table table' {...props}>
      <div className='table-row table-header'>
        <div className='table-cell'>Decision Name</div>
        <div className='table-cell'>Criteria</div>
        <div className='table-cell'>Action</div>
        <div className='table-cell'>Notify</div>
      </div>
      {data.map((usageDecision) => {
        return (
          <div key={usageDecision.id} className='table-row'>
            <div className='table-cell'>{usageDecision.name}</div>
            <div className='table-cell'>{usageDecision.criteria.name}</div>
            <div className='table-cell'>
              {usageDecision.action.name}
              {usageDecision.forwardActionToGroup !== null
                ? ` ${usageDecision.forwardActionToGroup + 1}`
                : ''}
            </div>
            <div className='table-cell pill-cell'>
              {usageDecision.notifyTo.map((role) => {
                return (
                  <StatusPill key={`${role.id}-${usageDecision.id}`}>
                    {role.role}
                  </StatusPill>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
