import ROLES from 'config/roles';

export const ADD_SOURCES_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];

export const EDIT_OWN_SOURCES_ROLES = [
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const EDIT_ALL_FIELDS_ROLES = [
  ...ADD_SOURCES_ROLES,
  ROLES.INTERNAL_SOURCE_OWNER,
];

export const SOURCES_FORM_INPUT_RESTRICTIONS = {
  name: EDIT_ALL_FIELDS_ROLES,
  externalId: EDIT_ALL_FIELDS_ROLES,
  type: EDIT_ALL_FIELDS_ROLES,
  status: EDIT_ALL_FIELDS_ROLES,
  address: [],
  zipCode: [],
  district: [],
  city: [],
  state: [],
  country: [],
  map: [],
  images: [],
  tags: EDIT_ALL_FIELDS_ROLES,
  owners: EDIT_ALL_FIELDS_ROLES,
};

/* BE filters sources returning sources associated to
 inspector, source owner, internal source and asset owner */
export const VIEW_ALL_SOURCES_ROLES = [
  ...ADD_SOURCES_ROLES,
  ...EDIT_OWN_SOURCES_ROLES,
  ROLES.COORDINATOR,
  ROLES.INSPECTOR,
  ROLES.ASSET_OWNER,
];

export const VIEW_ASSOCIATED_WORK_OBJECTS = [
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_OWNER,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const IMPORT_SOURCES_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];
