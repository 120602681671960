import classNames from 'classnames';
import { RESULT_RECORDING_OPTIONS } from 'config/questionOptions';
import useUOMs from 'lib/uomsService';

import { useUnits } from '../../unitsService';

import ErrorBag from '../error-bag/ErrorBag';
import Input from '../input/Input';
import Radio from '../radio/Radio';
import Select from '../select/Select';
import Switch from '../switch/Switch';

const MEASURE_TABLE_UOM_VISIBLE = ['Custom', 'Other'];

function ExpectedMeasureWidget({ isInModal, data, onChange, questionOptions }) {
  const unitsList = useUnits();
  const { uoms } = useUOMs();

  const customExpectedUomOptions = unitsList.map((unit) => ({
    value: unit.id,
    label: unit.label,
  }));

  const expectedMeasureTableResult =
    data.expectedMeasureTableResult.value?.value;
  const isCustomUOMVisible = expectedMeasureTableResult === 'Custom';
  const isMeasureTableUOMVisible =
    expectedMeasureTableResult &&
    !MEASURE_TABLE_UOM_VISIBLE.includes(expectedMeasureTableResult);
  const isResultRecordingVisible =
    !expectedMeasureTableResult ||
    MEASURE_TABLE_UOM_VISIBLE.includes(expectedMeasureTableResult);

  const isCustomMeasureSelectVisible = expectedMeasureTableResult === 'Other';

  return (
    <>
      <div className='flex'>
        <div className='expected-measure-container'>
          <Select
            isClearable
            isInModal={isInModal}
            className='expected-measure'
            label='Expected measure table result'
            options={questionOptions.expectedMeasureTableResult}
            value={data.expectedMeasureTableResult.value}
            errors={data.expectedMeasureTableResult.errors}
            onChange={(value) => onChange('expectedMeasureTableResult', value)}
          />
        </div>
        {isMeasureTableUOMVisible && (
          <div className='expected-measure-uom-container'>
            <Select
              isClearable
              isInModal={isInModal}
              className='expected-measure-uom'
              label='UOM'
              options={uoms}
              value={data.expectedBarcodeUOM.value}
              errors={data.expectedBarcodeUOM.errors}
              onChange={(value) => onChange('expectedBarcodeUOM', value)}
            />
          </div>
        )}
        {isCustomMeasureSelectVisible && (
          <div className='expected-measure-custom-container'>
            <Select
              isInModal={isInModal}
              className='expected-custom-measure'
              label='Expected measure'
              options={questionOptions.otherMeasureOptions}
              value={data.otherMeasure.value}
              errors={data.otherMeasure.errors}
              onChange={(value) => onChange('otherMeasure', value)}
            />
          </div>
        )}
      </div>
      {isResultRecordingVisible && (
        <div className='result-recording-container'>
          <Radio
            label='Result recording'
            options={RESULT_RECORDING_OPTIONS}
            value={data.individualMeasurementsRequired.value}
            errors={data.individualMeasurementsRequired.errors}
            onChange={(value) =>
              onChange('individualMeasurementsRequired', value)
            }
          />
        </div>
      )}
      {isCustomUOMVisible && (
        <>
          <div className='custom-measure-container'>
            <div className='form-label'>Custom measure</div>
            <div className='flex'>
              <Input
                name='custom-measure-input'
                className={classNames('custom-measure-input', {
                  'is-invalid': data.customExpectedMeasureValue?.errors.length,
                })}
                type='text'
                errors={data.customExpectedMeasureValue.errors}
                autoComplete='off'
                value={data.customExpectedMeasureValue.value}
                onChange={(ev) =>
                  onChange('customExpectedMeasureValue', ev.target.value)
                }
              />
              <div>
                <Select
                  isInModal={isInModal}
                  name='custom-measure-select'
                  className='custom-measure-select'
                  value={data.customExpectedUom.value}
                  errors={data.customExpectedUom.errors}
                  onChange={(value) => onChange('customExpectedUom', value)}
                  options={customExpectedUomOptions}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className='upper-tolerance-container'>
        <div className='form-label'>Upper tolerance</div>
        <div className='flex'>
          <Input
            name='upper-tolerance-input'
            className={classNames('upper-tolerance-input', {
              'is-invalid': data.upperTolerance.errors.length,
            })}
            type='text'
            autoComplete='off'
            value={data.upperTolerance?.value}
            onChange={(ev) => onChange('upperTolerance', ev.target.value)}
          />
          <Switch
            offLabel='#'
            onLabel='%'
            name='upper-tolerance-switch'
            className='upper-tolerance-switch'
            checked={data.upperToleranceRule?.value === '%'}
            onChange={() =>
              onChange(
                'upperToleranceRule',
                data.upperToleranceRule?.value === '%' ? 'Exact' : '%',
              )
            }
          />
          {data.upperTolerance.value !== '' && (
            <Select
              isInModal={isInModal}
              placeholder='Select weight...'
              className={classNames('upper-tolerance-weight', {
                'is-invalid': data.upperToleranceWeight.errors.length,
              })}
              onChange={(val) => onChange('upperToleranceWeight', val)}
              value={data.upperToleranceWeight.value}
              options={questionOptions.questionWeight}
            />
          )}
        </div>
        {/* Hidden due to current implementation on ipad app */}
        {/* <p className='field-description'>
          # will refer to the chosen unit in the measure table.
        </p> */}
        <ErrorBag
          errors={[
            ...data.upperTolerance.errors,
            ...data.upperToleranceWeight.errors,
          ]}
        />
      </div>
      <div className='lower-tolerance-container'>
        <div className='form-label'>Lower tolerance</div>
        <div className='flex'>
          <Input
            name='lower-tolerance-input'
            className={classNames('lower-tolerance-input', {
              'is-invalid': data.lowerTolerance.errors.length,
            })}
            type='text'
            autoComplete='off'
            value={data.lowerTolerance?.value}
            onChange={(ev) => onChange('lowerTolerance', ev.target.value)}
          />
          <Switch
            offLabel='#'
            onLabel='%'
            name='lower-tolerance-switch'
            className='lower-tolerance-switch'
            checked={data.lowerToleranceRule?.value === '%'}
            onChange={() =>
              onChange(
                'lowerToleranceRule',
                data.lowerToleranceRule?.value === '%' ? 'Exact' : '%',
              )
            }
          />
          {data.lowerTolerance.value !== '' && (
            <Select
              isInModal={isInModal}
              className={classNames('lower-tolerance-weight', {
                'is-invalid': data.lowerToleranceWeight.errors.length,
              })}
              placeholder='Select weight...'
              onChange={(val) => onChange('lowerToleranceWeight', val)}
              value={data.lowerToleranceWeight.value}
              options={questionOptions.questionWeight}
            />
          )}
        </div>
        {/* Hidden due to current implementation on ipad app */}
        {/* <p className='field-description'>
              # will refer to the chosen unit in the measure table.
            </p> */}
        <ErrorBag
          errors={[
            ...data.lowerTolerance.errors,
            ...data.lowerToleranceWeight.errors,
          ]}
        />
      </div>
    </>
  );
}

export default ExpectedMeasureWidget;
