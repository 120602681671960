
import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import DatePicker from 'lib/components/date-picker/DatePicker';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import LinkableResourcesModal from 'lib/components/linkable-resources-modal/LinkableResourcesModal';
import ResourceSelectButton from 'lib/components/resource-select-button/ResourceSelectButton';
import Select from 'lib/components/select/Select';
import SourcesModal from 'lib/components/sources-modal/SourcesModal';
import Textarea from 'lib/components/textarea/Textarea';
import WorkflowsModal from 'lib/components/workflows-modal/WorkflowsModal';

import useWorkObjectsForm from 'modules/work-objects/useWorkObjectsForm';

import NotesModal from 'lib/components/notes-modal/NotesModal';
import { useState, useMemo } from 'react';
import './WorkObjectsForm.scss';
import { endOfDay, isBefore, parseISO } from 'date-fns';

const renderLabelAndExtId = (v) => {
  return v?.label
    ? `${v.label}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const WorkObjectsForm = (props) => {
  const { state, ...vm } = useWorkObjectsForm(props);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const SubmitButton = state.id ? EditButton : AddButton;
  const submitText = state.id ? 'Save changes' : 'Create work object';

  const isDestinationSelectDisabled = vm.isWorkflowSingleStep;

  const onSubmit = (e) => {
    e.preventDefault();
    vm.dispatchInput('activityComment', '');
    if (state?.status?.value?.value === 'canceled') {
      setIsSaveModalOpen(true);
    } else {
      vm.userSubmitsForm();
    }
  }

  const onConfirmSaveModal = () => {
    vm.userSubmitsForm();
    setIsSaveModalOpen(false);
  }

  const saveModalDescriptionText = useMemo(() => {
    let actionDescr = null;
    let descr = 'You can provide additional information about the Work Object changes.';

    if (state?.status?.value?.value === 'canceled') {
      actionDescr = `You are Canceling this Work Object.`
    }

    return (<>
      {!!actionDescr && (<><strong>{actionDescr}</strong><br /></>)}
      {descr}
    </>)
  }, [state?.status?.value?.value])

  return (
    <form
      noValidate
      className='work-objects-form'
      data-testid='WorkObjectsForm.form'
    >
      <div className='form-row'>
        <ResourceSelectButton
          disabled={state.hasPartials}
          placeholder='Select'
          label='Select Asset / Source'
          title={
            state?.resource?.value?.label ? (
              <span>
                <span>{state.resource.value.label}</span>
                {!!(
                  state.resource.value?.value ||
                  state.resource.value?.externalId?.length
                ) && (
                    <>
                      {' '}
                      <span>
                        (
                        {!!state.resource.value?.externalId?.length &&
                          'External '}
                        ID:{' '}
                        {state.resource.value?.externalId?.length
                          ? state.resource.value?.externalId
                          : state.resource.value?.value}
                        )
                      </span>
                    </>
                  )}
              </span>
            ) : undefined
          }
          subtitle={state.resource.value?.location || ''}
          thumbUrl={state.resource.value?.image || ''}
          errors={state.resource.errors}
          onClick={vm.userOpensResourcesModal}
          data-testid='WorkObjectsForm.assetSource'
        />
      </div>
      <div className='form-row'>
        <Input
          name='externalId'
          type='text'
          value={state.externalId.value}
          errors={state.externalId.errors}
          onChange={vm.userTypesExternalId}
          charsLeft={state.externalId.charsLeft}
          label='External ID'
          data-testid='WorkObjectsForm.externalId'
        />
      </div>
      <div className='form-row quantity-container'>
        <Input
          name='quantity'
          type='number'
          min='0'
          value={state.quantity.value}
          errors={state.quantity.errors}
          onChange={vm.userTypesQuantity}
          label='Quantity'
          data-testid='WorkObjectsForm.quantity'
        />
      </div>
      <div className='form-row'>
        <Select
          name='status'
          options={vm.statusOptions?.filter((o) => (o.value !== 'canceled') || (!!state?.id && o.value === 'canceled'))}
          onChange={vm.userSetsStatus}
          placeholder='Select'
          label='Status'
          value={state.status.value}
          errors={state.status.errors}
          data-testid='WorkObjectsForm.status'
        />
      </div>
      <div className='form-row deadline-container'>
        <DatePicker
          name='deadline'
          label='Deadline'
          value={state.deadline.value}
          onChange={vm.userTypesDeadline}
          errors={
            state.deadline.value
              && isBefore(endOfDay(parseISO(state.deadline.value)), new Date())
              ? [...state.deadline.errors, {
                message: 'Warning: Deadline is set in the past',
                className: 'warning'
              }]
              : state.deadline.errors
          }
          data-testid='WorkObjectsForm.deadline'
        />
        <p className='field-description'>
          The deadline is only informational for when a work object is supposed
          to be finished by.
        </p>
      </div>
      <div className='form-row vendor-container'>
        <ResourceSelectButton
          placeholder='Select'
          label='Select Vendor'
          className='vendor'
          title={renderLabelAndExtId(state.vendor?.value)}
          subtitle={state.vendor?.value?.location || ''}
          errors={state.vendor.errors}
          onClick={vm.userOpensVendorsModal}
          isClearable
          onClear={() => vm.userTogglesVendor(null)}
        />
      </div>
      <div className='form-row custom-field-container'>
        <h3>Custom attributes</h3>
        <p className='form-info'>
          Custom attributes allow tracking of supplemental information specific
          to this work object.
        </p>
        {!state.customFields?.length && (
          <p className='form-info'>No custom attributes defined yet</p>
        )}
        {state.customFields.map((field, index) => (
          <div className='custom-field-item' key={index}>
            <Input
              name={`name-${index}`}
              type='text'
              value={field.name.value}
              errors={field.name.errors}
              onChange={(e) => vm.userTypesCustomFieldName(e, index)}
              charsLeft={field.name.charsLeft}
              label='Name'
              data-testid={`WorkObjectsForm.name${index}`}
            />
            <Textarea
              name={`data-${index}`}
              type='text'
              value={field.data.value}
              errors={field.data.errors}
              onChange={(e) => vm.userTypesCustomFieldData(e, index)}
              charsLeft={field.data.charsLeft}
              label='Data'
              data-testid={`WorkObjectsForm.data${index}`}
              resizable={true}
              rows={2}
            />
            <DeleteButton
              className='delete-custom-field'
              onClick={() => vm.userRemovesCustomField(index)}
              data-testid={`WorkObjectsForm.deleteField${index}`}
            >
              Delete
            </DeleteButton>
          </div>
        ))}
        <Button
          type='button'
          className='medium-button add-custom-field'
          onClick={vm.userAddsCustomField}
          data-testid='WorkObjectsForm.addField'
        >
          Add new field
        </Button>
      </div>
      <div className='form-row workflow-container'>
        <h3>Workflow</h3>
        <p className='form-info'>
          When a workflow is selected you can override the source and the
          destination only for the associated work object.
        </p>

        <ResourceSelectButton
          placeholder='Select'
          label='Select workflow'
          title={state.workflow.value?.label || ''}
          subtitle={`ID: ${state.workflow.value?.value}` || ''}
          errors={state.workflow.errors}
          onClick={vm.userOpensWorkflowsModal}
          data-testid='WorkObjectsForm.workflow'
        />
        {vm.isSourceWarningVisible && (
          <p className='form-info'>
            A source is selected as the work object target. Only workflows
            containing no inspection types with target asset may be selected.
          </p>
        )}
        {!!state.workflow?.value?.value && (
          <>
            <div className='form-row'>
              <ResourceSelectButton
                placeholder='Select'
                label={
                  state.originalSource.value
                    ? 'Override source'
                    : 'Select source'
                }
                className='workflow-source'
                title={renderLabelAndExtId(state.source?.value)}
                subtitle={state.source?.value?.location || ''}
                errors={state.source.errors}
                onClick={vm.userOpensSourcesModal}
                data-testid='WorkObjectsForm.source'
              />
              {vm.isSourceDifferent && (
                <button
                  type='button'
                  className='switch-btn'
                  onClick={vm.userSwitchesSourceToDefault}
                  data-testid='WorkObjectsForm.switchSourceToDefault'
                >
                  Switch to default
                </button>
              )}
            </div>
            <div className='form-row'>
              <ResourceSelectButton
                placeholder='Select'
                label={
                  state.originalDestination.value
                    ? 'Override destination'
                    : 'Select destination'
                }
                className='workflow-destination'
                disabled={isDestinationSelectDisabled}
                title={renderLabelAndExtId(state.destination?.value)}
                subtitle={state.destination?.value?.location || ''}
                errors={state.destination.errors}
                onClick={vm.userOpensDestinationsModal}
                data-testid='WorkObjectsForm.destination'
              />
              {vm.isWorkflowSingleStep && (
                <p className='field-description'>
                  Workflow has no destination step
                </p>
              )}
              {vm.isDestinationDifferent && !vm.isWorkflowSingleStep && (
                <button
                  type='button'
                  className='switch-btn'
                  onClick={vm.userSwitchesDestinationToDefault}
                  data-testid='WorkObjectsForm.switchDestinationToDefault'
                >
                  Switch to default
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className='form-row'>
        <div className='form-row submit-container'>
          <ErrorBag errors={state.errors} />
          <SubmitButton
            type='submit'
            disabled={state.loading}
            onClick={onSubmit}
            data-testid='WorkObjectsForm.submit'
          >
            {submitText}
          </SubmitButton>
        </div>
      </div>

      <SourcesModal
        isOpen={state.isSourcesModalOpen}
        onRequestClose={vm.userCancelsSourcesModal}
        onSubmit={vm.userSetsSource}
        data={vm.sourcesOptions}
        sortBy={state.availableSources.sortBy}
        sortOrder={state.availableSources.sortOrder}
        search={state.availableSources.search}
        page={state.availableSources.page}
        count={vm.resourcesOptions.sourcesCount}
        setPage={vm.userSetsSourcesPage}
        pageSize={state.availableSources.pageSize}
        onSearchChange={vm.userSearchesSources}
        setSortBy={vm.userSortsSources}
        onRowClick={vm.userTogglesSource}
      />
      <SourcesModal
        isOpen={state.isDestinationsModalOpen}
        onRequestClose={vm.userCancelsDestinationsModal}
        onSubmit={vm.userSetsDestination}
        data={vm.sourcesOptions}
        sortBy={state.availableDestinations.sortBy}
        page={state.availableDestinations.page}
        pageSize={state.availableDestinations.pageSize}
        setPage={vm.userSetsDestinationsPage}
        count={vm.resourcesOptions.sourcesCount}
        sortOrder={state.availableDestinations.sortOrder}
        search={state.availableDestinations.search}
        onSearchChange={vm.userSearchesDestinations}
        setSortBy={vm.userSortsDestinations}
        onRowClick={vm.userTogglesDestination}
      />
      <SourcesModal
        title='Assign Vendor'
        isOpen={state.isVendorsModalOpen}
        onRequestClose={vm.userCancelsVendorsModal}
        onSubmit={vm.userSetsVendor}
        data={vm.sourcesOptions}
        sortBy={state.availableVendors.sortBy}
        page={state.availableVendors.page}
        pageSize={state.availableVendors.pageSize}
        setPage={vm.userSetsVendorsPage}
        count={vm.resourcesOptions.sourcesCount}
        sortOrder={state.availableVendors.sortOrder}
        search={state.availableVendors.search}
        onSearchChange={vm.userSearchesVendors}
        setSortBy={vm.userSortsVendors}
        onRowClick={vm.userTogglesVendor}
      />
      <WorkflowsModal
        isOpen={state.isWorkflowsModalOpen}
        onRequestClose={vm.userCancelsWorkflowsModal}
        onSubmit={vm.userSetsWorkflow}
        data={vm.workflowsOptions.list}
        sortBy={state.availableWorkflows.sortBy}
        sortOrder={state.availableWorkflows.sortOrder}
        search={state.availableWorkflows.search}
        setPage={vm.userSetsWorkflowPage}
        page={state.availableWorkflows.page}
        pageSize={state.availableWorkflows.pageSize}
        count={vm.workflowsOptions.count}
        onSearchChange={vm.userSearchesWorkflows}
        setSortBy={vm.userSortsWorkflows}
        onRowClick={vm.userTogglesWorkflow}
      />
      <LinkableResourcesModal
        isOpen={state.isResourcesModalOpen}
        onRequestClose={vm.userCancelsResourcesModal}
        onSubmit={vm.userSetsResource}
        data={vm.resourcesOptions}
        sortBy={state.availableResources.sortBy}
        tab={state.availableResources.tab}
        sortOrder={state.availableResources.sortOrder}
        search={state.availableResources.search}
        pageSize={state.availableResources.pageSize}
        page={state.availableResources.page}
        setPage={vm.userSetsLinkedResourcePage}
        onSearchChange={vm.userSearchesResources}
        onTabChange={vm.userChangesResourcesTab}
        setSortBy={vm.userSortsResources}
        onRowClick={vm.userTogglesResource}
      />
      <NotesModal
        title='Submit Work Object changes?'
        submitText='Submit'
        cancelText='Cancel'
        description={saveModalDescriptionText}
        isOpen={isSaveModalOpen}
        onCancel={() => {
          vm.dispatchInput('activityComment', '');
          setIsSaveModalOpen(false);
        }}
        onSubmit={onConfirmSaveModal}
        className='confirm-save-work-object-modal'
        value={state.activityComment.value}
        onChange={(e) => vm.dispatchInput('activityComment', e.target.value)}
        charsLeft={state.activityComment.charsLeft}
      />
    </form>
  );
};

export default WorkObjectsForm;
