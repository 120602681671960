import { useContext } from 'react';
import ConfirmationContext from './ConfirmationContext';
import { CONFIRM_ACTIONS } from './confirmDialogReducer';

let resolveCallback;
const useConfirm = () => {
  const [confirmState, dispatch] = useContext(ConfirmationContext);

  const closeConfirmation = () => {
    dispatch({
      type: CONFIRM_ACTIONS.HIDE_CONFIRM,
    });
  };

  const onConfirm = () => {
    closeConfirmation();

    resolveCallback && resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirmation();

    resolveCallback && resolveCallback(false);
  };

  const confirm = ({ body, title, confirmText, cancelText } = {}) => {
    dispatch({
      type: CONFIRM_ACTIONS.SHOW_CONFIRM,
      payload: {
        body,
        title,
        confirm: confirmText,
        cancel: cancelText,
      },
    });

    return new Promise((resolve) => {
      resolveCallback = resolve;
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
};

export default useConfirm;
