import makeReducer from 'lib/makeReducer';

export const LIST_ACTIONS = {
  USER_SORTS_BY: 'USER_SORTS_BY',
  USER_TYPES_IN_SEARCH_BAR: 'USER_TYPES_IN_SEARCH_BAR',
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  USER_SETS_PAGE: 'USER_SETS_PAGE',
  USER_SETS_PAGE_SIZE: 'USER_SETS_PAGE_SIZE',
  SET_LOADING: 'SET_LOADING',
  SET_ERRORS: 'SET_ERRORS',
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const INITIAL_LIST_STATE = {
  pageSize: 10,
  page: 1,
  data: [],
  search: '',
  sortBy: 'id',
  sortOrder: SORT_ORDER.ASC,
  loading: false,
  errors: [],
  count: 0,
};

const defaultListReducerConfig = {
  [LIST_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      errors: [],
    };
  },
  [LIST_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR]: (state, action) => {
    return {
      ...state,
      search: action.payload,
    };
  },
  [LIST_ACTIONS.USER_SORTS_BY]: (state, action) => {
    return {
      ...state,
      sortBy: action.payload.column,
      sortOrder: action.payload.order,
    };
  },
  [LIST_ACTIONS.USER_SETS_PAGE_SIZE]: (state, action) => {
    return {
      ...state,
      pageSize: action.payload,
    };
  },
  [LIST_ACTIONS.USER_SETS_PAGE]: (state, action) => {
    return {
      ...state,
      page: action.payload,
    };
  },
  [LIST_ACTIONS.SET_LOADING]: (state, action) => {
    return {
      ...state,
      loading: action.payload,
    };
  },
};

export const makeListReducer = (reducerConfig = {}, initialData = {}) => {
  const reducers = { ...defaultListReducerConfig, ...reducerConfig };
  const initialState = { ...INITIAL_LIST_STATE, ...initialData };
  return makeReducer(reducers, initialState);
};

export default makeListReducer;
