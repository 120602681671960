import Table from 'lib/components/table/Table';
import SettingsDefectsForm from '../settings-defects-form/SettingsDefectsForm';
import SettingsDefectsItem from '../settings-defects-item/SettingsDefectsItem';

import './SettingsDefectsTable.scss';

const settingsDefectTable = ({ userClicksOnRow, ...props }) => {
  const renderDefectCell = (record) => {
    if (props.editField?.id === record.id) {
      return (
        <SettingsDefectsForm
          clearEdit={props.clearEdit}
          defect={props.editField}
          onChange={props.onChange}
          editDefect={props.editDefect}
          language={props.language}
          languageList={props.languageList}
        />
      );
    } else {
      return (
        <SettingsDefectsItem
          setEdit={() => props.setEdit(record.name, record.id)}
          name={record.name}
          language={props.language}
          editing={props.editField.editing}
        />
      );
    }
  };

  const settingsDefectTableColumns = [
    {
      title: 'ID / External ID',
      headerClassName: 'id-cell',
      className: 'id-cell',
      sortId: 'id',
      render: (record) =>
        [record.id, record.externalId].filter((id) => id).join(' / '),
    },
    {
      title: 'Defect',
      headerClassName: 'name-cell',
      sortId: 'name',
      render: (record) => renderDefectCell(record),
    },
  ];

  return (
    <Table
      columns={settingsDefectTableColumns}
      className='settings-defects-table'
      {...props}
    />
  );
};

export default settingsDefectTable;
