import { memo, useCallback, useMemo } from 'react';

import _isEqual from 'lodash/isEqual';

import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import Input from 'lib/components/input/Input';
import request from 'lib/request';
import DocumentUpload from 'lib/components/document-upload/DocumentUpload';
import { SelectAutosuggest } from 'lib/components/select-autosuggest/SelectAutosuggest';

const loadDataFunc = async ({ searchStr, setData }) => {
  if (searchStr?.length) {
    await request
      .get('/assets/reference-documents', {
        params: {
          search: searchStr,
          limit: 5,
        },
      })
      .then((res) => {
        if (res?.data?.length) {
          setData(
            res.data
              .map((item) =>
                item?.documentName?.length ? item.documentName : null,
              )
              .filter((item) => !!item),
          );
        } else {
          setData([]);
        }
      })
      .catch(() => {
        setData([]);
      });
  } else {
    setData([]);
  }
};

const AssetFormRefDocs = memo(
  ({ data, onChange, onDelete, onUpload }) => {
    // Do not include values already set in the question
    const excludeItems = useMemo(
      () => data.map((doc) => doc.value),
      [JSON.stringify(data)],
    );

    if (!data) {
      return null;
    }

    return (
      <div className='form-row reference-document'>
        <p className='section-label'>Reference Documents</p>
        <DocumentUpload
          data={data}
          onChange={onChange}
          onDelete={onDelete}
          onUpload={onUpload}
          customInput={({ value, index, onChange, errors }) => (
            <>
              <SelectAutosuggest
                inputProps={{
                  name: 'name',
                  'data-testid': `DocumentUpload.document-${index}`,
                }}
                value={value}
                errors={errors}
                onChange={(v) => {
                  onChange(v, index);
                }}
                excludeItems={
                  // Current field value shouldn't be in excludeItems
                  excludeItems.filter((item) => item !== value)
                }
                loadDataFunc={loadDataFunc}
              />
            </>
          )}
        />
      </div>
    );
  },
  (a, b) => {
    return _isEqual(a, b);
  },
);

export default AssetFormRefDocs;
