import * as yup from 'yup';
import validator from 'lib/validator';

export const loginValidationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const processLoginFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    acc[curr.path] = {
      value: input[curr.path] || '',
      errors: curr.errors,
    };

    return acc;
  }, {});
};

const loginValidator = (credentials) =>
  validator(credentials, loginValidationSchema, processLoginFormError);

export default loginValidator;
