import { Switch } from 'react-router-dom';

import { ADD_ASSET_ROLES, VIEW_ALL_ROLES } from './assetsPermissions';

import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';

import AssetsAddPage from './components/assets-add-page/AssetsAddPage';
import AssetsDetailPage from './components/assets-detail-page/AssetsDetailPage';
import AssetsEditPage from './components/assets-edit-page/AssetsEditPage';
import AssetsListPage from './components/assets-list-page/AssetsListPage';
import AssignInspectionPlanToAsset from './components/assign-inspection-plan-to-asset/AssignInspectionPlanToAsset';
import ImportAssets from './components/import-assets/ImportAssets';
import ImportMeasureTablePage from './components/import-measure-table-page/ImportMeasureTablePage';
import ImportReferenceDocumentsPage from './components/import-reference-documents-page/ImportReferenceDocumentsPage';

export default function AssetsRoutes() {
  return (
    <Switch>
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/assets'
        exact
        render={(props) => <AssetsListPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/assets/:id(\d+)/linked-inspection-plans'
        exact
        render={(props) => <AssignInspectionPlanToAsset {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_ASSET_ROLES}
        path='/assets/add'
        exact
        render={(props) => <AssetsAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_ASSET_ROLES}
        path='/assets/import'
        exact
        render={(props) => <ImportAssets {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_ASSET_ROLES}
        path='/assets/import-measurement-tables'
        exact
        render={(props) => <ImportMeasureTablePage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_ASSET_ROLES}
        path='/assets/import-reference-documents'
        exact
        render={(props) => <ImportReferenceDocumentsPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/assets/:id(\d+)'
        exact
        render={(props) => <AssetsDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_ASSET_ROLES}
        path='/assets/:id(\d+)/edit'
        exact
        render={(props) => <AssetsEditPage {...props} />}
      />
    </Switch>
  );
}
