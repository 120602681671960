import classNames from 'classnames';

import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import Pagination from 'lib/components/pagination/Pagination';
import SearchInput from 'lib/components/search-input/SearchInput';

import './SourcesModal.scss';

const renderNameAndExtId = (v) => {
  return v?.name
    ? `${v.name}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const sourcesTableColumns = [
  {
    title: 'Source',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => renderNameAndExtId(record),
  },
  {
    title: 'Type',
    className: 'type-cell',
    sortId: 'type.name',
    render: (record) => record?.type?.name,
  },
  {
    title: 'Location',
    className: 'location-cell',
    sortId: 'location.reducedLocation',
    render: ({ location = {} }) => (
      <>
        {!!location.address && (
          <>
            {location.address}
            <br />
          </>
        )}
        {[location.city, location.country].filter((i) => !!i).join(', ')}
      </>
    ),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const SourcesModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  sortBy,
  page,
  pageSize,
  setPage,
  count,
  className,
  title = 'Assign Source',
  ...props
}) => {
  const hasPagination = setPage && pageSize && page && count > 0;
  return (
    <Modal
      title={title}
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search'
            data-testid='SourcesModal.search'
          />
        </div>
      }
      actions={
        hasPagination && (
          <Pagination
            pageSize={pageSize}
            setPage={setPage}
            page={page}
            count={count}
            threshold={3}
          />
        )
      }
      className={classNames('sources-modal', className)}
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <Table
        data={data}
        columns={sourcesTableColumns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </Modal>
  );
};

export default SourcesModal;
