import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportDefectsPage.scss';

const columnLabels = {
  externalId: 'External ID',
  language: 'Language',
  name: 'Name',
  id: 'ID',
};

const defectErrorValidations = {
  id: {
    'defect.notFound': () => 'Could not find defect by ID',
  },
  name: {
    'defect.unique': () => 'Defect name already exists',
    'any.required': () => 'Defect name field is required',
    'language.duplicate': () => 'Name in this language was already imported',
  },
  'defect.name': {
    'name.text.unique': () => 'Defect name already exists',
    'language.unique': () => 'Name in this language was already imported',
    'language.duplicate': () => 'Name in this language was already imported',
  },
};

export default function ImportDefectsPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-defects-page'>
      <h4>Import Defects</h4>
      <p className='form-info'>
        Before importing defects, please make sure you structure your data like
        in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/defects.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='defects' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={defectErrorValidations}
        columnLabels={columnLabels}
      />
    </div>
  );
}
