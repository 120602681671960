import Pill from 'lib/components/pill/Pill';
import classNames from 'classnames';

import './StatusPill.scss';

export const STATUS_PILL_VARIANT = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  ALERT: 'alert',
  NOTIFY: 'notify',
  INFO: 'info',
};

const StatusPill = ({
  variant = STATUS_PILL_VARIANT.NOTIFY,
  className,
  children,
  icon,
  inline,
  ...props
}) => {
  const classes = classNames('status-pill', className, variant, { inline });
  return (
    <Pill className={classes} {...props}>
      {icon}
      <span>{children}</span>
    </Pill>
  );
};

export default StatusPill;
