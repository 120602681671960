import { useEffect, useReducer, useContext } from 'react';
import { AppContext } from 'App';
import {
  reducer,
  initialState,
  SOURCES_DETAIL_ACTIONS,
} from './sourcesDetailReducer';
import sourcesService from './sourcesService';
import {
  ADD_SOURCES_ROLES,
  EDIT_OWN_SOURCES_ROLES,
  VIEW_ASSOCIATED_WORK_OBJECTS,
} from './sourcePermissions';
import useRoles from 'lib/useRoles';
import ROLES from 'config/roles';
import { format } from 'date-fns';

const sourcesDetailParams = {
  relations: [
    'location',
    'type',
    'images',
    'owners.profilePhoto',
    'tags',
    'coordinators.profilePhoto',
  ],
};

const getRelatedWorkObjects = (id, dispatch) => {
  sourcesService
    .getRelatedWorkObjects({ atSource: id })
    .then((workObjects) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS,
        payload: workObjects,
      });
    })
    .catch((e) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
};

const getRelatedInspections = (id, dispatch) => {
  sourcesService
    .getRelatedInspections(id)
    .then((inspections) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTIONS,
        payload: inspections,
      });
    })
    .catch((e) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
};

const getRelatedInspectionPlans = (id, isUserExternalSourceOwner, dispatch) => {
  sourcesService
    .getInspectionPlans({
      pageSize: 5,
      sortBy: isUserExternalSourceOwner ? 'canPerformSurvey' : 'id',
      sortOrder: 'DESC',
      filters: { bySourceId: id }
    })
    .then((inspectionPlans) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTION_PLANS,
        payload: inspectionPlans,
      });
    })
    .catch((e) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
}

const getValidInspections = (id, date, dispatch) => {
  sourcesService
    .getRelatedInspections(id, {
      filters: { validUntil: date },
      extraSelect: ['validUntil'],
      extraRelations: ['inspectionPlan'],
    })
    .then((inspections) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.APP_LOADS_VALID_INSPECTIONS,
        payload: inspections,
      });
    })
    .catch((e) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
};

const getSource = (id, dispatch, history) => {
  sourcesService
    .getSource(id, sourcesDetailParams)
    .then((source) => {
      dispatch({
        type: SOURCES_DETAIL_ACTIONS.APP_LOADS_DATA,
        payload: source,
      });
    })
    .catch((e) => {
      if (e.response?.status === 403) {
        history.push(`/sources`);
      } else {
        dispatch({
          type: SOURCES_DETAIL_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
      }
    });
};

const useSourcesDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    appState: { currentUser },
  } = useContext(AppContext);
  const { isAllowed } = useRoles();

  const isUserExternalSourceOwner = isAllowed([ROLES.EXTERNAL_SOURCE_OWNER]);

  const isEditVisible = () => {
    const isSourceOwner = state.source?.owners?.find(
      (owner) => owner.id === currentUser.id,
    );
    /* TODO: Get vendor from BE when implementing the display vendor list  */
    const isSourceVendor = isAllowed([ROLES.VENDOR]);

    return (
      isAllowed(ADD_SOURCES_ROLES) ||
      ((isSourceOwner || isSourceVendor) && isAllowed(EDIT_OWN_SOURCES_ROLES))
    );
  };

  // TODO: Refactor when permissions matrix gets finalized
  const isOwnerClickable = () => {
    return isAllowed([
      ROLES.QM_ADMIN,
      ROLES.CLIENT_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
    ]);
  };

  const userClicksOnOwner = (owner) => {
    props.history.push(`/users/${owner.id}`);
  };

  const userClicksOnWorkObjectRow = (workObjectId) => {
    props.history.push(`/work-objects/${workObjectId}`);
  };

  const userClicksOnInspectionRow = (inspection) => {
    props.history.push(`/inspections/${inspection?.id}`);
  };

  const userClicksOnInspectionPlanRow = (inspectionPlanId) => {
    props.history.push(`/inspection-plans/${inspectionPlanId}`);
  };

  const today = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    const id = props.match?.params?.id;
    if (!id) return;

    getSource(id, dispatch, props.history);

    if (isAllowed(VIEW_ASSOCIATED_WORK_OBJECTS)) {
      getRelatedWorkObjects(id, dispatch);
    }

    getValidInspections(id, today, dispatch);
    getRelatedInspections(id, dispatch);
    getRelatedInspectionPlans(id, isUserExternalSourceOwner, dispatch)
  }, [props.match?.params?.id, isUserExternalSourceOwner]);

  return {
    state,
    today,

    userClicksOnOwner,
    userClicksOnWorkObjectRow,
    userClicksOnInspectionRow,
    userClicksOnInspectionPlanRow,
    isEditVisible,
    isOwnerClickable,
  };
};

export default useSourcesDetail;
