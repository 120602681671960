import classNames from 'classnames';
import paginate from 'lib/paginate';

import Toggle from '../toggle/Toggle';

import { ReactComponent as ChevronsUp } from 'assets/images/chevrons-up.svg';

import './Pagination.scss';

const Pagination = ({ pageSize, page, setPage, count, threshold }) => {
  const currentPage = Number(page);
  const { pages, startPage, endPage, totalPages } = paginate(
    count,
    currentPage,
    pageSize,
    threshold,
  );

  const firstPageDisabled = currentPage === startPage;
  const lastPageDisabled = currentPage === endPage;

  return (
    <div className='pagination-container'>
      {
        <div className='pagination-button-group'>
          <Toggle
            disabled={firstPageDisabled}
            className='page-button'
            direction='left'
            icon={<ChevronsUp />}
            onClick={() => setPage(1)}
          />
          <Toggle
            disabled={firstPageDisabled}
            className='page-button'
            direction='left'
            onClick={() => setPage(currentPage - 1)}
          />
        </div>
      }
      {pages.map((pageNo, index) => {
        const classes = classNames('page-number', {
          active: currentPage === pageNo,
        });
        return (
          <button
            key={index}
            onClick={() => setPage(pageNo)}
            className={classes}
          >
            {pageNo}
          </button>
        );
      })}
      {
        <div className='pagination-button-group'>
          <Toggle
            disabled={lastPageDisabled}
            className='page-button'
            direction='right'
            onClick={() => setPage(currentPage + 1)}
          />
          <Toggle
            disabled={lastPageDisabled}
            className='page-button'
            direction='right'
            icon={<ChevronsUp />}
            onClick={() => setPage(totalPages)}
          />
        </div>
      }
    </div>
  );
};

export default Pagination;
