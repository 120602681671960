import useCompaniesDetail from 'modules/companies/useCompaniesDetail';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import {
  LOGIN_COMPANIES_ROLES,
  ADD_COMPANIES_ROLES,
} from 'modules/companies/companiesPermissions';

import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import EditButton from 'lib/components/edit-button/EditButton';
import IconButton from 'lib/components/icon-button/IconButton';
import ContactInfo from '../contact-info/ContactInfo';

import { ReactComponent as LoginIcon } from 'assets/images/login-outline.svg';

import './CompaniesDetailPage.scss';

const CompaniesDetailPage = (props) => {
  const { state, ...vm } = useCompaniesDetail(props);
  const { company, loading, errors } = state;

  if (loading || errors.length) {
    return null;
  }

  return (
    <div className='companies-detail-page container-m'>
      <div className='company-header'>
        <div className='details'>
          <h1 className='name'>{company?.name}</h1>
          <div className='info'>
            <span>ID: {company?.id}</span>
            <span>Status: {company?.status}</span>
          </div>
        </div>
        <div className='buttons-container'>
          <ProtectedComponent allowed={ADD_COMPANIES_ROLES}>
            <EditButton
              onClick={vm.userClicksEditCompany}
              data-testid='CompaniesDetailPage.editCompany'
            >
              Edit
            </EditButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={LOGIN_COMPANIES_ROLES}>
            <IconButton
              prefix={<LoginIcon />}
              onClick={vm.userClicksLoginCompany}
              data-testid='CompaniesDetailPage.loginCompany'
            >
              Login to this company
            </IconButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='contact-information'>
        <div className='group'>
          <p className='title'>Address:</p>
          <p className='info'>{company?.location?.address || '-'}</p>
          <p className='info'>{company?.location?.zipCode || ''}</p>
          <p className='info'>{company?.location?.city || ''}</p>
          <p className='info'>{company?.location?.state || ''}</p>
          <p className='info'>{company?.location?.country || ''}</p>
        </div>
        <ContactInfo company={company} />
      </div>
      <div className='about-container row'>
        <h4>About the company</h4>
        <p className='description'>{company?.about || '-'}</p>
      </div>
      <div className='client-admin-container row'>
        <h4>Admins</h4>
        <div className='client-admin-list'>
          {(company?.clientAdmins || []).map((clientAdmin) => (
            <NamedAvatar
              onClick={() => vm.userClicksClientAdmin(clientAdmin)}
              large
              user={clientAdmin}
              key={clientAdmin?.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompaniesDetailPage;
