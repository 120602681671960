import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './WorkObjectsImportPage.scss';

const workObjectColumnLabels = {
  id: 'ID',
  externalId: 'External ID',
  assetExternalId: 'Asset External ID',
  asset: 'Asset',
  source: 'Source',
  sourceId: 'Source ID',
  dstId: 'Destination ID',
  deadline: 'Deadline',
  quantity: 'Quantity',
  woQty: 'Split Quantity',
  originalWorkflow: 'Workflow',
  workflowId: 'Workflow ID',
  targetAssetId: 'Target asset ID',
  targetSourceId: 'Target source ID',
  order: 'Order',
};

const workObjectErrorValidations = {
  assetExternalId: {
    'any.required': () => 'Asset external ID is required',
    'string.max': () => 'Asset external ID needs to be at most 50 characters',
  },
  asset: {
    'asset.notFound': () => 'Asset not found on your company',
    'any.required': () => 'Asset name is required',
  },
  source: {
    'source.notFound': () => 'Source not found on your company',
  },
  WorkObject: {
    'workObject.notFound': () => 'Work object not found',
  },
  sourceId: {
    'sourceId.notFound': () => 'Source Factory ID not found on your company',
    'sourceId.required': () => 'Workflow requires Source ID to be specified',
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `Source Factory ID must be consistent with line ${lineNumber}`,
    'string.max': () => 'Source Factory ID needs to be at most 50 characters',
  },
  dstId: {
    'dstId.notFound': () => 'Source Destination ID not found on your company',
    'dstId.required': () => 'Workflow requires Destination ID to be specified',
    'workflow.stepsExceeded': () =>
      'Selected workflow has only 1 step. Source Factory ID and Source Destination ID must be equal',
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `Source Destination ID must be consistent with line ${lineNumber}`,
    'string.max': () =>
      'Source Destination ID needs to be at most 50 characters',
  },
  deadline: {
    'date.min': () => 'Ready Date has passed',
    'any.required': () => 'Ready Date is required',
    'date.format': () => 'Ready Date has an invalid format',
  },
  quantity: {
    'string.pattern.base': () =>
      'Split Qty needs to be an integer greater than 0',
    'number.greater': () => 'Split Qty needs to be an integer greater than 0',
    'any.required': () => 'Split Qty is required',
    'wo.qtyExceeded': () =>
      'Split quantity exceeds the remaining quantity of the Work Object',
    'number.base': () => 'Split Qty must be a number',
  },
  woQty: {
    'wo.qtyExceeded': () =>
      'Split quantity exceeds the remaining quantity of the Work Object',
    'number.greater': () => 'WO Qty needs to be an integer greater than 0',
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `WO Qty must be consistent with line ${lineNumber}`,
    'any.required': () => 'WO Qty is required',
    'number.base': () => 'WO Qty must be a number',
  },
  originalWorkflow: {
    'originalWorkflow.notFound': () => 'Workflow ID not found on your company',
  },
  workflowId: {
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `Workflow ID must be consistent with line ${lineNumber}`,
    'any.required': () => 'Workflow ID is required',
    'string.max': () => 'Workflow ID needs to be at most 50 characters',
  },
  targetAssetId: {
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `Asset must be consistent with line ${lineNumber}`,
    'object.missing': () => 'Asset is required',
    'object.xor': () =>
      'Asset and Source must not be both set at the same time',
    'string.max': () => 'Asset name needs to be at most 50 characters',
  },
  targetSourceId: {
    'lineNumber.inconsistent': ({ lineNumber }) =>
      `Source must be consistent with line ${lineNumber}`,
    'object.missing': () => 'Source is required',
    'object.xor': () => null,
    'string.max': () => 'Source needs to be at most 50 characters',
  },
  externalId: {
    'any.required': () => 'External ID is required',
    'string.max': () => 'External ID needs to be at most 50 characters',
  },
  order: {
    'any.required': () => 'Split is required',
    'number.greater': () => 'Split needs to be an integer greater than 0',
    'number.base': () => 'Split should be a number',
  },
  __default__: {
    /* Custom columns */
    'string.max': ({ field }) => `${field} needs to be at most 1000 characters`,
  },
};

export default function WorkObjectsImportPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='work-objects-import-page container-m'>
      <h2>Import work objects</h2>
      <p className='form-info'>
        Before importing work objects, please make sure you structure your data
        like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/work-objects.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='work-objects' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={workObjectErrorValidations}
        columnLabels={workObjectColumnLabels}
      />
    </div>
  );
}
