import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const SETTINGS_QUESTIONS_ACTIONS = {
  APP_STARTS_EXPORT: 'APP_STARTS_EXPORT',
  APP_FINISHES_EXPORT: 'APP_FINISHES_EXPORT',
};

const INITIAL_QUESTIONS_STATE = {
  isExportingQuestions: false,
};

const SETTINGS_QUESTIONS_REDUCER_CONFIG = {
  [SETTINGS_QUESTIONS_ACTIONS.APP_STARTS_EXPORT]: (state) => {
    return {
      ...state,
      isExporting: true,
    };
  },
  [SETTINGS_QUESTIONS_ACTIONS.APP_FINISHES_EXPORT]: (state) => {
    return {
      ...state,
      isExporting: false,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  SETTINGS_QUESTIONS_REDUCER_CONFIG,
  INITIAL_QUESTIONS_STATE,
);
