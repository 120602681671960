import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './SourcesImportPage.scss';

const sourceColumnLabels = {
  id: 'ID',
  externalId: 'External ID',
  name: 'Name',
  status: 'Status',
  type: 'Type',
  'location.address': 'Address',
  'location.zipCode': 'Postal code',
  'location.city': 'City',
  'location.district': 'District',
  'location.state': 'State',
  'location.country': 'Country',
  'location.coordinates.x': 'Latitude',
  'location.coordinates.y': 'Longitude',
};

const errorGenerator = {
  source: {
    'source.notFound': () => 'Source with provided Exacture ID not found',
  },
  externalId: {
    'string.max': () => 'External ID must have less than 50 characters',
  },
  name: {
    'any.required': () => 'Name is required',
    'string.max': () => 'Name must have less than 50 characters',
    'string.empty': () => 'Name is required',
    'source.unique': () =>
      'A different source with the same name already exists',
  },
  status: {
    'any.required': () => 'Status is required',
    'any.only': () => 'Status is not valid',
    'string.empty': () => 'Status is required',
  },
  type: {
    'any.required': () => 'Type is required',
    'any.only': () => 'Type is not valid',
    'string.empty': () => 'Type is required',
  },
  'location.address': {
    'string.max': () => 'Address must have at most 255 characters',
    'any.required': () => 'Address is required',
    'string.empty': () => 'Address is required',
    'string.pattern.base': () => 'Address has invalid characters',
  },
  'location.zipCode': {
    'string.max': () => 'Postal Code must have at most 10 characters',
    'string.alphanum': () => 'Postal Code must have only numbers and letters',
  },
  'location.city': {
    'string.max': () => 'City must have at most 255 characters',
    'any.required': () => 'City is required',
    'string.empty': () => 'City is required',
    'string.pattern.base': () => 'City has invalid characters',
  },
  'location.district': {
    'string.max': () => 'District must have at most 255 characters',
    'string.pattern.base': () => 'District has invalid characters',
  },
  'location.state': {
    'string.max': () => 'State must have at most 100 characters',
    'string.pattern.base': () => 'State has invalid characters',
  },
  'location.country': {
    'string.max': () => 'Country must have at most 255 characters',
    'any.required': () => 'Country is required',
    'string.empty': () => 'Country is required',
    'string.pattern.base': () => 'Country is not in IS0-3166 format',
  },
  'location.coordinates.x': {
    'number.max': () => 'Latitude must be at most 90',
    'number.min': () => 'Latitude must be at least -90',
    'number.base': () => 'Latitude must be a number',
    'any.required': () => 'Latitude is required when Longitude is present',
  },
  'location.coordinates.y': {
    'number.max': () => 'Longitude must be at most 180',
    'number.min': () => 'Longitude must be least -180',
    'number.base': () => 'Longitude must be a number',
    'any.required': () => 'Longitude is required when Latitude is present',
  },
  sourceOwners: {
    'sourceOwners.notFound': () =>
      'Source Owner must be a valid user with Source Owner role assigned to your company',
  },
};

export default function SourcesImportPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='sources-import-page'>
      <h2>Import sources</h2>
      <p className='form-info'>
        Before importing sources, please make sure you structure your data like
        in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/sources.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='sources' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={errorGenerator}
        columnLabels={sourceColumnLabels}
      />
    </div>
  );
}
