import React from 'react';
import LinkText from '../link-text/LinkText';
import './Breadcrumbs.scss';

export const entityToBreadcrumb = (link, name) => (entity) => ({
  link: entity[link],
  name: entity[name],
});

export default function Breadcrumbs({ data, ...props }) {
  return (
    <div
      className='qm-breadcrumbs'
      data-testid='Breadcrumbs.container'
      {...props}
    >
      {data.map((el) => (
        <React.Fragment key={el.link}>
          <LinkText
            link={el.link}
            name={el.name}
            data-testid='Breadcrumbs.link'
          />
          <span className='divider'>/</span>
        </React.Fragment>
      ))}
    </div>
  );
}
