import ReactImageGallery from 'react-image-gallery';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './ImageGallery.scss';

const renderNavButton = (dir) => {
  return (onClick, disabled) => (
    <button
      className={`gallery-nav ${dir}`}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={dir === 'right' ? faArrowRight : faArrowLeft} />
    </button>
  );
};

const ImageGallery = (props) => {
  return (
    <ReactImageGallery
      useTranslate3D={false}
      slideDuration={0}
      showFullscreenButton={false}
      showPlayButton={false}
      renderLeftNav={renderNavButton('left')}
      renderRightNav={renderNavButton('right')}
      additionalClass={classNames('qm-image-gallery', {
        'thumbnails-center': props.items?.length < 4,
      })}
      {...props}
    />
  );
};

export default ImageGallery;
