import { upsertTranslation } from 'lib/dataTransform';
import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const SETTINGS_ANSWERS_ACTIONS = {
  SET_EDIT_ANSWER: 'SET_EDIT_ANSWER',
  CLEAR_EDIT_ANSWER: 'CLEAR_EDIT_ANSWER',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_OPENS_INPUT_MODAL: 'USER_OPENS_INPUT_MODAL',
  USER_CANCELS_INPUT_MODAL: 'USER_CANCELS_INPUT_MODAL',
  USER_SUBMITS_ANSWER: 'USER_SUBMITS_ANSWER',
  USER_ADDS_NEW_ANSWER: 'USER_ADDS_NEW_ANSWER',
  USER_ADDS_ANSWER_OPTION: 'USER_ADDS_ANSWER_OPTION',
  USER_ADDS_ANSWER_OPTION_NAME: 'USER_ADDS_ANSWER_OPTION_NAME',
  ADD_NEW_ANSWER_OPTION: 'ADD_NEW_ANSWER_OPTION',
  USER_EDITS_OPTIONS_NAME: 'USER_EDITS_OPTIONS_NAME',
  USER_EDITS_ACTION: 'USER_EDITS_ACTION',
  ADD_ANSWER_OPTION: 'ADD_ANSWER_OPTION',
  USER_SELECTS_ACTION: 'USER_SELECTS_ACTION',
  CLEAR_OPTION_IN_MODAL: 'CLEAR_OPTION_IN_MODAL',
  CLEAR_OPTION_IN_EDIT: 'CLEAR_OPTION_IN_EDIT',
  APP_STARTS_EXPORTING_ANSWERS: 'APP_STARTS_EXPORTING_ANSWERS',
  APP_FINISHES_EXPORTING_ANSWERS: 'APP_FINISHES_EXPORTING_ANSWERS',
  RESET_STATE: 'RESET_STATE',
  USER_SETS_LANGUAGE: 'USER_SETS_LANGUAGE',
};

const getInitialModalState = () => ({
  name: { value: '', errors: [] },
  options: [
    {
      label: { value: [], errors: [] },
      order: 0,
      meaning: { value: '', errors: [] },
    },
  ],
  actions: [
    { label: 'Accept', value: 'accept' },
    { label: 'Reject', value: 'reject' },
    { label: 'N/A', value: 'skip' },
  ],
  isModalOpen: false,
  errors: [],
  isDirty: false,
});

const INITIAL_ANSWERS_STATE = {
  editField: {
    name: { value: '', errors: [] },
    id: null,
    editing: false,
    options: [
      {
        label: { value: [], errors: [] },
        meaning: { value: '', errors: [] },
      },
    ],
    isDirty: false,
  },
  addAnswer: getInitialModalState(),
  actions: [
    { label: 'Accept', value: 'accept' },
    { label: 'Reject', value: 'reject' },
    { label: 'N/A', value: 'skip' },
  ],
  isExportingAnswers: false,
  language: null,
};

const SETTINGS_ANSWERS_REDUCER_CONFIG = {
  [SETTINGS_ANSWERS_ACTIONS.SET_EDIT_ANSWER]: (state, action) => {
    const answer = state.data.filter(
      (answer) => answer.id === action.payload.id,
    );
    const options = answer[0].options.map((option) => ({
      label: { value: option.label, errors: [] },
      meaning: { value: option.meaning, errors: [] },
      order: option.order,
      id: option.id,
      notEditable: true,
    }));
    return {
      ...state,
      editField: {
        name: { value: action.payload.name, errors: [] },
        editing: true,
        id: action.payload.id,
        options,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.CLEAR_EDIT_ANSWER]: (state) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        name: { value: '' },
        id: null,
        editing: false,
        options: [
          {
            label: { value: [], errors: [] },
            meaning: { value: '', errors: [] },
            order: 0,
          },
        ],
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        name: { value: action.payload, errors: [] },
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_OPENS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        isModalOpen: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_CANCELS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addAnswer: getInitialModalState(),
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_ADDS_NEW_ANSWER]: (state, action) => {
    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        name: { value: action.payload, errors: [] },
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_EDITS_OPTIONS_NAME]: (state, action) => {
    const newArray = state.editField.options.map((option, index) => {
      if (index === action.payload.id) {
        const newValue = upsertTranslation(
          option.label.value,
          action.payload.value,
          state.language,
        );
        return {
          ...option,
          label: { value: newValue, errors: [] },
        };
      }

      return option;
    });

    return {
      ...state,
      editField: {
        ...state.editField,
        options: newArray,
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_ADDS_ANSWER_OPTION_NAME]: (state, action) => {
    const newArray = state.addAnswer.options.map((option, index) => {
      if (index === action.payload.id) {
        const newValue = upsertTranslation(
          option.label.value,
          action.payload.value,
          state.language,
        );
        return {
          ...option,
          label: { value: newValue, errors: [] },
        };
      }

      return option;
    });

    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        options: newArray,
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_EDITS_ACTION]: (state, action) => {
    const optionIndex = state.editField.options.findIndex(
      (_, index) => index === action.payload.id,
    );
    const newArray = [...state.editField.options];
    newArray[optionIndex] = {
      ...newArray[optionIndex],
      meaning: { value: action.payload.value, errors: [] },
    };

    return {
      ...state,
      editField: {
        ...state.editField,
        options: newArray,
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_SELECTS_ACTION]: (state, action) => {
    const optionIndex = state.addAnswer.options.findIndex(
      (_, index) => index === action.payload.id,
    );
    const newArray = [...state.addAnswer.options];
    newArray[optionIndex] = {
      ...newArray[optionIndex],
      meaning: { value: action.payload.value.value, errors: [] },
    };

    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        options: newArray,
        isDirty: true,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_SUBMITS_ANSWER]: (state) => {
    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        isModalOpen: false,
        isDirty: false,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.ADD_ANSWER_OPTION]: (state) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        options: [
          ...state.editField.options,
          {
            label: { value: [], errors: [] },
            meaning: { value: '', errors: [] },
            order: state.editField.options.length,
          },
        ],
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.ADD_NEW_ANSWER_OPTION]: (state) => {
    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        options: [
          ...state.addAnswer.options,
          {
            label: { value: [], errors: [] },
            meaning: { value: '', errors: [] },
            order: state.editField.options.length,
          },
        ],
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.CLEAR_OPTION_IN_MODAL]: (state, action) => {
    const optionsArray = state.addAnswer.options.filter(
      (_, index) => index !== action.payload,
    );
    return {
      ...state,
      addAnswer: {
        ...state.addAnswer,
        options: optionsArray,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.CLEAR_OPTION_IN_EDIT]: (state, action) => {
    const optionsArray = state.editField.options.filter(
      (_, index) => index !== action.payload,
    );
    return {
      ...state,
      editField: {
        ...state.editField,
        options: optionsArray,
      },
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.APP_STARTS_EXPORTING_ANSWERS]: (state) => {
    return {
      ...state,
      isExportingAnswers: true,
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.APP_FINISHES_EXPORTING_ANSWERS]: (state) => {
    return {
      ...state,
      isExportingAnswers: false,
    };
  },
  [SETTINGS_ANSWERS_ACTIONS.USER_SETS_LANGUAGE]: (state, action) => {
    return {
      ...state,
      language: action.payload,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  SETTINGS_ANSWERS_REDUCER_CONFIG,
  INITIAL_ANSWERS_STATE,
);
