import { formatDate } from 'lib/dateHelpers';

import Button from 'lib/components/button/Button';
import Modal from 'lib/components/modal/Modal';

export default function InspectionsListPageBulkConfirmModal({ data, onClose }) {
  const getAppliedChangesLabel = () => {
    if (data?.inspectionDate?.length)
      return (
        <>
          Inspection Date{' '}
          <span className='applied-value'>
            {formatDate(data.inspectionDate)}
          </span>{' '}
          was applied to the following{' '}
          <span className='applied-inspections-count'>
            {data.selectedInspections.length ?? 'selected'}
          </span>{' '}
          inspections
        </>
      );
    else if (data?.selectedInspector?.name?.length)
      return (
        <>
          Inspector{' '}
          <span className='applied-value'>{data.selectedInspector.name}</span>{' '}
          was assigned to the following{' '}
          <span className='applied-inspections-count'>
            {data.selectedInspections.length ?? 'selected'}
          </span>{' '}
          inspections
        </>
      );
    else
      return (
        <>
          Bulk changes were applied to the following{' '}
          <span className='applied-inspections-count'>
            {data.selectedInspections?.length ?? 'selected'}
          </span>{' '}
          inspections
        </>
      );
  };

  const getInspectionInfoLabel = (inspection) => {
    const iId = inspection.id;
    const iType = inspection?.inspectionType?.type ?? null;
    const iAssetId = inspection?.asset?.id?.length ? inspection.asset.id : null;
    const iAssetExtId = inspection?.asset?.externalId?.length
      ? inspection.asset.externalId
      : null;
    const iSourceId = inspection?.source?.id?.length
      ? inspection.source.id
      : null;
    const iSourceExtId = inspection?.source?.externalId?.length
      ? inspection.source.externalId
      : null;
    const iWorkObjExtId = inspection?.workObjectExternalId?.length
      ? inspection.workObjectExternalId
      : null;

    let iAssetLabel = null;
    let iSourceLabel = null;
    let iWorkObjLabel = null;

    if (iType === 'asset' && (iAssetId || iAssetExtId)) {
      iAssetLabel = `Asset ${iAssetExtId ? ' External' : ''} ID: ${
        iAssetExtId ?? iAssetId
      }`;
    }

    if (iType === 'source' && (iSourceId || iSourceExtId)) {
      iSourceLabel = `Source ${iSourceExtId ? ' External' : ''} ID: ${
        iSourceExtId ?? iSourceId
      }`;
    }

    if (iWorkObjExtId) {
      iWorkObjLabel = `Work Object: ${iWorkObjExtId}`;
    }

    let iInfoLabel = `${iAssetLabel ?? iSourceLabel ?? ''}`;

    if (iInfoLabel?.length && iWorkObjLabel?.length) iInfoLabel += ', ';

    if (iWorkObjLabel?.length) {
      iInfoLabel += iWorkObjLabel;
    }

    return (
      <>
        <span className='inspection-id'>{iId}</span>{' '}
        <span className='inspection-info'>
          {iInfoLabel?.length ? `(${iInfoLabel})` : ''}
        </span>
      </>
    );
  };

  const appliedChangesLabel = getAppliedChangesLabel();

  return (
    <Modal
      title='Bulk changes applied'
      className='inspection-list--bulk-confirm'
      actions={<Button onClick={onClose}>Ok</Button>}
      isOpen={data?.isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <p className='applied-changes-label'>{appliedChangesLabel}:</p>
      <ul className='applied-changes-list'>
        {data?.selectedInspections?.map((item, idx) => (
          <li key={idx}>{getInspectionInfoLabel(item)}</li>
        ))}
      </ul>
    </Modal>
  );
}
