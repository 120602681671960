import CsvImport from 'lib/components/csv-import/CsvImport';
import File from 'lib/components/file/File';
import ImportResult from 'lib/components/import-error/ImportResult';
import useCsvImport from 'lib/useCsvImport';

import './ImportMeasureTablePage.scss';

const measurementColumnLabels = {
  id: 'ID',
  externalId: 'External ID',
};

const errorGenerator = {
  externalId: {
    'string.max': () => 'External ID must have less than 50 characters',
    'string.empty': () => 'External ID is required',
  },
  asset: {
    'asset.notFound': () => 'Asset not found in your company',
    'measurementUnit.notFound': () => 'Invalid measurement unit',
    'dimensionUnit.notFound': () => 'Invalid dimension unit',
    'volumeUnit.notFound': () => 'Invalid volume unit',
    'weightUnit.notFound': () => 'Invalid weight unit',
  },
  __default__: {
    'object.unknown': ({ field }) => `${field} is not a valid column`,
    'string.max': ({ field }) => `${field} must have less than 50 characters`,
    __default__: ({ field }) => `${field} has an unknown error`,
  },
};

export default function ImportMeasureTablePage(props) {
  const vm = useCsvImport(props);

  return (
    <div className='import-measure-table-page container-m'>
      <h2>Import measurement tables</h2>
      <p className='form-info'>
        Before importing measurement tables, please make sure you structure your
        data like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/measurement-table.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='asset-measure-tables' onUploadInfo={vm.uploadInfo} />
      <ImportResult
        list={vm}
        errorValidations={errorGenerator}
        columnLabels={measurementColumnLabels}
      />
    </div>
  );
}
