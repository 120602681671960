import { parseISO } from 'date-fns/fp';
import { subDays } from 'date-fns/fp';
import { pipe } from './funcHelpers';

const UNITS = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

/**
 * Format a time duration in short-form readable form (e.g. 2h 30m)
 * @param {number} duration duration in seconds
 * @returns {string} formatted duration
 */
export function formatDuration(duration) {
  if (!duration) return '';
  const date = new Date(duration * 1000);
  const steps = {
    d: date.getUTCDate() - 1,
    h: date.getUTCHours(),
    m: date.getUTCMinutes(),
    s: date.getUTCSeconds(),
  };
  return Object.entries(steps).reduce(
    (acc, [label, value]) => (value ? `${acc} ${value}${label}` : acc),
    '',
  );
}

// Source: https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time
/**
 * Format a date to relative date using Intl.RelativeTimeFormat
 * @param {Date} date date to be formatted
 * @param {Date} referralDate [referralDate = new Date()] - referralDate to compare with
 * @returns returns a string with the formatted date
 */
export function relativeDate(date, referralDate = new Date()) {
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const timeElapsed = date - referralDate;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (let u in UNITS) {
    if (Math.abs(timeElapsed) > UNITS[u] || u === 'second') {
      const dateString = rtf.format(Math.round(timeElapsed / UNITS[u]), u);
      //Transform to sentence case if only one word eg: Yesterday instead of yesterday
      if (dateString?.split(' ')?.length === 1) {
        return dateString[0].toUpperCase() + dateString.slice(1);
      }
      return dateString;
    }
  }
}

/**
 * Format a date to HTML date string format yyyy-mm-dd
 * @param {Date} date date to be formatted
 * @returns returns a string with the format yyyy-mm-dd
 */
export function formatDateToHTMLDateString(date) {
  if (date instanceof Date && isNaN(date)) {
    return '';
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return [year, month, day].join('-');
}

/**
 * Format a date to HTML date string format MM/DD/YYYY
 * @param {Date | string} newDate date to be formatted
 * @returns returns a string with the format MM/DD/YYYY
 */
export function formatDate(newDate, delimiter = '.') {
  if (!newDate) {
    return null;
  }
  const date = typeof newDate === 'string' ? parseISO(newDate) : newDate;
  return (
    (1 + date.getMonth()).toString().padStart(2, '0') +
    delimiter +
    date.getDate().toString().padStart(2, '0') +
    delimiter +
    date.getFullYear()
  );
}

/**
 * Format a date to date time format MM/DD/YYYY H:MM
 * @param {Date} date date to be formatted
 * @param {string} delimiter date to be formatted
 * @returns returns a string with the format MM/DD/YYYY H:MM
 */
export function formatDateTime(date, delimiter = '.') {
  const dateString = formatDate(date, delimiter);
  const newDate = new Date(date);
  return `${dateString}. ${newDate?.getHours()}:${newDate
    ?.getMinutes()
    .toString()
    .padStart(2, '0')}`;
}

/**
 * Get minimum selectable calendar date that can be today/future.
 * This is fuzzy to account for timezone difference in calendar dates.
 * Sometime, we might want to change how we limit 'today' dates
 * @returns string
 */
export function getMinFutureCalendarDate() {
  return pipe(subDays(2), formatDateToHTMLDateString)(new Date());
}

/**
 * Transform an ISO TimeZoned Date String to a simple date string
 * @param {string} date
 * @returns {string}
 */
export function castDateTimeToSimpleDate(date) {
  return pipe((s) =>
    s.replace(/([0-9]{4})-([0-9]{2})-([0-9]{2}).*/, '$1-$2-$3'),
  )(date);
}
