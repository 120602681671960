import { cloneDeep } from 'lodash';
import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const PAGE_ACTIONS = {
  APP_LOADS_TAGS: 'APP_LOADS_TAGS',
  APP_LOADS_ASSET_TYPES: 'APP_LOADS_ASSET_TYPES',
  APP_LOADS_SOURCE_TYPES: 'APP_LOADS_SOURCE_TYPES',
  APP_LOADS_INSPECTION_TYPES: 'APP_LOADS_INSPECTION_TYPES',
  APP_LOADS_CUSTOM_ATTRIBUTES: 'APP_LOADS_CUSTOM_ATTRIBUTES',
  APP_LOADS_COMPANY_ATTRIBUTES: 'APP_LOADS_COMPANY_ATTRIBUTES',
  APP_LOADS_QUESTION_OPTIONS: 'APP_LOADS_QUESTION_OPTIONS',
  USER_OPENS_QUESTION_GROUP_FORM: 'USER_OPENS_QUESTION_GROUP_FORM',
  USER_OPENS_ADD_QUESTION_GROUP_MODAL: 'USER_OPENS_ADD_QUESTION_GROUP_MODAL',
  USER_CANCELS_QUESTION_GROUP_FORM: 'USER_CANCELS_QUESTION_GROUP_FORM',
  USER_SAVES_QUESTION_GROUP: 'USER_SAVES_QUESTION_GROUP',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  SET_PAGE_ERRORS: 'SET_PAGE_ERRORS',
  RESET_STATE: 'RESET_STATE',
};

export const QUESTION_GROUP_FORM_ACTIONS = {
  USER_CHANGES_GROUP_INPUT: 'USER_CHANGES_GROUP_INPUT',
  USER_SETS_GROUPED_SELECT: 'USER_SETS_GROUPED_SELECT',
  USER_CHANGES_QUESTION_INPUT: 'USER_CHANGES_QUESTION_INPUT',
  USER_MOVES_QUESTION: 'USER_MOVES_QUESTION',
  USER_REMOVES_QUESTION: 'USER_REMOVES_QUESTION',
  USER_TOGGLES_QUESTION: 'USER_TOGGLES_QUESTION',
  USER_ASSIGNS_NEW_QUESTIONS: 'USER_ASSIGNS_NEW_QUESTIONS',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
};

export const QUESTION_MODAL_ACTIONS = {
  APP_LOADS_QUESTION_TEMPLATES: 'APP_LOADS_QUESTION_TEMPLATES',
  USER_OPENS_QUESTION_MODAL: 'USER_OPENS_QUESTION_MODAL',
  USER_CANCELS_QUESTION_MODAL: 'USER_CANCELS_QUESTION_MODAL',
  USER_SEARCHES_QUESTION_MODAL: 'USER_SEARCHES_QUESTION_MODAL',
  USER_SETS_QUESTION_MODAL_PAGE: 'USER_SETS_QUESTION_MODAL_PAGE',
  USER_SORTS_QUESTION_MODAL: 'USER_SORTS_QUESTION_MODAL',
};

const getInitialAddQuestionModalState = () => ({
  list: [],
  search: '',
  isModalOpen: false,
  sortBy: 'id',
  sortOrder: 'ASC',
  page: 1,
  pageSize: 10,
  selected: [],
});

const initialPageState = {
  form: null,
  initialData: [],
  questionOptions: null,
  tagOptions: [],
  assetTypeOptions: [],
  sourceTypeOptions: [],
  inspectionTypeOptions: [],
  customAttributeOptions: [],
  companyAttributeOptions: [],
  addQuestionOptions: getInitialAddQuestionModalState(),
  isAddModalOpen: false,
  processing: false,
};

function swapGroupQuestionTemplateOrders(group, qOrder, dir) {
  let targetOrder;
  const newGroup = cloneDeep(group);
  const qIdx = group.questions.findIndex((q) => q.order === qOrder);
  const maxOrder = group.questions.reduce(
    (acc, q) => (q.order > acc ? q.order : acc),
    0,
  );

  switch (dir) {
    case 'top':
      // If the current question has a dependency on another, we cannot
      // move before that dependency
      const depOrder = group.questions[qIdx].dependencyQuestion?.value?.value;
      targetOrder = depOrder !== undefined ? depOrder + 1 : 0;
      break;
    case 'up':
      // Buttons are disabled in UI when dependencies are next to eachother
      targetOrder = qOrder - 1;
      break;
    case 'down':
      // Buttons are disabled in UI when dependencies are next to eachother
      targetOrder = qOrder + 1;
      break;
    case 'bottom':
      // If the current question is a dependency for other questions, we
      // cannot move after any of them.
      const firstDependent = group.questions
        .sort((a, b) => a.order - b.order)
        .find(
          (q) =>
            q.dependencyQuestion?.value?.value === group.questions[qIdx].order,
        );
      targetOrder = firstDependent ? firstDependent.order - 1 : maxOrder;
      break;
    default:
      throw new Error(`Undefined direction ${dir}`);
  }

  if (targetOrder < 0 || targetOrder > maxOrder) {
    return group;
  }

  // Get the range of affected questions
  const start = Math.min(targetOrder, qOrder);
  const end = Math.max(targetOrder, qOrder);
  // Get the direction in which their order will be shifted
  const increment = dir === 'up' || dir === 'top' ? 1 : -1;

  // Loop through all affected questions and clear a spot for our moved
  // question to fit in
  newGroup.questions.forEach((q, idx) => {
    if (q.order >= start && q.order <= end) {
      q.order += increment;
    }

    // Also shift the order/label in the dependency question, if it falls
    // in the affected range of orders
    const dependency = newGroup.questions[idx].dependencyQuestion?.value;
    const depOrder = dependency?.value;

    if (depOrder >= start && depOrder <= end) {
      // Update the label in the dependency drop-down. Keep in mind that
      // the question we're moving is going up while others are going down
      // and vice-versa.
      const newOrder = depOrder !== qOrder ? depOrder + increment : targetOrder;

      newGroup.questions[idx].dependencyQuestion.value = {
        value: newOrder,
        label: `#${newOrder + 1}`,
      };
    }
  });

  // Put our question in the cleared spot
  newGroup.questions[qIdx].order = targetOrder;

  return newGroup;
}

const PAGE_REDUCER_CONFIG = {
  [PAGE_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_QUESTION_OPTIONS]: (state, action) => {
    return {
      ...state,
      questionOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_TAGS]: (state, action) => {
    return {
      ...state,
      tagOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_ASSET_TYPES]: (state, action) => {
    return {
      ...state,
      assetTypeOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_SOURCE_TYPES]: (state, action) => {
    return {
      ...state,
      sourceTypeOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_INSPECTION_TYPES]: (state, action) => {
    return {
      ...state,
      inspectionTypeOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_CUSTOM_ATTRIBUTES]: (state, action) => {
    return {
      ...state,
      customAttributeOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_COMPANY_ATTRIBUTES]: (state, action) => {
    return {
      ...state,
      companyAttributeOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.USER_OPENS_QUESTION_GROUP_FORM]: (state, action) => {
    return {
      ...state,
      form: action.payload,
    };
  },
  [PAGE_ACTIONS.USER_OPENS_ADD_QUESTION_GROUP_MODAL]: (state, action) => {
    return {
      ...state,
      form: {
        ...action.payload,
        isDirty: false,
      },
      isAddModalOpen: true,
    };
  },
  [PAGE_ACTIONS.USER_CANCELS_QUESTION_GROUP_FORM]: (state) => {
    return {
      ...state,
      form: null,
      addQuestionOptions: getInitialAddQuestionModalState(),
      isAddModalOpen: false,
    };
  },
  [PAGE_ACTIONS.USER_SAVES_QUESTION_GROUP]: (state) => {
    return {
      ...state,
      processing: true,
    };
  },
  [PAGE_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      processing: false,
      form: null,
      isAddModalOpen: false,
      addQuestionOptions: getInitialAddQuestionModalState(),
    };
  },
};

const QUESTION_GROUP_REDUCER_CONFIG = {
  [QUESTION_GROUP_FORM_ACTIONS.USER_CHANGES_GROUP_INPUT]: (state, action) => {
    const { key, value } = action.payload;
    return {
      ...state,
      form: {
        ...state.form,
        [key]: {
          value: value,
          errors: [],
        },
        isDirty: true,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_SETS_GROUPED_SELECT]: (state, action) => {
    const { key, value, changeData } = action.payload;
    const oldValue = cloneDeep(state.form[key]?.value);

    let resultValue;
    if (changeData.action === 'remove-value') {
      resultValue = (oldValue || []).filter(
        (v) =>
          v.groupValue !== changeData.removedValue.groupValue ||
          v.value !== changeData.removedValue.value,
      );
    } else {
      const valueAdded = value?.[value.length - 1];
      const isSelectAll = valueAdded?.value === null;
      // picking the any option, should clear all options from the same group
      // picking a specific option, should clear the any option from the same group
      resultValue = isSelectAll
        ? (value || []).filter(
          (v) => v.groupValue !== valueAdded?.groupValue || v.value === null,
        )
        : (value || []).filter(
          (v) => v.groupValue !== valueAdded?.groupValue || v.value !== null,
        );
    }
    return {
      ...state,
      form: {
        ...state.form,
        [key]: {
          value: resultValue,
          errors: [],
        },
        isDirty: true,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT]: (
    state,
    action,
  ) => {
    const { order, key, value } = action.payload;
    const newQuestions = cloneDeep(state.form.questions);
    const qIdx = newQuestions.findIndex((q) => q.order === order);
    newQuestions[qIdx] = {
      ...newQuestions[qIdx],
      [key]: {
        value: value,
        errors: [],
      },
    };

    return {
      ...state,
      form: {
        ...state.form,
        questions: newQuestions,
        isDirty: true,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_MOVES_QUESTION]: (state, action) => {
    const { qOrder, dir } = action.payload;
    return {
      ...state,
      form: {
        ...swapGroupQuestionTemplateOrders(state.form, qOrder, dir),
        isDirty: true,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_REMOVES_QUESTION]: (state, action) => {
    const newQuestions = cloneDeep(state.form.questions);
    const qIndex = newQuestions.findIndex(
      (q) => q.questionTemplateId === action.payload,
    );
    const qOrder = newQuestions[qIndex].order;

    newQuestions.splice(qIndex, 1);
    newQuestions.forEach((q) => {
      const dependencyQuestion = q.dependencyQuestion.value?.value;

      if (q.order > qOrder) q.order = q.order - 1;
      if (dependencyQuestion > qOrder) {
        const newOrder = dependencyQuestion - 1;

        q.dependencyQuestion.value = {
          label: `#${newOrder + 1}`,
          value: newOrder,
        };
      }
    });

    return {
      ...state,
      form: {
        ...state.form,
        questions: newQuestions,
        isDirty: true,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_TOGGLES_QUESTION]: (state, action) => {
    const newSelected = cloneDeep(state.addQuestionOptions.selected);

    const idx = newSelected.findIndex(
      (sel) => sel.questionTemplateId === action.payload.questionTemplateId,
    );
    if (idx !== -1) {
      newSelected.splice(idx, 1);
    } else {
      newSelected.push(action.payload);
    }

    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        selected: newSelected,
      },
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.USER_ASSIGNS_NEW_QUESTIONS]: (state) => {
    let index = 0;
    const data = state.addQuestionOptions.selected.map((order) => ({
      ...order,
      order: state.form.questions.length + index++,
    }));

    return {
      ...state,
      form: {
        ...state.form,
        questions: [...state.form.questions, ...data],
        isDirty: true,
      },
      addQuestionOptions: getInitialAddQuestionModalState(),
    };
  },
  [QUESTION_GROUP_FORM_ACTIONS.SET_FORM_ERRORS]: (state, action) => {
    return {
      ...state,
      form: {
        ...state.form,
        ...action.payload,
      },
      processing: false,
    };
  },
};

const QUESTION_MODAL_REDUCER_CONFIG = {
  [QUESTION_MODAL_ACTIONS.USER_OPENS_QUESTION_MODAL]: (state) => {
    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        isModalOpen: true,
      },
    };
  },
  [QUESTION_MODAL_ACTIONS.USER_CANCELS_QUESTION_MODAL]: (state) => {
    return {
      ...state,
      addQuestionOptions: getInitialAddQuestionModalState(),
    };
  },
  [QUESTION_MODAL_ACTIONS.USER_SEARCHES_QUESTION_MODAL]: (state, action) => {
    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        search: action.payload,
        page: 1,
      },
    };
  },
  [QUESTION_MODAL_ACTIONS.USER_SORTS_QUESTION_MODAL]: (state, action) => {
    const { col, order } = action.payload;
    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        sortBy: col,
        sortOrder: order,
      },
    };
  },
  [QUESTION_MODAL_ACTIONS.APP_LOADS_QUESTION_TEMPLATES]: (state, action) => {
    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        ...action.payload,
      },
    };
  },
  [QUESTION_MODAL_ACTIONS.USER_SETS_QUESTION_MODAL_PAGE]: (state, action) => {
    return {
      ...state,
      addQuestionOptions: {
        ...state.addQuestionOptions,
        page: action.payload,
      },
    };
  },
};

const REDUCER_CONFIG = {
  ...PAGE_REDUCER_CONFIG,
  ...QUESTION_GROUP_REDUCER_CONFIG,
  ...QUESTION_MODAL_REDUCER_CONFIG,
};

export const { reducer, initialState } = makeListReducer(
  REDUCER_CONFIG,
  initialPageState,
);
