import USER_STATUS, { getUserStatusLabel } from 'config/userStatus';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';

const USER_STATUS_TO_PILL_VARIANT = {
  [USER_STATUS.DRAFT]: STATUS_PILL_VARIANT.WARNING,
  [USER_STATUS.ACTIVE]: STATUS_PILL_VARIANT.SUCCESS,
  [USER_STATUS.DEACTIVATED]: STATUS_PILL_VARIANT.ERROR,
};

const UserStatusPill = ({ status = USER_STATUS.DRAFT }) => {
  const variant = USER_STATUS_TO_PILL_VARIANT[status];

  return (
    <StatusPill variant={variant}>{getUserStatusLabel(status)}</StatusPill>
  );
};

export default UserStatusPill;
