import React from 'react';
import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';

import './SettingsAssetStatusesForm.scss';

export default function SettingsAssetStatusesForm(props) {
  return (
    <form className='settings-asset-statuses-form'>
      <div>
        <Input
          label='Status name'
          type='text'
          value={props.assetStatus.name.value}
          errors={props.assetStatus.name.errors}
          autoComplete='off'
          onChange={props.onChange}
          data-testid='AssetStatusesForm.statusLabel'
        />
      </div>
      <div className='original-status'>
        <p>Original Status</p>
        <p className='status-value'>{props.originalStatus}</p>
      </div>
      <div className='form-buttons'>
        <Button
          type='button'
          data-testid='AssetStatusesForm.save'
          className='medium-button'
          onClick={props.editAssetStatus}
        >
          Save
        </Button>
        <Button
          onClick={props.clearEdit}
          className='link-button cancel-button'
          type='button'
          data-testid='AssetStatusesForm.cancel'
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
