import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportQuestionGroupsPage.scss';

const questionColumnLabels = {
  'questions.0.defects': 'Defects',
  'questions.0.printOnReport': 'Print on report',
  'questions.0.aqlLevel': 'AQL Level',
  'questions.0.name': 'Question name',
  'questions.0.photoRequired': 'Photo required',
  'questions.0.answerType': 'Answer type',
  'questions.0.type': 'Question type',
  'questions.0.dynamicAction': 'Dynamic action',
  'questions.0.dynamicCriteria': 'Dynamic criteria',
  'questions.0.questionWeight': 'Question weight',
  'questions.0.answer': 'Answer',
  'questions.0.dynamicRule': 'Dynamic rule',
  'questions.0.sampleQty': 'Sample size quantity',
  'questions.0.sampleRule': 'Sample size rule',
  'questions.0.lowerToleranceRule': 'Lower tolerance rule',
  'questions.0.lowerToleranceValue': 'Lower tolerance value',
  'questions.0.lowerToleranceWeight': 'Lower tolerance weight',
  'questions.0.upperToleranceValue': 'Upper tolerance rule',
  'questions.0.upperToleranceRule': 'Upper tolerance value',
  'questions.0.upperToleranceWeight': 'Upper tolerance weight',
  'questions.0.expectedMeasureTableResult': 'Expected measure table result',
  'questions.0.customExpectedUom': 'Custom expected UOM',
  customExpectedUom: 'Custom expected UOM',
  'questions.0.customExpectedMeasureValue': 'Custom expected measure value',
  'questions.0.expectedBarcodeUOM': 'Expected UOM',
  order: 'Group order',
  inspectionTypes: 'Inspection types',
  assetTypes: 'Asset types',
  'question.order': 'Question order',
};

// TODO: Remove declarations that are covered by __default__
const questionGroupErrorsValidations = {
  __prefix__: (options) => {
    const match = options.field.match(/questions.([0-9]+)/);
    return match ? `Question ${+match[1] + 1} - ` : '';
  },
  name: {
    'questionGroup.unique': () => 'Question group name should be unique',
  },
  order: {
    'number.positive': () => 'Group order should be a positive number',
    'number.base': () => 'Group order should be a number',
    'number.integer': () => 'Group order should be an integer',
    '0.inconsistent': () => 'Group order is inconsistent',
  },
  defects: {
    'defects.invalid': () => 'Defect does not exist',
    'defects.defectWeight': () => 'Defect weight is required',
  },
  answer: {
    'answer.answerMultipleChoice.missing': () =>
      'Answer name is required when answer type is multiple choice or barcode input',
    'answer.invalid': () => 'Answer is invalid',
    'customExpectedUom.invalid': () => 'Custom expected UOM is invalid',
  },
  customExpectedUom: {
    'customExpectedUom.required': () => 'Custom expected UOM is required',
    'customExpectedMeasureValue.required': () =>
      'Custom expected measure value is required',
  },
  'questionGroups.0.questions': {
    'questions.brokenDependency': () =>
      'Question group contains invalid dependency',
    required: () => 'Question group order is required',
  },
  'questions.0': {
    'array.unique': () => 'Every question name should be unique',
  },
  'questions.0.name': {
    'any.required': () => 'Question name is required',
  },
  'questions.0.dependencyAction': {
    'any.only': () => 'Dependency action invalid value',
    'any.required': () => 'Dependency action is required field',
  },
  'questions.0.dependencyCriteria': {
    'any.only': () => 'Dependency criteria invalid value',
    'any.required': () => 'Dependency criteria is required field',
  },
  'questions.0.type': {
    'any.required': () => 'Question type is required field',
    'any.only': () => 'Question type invalid value',
  },
  'questions.0.defects.0.weight': {
    'any.only': () => 'Defects weight invalid value',
  },
  'questions.0.printOnReport': {
    'any.only': () => 'Print on report invalid value',
    'any.required': () => 'Print on report is required',
  },
  'questions.0.aqlLevel': {
    'any.only': () => 'AQL Level invalid value',
  },
  'questions.0.questionWeight': {
    'any.only': () => 'Invalid question weight value',
    'any.required': () => 'Question weight is required',
  },
  'questions.0.answer': {
    'answer.invalid': () =>
      'Answer must be valid when multiple choice is selected',
  },
  'questions.0.answerType': {
    'any.only': () => 'Answer type invalid value',
    'any.required': () => 'Answer type is required',
  },
  'questions.0.dynamicAction': {
    'any.only': () => 'Dynamic action invalid value',
  },
  'questions.0.photoRequired': {
    'any.required': () => 'Photo required field is mandatory',
    'any.only': () => 'Invalid value for photo required field',
  },
  'questions.0.dynamicCriteria': {
    'number.integer': () => 'Dynamic criteria must be an integer',
    'number.greater': () =>
      'Dynamic criteria must be an integer greater than zero',
    'number.base': () => 'Dynamic criteria must be a number',
    'any.required': () => 'Dynamic criteria required field',
  },
  'questions.0.dynamicRule': {
    'any.only': () => 'Dynamic rule invalid value',
    'any.required': () => 'Dynamic rule required field',
  },
  'questions.0.sampleRule': {
    'number.max': () => 'Sample size value exceeded',
    'any.only': () => 'Sample size rule invalid value',
    'any.required': () => 'Sample size rule is required',
  },
  'questions.0.lowerToleranceRule': {
    'any.required': () => 'Lower tolerance rule is required',
    'any.only': () => 'Lower tolerance rule invalid value',
  },
  'questions.0.lowerToleranceValue': {
    'number.base': () => 'Lower tolerance must be a number',
    'number.max': () => 'Lower tolerance exceeded maximum',
    'number.min': () => 'Lower tolerance must not be less than zero',
  },
  'questions.0.lowerToleranceWeight': {
    'any.only': () => 'Lower tolerance weight invalid value',
    'any.required': () => 'Lower tolerance weight is required',
  },
  'questions.0.upperToleranceWeight': {
    'any.only': () => 'Upper tolerance weight invalid value',
    'any.required': () => 'Upper tolerance weight is required',
  },
  'questions.0.upperToleranceRule': {
    'any.only': () => 'Upper tolerance rule invalid value',
    'any.required': () => 'Upper tolerance rule is required',
  },
  'questions.0.upperToleranceValue': {
    'number.min': () => 'Upper tolerance value must not be less than zero',
    'number.max': () => 'Upper tolerance value exceeded maximum',
    'any.only': () => 'Upper tolerance value invalid value',
    'number.base': () => 'Upper tolerance should be a number',
  },
  'questions.0.expectedMeasureTableResult': {
    'any.only': () => 'Expected measure table result invalid value',
  },
  'questions.0.sampleQty': {
    'number.min': () => 'Sample size quantity should not be negative',
    'number.integer': () => 'Sample size quantity should be an integer',
    'number.base': () => 'Sample size quantity should be a number',
    'number.max': () => 'Sample quantity should not be greater than 100%',
    'any.required': () => 'Sample quantity is required',
  },
  'questions.0.customExpectedMeasureValue': {
    'number.min': () =>
      "Custom expected measure value shouldn't be negative number",
    'number.base': () => 'Custom expected measure value must be a number',
  },
  'questions.0.tools.0.name': {
    'string.pattern.base': () =>
      'Tool name should contain alphanumeric characters',
  },
  'questions.0.expectedBarcodeUOM': {
    'any.required': () =>
      'Expected UOM is required for barcode input questions',
  },
  'question.dependsOnOrder': {
    'dependency.invalid': () => 'Invalid dependency',
    'number.base': () => 'Depends On must be a number',
  },
  'question.dependencyAction': {
    'any.only': () => 'Dependency Action has an invalid value',
  },
  'question.dependencyCriteria': {
    'any.only': () => 'Dependency Criteria has an invalid value',
  },
  'question.dependencyAnswerOptionOrder': {
    'number.base': () => 'Dependency Answer Option Order must be a number',
  },

  dependencyAnswerOptionOrder: {
    'dependency.unknownAnswerOption': () =>
      'Dependency Answer Option Order has an invalid value',
  },
  dependencyAnswerOptionTranslation: {
    'dependency.unknownAnswerOption': () =>
      'Dependency Answer Option Translation has an invalid value',
    'dependency.ambiguousTranslation': () =>
      'Dependency Answer Option Translation is ambiguous',
  },
  questionId: {
    'questionId.notFound': () => 'Question not found',
  },
  questionDependsOnOrder: {
    'dependency.notDefined': () => 'Question dependency was not defined',
  },
  questionOrder: {
    'question.brokenOrder': () => 'Question order is invalid',
    'question.duplicatedOrder': () => 'Question order is duplicated',
  },
  inspectionTypes: {
    'inspectionTypes.notFound': () => 'Inspection type not found',
  },
  assetTypes: {
    'assetTypes.notFound': () => 'Asset type not found',
  },
};

export default function ImportQuestionGroupsPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-question-groups-page'>
      <h4>Import question groups</h4>
      <p className='form-info'>
        Before importing question groups, please make sure you structure your
        data like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/sample-question-groups.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='question-groups' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={questionGroupErrorsValidations}
        columnLabels={questionColumnLabels}
      />
    </div>
  );
}
