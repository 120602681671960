const WORK_OBJECT_ACTIVITIES = {
  WORK_OBJECT_CREATED: 'work_object_created',
  WORK_OBJECT_IMPORTED: 'work_object_imported',
  PARTIAL_WORK_OBJECT_ACCEPTED: 'partial_work_object_accepted',
  PARTIAL_WORK_OBJECT_SUBMITTED: 'partial_work_object_submitted',
  PARTIAL_WORK_OBJECT_REJECTED: 'partial_work_object_rejected',
  PARTIAL_WORK_OBJECT_DEADLINE_UPDATED: 'partial_work_object_deadline_updated',
  PARTIAL_WORK_OBJECT_READY_FOR_INSPECTION_CHANGE: 'partial_work_object_ready',
  PARTIAL_WORK_OBJECT_WINDOW_UPDATED: 'partial_window_changed',
  PARTIAL_WORK_OBJECT_INSPECTION_PUBLISHED: 'partial_published',
  PARTIAL_WORK_OBJECT_INSPECTION_DATE_CHANGED:
    'partial_inspection_date_changed',
};

export default WORK_OBJECT_ACTIVITIES;
