import Button from 'lib/components/button/Button';
import Modal from 'lib/components/modal/Modal';
import Select from 'lib/components/select/Select';
import './ReInspectionModal.scss';

const ReInspectionModal = (props) => {
  return (
    <Modal
      title='Re-inspect'
      actions={
        <Button onClick={props.onSubmit} data-testid='ReInspectModal.submit'>
          Ok
        </Button>
      }
      className='re-inspection-modal'
      isOpen={props.isOpen}
      onRequestClose={props.onCancel}
    >
      <form noValidate className='re-inspect-form'>
        <p>Select one of the options to perform the re-inspection</p>
        <Select
          name='aql'
          isSearchable={false}
          options={props.options}
          onChange={props.onChange}
          placeholder='Select'
          label='AQL'
          value={props.data.value}
          errors={props.data.errors}
          data-testid='ReInspectModal.aql'
        />
      </form>
    </Modal>
  );
};

export default ReInspectionModal;
