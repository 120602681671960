import * as yup from 'yup';
import _set from 'lodash.set';
import { makeTranslatableSchema, validator } from 'lib/validator';

export const processDefectsAPIError = (errorDetails, defect) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'Defect name needs to be unique';
          default:
            return error;
        }
      });

    return _set(acc, 'name.errors', values);
  }, defect);
};

export const settingsDefectsFormValidationSchema = yup.object().shape({
  name: makeTranslatableSchema(),
});

const settingsDefectsFormValidator = (state) =>
  validator(state, settingsDefectsFormValidationSchema);

export default settingsDefectsFormValidator;
