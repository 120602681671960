import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Check } from 'assets/images/check.svg';
import './Checkbox.scss';
const Checkbox = React.forwardRef(
  (
    {
      checked,
      indeterminate,
      disabled = false,
      onChange,
      className,
      label = null,
      ...props
    },
    ref,
  ) => {
    return (
      <label
        className={classNames('qm-checkbox', className, {
          indeterminate,
          disabled,
          checked,
        })}
      >
        <span className='checkbox-input'>
          <input
            ref={ref}
            type='checkbox'
            data-testid='checkbox'
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            {...props}
          />
          <span className='checkbox-control'>
            {indeterminate ? '–' : <Check />}
          </span>
        </span>
        {label && <span className='checkbox-label'>{label}</span>}
      </label>
    );
  },
);

export default Checkbox;
