import InspectionThirdPartyForm from '../inspection-third-party-form/InspectionThirdPartyForm';
import './InspectionsAddExternalPage.scss';

const InspectionsAddExternalPage = (props) => {
  return (
    <div className='inspections-add-third-party-page container-m'>
      <div className='row'>
        <h2>New third party inspection</h2>
      </div>
      <div className='row'>
        <InspectionThirdPartyForm {...props} />
      </div>
    </div>
  );
};

export default InspectionsAddExternalPage;
