import Button from 'lib/components/button/Button';
import LanguageSelect from 'lib/components/language-select/LanguageSelect';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import { VIEW_SETTINGS_ROLES } from 'modules/settings/settingsPermissions';
import useSettingsDefectsList from '../../useSettingsDefectsList';
import SettingsDefectsTable from '../settings-defects-table/SettingsDefectsTable';
import SettingsDefectsInputModal from '../settings-defects-input-modal/SettingsDefectsInputModal';

import './SettingsDefectsListPage.scss';

export default function SettingsDefectListPage(props) {
  const { ...list } = useSettingsDefectsList(props);

  return (
    <div className='settings-defects-list-page'>
      <div className='settings-header'>
        <h4>Defect list</h4>
        <Button
          data-testid='SettingsDefectListPage.importDefects'
          className='medium-button'
          onClick={() => props.history.push('/settings/defects/import')}
        >
          Import
        </Button>
        <Button
          data-testid='SettingsDefectListPage.exportDefects'
          className='medium-button'
          disabled={list.state.isExportingDefects}
          isLoading={list.state.isExportingDefects}
          onClick={list.exportDefects}
        >
          Export
        </Button>
      </div>
      <p className='form-info'>
        The list of defects that can be selected for each question are displayed
        here.
      </p>
      <div className='row difect-filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='SettingsDefectListPage.search'
          />
        </div>
        <ProtectedComponent allowed={VIEW_SETTINGS_ROLES}>
          <Button
            data-testid='SettingsDefectListPage.addNewDefect'
            className='medium-button'
            onClick={list.userOpensInputModal}
          >
            Add new defect
          </Button>
        </ProtectedComponent>
      </div>
      <LanguageSelect
        onChange={list.userSetsLanguage}
        language={list.state.language}
      />
      <div className='settings-defect-table'>
        <SettingsDefectsTable
          editField={list.state.editField}
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          sortBy={list.state.sortBy}
          sortOrder={list.state.sortOrder}
          setSortBy={list.sortBy}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setEdit={list.userSelectsEditDefect}
          clearEdit={list.userClearsEditDefect}
          onChange={list.userTypesDefectName}
          editDefect={list.editDefect}
          language={list.state.language}
          languageList={list.state.languages}
        />
      </div>
      <SettingsDefectsInputModal
        data={list.state.addDefect}
        isOpen={list.state.addDefect.isModalOpen}
        onChange={list.userTypesNewDefect}
        onRequestClose={list.userCancelsInputModal}
        onSubmit={list.userSubmitsDefect}
        addDefect={list.addDefect}
        language={list.state.language}
        onLanguageChange={list.userSetsLanguage}
      />
    </div>
  );
}
