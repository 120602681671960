import makeReducer from 'lib/makeReducer';

export const INSPECTION_PLANS_BULK_RESULTS_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  SET_ERRORS: 'SET_ERRORS',
  USER_FILTERS_ASSET: 'USER_FILTERS_ASSET',
  USER_FILTERS_SOURCE: 'USER_FILTERS_SOURCE',
};

export const RESULT_STATUS = {
  /** Plan created successfully */
  SUCCESS: 'success',
  /** Server or other unexpected error */
  ERROR: 'error',
  /** Plan was not created due to no matching tags */
  NO_TAGS: 'no_tags',
  /** Plan for this type already exists */
  AUTO_PLAN: 'plan_exists',
  /** User has manually edited the existing plan */
  USER_PLAN: 'user_plan_exists',
  /** Wrong inspection type for selected target */
  WRONG_TYPE: 'wrong_type',
  /** Existing plan is in needs_review status */
  NEEDS_REVIEW: 'plan_needs_review',
  /** @deprecated Plan failed to be created */
  FAILED: 'failed',
  /** @deprecated Plan skipped due to no matching tags */
  SKIPPED: 'skipped',
  /** Fallback result */
  UNKNOWN: 'unknown',
};

const INITIAL_STATE = {
  assets: [],
  sources: [],
  errors: [],
  loading: true,
  assetFilter: 'all',
  sourceFilter: 'all',
  sourceTotal: {
    [RESULT_STATUS.SUCCESS]: 0,
    [RESULT_STATUS.FAILED]: 0,
    [RESULT_STATUS.SKIPPED]: 0,
    [RESULT_STATUS.NO_TAGS]: 0,
    [RESULT_STATUS.AUTO_PLAN]: 0,
    [RESULT_STATUS.USER_PLAN]: 0,
    [RESULT_STATUS.WRONG_TYPE]: 0,
    [RESULT_STATUS.NEEDS_REVIEW]: 0,
  },
  assetTotal: {
    [RESULT_STATUS.SUCCESS]: 0,
    [RESULT_STATUS.FAILED]: 0,
    [RESULT_STATUS.SKIPPED]: 0,
    [RESULT_STATUS.NO_TAGS]: 0,
    [RESULT_STATUS.AUTO_PLAN]: 0,
    [RESULT_STATUS.USER_PLAN]: 0,
    [RESULT_STATUS.WRONG_TYPE]: 0,
    [RESULT_STATUS.NEEDS_REVIEW]: 0,
  },
};

export const RESULT_FILTER = {
  ALL: 'all',
  SUCCESS: RESULT_STATUS.SUCCESS,
  /** @deprecated */
  FAILED: RESULT_STATUS.FAILED,
  /** @deprecated */
  SKIPPED: RESULT_STATUS.SKIPPED,
  NO_TAGS: RESULT_STATUS.NO_TAGS,
  AUTO_PLAN: RESULT_STATUS.AUTO_PLAN,
  USER_PLAN: RESULT_STATUS.USER_PLAN,
  WRONG_TYPE: RESULT_STATUS.WRONG_TYPE,
  NEEDS_REVIEW: RESULT_STATUS.NEEDS_REVIEW,
};

const INSPECTION_PLANS_BULK_RESULTS_REDUCER_CONFIG = {
  [INSPECTION_PLANS_BULK_RESULTS_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
  [INSPECTION_PLANS_BULK_RESULTS_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      assets: [],
      sources: [],
      loading: false,
      errors: action.payload,
    };
  },
  [INSPECTION_PLANS_BULK_RESULTS_ACTIONS.USER_FILTERS_ASSET]: (
    state,
    action,
  ) => {
    return {
      ...state,
      assetFilter: action.payload,
    };
  },
  [INSPECTION_PLANS_BULK_RESULTS_ACTIONS.USER_FILTERS_SOURCE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      sourceFilter: action.payload,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_PLANS_BULK_RESULTS_REDUCER_CONFIG,
  INITIAL_STATE,
);
