import classNames from 'classnames';
import Button from '../button/Button';
import './IconButton.scss';

const IconButton = ({
  children,
  className,
  prefix = null,
  suffix = null,
  ...props
}) => {
  return (
    <Button className={classNames('icon-button', className)} {...props}>
      {prefix && <div className='icon'>{prefix}</div>}
      <div className='content'>{children}</div>
      {suffix && <div className='icon'>{suffix}</div>}
    </Button>
  );
};

export default IconButton;
