import * as yup from 'yup';
import _set from 'lodash.set';

import validator, { inputShape, yupNumber } from 'lib/validator';
import { MAX_FIELD_CHARS } from './workflowsFormReducer';
import { ERROR_TEMPLATES } from 'config/errorCodes';
import { parseTemplate } from 'lib/errorHelpers';

const ERRORS = {
  ...ERROR_TEMPLATES,
  positive: 'Value must be 1 or above',
  integer: 'Value must be an integer',
  maxPercent: parseTemplate(ERROR_TEMPLATES.exceeded, { max: '100%' }),
};

export const workflowsFormValidationSchema = yup
  .object()
  .shape({
    name: yup.object().shape({
      value: yup
        .string()
        .required('Name is a required field')
        .max(MAX_FIELD_CHARS.name, 'Name is too long'),
    }),
    description: yup.object().shape({
      value: yup
        .string()
        .required('Description is a required field')
        .max(MAX_FIELD_CHARS.description, 'Description is too long'),
    }),
    workflowSteps: yup.array().of(
      yup
        .object()
        .shape({
          source: yup.object().shape({
            value: yup.object().shape({
              value: yup.string(),
            }),
          }),
          inspectionPairs: yup.array().of(
            yup.object().shape({
              isRequired: yup.object().shape({
                value: yup.boolean(),
              }),
              inspectionType: yup.object().shape({
                value: yup.object().shape({
                  value: yup
                    .string()
                    .required('Inspection type is a required field'),
                }),
              }),
              inspectionPlan: yup.object().shape({
                value: yup.object().shape({
                  value: yup.string().nullable(),
                }),
              }),
              customInspectionQty: yup.object().when(['customInspectionQtyIsRelative'], {
                is: (isRelative) => !!isRelative.value,
                then: inputShape(
                  yupNumber
                    .nullable()
                    .integer(ERRORS.integer)
                    .min(1, ERRORS.positive)
                    .max(100, ERRORS.maxPercent),
                ),
                otherwise: inputShape(
                  yupNumber
                    .nullable()
                    .integer(ERRORS.integer)
                    .min(1, ERRORS.positive)
                ),
              })
            }),
          ),
        })
        .test('requiredLocation', (value, context) => {
          const arr = context.parent;
          const idx = arr.indexOf(value);

          if (idx === 0 || idx === arr.length - 1) {
            return true;
          }

          if (!value.source.value.value) {
            return context.createError({
              path: `${context.path}.source.value`,
              message: 'Inspection source is a required field.',
            });
          }
          return true;
        }),
    ),
  })
  .test('minInspections', (value, context) => {
    if (!value.workflowSteps.map((s) => s.inspectionPairs).flat().length) {
      return context.createError({
        message: 'At least one inspection is required',
      });
    }
    return true;
  });

export const processWorkflowsFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const arrayPath = curr.path.split('.');
    let path;

    if (curr.path.includes('inspectionPairs')) {
      path = `${arrayPath[0]}.${arrayPath[1]}.${arrayPath[2]}`;
    } else if (curr.path.includes('workflowSteps')) {
      path = `${arrayPath[0]}.${arrayPath[1]}`;
    } else if (curr.path === '') {
      return {
        ...acc,
        errors: curr.errors,
      };
    } else {
      path = arrayPath[0];
    }

    let defaultKey = 'value';
    let defaultValue = '';
    let defaultLabel = 'label';

    if (
      curr.path.includes(['workflowSteps'])) {
      if (curr.path.includes(['inspectionType']) || curr.path.includes(['inspectionPlan'])) {

        defaultKey = 'value.value';
        defaultLabel = 'value.label';
      } else if (curr.path.includes('isRequired') || curr.path.includes('isScheduled')) {
        defaultValue = false;
      }
    }

    _set(acc, path, {
      [defaultKey]: curr.params.originalValue || defaultValue,
      [defaultLabel]: '',
      errors: curr.errors,
    });

    return acc;
  }, input);
};

export const processAPIError = (errorDetails, workflow) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, workflow);
};

const workflowsFormValidator = (state) =>
  validator(state, workflowsFormValidationSchema, processWorkflowsFormError);

export default workflowsFormValidator;
