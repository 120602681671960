import { entityToSelectOption } from 'lib/dataTransform';

export const breadcrumbsTranformData = (name, id) => {
  return [
    {
      link: '/assets',
      name: 'Assets',
    },
    {
      link: `/assets/${id}`,
      name,
    },
  ];
};

export const refactorMeasureTableData = (table) => {
  const { columnValues = {}, columnNames = [] } = table || {};
  const dataTable = [];

  // Grab row count from first column
  const rowCount = Object.values(columnValues)[0]?.length || 0;

  for (let i = 0; i < rowCount; i++) {
    const rowData = [];
    for (let j = 0; j < columnNames.length; j++) {
      rowData.push(columnValues[columnNames[j]][i]);
    }
    dataTable.push(rowData);
  }

  return {
    columnNames,
    dataTable,
  };
};

export function companyAttributesToFormState(list) {
  return list.map((attr) => ({
    id: attr.id,
    label: attr.name,
    options: attr.options.map(entityToSelectOption('name', 'id')),
  }));
}

export const transformDataToPOSTParams = (state) => {
  const owners = state.owners.list.map((owner) => ({
    id: owner.id,
  }));

  return {
    name: state.name.value,
    externalId: state.externalId.value || null,
    shortDescription: state.description.value,
    companyFields: Object.keys(state.companyAttributes)
      ?.filter((key) => !!state.companyAttributes[key])
      .map((key) => ({
        attributeId: key,
        optionId: state.companyAttributes[key].value,
      })),
    customFields: state.customFields?.list?.map((customField) => ({
      id: customField.id,
      name: customField.name?.value,
      value: customField.value?.value,
    })),
    measureTable: {
      columnNames: state.measureTable.value.columnNames ?? [],
      columnValues: state.measureTable.value.columnValues ?? {},
    },
    customMeasurements: state.measureTable.value.customMeasurements ?? [],
    type:
      state.type.value.value || state.type.value
        ? {
            id: state.type.value.value
              ? state.type.value.value
              : state.type.value,
          }
        : undefined,
    status: {
      id: state.status.value.value
        ? state.status.value.value
        : state.status.value,
    },
    owners,
    images: state.images.values.filter((img) => img.id),
    referenceDocuments: state.referenceDocuments.list
      .filter((doc) => doc.file.id)
      .map((doc) => ({
        ...doc.file,
        documentName: doc.value,
        name: doc.value,
      })),
  };
};

export const assetsToFormState = (state, results) => {
  return {
    id: results.id,
    name: {
      value: results.name || '',
      errors: [],
      charsLeft: 250 - (results.name || '').length,
    },
    externalId: {
      value: results.externalId || '',
      errors: [],
      charsLeft: 50 - (results.externalId || '').length,
    },
    description: {
      value: results.shortDescription || '',
      errors: [],
      charsLeft: 250 - (results.shortDescription || '').length,
    },
    companyAttributes: results.companyFields.reduce(
      (acc, val) => ({
        ...acc,
        [val.attributeId]: state.availableCompanyAttributes
          .find((attr) => attr.id === val.attributeId)
          ?.options.find((opt) => opt.value === val.optionId),
      }),
      {},
    ),
    customFields: {
      list: results.customFields?.map((customField) => ({
        id: customField.id,
        name: {
          value: customField.name,
          errors: [],
        },
        value: {
          value: customField.value,
          errors: [],
        },
      })),
      errors: [],
    },
    measureTable: {
      value: {
        columnNames: results.measureTable?.columnNames || [],
        columnValues: results.measureTable?.columnValues || {},
        customMeasurements: results.customMeasurements || [],
      },
      errors: [],
    },
    initialImages: results.images,
    images: {
      values: results.images,
      errors: [],
    },
    type: {
      value: results.type.id,
      errors: [],
    },
    status: {
      value: results.status.id,
      errors: [],
    },
    owners: {
      list: results.owners,
      errors: [],
    },
    initialOwners: results.owners,
    referenceDocuments: {
      list: results.referenceDocuments.map((document) => ({
        file: document,
        value: document?.documentName || document?.name,
        errors: [],
      })),
      errors: [],
    },
  };
};
