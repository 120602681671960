import Modal from 'lib/components/modal/Modal';
import SettingsTypesForm from 'modules/settings/components/settings-types-form/SettingsTypesForm';
import './SettingsAssetTypesInputModal.scss';

const SettingsAssetTypesInputModal = ({
  isOpen,
  addType,
  onRequestClose,
  onChange,
  onSubmit,
  ...props
}) => {
  return (
    <Modal
      title='Add asset type'
      className='settings-asset-types-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      data-testid='SettingsAssetTypesInputModal.modal'
      {...props}
    >
      <SettingsTypesForm
        addType={addType}
        onChange={onChange}
        onSubmit={onSubmit}
        cancelForm={onRequestClose}
      />
    </Modal>
  );
};

export default SettingsAssetTypesInputModal;
