import * as yup from 'yup';
import _get from 'lodash.get';
import { validator } from 'lib/validator';
import { makeAPIBodyErrorProcessor, parseTemplate } from 'lib/errorHelpers';
import { API_ERROR_CODES, ERROR_TEMPLATES } from 'config/errorCodes';

const ERRORS = {
  ...ERROR_TEMPLATES,
  nameLength: parseTemplate(ERROR_TEMPLATES.exceeded, {
    field: 'Name',
    max: '250 characters',
  }),
  documentNameLength: parseTemplate(ERROR_TEMPLATES.exceeded, {
    field: 'Document name',
    max: '40 characters',
  }),
  fileSize: 'Maximum allowed size for documents is 50MB',
  descriptionLength: parseTemplate(ERROR_TEMPLATES.exceeded, {
    description: 'Description',
    max: '255 characters',
  }),
};

yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (item) => _get(item, path)?.toLowerCase();
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }
    const section = path === 'value' ? 'referenceDocuments' : 'customFields';
    const fieldIndex = list.findIndex((l, i) => mapper(l) !== set[i]);

    return this.createError({
      path: `${section}.list[${fieldIndex}].${path}`,
      message,
      params: {
        originalValue: _get(list[fieldIndex], path),
      },
    });
  });
});

const assetsFormValidationSchema = yup.object().shape({
  name: yup.object().shape({
    value: yup
      .string()
      .max(250, ERRORS.nameLength)
      .required('Name is a required field'),
  }),
  description: yup.object().shape({
    value: yup.string().max(255, ERRORS.descriptionLength),
  }),
  type: yup.object().shape({
    value: yup
      .mixed()
      .notOneOf([''], 'Asset type is a required field')
      .required('Asset type is a required field'),
  }),
  customFields: yup.object().shape({
    list: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.object().shape({
            value: yup.string().required('Name is a required field'),
          }),
          value: yup.object().shape({
            value: yup.string().required('Value is a required field'),
          }),
        }),
      )
      .unique('Custom field names have to to be unique', 'name.value'),
  }),
  referenceDocuments: yup.object().shape({
    list: yup
      .array()
      .of(
        yup.object().shape({
          value: yup
            .string()
            .max(40, ERRORS.documentNameLength)
            .matches(/^([a-zA-Z0-9 ]+)$/, ERRORS.alphanumWithSpaces)
            .required('Document name is a required field'),
          file: yup
            .mixed()
            .test('fileSize', ERRORS.fileSize, (file) => {
              return file?.url || file?.size <= 50 * 1024 * 1024;
            })
            .required(parseTemplate(ERRORS.required)),
        }),
      )
      .unique('Document names have to to be unique', 'value'),
  }),
});

const assetsErrors = {
  ...API_ERROR_CODES,
  'asset.unique': 'This value needs to be unique',
  'string.pattern.base': 'This value contains invalid characters.',
  'asset.owner_change_forbidden':
    'Users with the role Asset Owner cannot update owners',
  'asset.externalid_change_forbidden': 'ExternalID cannot be changed.',
};

const customFieldsRegex =
  /customFields\.(?<attributeName>.*)\.(?<errorField>value|name)/;
const apiPathToFormPath = (path, state) => {
  const customFieldsMatch = path.match(customFieldsRegex);
  if (customFieldsMatch) {
    const {
      groups: { errorField, attributeName },
    } = customFieldsMatch;
    const idx = state.customFields.list.findIndex(
      (field) => field.name.value === attributeName,
    );
    return `customFields.list[${idx}].${errorField}`;
  }
  return path;
};

export const processAssetsAPIError = makeAPIBodyErrorProcessor({
  errorCodes: assetsErrors,
  transformPath: apiPathToFormPath,
});

const assetsFormValidator = (state) =>
  validator(state, assetsFormValidationSchema);

export default assetsFormValidator;
