import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lib/funcHelpers';

import Toggle from '../toggle/Toggle';

import './Accordion.scss';

const Accordion = ({
  isOpen,
  children,
  onToggle = noop,
  title = null,
  subtitle = null,
  className = null,
  actions,
  hideToggle = false,
  ...props
}) => {
  return (
    <div
      data-testid='accordion'
      className={classNames('qm-accordion', className)}
      {...props}
    >
      <div className='accordion-header'>
        <div className='header-text'>
          {title && <div className='title'>{title}</div>}
          {subtitle && <div className='subtitle'>{subtitle}</div>}
        </div>
        {actions && <div className='header-actions'>{actions}</div>}
        {!hideToggle && (
          <Toggle direction={isOpen ? 'up' : 'down'} onClick={onToggle} />
        )}
      </div>
      {isOpen && <div className='accordion-body'>{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onToggle: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  actions: PropTypes.node,
  hideToggle: PropTypes.bool,
};

export default Accordion;
