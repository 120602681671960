import ROLES from 'config/roles';

/* BE filters assets returning assets associated to
 inspector, source owner and internal source */
export const VIEW_ALL_ROLES = [
  ROLES.ASSET_OWNER,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.INSPECTOR,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
];

export const BUILD_PLAN_ROLES = [
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
];

export const ADD_ASSET_ROLES = [
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_OWNER,
];

export const ASSIGN_PERMISSIONS = [...BUILD_PLAN_ROLES, ROLES.COORDINATOR];

export const VIEW_ASSOCIATED_WORK_OBJECTS = [
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_OWNER,
];

export const OVERRIDE_USAGE_DECISIONS = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
];
