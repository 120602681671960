import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Question from 'lib/components/question-detail/QuestionDetail';
import QuestionForm from 'lib/components/question-form/QuestionForm';

import Accordion from 'lib/components/accordion/Accordion';

import { ReactComponent as EditOutline } from 'assets/images/edit-outline.svg';

import './QuestionGroup.scss';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';

const QUESTION_COLUMNS = [
  {
    title: <EditOutline />,
    className: 'edit-cell',
    textAlign: 'center',
  },
  {
    title: '#',
    className: 'id-cell',
    textAlign: 'left',
  },
  {
    title: 'Question',
    className: 'question-cell',
    textAlign: 'left',
  },
  {
    title: 'Question Type',
    className: 'question-type-cell',
    textAlign: 'left',
  },
  {
    title: 'Dependency',
    className: 'dependency-cell',
    textAlign: 'left',
  },
  {
    title: 'Sample Size',
    className: 'sample-size-cell',
    textAlign: 'right',
  },
  {
    title: '',
    className: 'toggle-cell',
    textAlign: 'right',
  },
];

const QuestionGroup = ({
  data,
  children,
  className,
  editable = false,
  hasErrors = false,
  hideOrder = false,
  title,
  showHeaderDependency = false,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const questionCount = children?.length || 0;
  const questionCountLabel = questionCount === 1 ? 'question' : 'questions';

  return (
    <Accordion
      className={classNames(
        'qm-question-group',
        { editable, 'show-header-dependency': showHeaderDependency },
        className,
      )}
      title={title ?? data.name}
      isOpen={open || hasErrors}
      onToggle={() => setOpen(!open)}
      hideToggle={hasErrors}
      subtitle={
        <div className='question-group-details'>
          {!!data.order && !hideOrder && (
            <div className='question-group-order'>
              <span>Order:</span>
              <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
                {data.order}
              </StatusPill>
            </div>
          )}
          <div className='question-group-count'>
            {questionCount} {questionCountLabel}
          </div>
        </div>
      }
      data-testid='question-group'
      {...props}
    >
      <div className='questions-table'>
        <div className={classNames('table-row table-header')}>
          {QUESTION_COLUMNS.filter(
            (col) => !(!showHeaderDependency && col.title === 'Dependency'),
          ).map((col, idx) => (
            <div
              key={idx}
              className={classNames('table-cell', col.className, {
                [`align-${col.textAlign}`]: col.textAlign,
              })}
            >
              <span className="value">{col.title}</span>
            </div>
          ))}
        </div>
        {children}
      </div>
    </Accordion>
  );
};

QuestionGroup.Question = Question;
QuestionGroup.QuestionForm = QuestionForm;

QuestionGroup.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object),
};

export default QuestionGroup;
