import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { ADD_WORKFLOW_ROLES } from '../../workflowsPermissions';
import AddButton from 'lib/components/add-button/AddButton';
import SearchInput from 'lib/components/search-input/SearchInput';
import WorkflowsTable from '../workflows-table/WorkflowsTable';
import useWorkflowsList from 'modules/workflows/useWorkflowsList';
import './WorkflowsListPage.scss';

export default function WorkflowsListPage(props) {
  const list = useWorkflowsList(props);
  return (
    <div className='workflows-list-page'>
      <div className='row workflows-list-header container-m'>
        <h2>Workflows</h2>
        <ProtectedComponent allowed={ADD_WORKFLOW_ROLES}>
          <AddButton
            onClick={() => props.history.push('/workflows/add')}
            data-testid='WorkflowsListPage.add'
          >
            New workflow
          </AddButton>
        </ProtectedComponent>
      </div>
      <div className='row filter-container container-m'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='WorkflowsListPage.search'
          />
        </div>
      </div>
      <WorkflowsTable
        data={list.state.data}
        setPageSize={list.setPageSize}
        pageSize={list.state.pageSize}
        setPage={list.setPage}
        page={list.state.page}
        count={list.state.count}
        setSortBy={list.sortBy}
        sortOrder={list.state.sortOrder}
        sortBy={list.state.sortBy}
        userClicksOnRow={list.userClicksOnRow}
        data-testid='WorkflowsListPage.table'
      />
    </div>
  );
}
