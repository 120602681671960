import Select from 'lib/components/select/Select';
import { components } from 'react-select';
import UserStatusPill from '../user-status-pill/UserStatusPill';

import './UserStatusSelect.scss';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className='user-status-pill-option'>
        <UserStatusPill status={props.data.value} />
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className='user-status-pill-option'>
        <UserStatusPill status={props.value} />
      </div>
    </components.Option>
  );
};

const UserStatusSelect = (props) => {
  return <Select components={{ Option, SingleValue }} {...props} />;
};

export default UserStatusSelect;
