import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const DEFAULT_MESSAGE =
  'You have unsaved changes. Are you sure you want to leave?';

/**
 * Hook to show the user a browser confirmation prompt on navigation.
 * @param {boolean} when if true, prompt on navigation
 * @param {string} message optional custom message
 */
const useNavigationPrompt = (when, message = DEFAULT_MESSAGE) => {
  const history = useHistory();

  const onLeave = (ev) => {
    ev.preventDefault();
    if (when) {
      ev.returnValue = message;
      return message;
    }
  };

  useEffect(() => {
    if (when) {
      const unblock = history.block((transition) => {
        if (transition.noblock || window.confirm(message)) {
          return true;
        }
        return false;
      });
      window.addEventListener('beforeunload', onLeave);

      return () => {
        unblock();
        window.removeEventListener('beforeunload', onLeave);
      };
    }
  }, [when]);
};

export default useNavigationPrompt;
