import WorkObjectsForm from '../work-objects-form/WorkObjectsForm';
import './WorkObjectsAddPage.scss';

const WorkObjectsAddPage = (props) => {
  return (
    <div
      className='work-objects-add-page container-m'
      data-testid='WorkObjectsAddPage.container'
    >
      <div className='row'>
        <h1>New work object</h1>
      </div>
      <div className='row'>
        <WorkObjectsForm {...props} />
      </div>
    </div>
  );
};

export default WorkObjectsAddPage;
