import AUTH_EVENTS from 'events/auth-events';
import appBus from 'lib/appBus';
import { companyToAuthState } from 'lib/localStorageService';
import { useEffect, useReducer } from 'react';

import {
  reducer,
  initialState,
  COMPANIES_DETAIL_ACTIONS,
} from './companiesDetailReducer';
import companiesService from './companiesService';

const useCompaniesDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCompany = () => {
    const id = props.match?.params?.id;
    if (id) {
      return companiesService
        .getCompany({ id })
        .then((company) => {
          dispatch({
            type: COMPANIES_DETAIL_ACTIONS.APP_LOADS_DATA,
            payload: company,
          });
        })
        .catch((e) => {
          dispatch({
            type: COMPANIES_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  useEffect(() => {
    getCompany();
  }, [props.match?.params?.id]);

  const userClicksLoginCompany = () => {
    appBus.pub(AUTH_EVENTS.COMPANY_CHANGED, companyToAuthState(state.company));
  };

  const userClicksEditCompany = () =>
    props.history.push(`/companies/${state.company.id}/edit`);

  const userClicksClientAdmin = (clientAdmin) =>
    props.history.push(`/users/${clientAdmin.id}`);

  return {
    state,
    userClicksLoginCompany,
    userClicksEditCompany,
    userClicksClientAdmin,
  };
};

export default useCompaniesDetail;
