import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import {
  reducer,
  initialState,
  SETTINGS_ASSET_STATUSES_ACTIONS,
} from './settingsAssetStatusesListReducer';
import debounce from 'lodash.debounce';
import settingsService from './settingsService';
import { useEffect, useReducer } from 'react';
import useQueryParams from 'lib/useQueryParams';
import settingsAssetStatusesFormValidator from './settingsAssetStatusesFormValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const fetchSettingsAssetStatuses = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return settingsService
    .getAssetStatuses(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useSettingsAssetStatusesList = ({ location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setParams, getParams } = useQueryParams(location, history);

  useNavigationPrompt(state.editField?.isDirty);

  const editAssetStatus = () => {
    settingsAssetStatusesFormValidator(state.editField)
      .then((data) =>
        settingsService.saveAssetStatus(data).then(() => {
          fetchSettingsAssetStatuses(
            { page: state.page, pageSize: state.pageSize },
            dispatch,
          );
          dispatch({
            type: SETTINGS_ASSET_STATUSES_ACTIONS.CLEAR_EDIT_ASSET_STATUS,
          });
        }),
      )
      .catch((e) => {
        dispatch({
          type: SETTINGS_ASSET_STATUSES_ACTIONS.RESET_STATE,
          payload: {
            editField: e.payload,
          },
        });
      });
  };

  useEffect(async () => {
    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: getParams(),
    });
  }, []);

  useEffect(() => {
    fetchSettingsAssetStatuses(
      {
        order: {
          [state.sortBy]: state.sortOrder,
        },
        search: state.search,
        page: state.page,
        pageSize: state.pageSize,
      },
      dispatch,
    );
  }, [state.sortBy, state.sortOrder, state.search, state.page, state.pageSize]);

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  const userSelectsEditAssetStatus = (name, id) => {
    dispatch({
      type: SETTINGS_ASSET_STATUSES_ACTIONS.SET_EDIT_ASSET_STATUS,
      payload: {
        name,
        id,
      },
    });
  };

  const userClearsEditAssetStatus = () => {
    dispatch({
      type: SETTINGS_ASSET_STATUSES_ACTIONS.CLEAR_EDIT_ASSET_STATUS,
    });
  };

  const userTypesAssetStatusLabel = (e) => {
    dispatch({
      type: SETTINGS_ASSET_STATUSES_ACTIONS.USER_CHANGES_INPUT_EDIT,
      payload: e.target.value,
    });
  };

  const userSubmitsAssetStatus = () => {
    dispatch({
      type: SETTINGS_ASSET_STATUSES_ACTIONS.USER_SUBMITS_ASSET_STATUS,
    });
  };

  return {
    state,
    dispatch,
    userSelectsEditAssetStatus,
    userClearsEditAssetStatus,
    sortBy,
    setPage,
    setPageSize,
    setSearch,
    userTypesAssetStatusLabel,
    userSubmitsAssetStatus,
    editAssetStatus,
  };
};

export default useSettingsAssetStatusesList;
