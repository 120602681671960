import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import {
  FILTER_ACTIONS,
  reducer,
  initialState,
} from './workObjectsListReducer';
import debounce from 'lodash.debounce';
import workObjectsService from './workObjectsService';
import useQueryFilters from 'lib/useQueryFilters';
import { useEffect } from 'react';
import { entityToSelectOption } from 'lib/dataTransform';
import useRoles from 'lib/useRoles';
import ROLES from 'config/roles';

const NO_FILTER = {
  value: '',
  label: 'All',
};

const progressFilterOptions = [
  NO_FILTER,
  {
    label: 'Completed',
    value: 'complete',
  },
  {
    label: 'Not completed',
    value: 'not-complete',
  },
  {
    label: 'Awaiting decision',
    value: 'awaiting-decision',
  },
];

const byStatusFilterOptions = [
  NO_FILTER,
  { label: 'Active', value: 'active' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'Draft', value: 'draft' },
  { label: 'Finished', value: 'finished' },
  { label: 'Pending', value: 'pending' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Canceled', value: 'canceled' },
];

const setFilters = (params) => {
  let filters = { ...params.filters };
  if (params?.workflowId) {
    filters = { ...filters, workflowId: params.workflowId };
  }
  if (params?.atSource) {
    filters = { ...filters, atSource: params.atSource };
  }
  if (params?.byAssetId) {
    filters = { ...filters, byAssetId: params.byAssetId };
  }
  if (params?.pendingUD) {
    filters = { ...filters, pendingUD: !!params.pendingUD };
  }
  if (params?.source) {
    filters = { ...filters, bySourceId: params.source };
    delete params.source;
  }
  if (params?.progress) {
    filters = { ...filters, progress: params.progress };
    delete params.progress;
  }
  if (params?.byStatus) {
    filters = { ...filters, byStatus: params.byStatus };
    delete params.byStatus;
  }
  if (params?.destination) {
    filters = { ...filters, byDestinationId: params.destination };
    delete params.destination;
  }
  if (params?.vendor) {
    filters = { ...filters, byVendorId: params.vendor };
    delete params.vendor;
  }
  if (params?.city) {
    filters = { ...filters, bySourceCityId: params.city };
    delete params.city;
  }
  return filters;
};

const fetchWorkObjects = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });
  const filters = setFilters(params);

  return workObjectsService
    .getWorkObjects({ ...params, filters })
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: results,
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const fetchFilterOptions = (promises, dispatch) => {
  return Promise.all(promises)
    .then((filters) => {
      dispatch({
        type: FILTER_ACTIONS.APP_LOADS_FILTERS,
        payload: filters.reduce(
          (filters, partial) => ({ ...filters, ...partial }),
          {},
        ),
      });
    })
    .catch(() => {
      dispatch({
        type: FILTER_ACTIONS.APP_FAILS_LOAD_FILTERS,
        payload: ['Could not load filters'],
      });
    });
};

const useWorkObjectsList = ({ location, history }) => {
  const list = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchWorkObjects,
  );

  const { isAllowed } = useRoles();

  const filterConfig = {
    source: list.state.filterOptions.source,
    city: list.state.filterOptions.city,
    destination: list.state.filterOptions.destination,
    vendor: list.state.filterOptions.vendor,
    workflowId: list.state.filterOptions.workflowId,
    progress: list.state.filterOptions.progress,
    byStatus: list.state.filterOptions.byStatus,
  };

  const showFilters = {
    workflowId: !isAllowed([
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
    ]),
  };

  const filters = useQueryFilters(location, history, filterConfig);

  const userClicksOnRow = (id) => {
    history.push(`/work-objects/${id}`);
  };

  useEffect(() => {
    // Set default sort only once at load
    const { sortBy, sortOrder } = list.getParams();
    const newParams = {};
    if (!sortBy && !sortOrder) {
      newParams.sortBy = 'lastActivity';
      newParams.sortOrder = 'DESC';
    }
    // Set default filters only once at load
    if (!filters.values.progress) {
      newParams.progress = 'not-complete';
    }
    list.setParams(newParams);
  }, []);

  useEffect(() => {
    const sourceToSelectOption = (s) =>
      s?.name
        ? {
          label: `${s.name}${s.externalId ? ` (${s.externalId})` : ''}`,
          value: s.id,
        }
        : null;

    const filterPromises = [
      workObjectsService
        .getSources({ filters: { asWOSource: true } })
        .then((res) => ({
          source: [NO_FILTER, ...res.data.map(sourceToSelectOption)],
        })),
      workObjectsService
        .getSources({ filters: { asWODestination: true } })
        .then((res) => ({
          destination: [NO_FILTER, ...res.data.map(sourceToSelectOption)],
        })),

      workObjectsService
        .getSources({ filters: { asWOVendor: true } })
        .then((res) => ({
          vendor: [NO_FILTER, ...res.data.map(sourceToSelectOption)],
        })),
      showFilters.workflowId
        ? workObjectsService.getWorkflows().then((res) => ({
          workflowId: [
            NO_FILTER,
            ...res.data.map(entityToSelectOption('name', 'id')),
          ],
        }))
        : Promise.resolve({ workflowId: [] }),
      Promise.resolve({ progress: progressFilterOptions }),
      Promise.resolve({ byStatus: byStatusFilterOptions }),
      workObjectsService.getSourceCityFilterOptions().then((res) => ({
        city: [NO_FILTER, ...res.data.map(entityToSelectOption('name', 'id'))],
      })),
    ];

    fetchFilterOptions(filterPromises, list.dispatch);
  }, []);

  return { ...list, filters, showFilters, userClicksOnRow };
};

export default useWorkObjectsList;
