import './UsersTable.scss';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import UserStatusPill from '../user-status-pill/UserStatusPill';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import Table from 'lib/components/table/Table';

const usersTableColumns = [
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
  {
    title: 'Name / Company',
    sortId: 'name',
    className: 'name-cell',
    render: (record) => <NamedAvatar user={record} />,
  },
  {
    title: 'Roles',
    className: 'roles-cell',
    render: (record) => (
      <div className='roles-cell-container'>
        {(record.roles || []).map((role, index) => {
          return (
            <StatusPill key={index} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {role?.role}
            </StatusPill>
          );
        })}
      </div>
    ),
  },
  {
    title: 'Email',
    className: 'email-cell',
    sortId: 'emails.email',
    render: (record) => record.emails[0]?.email || '-',
  },
  {
    title: 'Phone',
    sortId: 'phoneNumbers.phone',
    className: 'email-cell',
    render: (record) => record.phoneNumbers[0]?.phone || '-',
  },
  {
    title: 'Status',
    sortId: 'status',
    textAlign: 'right',
    render: (record) => (
      <div className='user-status-container'>
        <UserStatusPill status={record.status} />
      </div>
    ),
  },
];

const UsersTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={usersTableColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='users-table'
    {...props}
  />
);

export default UsersTable;
