import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import AddButton from 'lib/components/add-button/AddButton';
import SearchInput from 'lib/components/search-input/SearchInput';
import IconButton from 'lib/components/icon-button/IconButton';
import SourcesTable from '../sources-table/SourcesTable';
import useSourcesList from 'modules/sources/useSourcesList';
import {
  ADD_SOURCES_ROLES,
  IMPORT_SOURCES_ROLES,
} from '../../sourcePermissions';

import { ReactComponent as ImportIcon } from 'assets/images/upload.svg';
import { ReactComponent as ExportIcon } from 'assets/images/download.svg';

import './SourcesListPage.scss';
import { useState } from 'react';
import sourcesService from 'modules/sources/sourcesService';
import { downloadResponse } from 'lib/download';

export default function SourcesListPage(props) {
  const [isExportSourcesBusy, setIsExportSourcesBusy] = useState(false);

  const doExportSources = () => {
    setIsExportSourcesBusy(true);

    sourcesService
      .exportSources()
      .then((data) => {
        downloadResponse(data, 'sources.csv');
      })
      .finally(() => {
        setIsExportSourcesBusy(false);
      });
  };

  const list = useSourcesList(props);

  return (
    <div className='sources-list-page'>
      <div className='row sources-list-header container-m'>
        <h2>Sources</h2>
        <div className='header-buttons'>
          <ProtectedComponent allowed={ADD_SOURCES_ROLES}>
            <IconButton
              prefix={<ExportIcon />}
              disabled={isExportSourcesBusy}
              onClick={doExportSources}
              data-testid='SourcesListPage.exportButton'
              className='margin-right'
            >
              Export sources
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={IMPORT_SOURCES_ROLES}>
            <IconButton
              prefix={<ImportIcon />}
              onClick={() => props.history.push('/sources/import')}
              data-testid='SourcesListPage.importSourcesButton'
            >
              Import sources
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_SOURCES_ROLES}>
            <AddButton onClick={() => props.history.push('/sources/add')}>
              New source
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row filter-container container-m'>
        <div className='search-container'>
          <SearchInput value={list.state.search} onChange={list.setSearch} />
        </div>
      </div>
      <SourcesTable
        data={list.state.data}
        setPageSize={list.setPageSize}
        pageSize={list.state.pageSize}
        setPage={list.setPage}
        page={list.state.page}
        count={list.state.count}
        setSortBy={list.sortBy}
        sortOrder={list.state.sortOrder}
        sortBy={list.state.sortBy}
        userClicksOnRow={list.userClicksOnRow}
      />
    </div>
  );
}
