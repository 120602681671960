import Modal from 'lib/components/modal/Modal';
import Button from 'lib/components/button/Button';
import Textarea from 'lib/components/textarea/Textarea';
import './UsageDecisionNotesModal.scss';
import classNames from 'classnames';

const UsageDecisionNotesModal = ({
  data,
  onSubmit,
  onCancel,
  onChange,
  ...props
}) => {
  return (
    <Modal
      title='Add notes'
      actions={
        <>
          <Button onClick={onSubmit}>{data.action?.name}</Button>
          <Button onClick={onCancel} className='link-button'>
            Cancel
          </Button>
        </>
      }
      isOpen={data.isModalOpen}
      onRequestClose={onCancel}
      className={classNames('usage-decision-notes-modal', {
        'is-override-usage-decision': data.action?.isOverrideUsageDecision,
      })}
      {...props}
    >
      <p>Please provide some information about this decision</p>
      <Textarea
        value={data.notes.value}
        charsLeft={data.notes.charsLeft}
        errors={data.notes.errors}
        onChange={onChange}
        rows={8}
      />
      {!!data.action?.isOverrideUsageDecision && (
        <p className='warn-note'>
          <strong>WARNING:</strong> You are about to override the Workflow
          decisions. This action is irreversible!
        </p>
      )}
      {data.action?.actionId === '1' && (
        <p className='warn-note'>
          <strong>WARNING:</strong> You are about to cancel the Work Object.
          This action is irreversible!
        </p>
      )}
    </Modal>
  );
};

export default UsageDecisionNotesModal;
