import useQueryParams from 'lib/useQueryParams';
import { useReducer } from 'react';
import authService from '../../lib/authService';

import newPasswordReducer, {
  INITIAL_NEW_PASSWORD_STATE,
  NEW_PASSWORD_ACTIONS,
} from './newPasswordReducer';

import newPasswordValidator from './newPasswordValidator';

function useNewPasswordForm({ location, history }) {
  const [state, dispatch] = useReducer(
    newPasswordReducer,
    INITIAL_NEW_PASSWORD_STATE,
  );

  const { getParams } = useQueryParams(location, history);

  const { token } = getParams();

  const onChangePassword = (e) =>
    dispatch({
      type: NEW_PASSWORD_ACTIONS.USER_TYPES_PASSWORD,
      payload: e.target.value,
    });

  const onChangeConfirmPassword = (e) => {
    dispatch({
      type: NEW_PASSWORD_ACTIONS.USER_TYPES_CONFIRM_PASSWORD,
      payload: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: NEW_PASSWORD_ACTIONS.FORM_LOADING, payload: true });

    return newPasswordValidator({
      password: state.password.value,
      confirmPassword: state.confirmPassword.value,
    })
      .then((credentials) => authService.setPassword(credentials, token))
      .catch(({ payload }) => {
        dispatch({
          type: NEW_PASSWORD_ACTIONS.RESET_STATE,
          payload: payload,
        });
        dispatch({ type: NEW_PASSWORD_ACTIONS.FORM_LOADING, payload: false });
      });
  };

  return { state, onChangePassword, onChangeConfirmPassword, onSubmit };
}

export default useNewPasswordForm;
