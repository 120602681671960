import React, { useEffect, useState } from 'react';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import { useUnits } from 'lib/unitsService';
import trashIcon from 'assets/images/trash.png';

function CustomFieldInput({ index, data, onChange, onDelete }) {
  const { measure, value, unit } = data;
  const uomsList = useUnits();
  const [unitValue, setUnitValue] = useState();

  useEffect(() => {
    let selection;
    if (unit && uomsList) {
      for (let i = 0; i < uomsList.length; i += 1) {
        if (uomsList[i].value === unit) {
          selection = uomsList[i];
          break;
        }
      }
    }
    setUnitValue(selection);
  }, [unit, uomsList]);

  function deleteMeasurement() {
    onDelete(index);
  }

  function measureChanged(e) {
    onChange(index, {
      measure: e.target.value,
      value,
      unit,
    });
  }

  function valueChanged(e) {
    onChange(index, {
      measure,
      value: e.target.value,
      unit,
    });
  }

  function unitChanged(val) {
    onChange(index, {
      measure,
      value,
      unit: val.value,
    });
  }

  return (
    <div className='custom-measure-field'>
      <div className='row'>
        <div className='cell'>Measure</div>
        <div className='cell'>Value</div>
        <div className='cell'>Unit</div>
        <div className='cell'></div>
      </div>
      <div className='row'>
        <div className='cell'>
          <Input onChange={measureChanged} value={measure} />
        </div>
        <div className='cell'>
          <Input onChange={valueChanged} value={value} />
        </div>
        <div className='cell dropdown'>
          <Select
            options={uomsList}
            menuPortalTarget={document.body}
            placeholder='Select UOM'
            onChange={unitChanged}
            isSearchable={true}
            menuPlacement='auto'
            value={unitValue}
          />
        </div>
        <div className='cell'>
          <div className='delete-button' onClick={deleteMeasurement}>
            <img src={trashIcon} alt='Delete' />
            <span>Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomFieldInput;
