import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import {
  INSPECTION_PLAN_STATUS,
  getInspectionPlanStatusLabel,
} from 'config/inspectionPlanStatus';

import { ReactComponent as CornerUpRightOutline } from 'assets/images/corner-up-right-outline.svg';

const INSPECTION_PLAN_TO_PILL_VARIANT = {
  [INSPECTION_PLAN_STATUS.DRAFT]: STATUS_PILL_VARIANT.WARNING,
  [INSPECTION_PLAN_STATUS.PUBLISHED]: STATUS_PILL_VARIANT.SUCCESS,
  [INSPECTION_PLAN_STATUS.DEACTIVATED]: STATUS_PILL_VARIANT.ERROR,
  [INSPECTION_PLAN_STATUS.NEEDS_REVIEW]: STATUS_PILL_VARIANT.INFO,
};

const PLAN_ICONS = {
  [INSPECTION_PLAN_STATUS.NEEDS_REVIEW]: <CornerUpRightOutline />,
};

const InspectionPlanStatusPill = ({
  status = INSPECTION_PLAN_STATUS.DRAFT,
  ...props
}) => {
  const variant = INSPECTION_PLAN_TO_PILL_VARIANT[status];
  const icon = PLAN_ICONS[status] || null;

  return (
    <StatusPill variant={variant} icon={icon} {...props}>
      {getInspectionPlanStatusLabel(status)}
    </StatusPill>
  );
};

export default InspectionPlanStatusPill;
