import { castDateTimeToSimpleDate } from 'lib/dateHelpers';
import makeReducer from 'lib/makeReducer';

const getInitialModalState = () => ({
  list: [],
  search: '',
  sortBy: 'id',
  sortOrder: 'ASC',
  isModalOpen: false,
  page: 1,
  pageSize: 10,
  count: 0,
  errors: [],
});

const getInitialCancelModalState = () => ({
  charsLeft: 1000,
  notes: '',
  isModalOpen: false,
});

const INITIAL_FORM_STATE = {
  inspectionDate: {
    value: null,
    errors: [],
  },
  inspectionWindow: {
    value: ['', ''],
    errors: [],
  },
  inspector: {
    value: null,
    errors: [],
  },
  availableInspectors: getInitialModalState(),
  cancelNotes: getInitialCancelModalState(),
  loading: true,
  errors: [],
};

export const INSPECTIONS_DETAIL_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  APP_LOADS_INSPECTION: 'APP_LOADS_INSPECTION',
  APP_LOADS_INSPECTORS: 'APP_LOADS_INSPECTORS',
  USER_OPENS_INSPECTORS_MODAL: 'USER_OPENS_INSPECTORS_MODAL',
  USER_CANCELS_INSPECTORS_MODAL: 'USER_CANCELS_INSPECTORS_MODAL',
  USER_SELECTS_INSPECTOR: 'USER_SELECTS_INSPECTOR',
  APP_FAILS_ASSIGN_INSPECTOR: 'APP_FAILS_ASSIGN_INSPECTOR',
  USER_SEARCHES_INSPECTORS: 'USER_SEARCHES_INSPECTORS',
  USER_SORTS_INSPECTORS: 'USER_SORTS_INSPECTORS',
  USER_SETS_PAGE_FOR_INSPECTORS: 'USER_SETS_PAGE_FOR_INSPECTORS',
  USER_SETS_DATE: 'USER_SETS_DATE',
  USER_SETS_WINDOW: 'USER_SETS_WINDOW',
  USER_CLEARS_INSPECTOR: 'USER_CLEARS_INSPECTOR',
  USER_CLEARS_DATE: 'USER_CLEARS_DATE',
  USER_CLEARS_WINDOW: 'USER_CLEARS_WINDOW',
  USER_OPENS_CANCEL_MODAL: 'USER_OPENS_CANCEL_MODAL',
  USER_CLOSES_CANCEL_MODAL: 'USER_CLOSES_CANCEL_MODAL',
  USER_TYPES_CANCEL_NOTES: 'USER_TYPES_CANCEL_NOTES',
};

const INSPECTIONS_REDUCER_CONFIG = {
  [INSPECTIONS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.APP_LOADS_INSPECTION]: (state, action) => {
    const { inspection, loading, errors } = action.payload;
    return {
      ...state,
      ...inspection,
      inspector: { value: inspection.inspector, errors: [] },
      inspectionDate: {
        value: inspection.inspectionDate,
        errors: [],
      },
      inspectionWindow: {
        value: [
          inspection.inspectionWindowStartDate
            ? castDateTimeToSimpleDate(inspection.inspectionWindowStartDate)
            : '',
          inspection.inspectionWindowEndDate
            ? castDateTimeToSimpleDate(inspection.inspectionWindowEndDate)
            : '',
        ],
        errors: [],
      },
      loading: loading,
      errors,
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.APP_LOADS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_OPENS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_CANCELS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: getInitialModalState(),
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_OPENS_CANCEL_MODAL]: (state) => {
    return {
      ...state,
      cancelNotes: {
        ...state.cancelNotes,
        isModalOpen: true,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_CLOSES_CANCEL_MODAL]: (state) => {
    return {
      ...state,
      cancelNotes: getInitialCancelModalState(),
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_TYPES_CANCEL_NOTES]: (state, action) => {
    const notes = action.payload.substring(0, 1000);
    return {
      ...state,
      cancelNotes: {
        ...state.cancelNotes,
        notes,
        charsLeft: 1000 - notes.length,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SELECTS_INSPECTOR]: (state, action) => {
    return {
      ...state,
      inspector: {
        value: action.payload,
        errors: [],
      },
      availableInspectors: getInitialModalState(),
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.APP_FAILS_ASSIGN_INSPECTOR]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        errors: action.payload,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SEARCHES_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SORTS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        ...action.payload,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SETS_PAGE_FOR_INSPECTORS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        page: action.payload,
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SETS_DATE]: (state, action) => {
    return {
      ...state,
      inspectionDate: {
        value: action.payload,
        errors: [],
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_CLEARS_DATE]: (state) => {
    return {
      ...state,
      inspectionDate: {
        value: null,
        errors: [],
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_SETS_WINDOW]: (state, action) => {
    return {
      ...state,
      inspectionWindow: {
        value: action.payload,
        errors: [],
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_CLEARS_WINDOW]: (state) => {
    return {
      ...state,
      inspectionWindow: {
        value: ['', ''],
        errors: [],
      },
    };
  },
  [INSPECTIONS_DETAIL_ACTIONS.USER_CLEARS_INSPECTOR]: (state) => {
    return {
      ...state,
      inspector: {
        value: null,
        errors: [],
      },
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTIONS_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
