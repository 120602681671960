import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import SourcesListPage from './components/sources-list-page/SourcesListPage';
import SourcesEditPage from './components/sources-edit-page/SourcesEditPage';
import SourcesDetailPage from './components/sources-detail-page/SourcesDetailPage';
import SourcesAddPage from './components/sources-add-page/SourcesAddPage';
import SourcesImportPage from './components/souces-import-page/SourcesImportPage';
import {
  ADD_SOURCES_ROLES,
  VIEW_ALL_SOURCES_ROLES,
  EDIT_OWN_SOURCES_ROLES,
  IMPORT_SOURCES_ROLES,
} from './sourcePermissions';

export default function SourcesRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_ALL_SOURCES_ROLES}
        path='/sources'
        exact
        render={(props) => <SourcesListPage {...props} />}
      />
      <ProtectedRoute
        allowed={[...ADD_SOURCES_ROLES]}
        path='/sources/add'
        exact
        render={(props) => <SourcesAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={[...IMPORT_SOURCES_ROLES]}
        path='/sources/import'
        exact
        render={(props) => <SourcesImportPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_SOURCES_ROLES}
        path='/sources/:id(\d+)'
        exact
        render={(props) => <SourcesDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={[...ADD_SOURCES_ROLES, ...EDIT_OWN_SOURCES_ROLES]}
        path='/sources/:id(\d+)/edit'
        exact
        render={(props) => <SourcesEditPage {...props} />}
      />
    </>
  );
}
