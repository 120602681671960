import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import _isEqual from 'lodash/isEqual';
import { SYSTEM_DEFECTS } from 'config/defects';
import {
  PHOTO_REQUIRED,
  PHOTO_REQUIRED_LABEL,
  ANSWER_TYPE,
  DEPENDENCY,
} from 'config/questionOptions';

import { formatQuestionCriteria, getTranslation } from 'lib/dataTransform';

import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import File from 'lib/components/file/File';
import Input from 'lib/components/input/Input';
import QuestionActions from 'lib/components/question-detail/QuestionActions';
import Radio from 'lib/components/radio/Radio';
import Select from 'lib/components/select/Select';
import Textarea from 'lib/components/textarea/Textarea';
import Toggle from 'lib/components/toggle/Toggle';
import UploadButton from 'lib/components/upload-button/UploadButton';

import AnswersWidget from './AnswersWidget';
import AqlWidget from './AqlWidget';
import DependencyWidget from './DependencyWidget';
import ExpectedMeasureWidget from './ExpectedMeasureWidget';
import DynamicModifierWidget from './DynamicModifierWidget';

import testId from './fixtures/testid';

import './QuestionForm.scss';
import QuestionFormAssetRefDocs from './QuestionFormAssetRefDocs';

function isSystemDefect(defect) {
  return Object.values(SYSTEM_DEFECTS).includes(
    getTranslation(defect.name).display,
  );
}

const formatDependency = (question) => {
  const { dependencyAction, dependencyQuestion, dependencyCriteria } = question;

  const act = dependencyAction.value?.label;
  const quest = dependencyQuestion.value?.label;
  const crit = dependencyCriteria.value?.value;
  if (!act || !quest || !crit) {
    return null;
  }

  let criteria;
  if (!Object.values(DEPENDENCY).includes(crit)) {
    criteria = dependencyCriteria.value?.label;
  } else {
    criteria = formatQuestionCriteria(crit);
  }

  return `${act} ${quest} ${criteria.toLowerCase()}`;
};

const PHOTO_REQUIRED_OPTIONS = Object.values(PHOTO_REQUIRED).map((val) => ({
  label: PHOTO_REQUIRED_LABEL[val],
  value: val,
}));

const QuestionForm = memo(
  ({
    data,
    onEditChange,
    isUndoEditDisabled,
    onChange,
    questionOptions,
    planOptions,
    onAddTool,
    onRemoveTool,
    onEditDefect,
    onRemoveDefect,
    onAddDocuments,
    onRemoveDocument,
    onResetDocuments,
    onAddAssetReferenceDocument,
    onRemoveAssetReferenceDocument,
    isMoveUpDisabled,
    onMoveUp,
    onMoveTop,
    isMoveDownDisabled,
    onMoveDown,
    onMoveBottom,
    onDelete,
    editVariant,
    isAnswerTypeDisabled = false,
    isDependencyDisabled = false,
    hasErrors = false,
    isCollapsible = true,
    displayId = false,
    hideActions = false,
    isNarrow = false,
    isInModal = false,
    showKeywords = false,
    showHeaderDependency = false,
    isDeleteDisabled = false,
    language = null,
    languageList = null,
  }) => {
    const [isOpen, setOpen] = useState(true);

    useEffect(() => {
      if (hasErrors && !isOpen) {
        setOpen(true);
      }
    }, [hasErrors]);

    if (!data) {
      return null;
    }

    const isDynamicCriteriaDisabled =
      data.dynamicRule?.value?.value === 'first_inspection';

    const isExpectedMeasureVisible =
      data.answerType?.value?.value === ANSWER_TYPE.QUANTITATIVE_INPUT;

    const isDefectsDisabled = [
      ANSWER_TYPE.QUANTITATIVE_INPUT,
      ANSWER_TYPE.DATE_SELECTION,
      ANSWER_TYPE.TEXT_AREA_INPUT,
    ].includes(data.answerType?.value?.value);

    const docUploadOptions = {
      multiple: true,
    };

    const formattedId = displayId
      ? `${data.id || ''}${data.externalId ? ` / ${data.externalId}` : ''}`
      : `#${data.order + 1 || ''}`;

    // TODO: Refactor to own subcomponent after automated testing implemented
    const questionTypeSelect = (
      <Select
        data-testid={testId.questionTypeInput}
        isInModal={isInModal}
        value={data.type?.value}
        errors={data.type?.errors}
        options={questionOptions?.type}
        onChange={(value) => onChange('type', value)}
      />
    );

    // Workaround for react-select not calculating available height
    // properly when body has overflow:hidden and scroll is not at bottom.
    const modifierSelectPlacement =
      isInModal && !isExpectedMeasureVisible ? 'top' : 'auto';

    const { translation, fallback } = getTranslation(
      data.name.value,
      language,
      languageList,
    );

    return (
      <div
        data-testid={testId.container}
        className={classNames('qm-question-form', { 'is-open': isOpen })}
      >
        <div
          data-testid={testId.summary}
          className='table-row question-editing question-summary'
        >
          <div className='table-cell edit-cell'>
            {!hideActions && (
              <QuestionActions
                editType={editVariant}
                isEditing={true}
                isEditDisabled={isUndoEditDisabled}
                onDelete={!isDeleteDisabled ? onDelete : undefined}
                isDeleteDisabled={isDeleteDisabled}
                onMoveUp={onMoveUp}
                onMoveTop={onMoveTop}
                onMoveDown={onMoveDown}
                onMoveBottom={onMoveBottom}
                isMoveUpDisabled={isMoveUpDisabled}
                isMoveDownDisabled={isMoveDownDisabled}
                onEdit={onEditChange}
              />
            )}
          </div>
          <div className='table-cell id-cell' data-testid={testId.id}>
            {formattedId}
          </div>
          <div className='table-cell question-cell'>
            <Textarea
              data-testid={testId.nameInput}
              rows={4}
              value={translation}
              placeholder={fallback}
              errors={data.name?.errors}
              onChange={(ev) => onChange('name', ev.target.value)}
            />
            {typeof isUndoEditDisabled === 'string' && (
              <p className='field-description'>{isUndoEditDisabled}</p>
            )}
          </div>
          <div className='table-cell question-type-cell'>
            {!isNarrow && questionTypeSelect}
          </div>
          {showHeaderDependency && (
            <div className='table-cell dependency-cell'>
              {formatDependency(data)}
            </div>
          )}
          <div className='table-cell align-right sample-size-cell'></div>
          <div className='table-cell align-right toggle-cell'>
            {isCollapsible ? (
              <Toggle
                data-testid={testId.toggle}
                disabled={hasErrors}
                direction={isOpen ? 'up' : 'down'}
                onClick={() => setOpen(!isOpen)}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {(isOpen || !isCollapsible) && (
          <div
            data-testid={testId.body}
            className='table-row question-body question-editing'
          >
            <div className='table-cell body-cell'>
              <div className='question-form'>
                {showKeywords && (
                  <div className='keywords-container'>
                    <Textarea
                      data-testid={testId.keywordsInput}
                      label='Keywords'
                      value={data?.keywords?.value}
                      errors={data?.keywords?.errors}
                      onChange={(ev) => onChange('keywords', ev.target.value)}
                    />
                  </div>
                )}
                {isNarrow && (
                  <div className='narrow-question-type-container'>
                    <div className='form-label'>Question type</div>
                    {questionTypeSelect}
                  </div>
                )}
                <div className='narrow-question-sample-size-container'>
                  <AqlWidget
                    isInModal={isInModal}
                    data={data}
                    planOptions={planOptions}
                    onChange={onChange}
                    questionOptions={questionOptions}
                  />
                </div>
                <div className='question-weight-container'>
                  <Select
                    isInModal={isInModal}
                    name='question-weight'
                    label='Question weight'
                    value={data.questionWeight?.value}
                    options={questionOptions.questionWeight}
                    errors={data.questionWeight?.errors}
                    autoComplete='off'
                    onChange={(value) => {
                      onChange('questionWeight', value);
                    }}
                  />
                </div>
                <div className='answers-container'>
                  <AnswersWidget
                    data={data}
                    questionOptions={questionOptions}
                    isDisabled={isAnswerTypeDisabled}
                    isInModal={isInModal}
                    onChange={onChange}
                  />
                </div>
                <div className='photo-required-container'>
                  <Radio
                    label='Photo'
                    options={PHOTO_REQUIRED_OPTIONS}
                    value={data.photoRequired?.value}
                    errors={data.photoRequired?.errors}
                    onChange={(value) => {
                      onChange('photoRequired', value);
                    }}
                  />
                </div>
                <div className='print-on-report-container'>
                  <Select
                    isInModal={isInModal}
                    label='Print on report'
                    className='print-on-report'
                    options={questionOptions.printOnReport}
                    value={data.printOnReport?.value}
                    errors={data.printOnReport?.errors}
                    onChange={(value) => {
                      onChange('printOnReport', value);
                    }}
                  />
                </div>
                <div className='tools-needed-container'>
                  <div className='form-label'>Tools needed</div>
                  {(data.tools || []).map((tool, idx) => (
                    <div key={idx} className='input-group'>
                      <Input
                        value={tool.value}
                        errors={tool.errors}
                        onChange={(ev) => {
                          onChange('tools', ev.target.value, idx);
                        }}
                      />
                      <DeleteButton onClick={() => onRemoveTool(idx)} />
                    </div>
                  ))}
                  <Button
                    type='button'
                    className='medium-button'
                    onClick={onAddTool}
                  >
                    Add new
                  </Button>
                </div>
                <div className='reference-documents-container'>
                  <div className='form-label'>Reference documents</div>
                  <div className='document-list'>
                    {(data.documents || []).map((doc, idx) => (
                      <div className='document' key={idx}>
                        <div className='form-group'>
                          <File iconOnly data={doc.file} />
                          <Input
                            value={doc.value}
                            className={!!doc.errors.length && 'is-invalid'}
                            onChange={(ev) =>
                              onChange('documents', ev.target.value, idx)
                            }
                          />
                          <DeleteButton onClick={() => onRemoveDocument(idx)} />
                        </div>
                        <ErrorBag errors={doc.errors} />
                      </div>
                    ))}
                  </div>
                  <p className='field-description'>
                    The iPad app only supports documents in PDF format
                  </p>
                  <div className='document-actions'>
                    <UploadButton
                      options={docUploadOptions}
                      onUpload={onAddDocuments}
                    >
                      Upload
                    </UploadButton>
                    {questionOptions.isDocumentListChanged && (
                      <Button
                        type='button'
                        className='medium-button'
                        onClick={onResetDocuments}
                      >
                        Cancel changes
                      </Button>
                    )}
                  </div>
                </div>
                <QuestionFormAssetRefDocs
                  data={data}
                  onChange={onChange}
                  onAddAssetReferenceDocument={onAddAssetReferenceDocument}
                  onRemoveAssetReferenceDocument={
                    onRemoveAssetReferenceDocument
                  }
                />
                <div className='defect-list-container'>
                  <div className='form-label'>Defect list</div>
                  <ul className='defect-list'>
                    {(data.defects || []).map((defect, idx) => {
                      const { translation, fallback } = getTranslation(
                        defect.name,
                        language,
                      );
                      return (
                        <li className='defect' key={idx}>
                          {translation ? (
                            <span>{translation}</span>
                          ) : (
                            <span className='no-translation'>{fallback}</span>
                          )}
                          {isSystemDefect(defect) ? (
                            <div className='system-defect-weight'>
                              {defect.weight?.label}
                            </div>
                          ) : (
                            <>
                              <div>
                                <Select
                                  isInModal={isInModal}
                                  placeholder='Select weight...'
                                  className={classNames(
                                    'defect-weight-select',
                                    {
                                      'is-invalid': defect.errors?.length,
                                    },
                                  )}
                                  value={defect.weight}
                                  onChange={(val) =>
                                    onChange('defects.weight', val, idx)
                                  }
                                  options={questionOptions.questionWeight}
                                />
                              </div>
                              <DeleteButton
                                onClick={() => onRemoveDefect(defect)}
                              />
                            </>
                          )}
                          <ErrorBag errors={defect.errors} />
                        </li>
                      );
                    })}
                  </ul>
                  {isDefectsDisabled && (
                    <p className='field-description'>
                      Text Area Input, Date Selection and Quantitative Input
                      questions do not support defects
                    </p>
                  )}
                  <Button
                    type='button'
                    className='medium-button'
                    onClick={onEditDefect}
                    disabled={isDefectsDisabled}
                  >
                    Edit
                  </Button>
                </div>
                {data.order > 0 && (
                  <div className='dependency-container'>
                    <DependencyWidget
                      data={data}
                      isInModal={isInModal}
                      questionOptions={questionOptions}
                      onChange={onChange}
                      isDisabled={isDependencyDisabled}
                    />
                  </div>
                )}
                <div className='dynamic-modifier-container'>
                  <DynamicModifierWidget
                    isInModal={isInModal}
                    data={data}
                    onChange={onChange}
                    questionOptions={questionOptions}
                    modifierSelectPlacement={modifierSelectPlacement}
                    isDynamicCriteriaDisabled={isDynamicCriteriaDisabled}
                  />
                </div>
                {isExpectedMeasureVisible && (
                  <ExpectedMeasureWidget
                    data={data}
                    onChange={onChange}
                    questionOptions={questionOptions}
                    isInModal={isInModal}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  (a, b) => {
    return _isEqual(a, b);
  },
);

QuestionForm.propTypes = {
  data: PropTypes.object,
  onEditChange: PropTypes.func,
  questionOptions: PropTypes.object,
  planOptions: PropTypes.object,
};

export default QuestionForm;
