import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import { formatDate } from 'lib/dateHelpers';
import './InspectionPreviousDecisions.scss';

const InspectionPreviousDecisions = ({ inspection }) => {
  return (
    <div className='previous-decisions-container'>
      {(inspection?.actualDecisions || []).map((decision) => (
        <div key={decision.id} className='previous-decision'>
          <NamedAvatar
            user={decision.user}
            subtitle={formatDate(decision.createdAt)}
          />
          {!!decision.notes && (
            <p className='decision-notes'>{decision.notes}</p>
          )}
          <div className='decision-name'>{decision.name}</div>
        </div>
      ))}
    </div>
  );
};

export default InspectionPreviousDecisions;
