import CompaniesForm from '../companies-form/CompaniesForm';
import './CompaniesAddPage.scss';

const CompaniesAddPage = (props) => {
  return (
    <div
      className='companies-add-page container-m'
      data-testid='CompaniesAddPage.container'
    >
      <div className='row'>
        <h1>New company</h1>
      </div>
      <div className='row'>
        <CompaniesForm {...props} />
      </div>
    </div>
  );
};

export default CompaniesAddPage;
