import React from 'react';
import useInspectionTypesList from '../../useInspectionTypesList';
import AddButton from 'lib/components/add-button/AddButton';
import SearchInput from 'lib/components/search-input/SearchInput';
import InspectionTypesTable from '../inspection-types-table/InspectionTypesTable';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { ADD_INSPECTION_TYPES_ROLES } from 'modules/inspection-types/inspectionTypesPermissions';

import './InspectionTypesListPage.scss';

export default function InspectionTypesListPage(props) {
  const list = useInspectionTypesList(props);

  return (
    <div className='inspection-types-list-page'>
      <div className='row inspection-types-list-header container-m'>
        <h2>Inspection types</h2>

        <div className='add-new-container'>
          <ProtectedComponent allowed={ADD_INSPECTION_TYPES_ROLES}>
            <AddButton
              onClick={() => props.history.push('/inspection-types/add')}
              data-testid='InspectionTypesListPage.addNew'
            >
              New inspection type
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput value={list.state.search} onChange={list.setSearch} />
        </div>
      </div>
      <div className='inspection-types-table'>
        <InspectionTypesTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
        />
      </div>
    </div>
  );
}
