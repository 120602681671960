import makeReducer from 'lib/makeReducer';
import { cloneDeep } from 'lodash';
import { questionToFormState } from './dataTransform';

export const PAGE_ACTIONS = {
  APP_LOADS_ANSWER_OPTIONS: 'APP_LOADS_ANSWER_OPTIONS',
  APP_LOADS_QUESTION_TEMPLATE: 'APP_LOADS_QUESTION_TEMPLATE',
  APP_LOADS_ASSET_CUSTOM_MEASURES: 'APP_LOADS_ASSET_CUSTOM_MEASURES',
  USER_SETS_LANGUAGE: 'USER_SETS_LANGUAGE',
};

export const QUESTION_FORM_ACTIONS = {
  USER_CHANGES_QUESTION_INPUT: 'USER_CHANGES_QUESTION_INPUT',
  USER_ADDS_QUESTION_TOOL: 'USER_ADDS_QUESTION_TOOL',
  USER_REMOVES_QUESTION_TOOL: 'USER_REMOVES_QUESTION_TOOL',
  USER_ADDS_ASSET_REFERENCE_DOCUMENT: 'USER_ADDS_ASSET_REFERENCE_DOCUMENT',
  USER_REMOVES_ASSET_REFERENCE_DOCUMENT:
    'USER_REMOVES_ASSET_REFERENCE_DOCUMENT',
  USER_ADDS_QUESTION_DOCUMENTS: 'USER_ADDS_QUESTION_DOCUMENTS',
  USER_REMOVES_QUESTION_DOCUMENT: 'USER_REMOVES_QUESTION_DOCUMENT',
  USER_REMOVES_QUESTION_DEFECT: 'USER_REMOVES_QUESTION_DEFECT',
  USER_RESETS_QUESTION_DOCUMENTS: 'USER_RESETS_QUESTION_DOCUMENTS',
  USER_EDITS_DEFECTS_LIST: 'USER_EDITS_DEFECT_LIST',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  SET_ERRORS: 'FORM_SET_ERRORS',
};

export const DEFECTS_MODAL_ACTIONS = {
  APP_LOADS_DEFECTS: 'APP_LOADS_DEFECTS',
  APP_RESETS_SELECTED_DEFECTS: 'APP_RESETS_SELECTED_DEFECTS',
  USER_OPENS_DEFECTS_MODAL: 'USER_OPENS_DEFECT_MODAL',
  USER_TOGGLES_DEFECT: 'USER_TOGGLES_DEFECT',
  USER_CANCELS_DEFECTS_MODAL: 'USER_CANCELS_DEFECT_MODAL',
  USER_SEARCHES_DEFECTS: 'USER_SEARCHES_DEFECTS',
  USER_SETS_DEFECTS_PAGE: 'USER_SETS_DEFECTS_PAGE',
  USER_SORTS_DEFECTS: 'USER_SORTS_DEFECTS',
  SET_ERRORS: 'DEFECTS_SET_ERRORS',
};

const getInitialDefectModalState = () => ({
  list: [],
  count: 0,
  errors: [],
  search: '',
  page: 1,
  pageSize: 10,
  sortBy: 'id',
  sortOrder: 'ASC',
  isModalOpen: false,
  selected: [],
});

const initialPageState = {
  form: questionToFormState({}, {}),
  loading: false,
  processing: false,
  language: null,
  availableDefects: getInitialDefectModalState(),
  isDirty: false,
};

const PAGE_REDUCER_CONFIG = {
  [PAGE_ACTIONS.APP_LOADS_ANSWER_OPTIONS]: (state, action) => {
    return {
      ...state,
      answerOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_QUESTION_TEMPLATE]: (state, action) => {
    return {
      ...state,
      form: action.payload,
      loading: false,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_ASSET_CUSTOM_MEASURES]: (state, action) => {
    return {
      ...state,
      otherMeasureOptions: action.payload,
    };
  },
  [PAGE_ACTIONS.USER_SETS_LANGUAGE]: (state, action) => {
    return {
      ...state,
      language: action.payload,
    };
  },
};

const QUESTION_FORM_REDUCER_CONFIG = {
  [QUESTION_FORM_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      form: {
        ...state.form,
        ...action.payload,
      },
    };
  },
  [QUESTION_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      processing: true,
      isDirty: false,
    };
  },
  [QUESTION_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      processing: false,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT]: (state, action) => {
    const { key, value, arrayIdx } = action.payload;
    const newForm = cloneDeep(state.form);
    const [questionKey, field = 'value'] = key.split('.');

    if (arrayIdx !== false) {
      newForm[questionKey][arrayIdx] = {
        ...newForm[questionKey][arrayIdx],
        [field]: value,
        errors: [],
      };
    } else {
      newForm[questionKey] = {
        ...newForm[questionKey],
        [field]: value,
        errors: [],
      };
    }
    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_ADDS_QUESTION_TOOL]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.tools.push({
      value: '',
      errors: [],
    });

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_TOOL]: (state, action) => {
    const newForm = cloneDeep(state.form);
    newForm.tools.splice(action.payload, 1);

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },

  [QUESTION_FORM_ACTIONS.USER_ADDS_ASSET_REFERENCE_DOCUMENT]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.assetReferenceDocNames.push({
      value: '',
      errors: [],
    });

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_REMOVES_ASSET_REFERENCE_DOCUMENT]: (
    state,
    action,
  ) => {
    const newForm = cloneDeep(state.form);
    newForm.assetReferenceDocNames.splice(action.payload, 1);

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },

  [QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_DEFECT]: (state, action) => {
    const newForm = cloneDeep(state.form);
    const defect = action.payload;
    newForm.defects = newForm.defects.filter((d) => d.id !== defect.id);

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_ADDS_QUESTION_DOCUMENTS]: (state, action) => {
    const newForm = cloneDeep(state.form);
    const newDocs = Array.from(action.payload).map((file) => ({
      file: file,
      value: file.name.split('.').slice(0, -1).join('.'),
      errors: [],
    }));
    newForm.documents = [...newForm.documents, ...newDocs];

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_REMOVES_QUESTION_DOCUMENT]: (state, action) => {
    const newForm = cloneDeep(state.form);
    newForm.documents.splice(action.payload, 1);

    return {
      ...state,
      form: newForm,
      isDirty: true,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_RESETS_QUESTION_DOCUMENTS]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.documents = newForm.initialDocuments;
    return {
      ...state,
      form: newForm,
    };
  },
  [QUESTION_FORM_ACTIONS.USER_EDITS_DEFECTS_LIST]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.defects = cloneDeep(state.availableDefects.selected);

    return {
      ...state,
      form: newForm,
      availableDefects: getInitialDefectModalState(),
      isDirty: true,
    };
  },
};

const DEFECTS_MODAL_REDUCER_CONFIG = {
  [DEFECTS_MODAL_ACTIONS.APP_LOADS_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        ...action.payload,
        errors: [],
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        list: [],
        errors: action.payload,
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_OPENS_DEFECTS_MODAL]: (state) => {
    const selected = cloneDeep(state.form.defects);
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        isModalOpen: true,
        selected,
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_TOGGLES_DEFECT]: (state, action) => {
    const newSelected = cloneDeep(state.availableDefects.selected);
    const defect = action.payload;
    const idx = newSelected.findIndex((sel) => sel.id === defect.id);

    if (idx !== -1) {
      newSelected.splice(idx, 1);
    } else {
      newSelected.push(defect);
    }

    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        selected: newSelected,
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.APP_RESETS_SELECTED_DEFECTS]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.defects = [];

    return {
      ...state,
      form: newForm,
      availableDefects: getInitialDefectModalState(),
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_CANCELS_DEFECTS_MODAL]: (state) => {
    return {
      ...state,
      availableDefects: getInitialDefectModalState(),
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_SEARCHES_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        search: action.payload,
        page: 1,
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_SORTS_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        ...action.payload,
      },
    };
  },
  [DEFECTS_MODAL_ACTIONS.USER_SETS_DEFECTS_PAGE]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        page: action.payload,
      },
    };
  },
};

const REDUCER_CONFIG = {
  ...PAGE_REDUCER_CONFIG,
  ...QUESTION_FORM_REDUCER_CONFIG,
  ...DEFECTS_MODAL_REDUCER_CONFIG,
};

export const { reducer, initialState } = makeReducer(
  REDUCER_CONFIG,
  initialPageState,
);
