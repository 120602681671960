import request from 'lib/request';
import INSPECTION_TYPE_STATUS from 'config/inspectionTypeStatus';
import {
  thirdPartyFormStateToPOSTParams,
  formStatetoPOSTParams,
  internalInspectionToPOSTState,
  updateDeleteParams,
} from './dataTransform';
import { processInspectionFormAPIError } from './inspectionsFormValidator';
import makeLogger from 'lib/makeLogger';
import { processThirdPartyInspectionFormAPIError } from './inspectionsThirdPartyFormValidator';

const log = makeLogger('inspectionsService');

export const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const defaultInspectorsListParams = {
  page: 1,
  pageSize: 50,
  select: ['name', 'companyName', 'city', 'country'],
  relations: ['profilePhoto'],
};
const getInspectors = (params) => {
  return request
    .get('/inspectors', {
      params: {
        ...defaultInspectorsListParams,
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const defaultInspectionTypesListParams = {
  select: ['name', 'type'],
  filters: { status: INSPECTION_TYPE_STATUS.ACTIVE },
};
const getInspectionTypes = (params) => {
  return request
    .get('/inspection-types', {
      params: {
        ...defaultInspectionTypesListParams,
        ...params,
        filters: {
          ...defaultInspectionTypesListParams.filters,
          ...params?.filters,
        },
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const exportInspectionReport = (file) => {
  return request.get(`/inspections/export/${file}`);
};

const getStatusesAndTypes = (params) => {
  return request
    .get('/inspections/inspection-filter-options', params)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const defaultInspectionPlansListParams = {
  select: [
    'name',
    'description',
    'publishedAt',
    'useAqlLevel',
    'aqlLevel',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'sampleQty',
    'samplesRule',
    'status',
  ],
};
const getInspectionPlans = (params) => {
  return request
    .get('/inspection-plans', {
      params: {
        ...defaultInspectionPlansListParams,
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getInspectionPlanOptions = () => {
  return request.get('/inspection-plan-options');
};

const defaultSourcesListParams = {
  select: ['name', 'status'],
  relations: ['location', 'type', 'images'],
  filters: { status: 'active' },
};
const getSources = (params) => {
  return request.get('/sources', {
    params: queryParamsToGETParams({
      ...defaultSourcesListParams,
      ...params,
    }),
  });
};

const defaultAssetsListParams = {
  select: ['name', 'showId', 'externalId'],
  relations: ['images', 'type', 'status'],
  filters: { status: 'active' },
};
const getAssets = (params) => {
  return request
    .get('/assets', {
      params: queryParamsToGETParams({
        ...defaultAssetsListParams,
        ...params,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const saveInspection = (inspection) => {
  const query = inspection.id
    ? `/inspections/${inspection.id}`
    : '/inspections';
  const method = inspection.id ? 'put' : 'post';
  const data = inspection.id
    ? {
      ...formStatetoPOSTParams(inspection),
      ...updateDeleteParams(inspection),
    }
    : { ...formStatetoPOSTParams(inspection) };

  return request[method](query, data)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processInspectionFormAPIError(
              e.response.data.details,
              inspection,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveExternalInspection = (inspection) => {
  const query = inspection.id
    ? `/inspections/third-party/${inspection.id}`
    : '/inspections/third-party';
  const method = inspection.id ? 'put' : 'post';
  const data = thirdPartyFormStateToPOSTParams(inspection);

  return request[method](query, data)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processThirdPartyInspectionFormAPIError(
              e.response.data.details,
              inspection,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};
const saveAttachedExternalInspection = ({
  inspection,
  partialId,
  stepId,
  order,
}) => {
  const query = `/work-objects/partial/${partialId}/add-external-inspection`;
  const data = thirdPartyFormStateToPOSTParams(inspection);

  return request
    .post(query, { ...data, stepId, order })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processThirdPartyInspectionFormAPIError(
              e.response.data.details,
              inspection,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const saveExternalInspectionReport = (id, report, name) => {
  const formData = new FormData();
  formData.append('file', report);
  formData.append('name', name);
  return request
    .post(`/inspections/third-party/${id}/upload-external-reports`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .catch((e) => {
      log('There was an error in uploading the report', e);
      return Promise.reject({
        payload: { errors: ['There was an error in uploading the report'] },
      });
    });
};

const saveExternalInspectionDocument = (id, document, name) => {
  const formData = new FormData();
  formData.append('file', document);
  formData.append('name', name);
  return request
    .post(
      `/inspections/third-party/${id}/upload-reference-documents`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    .catch((e) => {
      log(`There was an error in uploading document '${name}'`, e);
      return Promise.reject({
        payload: {
          errors: [`There was an error in uploading document '${name}'`],
        },
      });
    });
};

const saveAttachedInspection = ({ inspection, partialId, stepId, order }) => {
  return request
    .post(`/work-objects/partial/${partialId}/add-inspection`, {
      ...formStatetoPOSTParams(inspection),
      stepId,
      order,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      if (e.response.data.errorCode === 'entity_body_001') {
        return Promise.reject({
          payload: processInspectionFormAPIError(
            e.response.data.details,
            inspection,
          ),
        });
      }
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const defaultInspectionsListParams = {
  select: [
    'assetId',
    'asset.externalId',
    'asset.name',
    'sourceId',
    'source.name',
    'source.externalId',
    'inspectionType.name',
    'inspector.name',
    'status',
    'result',
    'statusOrResult',
    'inspectionDate',
    'finishedAt',
    'currentDecisionPoint',
    'isThirdParty',
    'inspectorType',
    'inspectionWindowStartDate',
    'inspectionWindowEndDate',
    'thirdPartyInspectorName',
    'thirdPartyInspectingCompany',
    'workObjectExternalId',
    'workObjectDeadline',
    'lastUsageDecision',
    'parentInspectionId',
  ],
  relations: [
    'asset',
    'source',
    'locationSource',
    'inspectionType',
    'inspector.profilePhoto',
  ],
  filters: {
    type: '',
    inspectionTypeId: '',
    status: '',
    inspectorId: '',
  },
};

const getInspections = (params) => {
  log('Attempting to fetch inspections with params', params);

  return request
    .get('/inspections', {
      params: queryParamsToGETParams({
        ...defaultInspectionsListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspections successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspections for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultSourceCityFilterOption = {
  select: ['name'],
  filters: { atInspectionSource: true },
};

const getSourceCityFilterOptions = (params) => {
  return request
    .get('/source-cities', {
      params: queryParamsToGETParams({
        ...defaultSourceCityFilterOption,
        ...params,
      }),
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getLastUsageDecisionFilterOptions = (params) => {
  const defaultLastUsageDecisionFilterOption = {};

  return request
    .get('inspections/taken-decisions', {
      params: queryParamsToGETParams({
        ...defaultLastUsageDecisionFilterOption,
        ...params,
      }),
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getAssignedInspectors = (params) => {
  const defaultInspectorsParams = {
    relations: ['profilePhoto'],
    order: { name: 'DESC' },
    select: [
      'id',
      'name',
      'companyName',
      'status',
      'companyPosition',
      'city',
      'country',
    ],
    filters: { assignedToInspections: true },
  };
  return request.get('/inspectors', {
    params: queryParamsToGETParams({
      ...defaultInspectorsParams,
      ...params,
    }),
  });
};

const getAssignedInspectionTypes = (params) => {
  const defaultInspectionTypes = {
    order: { name: 'DESC' },
    select: ['name'],
  };

  return request
    .get('/inspections/assigned-inspection-types', {
      params: queryParamsToGETParams({
        ...defaultInspectionTypes,
        ...params,
      }),
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getInspection = (id, params) => {
  const defaultInspectionsDetailParams = {
    select: [
      'id',
      'parentInspectionId',
      'inspectionDate',
      'inspectionWindowStartDate',
      'inspectionWindowEndDate',
      'finishedAt',
      'externalId',
      'showId',
      'criticalDefectsCountAll',
      'majorDefectsCountAll',
      'minorDefectsCountAll',
      'functionalDefectsCountAll',
      'status',
      'result',
      'workObjectId',
      'summary',
      'validUntil',
      'validityValue',
      'validityRange',
      'isThirdParty',
      'inspectorType',
      'thirdPartyInspectorName',
      'thirdPartyInspectingCompany',
    ],
    relations: [
      'asset.images',
      'asset.customMeasurements',
      'source',
      'locationSource',
      'inspectionPlan',
      'inspectionType',
      'inspector.profilePhoto',
      'actualDecisions',
      'validityInspection',
      'externalReports',
      'referenceDocuments',
      'stepPair.partialStep.partialWorkObject.parentWorkObject.vendor',
    ],
  };
  return request
    .get(`/inspections/${id}`, {
      params: {
        ...defaultInspectionsDetailParams,
        ...params,
      },
    })
    .catch((e) => {
      log('Could not load inspection', e);
      return Promise.reject({
        payload: {
          errors: ['An error has occured while loading inspection'],
        },
      });
    });
};
const getWorkObject = (id, params) => {
  const defaultWorkObjectDetailParams = {
    select: ['id'],
    relations: [
      'asset',
      'source',
      'asset.images',
      'partialWorkObjects',
      'workObjectSteps',
      'originalWorkflow',
    ],
  };
  return request
    .get(`/work-objects/${id}`, {
      params: {
        ...defaultWorkObjectDetailParams,
        ...params,
      },
    })
    .catch((e) => {
      log('Could not load work object', e);
      return Promise.reject({
        payload: {
          errors: ['An error has occured while loading work object'],
        },
      });
    });
};

const saveInspectorAndDate = (inspectionID, data) => {
  return request.put(`/inspections/${inspectionID}`, data).catch(() => {
    return Promise.reject({
      payload: {
        errors: [
          'An error has occured while performing this operation. Please try again',
        ],
      },
    });
  });
};

const cancelInspection = (inspectionID, notes) => {
  return request
    .post(`/inspections/${inspectionID}/cancel`, {
      note: notes,
    })
    .catch(() => {
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const bulkUpdateInspections = (data) => {
  return request.put(`/inspections/bulk`, data).catch(() => {
    return Promise.reject({
      payload: {
        errors: [
          'An error has occured while performing this operation. Please try again',
        ],
      },
    });
  });
};

const startInspection = (id) => {
  return request.post(`/inspections/${id}/start`).catch((e) => {
    return Promise.reject({
      payload: {
        errors: [
          'An error has occured while starting the inspection. Please try again',
        ],
        apiErrors: e.response.data,
      },
    });
  });
};

const finishInspection = (state) => {
  return request
    .post(
      `/inspections/${state.inspection.id}/finish`,
      internalInspectionToPOSTState(state),
    )
    .catch((e) => {
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while finishing the inspection. Please try again',
          ],
          apiErrors: e.response.data,
        },
      });
    });
};

const saveInspectionPhoto = (inspectionId, questionId, img) => {
  const formData = new FormData();
  formData.append('file', img);
  return request
    .post(
      `/inspections/${inspectionId}/question/${questionId}/upload-photo`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    .catch((e) =>
      Promise.reject({
        payload: {
          errors: ['Could not upload answer photo'],
          apiErrors: e.response.data,
        },
      }),
    );
};

const saveInspectionDocument = (inspectionId, questionId, name, doc) => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('file', doc);
  return request
    .post(
      `/inspections/${inspectionId}/question/${questionId}/upload-document`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    .catch((e) =>
      Promise.reject({
        payload: {
          errors: ['Could not upload answer document'],
          apiErrors: e.response.data,
        },
      }),
    );
};

const getReport = (id, format) => {
  return request.get(`/inspections/${id}/${format}`);
};

const getCAP = (id) => {
  return request.get(
    `/inspections/export/corrective-action-plan-report/${id}`,
    {
      responseType: 'arraybuffer',
    },
  );
};

const getSourceFilterOptions = () => {
  return request.get('/sources', {
    params: { select: ['name', 'externalId'] },
  });
};

const performInspectionAction = (action, notes = '') => {
  log('Attempting to save inspection action.');

  return request
    .post(
      `/inspections/${action?.inspectionId}/take-decision/${action?.id}`,
      {
        notes,
      },
      {
        params: action.isOverrideUsageDecision
          ? {
            inspectionTypeDecisionId: action?.id,
          }
          : undefined,
      },
    )
    .then((response) => {
      log('Inspection action successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in saving inspection action',
        e.response.data.message,
      );
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processInspectionFormAPIError(
            e.response.data.details,
            action,
          );
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const performSurveyInspection = (inspectionPlanId) => {
  return request
    .post(`/inspections/survey`,
      {
        inspectionPlan: {
          id: inspectionPlanId
        }
      })
    .catch((e) => {
      log('Could not get Survey Inspection', e);
      return Promise.reject({
        payload: {
          errors: ['An error has occured while getting Survey Inspection'],
        },
      });
    });
};

const inspectionsService = {
  getInspectionPlans,
  getInspectors,
  getSources,
  getAssets,
  saveInspection,
  saveAttachedInspection,
  saveAttachedExternalInspection,
  getInspections,
  getAssignedInspectors,
  getStatusesAndTypes,
  getInspectionTypes,
  getSourceFilterOptions,
  getSourceCityFilterOptions,
  getLastUsageDecisionFilterOptions,
  getAssignedInspectionTypes,
  getInspection,
  cancelInspection,
  getWorkObject,
  saveInspectorAndDate,
  bulkUpdateInspections,
  startInspection,
  finishInspection,
  saveInspectionPhoto,
  saveInspectionDocument,
  getInspectionPlanOptions,
  getReport,
  getCAP,
  performInspectionAction,
  saveExternalInspection,
  saveExternalInspectionReport,
  saveExternalInspectionDocument,
  exportInspectionReport,
  performSurveyInspection,
};

export default inspectionsService;
