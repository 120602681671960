import { useEffect, useState } from 'react';
import request from 'lib/request';

let cache;
let isBusy = false;
let onHoldRequests = [];

function setBusy(value, err) {
  isBusy = value;
  if (isBusy) {
    return;
  }

  if (cache) {
    onHoldRequests.forEach((req) => req.resolve(cache));
  } else {
    onHoldRequests.forEach((req) => req.reject(err));
  }

  onHoldRequests = [];
}

function getLanguages() {
  return new Promise((resolve, reject) => {
    if (cache) {
      resolve(cache);
    } else if (isBusy) {
      onHoldRequests.push({ resolve, reject });
    } else {
      setBusy(true);
      request
        .get('/settings/languages')
        .then(({ data }) => {
          cache = data;
          resolve(cache);
          setBusy(false);
        })
        .catch((err) => {
          reject(err);
          setBusy(false, err);
        });
    }
  });
}

export default function useLanguages() {
  const [languages, setLanguages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getLanguages()
      .then((data) => {
        setLanguages(data);
      })
      .catch((error) => {
        setLanguages([]);
        setError(error);
      });
  }, []);

  return { languages, error };
}
