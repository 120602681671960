import ROLES from 'config/roles';
import AUTH_EVENTS from 'events/auth-events';
import appBus from './appBus';
import makeLogger from './makeLogger';
import request from './request';

const log = makeLogger('authService');

const defaultRolesParams = {
  select: ['role'],
};

const fetchUserAndLogin = ({ token }) => {
  return request
    .get('/users/me', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((user) => {
      const payload = {
        currentUser: {
          ...user,
        },
        company: null,
        token,
      };

      // Automatically log in to default company if user is not QM Admin
      if (!user.roles?.some((role) => role.id === ROLES.QM_ADMIN)) {
        // TODO: Implement a better way to select the default company
        // once the BE adds this functionality
        const defaultCompany = user.companies?.[0];
        if (!defaultCompany) {
          throw new Error('User has no default company!');
        }
        payload.company = defaultCompany;
      }

      appBus.pub(AUTH_EVENTS.USER_LOGGED_IN, payload);
      return token;
    });
};

const login = (credentials) => {
  log('Attempting to log in with email:', credentials.email);

  return request
    .post('/users/login', credentials, { makeUnsigned: true })
    .then(fetchUserAndLogin)
    .catch((e) => {
      log('Error while logging in:', e.response.data.message);
      switch (e.response.data.errorCode) {
        case 'user_invalid_credentials':
        case 'entity_body_001':
          return Promise.reject({
            payload: {
              formErrors: ['Invalid email and password combination.'],
            },
          });
        case 'user_auth_rate_limit':
          return Promise.reject({
            payload: {
              formErrors: [
                'You have tried to sign in too many times. You will be able to try again in a few moments.',
              ],
            },
          });
        case 'user_unauthorized': {
          if (!!e.response.data.isMaintenanceMode) {
            return Promise.reject({
              payload: {
                formErrors: [
                  'The system is currently under maintenance. Please try again later.',
                ],
              },
            });
          }
        }
        default:
          return Promise.reject({
            payload: {
              formErrors: ['There was an error while trying to log you in.'],
            },
          });
      }
    });
};

const resetPassword = (email) => {
  log('Attempting to reset password for email:', email);

  return request
    .post('/users/reset-password', { email })
    .then(() => {
      return {
        payload: {
          messages: ['Email sent, check your inbox.'],
          formErrors: [],
        },
      };
    })
    .catch((e) => {
      log('Error while resetting password:', e.response.data.message);

      switch (e.response.data.errorCode) {
        case 'user_auth_rate_limit':
          return Promise.reject({
            payload: {
              messages: [],
              formErrors: [
                'You have tried to reset your password too many times. You will be able to try again in a few moments.',
              ],
            },
          });
        default:
          return Promise.reject({
            payload: {
              messages: [],
              formErrors: [
                'There was an error while trying to reset your password.',
              ],
            },
          });
      }
    });
};

const setPassword = (credentials, token) => {
  log('Attempting to set password for token:', token);

  return request
    .post('/users/set-password', credentials, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(fetchUserAndLogin)
    .catch((e) => {
      log('Error while setting password:', e.response.data.message);

      switch (e.response.data.errorCode) {
        // TODO treat error messages for setting password
        default:
          return Promise.reject({
            payload: {
              formErrors: [
                'There was an error while trying to reset your password.',
              ],
            },
          });
      }
    });
};

const logout = () => {
  log('User logging out');
  appBus.pub(AUTH_EVENTS.USER_LOGGED_OUT);
};

const getRoles = () => {
  log('Attempting to fetch user roles.');

  return request
    .get('/auth-roles', { params: defaultRolesParams })
    .then((response) => {
      log('Roles successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching roles', e);
      return Promise.reject(e);
    });
};

const authService = {
  login,
  logout,
  resetPassword,
  setPassword,
  getRoles,
};

export default authService;
