
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import inspectionPlansService from 'modules/inspection-plans/inspectionPlansService';
import './InspectionPlansBulkResultsPage.scss';
import InspectionPlansBulkResultsPagePanel from './InspectionPlansBulkResultsPagePanel';
import ErrorBag from 'lib/components/error-bag/ErrorBag';

const transformErrors = (errList) => {
  const output = [];

  errList.forEach((errItem) => {
    // TODO: Switch to checking type, not message
    if (errItem?.type === 'config_error') {
      if (errItem?.errors?.length) {
        errItem?.errors?.forEach((errSubItem) => {
          if (errSubItem?.message === 'Validation error') {
            if (errSubItem?.data?.length) {
              errSubItem?.data?.forEach((valErr) => {
                if (valErr?.message?.length) {
                  output.push(valErr?.message);
                } else {
                  console.error("Unhandled validation error", errItem);
                }
              })
            } else {
              output.push('Validation error.');
            }
          } else if ([
            'Invalid Asset Inspection Types', 'Invalid Source Inspection Types'
          ].includes(errSubItem?.message)) {
            if (errSubItem?.data?.length) {
              output.push(`${errSubItem?.message}: ${errSubItem.data.join(', ')}`);
            } else {
              output.push(errSubItem?.message);
            }
          } else if (['Invalid Company Attributes', 'Invalid Custom Attributes'
          ].includes(errSubItem?.message)) {
            let errMsg = `${errSubItem?.message}`;
            const attrMsgList = [];
            const optsMsgList = [];

            errSubItem?.data?.attributes?.forEach((attr) => { if (attr?.length) attrMsgList.push(attr) });

            if (errSubItem?.data?.options) {
              Object.keys(errSubItem?.data?.options)?.forEach(optKey => {
                if (errSubItem?.data?.options[optKey]?.length)
                  optsMsgList.push(`${optKey}(${errSubItem?.data?.options[optKey]?.join(', ')})`);
              });
            }

            if (attrMsgList?.length || optsMsgList?.length) {
              if (attrMsgList?.length) errMsg += `. Attributes: ${attrMsgList.join(', ')}`
              if (optsMsgList?.length) errMsg += `. Options: ${optsMsgList.join(', ')}`
            }

            output.push(errMsg);
          } else if (errSubItem?.message?.length) {
            output.push(errSubItem?.message);
          } else {
            console.error("Unhandled error", errItem);
          }
        });
      } else {
        output.push('Configuration error.');
      }
    }
  });

  return output;
}

const decorateResponseData = (resData, errData) => {
  const filtErrData = errData?.filter(v => isFinite(v?.bulkConfigIndex) && !['user_plan_exists'].includes(v.type));

  if (resData?.length) {
    resData.forEach((resItem) => {
      if (isFinite(resItem.bulkConfigIndex)) {
        const foundErrs = filtErrData?.filter((errItem) => errItem.bulkConfigIndex === resItem.bulkConfigIndex);
        resItem.errors = transformErrors(foundErrs);
      }
    });
  }

  return resData;
}

const InspectionPlansBulkResultsPage = () => {
  const [ipbJobStatusData, setIpbJobStatusData] = useState([]);
  const [globalErrors, setGlobalErrors] = useState([]);

  useEffect(() => {
    inspectionPlansService
      .getAutoBuildProgress()
      .then((res) => {
        setIpbJobStatusData(decorateResponseData(res?.response, res?.errors));

        if (res?.globalError?.length) {
          setGlobalErrors([res.globalError]);
        } else {
          setGlobalErrors([]);
        }

        if (!res?.response) {
          console.error('Empty IPB status response object', res.response);
        }
      })
      .catch((e) => {
        console.error('Error while retrieving IPB status response objcet', e);
        setIpbJobStatusData([]);
        setGlobalErrors([]);
      });
  }, []);

  return (
    <div className='inspection-plans-bulk-results-page container-m'>
      <div className='row'>
        <h2>Inspection plans</h2>
      </div>

      <ErrorBag errors={globalErrors ?? []} />
      {ipbJobStatusData?.length ? (
        <>
          {ipbJobStatusData?.map((item, i) => (

            <div key={i} className={
              classNames('ipb-bulk-results-item',
                {
                  "is-single": ipbJobStatusData.length === 1
                    && [null, undefined].includes(item?.bulkConfigIndex)
                })}>
              <h3>Batch Configuration {(item?.bulkConfigIndex ?? 0) + 1}</h3>

              <ErrorBag errors={item?.errors ?? []} />
              <InspectionPlansBulkResultsPagePanel ipbJobStatusDataItem={item} />
            </div>
          ))}
        </>
      ) : (
        <p className='empty-plan-list'>No results data available.</p>
      )}
    </div>
  );
};

export default InspectionPlansBulkResultsPage;
