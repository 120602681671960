import * as yup from 'yup';
import validator from 'lib/validator';

export const newPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=^\S*$)/,
      'Password has to be 8 characters long, have at least one upper case letter, at least one number, and no spaces',
    ),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const processNewPasswordError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    acc[curr.path] = {
      value: input[curr.path] || '',
      errors: curr.errors,
    };

    return acc;
  }, {});
};

const newPasswordValidator = (credentials) =>
  validator(credentials, newPasswordValidationSchema, processNewPasswordError);

export default newPasswordValidator;
