import React from 'react';
import classNames from 'classnames';
import './Button.scss';

const Button = React.forwardRef(({ className, ...props }, ref) => {
  const classes = classNames('qm-button', className);

  return (
    <button className={classes} {...props} ref={ref}>
      {props.children}
      {!!props.isLoading && <span className='loading' />}
    </button>
  );
});

export default Button;
