import * as yup from 'yup';
import validator, {
  inputShape,
  qmTagsSchema,
  requiredFormObject,
  requiredFormString,
} from 'lib/validator';
import _set from 'lodash.set';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';
import { API_ERROR_CODES } from 'config/errorCodes';
import { id } from 'lib/funcHelpers';

export const sourcesFormValidationSchema = yup.object().shape({
  name: yup.mixed().when('$inputPermissions.name', {
    is: true,
    then: requiredFormString(),
    otherwise: yup.mixed().transform(() => undefined),
  }),
  externalId: yup.mixed().when('$inputPermissions.externalId', {
    is: true,
    then: inputShape(),
    otherwise: yup.mixed().transform(() => undefined),
  }),
  type: yup.mixed().when('$inputPermissions.type', {
    is: true,
    then: inputShape(),
    otherwise: yup.mixed().transform(() => undefined),
  }),
  status: yup.mixed().when('$inputPermissions.status', {
    is: true,
    then: inputShape(),
    otherwise: yup.mixed().transform(() => undefined),
  }),
  address: requiredFormString(),
  city: requiredFormString(),
  country: requiredFormObject(),
  tags: yup.mixed().when('$inputPermissions.tags', {
    is: true,
    then: inputShape(qmTagsSchema),
    otherwise: yup.mixed().transform(() => undefined),
  }),
  owners: yup.mixed().when('$inputPermissions.owners', {
    is: true,
    then: yup.array(),
    otherwise: yup.mixed().transform(() => undefined),
  }),
});

const apiPathToFormPath = (path) => path.replace(/location./, '');

const sourcesErrors = {
  ...API_ERROR_CODES,
  'source.invalidRole': 'This user is no longer a source owner',
};

export const processAPIError = makeAPIBodyErrorProcessor({
  transformPath: apiPathToFormPath,
  errorCodes: sourcesErrors,
});

const processSourcesFormError = (e, input) => {
  e.inner.reduce((acc, curr) => {
    curr.path = curr.path.split('.')[0];
    _set(acc, curr.path, {
      value: curr.params.originalValue,
      errors: curr.errors,
    });
    return acc;
  }, input);
};

const sourcesFormValidator = (state, context) =>
  validator(state, sourcesFormValidationSchema, processSourcesFormError, id, {
    context,
  });

export default sourcesFormValidator;
