import { useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';
import { format } from 'date-fns';

import ROLES from 'config/roles';
import INSPECTION_STATUS from 'config/inspectionStatus';

import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryParams from 'lib/useQueryParams';
import useRoles from 'lib/useRoles';
import { downloadResponse } from 'lib/download';

import { FILTER_ACTIONS, PAGE_ACTIONS } from './inspectionsListReducer';
import { reducer, initialState } from './inspectionsListReducer';
import inspectionsService from './inspectionsService';
import { ASSIGN_INSPECTOR_ROLES } from './inspectionsPermissions';
import {
  getIsInspectionSelectable,
  listToBulkInspectorPOSTState,
} from './dataTransform';

const setFilters = (params) => {
  let filters = { ...params.filters };
  if (params?.byAssetId) {
    filters = { ...filters, byAssetId: params.byAssetId };
  }
  return filters;
};

const fetchInspections = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });
  const filters = setFilters(params);
  return inspectionsService
    .getInspections({ ...params, filters })
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: results,
      });
      return results;
    })
    .catch(() => {
      dispatch({
        type: PAGE_ACTIONS.SET_ERRORS,
        payload: { errors: ['An error occured while loading inspections.'] },
      });
    })
    .finally(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const fetchInspectors = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getInspectors(params)
      .then((response) => {
        dispatch({
          type: PAGE_ACTIONS.APP_LOADS_INSPECTORS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: PAGE_ACTIONS.SET_ERRORS,
          payload: { errors: ['An error occured while loading inspectors.'] },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const fetchInspectionsFilters = (dispatch, promises) => {
  return Promise.allSettled(promises).then((results) => {
    dispatch({
      type: FILTER_ACTIONS.LOAD_FILTERS,
      payload: results
        .filter((r) => r.status === 'fulfilled')
        .reduce(
          (filters, { value }) => ({
            ...filters,
            ...value,
          }),
          {},
        ),
    });
  });
};

// eslint-disable-next-line max-lines-per-function
const useInspectionsList = ({ location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setParams, getParams } = useQueryParams(location, history);

  const { isAllowed, currentUserRoles } = useRoles();

  const isTableSelectable = isAllowed(ASSIGN_INSPECTOR_ROLES);

  const showFilter = {
    inspector: isAllowed([
      ROLES.CLIENT_ADMIN,
      ROLES.QM_ADMIN,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.COORDINATOR,
      ROLES.EXTERNAL_SOURCE_OWNER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.VENDOR,
    ]),
    type: !currentUserRoles.includes(ROLES.INSPECTOR),
    target: isAllowed([
      ROLES.COORDINATOR,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.CLIENT_ADMIN,
      ROLES.QM_ADMIN,
    ]),
    city: isAllowed([
      ROLES.COORDINATOR,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.CLIENT_ADMIN,
      ROLES.QM_ADMIN,
    ]),
    atSource: true,
    inspectionDate: true,
    inspectionWindow: true,
    lastUsageDecision: isAllowed([
      ROLES.COORDINATOR,
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
      ROLES.INTERNAL_SOURCE_OWNER,
      ROLES.ASSET_OWNER,
      ROLES.CLIENT_ADMIN,
      ROLES.QM_ADMIN,
    ]),
  };

  useEffect(() => {
    const {
      target,
      inspectionTypeId,
      status,
      inspectorId,
      atSource,
      inspectionDateStart,
      inspectionDateEnd,
      inspectionWindowStart,
      inspectionWindowEnd,
      validUntil,
      city,
      lastUsageDecision,
      sortOrder,
      sortBy,
      isThirdParty,
      ...queryParams
    } = getParams();

    const defaultFilter = {
      inspector: '',
      status: '',
      inspection: '',
      atSource: '',
      target: '',
      city: '',
      lastUsageDecision: '',
      isThirdParty: '',
    };

    const defaultSort = {
      order: 'DESC',
      column: 'id',
    };

    // Set default filters if none selected
    // XXX: The above rule needs a workaround in case we need to redirect here
    // with the inspectors' filter turned off. The _all keyword is immediately removed
    // on load.
    if (inspectorId === '_all') {
      setParams({ inspectorId: null });
      defaultFilter.inspector = '';
    } else if (
      !inspectorId &&
      isAllowed([ROLES.COORDINATOR, ROLES.ASSET_MANAGER, ROLES.SOURCE_MANAGER])
    ) {
      setParams({ inspectorId: 'unassigned' });
      defaultFilter.inspector = 'unassigned';
    }

    if (!status && isAllowed([ROLES.INSPECTOR])) {
      setParams({ status: INSPECTION_STATUS.PUBLISHED });
      defaultFilter.status = INSPECTION_STATUS.PUBLISHED;
    }

    if (!status && isAllowed([ROLES.ASSET_OWNER])) {
      setParams({ status: INSPECTION_STATUS.AWAITING_DECISION });
      defaultFilter.status = INSPECTION_STATUS.AWAITING_DECISION;
    }

    if (!sortOrder && !sortBy) {
      if (isAllowed([ROLES.ASSET_OWNER])) {
        defaultSort.column = 'lastInspectionDate';
        defaultSort.order = 'DESC';
      }
      setParams({
        sortBy: defaultSort.column,
        sortOrder: defaultSort.order,
        page: 1,
      });
    }

    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: {
        ...queryParams,
        filters: {
          inspector: inspectorId || defaultFilter.inspector,
          inspection: inspectionTypeId || defaultFilter.inspection,
          atSource: atSource || defaultFilter.atSource,
          target: target || defaultFilter.target,
          status: status || defaultFilter.status,
          city: city || defaultFilter.city,
          inspectionTypeId,
          inspectorId,
          inspectionDateStart,
          inspectionDateEnd,
          inspectionWindowStart,
          inspectionWindowEnd,
          validUntil,
          isThirdParty,
          lastUsageDecision:
            lastUsageDecision || defaultFilter.lastUsageDecision,
        },
        sortOrder: sortOrder || defaultSort.order,
        sortBy: sortBy || defaultSort.column,
      },
    });

    const filterRequests = [
      inspectionsService.getStatusesAndTypes().then((res) => ({
        statuses: res.status,
        types: res.type,
      })),
      inspectionsService
        .getAssignedInspectionTypes()
        .then((res) => ({ inspections: res.data })),
    ];

    if (showFilter.inspector) {
      filterRequests.push(
        inspectionsService
          .getAssignedInspectors()
          .then((res) => ({ inspectors: res.data })),
      );
    } else {
      filterRequests.push(Promise.resolve({ inspectors: [] }));
    }

    if (showFilter.atSource) {
      filterRequests.push(
        inspectionsService.getSourceFilterOptions().then((res) => ({
          atSource: res.data,
        })),
      );
    } else {
      filterRequests.push(Promise.resolve({ atSource: [] }));
    }

    if (showFilter.city) {
      filterRequests.push(
        inspectionsService.getSourceCityFilterOptions().then((res) => ({
          city: res.data,
        })),
      );
    } else {
      filterRequests.push(Promise.resolve({ city: [] }));
    }

    if (showFilter.lastUsageDecision) {
      filterRequests.push(
        inspectionsService.getLastUsageDecisionFilterOptions().then((res) => {
          return {
            lastUsageDecision: res,
          };
        }),
      );
    } else {
      filterRequests.push(Promise.resolve({ lastUsageDecision: [] }));
    }

    filterRequests.push(
      Promise.resolve({
        isThirdParty: [
          {
            value: 'true',
            label: 'Third party',
          },
          {
            value: 'false',
            label: 'Internal',
          },
        ],
      }),
    );

    fetchInspectionsFilters(dispatch, filterRequests);
  }, []);

  const inspectionDateFilter =
    state.filters.inspectionDateStart && state.filters.inspectionDateEnd
      ? {
        inspectionDateStart: state.filters.inspectionDateStart,
        inspectionDateEnd: state.filters.inspectionDateEnd,
      }
      : {};
  const inspectionWindowFilter =
    state.filters.inspectionWindowStart && state.filters.inspectionWindowEnd
      ? {
        inspectionWindowStartDate: state.filters.inspectionWindowStart,
        inspectionWindowEndDate: state.filters.inspectionWindowEnd,
      }
      : {};

  const getInspectorIdFilter = () => {
    if (state.filters.inspector === 'unassigned') return null;
    if (state.filters.inspector === '_all') return '';
    return state.filters.inspector;
  };

  const fetchInspectionsParams = {
    filters: {
      type: state.filters.target,
      inspectionTypeId: state.filters.inspection,
      status: state.filters.status,
      atSource: state.filters.atSource,
      isThirdParty: state.filters.isThirdParty,
      atSourceCity: state.filters.city,
      lastUsageDecision: state.filters.lastUsageDecision,
      inspectorId: getInspectorIdFilter(),
      ...inspectionDateFilter,
      ...inspectionWindowFilter,
      validUntil: state.filters.validUntil || undefined,
    },
    search: state.search,
    sortBy: state.sortBy,
    sortOrder: state.sortOrder,
    page: state.page,
    pageSize: state.pageSize,
  };

  const showColumns = [];
  if (isAllowed([ROLES.ASSET_OWNER])) {
    showColumns.push('decision-point');
  }

  if (
    /* state.filters.inspectionWindowStart &&
    state.filters.inspectionWindowEnd */ true
  ) {
    showColumns.push('inspection-window');
  }

  useEffect(() => {
    fetchInspections({ ...getParams(), ...fetchInspectionsParams }, dispatch);
  }, [
    state.filters,
    state.search,
    state.sortBy,
    state.sortOrder,
    state.page,
    state.pageSize,
  ]);

  useEffect(() => {
    const { isModalOpen, search, sortBy, sortOrder, pageSize, page } =
      state.availableInspectors;

    if (isModalOpen) {
      const sources = new Set(state.selectedInspections.map((i) => i.sourceId));
      dispatch({
        type: PAGE_ACTIONS.APP_STARTS_LOADING_INSPECTORS,
      });
      fetchInspectors(
        {
          search,
          pageSize,
          page,
          order: {
            [sortBy]: sortOrder,
          },
          filters: {
            withAssignedLoad: state.availableInspectors.date || undefined,
            status: 'active',
            sourceIds: sources.size ? Array.from(sources) : undefined,
          },
        },
        dispatch,
      );
    }
  }, [
    state.availableInspectors.search,
    state.availableInspectors.page,
    state.availableInspectors.sortBy,
    state.availableInspectors.sortOrder,
    state.availableInspectors.isModalOpen,
  ]);

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  const setTarget = (type) => {
    setParams({ target: type, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_TYPE_FILTER,
      payload: type,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSource = (source) => {
    setParams({ atSource: source, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_SOURCE_FILTER,
      payload: source,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setInspectionDateFilter = (range) => {
    const [startDate, endDate] = range;
    setParams({
      inspectionDateStart: startDate,
      inspectionDateEnd: endDate,
      page: 1,
    });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_INSPECTION_DATE_FILTER,
      payload: range,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setInspectionWindowFilter = (range) => {
    const [startDate, endDate] = range;
    setParams({
      inspectionWindowStart: startDate,
      inspectionWindowEnd: endDate,
      page: 1,
    });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_INSPECTION_WINDOW_FILTER,
      payload: range,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setInspection = (inspection) => {
    setParams({ inspectionTypeId: inspection, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_INSPECTION_FILTER,
      payload: inspection,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setInspector = (inspector) => {
    setParams({ inspectorId: inspector, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_INSPECTOR_FILTER,
      payload: inspector,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setStatus = (status) => {
    setParams({ status, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_STATUS_FILTER,
      payload: status,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setCity = (city) => {
    setParams({ city, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_CITY_FILTER,
      payload: city,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setLastUsageDecision = (lastUsageDecision) => {
    setParams({ lastUsageDecision, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_LAST_USAGE_DECISION_FILTER,
      payload: lastUsageDecision,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setIsThirdParty = (isThirdParty) => {
    setParams({ isThirdParty, page: 1 });

    dispatch({
      type: FILTER_ACTIONS.USER_SETS_IS_THIRD_PARTY_FILTER,
      payload: isThirdParty,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const userClicksOnRow = (record) => {
    history.push(
      `/inspections/${record.id}${record.status === INSPECTION_STATUS.FINISHED && !record.isThirdParty
        ? '/results'
        : ''
      }`,
    );
  };

  const userChecksRow = (record) => {
    return dispatch({
      type: PAGE_ACTIONS.USER_TOGGLES_INSPECTION,
      payload: {
        ...record,
        sourceId: record.locationSource?.id || record.source?.id,
      },
    });
  };

  const userChecksHeader = () => {
    const disabledInspections = getDisabledInspectionIds();
    const isAllSelected =
      state.selectedInspections.length ===
      (state.data || []).length - disabledInspections.length;
    return dispatch({
      type: PAGE_ACTIONS.USER_TOGGLES_INSPECTIONS_HEADER,
      payload: isAllSelected
        ? []
        : state.data
          .filter((inspection) =>
            getIsInspectionSelectable(inspection, currentUserRoles),
          )
          .map((record) => ({
            ...record,
            sourceId: record.locationSource?.id || record.source?.id,
          })),
    });
  };

  const userClicksCancelSelect = () => {
    return dispatch({
      type: PAGE_ACTIONS.USER_TOGGLES_INSPECTIONS_HEADER,
      payload: [],
    });
  };

  const getDefaultSource = () =>
    state.filterOptions.atSource.find(
      (option) => option.value === state.filters.atSource,
    );
  const getDefaultTarget = () =>
    state.filterOptions.target.find(
      (option) => option.value === state.filters.target,
    );
  const getDefaultInspection = () =>
    state.filterOptions.inspection.find(
      (option) => option.value === state.filters.inspection,
    );
  const getDefaultInspector = () =>
    state.filterOptions.inspector.find(
      (option) => option.value === state.filters.inspector,
    );
  const getDefaultStatus = () =>
    state.filterOptions.status.find(
      (option) => option.value === state.filters.status,
    );
  const getDefaultCity = () =>
    state.filterOptions.city.find(
      (option) => option.value === state.filters.city,
    );
  const getDefaultLastUsageDecision = () =>
    state.filterOptions.lastUsageDecision.find(
      (option) => option.value === state.filters.lastUsageDecision,
    );
  const getDefaultIsThirdParty = () =>
    state.filterOptions.isThirdParty.find(
      (option) => option.value === state.filters.isThirdParty,
    );
  /* INSPECTORS MODAL */
  const userOpensInspectorsModal = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_INSPECTORS_MODAL,
    });
  };
  const userSearchesInspectors = (ev) => {
    dispatch({
      type: PAGE_ACTIONS.USER_SEARCHES_INSPECTORS,
      payload: ev.target.value,
    });
  };

  const userSelectsInspector = (inspector) => {
    dispatch({
      type: PAGE_ACTIONS.USER_SELECTS_INSPECTOR,
      payload: inspector,
    });
  };

  const userSortsInspectors = (col, order) => {
    dispatch({
      type: PAGE_ACTIONS.USER_SORTS_INSPECTORS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSetsInspectorsModalPage = (page) => {
    dispatch({
      type: PAGE_ACTIONS.USER_SETS_INSPECTORS_PAGE,
      payload: page,
    });
  };

  const userCancelsInspectorsModal = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_CANCELS_INSPECTORS_MODAL,
    });
  };
  const userClicksAssignInspector = () => {
    inspectionsService
      .bulkUpdateInspections(listToBulkInspectorPOSTState(state))
      .then(() => {
        dispatch({
          type: PAGE_ACTIONS.RESET_BULK_UPDATE_RESULT_MODAL,
          payload: {
            isOpen: true,
            selectedInspector: state.availableInspectors.selectedInspector,
            selectedInspections: state.selectedInspections,
          },
        });
        fetchInspections(fetchInspectionsParams, dispatch);
      })
      .catch((e) => {
        dispatch({
          type: PAGE_ACTIONS.SET_ERRORS,
          payload: e.payload,
        });
      });
  };
  /* END INSPECTORS MODAL */

  const userChangesInspectionDate = (date) => {
    dispatch({
      type: PAGE_ACTIONS.USER_CHANGES_INSPECTION_DATE,
      payload: date,
    });
  };
  const userOpensDateModal = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_DATE_MODAL,
    });
  };
  const userClosesDateModal = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_CANCELS_DATE_MODAL,
    });
  };
  const userAssignsDate = () => {
    inspectionsService
      .bulkUpdateInspections(listToBulkInspectorPOSTState(state))
      .then(() => {
        dispatch({
          type: PAGE_ACTIONS.RESET_BULK_UPDATE_RESULT_MODAL,
          payload: {
            isOpen: true,
            inspectionDate: state.inspectionDate,
            selectedInspections: state.selectedInspections,
          },
        });
        fetchInspections(fetchInspectionsParams, dispatch);
      })
      .catch((e) => {
        dispatch({
          type: PAGE_ACTIONS.SET_ERRORS,
          payload: e.payload,
        });
      });
  };

  const userClicksExportResults = () => {
    dispatch({
      type: PAGE_ACTIONS.APP_STARTS_EXPORTING_QUESTIONS,
    });
    Promise.all(
      ['reports', 'question-groups', 'questions', 'defects', 'assets'].map(
        (file) =>
          inspectionsService
            .exportInspectionReport(file)
            .then((data) => {
              const date = new Date();
              downloadResponse(
                data,
                `${file}-${format(date, 'yyyy-MM-dd')}.csv`,
              );
            })
            .catch((err) => {
              console.error(err);
            }),
      ),
    ).finally(() =>
      dispatch({
        type: PAGE_ACTIONS.APP_FINISHES_EXPORTING_QUESTIONS,
      }),
    );
  };

  const getDisabledInspectionIds = () =>
    (state.data || [])
      .filter((rec) => !getIsInspectionSelectable(rec, currentUserRoles))
      .map((rec) => rec.id);

  const resetBulkUpdateResultModal = () => {
    dispatch({
      type: PAGE_ACTIONS.RESET_BULK_UPDATE_RESULT_MODAL,
    });
  };

  return {
    state,
    dispatch,
    sortBy,
    setPageSize,
    setPage,
    setSearch,
    setParams,

    showFilter,
    showColumns,
    setSource,
    setTarget,
    setInspection,
    setInspector,
    setStatus,
    setCity,
    setLastUsageDecision,
    setIsThirdParty,
    setInspectionDateFilter,
    setInspectionWindowFilter,

    userClicksOnRow,
    userChecksRow,
    userChecksHeader,
    userOpensInspectorsModal,
    userClicksCancelSelect,

    userSearchesInspectors,
    userSortsInspectors,
    userSelectsInspector,
    userCancelsInspectorsModal,
    userSetsInspectorsModalPage,
    userClicksAssignInspector,
    userClicksExportResults,

    userChangesInspectionDate,
    userOpensDateModal,
    userClosesDateModal,
    userAssignsDate,

    isTableSelectable,
    getDisabledInspectionIds,
    getDefaultSource,
    getDefaultTarget,
    getDefaultInspection,
    getDefaultInspector,
    getDefaultStatus,
    getDefaultCity,
    getDefaultLastUsageDecision,
    getDefaultIsThirdParty,

    resetBulkUpdateResultModal,
  };
};

export default useInspectionsList;
