import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import SearchInput from 'lib/components/search-input/SearchInput';

import { formatDate } from 'lib/dateHelpers';

import './InspectionPlansModal.scss';
import Pagination from 'lib/components/pagination/Pagination';

const inspectorsTableColumns = [
  {
    title: 'Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Description',
    className: 'description-cell',
    render: (record) => <p>{record.description}</p>,
  },
  {
    title: 'AQL / Date published',
    className: 'aql-cell',
    render: (record) => (
      <div className='aql-container'>
        {record.useAqlLevel && (
          <div>
            {record.aqlLevel} / {record.majorDefect} / {record.minorDefect}
          </div>
        )}
        {record.publishedAt && <div>{formatDate(record.publishedAt)}</div>}
      </div>
    ),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const InspectionPlansModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  sortBy,
  page,
  pageSize,
  setPage,
  count,
  ...props
}) => {
  const hasPagination = setPage && pageSize && page && count > 0;
  return (
    <Modal
      title='Select inspection plan'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name or ID...'
            data-testid='InspectionPlansModal.search'
          />
        </div>
      }
      actions={
        hasPagination && (
          <Pagination
            setPage={setPage}
            page={page}
            threshold={3}
            pageSize={pageSize}
            count={count}
          />
        )
      }
      className='inspection-plans-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      data-testid='InspectionPlansModal.dialog'
      {...props}
    >
      <Table
        data={data}
        columns={inspectorsTableColumns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </Modal>
  );
};

export default InspectionPlansModal;
