import { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';

import Checkbox from 'lib/components/checkbox/Checkbox';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import Select from 'lib/components/select/Select';
import Switch from 'lib/components/switch/Switch';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';

import TaggableEntity from '../taggable-entity/TaggableEntity';

import { ReactComponent as HomeOutline } from 'assets/images/home-outline.svg';
import { ReactComponent as ClipboardOutline } from 'assets/images/clipboard-outline.svg';
import { ReactComponent as UserOutline } from 'assets/images/user-outline.svg';

import './TagMatchingPage.scss';

const MATCHING_ALGOS = {
  any: (a, b) =>
    !a.tags.length ||
    !b.tags.length ||
    a.tags.some((tag) => b.tags.includes(tag)),
  exact: (a, b) => a.tags.sort().join(',') === b.tags.sort().join(','),
  subset: (a, b, s) =>
    (s ? a.tags : b.tags).every((tag) => (s ? b.tags : a.tags).includes(tag)),
};

const DEFAULT_TAGS = ['GH Office', 'SZ Office', 'Team A', 'Team B'];

const MATCHING_OPTIONS = [
  {
    label: 'Any match',
    value: 'any',
  },
  {
    label: 'Exact match',
    value: 'exact',
  },
  {
    label: 'Subset',
    value: 'subset',
  },
];

const TYPE_OPTIONS = [
  {
    label: 'Source',
    value: 'source',
  },
  {
    label: 'Coordinator',
    value: 'coordinator',
  },
  {
    label: 'Inspector',
    value: 'inspector',
  },
  {
    label: 'Vendor',
    value: 'vendor',
  },
];

const initialEntities = [
  {
    id: 1,
    type: 'source',
    tags: [DEFAULT_TAGS[0]],
    posX: 50,
    posY: 20,
  },
  {
    id: 2,
    type: 'source',
    tags: [DEFAULT_TAGS[0]],
    posX: 100,
    posY: 20,
  },
  {
    id: 3,
    type: 'source',
    tags: [DEFAULT_TAGS[1]],
    posX: 350,
    posY: 20,
  },
  {
    id: 4,
    type: 'source',
    tags: [DEFAULT_TAGS[1]],
    posX: 400,
    posY: 20,
  },
  {
    id: 5,
    type: 'coordinator',
    tags: [DEFAULT_TAGS[0]],
    posX: 50,
    posY: 100,
  },
  {
    id: 6,
    type: 'coordinator',
    tags: [DEFAULT_TAGS[0]],
    posX: 100,
    posY: 100,
  },
  {
    id: 7,
    type: 'coordinator',
    tags: [DEFAULT_TAGS[1]],
    posX: 350,
    posY: 100,
  },
  {
    id: 8,
    type: 'coordinator',
    tags: [DEFAULT_TAGS[1]],
    posX: 400,
    posY: 100,
  },
  {
    id: 9,
    type: 'inspector',
    tags: [DEFAULT_TAGS[0]],
    posX: 150,
    posY: 200,
  },
  {
    id: 10,
    type: 'inspector',
    tags: [DEFAULT_TAGS[0]],
    posX: 200,
    posY: 200,
  },
  {
    id: 11,
    type: 'inspector',
    tags: [DEFAULT_TAGS[1]],
    posX: 250,
    posY: 200,
  },
  {
    id: 12,
    type: 'inspector',
    tags: [DEFAULT_TAGS[1]],
    posX: 300,
    posY: 200,
  },
  {
    id: 13,
    type: 'vendor',
    tags: [DEFAULT_TAGS[0]],
    posX: 75,
    posY: -45,
  },
  {
    id: 14,
    type: 'vendor',
    tags: [DEFAULT_TAGS[1]],
    posX: 375,
    posY: -45,
  },
];

/*
 * WARNING!
 * DO NOT ATTEMPT TO REPLICATE __ANYTHING__ FROM THIS PAGE.
 * This is a slow experiment and one-time visual aid built
 * in a very short amount of time, for the specific purpose
 * of helping choose the proper tag matching algorithm for Exacture.
 *
 * If this needs to be integrated in the main application flow,
 * please follow the proper development steps.
 */
export default function TagMatchingPage() {
  const [displaySources, setDisplaySources] = useState(true);
  const [displayCoordinators, setDisplayCoordinators] = useState(true);
  const [displayInspectors, setDisplayInspectors] = useState(true);
  const [displayVendors, setDisplayVendors] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [tagOptions, setTagOptions] = useState(DEFAULT_TAGS);
  const [entities, setEntities] = useState(initialEntities);
  const [tab, setTab] = useState('settings');
  const [viewBox, setViewBox] = useState([-50, -100]);

  const [isDragging, setIsDragging] = useState(false);
  const [dragState, setDragState] = useState({
    prevX: 0,
    prevY: 0,
    elemId: null,
  });
  const drawFrameRef = useRef(null);

  const [coordSrcAlgo, setCoordSrcAlgo] = useState('any');
  const [vendorSrcAlgo, setVendorSrcAlgo] = useState('any');
  const [inspSrcAlgo, setInspSrcAlgo] = useState('any');
  const [coordInspAlgo, setCoordInspAlgo] = useState('any');

  const [roleSwitch, setRoleSwitch] = useState({
    coordSrc: false,
    inspSrc: false,
    vendorSrc: false,
    coordInsp: false,
  });

  const sources = entities.filter((e) => e.type === 'source');
  const coordinators = entities.filter((e) => e.type === 'coordinator');
  const inspectors = entities.filter((e) => e.type === 'inspector');
  const vendors = entities.filter((e) => e.type === 'vendor');

  const selectedEntityIdx = entities.findIndex((ent) => ent.id === selectedId);
  const selectedEntity = entities[selectedEntityIdx];

  const onEntityClick = useCallback((entityId) => {
    setTab('entity');
    setSelectedId(entityId);
  });

  const userChangesEntityType = (type) => {
    const newEntities = cloneDeep(entities);
    newEntities[selectedEntityIdx].type = type.value;
    setEntities(newEntities);
  };

  const userSetsTags = (tags) => {
    const newTags = tags.filter((t) => t.__isNew__);
    if (newTags.length) {
      setTagOptions([...tagOptions, ...tags.map((tag) => tag.label)]);
    }

    const newEntities = cloneDeep(entities);
    newEntities[selectedEntityIdx].tags = tags.map((tag) => tag.label);
    setEntities(newEntities);
  };

  const onMouseMove = useCallback(
    function ({ clientX, clientY }) {
      if (isDragging) {
        if (!drawFrameRef.current) {
          drawFrameRef.current = requestAnimationFrame(() => {
            const deltaX = dragState.prevX - clientX;
            const deltaY = dragState.prevY - clientY;
            if (dragState.elemId) {
              const idx = entities.findIndex(
                (e) => e.id === Number(dragState.elemId),
              );
              const newEntities = cloneDeep(entities);
              newEntities[idx] = {
                ...newEntities[idx],
                posX: newEntities[idx].posX - deltaX,
                posY: newEntities[idx].posY - deltaY,
              };
              setEntities(newEntities);
            } else {
              setViewBox([viewBox[0] + deltaX, viewBox[1] + deltaY]);
            }

            setDragState({
              elemId: dragState.elemId,
              prevX: clientX,
              prevY: clientY,
            });
            drawFrameRef.current = null;
          });
        }
      }
    },
    [isDragging, dragState, entities, viewBox],
  );

  const onSvgMouseDown = ({ clientX, clientY, target }) => {
    setDragState({
      prevX: clientX,
      prevY: clientY,
      elemId: target.parentElement?.dataset?.id ?? null,
    });
    setIsDragging(true);
  };

  const onSvgMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
    } else {
      window.removeEventListener('mousemove', onMouseMove);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isDragging]);

  const onSvgDoubleClick = (ev) => {
    const { x, y } = ev.target.getBoundingClientRect();
    const { clientX, clientY } = ev;
    const [viewboxX, viewboxY] = viewBox;
    const createX = clientX - x + viewboxX;
    const createY = clientY - y + viewboxY;
    const newEntity = {
      id: entities.reduce((max, ent) => (ent.id > max ? ent.id : max), 0) + 1,
      type: selectedEntity ? selectedEntity.type : 'source',
      posX: createX,
      posY: createY,
      tags: selectedEntity ? [...selectedEntity.tags] : [],
    };
    setEntities([...entities, newEntity]);
  };

  const deleteEntity = () => {
    const newEntities = cloneDeep(entities);
    newEntities.splice(selectedEntityIdx, 1);
    setEntities(newEntities);
  };

  return (
    <div className='page tag-matching-page'>
      <div className='container-m row fill'>
        <h2>Tag matching playground</h2>
        <div className='playground-container'>
          <div className='settings-panel'>
            <Tabs>
              <TabsItem
                onClick={() => setTab('entity')}
                active={tab === 'entity'}
              >
                Entity
              </TabsItem>
              <TabsItem
                onClick={() => setTab('settings')}
                active={tab === 'settings'}
              >
                Settings
              </TabsItem>
            </Tabs>
            {tab === 'entity' && (
              <>
                {!selectedEntity ? (
                  <p>No entity selected</p>
                ) : (
                  <div>
                    <div className='form-row'>
                      <Select
                        label='Type'
                        options={TYPE_OPTIONS}
                        value={TYPE_OPTIONS.find(
                          (opt) => opt.value === selectedEntity.type,
                        )}
                        onChange={userChangesEntityType}
                      />
                    </div>
                    <div className='form-row'>
                      <Select
                        isMulti={true}
                        isClearable={false}
                        isCreatable={true}
                        isSearchable={true}
                        value={selectedEntity.tags.map((t) => ({
                          label: t,
                          value: t,
                        }))}
                        onChange={userSetsTags}
                        placeholder={''}
                        onInputChange={(inputValue) =>
                          inputValue.length <= 50
                            ? inputValue
                            : inputValue.substr(0, 50)
                        }
                        name='tags'
                        label='Tags'
                        options={tagOptions.map((t) => ({
                          label: t,
                          value: t,
                        }))}
                      />
                    </div>
                    <div className='form-row'>
                      <DeleteButton onClick={deleteEntity}>
                        Delete entity
                      </DeleteButton>
                    </div>
                    <p className='field-description'>
                      To create a new entity, double-click anywhere on the
                      drawing.
                    </p>
                  </div>
                )}
              </>
            )}
            {tab === 'settings' && (
              <>
                <div className='form-row'>
                  <Checkbox
                    label='Display coordinators'
                    checked={displayCoordinators}
                    onChange={() =>
                      setDisplayCoordinators(!displayCoordinators)
                    }
                  />
                </div>
                <div className='form-row'>
                  <Checkbox
                    label='Display sources'
                    checked={displaySources}
                    onChange={() => setDisplaySources(!displaySources)}
                  />
                </div>
                <div className='form-row'>
                  <Checkbox
                    label='Display inspectors'
                    checked={displayInspectors}
                    onChange={() => setDisplayInspectors(!displayInspectors)}
                  />
                </div>
                <div className='form-row'>
                  <Checkbox
                    label='Display vendors'
                    checked={displayVendors}
                    onChange={() => setDisplayVendors(!displayVendors)}
                  />
                </div>
                <div className='form-row'>
                  <div className='form-label'>Coordinator - Source</div>
                  <div className='flex'>
                    <Select
                      options={MATCHING_OPTIONS}
                      value={MATCHING_OPTIONS.find(
                        (o) => o.value === coordSrcAlgo,
                      )}
                      onChange={(val) => setCoordSrcAlgo(val.value)}
                    />
                    {coordSrcAlgo === 'subset' && (
                      <Switch
                        checked={roleSwitch['coordSrc']}
                        onChange={() =>
                          setRoleSwitch({
                            ...roleSwitch,
                            coordSrc: !roleSwitch['coordSrc'],
                          })
                        }
                        offLabel={<UserOutline />}
                        onLabel={<HomeOutline />}
                      />
                    )}
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-label'>Inspector - Source</div>
                  <div className='flex'>
                    <Select
                      options={MATCHING_OPTIONS}
                      value={MATCHING_OPTIONS.find(
                        (o) => o.value === inspSrcAlgo,
                      )}
                      onChange={(val) => setInspSrcAlgo(val.value)}
                    />
                    {inspSrcAlgo === 'subset' && (
                      <Switch
                        checked={roleSwitch['inspSrc']}
                        onChange={() =>
                          setRoleSwitch({
                            ...roleSwitch,
                            inspSrc: !roleSwitch['inspSrc'],
                          })
                        }
                        offLabel={<ClipboardOutline />}
                        onLabel={<HomeOutline />}
                      />
                    )}
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-label'>Coordinator - Inspector</div>
                  <div className='flex'>
                    <Select
                      options={MATCHING_OPTIONS}
                      value={MATCHING_OPTIONS.find(
                        (o) => o.value === coordInspAlgo,
                      )}
                      onChange={(val) => setCoordInspAlgo(val.value)}
                    />
                    {coordInspAlgo === 'subset' && (
                      <Switch
                        checked={roleSwitch['coordInsp']}
                        onChange={() =>
                          setRoleSwitch({
                            ...roleSwitch,
                            coordInsp: !roleSwitch['coordInsp'],
                          })
                        }
                        offLabel={<UserOutline />}
                        onLabel={<ClipboardOutline />}
                      />
                    )}
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-label'>Vendor - Source</div>
                  <div className='flex'>
                    <Select
                      options={MATCHING_OPTIONS}
                      value={MATCHING_OPTIONS.find(
                        (o) => o.value === vendorSrcAlgo,
                      )}
                      onChange={(val) => setVendorSrcAlgo(val.value)}
                    />
                    {vendorSrcAlgo === 'subset' && (
                      <Switch
                        checked={roleSwitch['vendorSrc']}
                        onChange={() =>
                          setRoleSwitch({
                            ...roleSwitch,
                            vendorSrc: !roleSwitch['vendorSrc'],
                          })
                        }
                        offLabel={<ClipboardOutline />}
                        onLabel={<HomeOutline />}
                      />
                    )}
                  </div>
                </div>
                {[
                  coordInspAlgo,
                  inspSrcAlgo,
                  coordSrcAlgo,
                  vendorSrcAlgo,
                ].includes('subset') && (
                  <p className='field-description'>
                    For the subset algorithm, the checked entity can have more
                    tags than its counterpart
                  </p>
                )}
              </>
            )}
          </div>
          <div className='whiteboard-container'>
            <svg
              viewBox={`${viewBox[0]} ${viewBox[1]} 1000 1000`}
              width='1000'
              height='1000'
              onDoubleClick={onSvgDoubleClick}
              onMouseDown={onSvgMouseDown}
              onMouseUp={onSvgMouseUp}
              className='whiteboard'
            >
              {displaySources &&
                displayCoordinators &&
                coordinators.map((coord) =>
                  sources.map((src) =>
                    MATCHING_ALGOS[coordSrcAlgo](
                      coord,
                      src,
                      roleSwitch['coordSrc'],
                    ) ? (
                      <line
                        key={`coord-src-${coord.id}-${src.id}`}
                        x1={src.posX}
                        x2={coord.posX}
                        y1={src.posY}
                        y2={coord.posY}
                        strokeWidth={
                          [coord.id, src.id].includes(selectedEntity?.id)
                            ? 3
                            : 1
                        }
                        className='coordinator-source-link'
                      />
                    ) : null,
                  ),
                )}
              {displayCoordinators &&
                displayInspectors &&
                coordinators.map((coord) =>
                  inspectors.map((insp) =>
                    MATCHING_ALGOS[coordInspAlgo](
                      coord,
                      insp,
                      roleSwitch['coordInsp'],
                    ) ? (
                      <line
                        key={`coord-insp-${coord.id}-${insp.id}`}
                        x1={insp.posX}
                        x2={coord.posX}
                        y1={insp.posY}
                        y2={coord.posY}
                        strokeWidth={
                          [coord.id, insp.id].includes(selectedEntity?.id)
                            ? 3
                            : 1
                        }
                        className='coordinator-inspector-link'
                      />
                    ) : null,
                  ),
                )}
              {displaySources &&
                displayInspectors &&
                inspectors.map((insp) =>
                  sources.map((src) =>
                    MATCHING_ALGOS[inspSrcAlgo](
                      insp,
                      src,
                      roleSwitch['inspSrc'],
                    ) ? (
                      <line
                        key={`insp-src-${insp.id}-${src.id}`}
                        x1={src.posX}
                        x2={insp.posX}
                        y1={src.posY}
                        y2={insp.posY}
                        strokeWidth={
                          [insp.id, src.id].includes(selectedEntity?.id) ? 3 : 1
                        }
                        className='inspector-source-link'
                      />
                    ) : null,
                  ),
                )}
              {displaySources &&
                displayVendors &&
                vendors.map((vendor) =>
                  sources.map((src) =>
                    MATCHING_ALGOS[vendorSrcAlgo](
                      vendor,
                      src,
                      roleSwitch['vendorSrc'],
                    ) ? (
                      <line
                        key={`vendor-src-${vendor.id}-${src.id}`}
                        x1={src.posX}
                        x2={vendor.posX}
                        y1={src.posY}
                        y2={vendor.posY}
                        strokeWidth={
                          [vendor.id, src.id].includes(selectedEntity?.id)
                            ? 3
                            : 1
                        }
                        className='vendor-source-link'
                      />
                    ) : null,
                  ),
                )}

              {entities
                .filter(
                  (entity) =>
                    (displayCoordinators && entity.type === 'coordinator') ||
                    (displayInspectors && entity.type === 'inspector') ||
                    (displaySources && entity.type === 'source') ||
                    (displayVendors && entity.type === 'vendor'),
                )
                .map((entity) => (
                  <TaggableEntity
                    key={entity.id}
                    {...entity}
                    selectedId={selectedId}
                    onClick={onEntityClick}
                    onDragStart={(ev) => {
                      console.log(ev);
                    }}
                    onDragEnd={(ev) => {
                      console.log(ev);
                    }}
                  />
                ))}
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
