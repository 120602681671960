import { RESULT_STATUS } from 'modules/inspection-plans/inspectionPlansBulkResultsReducer';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';
import { ReactComponent as CloseCircleSolid } from 'assets/images/remove.svg';
import { ReactComponent as WarningCircleSolid } from 'assets/images/warning-circle-solid.svg';
import { ReactComponent as WarningCircleOutline } from 'assets/images/warning-circle-outline.svg';

const STATUS_ICON = {
  [RESULT_STATUS.SUCCESS]: <CheckCircleSolid />,
  [RESULT_STATUS.FAILED]: <CloseCircleSolid />,
  [RESULT_STATUS.ERROR]: <CloseCircleSolid />,
  [RESULT_STATUS.SKIPPED]: <WarningCircleOutline />,
  [RESULT_STATUS.NO_TAGS]: <WarningCircleOutline />,
  [RESULT_STATUS.AUTO_PLAN]: <WarningCircleOutline />,
  [RESULT_STATUS.USER_PLAN]: <WarningCircleOutline />,
  [RESULT_STATUS.WRONG_TYPE]: <WarningCircleOutline />,
  [RESULT_STATUS.NEEDS_REVIEW]: <WarningCircleOutline />,
  [RESULT_STATUS.UNKNOWN]: <WarningCircleSolid />,
};

export default STATUS_ICON;
