import Table from 'lib/components/table/Table';
import { formatDate } from 'lib/dateHelpers';
import CompaniesStatusPill from '../companies-status-pill/CompaniesStatusPill';
import './CompaniesTable.scss';

const companiesListColumns = [
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
  {
    title: 'Company',
    sortId: 'name',
    className: 'name-cell',
    render: (record) => record.name,
  },
  {
    title: 'Email',
    className: 'email-cell',
    sortId: 'emails.email',
    render: (record) => record['emails.email'],
  },
  {
    title: 'Phone',
    className: 'phone-cell',
    sortId: 'phones.phone',
    render: (record) => record['phones.phone'],
  },
  {
    title: 'Created',
    className: 'created-cell',
    sortId: 'createdAt',
    render: (record) => formatDate(record.createdAt),
  },
  {
    title: 'Status',
    className: 'status-cell',
    textAlign: 'right',
    render: (record) => (
      <span className='pill-container'>
        <CompaniesStatusPill status={record.status} />
      </span>
    ),
  },
];

const CompaniesTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={companiesListColumns}
    onRowClick={(record) =>
      typeof userClicksOnRow === 'function'
        ? userClicksOnRow(record.id)
        : undefined
    }
    className='companies-table'
    {...props}
  />
);

export default CompaniesTable;
