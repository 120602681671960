import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import { reducer, initialState } from './workflowsListReducer';
import workflowsService from './workflowsService';
import debounce from 'lodash.debounce';

const fetchWorkflows = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return workflowsService
    .getWorkflows(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useWorkflowsList = ({ location, history }) => {
  const list = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchWorkflows,
  );

  const userClicksOnRow = (id) => {
    history.push(`/workflows/${id}`);
  };

  return { ...list, userClicksOnRow };
};

export default useWorkflowsList;
