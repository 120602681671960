import InspectionTypesForm from '../inspection-types-form/InspectionTypesForm';

import './InspectionTypesEditPage.scss';

const InspectionTypesEditPage = (props) => {
  return (
    <div
      className='inspection-types-edit-page container-m'
      data-testid='InspectionTypesEditPage.container'
    >
      <div className='row'>
        <h1>Edit Inspection Type</h1>
      </div>
      <div className='row'>
        <InspectionTypesForm {...props} />
      </div>
    </div>
  );
};

export default InspectionTypesEditPage;
