import classNames from 'classnames';
import Button from 'lib/components/button/Button';
import { useState } from 'react';
import './InspectionUsageDecisionsOverride.scss';

const InspectionUsageDecisionsOverride = ({ inspection, actions }) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);

  const generateUsageDecisionsTree = () => {
    const output = {
      fail: [],
      pass: [],
      group: [],
    };

    inspection?.inspectionType?.usageDecisions?.forEach((ud) => {
      const dt = ud?.decisionType;

      if (['fail', 'pass'].includes(dt)) {
        output[dt].push(ud);
      } else if (dt === 'group' && Number.isFinite(ud.groupNumber)) {
        if (!output.group[ud.groupNumber]?.length) {
          output.group[ud.groupNumber] = [];
        }
        output.group[ud.groupNumber].push(ud);
      }
    });

    return output;
  };

  const udTree = generateUsageDecisionsTree();

  return (
    <div className='inspection-usage-decisions-override'>
      <Button
        className='medium-button'
        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
      >
        {isSectionExpanded ? 'Show' : 'Hide'} Override Decision
      </Button>
      {isSectionExpanded && (
        <>
          {!!udTree?.pass?.length && (
            <div className='ud-group ud-group-pass'>
              <h5>Inspection pass usage decisions:</h5>
              <div className='ud-group-actions-buttons'>
                {udTree.pass.map((ud, udIdx) => (
                  <Button
                    key={udIdx}
                    className='medium-button'
                    onClick={() =>
                      actions.userTakesInspectionAction({
                        ...ud,
                        inspectionId: inspection.id,
                        isOverrideUsageDecision: true,
                      })
                    }
                  >
                    {ud.name}
                  </Button>
                ))}
              </div>
            </div>
          )}
          {!!udTree?.fail?.length && (
            <div className='ud-group ud-group-fail'>
              <h5>Inspection fail usage decisions:</h5>
              <div className='ud-group-actions-buttons'>
                {udTree.fail.map((ud, udIdx) => (
                  <Button
                    key={udIdx}
                    className='medium-button'
                    onClick={() =>
                      actions.userTakesInspectionAction({
                        ...ud,
                        inspectionId: inspection.id,
                        isOverrideUsageDecision: true,
                      })
                    }
                  >
                    {ud.name}
                  </Button>
                ))}
              </div>
            </div>
          )}

          {udTree?.group
            ?.filter((udg) => udg?.length)
            ?.map((udg, udgIdx) => (
              <div
                className={classNames(
                  'ud-group',
                  'ud-group-group',
                  `ud-group-group-${udgIdx}`,
                )}
              >
                <h5>Decision group {udgIdx + 1}:</h5>
                <div className='ud-group-actions-buttons'>
                  {udg.map((ud, udIdx) => (
                    <Button
                      key={udIdx}
                      className='medium-button'
                      onClick={() =>
                        actions.userTakesInspectionAction({
                          ...ud,
                          inspectionId: inspection.id,
                          isOverrideUsageDecision: true,
                        })
                      }
                    >
                      {ud.name}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default InspectionUsageDecisionsOverride;
