import { id } from 'lib/funcHelpers';
import { useReducer } from 'react';
import authService from '../../lib/authService';

import resetPasswordReducer, {
  INITIAL_RESET_PASSWORD_STATE,
  RESET_PASSWORD_ACTIONS,
} from './resetPasswordReducer';

import resetPasswordValidator from './resetPasswordValidator';

function useResetPasswordForm() {
  const [state, dispatch] = useReducer(
    resetPasswordReducer,
    INITIAL_RESET_PASSWORD_STATE,
  );

  const onChangeEmail = (e) =>
    dispatch({
      type: RESET_PASSWORD_ACTIONS.USER_TYPES_EMAIL,
      payload: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: RESET_PASSWORD_ACTIONS.FORM_LOADING, payload: true });

    return resetPasswordValidator({
      email: state.email.value,
    })
      .then(({ email }) => authService.resetPassword(email))
      .then(id)
      .catch(id)
      .then(({ payload }) => {
        dispatch({
          type: RESET_PASSWORD_ACTIONS.RESET_STATE,
          payload: payload,
        });
        dispatch({ type: RESET_PASSWORD_ACTIONS.FORM_LOADING, payload: false });
      });
  };

  return { state, onChangeEmail, onSubmit };
}

export default useResetPasswordForm;
