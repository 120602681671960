import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';

import DeleteIcon from 'assets/images/trash.png';

const InspectionTypesDecisionItem = (props) => {
  return (
    <div
      className='table-row'
      data-testid='InspectionTypesDecisionItem.container'
    >
      <div className='table-cell'>
        {props.isDefaultDecision ? (
          <span className='default-value'>{props.name.value}</span>
        ) : (
          <div className='cell-container'>
            <Input
              name={`decisionName-${props.name.index}`}
              type='text'
              value={props.name.value}
              errors={props.name.errors}
              autoComplete='off'
              onChange={props.name.onChange}
              charsLeft={props.name.charsLeft}
              data-testid={`InspectionTypesDecisionItem.decisionName${props.name.index}`}
            />
          </div>
        )}
      </div>
      <div className='table-cell'>
        {props.isDefaultDecision ? (
          <span className='default-value'>{props.criteria.value.label}</span>
        ) : (
          <div className='cell-container'>
            <Select
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              value={props.criteria.value}
              onChange={props.criteria.onChange}
              placeholder={''}
              name={`criteria-${props.name.index}`}
              options={props.criteria.options}
              errors={props.criteria.errors}
              data-testid={`InspectionTypesDecisionItem.criteria${props.name.index}`}
            />
          </div>
        )}
      </div>
      <div className='table-cell'>
        <div className='cell-container'>
          <Select
            isMulti={false}
            isClearable={false}
            isSearchable={true}
            value={props.action.value}
            onChange={props.action.onChange}
            placeholder={''}
            name={`action-${props.name.index}`}
            options={props.action.options}
            errors={props.action.errors}
            data-testid={`InspectionTypesDecisionItem.action${props.name.index}`}
          />
        </div>
      </div>
      <div className='table-cell notify-cell'>
        <div className='cell-container notify'>
          <Select
            isMulti={true}
            isClearable={false}
            isSearchable={false}
            value={props.notifyTo.value}
            onChange={props.notifyTo.onChange}
            placeholder={''}
            name={`notifyTo-${props.name.index}`}
            options={props.notifyTo.options}
            errors={props.notifyTo.errors}
            data-testid={`InspectionTypesDecisionItem.notifyTo${props.name.index}`}
          />
        </div>
      </div>
      <div className='table-cell delete-cell'>
        {!props.isDefaultDecision && props.showDeleteButton && (
          <button
            type='button'
            className='secondary trash-button'
            onClick={props.onDelete}
            data-testid={`InspectionTypesDecisionItem.deleteButton${props.name.index}`}
          >
            <img src={DeleteIcon} alt='delete' />
          </button>
        )}
      </div>
    </div>
  );
};

InspectionTypesDecisionItem.defaultProps = {
  showDeleteButton: true,
};

export default InspectionTypesDecisionItem;
