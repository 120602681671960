import ROLES from 'config/roles';

export const ADD_PLANS_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const REVIEW_PLANS_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

/* BE filters inspection plans returning inspection plans associated to
 inspector, source owner, internal source and asset owner */
export const VIEW_PLANS_ROLES = [
  ...ADD_PLANS_ROLES,
  ROLES.INSPECTOR,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.ASSET_OWNER,
];

export const OVERRIDE_PLANS_BULK_CREATION = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
];
