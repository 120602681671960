import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import SearchInput from 'lib/components/search-input/SearchInput';

import './WorkflowsModal.scss';
import Pagination from '../pagination/Pagination';

const workflowsTableColumns = [
  {
    title: 'Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Source',
    className: 'location-cell',
    sortId: 'defaultSource',
    render: (record) => (
      <>
        {record.defaultSource}
        <br />
        <span className='subtitle'>{record?.sourceLocation || ''}</span>
      </>
    ),
  },
  {
    title: 'Destination',
    className: 'location-cell',
    sortId: 'defaultDestination',
    render: (record) => (
      <>
        {record.defaultDestination}
        <br />
        <span className='subtitle'>{record?.destinationLocation || ''}</span>
      </>
    ),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const WorkflowsModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  sortBy,
  page,
  setPage,
  pageSize,
  count,
  ...props
}) => {
  const hasPagination = setPage && page && pageSize && count > 0;
  return (
    <Modal
      title='Select workflow'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search'
            data-testid='WorkflowsModal.search'
          />
        </div>
      }
      actions={
        hasPagination && (
          <Pagination
            pageSize={pageSize}
            setPage={setPage}
            page={page}
            count={count}
            threshold={3}
          />
        )
      }
      className='workflows-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <Table
        data={data}
        columns={workflowsTableColumns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </Modal>
  );
};

export default WorkflowsModal;
