import Button from 'lib/components/button/Button';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import { VIEW_SETTINGS_ROLES } from 'modules/settings/settingsPermissions';
import useSettingsSourceTypesList from '../../useSettingsSourceTypesList';
import SettingsSourceTypesInputModal from '../settings-source-types-input-modal/SettingsSourceTypesInputModal';
import SettingsSourceTypesTable from '../settings-source-types-table/SettingsSourceTypesTable';
import './SettingsSourceTypesListPage.scss';

export default function SettingsSourceTypesListPage(props) {
  const list = useSettingsSourceTypesList(props);

  return (
    <div className='settings-source-types-list-page'>
      <div className='settings-header'>
        <h4>Source types</h4>
      </div>
      <p className='form-info'>
        The list of types that can be selected for each source displayed here.
      </p>
      <div className='row difect-filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='SettingsSourceTypesListPage.search'
          />
        </div>
        <ProtectedComponent allowed={VIEW_SETTINGS_ROLES}>
          <Button
            data-testid='SettingsSourceTypesListPage.addNewDefect'
            className='medium-button'
            onClick={list.userOpensInputModal}
          >
            Add source type
          </Button>
        </ProtectedComponent>
      </div>
      <div className='settings-source-types-table'>
        <SettingsSourceTypesTable
          editField={list.state.editField}
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          sortBy={list.state.sortBy}
          sortOrder={list.state.sortOrder}
          setSortBy={list.sortBy}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setEdit={list.userSelectsEditSourceType}
          clearEdit={list.userClearsEditSourceType}
          onChange={list.userTypesSourceTypeName}
          editSourceType={list.editSubmitsEditSourceType}
        />
      </div>
      <SettingsSourceTypesInputModal
        addType={list.state.addSourceType}
        isOpen={list.state.addSourceType.isModalOpen}
        onChange={list.userTypesNewSourceTypeLabel}
        onRequestClose={list.userCancelsInputModal}
        onSubmit={list.userSubmitsNewSourceType}
      />
    </div>
  );
}
