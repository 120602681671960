/* eslint-disable no-unused-vars */
import { AppContext } from 'App';
import { useContext, useEffect, useState } from 'react';
import useQueryParams from 'lib/useQueryParams';

import inspectionPlansService from './inspectionPlansService';
import {
  inspectionPlanToDetailState,
  optionsToFormState,
  questionOptionsToFormState,
} from './dataTransform';

const planParams = {};

export default function useInspectionPlanPrint(props) {
  const [planOptions, setPlanOptions] = useState(null);
  const [questionOptions, setQuestionOptions] = useState(null);
  const [errors, setErrors] = useState(false);
  const [plan, setPlan] = useState(null);

  const { getParams } = useQueryParams(props.location);
  const id = props.match?.params?.id;
  const { debug, language = 'en' } = getParams();

  const {
    appState: { company },
  } = useContext(AppContext);

  const noPrint = !!debug;

  useEffect(() => {
    inspectionPlansService
      .getInspectionPlanOptions()
      .then((options) => {
        setPlanOptions(optionsToFormState(options));
        setQuestionOptions(questionOptionsToFormState(options));
      })
      .catch(() => {
        setErrors(['Could not load inspection plan options']);
      });
  }, []);

  useEffect(() => {
    if (planOptions && questionOptions && id) {
      inspectionPlansService
        .getInspectionPlan(id, planParams)
        .then((plan) => {
          setPlan(
            inspectionPlanToDetailState(plan, planOptions, questionOptions),
          );
        })
        .catch(() => {
          setErrors(['Could not load inspection plan']);
        });
    }
  }, [planOptions, questionOptions, id]);

  const onAfterPrint = () => {
    props.history.push(`/inspection-plans/${plan.id}`);
  };

  useEffect(() => {
    if (!noPrint) {
      window.addEventListener('afterprint', onAfterPrint);
    }

    if (plan && !noPrint) {
      window.print();
      onAfterPrint();
    }

    return () => {
      if (!noPrint) {
        window.removeEventListener('afterprint', onAfterPrint);
      }
    };
  }, [plan, noPrint]);

  return {
    company,
    planOptions,
    plan,
    language,
    errors,
  };
}
