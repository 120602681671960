const testId = {
  loading: 'PerformInspectionPage.loading',
  container: 'PerformInspectionPage.container',
  startButton: 'performInspectionPage.startButton',
  answerGroup: 'performInspectionPage.answerGroup',
  answerForm: 'AnswerForm.container',
  refDoc: 'AnswerForm.referenceDocument',
  summary: 'PerformInspectionPage.summary',
};

export default testId;
