import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'lib/components/select/Select';
import Pagination from '../pagination/Pagination';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import './Table.scss';

export const HeaderSortButton = ({
  label,
  sortId,
  sortOrder,
  setSortBy,
  sortBy,
}) => {
  const labels = !Array.isArray(label) ? [label] : label;
  const sortIds = !Array.isArray(sortId) ? [sortId] : sortId;
  const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
  const isSortingAvailable = sortOrder && sortBy && setSortBy;

  const icon =
    sortOrder === 'ASC' ? (
      <FontAwesomeIcon icon={faArrowUp} />
    ) : (
      <FontAwesomeIcon icon={faArrowDown} />
    );

  return labels.map((label, idx) =>
    isSortingAvailable && !!sortIds?.[idx] ? (
      <div
        className='header-button'
        onClick={() => setSortBy(sortIds[idx], newOrder)}
        key={label}
      >
        {label}
        {sortBy === sortIds[idx] && (
          <span className={`sort-icon active`}>{icon}</span>
        )}
      </div>
    ) : (
      <span key={label} className='header-label'>
        {label}
      </span>
    ),
  );
};

const defaultPaginationOptions = [
  { value: 10, label: '10 per page' },
  { value: 50, label: '50 per page' },
  { value: 100, label: '100 per page' },
];

const Table = ({
  columns,
  data = [],
  hideColumns = [],
  showColumns = [],
  sortOrder,
  setSortBy,
  sortBy,
  pageSize,
  setPageSize,
  threshold = 7,
  page,
  setPage,
  count,
  paginationOptions = defaultPaginationOptions,
  onRowClick,
  rowKey,
  rowClassName,
  overflowX = false,
  isLoading = false,
  emptyState = null,
  actions = null,
  ...props
}) => {
  const getPageSize = () =>
    paginationOptions.find((option) => option.value === Number(pageSize));
  const isRowClickable = typeof onRowClick === 'function';
  const isPaginationAvailable = count && pageSize && page && setPage;

  const shouldRender = (column) => {
    const shouldUnhide =
      column.key && column.hideByDefault && showColumns.includes(column.key);
    const canRender =
      !column.hideByDefault && !hideColumns.includes(column.key);
    return shouldUnhide || canRender;
  };

  return (
    <>
      <div
        className={classNames('qm-table-container', {
          'overflow-x': overflowX,
        })}
      >
        {actions}
        <div className={classNames('qm-table', props.className)}>
          {!data.length && !isLoading && (
            <div className='empty-state'>{emptyState}</div>
          )}
          <div className='table-row table-header'>
            {columns.map((column, idx) =>
              shouldRender(column) ? (
                <div
                  key={`header-${idx}`}
                  className={classNames(
                    'table-cell',
                    {
                      [`align-${column.textAlign}`]: !!column.textAlign,
                    },
                    column.headerClassName,
                  )}
                >
                  <HeaderSortButton
                    label={column.title}
                    sortId={column.sortId}
                    sortOrder={sortOrder}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                  />
                </div>
              ) : null,
            )}
          </div>
          {data.map((record, rowIdx) => (
            <div
              key={rowKey?.(record) || record.id || rowIdx}
              className={classNames(
                'table-row',
                { 'table-row-clickable': isRowClickable },
                typeof rowClassName === 'function'
                  ? rowClassName(record)
                  : rowClassName,
              )}
              onClick={
                isRowClickable ? (ev) => onRowClick(record, ev) : undefined
              }
            >
              {columns.map((column, cellIdx) =>
                shouldRender(column) ? (
                  <div
                    key={`${record.id}-${cellIdx}`}
                    className={classNames('table-cell', column.className, {
                      [`align-${column.textAlign}`]: !!column.textAlign,
                    })}
                  >
                    {column.render(record, rowIdx)}
                  </div>
                ) : null,
              )}
            </div>
          ))}
        </div>
      </div>
      {!!isPaginationAvailable && (
        <div className='qm-table-controls row container-m'>
          {typeof setPageSize === 'function' && (
            <div className='page-size'>
              <label>Show:</label>
              <Select
                className='page-size-select'
                options={paginationOptions}
                value={getPageSize()}
                onChange={(option) => setPageSize(option.value)}
                isSearchable={false}
                menuPlacement='auto'
              />
            </div>
          )}
          <div className='pagination'>
            <Pagination
              pageSize={pageSize}
              setPage={setPage}
              page={page}
              count={count}
              threshold={threshold}
            />
          </div>
        </div>
      )}
    </>
  );
};

const ColumnsPropShape = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    sortId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    hideByDefault: PropTypes.bool,
    title: PropTypes.node.isRequired,
    onRowClick: PropTypes.func,
    render: PropTypes.func.isRequired,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  }),
);

const PaginationOptionsPropShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

Table.propTypes = {
  columns: ColumnsPropShape.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  sortOrder: PropTypes.oneOf(['ASC', 'DESC']),
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPageSize: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPage: PropTypes.func,
  count: PropTypes.number,
  paginationOptions: PaginationOptionsPropShape,
  onRowClick: PropTypes.func,
  rowKey: PropTypes.func,
  rowClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  showColumns: PropTypes.arrayOf(PropTypes.string),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
};

export default Table;
