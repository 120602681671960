import React from 'react';
import Button from 'lib/components/button/Button';
import { ReactComponent as ExactureLogo } from 'assets/logo/exacture-horz-white.svg';
import AuthRoutes from 'modules/auth/routes';

import './AuthPanel.scss';

const LoginPage = () => {
  return (
    <div className='auth-panel'>
      <div className='container'>
        <div className='row bg-row'>
          <ExactureLogo className='exacture-logo' />
          <div className='info'>
            <h2>Quality Management. Elevated.</h2>
            <p>
              Ready to take your company to the next
              <br /> level of quality management?
            </p>
            <div className='button-container'>
              <Button onClick={() => {}}>Schedule a demo</Button>
            </div>
          </div>
        </div>
        <div className='row content-row'>
          <AuthRoutes />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
