import classNames from 'classnames';

import useLanguages from 'lib/languageService';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';

import './LanguageSelect.scss';

export default function LanguageSelect({ language, onChange, ...props }) {
  const { languages } = useLanguages();
  return (
    <Tabs
      className={classNames('qm-language-select', props.className)}
      data-testid='LanguageSelect.container'
    >
      {languages.map((lang) => (
        <TabsItem
          key={lang.code}
          active={language === lang.code}
          onClick={() => onChange(lang.code)}
          data-testid='LanguageSelect.tabItem'
        >
          {lang.label}
        </TabsItem>
      ))}
    </Tabs>
  );
}
