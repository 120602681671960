import { DEPENDENCY } from 'config/questionOptions';
import { formatQuestionCriteria, getTranslation } from 'lib/dataTransform';

export const questionToDetailState = (question, questionOptions) => {
  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === question[questionKey],
    ) ?? null;

  return {
    ...question,
    type: optionByValue('type'),
    useAqlLevel: question.useAqlLevel ?? true,
    aqlLevel: optionByValue('aqlLevel'),
    answerType: optionByValue('answerType'),
    expectedBarcodeUOM: optionByValue('expectedBarcodeUOM'),
    answer: question.answer
      ? {
          label: question.answer?.name,
          value: question.answer?.id,
        }
      : null,
    answerOptions: (question.answer?.options || []).map((opt) => ({
      label: getTranslation(opt.label).display,
      value: opt.order,
    })),
    defects: (question.defects || []).map((defect) => ({
      ...defect,
      weight:
        (questionOptions.questionWeight || []).find(
          (o) => o.value === defect.weight,
        ) || defect.weight,
    })),
    dynamicAction: optionByValue('dynamicAction'),
    dynamicRule: optionByValue('dynamicRule'),
    dependencyAction: optionByValue('dependencyAction'),
    printOnReport: optionByValue('printOnReport'),
    expectedMeasureTableResult: optionByValue('expectedMeasureTableResult'),
    questionWeight: optionByValue('questionWeight'),
  };
};

/**
 * Format a detail question's sample size for display
 * @param {object} detailQuestion detail question object
 * @returns {string} human readable sample size
 */
export function formatDetailQuestionSampleSize(detailQuestion) {
  const {
    useAqlLevel,
    aqlLevel,
    majorDefect,
    minorDefect,
    functionalDefect,
  } = detailQuestion;
  const sampleRuleLabel = detailQuestion.sampleRule === '%' ? '%' : '';

  const aqlLvlLabel = aqlLevel?.label ?? aqlLevel;
  const majLabel = majorDefect?.label ?? majorDefect;
  const minLabel = minorDefect?.label ?? minorDefect;
  const funLabel = functionalDefect?.label ?? functionalDefect;

  const minMajLabel =
    majLabel !== null && minLabel !== null && funLabel !== null
      ? ` / ${majLabel} / ${minLabel} / ${funLabel}`
      : '';

  const aqlLabel = !!aqlLvlLabel ? `${aqlLvlLabel}${minMajLabel}` : null;

  return useAqlLevel
    ? aqlLabel ?? 'AQL'
    : `${detailQuestion.sampleQty ?? 'N/A'}${sampleRuleLabel}`;
}

export function formatQuestionId(question) {
  return `${question.id || ''}${
    question.externalId ? ` / ${question.externalId}` : ''
  }`;
}

export function formatQuestionOrder(question) {
  return `#${question.order + 1}`;
}

export function formatDependency(question) {
  // TODO: Refactor to not have the form/detail
  // representations of questions in the same functon
  const {
    dependencyQuestion: { order: dependencyOrder, answer: dependencyAnswer },
  } = question;

  const actionLabel =
    question.dependencyAction.value?.label || question.dependencyAction?.label;
  const dependencyCriteria =
    question.dependencyCriteria.value?.value || question.dependencyCriteria;

  let criteria;
  if (
    question.dependencyCriteria === DEPENDENCY.SPECIFIC_ANSWER &&
    dependencyAnswer
  ) {
    const answerOption = dependencyAnswer.options.find(
      (opt) => opt.order === question.dependencyAnswerOptionOrder,
    );
    criteria = formatQuestionCriteria(dependencyCriteria, answerOption?.label);
  } else if (typeof question.dependencyCriteria.value?.value === 'number') {
    const answerOption = question.dependencyQuestion.answerOptions.find(
      (opt) => opt.value === question.dependencyCriteria.value?.value,
    );
    criteria = formatQuestionCriteria('specific_answer', answerOption?.label);
  } else {
    criteria = formatQuestionCriteria(dependencyCriteria).toLowerCase();
  }

  return `${actionLabel} #${dependencyOrder + 1} ${criteria}`;
}

export function formatDynamicModifier(question) {
  const {
    dynamicCriteria,
    dynamicAction: { label: dynamicActionLabel },
    dynamicRule,
  } = question;
  const dynamicRuleLabel = dynamicRule.label.toLowerCase();
  const criteriaLabel =
    dynamicRule.value === 'first_inspection' ? '' : dynamicCriteria;

  return `${dynamicActionLabel} ${criteriaLabel} ${dynamicRuleLabel}`;
}

export function formatExpectedResult(question) {
  switch (question.expectedMeasureTableResult.value) {
    case 'Custom':
      return `${question.customExpectedMeasureValue} ${question.customExpectedUom?.label}`;
    case 'Other':
      return question.otherMeasure;
    default:
      return `${question.expectedMeasureTableResult.value}${
        question.expectedBarcodeUOM
          ? ` (${question.expectedBarcodeUOM.label})`
          : ''
      }`;
  }
}
