import Table from 'lib/components/table/Table';
import SettingsAssetStatusesForm from '../settings-asset-statuses-form/SettingsAssetStatusesForm';
import SettingsAssetStatusesItem from '../settings-asset-statuses-item/SettingsAssetStatusesItem';

import './SettingsAssetStatusesTable.scss';

const SettingsAssetStatusesTable = ({ userClicksOnRow, ...props }) => {
  const renderAssetStatusesCell = (record) => {
    if (props.editField?.id === record.id) {
      return (
        <SettingsAssetStatusesForm
          clearEdit={props.clearEdit}
          assetStatus={props.editField}
          originalStatus={record?.meaning}
          onChange={props.onChange}
          editAssetStatus={props.editAssetStatus}
        />
      );
    } else {
      return (
        <SettingsAssetStatusesItem
          setEdit={() => props.setEdit(record.name, record.id)}
          name={record.name}
          editing={props.editField.editing}
        />
      );
    }
  };

  const settingsAssetStatusesTableColumns = [
    {
      title: 'ID',
      headerClassName: 'id-cell',
      sortId: 'id',
      render: (record) => record.id,
    },
    {
      title: 'Asset status',
      headerClassName: 'name-cell',
      sortId: 'name',
      render: (record) => renderAssetStatusesCell(record),
    },
  ];

  return (
    <Table
      columns={settingsAssetStatusesTableColumns}
      className='settings-asset-statuses-table'
      {...props}
    />
  );
};

export default SettingsAssetStatusesTable;
