import * as yup from 'yup';
import _set from 'lodash.set';
import { validator } from 'lib/validator';
import { LANGUAGE_LABELS } from 'config/languages';
import { normalizeWhitespace } from 'lib/dataTransform';

export const settingsAnswersFormValidationSchema = yup.object().shape({
  name: yup.object().shape({
    value: yup.string().required('Name is a required field'),
  }),
  options: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.object().shape({
          value: yup
            .array()
            .test(
              'atLeastOne',
              'At least one translation required',
              (val) => val?.filter((t) => t.text).length,
            ),
        }),
        meaning: yup.object().shape({
          value: yup.string().required('Action is a required field'),
        }),
      }),
    )
    .test('translationCountMismatch', (options, context) => {
      // All options must be translated in a particular language or none can
      const occurrencesByLanguage = options
        .map((opt) =>
          opt.label.value.filter((t) => !!t.text).map((t) => t.language),
        )
        .reduce((acc, next) => {
          next.forEach((lang) => {
            if (!acc[lang]) acc[lang] = 1;
            else acc[lang]++;
          });
          return acc;
        }, {});
      const errorLang = Object.keys(occurrencesByLanguage)
        .filter((lang) => occurrencesByLanguage[lang] !== options.length)
        .map((lang) => LANGUAGE_LABELS[lang]);

      return errorLang.length
        ? context.createError({
            message: `All options must be translated in a specific language.Check the translations for: ${errorLang.join(
              ', ',
            )}`,
          })
        : true;
    })
    .test('duplicateAnswerTranslation', (options, context) => {
      const textByLanguage = options
        .map((opt) => opt.label.value.filter((t) => !!t.text))
        .reduce((acc, next) => {
          next.forEach((obj) => {
            if (!acc[obj.language]) acc[obj.language] = [];
            acc[obj.language].push(normalizeWhitespace(obj.text.toLowerCase()));
          });
          return acc;
        }, {});
      const errorLang = Object.keys(textByLanguage)
        .filter(
          (lang) =>
            textByLanguage[lang].length !== new Set(textByLanguage[lang]).size,
        )
        .map((lang) => LANGUAGE_LABELS[lang]);

      return errorLang.length
        ? context.createError({
            message: `Answer names must be unique. Check the translations for: ${errorLang.join(
              ', ',
            )}`,
          })
        : true;
    }),
});

export const processAPIError = (errorDetails, answer) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, answer);
};

const settingsAnswersFormValidator = (state) =>
  validator(state, settingsAnswersFormValidationSchema);

export default settingsAnswersFormValidator;
