import Button from 'lib/components/button/Button';

export default function SettingsQuestionFormActions({
  title,
  onSave,
  onCancel,
  disabled,
}) {
  return (
    <div className='question-form-actions'>
      <div className='table-row question-body'>
        <div className='table-cell body-cell'>
          <div className='question-actions-container'>
            <Button disabled={disabled} onClick={onSave}>
              Save {title}
            </Button>
            <Button
              disabled={disabled}
              onClick={onCancel}
              className='link-button'
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
