const COMPANY_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

const COMPANY_STATUS_LABEL = {
  [COMPANY_STATUS.ACTIVE]: 'Active',
  [COMPANY_STATUS.DISABLED]: 'Disabled',
};

export const getStatusLabel = (status) => COMPANY_STATUS_LABEL[status] || '';

export default COMPANY_STATUS;
