import * as yup from 'yup';
import _set from 'lodash.set';
import { validator } from 'lib/validator';

export const processAssetStatusesAPIError = (errorDetails, defect) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    return _set(acc, 'name.errors', values);
  }, defect);
};

export const settingsAssetStatusesFormValidationSchema = yup.object().shape({
  name: yup.object().shape({
    value: yup.string().required('Status name is a required field'),
  }),
});

const settingsAssetStatusesFormValidator = (state) =>
  validator(state, settingsAssetStatusesFormValidationSchema);

export default settingsAssetStatusesFormValidator;
