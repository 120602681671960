import { cloneDeep } from 'lodash';
import makeListReducer, { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';

const initialPageState = {
  form: null,
  isAddModalOpen: false,
};

export const PAGE_ACTIONS = {
  USER_OPENS_FORM: 'USER_OPENS_FORM',
  USER_OPENS_ADD_MODAL: 'USER_OPENS_ADD_MODAL',
  USER_CANCELS_FORM: 'USER_CANCELS_FORM',
  USER_ADDS_OPTION: 'USER_ADDS_OPTION',
  USER_REMOVES_OPTION: 'USER_REMOVES_OPTION',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
};

const PAGE_REDUCER_CONFIG = {
  [PAGE_ACTIONS.USER_OPENS_ADD_MODAL]: (state, action) => {
    return {
      ...state,
      form: {
        ...cloneDeep(action.payload),
        isDirty: false,
      },
      isAddModalOpen: true,
    };
  },
  [PAGE_ACTIONS.USER_OPENS_FORM]: (state, action) => {
    return {
      ...state,
      form: {
        ...cloneDeep(action.payload),
        isDirty: false,
      },
    };
  },
  [PAGE_ACTIONS.SET_FORM_ERRORS]: (state, action) => {
    return {
      ...state,
      form: {
        ...state.form,
        ...action.payload,
      },
    };
  },
  [PAGE_ACTIONS.USER_CANCELS_FORM]: (state) => {
    return {
      ...state,
      form: null,
      isAddModalOpen: false,
    };
  },
  [PAGE_ACTIONS.USER_ADDS_OPTION]: (state) => {
    const newForm = cloneDeep(state.form);
    newForm.options.push({
      name: '',
      errors: [],
      isRemovable: true,
    });
    newForm.isDirty = true;

    return {
      ...state,
      form: newForm,
    };
  },
  [PAGE_ACTIONS.USER_REMOVES_OPTION]: (state, action) => {
    const idx = action.payload;
    const newForm = cloneDeep(state.form);
    newForm.options.splice(idx, 1);

    return {
      ...state,
      form: newForm,
    };
  },
  [PAGE_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    const { key, value, index } = action.payload;
    const newForm = cloneDeep(state.form);

    if (index !== false) {
      newForm[key][index].name = value;
      newForm[key][index].errors = [];
    } else {
      newForm[key].value = value;
      newForm[key].errors = [];
    }

    newForm.errors = [];
    newForm.isDirty = true;

    return {
      ...state,
      form: newForm,
    };
  },
};

const LIST_REDUCER_CONFIG = {
  [LIST_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      form: null,
      isAddModalOpen: false,
    };
  },
};

const REDUCER_CONFIG = {
  ...PAGE_REDUCER_CONFIG,
  ...LIST_REDUCER_CONFIG,
};

export const { reducer, initialState } = makeListReducer(
  REDUCER_CONFIG,
  initialPageState,
);
