import Tabs from 'lib/components/tabs/Tabs';
import { RESULT_FILTER } from 'modules/inspection-plans/inspectionPlansBulkResultsReducer';
import STATUS_ICON from './statusIconConfig';

const FILTER_LABELS = {
  [RESULT_FILTER.SUCCESS]: 'Created',
  [RESULT_FILTER.SKIPPED]: 'No matching tags',
  [RESULT_FILTER.NO_TAGS]: 'No matching tags',
  [RESULT_FILTER.FAILED]: 'Failed',
  [RESULT_FILTER.ERROR]: 'Failed',
  [RESULT_FILTER.AUTO_PLAN]: 'Existing plan',
  [RESULT_FILTER.USER_PLAN]: 'Existing user plan',
  [RESULT_FILTER.WRONG_TYPE]: 'Wrong inspection type',
  [RESULT_FILTER.NEEDS_REVIEW]: 'Existing plan needs review',
};

const FilterItem = ({ status, onChange, currentTab, totals }) => (
  <Tabs.Item
    onClick={() => onChange(status)}
    active={currentTab === status}
    disabled={!totals[status]}
    className={status}
  >
    {!!totals[status] && STATUS_ICON[status]}
    {FILTER_LABELS[status]}
    {!!totals[status] && ` (${totals[status]})`}
  </Tabs.Item>
);

const FilterTabs = ({ onChange, currentTab, totals = {} }) => (
  <Tabs>
    <Tabs.Item
      onClick={() => onChange(RESULT_FILTER.ALL)}
      active={currentTab === RESULT_FILTER.ALL}
      disabled={false}
    >
      All plans
    </Tabs.Item>
    <FilterItem
      status={RESULT_FILTER.SUCCESS}
      onChange={onChange}
      currentTab={currentTab}
      totals={totals}
    />
    <FilterItem
      status={RESULT_FILTER.NO_TAGS}
      onChange={onChange}
      currentTab={currentTab}
      totals={totals}
    />
    <FilterItem
      status={RESULT_FILTER.AUTO_PLAN}
      onChange={onChange}
      currentTab={currentTab}
      totals={totals}
    />
    <FilterItem
      status={RESULT_FILTER.USER_PLAN}
      onChange={onChange}
      currentTab={currentTab}
      totals={totals}
    />
    <FilterItem
      status={RESULT_FILTER.NEEDS_REVIEW}
      onChange={onChange}
      currentTab={currentTab}
      totals={totals}
    />
  </Tabs>
);

export default FilterTabs;
