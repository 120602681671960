import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as DocumentSolid } from 'assets/images/document-solid.svg';
import { ReactComponent as WordDocumentSolid } from 'assets/images/word-document-solid.svg';
import { ReactComponent as SpreadsheetSolid } from 'assets/images/excel.svg';
import { ReactComponent as CloseCircleSolid } from 'assets/images/remove.svg';

import './File.scss';

const getFileIcon = (mimetype) => {
  switch (mimetype) {
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return <SpreadsheetSolid />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return <WordDocumentSolid />;
    default:
      return <DocumentSolid />;
  }
};

const File = ({
  onClick,
  onRemove,
  data,
  iconOnly,
  className,
  type,
  name,
  ...props
}) => {
  const clickable = typeof onClick === 'function';
  const removable = typeof onRemove === 'function';
  const fileMimetype = type || data?.type || data?.metadata?.type;
  return (
    <div
      data-testid='file'
      className={classNames('qm-file', { clickable }, className)}
      {...props}
    >
      <div className='icon'>
        {getFileIcon(fileMimetype)}
        {removable && (
          <button type='button' onClick={onRemove} className='remove-button'>
            <CloseCircleSolid />
          </button>
        )}
      </div>
      {!iconOnly && (
        <div className='name'>{name || data?.documentName || data?.name}</div>
      )}
    </div>
  );
};

File.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  iconOnly: PropTypes.bool,
};

export default File;
