import { AppContext } from 'App';
import { useContext, useEffect, useState } from 'react';
import useQueryParams from 'lib/useQueryParams';
import inspectionsService from './inspectionsService';

const inspectionParams = {
  select: [
    'id',
    'inspectionDate',
    'finishedAt',
    'externalId',
    'showId',
    'validityValue',
    'validityRange',
    'aqlLevel',
    'criticalDefectValue',
    'criticalDefectRule',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'status',
    'result',
    'workObjectId',
    'workObjectExternalId',
    'minorDefectsCountAll',
    'majorDefectsCountAll',
    'functionalDefectsCountAll',
    'criticalDefectsCountAll',
    'sampleSize',
    'minorDefectAllowed',
    'majorDefectAllowed',
    'functionalDefectAllowed',
    'criticalDefectAllowed',
    'woQty',
    'partialQty',
    'summary',
    'currentDecisionPoint',
  ],
  relations: [
    'asset.images',
    'source',
    'locationSource',
    'inspectionPlan',
    'inspector.profilePhoto',
    'inspectionType',
    'questionGroups',
    'woCustomFields',
    'actualDecisions',
  ],
};

export default function useInspectionsReportPrint(props) {
  const [options, setOptions] = useState(null);
  const [inspection, setInspection] = useState(null);
  const [errors, setErrors] = useState(false);
  const { getParams } = useQueryParams(props.location);
  const id = props.match?.params?.id;

  const {
    appState: { company },
  } = useContext(AppContext);

  const noPrint = !!getParams().debug;

  useEffect(() => {
    inspectionsService
      .getInspectionPlanOptions()
      .then((options) => {
        setOptions(options);
      })
      .catch(() => {
        setErrors(['Could not load inspection plan options']);
      });
  }, []);

  useEffect(() => {
    if (options && id) {
      inspectionsService
        .getInspection(id, inspectionParams)
        .then((inspection) => {
          setInspection(inspection);
        })
        .catch(() => {
          setErrors(['Could not load inspection']);
        });
    }
  }, [options, id]);

  const onAfterPrint = () => {
    props.history.push(`/inspections/${inspection.id}/results`);
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!noPrint) {
      window.addEventListener('afterprint', onAfterPrint);
    }

    if (inspection) {
      const toBeLoaded = Array.from(document.images).filter(
        (img) => !img.complete,
      );

      const mapImgToLoadPromise = (img) =>
        new Promise((resolve, reject) => {
          controller.signal.addEventListener('abort', () => {
            img.removeEventListener('load', resolve);
            img.removeEventListener('error', resolve);

            // On abort we reject to not trigger window.print()
            reject();
          });

          // We don't care if some images are errored or not
          // but need to resolve for error as well
          // or else the Promise.all will hang indefinitely
          img.addEventListener('load', resolve);
          img.addEventListener('error', resolve);
        });

      Promise.all(toBeLoaded.map(mapImgToLoadPromise))
        .then(() => {
          if (!noPrint) {
            window.print();
            props.history.push(`/inspections/${inspection.id}/results`);
          }
        })
        .catch(() => {
          console.log('User navigated away before all images were loaded');
        });
    }

    return () => {
      if (!noPrint) {
        window.removeEventListener('afterprint', onAfterPrint);
      }
      controller.abort();
    };
  }, [inspection, noPrint]);

  return {
    company,
    options,
    inspection,
    errors,
  };
}
