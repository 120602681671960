import classNames from 'classnames';
import './Pill.scss';

const Pill = ({ children, className, ...props }) => {
  const classes = classNames('pill', className);

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

export default Pill;
