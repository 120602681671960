import { addDays, isValid, startOfDay } from 'date-fns';
import _get from 'lodash.get';
import _set from 'lodash.set';
import * as yup from 'yup';

import WORK_OBJECT_STATUS from 'config/workObjectStatus';
import { isBefore } from 'date-fns';
import validator, { requiredFormObject, yupNumber } from 'lib/validator';
import { MAX_FIELD_CHARS } from './workObjectsFormReducer';

const MAX_QUANTITY = 2_147_483_647;

// validate if custom field name value is unique in array
yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (item) => _get(item, path)?.toLowerCase();
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const fieldIndex = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `customFields[${fieldIndex}].${path}`,
      message,
    });
  });
});

export const workflowsFormValidationSchema = yup.object().shape({
  resource: yup.object().shape({
    value: yup
      .object()
      .nullable()
      .required('Asset / Source is a required field'),
  }),
  originalQuantity: yup.number(),
  quantity: yup
    .object()
    .shape({
      value: yupNumber
        .required('Quantity is a required field')
        .integer('Quanitity needs to be an integer')
        .positive('Quantity needs to be greater than 0'),
    })
    .test('match', `Quantity should be greater than `, function (quantity) {
      if (
        !quantity.value ||
        !this.parent.hasAcceptedPartials ||
        (this.parent.hasAcceptedPartials &&
          quantity.value >= this.parent.originalQuantity)
      ) {
        return true;
      }
      return this.createError({
        path: 'quantity.value',
        params: { originalValue: quantity.value },
        message: `Quantity should be greater than ${this.parent.originalQuantity}`,
      });
    })
    .test('less', `Quantity should be less than `, function (quantity) {
      if (!quantity.value || Number(quantity.value) <= MAX_QUANTITY) {
        return true;
      }
      return this.createError({
        path: 'quantity.value',
        params: { originalValue: quantity.value },
        message: `Quantity should be less than ${MAX_QUANTITY}`,
      });
    }),
  status: yup.object().shape({
    value: yup.object().nullable().required('Status is a required field'),
  }),
  deadline: yup
    .object()
    .shape({
      value: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .required('Deadline is a required field'),
    }),
  customFields: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .required('Name is required if there is a custom field')
            .max(MAX_FIELD_CHARS.name, 'Name is too long'),
        }),
        data: yup.object().shape({
          value: yup
            .string()
            .nullable()
            .required('Data is required if there is a custom field')
            .max(MAX_FIELD_CHARS.data, 'Data is too long'),
        }),
      }),
    )
    .unique('Name needs to be unique', 'name.value'),
  workflow: yup.object().shape({
    value: yup.object().nullable().required('Workflow is a required field'),
  }),
  hasPartials: yup.boolean(),
  hasAcceptedPartials: yup.boolean(),
  source: requiredFormObject('Source is a required field'),
  destination: requiredFormObject('Destination is a required field'),
});

export const processWorkflowsFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const path = curr.path.split('.').slice(0, -1).join('.');

    let originalValue, valueIndex;

    if (path.includes('name') && Array.isArray(curr.params.originalValue)) {
      valueIndex = Number(curr.path.match(/\[(.*?)\]/)[1]);
      originalValue = curr.params.originalValue[valueIndex].name.value;
    } else {
      originalValue = curr.params.originalValue;
    }

    _set(acc, path, {
      value: originalValue,
      errors: curr.errors,
    });
    return acc;
  }, input);
};

export const processAPIError = (errorDetails, workflow) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          case 'status':
            return 'This Work Object cannot be canceled';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, workflow);
};

const workObjectsFormValidator = (state) =>
  validator(state, workflowsFormValidationSchema, processWorkflowsFormError);

export default workObjectsFormValidator;
