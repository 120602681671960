import AddButton from 'lib/components/add-button/AddButton';
import IconButton from 'lib/components/icon-button/IconButton';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import useUsersList from 'modules/users/useUsersList';
import React from 'react';
import UsersTable from '../users-table/UsersTable';
import { ADD_USER_ROLES } from 'modules/users/usersPermissions';

import { ReactComponent as ImportIcon } from 'assets/images/upload.svg';
import { ReactComponent as ExportIcon } from 'assets/images/download.svg';

import './UsersListPage.scss';

export default function UsersListPage(props) {
  const list = useUsersList(props);

  return (
    <div className='users-list-page'>
      <div className='row user-list-header container-m'>
        <h2>Users</h2>

        <div className='row header-buttons'>
          <ProtectedComponent allowed={ADD_USER_ROLES}>
            <IconButton
              prefix={<ExportIcon />}
              disabled={list.state.isPreparingExport}
              onClick={list.userClicksExportUsers}
              data-testid='UsersListPage.exportButton'
              className='margin-right'
            >
              Export users
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_USER_ROLES}>
            <IconButton
              prefix={<ImportIcon />}
              onClick={() => props.history.push('/users/import')}
              data-testid='UsersListPage.importButton'
              className='margin-right'
            >
              Import users
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_USER_ROLES}>
            <AddButton
              onClick={() => props.history.push('/users/add')}
              data-testid='UsersListPage.newUserButton'
            >
              New user
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput value={list.state.search} onChange={list.setSearch} />
        </div>
      </div>
      <div className='users-table'>
        <UsersTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
        />
      </div>
    </div>
  );
}
