import React, { memo } from 'react'; import {
  relativeDate
} from 'lib/dateHelpers';
import Table from 'lib/components/table/Table';
import _isEqual from 'lodash/isEqual';

const getTimestamp = (dateStr) => {
  const ts = Date.parse(dateStr);

  return ts ? relativeDate(ts) : 'N/A';
}

const dbStatsTableColumns = [
  {
    title: 'Conn. ID',
    className: 'conn-id',
    render: (record) => record.connId,
  },
  {
    title: 'DB Name',
    className: 'db-name',
    render: (record) => record.dbName,
  },
  {
    title: 'Conn App',
    className: 'conn-app',
    render: (record) => record.appName,
  },
  {
    title: 'State',
    className: 'state',
    render: (record) => record.state,
  },
  {
    title: 'Last Query',
    className: 'last-query',
    render: (record) => getTimestamp(record.lastQueryAt),
  },
  {
    title: 'Last Transaction',
    className: 'last-transaction',
    render: (record) => getTimestamp(record.lastTransactionAt),
  },
];


const SystemSettingsDbStats = React.memo(({ dbStatsData }) => {

  const sortedDbStatsData = dbStatsData?.length ? [...dbStatsData].sort((a, b) => {
    const _a = a?.connId ?? 0;
    const _b = b?.connId ?? 0;

    if (_a > _b) return 1;
    if (_a < _b) return -1;
    return 0;
  }) : [];


  return (
    <div className='system-settings-db-stats'>
      <Table
        columns={dbStatsTableColumns}
        className='db-stats-table'
        data={sortedDbStatsData}
      />
    </div>
  );
}, (a, b) => _isEqual(a, b));

export { SystemSettingsDbStats };
