import ROLES from 'config/roles';

export const TAGGABLE_USER_ROLES = [
  ROLES.COORDINATOR,
  ROLES.INSPECTOR,
  ROLES.VENDOR,
];

export const ADD_USER_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];

/* BE filters users returning users associated to
 coordinator, inspector, source owner, internal source and asset owner */
export const VIEW_USER_ROLES = [
  ...ADD_USER_ROLES,
  ROLES.COORDINATOR,
  ROLES.INSPECTOR,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.ASSET_OWNER,
  ROLES.VENDOR,
];
