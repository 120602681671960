import { ReactComponent as Email } from 'assets/images/email.svg';
import { ReactComponent as PhoneInTalk } from 'assets/images/phone-in-talk.svg';

const ContactInfo = ({ company }) => {
  if (!company?.emails?.length && !company?.phones?.length) {
    return (
      <div className='group'>
        <div className='title'>Contact info:</div>
        <div className='info'>-</div>
      </div>
    );
  }
  if (company?.emails?.length === 1 && company?.phones?.length === 1) {
    return (
      <div className='group'>
        <div className='title'>Contact info:</div>
        <div className='info'>
          <a href={`mailto:${company.emails[0]?.email}`}>
            <Email />
            <span>{company.emails[0]?.email}</span>
          </a>
        </div>
        <div className='info'>
          <a href={`tel:${company.phones[0]?.phone}`}>
            <PhoneInTalk />
            <span>{company.phones[0]?.phone}</span>
          </a>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className='group'>
        <div className='title'>Contact info:</div>
        {(company?.emails || []).map((item, index) => (
          <div className='info' key={`email-${index}`}>
            <a href={`mailto:${item.email}`}>
              <Email />
              <span>{item.email}</span>
            </a>
          </div>
        ))}
      </div>
      <div className='group'>
        <div className='title'>&nbsp;</div>
        {(company?.phones || []).map((item, index) => (
          <div className='info' key={`phone-${index}`}>
            <a href={`tel:${item.phone}`}>
              <PhoneInTalk />
              <span>{item.phone}</span>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactInfo;
