import React, { useReducer } from 'react';

const defaultData = {
  columnNames: [],
  columnValues: {},
  customMeasurements: [],
};

function measurementReducer(state, action) {
  switch (action.type) {
    case MEASUREMENT_ACTIONS.RESET:
      return defaultData;
    case MEASUREMENT_ACTIONS.SET_DATA:
      if (action.data) {
        return { ...action.data };
      }
      break;
    case MEASUREMENT_ACTIONS.SET_CUSTOM_MEASUREMENT:
      if (action.data) {
        return {
          ...state,
          customMeasurements: action.data,
        };
      }
      break;
    case MEASUREMENT_ACTIONS.SET_TABLE_VALUE:
      if (action.data) {
        const { row, col, value } = action.data;
        const { columnNames, columnValues } = state;
        if (columnNames?.length > col) {
          const values = columnValues[columnNames[col]];
          if (values?.length > row) {
            values[row] = value;
            columnValues[columnNames[col]] = [...values];
            return {
              ...state,
              columnValues: { ...columnValues },
            };
          }
        }
      }
      break;
    default:
      return state;
  }

  return state;
}

export const MEASUREMENT_ACTIONS = {
  RESET: 'mt_reset',
  SET_DATA: 'mt_set',
  SET_CUSTOM_MEASUREMENT: 'mt_set_cust',
  SET_TABLE_VALUE: 'mt_set_tabval',
};

export const MeasurementContext = React.createContext();

/**
 * Measurement table context data wrapper
 */
export const MeasurementCollection = function MeasurementCollection(props) {
  const [data, dispatch] = useReducer(measurementReducer, defaultData);

  return <MeasurementContext.Provider value={[data, dispatch]} {...props} />;
};
