import { getTranslation } from 'lib/dataTransform';
import classNames from 'classnames';
import Defect from 'lib/components/defect/Defect';

function getDefectWeight(defectList, id) {
  return defectList.find((d) => d.id === id)?.weight;
}

export default function DefectWidget({ defect, defectList, onPhotoClick }) {
  return (
    <div className='defect-container'>
      <div className='attribute defect-attribute'>
        <div className='label'>Defect</div>
        <div className='value'>
          {getTranslation(defect.defect.name).display}{' '}
          <Defect
            weight={
              defect.weight || getDefectWeight(defectList, defect.defectId)
            }
          />
        </div>
      </div>
      {defect.defectiveUnits !== null && (
        <div className='attribute'>
          <div className='label'>Defective units</div>
          <div className='value'>{defect.defectiveUnits}</div>
        </div>
      )}
      {defect.qty !== null && (
        <div className='attribute'>
          <div className='label'>Defect count</div>
          <div className='value'>{defect.qty}</div>
        </div>
      )}
      {!!defect.location && (
        <div className='attribute'>
          <div className='label'>Location</div>
          <div className='value'>{defect.location}</div>
        </div>
      )}
      {!!defect.notes && (
        <div className='attribute'>
          <div className='label'>Defect notes</div>
          <div className='value'>
            <p>{defect.notes}</p>
          </div>
        </div>
      )}
      {!!defect.photos.length && (
        <div className='attribute photos-attribute'>
          <div className='label'>Defect photos</div>
          <div className='value-list'>
            {defect.photos.map((photo, index) => (
              <img
                className={classNames({
                  'is-clickable': !!onPhotoClick,
                })}
                key={photo.id}
                src={photo.url}
                alt='Defect'
                onClick={() =>
                  onPhotoClick && onPhotoClick(defect.photos, index)
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
