import { Link } from 'react-router-dom';

import { INSPECTION_ORIGIN } from 'config/inspectionConfig';
import { ADD_WORKFLOW_ROLES } from 'modules/workflows/workflowsPermissions';
import Button from 'lib/components/button/Button';
import EditButton from 'lib/components/edit-button/EditButton';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Timeline from 'lib/components/timeline/Timeline';
import useWorkflowsDetail from 'modules/workflows/useWorkflowsDetail';
import WorkObjectsTable from 'lib/components/work-objects-table/WorkObjectsTable';

import './WorkflowsDetailPage.scss';

const renderNameAndExtId = (v) => {
  return v?.name
    ? `${v.name}${v.externalId ? ` (External ID: ${v.externalId})` : ''}`
    : null;
};

const RenderInspectionPlan = ({ origin, plan }) => {
  if (origin === INSPECTION_ORIGIN.EXTERNAL) {
    return (
      <>
        <span className='text-separator'> - </span>
        <span className='step-subtitle'>(Third party)</span>
      </>
    );
  }

  if (plan?.name) {
    return (
      <>
        <span className='text-separator'> - </span>
        <Link to={`/inspection-plans/${plan.id}`}>{plan.name}</Link>
      </>
    );
  }

  return (
    <>
      <span className='text-separator'> - </span>
      <span className='step-subtitle'>(Plan inherited from asset)</span>
    </>
  );
};

const renderLocation = (city, country) =>
  [city || '', country || ''].join(', ');

const renderStepTitle = (step, type = 'source') => {
  if (!step.source) {
    return (
      <>
        <p className='step-title empty-step'>{type}</p>
        <p className='step-subtitle'>(inherited from work object)</p>
      </>
    );
  }

  return (
    <>
      <p className='step-title'>{renderNameAndExtId(step.source)}</p>
      <p className='step-subtitle'>
        {renderLocation(
          step.source.location?.city,
          step.source.location?.country,
        )}
      </p>
    </>
  );
};

const WorkflowsDetailPage = (props) => {
  const { state, ...vm } = useWorkflowsDetail(props);
  const { workflow, loading, errors } = state;

  if (loading) {
    // TODO: handle loading state
    return null;
  }
  if (errors.length || !workflow) {
    //TODO: handle api error
    return null;
  }

  return (
    <div className='workflows-detail-page'>
      <div className='container-m' data-testid='WorkflowsDetailPage.container'>
        <div className='workflow-header'>
          <h1>{workflow.name}</h1>
          <ProtectedComponent allowed={ADD_WORKFLOW_ROLES}>
            <EditButton
              onClick={() =>
                props.history.push(`/workflows/${workflow.id}/edit`)
              }
              data-testid='WorkflowsDetailPage.editButton'
            >
              Edit
            </EditButton>
          </ProtectedComponent>
        </div>
        <div className='header-info'>
          <p>ID: {workflow?.id}</p>
          {!!workflow?.externalId && <p>External ID: {workflow.externalId}</p>}
        </div>
        <p className='page-description'>{workflow.description}</p>
        <Timeline className='primary'>
          {(workflow.workflowSteps || []).map((step, stepIndex) => (
            <Timeline.Item icon={stepIndex + 1} key={step.id}>
              {renderStepTitle(
                step,
                stepIndex === 0
                  ? 'Source'
                  : stepIndex === workflow.workflowSteps.length - 1
                    ? 'Destination'
                    : 'Location',
              )}
              {!step.inspectionPairs.length && (
                <Timeline.Subitem className='empty-subitem'>
                  <p>No inspection will take place at this location.</p>
                </Timeline.Subitem>
              )}
              {(step.inspectionPairs || []).map((pair) => (
                <Timeline.Subitem key={pair.id}>
                  <p className='step-title'>
                    {pair.inspectionType.name}
                    <RenderInspectionPlan
                      origin={pair.origin}
                      plan={pair.inspectionPlan}
                    />
                  </p>
                  {pair.isScheduled && (
                    <p className='step-subtitle'>Scheduled inspection</p>
                  )}
                  {Number.isFinite(pair.customInspectionQty) && (
                    <p className='step-subtitle step-subtitle-block'>Custom Qty: {pair.customInspectionQty}{!!pair.customInspectionQtyIsRelative && '%'}</p>
                  )}
                </Timeline.Subitem>
              ))}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div className='row'>
        <div className='work-objects-table-title'>
          <h2>Associated work objects</h2>
          {state.workObjects?.count > 5 && (
            <Button
              type='button'
              className='medium-button add-inspection-pair'
              onClick={() =>
                props.history.push(`/work-objects/?workflowId=${workflow.id}`)
              }
              data-testid='WorkflowsDetailPage.viewAll'
            >
              View all
            </Button>
          )}
        </div>
        <WorkObjectsTable
          data={state.workObjects?.data}
          data-testid='WorkflowsDetailPage.WorkObjectsTable'
          userClicksOnRow={vm.userClicksOnRow}
        />
        {!state.workObjects?.count && (
          <p className='info-message'>No associated work objects found</p>
        )}
      </div>
    </div>
  );
};

export default WorkflowsDetailPage;
