import makeReducer from 'lib/makeReducer';

const INITIAL_ASSETS_DETAIL_STATE = {
  asset: {},
  workObjects: { count: null, data: [] },
  inspections: { count: null, data: [] },
  inspectionPlans: { count: null, data: [] },
  loading: true,
  errors: [],
};

export const ASSETS_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  APP_LOADS_RELATED_WORK_OBJECTS: 'APP_LOADS_RELATED_WORK_OBJECTS',
  APP_LOADS_RELATED_INSPECTIONS: 'APP_LOADS_RELATED_INSPECTIONS',
  APP_LOADS_RELATED_INSPECTION_PLANS: 'APP_LOADS_RELATED_INSPECTION_PLANS',
  RESET_STATE: 'RESET_STATE',
};

const ASSETS_DETAIL_REDUCER_CONFIG = {
  [ASSETS_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      asset: action.payload,
      loading: false,
    };
  },
  [ASSETS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS]: (state, action) => {
    return {
      ...state,
      workObjects: action.payload,
    };
  },
  [ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTIONS]: (state, action) => {
    return {
      ...state,
      inspections: action.payload,
    };
  },
  [ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTION_PLANS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      inspectionPlans: action.payload,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  ASSETS_DETAIL_REDUCER_CONFIG,
  INITIAL_ASSETS_DETAIL_STATE,
);
