import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import { inspectionTypeStateToPOSTParams } from './dataTransform';
import { processAPIError } from './inspectionTypesFormValidator';

const log = makeLogger('inspectionTypesService');

export const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const defaultInspectionTypesListParams = {
  page: 1,
  pageSize: 10,
  select: ['name', 'status', 'inspectionsDone', 'type'],
};

const defaultFieldParams = {
  select: ['name'],
};

const defaultRolesParams = {
  select: ['role'],
};

const getNotifiableRoles = () => {
  log('Attempting to fetch notifiable roles.');

  return request
    .get('/notifiable-roles', { params: defaultRolesParams })
    .then((response) => {
      log('Notifiable roles successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching notifiable roles', e);
      return Promise.reject(e);
    });
};

const getInspectionTypes = (params) => {
  log('Attempting to fetch inspection types with params', params);

  return request
    .get('/inspection-types', {
      params: queryParamsToGETParams({
        ...defaultInspectionTypesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspection types successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getInspectionType = ({ id, ...params }) => {
  log('Attempting to fetch inspection type with params', { id, ...params });

  return request
    .get(`/inspection-types/${id}`, {
      params,
    })
    .then((response) => {
      log('Inspection types successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection type for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getUsageDecisionCriterias = (params) => {
  log('Attempting to fetch usage decision criterias with params', { params });

  return request
    .get('/usage-decision-criteria', {
      params: { ...defaultFieldParams, params },
    })
    .then((response) => {
      log('Usage decision criterias successfully fetched', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue fetching the usage decision criterias for the params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getUsageDecisionActions = (params) => {
  log('Attempting to fetch usage decision actions with params', { params });

  return request
    .get('/usage-decision-actions', {
      params: { ...defaultFieldParams, params },
    })
    .then((response) => {
      log('Usage decision actions successfully fetched', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue fetching the usage decision actions for the params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveInspectionType = (inspectionType) => {
  log('Attempting to save inspection type.');

  const query = inspectionType.id
    ? `/inspection-types/${inspectionType.id}`
    : '/inspection-types';
  const method = inspectionType.id ? 'put' : 'post';

  return request[method](query, {
    ...inspectionTypeStateToPOSTParams(inspectionType),
  })
    .then((response) => {
      log('Inspection type successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in saving inspection type',
        e.response.data.message,
      );
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processAPIError(
            e.response.data.details,
            inspectionType,
          );
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const inspectionTypesService = {
  getNotifiableRoles,
  getInspectionTypes,
  getInspectionType,
  getUsageDecisionCriterias,
  getUsageDecisionActions,
  saveInspectionType,
};

export default inspectionTypesService;
