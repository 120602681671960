import WorkflowsForm from '../workflows-form/WorkflowsForm';
import './WorkflowsEditPage.scss';

const WorkflowsEditPage = (props) => {
  return (
    <div
      className='workflows-edit-page container-m'
      data-testid='WorkflowsEditPage.container'
    >
      <div className='row'>
        <h1>Edit workflow</h1>
      </div>
      <div className='row'>
        <WorkflowsForm {...props} />
      </div>
    </div>
  );
};

export default WorkflowsEditPage;
