import React from 'react';
import classNames from 'classnames';
import Button from 'lib/components/button/Button';

import './UploadButton.scss';

const UploadButton = ({ options, className, onUpload, children, ...props }) => {
  const inputRef = React.createRef();
  const onButtonClick = () => inputRef.current.click();
  const onInputChange = (ev) =>
    typeof onUpload === 'function' && onUpload(ev.target.files);

  return (
    <div className={classNames('qm-upload-button', className)}>
      <input
        type='file'
        multiple={options?.multiple}
        onChange={onInputChange}
        ref={inputRef}
      />
      <Button
        type='button'
        className='medium-button'
        {...props}
        onClick={onButtonClick}
      >
        {children}
      </Button>
    </div>
  );
};

// UploadButton.propTypes = {}

export default UploadButton;
