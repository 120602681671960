import AssetsForm from '../assets-form/AssetsForm';
import 'react-dropzone-uploader/dist/styles.css';
import './AssetsAddPage.scss';
import { MeasurementCollection } from 'modules/measurement-table/MeasurementTableContext';

const AssetsAddPage = (props) => {
  return (
    <div
      className='assets-add-page container-m'
      data-testid='AssetsAddPage.container'
    >
      <div className='row'>
        <h2>New Asset</h2>
      </div>
      <MeasurementCollection>
        <AssetsForm {...props} />
      </MeasurementCollection>
    </div>
  );
};

export default AssetsAddPage;
