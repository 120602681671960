import Avatar, { AVATAR_SIZES } from '../avatar/Avatar';
import classNames from 'classnames';
import './NamedAvatar.scss';

const NamedAvatar = ({
  user,
  subtitle = user?.companyName,
  large = false,
  className,
  onClick,
  ...props
}) => {
  const containerClass = classNames('qm-named-avatar', className, {
    clickable: typeof onClick === 'function',
    large: large === true,
  });
  const avatarSize = large ? AVATAR_SIZES.L : AVATAR_SIZES.S;

  return (
    <div className={containerClass} onClick={onClick} {...props}>
      <Avatar
        url={user?.profilePhoto?.url}
        placeholder={user?.name}
        size={avatarSize}
      />
      <div className='details'>
        {!!user?.name && <div className='title'>{user.name}</div>}
        {!!subtitle && <div className='subtitle'>{subtitle}</div>}
      </div>
    </div>
  );
};

export default NamedAvatar;
