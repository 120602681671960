import { useEffect } from 'react';
import debounce from 'lodash.debounce';

import useQueryList from 'lib/list-helper/useQueryList';
import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import {
  reducer,
  initialState,
  PAGE_ACTIONS,
} from './settingsCompanyAttributesListReducer';
import settingsService from './settingsService';
import { companyAttributeToPageState } from './dataTransform';
import settingsCompanyAttributesListValidator from './settingsCompanyAttributesListValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const fetchCompanyAttributes = debounce(
  (params, dispatch) => {
    return settingsService
      .getCompanyAttributes(params)
      .then((response) => {
        dispatch({
          type: LIST_ACTIONS.APP_LOADS_DATA,
          payload: {
            data: response.data.map(companyAttributeToPageState),
            count: response.count,
            errors: [],
          },
        });
      })
      .catch(() => {
        dispatch({
          type: LIST_ACTIONS.SET_ERRORS,
          payload: {
            errors: ['Could not fetch company attributes'],
          },
        });
      });
  },
  {
    leading: 400,
    trailing: 400,
  },
);

const useSettingsCompanyAttributesList = ({ location, history }) => {
  const { dispatch, state, ...list } = useQueryList(
    location,
    history,
    reducer,
    initialState,
  );

  useNavigationPrompt(state.form?.isDirty);

  const companyAttributesParams = {
    search: state.search,
    page: state.page,
    pageSize: state.pageSize,
    order: {
      [state.sortBy]: state.sortOrder,
    },
  };

  useEffect(() => {
    fetchCompanyAttributes(companyAttributesParams, dispatch);
  }, [state.search, state.page, state.sortOrder, state.sortBy, state.pageSize]);

  const userClicksEditAttribute = (attribute) => {
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_FORM,
      payload: attribute,
    });
  };

  const userChangesInput = (key, value, index = false) => {
    dispatch({
      type: PAGE_ACTIONS.USER_CHANGES_INPUT,
      payload: {
        key,
        value,
        index,
      },
    });
  };
  const userClicksSaveForm = () => {
    settingsCompanyAttributesListValidator(state.form)
      .then(() => settingsService.saveCompanyAttribute(state.form))
      .then(() => fetchCompanyAttributes(companyAttributesParams, dispatch))
      .catch((err) => {
        dispatch({
          type: PAGE_ACTIONS.SET_FORM_ERRORS,
          payload: err.payload,
        });
      });
  };
  const userClicksCancelForm = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_CANCELS_FORM,
    });
  };
  const userClicksAddOption = () => {
    dispatch({
      type: PAGE_ACTIONS.USER_ADDS_OPTION,
    });
  };
  const userClicksRemoveOption = (idx) => {
    dispatch({
      type: PAGE_ACTIONS.USER_REMOVES_OPTION,
      payload: idx,
    });
  };

  const userClicksAddAttribute = () => {
    const emptyAttribute = {
      options: [
        {
          name: '',
          isRemovable: true,
        },
      ],
    };
    dispatch({
      type: PAGE_ACTIONS.USER_OPENS_ADD_MODAL,
      payload: companyAttributeToPageState(emptyAttribute),
    });
  };

  return {
    dispatch,
    state,
    ...list,
    userClicksAddAttribute,
    userClicksEditAttribute,
    userChangesInput,
    userClicksSaveForm,
    userClicksCancelForm,
    userClicksAddOption,
    userClicksRemoveOption,
  };
};

export default useSettingsCompanyAttributesList;
