import makeReducer from 'lib/makeReducer';

export const UNSUBSCRIBE_ACTIONS = {
  APP_SAVES_DATA: 'APP_SAVES_DATA',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_UNSUBSCRIBE_STATE = {
  message: '',
  loading: true,
};

const UNSUBSCRIBE_REDUCER_CONFIG = {
  [UNSUBSCRIBE_ACTIONS.APP_SAVES_DATA]: (state) => {
    return {
      ...state,
      message: 'You have been successfully unsubscribed',
      loading: false,
    };
  },
  [UNSUBSCRIBE_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  UNSUBSCRIBE_REDUCER_CONFIG,
  INITIAL_UNSUBSCRIBE_STATE,
);
