import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import { reducer, initialState } from './inspectionPlansListReducer';
import inspectionPlansService from './inspectionPlansService';
import debounce from 'lodash.debounce';

const setFilters = (params) => {
  let filters = { ...params.filters };
  if (params?.byAssetId) {
    filters = { ...filters, byAssetId: params.byAssetId };
  }
  if (params?.bySourceId) {
    filters = { ...filters, bySourceId: params.bySourceId };
  }
  return filters;
};

const fetchInspectionPlans = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });
  const filters = setFilters(params);
  return inspectionPlansService
    .getInspectionPlans({ ...params, filters })
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useInspectionPlansList = ({ location, history }) => {
  const list = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchInspectionPlans,
  );

  const userClicksOnRow = (id) => {
    history.push(`/inspection-plans/${id}`);
  };

  const userClicksOnAdd = () => {
    history.push(`/inspection-plans/add`);
  };

  return { ...list, userClicksOnRow, userClicksOnAdd };
};

export default useInspectionPlansList;
