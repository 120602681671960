import request from './request';
import makeLogger from './makeLogger';

const log = makeLogger('tags');

const defaultParams = {
  select: ['label'],
};

const getTags = () => {
  log('Attempting to fetch tags');

  return request
    .get('/tags', { params: defaultParams })
    .then((results) => {
      log('Tags fetched successfully', results);
      return results;
    })
    .catch((e) => {
      log('Failed to fetch tags', e);
      return Promise.reject(e);
    });
};

const tagsService = {
  getTags,
};

export default tagsService;
