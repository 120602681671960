import { formatDate } from 'lib/dateHelpers';
import { formatAqlLevelSummary } from 'lib/dataTransform';

import Table from 'lib/components/table/Table';
import Button from 'lib/components/button/Button';

import { ReactComponent as LinkOutline } from 'assets/images/link-outline.svg';
import { ReactComponent as DeleteCircleOutline } from 'assets/images/close-circle-outline.svg';

import './AssignInspectionPlanToAssetTable.scss';

const assignInspectionPlanToAnAssetTable = ({ userClicksOnRow, ...props }) => {
  const assignInspectionPlanToAnAssetColumns = [
    {
      title: 'ID',
      sortId: 'id',
      className: 'id-cell',
      render: (record) => <span>{record.id}</span>,
    },
    {
      title: 'Name',
      sortId: 'name',
      className: 'name-cell',
      render: (record) => record.name,
    },
    {
      title: 'Description',
      sortId: 'description',
      className: 'name-cell',
      render: (record) => record.description,
    },
    {
      title: 'Date published / AQL',
      className: 'date-published',
      sortId: 'publishedAt',
      textAlign: 'left',
      render: (record) => (
        <>
          {!!record.publishedAt && <div>{formatDate(record.publishedAt)}</div>}
          {!!record.useAqlLevel && (
            <div>
              {formatAqlLevelSummary(
                record.aqlLevel,
                record.majorDefect,
                record.minorDefect,
                record.functionalDefect,
              )}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      sortId: 'action',
      className: 'action-cell',
      textAlign: 'right',
      render: (record) =>
        record.isAssigned ? (
          <Button
            type='button'
            onClick={() => props.onUnassignClick(record.id)}
            className='assign-table-action-button unassign'
          >
            <DeleteCircleOutline />
          </Button>
        ) : (
          <Button
            type='button'
            onClick={() => props.onAssignClick(record.id)}
            className='assign-table-action-button assign'
          >
            <LinkOutline />
          </Button>
        ),
    },
  ];

  return (
    <Table
      columns={assignInspectionPlanToAnAssetColumns}
      className='assign-inspection-plan-to-an-asset-table'
      {...props}
    />
  );
};

export default assignInspectionPlanToAnAssetTable;
