import { endOfDay, isBefore, parseISO } from 'date-fns';

import Modal from 'lib/components/modal/Modal';
import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';
import DatePicker from 'lib/components/date-picker/DatePicker';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import {
  formatDateToHTMLDateString,
} from 'lib/dateHelpers';

import './InspectionReadyModal.scss';

const InspectionReadyModal = ({
  isOpen,
  onQuantityChange,
  onDateChange,
  data = [],
  onSubmit,
  ...props
}) => {
  const maxQuantity = data?.isEditing
    ? data?.quantity + Number(data?.partialDefaultQuantity || 0)
    : data?.quantity;

  return (
    <Modal
      title={data?.isEditing ? 'Edit partial' : 'Create partial'}
      actions={
        <Button onClick={onSubmit} data-testid='InspectionReadyModal.submit'>
          {data?.isEditing ? 'Save changes' : 'Confirm quantity'}
        </Button>
      }
      className='inspection-ready-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <form noValidate className='inspection-ready-form'>
        {!data?.isEditing && (
          <p>
            Select a quantity and delivery date to create a partial. A quantity
            lower than the full amount will split the work object.
          </p>
        )}
        <DatePicker
          name='deadline'
          label='Delivery date'
          value={
            data.deliveryDate?.value ||
            formatDateToHTMLDateString(
              parseISO(
                !!data?.isEditing
                  ? data?.partialDefaultDeadline
                  : data?.defaultDeadline,
              ),
            )
          }
          onChange={onDateChange}
          errors={
            data.deliveryDate?.value
              && isBefore(endOfDay(parseISO(data.deliveryDate?.value)), new Date())
              ? [...data.deliveryDate?.errors, {
                message: 'Warning: Delivery Date is set in the past',
                className: 'warning'
              }]
              : data.deliveryDate?.errors
          }
          data-testid='InspectionReadyModal.deliveryDate'
        />
        <Input
          label='Produced quantity'
          type='number'
          name='partialQuantity'
          value={data.partialQuantity.value}
          errors={data.partialQuantity.errors}
          onChange={(e) => onQuantityChange(e, data)}
          max={maxQuantity}
          min={1}
          data-testid='InspectionReadyModal.quantity'
        />
        <p className='form-description'>Maximum: {maxQuantity}</p>
        <ErrorBag
          data-testid='InspectionReadyModal.errors'
          errors={props.errors}
        />
      </form>
    </Modal>
  );
};

export default InspectionReadyModal;
