import classNames from 'classnames';
import './InspectionsResultPage.scss';
import Defect from 'lib/components/defect/Defect';

export default function AqlOverviewTable({
  inspection,
  showAQLStandards,
  isEnableInspectionFunctionalPlusMajor,
}) {
  // TODO: Export to utils(reused)
  const getDefectCellClass = (defectType) => {
    let isReject = false;

    let allowedCount = null;
    let actualCount = null;

    if (['critical', 'major', 'minor', 'functional'].includes(defectType)) {
      allowedCount = inspection[`${defectType}DefectAllowed`];
      actualCount = inspection[`${defectType}DefectsCountAll`];
    } else if (defectType === 'functionalPlusMajor') {
      allowedCount =
        Number(inspection.majorDefectAllowed) +
        Number(inspection.functionalDefectAllowed) +
        1;
      actualCount =
        Number(inspection.majorDefectsCountAll) +
        Number(inspection.functionalDefectsCountAll);
    }

    if (allowedCount !== null && actualCount !== null) {
      isReject = actualCount > allowedCount;
    }

    return classNames(
      'actual-defects-count',
      `is-${isReject ? 'rejected' : 'accepted'}`,
    );
  };

  return (
    !!inspection && (
      <table className='aql-overview-table'>
        <caption>Inspection standards</caption>
        <thead>
          <tr>
            <th></th>
            <th>Critical</th>
            <th>Major</th>
            <th>Minor</th>
            <th>Functional</th>
            {!!isEnableInspectionFunctionalPlusMajor && (
              <th>
                Functional
                <br />
                &amp; Major
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!!showAQLStandards && (
            <tr>
              <td>AQL</td>
              <td>{inspection.criticalDefectValue}</td>
              <td>{inspection.majorDefect}</td>
              <td>{inspection.minorDefect}</td>
              <td>{inspection.functionalDefect}</td>
              {!!isEnableInspectionFunctionalPlusMajor && (
                <td>
                  {Number(inspection.majorDefect) +
                    Number(inspection.functionalDefect)}
                </td>
              )}
            </tr>
          )}
          <tr>
            <td>Accept criteria</td>
            <td>{inspection.criticalDefectAllowed}</td>
            <td>{inspection.majorDefectAllowed}</td>
            <td>{inspection.minorDefectAllowed}</td>
            <td>{inspection.functionalDefectAllowed}</td>
            {!!isEnableInspectionFunctionalPlusMajor && (
              <td>
                {Number(inspection.majorDefectAllowed) +
                  Number(inspection.functionalDefectAllowed) +
                  1}
              </td>
            )}
          </tr>
          <tr>
            <td>Reject criteria</td>
            <td>{inspection.criticalDefectAllowed + 1}</td>
            <td>{inspection.majorDefectAllowed + 1}</td>
            <td>{inspection.minorDefectAllowed + 1}</td>
            <td>{inspection.functionalDefectAllowed + 1}</td>
            {!!isEnableInspectionFunctionalPlusMajor && (
              <td>
                {Number(inspection.majorDefectAllowed) +
                  Number(inspection.functionalDefectAllowed) +
                  2}
              </td>
            )}
          </tr>
          <tr>
            <td>Defects found</td>
            <td className={getDefectCellClass('critical')}>
              <Defect count={inspection.criticalDefectsCountAll} iconOnly />
            </td>
            <td className={getDefectCellClass('major')}>
              <Defect count={inspection.majorDefectsCountAll} iconOnly />
            </td>
            <td className={getDefectCellClass('minor')}>
              <Defect count={inspection.minorDefectsCountAll} iconOnly />
            </td>
            <td className={getDefectCellClass('functional')}>
              <Defect count={inspection.functionalDefectsCountAll} iconOnly />
            </td>
            {!!isEnableInspectionFunctionalPlusMajor && (
              <td className={getDefectCellClass('functionalPlusMajor')}>
                <Defect
                  count={
                    Number(inspection.majorDefectsCountAll) +
                    Number(inspection.functionalDefectsCountAll)
                  }
                  iconOnly
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    )
  );
}
