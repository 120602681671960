import {
  ANSWER_TYPE,
  ANSWER_TYPE_LABEL,
  MULTIPLE_CHOICE_ANSWER_TYPES,
} from 'config/questionOptions';

import useUOMs from 'lib/uomsService';
import Select from 'lib/components/select/Select';
import testId from './fixtures/testid';

function AnswersWidget({
  data,
  questionOptions,
  isDisabled,
  isInModal,
  onChange,
}) {
  const answerType = data.answerType?.value?.value;

  // TODO: this hook is also used in ExpectedMeasureWidget. We can store this value in parent context
  const { uoms } = useUOMs();

  const isAnswerOptionSelectVisible = MULTIPLE_CHOICE_ANSWER_TYPES.includes(
    answerType,
  );
  const isUnitSelectVisible = answerType === ANSWER_TYPE.BARCODE_INPUT;

  const isMissingAnswerOptions =
    !questionOptions?.answerOptions?.length && isAnswerOptionSelectVisible;

  return (
    <>
      <div className='flex'>
        <div>
          <Select
            data-testid={testId.answerType}
            isInModal={isInModal}
            className='answer-type'
            label='Answer type'
            options={questionOptions.answerType}
            value={data.answerType?.value}
            errors={data.answerType?.errors}
            onChange={(value) => {
              onChange('answerType', value);
            }}
            isDisabled={isDisabled}
          />
        </div>
        {isAnswerOptionSelectVisible && (
          <div>
            <Select
              isInModal={isInModal}
              className='answer-choice'
              label='Answers'
              data-testid={testId.answerChoice}
              value={data.answer?.value}
              errors={data.answer?.errors}
              options={questionOptions.answerOptions}
              onChange={(value) => {
                onChange('answer', value);
              }}
              isDisabled={isDisabled}
            />
          </div>
        )}
        {isUnitSelectVisible && (
          <div>
            <Select
              className='barcode-uom-select'
              label='UOM'
              data-testid={testId.answerUOM}
              options={uoms}
              value={data.expectedBarcodeUOM?.value}
              errors={data.expectedBarcodeUOM?.errors}
              onChange={(value) => {
                onChange('expectedBarcodeUOM', value);
              }}
            />
          </div>
        )}
      </div>
      {typeof isDisabled === 'string' && (
        <p className='field-description'>{isDisabled}</p>
      )}
      {isMissingAnswerOptions && (
        <p className='field-description'>
          To create a {ANSWER_TYPE_LABEL[answerType].toLowerCase()} question,
          please first define answer options in the settings/answers page
        </p>
      )}
    </>
  );
}

export default AnswersWidget;
