import ROLES from 'config/roles';

export const VIEW_ALL_WORK_OBJECTS_ROLES = [
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
];

export const VIEW_OWN_WORK_OBJECT_ROLES = [
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const ADD_WORK_OBJECT_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
  ROLES.INTERNAL_SOURCE_OWNER,
];
export const IMPORT_WORK_OBJECT_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];
export const ACCEPT_PARTIAL_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const READY_FOR_INSPECTION_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.INTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];
export const CREATE_PARTIAL_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
  ROLES.EXTERNAL_SOURCE_OWNER,
  ROLES.VENDOR,
];

export const WORK_OBJECT_WORKFLOW_ACTIONS_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
];

export const OPEN_INSPECTOR_DETAIL_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.COORDINATOR,
  ROLES.ASSET_OWNER,
  ROLES.INSPECTOR,
];
