export const INSPECTION_PLAN_STATUS = {
  /** Inspection Plan cannot be used for new inspections */
  DEACTIVATED: 'deactivated',
  /** Inspection Plan is incomplete and cannot be used for new inspections */
  DRAFT: 'draft',
  /** Inspection plan is active and able to be used for new inspections */
  PUBLISHED: 'published',
  /** Inspection plan is innacurate and needs to be manually verified */
  NEEDS_REVIEW: 'needs_review',
};

const INSPECTION_PLAN_STATUS_LABEL = {
  [INSPECTION_PLAN_STATUS.DRAFT]: 'Draft',
  [INSPECTION_PLAN_STATUS.DEACTIVATED]: 'Deactivated',
  [INSPECTION_PLAN_STATUS.PUBLISHED]: 'Published',
  [INSPECTION_PLAN_STATUS.NEEDS_REVIEW]: 'Needs review',
};

/** Statuses available on inspection plan creation */
export const CREATABLE_STATUS_LIST = [
  INSPECTION_PLAN_STATUS.DRAFT,
  INSPECTION_PLAN_STATUS.PUBLISHED,
  INSPECTION_PLAN_STATUS.DEACTIVATED,
];

/** List of plan statuses that can be transitioned to `needs_review` */
export const REVIEWABLE_PLAN_STATUS_LIST = [INSPECTION_PLAN_STATUS.PUBLISHED];

/**
 * Get formatted inspection plan status label or `Unknown`
 * @param {INSPECTION_PLAN_STATUS} status Inspection Plan status enum
 * @returns {string} Readable inspection Plan status
 */
export const getInspectionPlanStatusLabel = (status) =>
  INSPECTION_PLAN_STATUS_LABEL[status] || 'Unknown';
