import { forwardRef } from 'react';
import Radio from 'lib/components/radio/Radio';
import Select from 'lib/components/select/Select';

const CollapsibleRadio = forwardRef(({ threshold, ...props }, ref) =>
  props.options?.length <= threshold || !threshold ? (
    <Radio ref={ref} {...props} />
  ) : (
    <Select ref={ref} {...props} />
  ),
);

export default CollapsibleRadio;
