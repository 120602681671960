import useQueryParams from 'lib/useQueryParams';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  groupedQuestionToFormStateNew,
  inspectionPlanFormToData,
  inspectionPlanToFormStateNew,
  questionGroupToFormStateNew,
  questionTemplateToFormNew,
} from './dataTransformNew';
import inspectionPlansService from './inspectionPlansService';
import _cloneDeep from 'lodash/cloneDeep';
import useLanguages from 'lib/languageService';
import {
  CREATABLE_STATUS_LIST,
  getInspectionPlanStatusLabel,
  INSPECTION_PLAN_STATUS,
} from 'config/inspectionPlanStatus';
import useConfirm from 'lib/components/useConfirm/useConfirm';
import {
  publishOnHoldInspectionsDialog,
  unlinkOnAddDialog,
  unlinkOnReorderDialog,
} from './components/inspection-plans-form/InspectionPlansFormDialogs';
import {
  ALL_DEPENDENCY_OPTIONS,
  ANSWER_TYPE,
  DEPENDENCY,
  MULTIPLE_CHOICE_ANSWER_TYPES,
  QUESTION_TYPE,
  SIMPLE_DEPENDENCY_OPTIONS,
} from 'config/questionOptions';
import useInspectionPlansFormModals from './useInspectionPlansFormNewModals';
import { getTranslation } from 'lib/dataTransform';
import inspectionPlansFormValidatorNew, {
  processInspectionPlansAPIError,
} from './inspectionPlansFormValidatorNew';
import {
  entityToSelectOption,
  optionsToFormState,
  questionOptionsToFormState,
} from './dataTransform';
import { DEFAULT_LANGUAGE } from 'config/languages';
import INSPECTION_STATUS from 'config/inspectionStatus';

// TODO: Issues:
// ---- dep not shown
// ---- info sample size column undefined
//
// TODO: Refactor:
// ---- Split this into several vm's depending on context
// ---- Move presentational logic to the ui components
// ---- Extract repeating calls to util functions

// TODO: Rename to something meaningful
const isDefectsDisabled = (answerType) =>
  [
    ANSWER_TYPE.QUANTITATIVE_INPUT,
    ANSWER_TYPE.DATE_SELECTION,
    ANSWER_TYPE.TEXT_AREA_INPUT,
  ].includes(answerType);

const getInitialPlanData = () => ({
  aqlLevel: null,
  criticalDefectRule: null,
  criticalDefectValue: null,
  description: null,
  functionalDefect: null,
  inspectionType: null,
  linkedResource: null,
  majorDefect: null,
  minorDefect: null,
  name: null,
  overriddenQuestions: [],
  questionGroups: [],
  status: 'draft',
  validityValue: null,
  validityRange: null,
});

/* eslint-disable-next-line max-lines-per-function*/
const useInspectionPlansForm = ({ id }) => {
  const history = useHistory();
  const location = useLocation();
  const { languages } = useLanguages();
  const { confirm } = useConfirm();

  // Raw plan data from server
  const [planData, setPlanData] = useState(null);

  // Initial plan form data
  // TODO: Since this data can change(add quesitons, groups, etc), keep separate initial object under form, each group, each question
  const [planFormInitial, setPlanFormInitial] = useState(null);

  // Current plan form data
  const [planForm, setPlanForm] = useState(null);

  // Static aux data
  // TODO: Extract to separate vm
  const [planOptions, setPlanOptions] = useState(null);
  const [questionOptions, setQuestionOptions] = useState(null);
  const [planAnswers, setPlanAnswers] = useState(null);
  const [assetCustomMeasures, setAssetCustomMeasures] = useState(null);

  // Asset selector helper
  // TODO: Should be moved next to the actual component as it's not reused anywhere
  const [linkedAsset, setLinkedAsset] = useState(null);

  // Language selection
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);

  // Available inspection types
  const [inspectionTypes, setInspectionTypes] = useState(null);

  const modals = useInspectionPlansFormModals({ planForm, questionOptions });

  const { getParams } = useQueryParams(location, history);

  const {
    linkedResourceId,
    linkedResourceType,
    redirect: redirectQuery,
  } = getParams();

  // Updates the plan form state for a single group update
  const updatePlanFormGroup = (replacementData, gIndex) => {
    setPlanForm({
      ...planForm,
      questionGroups: planForm.questionGroups.map((_g, _gIndex) =>
        _gIndex === gIndex ? { ..._g, ...replacementData } : _g,
      ),
    });
  };

  // Updates the plan form state for a single group question update
  const updatePlanFormQuestion = (replacementData, gIndex, qIndex) => {
    updatePlanFormGroup(
      {
        questions: planForm.questionGroups[
          gIndex
        ].questions.map((_q, _qIndex) =>
          _qIndex === qIndex ? { ..._q, ...replacementData } : _q,
        ),
      },
      gIndex,
    );
  };

  const getGroupUnlinkChanges = (gIndex) => ({
    id: null,
    isCustom: true,
    questions: planForm.questionGroups[gIndex].questions.map((_q) => ({
      ..._q,
      groupedQuestionTemplateId: null,
    })),
  });

  const doUnlinkGroup = (gIndex) => {
    updatePlanFormGroup(getGroupUnlinkChanges(gIndex), gIndex);
  };

  const doRelinkGroup = (gIndex) => {
    const qgtOriginalGroupTemplateId =
      planForm.questionGroups[gIndex].originalGroupTemplateId;
    inspectionPlansService
      .getQuestionGroupTemplates({
        filters: {
          id: parseInt(qgtOriginalGroupTemplateId),
        },
      })
      .then((res) => {
        if (res?.data?.length !== 1) {
          throw new Error('Could not find question group template', res.data);
        }

        const qgData = {
          ...res.data[0],
          originalGroupTemplateId: res.data[0].id,
          isCustom: false,
        };

        const newqgForm = {
          ...questionGroupToFormStateNew(qgData, questionOptions),
        };

        setPlanForm({
          ...planForm,
          questionGroups: planForm.questionGroups.map((_g, _gIndex) =>
            _gIndex === gIndex ? newqgForm : _g,
          ),
        });
      })
      .catch((e) => {
        // XXX Global errors
        // TODO: Make consistent global errors
        console.warn("Couldn't relink group", e);
      });
  };

  // TODO: Since we are now keeping the dependency data separate from the question
  //       we should reuse the logic in Settings -> Q Groups where we can change the
  //       dependency just like we change question order without overriding/customizing
  //       the question. A side effect of this is, when relinking an overridden question
  //       we don't revert its dependency
  const doRelinkQuestion = (gIndex, qIndex) => {
    const g = planForm.questionGroups[gIndex]; //.originalGroupTemplateId;
    const q = planForm.questionGroups[gIndex].questions[qIndex];
    // .groupedQuestionTemplateId;

    // const gqtDataInitial = planData.questionGroups
    //   ?.find((_g) => _g.originalGroupTemplateId === g.originalGroupTemplateId)
    //   ?.questions?.find((_q) => _q.id === q.groupedQuestionTemplateId);

    const revertedQ = {
      groupedQuestionTemplateId: q.groupedQuestionTemplateId,
      groupedQuestionTemplateBodyId: q.groupedQuestionTemplateBodyId,
      groupedQuestionTemplateOverriddenId: null,
      order: q.order,
      isCustom: false,
      isOverridden: false,
      originalTemplateData: q.originalTemplateData,
      dependencyAction: q.dependencyAction.value,
      dependencyCriteria: q.dependencyCriteria,
      dependencyAnswerOptionOrder: q.dependencyAnswerOptionOrder,
      dependencyQuestion: q.dependencyQuestion.value,
      ...questionTemplateToFormNew(
        q.originalTemplateData,
        questionOptions,
        true,
      ),
    };

    const newQuestions = g.questions.map((_q, _qIndex) =>
      _qIndex === qIndex ? revertedQ : _q,
    );

    updatePlanFormGroup({ questions: newQuestions }, gIndex);
  };

  const doUnlinkQuestion = (gIndex, qIndex) => {
    const g = planForm.questionGroups[gIndex];
    const q = planForm.questionGroups[gIndex].questions[qIndex];

    const newQuestion = groupedQuestionToFormStateNew(
      {
        id: q.groupedQuestionTemplateId, // Keep GQT id and rest of the object the same
        order: q.order,
        useDependency: !!q?.dependencyAction?.value,
        dependencyAction: q?.dependencyAction?.value ?? false,
        dependencyCriteria: q?.dependencyCriteria ?? null,
        dependencyAnswerOptionOrder: q?.dependencyAnswerOptionOrder,
        dependencyQuestionTemplateOrder: q?.dependencyQuestion?.value,

        body: q.originalTemplateData, // The body is created from the original data so it replicates a pristine quesiton
      },
      questionOptions,
      {
        body: { ...q.originalTemplateData },
        replacementQuestionTemplateId: null, // Overridden question is initialized with a null id to be sent as not existing yet
      },
    );

    const newQuestions = g.questions.map((_q, _qIndex) =>
      _qIndex === qIndex ? newQuestion : _q,
    );

    updatePlanFormGroup({ questions: newQuestions }, gIndex);
  };

  const doAddCustomQuestionToGroup = async (gIndex) => {
    const isCustomQG = !!planForm.questionGroups[gIndex].isCustom;
    if (!isCustomQG && !(await confirm(unlinkOnAddDialog()))) {
      return;
    }

    const newQuestionGroup = {
      ...planForm.questionGroups[gIndex],
      // Only do unlink changes if group was linked
      ...(!isCustomQG ? getGroupUnlinkChanges(gIndex) : {}),
    };

    const newQuestion = groupedQuestionToFormStateNew(
      {
        order: planForm.questionGroups[gIndex].questions?.length,
        body: {
          isCustom: true,
        },
      },
      questionOptions,
    );

    newQuestionGroup.questions = [...newQuestionGroup.questions, newQuestion];

    setPlanForm({
      ...planForm,
      questionGroups: planForm.questionGroups.map((_g, _gIndex) =>
        _gIndex === gIndex ? newQuestionGroup : _g,
      ),
    });
  };

  // Find the resulting position after a move, if possible
  // TODO: Util f
  const findMoveQuestionAllowedIndex = (gIndex, qIndex, dir) => {
    const questions = planForm.questionGroups[gIndex].questions;
    const question = questions[qIndex];

    const dependencyQuestionIndex =
      question.dependencyQuestion?.value?.value ??
      question.dependencyQuestion?.value ??
      null;

    let firstDependentQuestionIndex = questions.findIndex(
      (_question) =>
        _question.dependencyQuestion?.value?.value === qIndex ||
        _question.dependencyQuestion?.value === qIndex,
    );
    if (firstDependentQuestionIndex === -1) firstDependentQuestionIndex = null;

    // Fail if
    // - trying to move up if the question is first or the previous question is the dependency
    // - trying to move down if the question is last or the next question is a dependent
    if (
      ['up', 'top'].includes(dir) &&
      (qIndex === 0 || dependencyQuestionIndex === qIndex - 1)
    ) {
      return null;
    } else if (
      ['down', 'bottom'].includes(dir) &&
      (qIndex === questions?.length - 1 ||
        firstDependentQuestionIndex === qIndex + 1)
    ) {
      return null;
    }

    switch (dir) {
      case 'up': // Move to previous index
        return qIndex - 1;
      case 'top': // Move to first index or the index after the dependency question
        return Number.isFinite(dependencyQuestionIndex)
          ? dependencyQuestionIndex + 1
          : 0;
      case 'down': // Move to next index
        return qIndex + 1;
      case 'bottom': // Move to last index or the index before the dependent question
        return Number.isFinite(firstDependentQuestionIndex)
          ? firstDependentQuestionIndex - 1
          : questions.length - 1;
      default:
    }

    console.debug('Invalid move dir', dir);
    return null;
  };

  const handleMoveQuestionInGroup = async (
    gIndex,
    qIndex,
    qIndexTarget,
    isDelete,
  ) => {
    if (!isDelete && !Number.isFinite(qIndexTarget)) {
      console.debug('Invalid mode - no target index for moving');
      return;
    }

    const isCustomQG = !!planForm.questionGroups[gIndex].isCustom;
    if (!isCustomQG && !(await confirm(unlinkOnReorderDialog()))) {
      return;
    }

    const newQuestionGroup = {
      ...planForm.questionGroups[gIndex],
      // Merge unlink changes only if we're moving questions and group is linked
      ...(!isDelete && !isCustomQG ? getGroupUnlinkChanges(gIndex) : {}),
    };

    const newQuestions = newQuestionGroup.questions.map(
      (_question, _qIndex) => ({
        ..._question,
        __previousIndex: _qIndex,
        dependencyQuestion: _question.dependencyQuestion,
      }),
    );

    const question = newQuestions[qIndex];

    newQuestions.splice(qIndex, 1);
    if (!isDelete) {
      newQuestions.splice(qIndexTarget, 0, question);
    }
    newQuestions.forEach((_question, _qIndex) => {
      _question.order = _qIndex;
      const depIndex =
        _question.dependencyQuestion?.value?.value ??
        _question.dependencyQuestion?.value ??
        null;
      if (depIndex !== null) {
        const newDepIndex = newQuestions.findIndex(
          (_depQuestion) => _depQuestion.__previousIndex === depIndex,
        );
        const newDepFormValue = {
          label: `#${newDepIndex + 1}`,
          value: newDepIndex,
        };
        if (Number.isFinite(_question.dependencyQuestion?.value?.value)) {
          _question.dependencyQuestion.value = newDepFormValue;
        } else {
          _question.dependencyQuestion = newDepFormValue;
        }
      }
    });
    newQuestions.forEach((_question) => {
      delete _question.__previousIndex;
    });

    newQuestionGroup.questions = newQuestions;

    // TODO: Use util func for update
    setPlanForm({
      ...planForm,
      questionGroups: planForm.questionGroups.map((_g, _gIndex) =>
        _gIndex === gIndex ? newQuestionGroup : _g,
      ),
    });
  };

  const doMoveQuestionInGroup = async (gIndex, qIndex, dir) => {
    const allowedTargetIndex = findMoveQuestionAllowedIndex(
      gIndex,
      qIndex,
      dir,
    );

    if (allowedTargetIndex === null) {
      console.debug('Not allowed to move', gIndex, qIndex, dir);
      return;
    }

    handleMoveQuestionInGroup(gIndex, qIndex, allowedTargetIndex);
  };

  // TODO: Util f
  const checkCanDeleteQuestionInGroup = (gIndex, qIndex) =>
    !!planForm.questionGroups[gIndex].questions[qIndex].isCustom &&
    planForm.questionGroups[gIndex]?.questions?.every((_q) => {
      const depIndex =
        _q.dependencyQuestion?.value?.value ??
        _q.dependencyQuestion?.value ??
        null;
      return depIndex !== qIndex;
    });

  const doDeleteQuestionInGroup = (gIndex, qIndex) => {
    if (!checkCanDeleteQuestionInGroup(gIndex, qIndex)) {
      console.debug('Cannot delete question - has dependents');
      return;
    }

    handleMoveQuestionInGroup(gIndex, qIndex, null, true);
  };

  const doAddQuestionGroup = (qgt) => {
    const newqg = {
      ...qgt,
      id: parseInt(qgt.id),
      originalGroupTemplateId: parseInt(qgt.id),
      isCustom: false,
    };

    const newqgForm = questionGroupToFormStateNew(newqg, questionOptions);

    const newQuestionGroups = [...planForm.questionGroups, newqgForm].sort(
      (a, b) => a.order - b.order,
    );

    setPlanForm({
      ...planForm,
      questionGroups: newQuestionGroups,
    });
  };

  const doRemoveQuestionGroup = (gIndex) => {
    // XXX: A nice approach would be to mark group as deleted and hide it instead of deleting, for easier undo.
    // XXX: An implication of this would be if you later add the same group - should replace, ask, etc.
    setPlanForm({
      ...planForm,
      questionGroups: planForm.questionGroups.filter(
        (_g, _gIndex) => _gIndex !== gIndex,
      ),
    });
  };

  const doSetLinkedResource = (linkedResource) => {
    // TODO: If insp type conflicts with the newly-selected resource - clear insp type selection
    // TODO: Should question groups always be cleared? What if we can reuse same inspection type?
    // Changing linked resource triggers reloading inspection types.
    // Maybe clearing the q groups list should happen after the new insp types list doesn't contain
    // the same type
    setPlanForm({
      ...planForm,
      linkedResource: { ...linkedResource },
      type: { value: '', errors: [] },
    });
  };

  const doAddToolToQuestion = (gIndex, qIndex) => {
    const tools = planForm.questionGroups[gIndex].questions[qIndex].tools;
    const newTools = [
      ...(tools?.length ? tools : []),
      { value: '', errors: [] },
    ];

    updatePlanFormQuestion({ tools: newTools }, gIndex, qIndex);
  };

  const doRemoveToolFromQuestion = (gIndex, qIndex, tIndex) => {
    const newTools = planForm.questionGroups[gIndex].questions[
      qIndex
    ].tools?.filter((_t, _tIndex) => _tIndex !== tIndex);

    updatePlanFormQuestion({ tools: newTools }, gIndex, qIndex);
  };

  const doAddAssetRefDocToQuestion = (gIndex, qIndex) => {
    const assetRefDocs =
      planForm.questionGroups[gIndex].questions[qIndex].assetReferenceDocNames;
    const newAssetRefDocs = [
      ...(assetRefDocs?.length ? assetRefDocs : []),
      { value: '', errors: [] },
    ];

    updatePlanFormQuestion(
      { assetReferenceDocNames: newAssetRefDocs },
      gIndex,
      qIndex,
    );
  };

  const doRemoveAssetRefDocToQuestion = (gIndex, qIndex, dIndex) => {
    const newAssetRefDocs = planForm.questionGroups[gIndex].questions[
      qIndex
    ].assetReferenceDocNames?.filter((_d, _dIndex) => _dIndex !== dIndex);

    updatePlanFormQuestion(
      { assetReferenceDocNames: newAssetRefDocs },
      gIndex,
      qIndex,
    );
  };

  /**
   * Init plan data
   */
  useEffect(() => {
    if (id) {
      inspectionPlansService
        .getInspectionPlan(id)
        .then((res) => {
          setPlanData(res);
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            history.push(`/inspection-plans`);
          }
        });
    } else {
      setPlanData(null);
    }
  }, [id, history]);

  /**
   * Init helper data
   */
  useEffect(() => {
    inspectionPlansService
      .getInspectionPlanOptions()
      .then((res) => {
        setPlanOptions(optionsToFormState(res));
        setQuestionOptions(questionOptionsToFormState(res));
      })
      .catch(() => console.warn('Error loading options.'));

    inspectionPlansService
      .getAnswers()
      .then((res) => setPlanAnswers(res.data))
      .catch(() => console.warn('Error loading answers.'));

    inspectionPlansService
      .getAssetCustomMeasures()
      .then((res) =>
        setAssetCustomMeasures(
          res.data.map(entityToSelectOption('label', 'id')),
        ),
      )
      .catch(() => console.warn('Error loading asset custom measures.'));
  }, []);

  /**
   * Init preselected linked asset
   * // XXX: How is this used?
   */
  useEffect(() => {
    if (linkedResourceType === 'asset' && linkedResourceId !== '') {
      inspectionPlansService
        .getAsset(linkedResourceId)
        .then((res) => setLinkedAsset(res));
    }
  }, [linkedResourceId, linkedResourceType]);

  /**
   * Initialize plan form data
   */
  useEffect(() => {
    // Only execute logic if we already have the options data
    if (planOptions && questionOptions) {
      // If we are editing(have id), execute only when planData is loaded
      if (id) {
        if (planData) {
          const _planForm = inspectionPlanToFormStateNew(
            planData,
            planOptions,
            questionOptions,
          );

          setPlanForm(_cloneDeep(_planForm));
          setPlanFormInitial(_cloneDeep(_planForm));
        }
      } else {
        // When starting with an empty form, initialize from init data object
        const _planForm = inspectionPlanToFormStateNew(
          getInitialPlanData(),
          planOptions,
          questionOptions,
        );
        setPlanForm(_cloneDeep(_planForm));
        setPlanFormInitial(_cloneDeep(_planForm));
      }
    }
  }, [id, planData, planOptions, questionOptions]);

  /**
   * Initialize languages
   */
  useEffect(() => {
    if (languages.length && !language) {
      setLanguage(languages[0].code);
    }
  }, [languages, language]);

  /**
   * Init Inspection Types list
   */
  useEffect(() => {
    // Abort if still waiting for linked asset to load
    if (linkedResourceType && linkedResourceId && !linkedAsset) {
      return;
    }

    let reqOpts = {};

    if (linkedAsset) {
      reqOpts.filters = {
        linkedResource: {
          id: linkedAsset.id,
          type: 'asset',
        },
      };
    } else if (planForm?.linkedResource?.id) {
      reqOpts.filters = {
        linkedResource: {
          id: planForm.linkedResource.id,
          type: planForm.linkedResource.resourceType,
        },
        type:
          planForm.linkedResource.resourceType === 'source'
            ? 'source'
            : undefined,
      };
    }
    inspectionPlansService
      .getInspectionTypes(reqOpts)
      .then((res) => {
        if (res?.data?.length) {
          setInspectionTypes(res.data.map(entityToSelectOption('name', 'id')));
        }
      })
      .catch(() => console.warn('Error loading inspection types.'));
  }, [
    JSON.stringify(planForm?.linkedResource),
    JSON.stringify(linkedAsset),
    linkedResourceType,
    linkedResourceId,
  ]);

  const doSetInspectionType = (v) => {
    const inspType = v?.value;

    if (inspType && inspType !== planForm?.type?.value?.value) {
      setPlanForm({
        ...planForm,
        type: {
          ...planForm?.type,
          value: v,
        },
      });
    }
  };

  const doLoadDefaultQuestionGroups = () => {
    const linkedResId = linkedAsset?.id ?? planForm?.linkedResource?.id;
    const linkedResType = linkedAsset?.id
      ? 'asset'
      : planForm?.linkedResource?.resourceType;
    const inspType = planForm?.type?.value?.value;

    if (inspType && linkedResId && linkedResType) {
      inspectionPlansService
        .getQuestionGroups(linkedResId, linkedResType, inspType)
        .then((res) => {
          let newQuestionGroups = [];

          if (res?.data?.length) {
            newQuestionGroups = res?.data?.map((qgt) => {
              const rslt = questionGroupToFormStateNew(
                {
                  ...qgt,
                  id: parseInt(qgt.id),
                  originalGroupTemplateId: parseInt(qgt.id),
                  isCustom: false,
                },
                questionOptions,
              );

              return rslt;
            });

            newQuestionGroups.sort((a, b) => a.order - b.order);
          }

          setPlanForm({
            ...planForm,
            questionGroups: newQuestionGroups,
          });
        })
        .catch(() => console.warn('Error getting new groups.'));
    }
  };

  const doSetDefectsList = (selected, gIndex, qIndex) => {
    const newDefects = selected?.length
      ? selected.map((_d) => ({ ..._d, selected: undefined }))
      : [];

    const newQuestions = planForm.questionGroups[
      gIndex
    ].questions.map((_q, _qIndex) =>
      _qIndex === qIndex ? { ..._q, defects: newDefects } : _q,
    );

    const newQuestionGroups = planForm.questionGroups.map((_g, _gIndex) =>
      _gIndex === gIndex ? { ..._g, questions: newQuestions } : _g,
    );

    setPlanForm({
      ...planForm,
      questionGroups: newQuestionGroups,
    });
  };

  const doRemoveDefect = (gIndex, qIndex, defect) => {
    const newSelected = planForm.questionGroups[gIndex].questions[
      qIndex
    ].defects.filter((_d) => _d.id !== defect.id);
    doSetDefectsList(newSelected, gIndex, qIndex);
  };

  const doRemoveQuestionDocument = (gIndex, qIndex, dIndex) => {
    const newDocuments = planForm.questionGroups[gIndex].questions[
      qIndex
    ].documents.filter((_d, _dIndex) => _dIndex !== dIndex);

    updatePlanFormQuestion({ documents: newDocuments }, gIndex, qIndex);
  };

  const doAddQuestionDocuments = (gIndex, qIndex, files) => {
    const currDocuments =
      planForm.questionGroups[gIndex].questions[qIndex].documents;

    const newDocuments = [
      ...(currDocuments?.length ? currDocuments : []),
      ...Array.from(files).map((file) => ({
        file: file,
        value: file.name.split('.').slice(0, -1).join('.'),
        errors: [],
      })),
    ];

    updatePlanFormQuestion({ documents: newDocuments }, gIndex, qIndex);
  };

  const doResetQuestionDocuments = (gIndex, qIndex) => {
    const newDocuments = _cloneDeep(
      planForm.questionGroups[gIndex].questions[qIndex].initial.documents,
    );

    updatePlanFormQuestion({ documents: newDocuments }, gIndex, qIndex);
  };

  const tryClearErrors = (v) => {
    if (v?.errors?.length) {
      return { ...v, errors: [] };
    } else {
      return v;
    }
  };

  const onChangeQuestionName = (gIndex, qIndex, v) => {
    const newName = tryClearErrors({
      ...planForm.questionGroups[gIndex].questions[qIndex].name,
    });

    let isReplaced = false;

    if (!newName.value?.length) newName.value = [];

    newName.value = newName.value?.map((_n) => {
      if (_n.language === language) {
        isReplaced = true;
        return { ..._n, text: v };
      }
      return _n;
    });

    if (!isReplaced) newName.value.push({ language, text: v });

    updatePlanFormQuestion({ name: newName }, gIndex, qIndex);
  };

  const onChangeQuestionDependencyCriteria = (gIndex, qIndex, v) => {
    const nestedV = v?.value ?? null;
    let qDepCriteria = nestedV;
    let qDepAnswerOptionOrder = null;

    if (nestedV !== null) {
      if (Number.isFinite(nestedV)) {
        qDepCriteria = DEPENDENCY.SPECIFIC_ANSWER;
        qDepAnswerOptionOrder = nestedV;
      } else if (Object.values(DEPENDENCY).includes(nestedV)) {
        qDepCriteria = nestedV;
        qDepAnswerOptionOrder = null;
      } else {
        console.warn('Invalid selected dependency criteria', gIndex, qIndex, v);
      }
    }

    updatePlanFormQuestion(
      {
        dependencyCriteria: qDepCriteria,
        dependencyAnswerOptionOrder: qDepAnswerOptionOrder,
      },
      gIndex,
      qIndex,
    );
  };

  const onChangeDefectsWeight = (gIndex, qIndex, dWeightV, dIndex) => {
    const newDefects = planForm.questionGroups[gIndex].questions[
      qIndex
    ].defects.map((_d, _dIndex) =>
      _dIndex === dIndex ? tryClearErrors({ ..._d, weight: dWeightV }) : _d,
    );

    updatePlanFormQuestion({ defects: newDefects }, gIndex, qIndex);
  };

  // TODO: Util f
  const generateQuestionUpdates = (k, v) => {
    let q = { [k]: v };

    switch (k) {
      case 'type':
        if (v?.value?.value === QUESTION_TYPE.INFORMATIONAL_NON_AQL)
          q.useAqlLevel = { value: false, errors: [] };
        break;
      case 'answerType':
        if (isDefectsDisabled(v?.value?.value)) q.defects = [];
        q.expectedBarcodeUOM = { value: null, errors: [] };
        break;
      case 'dependencyAction':
        if (!v?.value?.value) {
          q.dependencyCriteria = null;
          q.dependencyAnswerOptionOrder = null;
          q.dependencyQuestion = { value: null, errors: [] };
        }
        break;
      case 'dependencyQuestion':
        q.dependencyCriteria = null;
        q.dependencyAnswerOptionOrder = null;
        break;
      case 'dynamicAction':
        if (!v?.value?.value) {
          q.dynamicCriteria = { value: '', errors: [] };
          q.dynamicRule = { value: null, errors: [] };
        }
        break;
      case 'dynamicRule':
        if (v?.value?.value === 'first_inspection')
          q.dynamicCriteria = { value: '1', errors: [] };
        break;
      case 'expectedMeasureTableResult':
        if (v?.value?.value === 'Custom' || !v?.value?.value) {
          // XXX: This doesn't make sense - when type is quantative, barcode UOM is not visible
          q.expectedBarcodeUOM = { value: null, errors: [] };
          if (v?.value?.value === 'Custom') {
            // XXX: Shouldn't we clear the weights as they're hidden in Custom mode?
            q.upperTolerance = { value: '', errors: [] };
            q.lowerTolerance = { value: '', errors: [] };
          }
        }
        break;
      case 'customExpectedMeasureValue':
        if (![null, undefined].includes(v?.value)) {
          let _v;
          if (typeof v?.value === 'number') {
            _v = v.value.toString();
          }
          _v = (v?.value || '').replace(',', '.').replace(/[^0-9.-]/g, '');
          q[k].value = _v;
        }
        break;
      case 'lowerTolerance':
      case 'upperTolerance':
      case 'dynamicCriteria':
      case 'sampleQty':
        if (![null, undefined].includes(v?.value)) {
          // XXX: See if/when this workaround is needed
          let _v;
          if (typeof v?.value === 'number') {
            // Hard to debug type-casting happens on api submit
            _v = v.value.toString();
          }
          // XXX: Not a good way to guard for invalid numbers.
          _v = (v?.value || '').replace(',', '.').replace(/[^0-9.]/g, '');
          q[k].value = _v;
        }
        break;
      default:
    }

    return q;
  };

  // TODO: Separate error model from form model!
  const onChangeQuestionInput = (gIndex, qIndex, cK, cV, cI) => {
    // TODO: The question form itself should have separate onChange handlers for specific cases
    if (cK === 'name') {
      onChangeQuestionName(gIndex, qIndex, cV);
      return;
    }

    if (cK === 'dependencyCriteria') {
      onChangeQuestionDependencyCriteria(gIndex, qIndex, cV);
      return;
    }

    if (cK === 'defects.weight') {
      onChangeDefectsWeight(gIndex, qIndex, cV, cI);
      return;
    }

    // TODO: Compound values should also be handled by specific onChange handlers instead of complex logic
    const isNestedValue = (_v) =>
      'errors' in _v ||
      ('value' in _v && !('label' in _v)) ||
      (_v.value && 'value' in _v.value);

    const question = planForm.questionGroups[gIndex].questions[qIndex];

    if (!(cK && cK in question)) {
      console.warn('Invalid key', gIndex, qIndex, cK, cV, cI, question);
      return;
    }

    const isValArrayItem = Number.isFinite(cI);

    const currInnerValue = isNestedValue(question[cK])
      ? question[cK].value
      : question[cK];

    let newValue = cV;

    if (isValArrayItem) {
      newValue = currInnerValue?.map((_v, _vIndex) => {
        if (_vIndex !== cI) return _v;
        return isNestedValue(_v) ? tryClearErrors({ ..._v, value: cV }) : cV;
      });
    }

    if (isNestedValue(question[cK])) {
      newValue = tryClearErrors({ ...question[cK], value: newValue });
    }

    const questionUpdates = generateQuestionUpdates(cK, newValue);

    updatePlanFormQuestion(questionUpdates, gIndex, qIndex);
  };

  const getInspectionTypes = () => {
    const inspectionTypesList = inspectionTypes?.length
      ? [...inspectionTypes]
      : [];

    if (
      planFormInitial?.type?.value &&
      planFormInitial.linkedResource?.id &&
      planFormInitial.linkedResource.id === planForm?.linkedResource?.id &&
      !inspectionTypes?.some((_it) => _it.value === planFormInitial.type.value)
    ) {
      inspectionTypesList.push(planFormInitial.type.value);
    }
    return inspectionTypesList;
  };

  const getDependentQuestions = (gIndex, qIndex) => {
    const questions = planForm.questionGroups[gIndex].questions;

    const dependents = questions.filter(
      (_q, _qIndex) =>
        _qIndex > qIndex &&
        (_q.dependencyQuestion?.value?.value === qIndex ||
          _q.dependencyQuestion?.value === qIndex),
    );

    return dependents;
  };

  const prepareUploadDocumentsList = (savedPlanData) => {
    const uploadList = [];

    const findSavedQuestionId = (_g, _q, isOverridden) => {
      let qId = null;

      if (isOverridden) {
        savedPlanData?.overriddenQuestions?.every((_soq) => {
          if (
            `${_soq?.questionTemplateId}` ===
              `${_q?.groupedQuestionTemplateBodyId}` &&
            `${_soq?.originalGroupTemplateId}` ===
              `${_g?.originalGroupTemplateId}`
          ) {
            qId = _soq?.replacementQuestionTemplateId;
            if (!qId)
              console.warn(
                'Saved overridden template does not return id',
                _soq,
              );
          }
          return !qId;
        });
      } else {
        savedPlanData?.questionGroups?.every((_sg) => {
          if (_sg?.originalGroupTemplateId === _g?.originalGroupTemplateId)
            _sg?.questions?.every((_sq) => {
              if (_sq?.order === _q?.order) {
                qId = _sq?.body?.id;
                if (!qId)
                  console.warn('Saved template does not return id', _sg, _sq);
              }
              return !qId;
            });

          return !qId;
        });
      }

      return qId;
    };

    planForm?.questionGroups?.forEach((_g) => {
      _g?.questions?.forEach((_q) => {
        _q?.documents?.forEach((_d) => {
          if (!_d?.file?.url && _d?.file instanceof File) {
            const savedQuestionId = findSavedQuestionId(
              _g,
              _q,
              !!_q?.isOverridden,
            );
            if (savedQuestionId) {
              uploadList.push({
                questionId: savedQuestionId,
                value: _d.value,
                file: _d.file,
              });
            }
          }
        });
      });
    });

    return uploadList;
  };

  const uploadQuestionDocuments = (savedPlanData) => {
    const uploadList = prepareUploadDocumentsList(savedPlanData);

    return uploadList?.length
      ? Promise.all(
          uploadList.map((_d) =>
            inspectionPlansService.saveDocument(
              _d.questionId,
              _d.value,
              _d.file,
            ),
          ),
        )
      : Promise.resolve();
  };

  const showConfirmDialogOnSave = async () => {
    let isConfirmedSave = true;

    const initialStatus = planFormInitial?.status?.value;
    const currentStatus = planForm?.status?.value;

    if (
      planForm?.id &&
      initialStatus === INSPECTION_PLAN_STATUS.NEEDS_REVIEW &&
      currentStatus === INSPECTION_PLAN_STATUS.PUBLISHED
    ) {
      const onHoldInspectionCount = await (() =>
        inspectionPlansService
          .getInspections({
            filters: {
              status: INSPECTION_STATUS.ON_HOLD,
              inspectionPlanId: planForm.id,
            },
          })
          .then((res) => res.count))();

      if (onHoldInspectionCount) {
        isConfirmedSave = !!(await confirm(
          publishOnHoldInspectionsDialog({ count: onHoldInspectionCount }),
        ));
      }
    }

    return isConfirmedSave;
  };

  const doSaveInspectionPlan = async () => {
    const isConfirmedSave = await showConfirmDialogOnSave();

    if (!isConfirmedSave) return;

    const newPlanData = { ...inspectionPlanFormToData(planForm) };

    if (linkedAsset) {
      newPlanData.linkedResource = {
        id: linkedAsset.id,
        type: 'asset',
      };
    }

    inspectionPlansFormValidatorNew(planForm)
      .then((data) => {
        inspectionPlansService
          .saveInspectionPlanNew(newPlanData)
          .then((res) => {
            console.debug('Save success', res);
            return uploadQuestionDocuments(res).then((resUpload) => {
              console.debug('Upload success', resUpload);
              return res;
            });
          })
          .then((res) => {
            console.debug('After save and upload', res);
            if (redirectQuery) {
              return history.replace(redirectQuery);
            } else {
              return history.push(`/inspection-plans/${res.id}`);
            }
          })
          .catch((e) => {
            let newPlanForm = _cloneDeep(planForm);
            if (e.response.data.errorCode === 'entity_body_001') {
              newPlanForm = processInspectionPlansAPIError(
                e.response.data.details,
                planForm,
              );
            } else {
              newPlanForm = {
                ...newPlanForm,
                errors: [
                  'An error has occured while performing this operation. Please try again',
                ],
              };
            }
            setPlanForm(newPlanForm);
          })
          .finally(() => {
            document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
          });
      })
      .catch((e) => {
        console.log('exception', e, e?.payload);
        if (e?.payload) {
          setPlanForm({ ...e.payload });
        }
      })
      .finally(() => {
        document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
      });
  };

  // TODO: UI-related - move to component
  const planStatusOptions = [
    ...CREATABLE_STATUS_LIST,
    ...(planFormInitial?.status?.value === INSPECTION_PLAN_STATUS.NEEDS_REVIEW
      ? [INSPECTION_PLAN_STATUS.NEEDS_REVIEW]
      : []),
  ].map((status) => ({
    label: getInspectionPlanStatusLabel(status),
    value: status,
  }));

  const getDependencyAnswerOptions = (gIndex, qIndex) => {
    if (!(Number.isFinite(gIndex) && Number.isFinite(qIndex))) {
      return [];
    }

    const g = planForm?.questionGroups[gIndex];
    const q = g?.questions[qIndex];
    const qDependencyIndex =
      q.dependencyQuestion?.value?.value ?? q.dependencyQuestion?.value ?? null;

    if (!Number.isFinite(qDependencyIndex)) {
      return [];
    }

    const qDependency = g.questions.find(
      (_q, _qIndex) => _qIndex === qDependencyIndex,
    );

    if (!qDependency) {
      console.warn(
        'Could not find the dependency question',
        g,
        q,
        qDependencyIndex,
      );
      return [];
    }

    const depAnswerType =
      qDependency.answerType?.value?.value ??
      qDependency.answerType?.value ??
      null;
    const depAnswerId =
      qDependency.answer?.value?.value ?? qDependency.answer?.value ?? null;

    let options = [];
    const answerToOption = ({ label, value, order }) => ({
      label: `Is answered ${
        Array.isArray(label) ? getTranslation(label).display : label
      }`,
      value: value ?? order,
    });

    if (MULTIPLE_CHOICE_ANSWER_TYPES.includes(depAnswerType)) {
      if (depAnswerId) {
        options = (
          planAnswers?.find((opt) => opt.id === depAnswerId)?.options || []
        ).map(answerToOption);
      }

      return [...options, ...ALL_DEPENDENCY_OPTIONS];
    } else {
      return [...ALL_DEPENDENCY_OPTIONS, ...SIMPLE_DEPENDENCY_OPTIONS];
    }
  };

  return {
    modals,
    planData,
    planFormInitial,
    planForm,
    setPlanForm,
    planOptions,
    planStatusOptions,
    questionOptions,
    planAnswers,
    assetCustomMeasures,
    linkedAsset,
    getInspectionTypes,
    getDependentQuestions,
    language,
    setLanguage,
    languages,
    doUnlinkGroup,
    doRelinkGroup,
    doRelinkQuestion,
    doUnlinkQuestion,
    doAddCustomQuestionToGroup,
    doAddQuestionGroup,
    doRemoveQuestionGroup,
    findMoveQuestionAllowedIndex,
    doMoveQuestionInGroup,
    doDeleteQuestionInGroup,
    doSetLinkedResource,
    doSetInspectionType,
    doLoadDefaultQuestionGroups,
    doAddToolToQuestion,
    doRemoveToolFromQuestion,
    doAddAssetRefDocToQuestion,
    doRemoveAssetRefDocToQuestion,
    doSetDefectsList,
    doRemoveDefect,
    doRemoveQuestionDocument,
    doAddQuestionDocuments,
    doResetQuestionDocuments,
    onChangeQuestionInput,
    getDependencyAnswerOptions,
    doSaveInspectionPlan,
  };
};

export default useInspectionPlansForm;
