import InspectionsForm from '../inspections-form/InspectionsForm';
import './InspectionsAddPage.scss';

const InspectionsAddPage = (props) => {
  return (
    <div className='inspections-add-page container-m'>
      <div className='row'>
        <h2>New inspection</h2>
      </div>
      <div className='row'>
        <InspectionsForm {...props} />
      </div>
    </div>
  );
};

export default InspectionsAddPage;
