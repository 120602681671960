import makeReducer from 'lib/makeReducer';

export const CONFIRM_ACTIONS = {
  SHOW_CONFIRM: 'SHOW_CONFIRM',
  HIDE_CONFIRM: 'HIDE_CONFIRM',
};

export const INITIAL_STATE = {
  isOpen: false,
  body: '',
  confirm: 'Confirm',
  cancel: 'Cancel',
  title: '',
};

const CONFIRM_REDUCER_CONFIG = {
  [CONFIRM_ACTIONS.SHOW_CONFIRM]: (state, action) => {
    const {
      title = initialState.title,
      body = initialState.body,
      confirm = initialState.confirm,
      cancel = initialState.cancel,
    } = action.payload;

    return {
      isOpen: true,
      body,
      title,
      confirm,
      cancel,
    };
  },
  [CONFIRM_ACTIONS.HIDE_CONFIRM]: () => {
    return initialState;
  },
};

export const { reducer, initialState } = makeReducer(
  CONFIRM_REDUCER_CONFIG,
  INITIAL_STATE,
);
