import React from 'react';

import { getMinFutureCalendarDate } from 'lib/dateHelpers';
import {
  ADD_INSPECTIONS_ROLES,
  EXPORT_INSPECTIONS_ROLES,
} from 'modules/inspections/inspectionsPermissions';

import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import DateRangePicker from 'lib/components/date-range-picker/DateRangePicker';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import Select from 'lib/components/select/Select';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import InspectionsTable from 'lib/components/inspections-table/InspectionsTable';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Modal from 'lib/components/modal/Modal';
import DatePicker from 'lib/components/date-picker/DatePicker';
import Pagination from 'lib/components/pagination/Pagination';

import InspectorsModal from '../inspectors-modal/InspectorsModal';
import useInspectionsList from '../../useInspectionsList';

import { ReactComponent as AddIcon } from 'assets/images/plus-solid.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';

import './InspectionsListPage.scss';
import InspectionsListPageBulkConfirmModal from './InspectionsListPageBulkConfirmModal';

function formatSelectedInspectionsText(num) {
  return num === 1 ? '1 inspection selected' : `${num} inspections selected`;
}

export default function InspectionsListPage(props) {
  const list = useInspectionsList(props);

  const isAssignInspectorDisabled =
    !list.state.availableInspectors.selectedInspector;
  const { page, pageSize, count } = list.state.availableInspectors;
  const isPaginationAvailable = !!(page && pageSize && count);

  const inspectorModalActions = (
    <>
      <Button
        disabled={isAssignInspectorDisabled}
        onClick={list.userClicksAssignInspector}
      >
        {list.state.inspectionDate ? 'Assign inspector and date' : 'Assign'}
      </Button>
      {isPaginationAvailable && (
        <Pagination
          setPage={list.userSetsInspectorsModalPage}
          page={page}
          pageSize={pageSize}
          count={count}
          threshold={3}
        />
      )}
    </>
  );
  const dateModalActions = (
    <Button
      onClick={list.userAssignsDate}
      disabled={!list.state.inspectionDate}
    >
      Assign
    </Button>
  );
  const getInspectorModalRowClass = (record) =>
    record.id === list.state.availableInspectors.selectedInspector?.id
      ? 'row-selected'
      : '';

  const numSelectedInspections = list.state.selectedInspections.length;

  return (
    <div className='inspections-list-page'>
      <div className='row inspections-list-header container-m'>
        <h2>Inspections</h2>
        <div className='actions-container'>
          <ProtectedComponent allowed={EXPORT_INSPECTIONS_ROLES}>
            <Button
              onClick={list.userClicksExportResults}
              data-testid='InspectionsListPage.export'
            >
              Export results
            </Button>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_INSPECTIONS_ROLES}>
            <DropdownMenu
              placement='bottom-end'
              data-testid='InspectionsListPage.newDropdown'
              items={[
                <MenuItem
                  label='New inspection'
                  icon={<AddIcon />}
                  onClick={() => props.history.push('/inspections/add')}
                />,
                <MenuItem
                  label='New third party inspection'
                  icon={<DownloadIcon />}
                  onClick={() =>
                    props.history.push('/inspections/add-third-party')
                  }
                />,
              ]}
            >
              <AddButton data-testid='InspectionsListPage.new'>
                New inspection
              </AddButton>
            </DropdownMenu>
          </ProtectedComponent>
        </div>
      </div>
      {!!list.state.errors.length && (
        <div className='row container-m errors-container'>
          <ErrorBag
            errors={list.state.errors}
            data-testid='InspectionListPage.errors'
          />
        </div>
      )}
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='InspectionsListPage.search'
          />
        </div>
        {list.showFilter.atSource && (
          <div className='filter'>
            <label>Source:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.atSource}
              value={list.getDefaultSource()}
              onChange={(option) => list.setSource(option.value)}
              isSearchable={true}
              menuPlacement='auto'
            />
          </div>
        )}
        {list.showFilter.target && (
          <div className='filter'>
            <label>Target:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.target}
              value={list.getDefaultTarget()}
              onChange={(option) => list.setTarget(option.value)}
              isSearchable={false}
              menuPlacement='auto'
            />
          </div>
        )}
        {list.showFilter.type && (
          <div className='filter'>
            <label>Type:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.inspection}
              value={list.getDefaultInspection()}
              onChange={(option) => list.setInspection(option.value)}
              isSearchable={false}
              menuPlacement='auto'
            />
          </div>
        )}
        <div className='filter'>
          <label>Status:</label>
          <Select
            className='filter-select'
            options={list.state.filterOptions.status}
            value={list.getDefaultStatus()}
            onChange={(option) => list.setStatus(option.value)}
            isSearchable={false}
            menuPlacement='auto'
          />
        </div>
        <div className='filter'>
          <label>Origin:</label>
          <Select
            className='filter-select'
            options={list.state.filterOptions.isThirdParty}
            value={list.getDefaultIsThirdParty()}
            onChange={(option) => list.setIsThirdParty(option.value)}
            isSearchable={false}
            menuPlacement='auto'
          />
        </div>
        {list.showFilter.inspector && (
          <div className='filter'>
            <label>Inspector:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.inspector}
              value={list.getDefaultInspector()}
              onChange={(option) => list.setInspector(option.value)}
              isSearchable={true}
              menuPlacement='auto'
            />
          </div>
        )}
        {list.showFilter.city && (
          <div className='filter'>
            <label>City:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.city}
              value={list.getDefaultCity()}
              onChange={(option) => list.setCity(option.value)}
              isSearchable={true}
              menuPlacement='auto'
            />
          </div>
        )}
        {list.showFilter.inspectionDate && (
          <div className='filter'>
            <label>Inspection date:</label>
            <DateRangePicker
              className='filter-date-range'
              value={[
                list.state.filters.inspectionDateStart,
                list.state.filters.inspectionDateEnd,
              ]}
              onChange={(range) => list.setInspectionDateFilter(range)}
              isClearable
            />
          </div>
        )}
        {list.showFilter.inspectionWindow && (
          <div className='filter'>
            <label>Inspection window:</label>
            <DateRangePicker
              className='filter-date-range'
              value={[
                list.state.filters.inspectionWindowStart,
                list.state.filters.inspectionWindowEnd,
              ]}
              onChange={(range) => list.setInspectionWindowFilter(range)}
              isClearable
            />
          </div>
        )}
        {list.showFilter.lastUsageDecision && (
          <div className='filter'>
            <label>Last Taken Decision:</label>
            <Select
              className='filter-select'
              options={list.state.filterOptions.lastUsageDecision}
              value={list.getDefaultLastUsageDecision()}
              onChange={(option) => list.setLastUsageDecision(option.value)}
              isSearchable
            />
          </div>
        )}
      </div>

      <div className='inspections-table'>
        <InspectionsTable
          actions={
            !!numSelectedInspections && (
              <div className='row action-banner-container'>
                <div className='info'>
                  {formatSelectedInspectionsText(numSelectedInspections)}
                </div>
                <div className='actions'>
                  <Button
                    onClick={list.userOpensInspectorsModal}
                    className='link-button'
                  >
                    Assign inspector
                  </Button>
                  <Button
                    onClick={list.userOpensDateModal}
                    className='link-button'
                  >
                    Assign date
                  </Button>
                  <Button
                    onClick={list.userClicksCancelSelect}
                    className='link-button'
                  >
                    Clear selection
                  </Button>
                </div>
              </div>
            )
          }
          data={list.state.data}
          overflowX
          selectedRows={list.state.selectedInspections.map((i) => i.id)}
          disabledRows={list.getDisabledInspectionIds()}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          selectable={list.isTableSelectable}
          userClicksOnRow={list.userClicksOnRow}
          userChecksRow={list.userChecksRow}
          userChecksHeader={list.userChecksHeader}
          showColumns={list.showColumns}
        />
      </div>
      <InspectorsModal
        title='Assign inspector'
        data-testid='InspectionsListPage.inspectorsModal'
        data={list.state.availableInspectors.list}
        isOpen={list.state.availableInspectors.isModalOpen}
        sortBy={list.state.availableInspectors.sortBy}
        sortOrder={list.state.availableInspectors.sortOrder}
        page={list.state.availableInspectors.page}
        setPage={list.userSetsInspectorsModalPage}
        count={list.state.availableInspectors.count}
        pageSize={10}
        onRequestClose={list.userCancelsInspectorsModal}
        onRowClick={list.userSelectsInspector}
        setSortBy={list.userSortsInspectors}
        onSearchChange={list.userSearchesInspectors}
        search={list.state.availableInspectors.search}
        onDateChange={list.userChangesInspectionDate}
        inspectionDate={list.state.inspectionDate}
        actions={inspectorModalActions}
        className='inspection-list--inspector-modal'
        rowClassName={getInspectorModalRowClass}
        isLoading={list.state.availableInspectors.isLoading}
        emptyState={
          <>
            <p>
              There are no available inspectors linked with all sources of the
              selected inspections.
            </p>
            <br />
            <p>Please adjust your selection and try again.</p>
          </>
        }
      />
      <Modal
        data-testid='InspectionsListPage.dateModal'
        isOpen={list.state.isDateModalOpen}
        onRequestClose={list.userClosesDateModal}
        title='Assign date'
        className='inspection-list--date-modal'
        actions={dateModalActions}
      >
        <DatePicker
          min={getMinFutureCalendarDate()}
          value={list.state.inspectionDate}
          onChange={list.userChangesInspectionDate}
        />
      </Modal>

      <InspectionsListPageBulkConfirmModal
        data={list?.state?.bulkUpdateResultModal}
        onClose={list.resetBulkUpdateResultModal}
      />
    </div>
  );
}
