import React from 'react';

import Input from 'lib/components/input/Input';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Button from 'lib/components/button/Button';
import useResetPasswordForm from '../../useResetPasswordForm';

import { ReactComponent as MailIcon } from 'assets/images/mail.svg';

function ResetPasswordForm() {
  const { state, onChangeEmail, onSubmit } = useResetPasswordForm();

  return (
    <div className='reset-password-form'>
      <form onSubmit={onSubmit} noValidate={true}>
        <Input
          type='email'
          name='email'
          value={state.email.value}
          errors={state.email.errors}
          onChange={onChangeEmail}
          placeholder='Email'
          renderLeftInsert={() => <MailIcon fill='#6320ee' />}
        />

        <ErrorBag errors={state.formErrors} />

        {state.messages.length > 0 && (
          <div className='messages instructions'>
            {state.messages.map((message, index) => {
              return (
                <div key={`message-${index}`} className='message-item'>
                  {message}
                </div>
              );
            })}
          </div>
        )}

        <Button type='submit' disabled={state.loading}>
          Send instructions
        </Button>
      </form>
    </div>
  );
}

export default ResetPasswordForm;
