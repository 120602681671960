import { ReactComponent as ExportIcon } from 'assets/images/download.svg';
import AddButton from 'lib/components/add-button/AddButton';
import IconButton from 'lib/components/icon-button/IconButton';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import Select from 'lib/components/select/Select';
import WorkObjectsTable from 'lib/components/work-objects-table/WorkObjectsTable';
import useWorkObjectList from 'modules/work-objects/useWorkObjectsList';
import {
  ADD_WORK_OBJECT_ROLES,
  IMPORT_WORK_OBJECT_ROLES,
} from 'modules/work-objects/workObjectsPermissions';

import { ReactComponent as ImportIcon } from 'assets/images/upload.svg';
import { downloadResponse } from 'lib/download';

import workObjectService from 'modules/work-objects/workObjectsService';
import { useState } from 'react';
import './WorkObjectsListPage.scss';

export default function WorkObjectsListPage(props) {
  const [isExportWorkObjectsBusy, setIsExportWorkObjectsBusy] = useState(false);

  const doExportWorkObjects = () => {
    setIsExportWorkObjectsBusy(true);

    workObjectService
      .exportWorkObjects()
      .then((data) => {
        downloadResponse(data, 'work-objects.csv');
      })
      .finally(() => {
        setIsExportWorkObjectsBusy(false);
      });
  };

  const list = useWorkObjectList(props);

  return (
    <div className='work-objects-list-page'>
      <div className='row work-objects-list-header container-m'>
        <h2>Work objects</h2>
        <div className='header-buttons'>
          <ProtectedComponent allowed={IMPORT_WORK_OBJECT_ROLES}>
            <IconButton
              prefix={<ExportIcon />}
              disabled={isExportWorkObjectsBusy}
              onClick={doExportWorkObjects}
              data-testid='WorkObjectsListPage.exportButton'
              className='margin-right'
            >
              Export work objects
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={IMPORT_WORK_OBJECT_ROLES}>
            <IconButton
              prefix={<ImportIcon />}
              onClick={() => props.history.push('/work-objects/import')}
              data-testid='WorkObjectsListPage.importWorkObjectsButton'
            >
              Import work objects
            </IconButton>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_WORK_OBJECT_ROLES}>
            <AddButton
              onClick={() => props.history.push('/work-objects/add')}
              data-testid='WorkObjectsListPage.add'
            >
              New work object
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row filter-bar container-m'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='WorkObjectsListPage.search'
          />
        </div>
        <div className='filters-container'>
          <div className='filter'>
            <label>Source:</label>
            <Select
              className='filter-select'
              isSearchable={false}
              menuPlacement='auto'
              {...list.filters.getProps('source')}
            />
          </div>
          <div className='filter'>
            <label>Destination:</label>
            <Select
              className='filter-select'
              isSearchable={false}
              menuPlacement='auto'
              {...list.filters.getProps('destination')}
            />
          </div>
          <div className='filter'>
            <label>Vendor:</label>
            <Select
              className='filter-select'
              isSearchable={false}
              menuPlacement='auto'
              {...list.filters.getProps('vendor')}
            />
          </div>
          {list.showFilters.workflowId && (
            <div className='filter'>
              <label>Workflow:</label>
              <Select
                className='filter-select'
                isSearchable={false}
                menuPlacement='auto'
                {...list.filters.getProps('workflowId')}
              />
            </div>
          )}
          <div className='filter'>
            <label>Progress:</label>
            <Select
              className='filter-select'
              isSearchable={false}
              menuPlacement='auto'
              {...list.filters.getProps('progress')}
            />
          </div>
          <div className='filter'>
            <label>Status:</label>
            <Select
              className='filter-select'
              isSearchable={false}
              menuPlacement='auto'
              {...list.filters.getProps('byStatus')}
            />
          </div>
          <div className='filter'>
            <label>Source city:</label>
            <Select
              className='filter-select'
              isSearchable={true}
              menuPlacement='auto'
              {...list.filters.getProps('city')}
            />
          </div>
        </div>
      </div>
      <div className='work-object-table'>
        <WorkObjectsTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
          overflowX
        />
      </div>
    </div>
  );
}
