import _cloneDeep from 'lodash/cloneDeep';

import { DEFAULT_RESULT_RECORDING_TYPE } from 'config/questionOptions';
import { defectToFormState, entityToSelectOption } from './dataTransform';

// TODO: Release refactor:
// ----- Cleanup the dataTransform file from old/unused stuff
// ----- Merge this new logic in it
// ----- Delete this file
//
// TODO: Long-run refactor:
// ----- Split the transform methods into smaller files specific to components
// ----- Refactor UI and VM to use raw server data instead of transforming it from- and to-
// ----- Remove all presentational models from data - errors, value/label pairs, etc.

// Converts a single q template to form model
export const questionTemplateToFormNew = (
  qt,
  questionOptions,
  isDetailView,
) => {
  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === qt[questionKey],
    ) ?? null;

  const valWithErrs = (v) => (!!isDetailView ? v : { value: v, errors: [] });

  const rslt = {
    answer: valWithErrs(
      qt.answer
        ? {
            label: qt.answer?.name,
            value: qt.answer?.id,
          }
        : null,
    ),
    // This doesn't belong to the form, refactor
    // answerOptions: (question.answer?.options || []).map((opt) => ({
    //   label: getTranslation(opt.label).display,
    //   value: opt.order,
    // })),
    answerType: valWithErrs(optionByValue('answerType')),

    aqlFunctional: valWithErrs(optionByValue('functionalDefect')),
    aqlLevel: valWithErrs(optionByValue('aqlLevel')),
    aqlMajor: valWithErrs(optionByValue('majorDefect')),
    aqlMinor: valWithErrs(optionByValue('minorDefect')),

    assetReferenceDocNames: (qt.assetReferenceDocNames || []).map((doc) =>
      valWithErrs(doc.name),
    ),

    criticalDefect: valWithErrs(qt.criticalDefectValue ?? ''),
    criticalDefectRule: valWithErrs(qt.criticalDefectRule || 'Exact'),
    customExpectedMeasureValue: valWithErrs(
      qt.customExpectedMeasureValue ?? '',
    ),
    customExpectedUom: valWithErrs(
      qt.customExpectedUom
        ? {
            label: qt.customExpectedUom.label,
            value: qt.customExpectedUom.id,
          }
        : null,
    ),
    defects:
      (qt.defects || []).map(
        (d) => defectToFormState(d, questionOptions.questionWeight), // FIXME: This seems like a workaround
      ) || [],
    documents: isDetailView
      ? qt?.documents
      : (qt.documents || []).map((doc) => ({
          file: doc,
          ...valWithErrs(doc.documentName || doc.name || ''),
        })),
    dynamicAction: valWithErrs(
      qt.useDynamic
        ? optionByValue('dynamicAction')
        : {
            value: false,
            label: 'None',
          },
    ),
    dynamicCriteria: valWithErrs(qt.dynamicCriteria || ''),
    dynamicRule: valWithErrs(optionByValue('dynamicRule')),

    expectedBarcodeUOM: valWithErrs(optionByValue('expectedBarcodeUOM')),
    expectedMeasureTableResult: valWithErrs(
      optionByValue('expectedMeasureTableResult'),
    ),
    questionTemplateId: qt.id ?? null, // Q template id, not used for any logic, would be either the original template id in QGT context, or Overriden q template id if overridden q
    individualMeasurementsRequired: valWithErrs(
      qt.individualMeasurementsRequired ?? DEFAULT_RESULT_RECORDING_TYPE,
    ),
    lowerTolerance: valWithErrs(qt.lowerToleranceValue ?? ''),
    lowerToleranceRule: valWithErrs(qt.lowerToleranceRule || 'Exact'),
    lowerToleranceWeight: valWithErrs(
      optionByValue('lowerToleranceWeight', 'questionWeight'),
    ),
    name: valWithErrs(qt.name || []),
    otherMeasure: valWithErrs(
      qt.otherMeasure
        ? {
            label: qt.otherMeasure,
            value: qt.otherMeasure,
          }
        : null,
    ),

    photoRequired: valWithErrs(qt.photoRequired),
    printOnReport: valWithErrs(optionByValue('printOnReport')),
    questionWeight: valWithErrs(optionByValue('questionWeight')),
    sampleQty: valWithErrs(qt.sampleQty ?? ''),
    sampleRule: valWithErrs(qt.sampleRule || 'Exact'),
    tools: (qt.tools || []).map((tool) => valWithErrs(tool.name)),
    type: valWithErrs(optionByValue('type')),
    upperTolerance: valWithErrs(qt.upperToleranceValue ?? ''),
    upperToleranceRule: valWithErrs(qt.upperToleranceRule || 'Exact'),
    upperToleranceWeight: valWithErrs(
      optionByValue('upperToleranceWeight', 'questionWeight'),
    ),
    useAqlLevel: valWithErrs(qt.useAqlLevel ?? true),
  };

  // XXX: There are sporadic cases where resetting to original data is used
  // TBD general direction to plan a solution
  rslt.initial = {
    documents: _cloneDeep(rslt.documents),
  };

  return rslt;
};

// Converts grouped q template to form model - basically merge q body or overridden template to parent.
export const groupedQuestionToFormStateNew = (
  gqt,
  questionOptions,
  overriddenQuestion,
  order,
) => {
  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === gqt[questionKey],
    ) ?? null;

  // -------------
  /**
   * if gqt.dependencyCriteria === 'specific_answer' DEPENDENCY.SPECIFIC_ANSWER
   * try to find the dependency question's answer option from its answer options list
   * where it matches the gqt.dependencyAnswerOptionOrder
   * then assemble selected option {
   *  label: call formatQuestionCriteria('specific_answer', the found answer's label - which contains languages),
   *  value: gqt.dependencyAnswerOptionOrder
   *
   * if dep answer is not found or not specific_answer, we assemble the selected option as
   * formatQuestionCriteria to find the label for gqt.dependencyCriteria:
   * - could be Specific Answer, Has Defects, etc generic criteria
   * value is dependencyCriteria - specific_answer, etc.
   *
   */
  // const isCustomAnswer = gqt.dependencyCriteria === DEPENDENCY.SPECIFIC_ANSWER;
  // const customAnswer = isCustomAnswer
  //   ? 'dependencyQuestion'?.answer?.options?.find(
  //       // XXX With new implementation this is always false,
  //       // we need to find the master question from template order and extract answers from it
  //       (opt) => opt.order === gqt.dependencyAnswerOptionOrder,
  //     )
  //   : null;

  // const dependencyCriteriaValue = isCustomAnswer
  //   ? {
  //       label: formatQuestionCriteria(
  //         gqt.dependencyCriteria,
  //         customAnswer?.label,
  //       ),
  //       value: gqt.dependencyAnswerOptionOrder,
  //     }
  //   : {
  //       label: formatQuestionCriteria(gqt.dependencyCriteria),
  //       value: gqt.dependencyCriteria,
  //     };
  // -------------

  if (Number.isFinite(order) && order !== gqt.order) {
    console.warn("Question order doesn't match expected order!", order, gqt);
  }

  const rslt = {
    groupedQuestionTemplateId: gqt.id ?? null, // GQT wrapper id
    groupedQuestionTemplateBodyId: gqt.body.id ?? null, // GQT original template id
    groupedQuestionTemplateOverriddenId:
      overriddenQuestion?.replacementQuestionTemplateId ?? null, // Overridden QT id
    order: Number.isFinite(order) ? order : gqt.order,
    isCustom: !overriddenQuestion && !!gqt.body.isCustom,
    isOverridden: !!overriddenQuestion,
    originalTemplateData: _cloneDeep(gqt.body), // Copy of original template, for use when re-linking an overidden q
    dependencyAction: {
      value: optionByValue('dependencyAction') ?? {
        value: false,
        label: 'None',
      },
      errors: [],
    },
    dependencyCriteria: gqt.dependencyCriteria,
    dependencyAnswerOptionOrder: gqt.dependencyAnswerOptionOrder,
    dependencyQuestion: {
      value: Number.isFinite(gqt.dependencyQuestionTemplateOrder)
        ? {
            label: `#${gqt.dependencyQuestionTemplateOrder + 1}`,
            value: gqt.dependencyQuestionTemplateOrder,
          }
        : null,
      errors: [],
    },
    ...questionTemplateToFormNew(
      overriddenQuestion?.body || gqt.body,
      questionOptions,
    ),
  };

  return rslt;
};

// Same as above, for detail view
// TODO: Data shouldn't be different here.
export const groupedQuestionToDetailStateNew = (
  gqt,
  questionOptions,
  overriddenQuestion,
  order,
) => {
  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === gqt[questionKey],
    ) ?? null;

  // -------------
  // const isCustomAnswer = gqt.dependencyCriteria === DEPENDENCY.SPECIFIC_ANSWER;
  // const customAnswer = isCustomAnswer
  //   ? gqt.dependencyQuestion?.answer?.options?.find(
  //       // XXX With new implementation this is always false,
  //       // we need to find the master question from template order and extract answers from it
  //       (opt) => opt.order === gqt.dependencyAnswerOptionOrder,
  //     )
  //   : null;

  // const dependencyCriteriaValue = isCustomAnswer
  //   ? {
  //       label: formatQuestionCriteria(
  //         gqt.dependencyCriteria,
  //         customAnswer?.label,
  //       ),
  //       value: gqt.dependencyAnswerOptionOrder,
  //     }
  //   : {
  //       label: formatQuestionCriteria(gqt.dependencyCriteria),
  //       value: gqt.dependencyCriteria,
  //     };
  // -------------

  if (Number.isFinite(order) && order !== gqt.order) {
    console.warn("Question order doesn't match expected order!", order, gqt);
  }

  const rslt = {
    groupedQuestionTemplateId: gqt.id,
    groupedQuestionTemplateBodyId: gqt.body.id,
    groupedQuestionTemplateOverriddenId:
      overriddenQuestion?.replacementQuestionTemplateId ?? null,
    order: Number.isFinite(order) ? order : gqt.order,
    isCustom: !overriddenQuestion && !!gqt.body.isCustom,
    isOverridden: !!overriddenQuestion,
    originalTemplateData: _cloneDeep(gqt.body),
    dependencyAction: optionByValue('dependencyAction') ?? {
      value: false,
      label: 'None',
    },
    dependencyCriteria: gqt.dependencyCriteria,
    dependencyAnswerOptionOrder: gqt.dependencyAnswerOptionOrder,
    dependencyQuestion: Number.isFinite(gqt.dependencyQuestionTemplateOrder)
      ? {
          label: `#${gqt.dependencyQuestionTemplateOrder + 1}`,
          value: gqt.dependencyQuestionTemplateOrder,
        }
      : null,
    ...questionTemplateToFormNew(
      overriddenQuestion?.body || gqt.body,
      questionOptions,
      true,
    ),
  };

  return rslt;
};

// Transform group to form model.
// TODO: Sorting of groups should happen on server so we don't care maintaining a separate "order" attribute
export const questionGroupToFormStateNew = (
  qg,
  questionOptions,
  overriddenQuestions,
) => {
  return {
    ...qg,
    isCustom: !!qg.isCustom,
    questions: [...(qg.questions || [])]
      .sort((a, b) => a.order - b.order)
      .map((gqt, qIndex) => {
        const overriddenQuestion = overriddenQuestions?.find(
          (oqt) =>
            Number(oqt.questionTemplateId) === Number(gqt.body.id) &&
            Number(oqt.originalGroupTemplateId) ===
              Number(qg.originalGroupTemplateId),
        );

        const questionFormState =
          gqt.body?.isCustom || overriddenQuestion
            ? groupedQuestionToFormStateNew(
                gqt,
                questionOptions,
                overriddenQuestion,
                qIndex,
              )
            : groupedQuestionToDetailStateNew(
                gqt,
                questionOptions,
                overriddenQuestion,
                qIndex,
              );

        return questionFormState;
      }),
  };
};

// Transforms insp plan to form model.
// TODO: Sorting of groups should happen on server so we don't care maintaining a separate "order" attribute
export const inspectionPlanToFormStateNew = (
  planData,
  planOptions,
  questionOptions,
) => {
  const optionByValue = (planKey, optionsKey = planKey) =>
    (planOptions[optionsKey] || []).find(
      (opt) => opt.value === planData[planKey],
    ) ?? null;

  const valWithErrs = (v) => ({ value: v, errors: [] });

  const mapped = {
    id: planData?.id ?? null,
    name: valWithErrs(planData.name || ''),
    description: valWithErrs(planData.description || ''),
    status: valWithErrs(planData.status || ''),
    validity: valWithErrs(planData.validityValue || ''),
    validityRange: valWithErrs(optionByValue('validityRange')),
    aqlLevel: valWithErrs(optionByValue('aqlLevel')),
    aqlMajor: valWithErrs(optionByValue('majorDefect')),
    aqlMinor: valWithErrs(optionByValue('minorDefect')),
    aqlFunctional: valWithErrs(optionByValue('functionalDefect')),
    criticalDefect: valWithErrs(planData.criticalDefectValue),
    criticalDefectRule: valWithErrs(planData.criticalDefectRule || 'Exact'),
    linkedResource: planData.linkedResource
      ? {
          ...planData.linkedResource,
          resourceType: planData.linkedResource.type,
        }
      : null,
    type: valWithErrs(
      planData.inspectionType
        ? entityToSelectOption('name', 'id')(planData.inspectionType)
        : null,
    ),
    questionGroups: [...(planData.questionGroups || [])]
      .sort((a, b) => a.order - b.order)
      .map((qg) =>
        questionGroupToFormStateNew(
          qg,
          questionOptions,
          planData.overriddenQuestions,
        ),
      ),
  };
  return mapped;
};

const numOrNull = (v) => {
  const _v = ![null, undefined, ''].includes(v) ? Number(v) : null;
  return Number.isFinite(_v) ? _v : null;
};

const strOrNull = (v) => {
  return ![null, undefined, ''].includes(v) ? `${v}` : null;
};

/**
 * Extract plan form base-level fields into data object
 */
export const inspectionPlanBaseFormToData = (planForm) => {
  const ipTypeId = numOrNull(planForm?.type?.value?.value);
  const ipLinkedResourceId = numOrNull(planForm?.linkedResource?.id);

  const rslt = {
    aqlLevel: planForm?.aqlLevel?.value?.value ?? null,
    criticalDefectRule: planForm?.criticalDefectRule?.value ?? null,
    criticalDefectValue: numOrNull(planForm?.criticalDefect?.value) ?? 0,
    description: planForm?.description?.value ?? null,
    functionalDefect: planForm?.aqlFunctional?.value?.value ?? null,
    // XXX: Should IDs be numbers or strings? Which? Send nulls or remove id when none? Which?
    id: numOrNull(planForm?.id) ?? undefined,
    inspectionType: ipTypeId !== null ? { id: ipTypeId } : null,
    linkedResource:
      ipLinkedResourceId !== null
        ? {
            id: `${ipLinkedResourceId}`,
            type: planForm?.linkedResource?.resourceType ?? null,
          }
        : null,
    majorDefect: planForm?.aqlMajor?.value?.value ?? null,
    minorDefect: planForm?.aqlMinor?.value?.value ?? null,
    name: planForm?.name?.value ?? null,
    status: planForm?.status?.value ?? null,
    validityRange: planForm?.validityRange?.value?.value ?? null,
    validityValue: numOrNull(planForm?.validity?.value),
  };

  return rslt;
};

/**
 * Extract question template data from question form
 */
export const inspectionPlanQuestionTemplateFormToData = (q) => {
  const answer = ![null, undefined, ''].includes(q?.answer?.value?.value)
    ? { id: q?.answer?.value?.value }
    : null;

  const customExpectedUom = ![null, undefined, ''].includes(
    q?.customExpectedUom?.value?.value,
  )
    ? { id: q?.customExpectedUom?.value?.value }
    : null;

  const defects = q?.defects?.length
    ? q?.defects?.map((_defect) => ({
        id: _defect.id,
        weight: _defect.weight?.value ?? null,
      }))
    : [];

  const documents = q?.documents?.length
    ? q.documents
        .filter((_d) => {
          return !!_d.file.url;
        })
        .map((_d) => ({
          id: _d.file.id,
          documentName: _d.value,
        }))
    : [];

  const tools = q?.tools?.length
    ? q?.tools?.map((_tool) => ({
        name: _tool.value,
      }))
    : [];

  const assetRefDocs = q?.assetReferenceDocNames?.length
    ? q?.assetReferenceDocNames?.map((_doc) => ({
        name: _doc.value,
      }))
    : [];

  const rslt = {
    answer,
    answerType: q?.answerType?.value?.value ?? null,
    aqlLevel: q?.aqlLevel?.value?.value ?? null,
    assetReferenceDocNames: assetRefDocs,
    criticalDefectValue: numOrNull(q?.criticalDefect?.value) ?? 0,
    criticalDefectRule: q?.criticalDefectRule?.value ?? null,
    customExpectedMeasureValue: numOrNull(q?.customExpectedMeasureValue?.value),
    customExpectedUom,
    defects,
    documents,
    dynamicAction: q?.dynamicAction?.value?.value ?? null,
    dynamicCriteria: numOrNull(q?.dynamicCriteria?.value),
    dynamicRule: q?.dynamicRule?.value?.value ?? null,
    expectedBarcodeUOM: q?.expectedBarcodeUOM?.value?.value ?? null,
    expectedMeasureTableResult:
      q?.expectedMeasureTableResult?.value?.value ?? null,
    functionalDefect: strOrNull(q?.aqlFunctional?.value?.value),
    individualMeasurementsRequired:
      q?.individualMeasurementsRequired?.value ?? null,
    lowerToleranceValue: numOrNull(q?.lowerTolerance?.value),
    lowerToleranceRule: q?.lowerToleranceRule?.value ?? null,
    lowerToleranceWeight: q?.lowerToleranceWeight?.value?.value ?? null,
    majorDefect: strOrNull(q?.aqlMajor?.value?.value),
    minorDefect: strOrNull(q?.aqlMinor?.value?.value),
    name: q?.name?.value ?? null, // TODO: check if should use remapping
    otherMeasure: q?.otherMeasure?.value?.value ?? null,
    photoRequired: q?.photoRequired?.value ?? null,
    printOnReport: q?.printOnReport?.value?.value ?? null,
    questionWeight: q?.questionWeight?.value?.value ?? null,
    sampleQty: numOrNull(q?.sampleQty?.value) ?? 0,
    sampleRule: q?.sampleRule?.value ?? null,
    tools,
    type: q?.type?.value?.value ?? null,
    upperToleranceRule: q?.upperToleranceRule?.value ?? null,
    upperToleranceValue: numOrNull(q?.upperTolerance?.value),
    upperToleranceWeight: q?.upperToleranceWeight?.value?.value ?? null,
    useAqlLevel: !!q?.useAqlLevel?.value,
    useDynamic: !!q?.dynamicAction?.value?.value,
  };

  return rslt;
};

/**
 * Split question form into:
 * - GroupedQuestionTemplate base
 * - template body for isCustom questions
 * - overridden template for isOverridden questions
 */
export const inspectionPlanQuestionFormToData = (q) => {
  const gqtBase = {
    id: q?.groupedQuestionTemplateId ? `${q?.groupedQuestionTemplateId}` : null,
    questionTemplateId: q?.groupedQuestionTemplateBodyId
      ? `${q?.groupedQuestionTemplateBodyId}`
      : undefined,
    order: numOrNull(q.order),
    useDependency: !!(
      q?.dependencyAction?.value?.value ?? q?.dependencyAction?.value
    ),
    dependencyAction:
      q?.dependencyAction?.value?.value ?? q?.dependencyAction?.value ?? false,
    dependencyCriteria: q?.dependencyCriteria ?? null,
    dependencyAnswerOptionOrder: numOrNull(q?.dependencyAnswerOptionOrder),
    dependencyQuestionTemplateOrder: numOrNull(
      q?.dependencyQuestion?.value?.value ?? q?.dependencyQuestion?.value,
    ),
  };

  const gqtBody = q?.isCustom
    ? {
        ...inspectionPlanQuestionTemplateFormToData(q),
        isCustom: true,
        id: gqtBase.questionTemplateId,
        _debug: 'This is a custom q body',
      }
    : null;

  const qtOverridden = q?.isOverridden
    ? {
        _debug: 'This is an overridden q template',
        isOverridden: true,
        replacementQuestionTemplateId: q?.groupedQuestionTemplateOverriddenId
          ? `${q?.groupedQuestionTemplateOverriddenId}`
          : undefined,
        questionTemplateId: `${gqtBase.questionTemplateId}`, // XXX: Should this be in the GroupedQTemplate?
        body: inspectionPlanQuestionTemplateFormToData(q),
      }
    : null;

  return { gqtBase, gqtBody, qtOverridden };
};

/**
 * Converts a form group to sendable data and extracts overridden questions.
 * A group data object can take sevaral forms:
 * - If it's a linked group, we only send the id(if loaded previously) and the group template id.
 * - If it's a custom group, we always send the question list with GroupedQuestionTemplate base,
 *   containing info about question order, dependencies, template ids, etc.
 * - If a question isCustom, we also include the gqt.body - the actual question template
 * - If a question is overridden, we extract the template into the overriden q list
 */
export const inspectionPlanQuestionGroupFormToData = (qg) => {
  const oqList = [];

  const qgNew = {
    id: numOrNull(qg?.id) ?? null,
    originalGroupTemplateId: numOrNull(qg?.originalGroupTemplateId),
    isCustom: !!qg?.isCustom,
  };

  if (qg?.isCustom) {
    qgNew.questions = [];
  }

  qg?.questions?.forEach((_q) => {
    const { gqtBase, gqtBody, qtOverridden } = inspectionPlanQuestionFormToData(
      _q,
    );
    if (qg?.isCustom) {
      const gqtNew = { ...gqtBase };
      if (gqtBody) {
        gqtNew.body = gqtBody;
      }
      qgNew.questions.push(gqtNew);
    }

    if (qtOverridden) {
      oqList.push({
        ...qtOverridden,
        originalGroupTemplateId: `${qg.originalGroupTemplateId}`,
      });
    }
  });

  return {
    questionGroup: qgNew,
    overriddenQuestions: oqList?.length ? oqList : null,
  };
};

/**
 * Iterates through the qg list, converts form objects to data objects
 * and accumulates extracted overridden questions since they're global for the plan
 */
export const inspectionPlanQuestionGroupsFormToData = (qgList) => {
  const oqList = [];
  const qgListNew = qgList?.map((_qg) => {
    const {
      questionGroup: qgNew,
      overriddenQuestions: oqListForGroup,
    } = inspectionPlanQuestionGroupFormToData(_qg);

    if (oqListForGroup?.length) oqList.push(...oqListForGroup);

    return qgNew;
  });

  return {
    questionGroups: qgListNew?.length ? qgListNew : [],
    overriddenQuestions: oqList?.length ? oqList : [],
  };
};

/**
 * Generate API-conforming data structure from the Inspection plan form object
 *
 * In summary, the conversion takes the following steps:
 * - Convert base plan fields(top level)
 * - Remove items and fields we don't need to submit(non-editable items)
 * - Split question form objects into GroupedQuestionTemplates and QuestionTemplates(gqt.body)
 * - Extract custom questions and overridden questions - those are the only actual form objects that need to be sent.
 * - Assemble POST/PUT object
 */
export const inspectionPlanFormToData = (planForm) => {
  const pf = _cloneDeep(planForm);

  const {
    questionGroups,
    overriddenQuestions,
  } = inspectionPlanQuestionGroupsFormToData(pf?.questionGroups);

  const pd = {
    ...inspectionPlanBaseFormToData(pf),
    questionGroups,
    overriddenQuestions,
  };

  return pd;
};
