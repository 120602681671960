import React from 'react';
import classNames from 'classnames';
import ErrorBag from '../error-bag/ErrorBag';
import './Textarea.scss';

const Textarea = React.forwardRef((props, ref) => {
  const {
    errors = [],
    label,
    charsLeft,
    focused,
    className,
    containerClassName,
    ...inputProps
  } = props;

  const classes = classNames('qm-textarea', className, {
    'is-invalid': errors.length,
  });

  const containerClasses = classNames(
    'qm-textarea-container',
    containerClassName,
  );

  return (
    <div className={containerClasses}>
      <div className='textarea-info'>
        {label && (
          <label className='form-label' htmlFor={inputProps.name}>
            {label}
          </label>
        )}
        {charsLeft !== undefined && (
          <span className='chars-left'>{charsLeft} characters left</span>
        )}
      </div>
      <textarea
        ref={ref}
        data-testid='textarea'
        className={classes}
        {...inputProps}
      />

      <ErrorBag errors={errors} />
    </div>
  );
});

export default Textarea;
