import { useEffect, useReducer } from 'react';
import {
  reducer,
  initialState,
  ASSETS_DETAIL_ACTIONS,
} from './assetsDetailReducer';
import assetsService from './assetsService';
import { refactorMeasureTableData } from './dataTransform';
import useRoles from 'lib/useRoles';
import ASSET_STATUS from 'config/assetStatus';
import { VIEW_ASSOCIATED_WORK_OBJECTS } from './assetsPermissions';

function getAssetDetail(id, dispatch) {
  assetsService
    .getAssetDetail(id)
    .then((results) =>
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.APP_LOADS_DATA,
        payload: {
          ...results,
          measureTable: refactorMeasureTableData(results.measureTable),
        },
      }),
    )
    .catch(() => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.RESET_STATE,
        payload: {
          asset: null,
          loading: false,
          errors: ['There was an issue loading the asset'],
        },
      });
    });
}

function getRelatedInspectionPlans(id, dispatch) {
  assetsService
    .getInspectionPlans({ pageSize: 5, filters: { byAssetId: id } })
    .then((inspectionPlans) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTION_PLANS,
        payload: inspectionPlans,
      });
    })
    .catch((e) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
}

function getRelatedInspections(id, dispatch) {
  assetsService
    .getRelatedInspections({ byAssetId: id })
    .then((inspections) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_INSPECTIONS,
        payload: inspections,
      });
    })
    .catch((e) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
}

const getRelatedWorkObjects = (id, dispatch) => {
  assetsService
    .getRelatedWorkObjects({ byAssetId: id })
    .then((workObjects) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS,
        payload: workObjects,
      });
    })
    .catch((e) => {
      dispatch({
        type: ASSETS_DETAIL_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
};

const useAssetsDetails = ({ history, match }) => {
  const { isAllowed } = useRoles();
  const [state, dispatch] = useReducer(reducer, initialState);

  const id = match?.params?.id;

  useEffect(() => {
    if (id) {
      getAssetDetail(id, dispatch);
    }
  }, []);

  useEffect(() => {
    const id = state.asset?.id;
    if (id) {
      if (isAllowed(VIEW_ASSOCIATED_WORK_OBJECTS)) {
        getRelatedWorkObjects(id, dispatch);
      }
      getRelatedInspectionPlans(id, dispatch);
      getRelatedInspections(id, dispatch);
    }
  }, [state.asset?.id]);

  // TODO: Refactor when permissions matrix gets finalized
  // const isOwnerClickable = () => {
  //   return isAllowed([ROLES.QM_ADMIN, ROLES.CLIENT_ADMIN, ROLES.ASSET_MANAGER, ROLES.SOURCE_MANAGER,]);
  // };

  const userClicksOnOwner = (owner) => {
    history.push(`/users/${owner.id}`);
  };

  const userClicksOnInspectionPlanRow = (inspectionPlanId) => {
    history.push(`/inspection-plans/${inspectionPlanId}`);
  };

  const userClicksOnWorkObjectRow = (workObjectId) => {
    history.push(`/work-objects/${workObjectId}`);
  };

  const userClicksOnInspectionRow = (inspection) => {
    history.push(`/inspections/${inspection?.id}`);
  };

  const isAssetActive = state.asset?.status?.meaning === ASSET_STATUS.ACTIVE;

  return {
    state,
    userClicksOnInspectionPlanRow,
    userClicksOnWorkObjectRow,
    userClicksOnInspectionRow,
    userClicksOnOwner,
    isAssetActive,
    // isOwnerClickable,
  };
};

export default useAssetsDetails;
