import UsersForm from '../users-form/UsersForm';
import './UsersAddPage.scss';

const UsersAddPage = (props) => {
  return (
    <div className='users-add-page container-m'>
      <div className='row'>
        <h1>New user</h1>
      </div>
      <div className='row'>
        <UsersForm {...props} />
      </div>
    </div>
  );
};

export default UsersAddPage;
