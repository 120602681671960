import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import InspectionTypesListPage from './components/inspection-types-list-page/InspectionTypesListPage';
import InspectionTypesDetailsPage from './components/inspection-types-details-page/InspectionTypesDetailsPage';
import InspectionTypesAddPage from './components/inspection-types-add-page/InspectionTypesAddPage';
import InspectionTypesEditPage from './components/inspection-types-edit-page/InspectionTypesEditPage';
import {
  VIEW_ALL_INSPECTION_TYPES_ROLES,
  ADD_INSPECTION_TYPES_ROLES,
} from './inspectionTypesPermissions';

export default function SourcesRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_ALL_INSPECTION_TYPES_ROLES}
        path='/inspection-types'
        exact
        render={(props) => <InspectionTypesListPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_INSPECTION_TYPES_ROLES}
        path='/inspection-types/add'
        exact
        render={(props) => <InspectionTypesAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_INSPECTION_TYPES_ROLES}
        path='/inspection-types/:id(\d+)'
        exact
        render={(props) => <InspectionTypesDetailsPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_INSPECTION_TYPES_ROLES}
        path='/inspection-types/:id(\d+)/edit'
        exact
        render={(props) => <InspectionTypesEditPage {...props} />}
      />
    </>
  );
}
