import Button from 'lib/components/button/Button';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Modal from 'lib/components/modal/Modal';
import SearchInput from 'lib/components/search-input/SearchInput';
import useSettingsCompanyAttributesList from 'modules/settings/useSettingsCompanyAttributesList';
import {
  CompanyAttributeNameForm,
  CompanyAttributeOptionForm,
} from '../settings-company-attributes-form/SettingsCompanyAttributesForm';
import SettingsCompanyAttributesTable from '../settings-company-attributes-table/SettingsCompanyAttributesTable';
import './SettingsCompanyAttributesListPage.scss';

export default function SettingsCompanyAttributesListPage(props) {
  const vm = useSettingsCompanyAttributesList(props);

  const nameForm = vm.state.form ? (
    <CompanyAttributeNameForm
      value={vm.state.form.label.value}
      errors={vm.state.form.label.errors}
      onChange={(ev) => vm.userChangesInput('label', ev.target.value)}
    />
  ) : null;

  const optionsForm = vm.state.form ? (
    <CompanyAttributeOptionForm
      onChange={vm.userChangesInput}
      onSave={vm.userClicksSaveForm}
      onCancel={vm.userClicksCancelForm}
      onAddOption={vm.userClicksAddOption}
      onRemoveOption={vm.userClicksRemoveOption}
      options={vm.state.form.options}
      errors={[
        ...vm.state.form.errors,
        ...(vm.state.form?.options.errors || []),
      ]}
    />
  ) : null;

  return (
    <div className='settings-company-attributes-list-page'>
      <div className='settings-header'>
        <h4>Company attributes</h4>
        <Button
          data-testid='SettingsDefectListPage.importDefects'
          className='medium-button'
          onClick={() =>
            props.history.push('/settings/company-attributes/import')
          }
        >
          Import
        </Button>
      </div>
      <ErrorBag errors={vm.state.errors} />
      <p className='form-info'>
        Attributes defined here will be shown across all the assets.
      </p>
      <div className='row actions-container'>
        <SearchInput
          value={vm.state.search}
          onChange={vm.setSearch}
          data-testid='SettingsCompanyAttributesListPage.search'
        />
        <Button
          onClick={vm.userClicksAddAttribute}
          data-testid='SettingsCompanyAttributesListPage.add'
          className='medium-button'
        >
          Add new attribute
        </Button>
      </div>
      <SettingsCompanyAttributesTable
        pageSize={vm.state.pageSize}
        setPageSize={vm.setPageSize}
        sortBy={vm.state.sortBy}
        sortOrder={vm.state.sortOrder}
        setSortBy={vm.sortBy}
        page={vm.state.page}
        setPage={vm.setPage}
        count={vm.state.count}
        data={vm.state.data}
        form={vm.state.form}
        onRowEdit={vm.userClicksEditAttribute}
        rowClassName={(record) =>
          vm.state.form?.id === record.id ? 'editing' : ''
        }
        optionFormComponent={optionsForm}
        nameFormComponent={nameForm}
      />
      <Modal
        title='Add new attribute'
        isOpen={vm.state.isAddModalOpen}
        className='settings-company-attributes-list-page--add-modal'
        onRequestClose={vm.userClicksCancelForm}
      >
        <div className='add-form-container'>
          {nameForm}
          {optionsForm}
        </div>
      </Modal>
    </div>
  );
}
