import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import { getTranslation } from 'lib/dataTransform';

import './SettingsAnswersForm.scss';

export default function SettingsAnswersForm(props) {
  return (
    <form className='settings-answers-form'>
      <Input
        label='Answer group name'
        type='text'
        value={props.answer.name.value}
        errors={props.answer.name.errors}
        autoComplete='off'
        onChange={props.onChange}
        className='answers-group'
        data-testid='AnswerForm.answerValue'
      />

      <div className='column'>
        <div className='row'>
          <div className='column'>
            <div className='label-container'>
              <p>Answers name</p>
              <p>Action</p>
            </div>
            {props.answer.options.map((option, index) => {
              const { translation, fallback } = getTranslation(
                option.label.value,
                props.language,
              );
              return (
                <div className='answer-data' key={`answer-${index}`}>
                  <Input
                    type='text'
                    value={translation ?? ''}
                    placeholder={fallback}
                    errors={option.label.errors}
                    autoComplete='off'
                    onChange={(e) => props.addAnswerName(index, e)}
                    className='answers-name'
                    data-testid='AnswerForm.answerValue'
                  />
                  <div className='select-action'>
                    <Select
                      placeholder='Select...'
                      value={props.getDefaultAction(option.meaning.value)}
                      isDisabled={option.notEditable}
                      errors={option.meaning.errors}
                      isSearchable={false}
                      options={props.actions}
                      onChange={(e) => props.userEditsAction(index, e)}
                      className='actions-name'
                      data-testid='AnswerForm.resourceSelectButton'
                    />
                  </div>
                  {(!option.notEditable ||
                    (!option.notEditable &&
                      props.answer.options.length > 1)) && (
                    <DeleteButton
                      onClick={() => props.clearOptionInEdit(index)}
                      data-testid='AnswerForm.clearOption'
                    />
                  )}
                </div>
              );
            })}
          </div>
          <Button
            onClick={props.onSave}
            data-testid='AnswerForm.save'
            className='medium-button'
          >
            Save
          </Button>
          <p className='link-button' onClick={props.cancelEdit}>
            Cancel
          </p>
        </div>
        <Button
          type='button'
          className='medium-button outline-button'
          onClick={props.addAnswerOption}
        >
          Add new field
        </Button>
        <ErrorBag errors={props.answer.errors} />
      </div>
    </form>
  );
}
