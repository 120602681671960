import { useContext, useEffect, useReducer } from 'react';
import useRoles from 'lib/useRoles';
import {
  DASHBOARD_TABLES,
  METRICS_VIEW_CONFIG,
  TABLES_VIEW_CONFIG,
} from './dashboardConfig';
import dashboardService from './dashboardService';
import { reducer, initialState, DASHBOARD_ACTIONS } from './dashboardReducer';
import INSPECTION_STATUS from 'config/inspectionStatus';
import { AppContext } from 'App';
import ROLES from 'config/roles';

const TABLE_PREVIEW_PAGE_SIZE = 5;

function fetchMetrics(dispatch) {
  dashboardService
    .getMetrics()
    .then((metrics) => {
      dispatch({
        type: DASHBOARD_ACTIONS.APP_LOADS_METRICS,
        payload: {
          data: metrics,
          errors: [],
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DASHBOARD_ACTIONS.APP_LOADS_METRICS,
        payload: {
          data: {},
          errors: ['Could not load dashboard metrics'],
        },
      });
    });
}

function fetchList(func, params, key, dispatch) {
  func(params)
    .then((response) => {
      dispatch({
        type: DASHBOARD_ACTIONS.APP_LOADS_LIST,
        key,
        payload: {
          data: response.data,
          count: response.count,
          errors: [],
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DASHBOARD_ACTIONS.APP_LOADS_LIST,
        key,
        payload: {
          data: [],
          count: false,
          errors: ['Could not load data'],
        },
      });
    });
}

const upcomingInspectionsTableParams = {
  pageSize: TABLE_PREVIEW_PAGE_SIZE,
  page: 1,
  order: {
    inspectionDate: 'ASC',
  },
  filters: {
    status: 'published',
  },
  select: ['lastUsageDecision', 'status', 'isThirdParty'],
  relations: ['asset', 'source', 'locationSource', 'inspectionType'],
};
function fetchUpcomingInspections(dispatch) {
  return fetchList(
    dashboardService.getInspections,
    upcomingInspectionsTableParams,
    'upcomingInspections',
    dispatch,
  );
}

const latestInspectionsTableParams = {
  pageSize: TABLE_PREVIEW_PAGE_SIZE,
  page: 1,
  order: {
    inspectionDate: 'DESC',
  },
  filters: {
    status: 'finished',
  },
  select: ['lastUsageDecision', 'status', 'isThirdParty'],
  relations: ['asset', 'source', 'locationSource', 'inspectionType', 'inspector'],
};
function fetchLatestInspections(dispatch) {
  return fetchList(
    dashboardService.getInspections,
    latestInspectionsTableParams,
    'latestInspections',
    dispatch,
  );
}

const timePerUnitInspectionsTableParams = {
  pageSize: TABLE_PREVIEW_PAGE_SIZE,
  page: 1,
  order: {
    inspectionDate: 'DESC',
  },
  filters: {
    status: 'finished',
  },
  select: [
    'asset.name',
    'source.name',
    'inspectionType.name',
    'inspector.name',
    'statusOrResult',
    'timePerUnit',
    'lastUsageDecision',
    'status',
    'isThirdParty',
  ],
};
function fetchTimePerUnitInspections(dispatch) {
  return fetchList(
    dashboardService.getInspections,
    timePerUnitInspectionsTableParams,
    'timePerUnitInspections',
    dispatch,
  );
}

const pendingUsageDecisionsParams = {
  filters: {
    pendingUD: true,
  },
};
function fetchPendingUsageDecisions(dispatch) {
  return fetchList(
    dashboardService.getWorkObjects,
    pendingUsageDecisionsParams,
    'pendingUsageDecisions',
    dispatch,
  );
}

const assetsMissingPlansParams = {
  filters: {
    missingPlans: true,
  },
};
function fetchAssetsMissingPlans(dispatch) {
  return fetchList(
    dashboardService.getAssets,
    assetsMissingPlansParams,
    'assetsMissingPlans',
    dispatch,
  );
}

export default function useDashboard({ history }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    appState: { company },
  } = useContext(AppContext);
  const { isAllowed, currentUserRoles } = useRoles();

  // QM Admin while logged into a company behaves exactly like client admin
  const modifiedRoles = company?.id
    ? currentUserRoles.map((r) =>
      r === ROLES.QM_ADMIN ? ROLES.CLIENT_ADMIN : r,
    )
    : currentUserRoles;

  const displayMetrics = Object.keys(METRICS_VIEW_CONFIG).filter((key) => {
    return isAllowed(METRICS_VIEW_CONFIG[key], modifiedRoles);
  });
  const displayTables = Object.keys(TABLES_VIEW_CONFIG).filter((key) => {
    return isAllowed(TABLES_VIEW_CONFIG[key], modifiedRoles);
  });
  function isTableVisible(key) {
    return displayTables.includes(key);
  }

  useEffect(() => {
    fetchMetrics(dispatch);
    if (isTableVisible(DASHBOARD_TABLES.UPCOMING_INSPECTIONS)) {
      fetchUpcomingInspections(dispatch);
    }

    if (isTableVisible(DASHBOARD_TABLES.LATEST_INSPECTIONS)) {
      fetchLatestInspections(dispatch);
    }

    if (isTableVisible(DASHBOARD_TABLES.TIME_PER_UNIT_INSPECTIONS)) {
      fetchTimePerUnitInspections(dispatch);
    }

    if (isTableVisible(DASHBOARD_TABLES.PENDING_USAGE_DECISIONS)) {
      fetchPendingUsageDecisions(dispatch);
    }

    if (isTableVisible(DASHBOARD_TABLES.ASSETS_MISSING_PLANS)) {
      fetchAssetsMissingPlans(dispatch);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: DASHBOARD_ACTIONS.UPDATE_CALCULATED_METRICS,
      payload: {
        pending_inspections: state.upcomingInspections.count || 0,
      },
    });
  }, [state.upcomingInspections.count]);

  const userClicksInspectionRow = (record) => {
    history.push(
      `/inspections/${record.id}${record.status === INSPECTION_STATUS.FINISHED && !record.isThirdParty
        ? '/results'
        : ''
      }`,
    );
  };
  const userClicksViewLatestInspections = () => {
    const query = new URLSearchParams();
    query.append('sortOrder', 'DESC');
    query.append('sortBy', 'inspectionDate');
    query.append('status', INSPECTION_STATUS.FINISHED);

    history.push(`/inspections?${query}`);
  };

  // TODO: When customizable table columns UI are implemented,
  // make this function hide results and show timePerUnit in
  // main table.
  const userClicksViewTimePerUnitInspections = userClicksViewLatestInspections;

  const userClicksViewUpcomingInspections = () => {
    const query = new URLSearchParams();
    query.append('sortOrder', 'ASC');
    query.append('sortBy', 'inspectionDate');
    query.append('status', 'published');

    history.push(`/inspections?${query}`);
  };

  const userClicksViewPendingUsageDecisions = () => {
    const query = new URLSearchParams();
    query.append('pendingUD', 'true');

    history.push(`/work-objects?${query}`);
  };
  const userClicksViewAssetsMissingPlans = () => {
    const search = new URLSearchParams();
    search.append('missingPlans', true);

    history.push({ pathname: '/assets', search: search.toString() });
  };
  const userClicksWorkObjectRow = (workObject) => {
    history.push(`/work-objects/${workObject.id}`);
  };
  const userClicksAssetRow = (asset) => {
    history.push(`/assets/${asset.id}`);
  };

  const getMetrics = () => ({
    ...state.metrics.data,
    ...state.calculatedMetrics,
  });

  return {
    state,
    getMetrics,
    displayMetrics,
    displayTables,
    isTableVisible,
    userClicksInspectionRow,
    userClicksViewLatestInspections,
    userClicksViewUpcomingInspections,
    userClicksViewTimePerUnitInspections,
    userClicksViewPendingUsageDecisions,
    userClicksWorkObjectRow,
    userClicksViewAssetsMissingPlans,
    userClicksAssetRow,
  };
}
