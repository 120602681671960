import * as yup from 'yup';
import _set from 'lodash.set';
import validator from 'lib/validator';

export const reInspectValidationSchema = yup.object().shape({
  reInspectAql: yup.object().shape({
    value: yup.object().nullable().required('AQL is a required field'),
  }),
});

export const processInspectionResultError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const path = curr.path.split('.').slice(0, -1).join('.');

    _set(acc, path, {
      value: curr.params.originalValue,
      errors: curr.errors,
    });
    return acc;
  }, input);
};

export const inspectionReInspectValidator = (state) =>
  validator(state, reInspectValidationSchema, processInspectionResultError);
