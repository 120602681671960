import { useEffect } from 'react';
import parseQueryString from './parseQueryString';
import { noop, pipe } from './funcHelpers';

const useQueryParams = (location, history, callback = noop) => {
  useEffect(() => {
    pipe(parseQueryString, callback)(location.search);
  }, [location.search]);

  const setParams = (obj) => {
    const params = new URLSearchParams(location.search);

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (!value) {
        // we can remove params by setting them to a falsy value
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });

    history.push({ search: params.toString() });
  };

  const getParams = () => parseQueryString(location.search);

  return {
    setParams,
    getParams,
  };
};

export default useQueryParams;
