export const id = (val) => val;

export const noop = () => {};

export const tap = (fn) => (val) => {
  try {
    fn(val);
  } finally {
    return val;
  }
};

export const pipe = (...fns) => (val) => fns.reduce((acc, f) => f(acc), val);

export const memoize = (fn, keymaker = JSON.stringify) => {
  const store = {};

  return function (...args) {
    const key = keymaker(args);

    if (!store[key]) {
      store[key] = fn.apply(this, args);
    }
    return store[key];
  };
};
