import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'lib/components/tooltip/Tooltip';
import Pill from '../pill/Pill';

import { ReactComponent as WarningCircleSolid } from 'assets/images/warning-circle-solid.svg';
import './WarningPill.scss';

const WarningPill = ({
  overlay,
  text,
  icon = <WarningCircleSolid />,
  className,
}) => {
  const component = (
    <Pill className={classNames('qm-warning-pill', { 'has-text': !!text })}>
      {icon}
      {!!text && <span className='qm-warning-text'>{text}</span>}
    </Pill>
  );
  return overlay ? (
    <Tooltip
      className={classNames('qm-warning-pill-container', className)}
      placement='top'
      overlay={overlay}
    >
      {component}
    </Tooltip>
  ) : (
    <div className='qm-warning-pill-container'>{component}</div>
  );
};

WarningPill.propTypes = {
  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default WarningPill;
