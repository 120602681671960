import Input from '../input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchInput.scss';

const SearchInput = (props) => {
  return (
    <Input
      type='text'
      placeholder='Search...'
      containerClassName='search-input-container'
      className='search-input'
      renderLeftInsert={() => (
        <FontAwesomeIcon fill='#d7d7d7' icon={faSearch} />
      )}
      {...props}
    />
  );
};

export default SearchInput;
