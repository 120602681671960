/**
 * Download response as a file
 * @param {object} data data to be downloaded
 * @param {string} filename name of file to be downloaded
 */
export function downloadResponse(data, filename = 'download') {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
