import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import SearchInput from 'lib/components/search-input/SearchInput';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';

import './DefectsModal.scss';
import Button from 'lib/components/button/Button';
import ErrorBag from '../error-bag/ErrorBag';
import Pagination from '../pagination/Pagination';
import { getTranslation } from 'lib/dataTransform';

const defectsTableColumns = [
  {
    title: 'Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <>
        {record.selected && <CheckCircleSolid className='check-selected' />}
        {getTranslation(record.name).display}
      </>
    ),
  },
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) =>
      [record.id, record.externalId].filter((id) => id).join(' / '),
  },
];

const DefectsModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  count,
  onRowClick,
  sortOrder,
  setSortBy,
  sortBy,
  setPage,
  pageSize,
  page,
  onSubmit,
  isCreatable,
  onCreateClick,
  onRequestClose,
  errors,
  ...props
}) => {
  const hasPagination = setPage && pageSize && page && count > 0;
  const isSearchExactMatch = data.some(
    (defect) =>
      getTranslation(defect.name).display.toLowerCase() ===
      search.toLowerCase(),
  );
  const createButton =
    isCreatable && !isSearchExactMatch && search !== '' ? (
      <Button
        data-testid='DefectsModal.createButton'
        onClick={onCreateClick}
        className='medium-button outline-button'
      >
        Create "{search}"
      </Button>
    ) : null;
  return (
    <Modal
      title='Edit defects'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name or ID...'
            data-testid='DefectsModal.search'
          />
        </div>
      }
      className='qm-defects-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      data-testid='DefectsModal'
      actions={
        <>
          <div>
            <div className='defects-modal-actions'>
              <Button data-testid='DefectsModal.submit' onClick={onSubmit}>
                Submit
              </Button>
              {createButton}
            </div>
            <ErrorBag data-testid='DefectsModal.errors' errors={errors} />
          </div>
          {hasPagination && (
            <Pagination
              pageSize={pageSize}
              threshold={3}
              page={page}
              count={count}
              setPage={setPage}
            />
          )}
        </>
      }
      {...props}
    >
      <Table
        data={data}
        columns={defectsTableColumns}
        rowClassName={(record) => record.selected && 'row-selected'}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
        data-testid='DefectsModal.table'
      />
    </Modal>
  );
};

export default DefectsModal;
