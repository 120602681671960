import request from 'lib/request';
import makeLogger from 'lib/makeLogger';

const log = makeLogger('unsubscribeService');

const unsubscribe = (token) => {
  log('Attempting to unsubscribe.');

  return request
    .post(
      '/users/unsubscribe',
      {},
      {
        makeUnsigned: true,
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then((response) => {
      log('Unsubscribe successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in unsubscribing', e.response.data.message);
      return Promise.reject({
        payload: {
          message:
            'An error has occured while performing this operation. Please try again later.',
        },
      });
    });
};

const unsubscribeService = {
  unsubscribe,
};

export default unsubscribeService;
