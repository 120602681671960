/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import Map, { Marker } from 'lib/components/map/Map';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import OwnersModal from 'lib/components/owners-modal/OwnersModal';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Select from 'lib/components/select/Select';
import Textarea from 'lib/components/textarea/Textarea';

import useSourcesForm from '../../useSourcesForm';
import testId from './fixtures/testid';

import { ReactComponent as CloseCircleOutline } from 'assets/images/close-circle-outline.svg';
import { ReactComponent as CloseCircleSolid } from 'assets/images/remove.svg';

import './SourcesForm.scss';

function NameInput({
  value = '',
  errors = [],
  charsLeft,
  onChange = () => {},
}) {
  return (
    <div className='form-row name-input-container'>
      <Input
        name='name'
        type='text'
        value={value}
        errors={errors}
        onChange={onChange}
        charsLeft={charsLeft}
        label='Name'
        data-testid={testId.name}
      />
    </div>
  );
}

function ExternalIdInput({
  value = '',
  errors = [],
  charsLeft,
  onChange = () => {},
}) {
  return (
    <div className='form-row'>
      <Input
        name='externalId'
        type='text'
        value={value}
        errors={errors}
        onChange={onChange}
        charsLeft={charsLeft}
        label='External ID'
        data-testid={testId.externalId}
      />
    </div>
  );
}

function TypeInput({
  value = {},
  errors = [],
  options = [],
  onChange = () => {},
}) {
  const isSourceTypeAvailable = !!options.length;
  return (
    <div className='form-row type-input-container'>
      <label
        className={classNames('form-label', {
          'is-invalid': !isSourceTypeAvailable,
        })}
      >
        Type
      </label>
      {isSourceTypeAvailable ? (
        <CollapsibleRadio
          name='type'
          value={value}
          errors={errors}
          options={options}
          threshold={7}
          onChange={onChange}
          data-testid={testId.type}
        />
      ) : (
        <ErrorBag
          errors={[
            <span>
              There are no source types defined. Please add them in{' '}
              <Link to='/settings/source-types'>settings</Link>!
            </span>,
          ]}
        />
      )}
    </div>
  );
}

function StatusInput({
  value = {},
  errors = [],
  options = [],
  onChange = () => {},
}) {
  return (
    <div className='form-row status-input-container'>
      <CollapsibleRadio
        label='Status'
        name='status'
        value={value}
        errors={errors}
        options={options}
        threshold={7}
        onChange={onChange}
        data-testid={testId.status}
      />
    </div>
  );
}

function AddressInput({
  value = '',
  errors = [],
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <div className='form-row address-input-container'>
      <Textarea
        name='address'
        type='text'
        label='Address'
        rows={4}
        value={value}
        errors={errors}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={testId.address}
      />
    </div>
  );
}

function PostalCodeInput({
  value = {},
  errors = [],
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <div className='form-row zip-input-container'>
      <Input
        name='zip'
        type='text'
        label='Postal Code'
        value={value}
        errors={errors}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={testId.postalCode}
      />
    </div>
  );
}

function DistrictInput({
  value = {},
  errors = [],
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <div className='form-row district-input-container'>
      <Input
        name='district'
        type='text'
        label='District'
        value={value}
        errors={errors}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={testId.district}
      />
    </div>
  );
}

function CityInput({
  value = {},
  errors = [],
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <div className='form-row city-input-container'>
      <Input
        name='city'
        type='text'
        label='City'
        value={value}
        errors={errors}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={testId.city}
      />
    </div>
  );
}

function StateInput({
  value = {},
  errors = [],
  onChange = () => {},
  onBlur = () => {},
}) {
  return (
    <div className='form-row state-input-container'>
      <Input
        name='state'
        type='text'
        label='State'
        value={value}
        errors={errors}
        onChange={onChange}
        onBlur={onBlur}
        data-testid={testId.state}
      />
    </div>
  );
}

function CountryInput({
  value = {},
  errors = [],
  options = [],
  onChange = () => {},
}) {
  return (
    <div className='form-row country-input-container'>
      <Select
        name='country'
        label='Country'
        value={value}
        errors={errors}
        options={options}
        onChange={onChange}
        data-testid={testId.country}
      />
    </div>
  );
}

function MapWidget({
  center,
  zoom,
  coordinates,
  errors = [],
  onClick = () => {},
  onIdle = () => {},
}) {
  return (
    <div className='form-row map-container'>
      <div className='form-label'>Coordinates</div>
      <p className='field-description'>
        Click on the map to refine the location.
      </p>
      <Map
        center={center}
        zoom={zoom}
        disableDefaultUI={true}
        mapTypeId='hybrid'
        onClick={onClick}
        onIdle={onIdle}
      >
        {!!coordinates && (
          <Marker
            position={{
              lat: coordinates.x,
              lng: coordinates.y,
            }}
          />
        )}
      </Map>
      <ErrorBag errors={errors} />
    </div>
  );
}

function TagsInput({
  value = {},
  errors = [],
  options = [],
  onChange = () => {},
}) {
  return (
    <div className='form-row tags-select-container'>
      <Select
        isMulti={true}
        isClearable={false}
        isCreatable={true}
        isSearchable={true}
        value={value}
        onChange={onChange}
        placeholder={''}
        onInputChange={(inputValue) =>
          inputValue.length <= 50 ? inputValue : inputValue.substr(0, 50)
        }
        name='tags'
        label='Source tags'
        options={options}
        errors={errors}
        data-testid={testId.tags}
      />
      {!value.length && (
        <p className='field-description'>
          An un-tagged source will be linked with all inspectors, coordinators
          and vendors
        </p>
      )}
    </div>
  );
}

/** TODO: Move to /lib
 * @deprecated */
function ImageUploadWidget({
  values = [],
  inputProps = {},
  isChanged = false,
  onRemove = () => {},
  onUpload = () => {},
  onCancel = () => {},
  errors = [],
  maxCount = 5,
}) {
  const getPreviewURL = (image) => {
    return (image.path && URL.createObjectURL(image)) || image.url;
  };

  return (
    <div className='form-row images-input-container'>
      <p className='form-label'>Source Images</p>
      <p className='field-description'>
        Please make sure that each image size is at least 1000 x 1000 pixels to
        avoid pixelization.
        <br />
        <br />
        Images are cropped and scaled automatically for mobile and tablet view.
      </p>
      <div className='image-preview-container'>
        <input {...inputProps} />
        {values.map((image, index) => (
          <div
            className='source-image'
            key={`source-img-${image.path || image.id}`}
          >
            <img alt={index} src={getPreviewURL(image)} />
            <button
              type='button'
              onClick={() => onRemove(index)}
              className='remove-button'
              data-testid={testId.removeImage}
              data-index={index}
            >
              <CloseCircleSolid />
            </button>
          </div>
        ))}
      </div>
      <div className='list-controls'>
        {values.length < maxCount && (
          <Button
            type='button'
            onClick={onUpload}
            className='medium-button'
            data-testid={testId.uploadImage}
          >
            Upload images
          </Button>
        )}
        {isChanged && (
          <Button
            type='button'
            onClick={onCancel}
            className='medium-button'
            data-testid={testId.cancelImageChanges}
          >
            Cancel changes
          </Button>
        )}
      </div>
      <ErrorBag errors={errors} />
    </div>
  );
}

/** TODO: Move to /lib
 * @deprecated */
function UserListSelectWidget({
  users = [],
  onRemove = () => {},
  isChanged = false,
  onCancel = () => {},
  onAssignOpen = () => {},
}) {
  return (
    <div className='form-row owners-container'>
      <p className='form-label'>Source Owners</p>
      <div className='owners-list'>
        {users.map((user, index) => (
          <div
            className={classNames('owner', {
              'has-errors': user.errors.length,
            })}
            key={`source-owner-${user.value.id}`}
          >
            <div>
              <NamedAvatar user={user.value} />
              <button
                type='button'
                onClick={() => onRemove(user.value)}
                className='remove-button'
                data-testid={testId.removeOwner}
                data-index={index}
              >
                <CloseCircleOutline />
              </button>
            </div>
            <ErrorBag errors={user.errors} />
          </div>
        ))}
      </div>

      <div className='list-controls'>
        <Button
          type='button'
          onClick={onAssignOpen}
          className='medium-button'
          data-testid={testId.assignOwner}
        >
          Assign new owner
        </Button>
        {isChanged && (
          <Button
            type='button'
            onClick={onCancel}
            className='medium-button'
            data-testid={testId.cancelOwnerChanges}
          >
            Cancel changes
          </Button>
        )}
      </div>
    </div>
  );
}

const SourcesForm = (props) => {
  const { state, ...vm } = useSourcesForm(props);

  const SubmitButton = state.id ? EditButton : AddButton;
  const submitText = state.id ? 'Save changes' : 'Create source';

  return (
    <div className='sources-form' data-testid={testId.container}>
      <form noValidate>
        <ProtectedComponent allowed={vm.getInputPermission('name')}>
          <NameInput
            value={state.name.value}
            errors={state.name.errors}
            charsLeft={state.name.charsLeft}
            onChange={vm.userTypesName}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('externalId')}>
          <ExternalIdInput
            value={state.externalId.value}
            errors={state.externalId.errors}
            charsLeft={state.externalId.charsLeft}
            onChange={vm.userTypesExternalId}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('type')}>
          <TypeInput
            value={vm.getTypeValue()}
            errors={state.type.errors}
            options={vm.typeOptions}
            onChange={vm.userSelectsType}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('status')}>
          <StatusInput
            value={state.status.value}
            errors={state.status.errors}
            options={vm.getStatusOptions()}
            onChange={vm.userSelectsStatus}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('address')}>
          <AddressInput
            value={state.address.value}
            errors={state.address.errors}
            onChange={vm.userTypesAddress}
            onBlur={vm.userBlursAddress}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('zipCode')}>
          <PostalCodeInput
            value={state.zipCode.value}
            errors={state.zipCode.errors}
            onChange={vm.userTypesZipCode}
            onBlur={vm.userBlursAddress}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('district')}>
          <DistrictInput
            value={state.district.value}
            errors={state.district.errors}
            onChange={vm.userTypesDistrict}
            onBlur={vm.userBlursAddress}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('city')}>
          <CityInput
            value={state.city.value}
            errors={state.city.errors}
            onChange={vm.userTypesCity}
            onBlur={vm.userBlursAddress}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('state')}>
          <StateInput
            value={state.state.value}
            errors={state.state.errors}
            onChange={vm.userTypesState}
            onBlur={vm.userBlursAddress}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('country')}>
          <CountryInput
            value={state.country.value}
            errors={state.country.errors}
            options={vm.getCountryOptions()}
            onChange={vm.userSelectsCountry}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('map')}>
          <MapWidget
            center={state.map.center}
            zoom={state.map.zoom}
            coordinates={state.map.coordinates}
            errors={state.map.errors}
            onClick={vm.userClicksMap}
            onIdle={vm.handleMapIdle}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('tags')}>
          <TagsInput
            value={state.tags.value}
            errors={state.tags.errors}
            options={vm.tagsOptions}
            onChange={vm.userSetsTags}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('images')}>
          <ImageUploadWidget
            values={state.images.values}
            inputProps={vm.getImageInputProps()}
            isChanged={vm.isImageListChanged()}
            onRemove={vm.userRemovesImage}
            onUpload={vm.userClicksUploadImage}
            onCancel={vm.userCancelsImageChanges}
            errors={state.images.errors}
          />
        </ProtectedComponent>
        <ProtectedComponent allowed={vm.getInputPermission('owners')}>
          <UserListSelectWidget
            users={state.owners}
            onRemove={vm.userRemovesOwner}
            isChanged={vm.isOwnerListChanged()}
            onCancel={vm.userCancelsOwnerChanges}
            onAssignOpen={vm.userOpensOwnersModal}
          />
          <OwnersModal
            isOpen={state.availableOwners.isModalOpen}
            onRequestClose={vm.userCancelsOwnersModal}
            onSubmit={vm.userAssignsOwners}
            data={vm.getOwnersForDisplay()}
            selectedCount={state.availableOwners.selected.length}
            sortBy={state.availableOwners.sortBy}
            sortOrder={state.availableOwners.sortOrder}
            search={state.availableOwners.search}
            page={state.availableOwners.page}
            count={state.availableOwners.count}
            pageSize={state.availableOwners.pageSize}
            setPage={vm.userSetsOwnersPage}
            onSearchChange={vm.userSearchesOwners}
            setSortBy={vm.userSortsOwners}
            onRowClick={vm.userTogglesNewOwner}
          />
        </ProtectedComponent>
        <SubmitButton
          type='submit'
          disabled={state.loading}
          onClick={vm.userSubmitsForm}
          data-testid={testId.submit}
        >
          {submitText}
        </SubmitButton>
      </form>
    </div>
  );
};
export default SourcesForm;
