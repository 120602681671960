import Table from 'lib/components/table/Table';
import AssetsStatusPill from 'lib/components/assets-status-pill/AssetsStatusPill';
import WarningPill from '../warning-pill/WarningPill';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import './AssetsTable.scss';
import ImageFallback from '../image-fallback/ImageFallback';

const assetsListColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    textAlign: 'center',
    render: (record) => (
      <div>
        {!record.inspectionPlans?.length && (
          <WarningPill overlay='Missing inspection plan' />
        )}
        {record.id} {!!record?.externalId && `/ ${record.externalId}`}
      </div>
    ),
  },
  {
    title: 'Image / Name',
    sortId: 'name',
    render: (record) => (
      <div className='asset'>
        {!!record.images.length && (
          <div className='image-container'>
            <ImageFallback
              src={record.images[0].url}
              alt={record.images[0].name}
              fallback=''
            />
          </div>
        )}
        <span>{record.name}</span>
      </div>
    ),
  },
  {
    title: 'Description',
    sortId: 'shortDescription',
    className: 'description-cell',
    render: (record) => record.shortDescription,
  },
  {
    title: 'Type',
    sortId: 'type.name',
    className: 'pill-cell',
    render: (record) => (
      <span className='pill-container'>
        <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
          {record.type.name}
        </StatusPill>
      </span>
    ),
  },
  {
    title: 'Status',
    className: 'pill-cell',
    sortId: 'status.name',
    textAlign: 'right',
    render: (record) => (
      <span className='pill-container'>
        <AssetsStatusPill status={record.status} />
      </span>
    ),
  },
];

const AssetsTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={assetsListColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='assets-table'
    {...props}
  />
);

export default AssetsTable;
