import { useState } from 'react';

import Table from 'lib/components/table/Table.js';
import Tooltip from 'lib/components/tooltip/Tooltip';

import { ReactComponent as PencilIcon } from 'assets/images/pencil-outline.svg';

import './SettingsCompanyAttributesTable.scss';

const OPTIONS_TO_DISPLAY = 7;

function CompanyAttributeOptionList({ options }) {
  const [isOpen, setOpen] = useState(false);

  if (!options) {
    return null;
  }

  const expandButton = !isOpen ? (
    <span className='expand-button' onClick={() => setOpen(true)}>
      +{options.length - OPTIONS_TO_DISPLAY} more
    </span>
  ) : (
    <span className='expand-button' onClick={() => setOpen(false)}>
      show fewer
    </span>
  );

  const displayOptions = isOpen
    ? options
    : options.slice(0, OPTIONS_TO_DISPLAY);

  return (
    <>
      {displayOptions.map((o) => o.name).join(', ')}
      {options.length > OPTIONS_TO_DISPLAY && expandButton}
    </>
  );
}

export default function SettingsCompanyAttributesTable({
  onInputChange,
  form,
  onRowEdit,
  optionFormComponent,
  nameFormComponent,
  ...props
}) {
  const settingsCompanyAttributesTableColumns = [
    {
      title: 'ID',
      sortId: 'id',
      headerClassName: 'id-cell',
      className: 'id-cell',
      render: (record) =>
        form?.id === record.id ? (
          <>
            <div className='form-label'>&nbsp;</div>
            {record.id}
          </>
        ) : (
          record.id
        ),
    },
    {
      title: 'Company attributes',
      sortId: 'name',
      headerClassName: 'name-cell',
      className: 'name-cell',
      render: (record) =>
        form?.id === record.id ? nameFormComponent : record.label.value,
    },
    {
      title: 'Options',
      headerClassName: 'options-cell',
      className: 'options-cell',
      render: (record) =>
        form?.id === record.id ? (
          optionFormComponent
        ) : (
          <>
            <CompanyAttributeOptionList options={record.options} />
            <button className='edit-button' onClick={() => onRowEdit(record)}>
              <Tooltip overlay='Edit company attribute' placement='top'>
                <PencilIcon />
              </Tooltip>
            </button>
          </>
        ),
    },
  ];

  return (
    <Table
      className='settings-company-attributes-table'
      columns={settingsCompanyAttributesTableColumns}
      {...props}
    />
  );
}
