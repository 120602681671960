import { SYSTEM_DEFECTS } from 'config/defects';
import { QUESTION_GROUP_STATUS } from 'config/questionOptions';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { defectToFormState } from './dataTransform';
import inspectionPlansService from './inspectionPlansService';

// TODO: Refactor
// ----- Extract initial data to separate file

const getInitialQuestionGroupModalParams = () => ({
  sortBy: 'name',
  sortOrder: 'ASC',
  search: '',
  page: 1,
  pageSize: 10,
  isModalOpen: false,
  excludeIds: [],
});

const getInitialQuestionGroupModalData = () => ({
  list: [],
  count: 0,
  errors: [],
});

const getInitialResourcesModalParams = () => ({
  sortBy: 'id',
  sortOrder: 'ASC',
  search: '',
  isModalOpen: false,
  page: 1,
  pageSize: 10,
  tab: 'assets',
});

const getInitialResourcesModalData = () => ({
  assets: [],
  sources: [],
  assetsCount: 0,
  sourcesCount: 0,
  errors: [],
});

const getInitialDefectsModalParams = () => ({
  sortBy: 'id',
  sortOrder: 'ASC',
  page: 1,
  pageSize: 10,
  search: '',
  isModalOpen: false,
  groupIndex: undefined,
  questionIndex: undefined,
});

const getInitialDefectsModalData = () => ({
  list: [],
  count: 0,
  errors: [],
});

const getQuestionGroupModalData = debounce(
  (params, setData) => {
    return inspectionPlansService
      .getQuestionGroupTemplates(params)
      .then((res) => {
        if (res?.data) {
          setData({ list: res.data, count: res.count, errors: [] });
        }
      })
      .catch(() => {
        setData({
          ...getInitialQuestionGroupModalData(),
          errors: ['An error occured while fetching groups'],
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getResourcesModalData = debounce(
  (params, type, setData) => {
    const isAssets = type === 'assets';

    const apiCallFunc = isAssets
      ? inspectionPlansService.getAssets
      : inspectionPlansService.getSources;

    return apiCallFunc(params)
      .then((res) => {
        if (res?.data) {
          setData({
            [isAssets ? 'assets' : 'sources']: res.data?.map((_item) => ({
              ..._item,
              resourceType: isAssets ? 'asset' : 'source',
            })),
            [isAssets ? 'assetsCount' : 'sourcesCount']: res.count,
            errors: [],
          });
        }
      })
      .catch(() => {
        setData({
          ...getInitialResourcesModalData(),
          errors: ['An error occured while fetching assets / sources'],
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getDefectsModalData = debounce(
  (params, selectedList, defectOptions, setData) => {
    return inspectionPlansService
      .getDefects(params)
      .then((res) => {
        if (res?.data) {
          const defectsList = res.data
            ?.filter((_d) => !Object.values(SYSTEM_DEFECTS).includes(_d.name))
            ?.map((_d) => ({
              ...defectToFormState(_d, defectOptions),
              selected: !!selectedList?.some((__d) => __d.id === _d.id),
            }));
          setData({
            list: defectsList,
            count: res.count,
            errors: [],
          });
        }
      })
      .catch(() => {
        setData({
          ...getInitialDefectsModalData(),
          errors: ['An error occured while fetching defects'],
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

/* eslint-disable-next-line max-lines-per-function*/
const useInspectionPlansFormModals = ({ planForm, questionOptions }) => {
  const [questionGroupModalParams, setQuestionGroupModalParams] = useState(
    getInitialQuestionGroupModalParams(),
  );
  const [questionGroupModalData, setQuestionGroupModalData] = useState(
    getInitialQuestionGroupModalData(),
  );

  const [resourcesModalParams, setResourcesModalParams] = useState(
    getInitialResourcesModalParams(),
  );
  const [resourcesModalData, setResourcesModalData] = useState(
    getInitialResourcesModalData(),
  );

  const [defectsModalParams, setDefectsModalParams] = useState(
    getInitialDefectsModalParams(),
  );
  const [defectsModalData, setDefectsModalData] = useState(
    getInitialDefectsModalData(),
  );
  const [defectsModalSelected, setDefectsModalSelected] = useState([]);

  const doOpenQuestionGroupModal = () => {
    setQuestionGroupModalParams({
      ...getInitialQuestionGroupModalParams(),
      excludeIds:
        planForm?.questionGroups
          ?.map((_g) => _g.originalGroupTemplateId ?? _g.id ?? null)
          .filter((_gId) => Number.isFinite(_gId)) || [],
      isModalOpen: true,
    });
  };

  const doOpenResourcesModal = () => {
    setResourcesModalParams({
      ...getInitialResourcesModalParams(),
      isModalOpen: true,
    });
  };

  const doOpenDefectsModal = (gIndex, qIndex) => {
    const currentDefects =
      planForm.questionGroups[gIndex].questions[qIndex].defects;

    setDefectsModalParams({
      ...getInitialDefectsModalParams(),
      isModalOpen: true,
      groupIndex: gIndex,
      questionIndex: qIndex,
    });

    setDefectsModalSelected([
      ...(currentDefects?.length ? currentDefects : []),
    ]);
  };

  // Init Add Group modal panel
  useEffect(() => {
    if (questionGroupModalParams.isModalOpen) {
      getQuestionGroupModalData(
        {
          order: {
            [questionGroupModalParams.sortBy]:
              questionGroupModalParams.sortOrder,
          },
          pageSize: questionGroupModalParams.pageSize,
          page: questionGroupModalParams.page,
          filters: {
            exclude: questionGroupModalParams.excludeIds?.length
              ? questionGroupModalParams.excludeIds
              : undefined,
            status: QUESTION_GROUP_STATUS.PUBLISHED,
          },
          search: questionGroupModalParams.search,
        },
        setQuestionGroupModalData,
      );
    }
  }, [JSON.stringify({ questionGroupModalParams })]);

  // Reset Add Group modal panel
  useEffect(() => {
    if (!questionGroupModalParams.isModalOpen) {
      setQuestionGroupModalParams(getInitialQuestionGroupModalParams());
      setQuestionGroupModalData(getInitialQuestionGroupModalData());
    }
  }, [questionGroupModalParams.isModalOpen]);

  // Init Resource Select modal panel
  useEffect(() => {
    if (resourcesModalParams.isModalOpen) {
      getResourcesModalData(
        {
          order: {
            [resourcesModalParams.sortBy]: resourcesModalParams.sortOrder,
          },
          pageSize: resourcesModalParams.pageSize,
          page: resourcesModalParams.page,
          search: resourcesModalParams.search,
        },
        resourcesModalParams.tab,
        setResourcesModalData,
      );
    }
  }, [JSON.stringify(resourcesModalParams)]);

  // Reset Resource Select modal panel
  useEffect(() => {
    if (!resourcesModalParams.isModalOpen) {
      setResourcesModalParams(getInitialResourcesModalParams());
      setResourcesModalData(getInitialResourcesModalParams());
    }
  }, [resourcesModalParams.isModalOpen]);

  const doLoadDefectsModalData = useCallback(() => {
    if (defectsModalParams.isModalOpen) {
      getDefectsModalData(
        {
          order: {
            [defectsModalParams.sortBy]: defectsModalParams.sortOrder,
          },
          pageSize: defectsModalParams.pageSize,
          page: defectsModalParams.page,
          search: defectsModalParams.search,
        },
        defectsModalSelected,
        questionOptions?.questionWeight,
        setDefectsModalData,
      );
    } else {
      setDefectsModalParams(getInitialDefectsModalParams());
      setDefectsModalData(getInitialDefectsModalData());
    }
  }, [JSON.stringify(defectsModalParams), questionOptions?.questionWeight]);

  // Init Defects Select modal panel
  useEffect(() => {
    doLoadDefectsModalData();
  }, [doLoadDefectsModalData]);

  const doToggleDefectSelect = (v) => {
    let isAddDefect = false;

    setDefectsModalData({
      ...defectsModalData,
      list: defectsModalData.list.map((_d) => {
        if (_d.id === v.id) {
          isAddDefect = !_d.selected;
          return { ..._d, selected: !_d.selected };
        } else {
          return _d;
        }
      }),
    });
    if (isAddDefect) {
      setDefectsModalSelected([...defectsModalSelected, v]);
    } else {
      setDefectsModalSelected(
        defectsModalSelected.filter((_d) => _d.id !== v?.id),
      );
    }
  };

  const doCreateDefect = () => {
    if (defectsModalParams?.search?.length) {
      inspectionPlansService
        .saveDefect(defectsModalParams.search)
        .then(() => doLoadDefectsModalData())
        .catch(() =>
          setDefectsModalData({
            ...defectsModalData,
            errors: ['An error has occured while saving new defect'],
          }),
        );
    }
  };

  return {
    doOpenQuestionGroupModal,
    questionGroupModalParams,
    setQuestionGroupModalParams,
    questionGroupModalData,
    doOpenResourcesModal,
    resourcesModalParams,
    getInitialResourcesModalParams,
    setResourcesModalParams,
    resourcesModalData,
    doOpenDefectsModal,
    defectsModalParams,
    setDefectsModalParams,
    defectsModalData,
    defectsModalSelected,
    doToggleDefectSelect,
    doCreateDefect,
  };
};

export default useInspectionPlansFormModals;
