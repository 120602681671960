import { useEffect, useState } from 'react';

// Available debug views that can be enabled
// by including them in qm_debug_views record in local storage.
const VIEWS = {
  INSP_RSLT_Q_QUANT_ANSWER: 'INSP_RSLT_Q_QUANT_ANSWER',
};

/**
 * Hook that returns state related to enabled debug views. This hook can be used to
 * enable and render specific debug logic or components when the keys are included
 * manually as CSV in the local storage qm_debug_views record.
 *
 * By checking the isDebugView attr you can probe whether a specific view is enabled
 * by passing the view prop to the hook. If no view prop is provided, the isDebugView
 * attr tells that there is at least one debug view enabled in local storage.
 *
 * @param {String} view Specific view to check, or empty/null to check whether any debug view is enabled.
 * @returns
 */
const useDebugViews = (view) => {
  const [debugViewsList, setDebugViewsList] = useState([]);
  const [isDebugView, setIsDebugView] = useState(false);

  useEffect(() => {
    const csvStr = localStorage.getItem('qm_debug_views');
    let viewsArray = null;

    if (csvStr?.length) {
      viewsArray = csvStr
        .split(',')
        ?.map((v) => v?.trim())
        ?.filter((v) => v in VIEWS);
    }

    setDebugViewsList(viewsArray?.length ? [...new Set(viewsArray)] : []);
    setIsDebugView(
      view?.length ? !!viewsArray?.includes(view) : !!viewsArray?.length,
    );
  }, [view]);

  return { debugViewsList, isDebugView };
};

useDebugViews.VIEWS = VIEWS;

export default useDebugViews;
