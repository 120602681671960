import makeReducer from 'lib/makeReducer';

export const USERS_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  RESET_STATE: 'RESET_STATE',
  USER_CLICKS_RESEND_PASSWORD: 'USER_CLICKS_RESEND_PASSWORD',
  APP_SENT_PASSWORD: 'APP_SENT_PASSWORD',
  APP_FAILED_RESEND_PASSWORD: 'APP_FAILED_RESEND_PASSWORD',
};

const INITIAL_DETAIL_STATE = {
  user: false,
  errors: [],
  loading: true,
  passwordEmailButton: {
    disabled: false,
    message: '',
  },
};

const USERS_DETAIL_REDUCER_CONFIG = {
  [USERS_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      user: action.payload,
      loading: false,
    };
  },
  [USERS_DETAIL_ACTIONS.USER_CLICKS_RESEND_PASSWORD]: (state, action) => {
    return {
      ...state,
      passwordEmailButton: {
        disabled: true,
        message: '',
      },
      ...action.payload,
    };
  },
  [USERS_DETAIL_ACTIONS.APP_SENT_PASSWORD]: (state) => {
    return {
      ...state,
      passwordEmailButton: {
        disabled: true,
        message: 'Email sent',
      },
    };
  },
  [USERS_DETAIL_ACTIONS.APP_FAILED_RESEND_PASSWORD]: (state) => {
    return {
      ...state,
      passwordEmailButton: {
        disabled: false,
        message: 'Please try again in 2 minutes',
      },
    };
  },
  [USERS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  USERS_DETAIL_REDUCER_CONFIG,
  INITIAL_DETAIL_STATE,
);
