import classNames from 'classnames';

import './InfoBox.scss';

export const INFO_BOX_VARIANT = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  NOTIFY: 'notify',
  INFO: 'info',
};

const InfoBox = ({
  variant = INFO_BOX_VARIANT.NOTIFY,
  className,
  children,
  icon = null,
  ...props
}) => {
  const classes = classNames('qm-info-box', className, variant);

  return (
    <div className={classes} {...props}>
      {icon && <div className='icon'>{icon}</div>}
      <div className='body'>{children}</div>
    </div>
  );
};

export default InfoBox;
