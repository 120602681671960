import classNames from 'classnames';
import PropTypes from 'prop-types';

import TimelineItem from './timeline-item/TimelineItem';
import TimelineNewItem from './timeline-new-item/TimelineNewItem';
import TimelineSubitem from './timeline-subitem/TimelineSubitem';
import TimelineNewSubitem from './timeline-new-subitem/TimelineNewSubitem';

import './Timeline.scss';
const Timeline = (props) => {
  return (
    <div className={classNames('timeline', props.className)}>
      {props.title && <h4>{props.title}</h4>}
      <ul>{props.children}</ul>
    </div>
  );
};
Timeline.Item = TimelineItem;
Timeline.Subitem = TimelineSubitem;
Timeline.NewItem = TimelineNewItem;
Timeline.NewSubitem = TimelineNewSubitem;

Timeline.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Timeline;
