import CompaniesForm from '../companies-form/CompaniesForm';
import './CompaniesEditPage.scss';

const CompaniesEditPage = (props) => {
  return (
    <div
      className='companies-edit-page container-m'
      data-testid='CompaniesEditPage.container'
    >
      <div className='row'>
        <h1>Edit company</h1>
      </div>
      <div className='row'>
        <CompaniesForm {...props} />
      </div>
    </div>
  );
};

export default CompaniesEditPage;
