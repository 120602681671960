import { parseISO } from 'date-fns';
import classNames from 'classnames';
import React, { useState } from 'react';

import { ANSWER_TYPE } from 'config/questionOptions';
import { DEFAULT_LANGUAGE } from 'config/languages';

import { formatDate } from 'lib/dateHelpers';
import { getTranslation } from 'lib/dataTransform';

import { formatDetailQuestionSampleSize } from 'lib/components/question-detail/dataTransform';
import File from 'lib/components/file/File';
import Toggle from 'lib/components/toggle/Toggle';

import DefectWidget from './DefectWidget';

import './AnswerDetail.scss';
import AnswerDetailValuesDebug from './AnswerDetailValuesDebug';
import AnswerDetailValues from './AnswerDetailValues';

function getFormattedAnswer(question, language) {
  const rejectionStatus =
    typeof question.isRejected === 'boolean' &&
    (question.isRejected ? 'Reject' : 'Accept');

  switch (question.answerType) {
    case ANSWER_TYPE.DATE_SELECTION:
      return formatDate(parseISO(question.actualAnswer));
    case ANSWER_TYPE.MULTIPLE_CHOICE:
    case ANSWER_TYPE.BARCODE_INPUT:
      const translationArray =
        question.answer.options.find(
          (opt) => opt.order === Number(question.actualAnswer),
        )?.label || [];
      return (
        getTranslation(translationArray, language).display ?? rejectionStatus
      );
    default:
      return question.actualAnswer ?? rejectionStatus;
  }
}

export default function AnswerDetail({
  data,
  inspection,
  initialOpen = false,
  onPhotoClick,
}) {
  const [isOpen, setOpen] = useState(initialOpen);
  const isHidden =
    data.actualAnswer === null &&
    !data.singleMeasurements?.length &&
    data.isRejected === null;

  const formattedAnswer = getFormattedAnswer(data);

  const isAnswerHidden =
    data.answerType === ANSWER_TYPE.DEVICE_IMAGE_CAPTURE ||
    (data.actualAnswer === null && !formattedAnswer);

  const { display: displayName } = getTranslation(data.name, DEFAULT_LANGUAGE);

  const renderAnswerBlock = () => {
    let output = null;

    if (data?.answerType === 'quantitative_input') {
      output = (
        <>
          <div className='answer-value-items'>
            <AnswerDetailValues question={data} inspection={inspection} />
          </div>
          <AnswerDetailValuesDebug question={data} inspection={inspection} />
        </>
      );
    } else if (!isAnswerHidden) {
      output = formattedAnswer;
    }

    return output ? (
      <div className='attribute answer-attribute'>
        <div className='label'>Answer</div>
        <div className='value'>{output}</div>
      </div>
    ) : null;
  };

  return (
    <div className={classNames('qm-answer-detail', { 'is-hidden': isHidden })}>
      <div className='table-row question-summary'>
        <div className='table-cell id-cell'>#{data.order + 1}</div>
        <div className='table-cell question-cell'>{displayName}</div>
        <div className='table-cell question-type-cell'>{data.type}</div>
        <div className='table-cell sample-size-cell'>
          <span className="value">{formatDetailQuestionSampleSize(data)}</span>
        </div>
        <div className='table-cell toggle-cell'>
          {!isHidden && (
            <Toggle
              direction={isOpen ? 'up' : 'down'}
              onClick={() => setOpen(!isOpen)}
            />
          )}
        </div>
      </div>
      {isOpen && !isHidden && (
        <div className='table-row question-body'>
          <div className='table-cell body-cell'>
            <div className='attributes-container'>
              {renderAnswerBlock()}
              {!!data.actualPhotos?.length && (
                <div className='attribute photos-attribute'>
                  <div className='label'>Photos</div>
                  <div className='value-list'>
                    {data.actualPhotos.map((photo, index) => (
                      <img
                        className={classNames({
                          'is-clickable': !!onPhotoClick,
                        })}
                        key={photo.id}
                        src={photo.url}
                        alt='Answer'
                        onClick={() =>
                          onPhotoClick && onPhotoClick(data.actualPhotos, index)
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {!!data.actualDocuments?.length && (
                <div className='attribute documents-attribute'>
                  <div className='label'>Documents</div>
                  <div className='value-list'>
                    {data.actualDocuments.map((doc) => (
                      <a
                        href={doc.url}
                        target='_blank'
                        rel='noreferrer'
                        key={doc.id}
                      >
                        <File data={doc} />
                      </a>
                    ))}
                  </div>
                </div>
              )}
              {!!data.actualDefects.length &&
                data.actualDefects.map((actualDefect) => (
                  <DefectWidget
                    key={actualDefect.id}
                    defect={actualDefect}
                    defectList={data.defects}
                    onPhotoClick={(actualDefectPhotos, actualDefectPhotoIdx) =>
                      onPhotoClick &&
                      onPhotoClick(actualDefectPhotos, actualDefectPhotoIdx)
                    }
                  />
                ))}
              {data.questionNotes && (
                <div className='attribute'>
                  <div className='label'>Question notes</div>
                  <div className='value'>
                    <p>{data.questionNotes}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
