import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import INSPECTION_TYPE_STATUS from 'config/inspectionTypeStatus';
import { ANSWER_TYPE } from 'config/questionOptions';
import {
  autoBuildPlanToPOSTParams,
  formStateToPOSTParams,
} from './dataTransform';
import { processInspectionPlansAPIError } from './inspectionPlansFormValidator';
import { DEFAULT_LANGUAGE } from 'config/languages';

const log = makeLogger('inspectionPlansService');

export const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const defaultInspectionPlansListParams = {
  page: 1,
  pageSize: 10,
  select: [
    'name',
    'description',
    'useAqlLevel',
    'inspectionType.name',
    'publishedAtWithAql',
    'publishedAt',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'aqlLevel',
    'status',
  ],
  relations: ['linkedResource', 'asset', 'source'],
};

const getInspectionPlans = (params) => {
  log('Attempting to fetch inspection plans with params', params);

  return request
    .get('/inspection-plans', {
      params: queryParamsToGETParams({
        ...defaultInspectionPlansListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspection plans successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection plans for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const defaultInspectionPlansDetailParams = {
  select: [
    'name',
    'description',
    'publishedAt',
    'validityValue',
    'validityRange',
    'aqlLevel',
    'majorDefect',
    'minorDefect',
    'functionalDefect',
    'criticalDefectValue',
    'criticalDefectRule',
    'status',
    'notes',
  ],
  relations: [
    'linkedResource',
    'inspectionType',
    'questionGroups',
    'overriddenQuestions',
  ],
};

const getInspectionPlan = (id, params) => {
  return request
    .get(`/inspection-plans/${id}`, {
      params: {
        ...defaultInspectionPlansDetailParams,
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching the inspection plan options', e);
      return Promise.reject(e);
    });
};

const getInspections = (params) => {
  return request
    .get(`/inspections`, {
      params,
    })
    .catch((e) => {
      log('There was an issue in fetching the inspections', e);
      return Promise.reject(e);
    });
};

const defaultInspectionTypesListParams = {
  select: ['name'],
  filters: { status: INSPECTION_TYPE_STATUS.ACTIVE },
};

const getInspectionPlanOptions = () => {
  return request
    .get('/inspection-plan-options')
    .then((response) => {
      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching the inspection plan options', e);
      return Promise.reject(e);
    });
};

const defaultAssetCustomMeasuresParams = {
  select: ['id', 'label'],
};
const getAssetCustomMeasures = (params) => {
  return request
    .get('/settings/custom-measurements', {
      params: {
        ...defaultAssetCustomMeasuresParams,
        ...params,
      },
    })
    .then((response) => {
      log('Loaded asset custom measurements', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in fetching asset custom measurements', e);
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again later',
          ],
        },
      });
    });
};

const defaultSourcesListParams = {
  page: 1,
  pageSize: 50,
  select: ['name', 'status', 'externalId'],
  relations: ['location', 'type'],
  filters: { status: 'active' },
};
const getSources = (params) => {
  return request
    .get('/sources', {
      params: queryParamsToGETParams({
        ...defaultSourcesListParams,
        ...params,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const defaultAssetsListParams = {
  page: 1,
  pageSize: 50,
  select: ['name', 'showId', 'externalId'],
  relations: ['images', 'type', 'status'],
  filters: { status: 'active' },
};
const getAssets = (params) => {
  return request
    .get('/assets', {
      params: queryParamsToGETParams({
        ...defaultAssetsListParams,
        ...params,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const defaultAssetParams = {
  whereField: 'showId',
  select: ['name', 'showId', 'externalId'],
  relations: ['images', 'status', 'type'],
};
const getAsset = (id) => {
  return request
    .get(`/assets/${id}`, {
      params: {
        ...defaultAssetParams,
      },
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const getAnswers = (params) => {
  return request
    .get('/settings/answers', {
      params: {
        select: ['name'],
        relations: ['options'],
        filters: {
          type: ANSWER_TYPE.MULTIPLE_CHOICE,
        },
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
const defaultDefectsParams = {
  select: ['id', 'name', 'externalId'],
};
const getDefects = (params) => {
  return request
    .get('/settings/defects', {
      params: {
        ...defaultDefectsParams,
        ...params,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getQuestionGroupTemplates = (params) => {
  return request
    .get('/question-group-templates', {
      params,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const getInspectionTypes = (params) => {
  return request
    .get('/inspection-types', {
      params: {
        ...defaultInspectionTypesListParams,
        ...params,
        filters: {
          ...defaultInspectionTypesListParams.filters,
          ...params?.filters,
        },
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getQuestionGroups = (id, type, inspectionType) => {
  return request
    .get('/question-groups/load', {
      params: {
        filters: {
          linkedResource: { id, type },
          inspectionType,
        },
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

const saveInspectionPlan = (plan) => {
  const query = plan.id ? `/inspection-plans/${plan.id}` : '/inspection-plans';
  const method = plan.id ? 'put' : 'post';

  return request[method](query, { ...formStateToPOSTParams(plan) })
    .then((response) => {
      log('Inspection plan saved successfully.', response);
      return response;
    })
    .catch((e) => {
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          return Promise.reject({
            payload: processInspectionPlansAPIError(
              e.response.data.details,
              plan,
            ),
          });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};
const saveInspectionPlanNew = (plan) => {
  const query = plan.id ? `/inspection-plans/${plan.id}` : '/inspection-plans';
  const method = plan.id ? 'put' : 'post';

  return request[method](query, { ...plan }).then((response) => {
    log('Inspection plan saved successfully.', response);
    return response;
  });
};

const saveAutoBuildPlan = (plan) => {
  return request
    .post('/inspection-plans/bulk-create', {
      ...autoBuildPlanToPOSTParams(plan),
    })
    .then((response) => {
      log('Auto Build plan saved successfully.', response);
      return response;
    })
    .catch((e) => {
      if (e.response.data.errorCode === 'entity_body_001') {
        return Promise.reject({
          payload: processInspectionPlansAPIError(
            e.response.data.details,
            plan,
          ),
        });
      } else if (e.response.data.errorCode === 'userJob.active') {
        return Promise.reject({
          errorCode: 'e.response.data.errorCode',
          payload: {
            errors: ['Another User is currently using the Inspection Plan Builder. Please try again after it finishes.']
          }
        });
      }
      return Promise.reject({
        payload: {
          errors: [
            'An error has occured while performing this operation. Please try again',
          ],
        },
      });
    });
};

const getAutoBuildProgress = (params) => {
  return request
    .get('/imports/job-status?jobType=inspection-plan', {
      params: {
        ...params,
      },
    })
    .then((res) => {
      // XXX: Workaround to support both object and array response until BE moves everything to array model.

      if (res) {
        const newRes = { ...res };

        if (!params?.isSummary) {
          if (newRes?.response) {
            if (!Array.isArray(newRes.response)) {
              if (typeof newRes.response === 'object') {
                newRes.response = [newRes.response];
              } else {
                console.error('Invalid IPB status response object', newRes.response);
              }
            }
          } else {
            console.error('Empty IPB status response object', newRes);
          }
        }
        return newRes;
      } else {
        return null;
      }
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveDocument = (questionId, name, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', name);
  return request
    .post(`/questions/${questionId}/upload-reference-documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      log('Document uploaded successfully');
      return response;
    })
    .catch((e) => {
      log('There was an error in uploading the document', e);
      return Promise.reject({
        payload: {
          errors: [`An error occurred while uploading document ${name}`],
        },
      });
    });
};

const saveDefect = (name) => {
  return request.post('/settings/defects', {
    name: [
      {
        language: DEFAULT_LANGUAGE,
        text: name,
      },
    ],
  });
};

const defaultCompanyAttributesParams = {
  page: 1,
  pageSize: 10,
};

const getCompanyAttributes = (params) => {
  return request.get('/settings/company-attributes', {
    params: {
      ...defaultCompanyAttributesParams,
      ...params,
    },
  });
};
const defaultCustomAttributesParams = {
  page: 1,
  pageSize: 10,
};

const getCustomAttributes = (params) => {
  return request.get('/settings/custom-attributes', {
    params: {
      ...defaultCustomAttributesParams,
      ...params,
    },
  });
};

const defaultAssetStatusesListParams = {
  select: ['name', 'meaning'],
};
const getAssetStatuses = (params) => {
  log('Attempting to fetch asset statuses with params', params);

  return request
    .get('/asset-statuses', {
      params: {
        ...defaultAssetStatusesListParams,
        ...params,
      },
    })
    .catch((e) => {
      log('There was an issue in fetching asset statuses', params, e);
      return Promise.reject(e);
    });
};

const getAssetTypes = (params) => {
  log('Attempting to fetch asset types with params', params);

  return request
    .get('/asset-types', {
      params: {
        select: ['id', 'name'],
      },
    })
    .catch((e) => {
      log('There was an issue in fetching the asset types', params, e);
      return Promise.reject(e);
    });
};

const getSourceTypes = (params) => {
  log('Attempting to fetch source types with params', params);

  return request
    .get('/source-types', {
      params: {
        select: ['id', 'name'],
      },
    })
    .catch((e) => {
      log('There was an issue in fetching the source types', params, e);
      return Promise.reject(e);
    });
};

const postNeedsReview = (id, notes) => {
  return request
    .post(`/inspection-plans/${id}/needs-review`, {
      notes,
    })
    .catch((e) => {
      log('There was an issue transitioning the plan to needs-review');
      return Promise.reject(e);
    });
};

const inspectionPlansService = {
  getInspectionPlans,
  getInspections,
  getInspectionPlan,
  getInspectionTypes,
  getInspectionPlanOptions,
  getAssetCustomMeasures,
  getAssets,
  getAsset,
  getSources,
  getQuestionGroups,
  getAnswers,
  getDefects,
  getQuestionGroupTemplates,
  getCompanyAttributes,
  getCustomAttributes,
  getAssetStatuses,
  getAssetTypes,
  getSourceTypes,
  saveInspectionPlan,
  saveInspectionPlanNew,
  saveAutoBuildPlan,
  getAutoBuildProgress,
  saveDocument,
  saveDefect,
  postNeedsReview,
};

export default inspectionPlansService;
