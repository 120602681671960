import * as yup from 'yup';
import {
  validator,
  requiredFormString,
  inputShape,
  yupNumber,
  requiredFormObject,
  qmTagsSchema,
} from 'lib/validator';
import { API_ERROR_CODES } from 'config/errorCodes';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';

const settingsQuestionGroupFormQuestionSchema = yup.object().shape({
  dependencyAction: yup.object(),
  dependencyQuestion: yup.object().when(['dependencyAction'], {
    is: (act) => act.value?.value !== false,
    then: requiredFormObject(),
  }),
  dependencyCriteria: yup.object().when(['dependencyAction'], {
    is: (act) => act.value?.value !== false,
    then: requiredFormObject(),
  }),
});

export const settingsQuestionGroupFormValidationSchema = yup.object().shape({
  name: requiredFormString('Question group name is required'),
  order: inputShape(
    yupNumber
      .min(1, 'Question group order must be greater than 0')
      .required('Question group order is required'),
  ),
  tags: inputShape(qmTagsSchema),
  questions: yup.array().of(settingsQuestionGroupFormQuestionSchema),
});

const settingsQuestionGroupsFormValidator = (state) =>
  validator(state, settingsQuestionGroupFormValidationSchema);

const settingsQuestionGroupErrors = {
  ...API_ERROR_CODES,
  unique: 'Question Group names must be unique within the company',
};

export const processSettingsQuestionGroupAPIError = makeAPIBodyErrorProcessor({
  errorCodes: settingsQuestionGroupErrors,
});

export default settingsQuestionGroupsFormValidator;
