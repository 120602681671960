import { MAX_FIELD_CHARS } from './sourcesFormReducer';
import * as _get from 'lodash.get';
import { entityToSelectOption } from 'lib/dataTransform';

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) || '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

export const ownerToFormState = (owner) => ({
  ...owner,
  selected: false,
});

export const sourceToFormState = (response) => ({
  id: response.id,
  name: setStringValue('name', response),
  externalId: setStringValue('externalId', response),
  type: {
    value: entityToSelectOption('name', 'id')(response.type),
    errors: [],
  },
  status: {
    value: response.status,
    label: response.status,
  },
  address: setStringValue('location.address', response, 'address'),
  zipCode: setStringValue('location.zipCode', response, 'zipCode'),
  district: setStringValue('location.district', response, 'district'),
  city: setStringValue('location.city', response, 'city'),
  state: setStringValue('location.state', response, 'state'),
  coordinates: response.location?.coordinates || null,
  country: {
    value: {
      value: response.location?.country,
      label: response.location?.country,
    },
    errors: [],
  },
  images: {
    values: response.images,
    errors: [],
  },
  tags: {
    value:
      response.tags?.map((tag) => ({
        value: tag.id,
        label: tag.label,
      })) || [],
    errors: [],
  },
  map: {
    zoom: response.location?.coordinates ? 13 : 1,
    center: response.location?.coordinates
      ? {
          lat: response.location?.coordinates.x,
          lng: response.location?.coordinates.y,
        }
      : {
          lat: 0,
          lng: 0,
        },
  },
  initialImages: response.images,
  owners: response.owners.map((owner) => ({
    value: owner,
    errors: [],
  })),
  initialOwners: response.owners,
});

export const formStateToPOSTParams = (state) => {
  return {
    name: state.name?.value,
    externalId: state.externalId?.value,
    type: state.type && {
      id: state.type.value.value,
    },
    status: state.status?.value,
    tags: state.tags?.value.map((t) => t.label.trim()),
    owners: state.owners?.map((o) => ({ id: o.value.id })),
    images: state.images?.values.filter((i) => !!i.id),
    location: {
      address: state.address?.value,
      zipCode: state.zipCode?.value,
      district: state.district?.value,
      city: state.city?.value,
      state: state.state?.value,
      country: state.country?.value.value,
      coordinates: state.coordinates,
    },
  };
};
