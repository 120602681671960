import classNames from 'classnames';

import { reduceLocation } from 'lib/dataTransform';

import Button from '../button/Button';
import ImageFallback from 'lib/components/image-fallback/ImageFallback';
import Modal from 'lib/components/modal/Modal';
import Pagination from '../pagination/Pagination';
import SearchInput from 'lib/components/search-input/SearchInput';
import SelectableTable from '../selectable-table/SelectableTable';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import Table from 'lib/components/table/Table';
import Tabs from '../tabs/Tabs';

import { ReactComponent as PackageOutline } from 'assets/images/package-outline.svg';
import { ReactComponent as NavigationOutline } from 'assets/images/navigation-outline.svg';

import './LinkableResourcesModal.scss';

const TABS = {
  ASSETS: 'assets',
  SOURCES: 'sources',
};

const assetsTableColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <span>
        {record.id} {!!record?.externalId && `/ ${record.externalId}`}
      </span>
    ),
  },
  {
    title: 'Image / Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <div className='name'>
        {record.images && (
          <ImageFallback src={record.images[0]?.url} fallback={null} />
        )}
        <span className='title'>{record.name}</span>
      </div>
    ),
  },
  {
    title: 'Type',
    className: 'type-cell',
    render: (record) => (
      <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
        {record.type?.name}
      </StatusPill>
    ),
  },
];

const sourcesTableColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <span>
        {record.id} {!!record?.externalId && `/ ${record.externalId}`}
      </span>
    ),
  },
  {
    title: 'Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Location',
    className: 'location-cell',
    render: (record) =>
      record.location &&
      reduceLocation(record.location, 'address', 'city', 'country'),
  },
  {
    title: 'Type',
    className: 'type-cell',
    render: (record) => (
      <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
        {record.type?.name}
      </StatusPill>
    ),
  },
];

const LinkableResourcesModal = ({
  isOpen,
  search,
  onSearchChange,
  onTabChange = () => { },
  tab = TABS.ASSETS,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  sortBy,
  page,
  setPage,
  pageSize,
  isMultipleSelection = false,
  isAllSelected = false,
  selectedRows = [],
  selectedPages = [],
  onSelectRow,
  onSelectPage,
  onSelectAll,
  onClearSelection,
  filters,
  className,
  ...props
}) => {
  const TableComponent = isMultipleSelection ? SelectableTable : Table;
  const columns =
    tab === TABS.ASSETS ? assetsTableColumns : sourcesTableColumns;
  const count = tab === TABS.ASSETS ? data.assetsCount : data.sourcesCount;

  const hasPagination = pageSize && setPage && page && count > 0;

  return (
    <Modal
      header={
        <>
          <div className='header-container'>
            <Tabs>
              <Tabs.Item
                active={tab === TABS.ASSETS}
                onClick={() => onTabChange(TABS.ASSETS)}
              >
                Select asset
              </Tabs.Item>
              <Tabs.Item
                active={tab === TABS.SOURCES}
                onClick={() => onTabChange(TABS.SOURCES)}
              >
                Select source
              </Tabs.Item>
            </Tabs>
          </div>
          <div className='filters-container'>
            {!(search === undefined && onSearchChange === undefined) && (
              <div className='search-container'>
                <SearchInput
                  value={search}
                  className='search-input'
                  onChange={onSearchChange}
                  placeholder='Search'
                  data-testid='LinkableResourcesModal.search'
                />
              </div>
            )}
            {filters}
          </div>
        </>
      }
      actions={
        <>
          {isMultipleSelection && (
            <>
              <Button
                className='medium-button'
                onClick={onSubmit}
                disabled={
                  !selectedRows.totalAssets && !selectedRows.totalSources
                }
              >
                Add to selection
              </Button>
              <p className='selected-resources-summary'>
                <span className='summary-item'>
                  <PackageOutline />
                  <strong>{selectedRows.totalAssets}</strong> assets selected
                </span>
                <span className='summary-item'>
                  <NavigationOutline />
                  <strong>{selectedRows.totalSources} </strong> sources selected
                </span>
              </p>
            </>
          )}
          {hasPagination && (
            <Pagination
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              count={count}
              threshold={3}
            />
          )}
        </>
      }
      className={classNames(
        'linkable-resources-modal',
        {
          'multiple-selection': isMultipleSelection,
        },
        className,
      )}
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <TableComponent
        data={data[tab]}
        selectedRows={selectedRows[tab]}
        selectedPages={selectedPages[tab]}
        isAllSelected={isAllSelected[tab]}
        onSelectRow={(item) => onSelectRow(item)[tab]()}
        onSelectPage={(items, page) => onSelectPage(items, page)[tab]()}
        onSelectAll={(total) => onSelectAll && onSelectAll()[tab](total)}
        onClearSelection={onClearSelection && onClearSelection()[tab]}
        columns={columns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
        page={page}
        totalEntries={count}
      />
    </Modal>
  );
};

export default LinkableResourcesModal;
