import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as ChevronUp } from 'assets/images/chevron-up.svg';

import './Toggle.scss';

const Toggle = React.forwardRef(
  (
    { direction = 'down', className, onClick, disabled, icon, ...props },
    ref,
  ) => {
    return (
      <button
        type='button'
        ref={ref}
        data-testid='toggle'
        className={classNames('qm-toggle', className, direction, { disabled })}
        onClick={disabled ? undefined : onClick}
        {...props}
      >
        {icon || <ChevronUp />}
      </button>
    );
  },
);

Toggle.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default Toggle;
