import InspectionTypesForm from '../inspection-types-form/InspectionTypesForm';
import './InspectionTypesAddPage.scss';

const InspectionTypesAddPage = (props) => {
  return (
    <div
      className='inspection-types-add-page container-m'
      data-testid='InspectionTypesAddPage.container'
    >
      <div className='row'>
        <h1>New Inspection Type</h1>
      </div>
      <div className='row'>
        <InspectionTypesForm {...props} />
      </div>
    </div>
  );
};

export default InspectionTypesAddPage;
