import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import InspectionPlansListPage from './inspection-plans-list-page/InspectionPlansListPage';
import InspectionPlansAddPage from './components/inspection-plans-add-page/InspectionPlansAddPage';
import InspectionPlansBulkResultsPage from './components/inspection-plans-bulk-results-page/InspectionPlansBulkResultsPage';
import InspectionPlansEditPage from './components/inspection-plans-edit-page/InspectionPlansEditPage';
import InspectionPlansDetailPage from './components/inspection-plans-detail-page/InspectionPlansDetailPage';
import InspectionPlansPrintPage from './components/inspection-plans-print-page/InspectionPlansPrintPage';
import {
  ADD_PLANS_ROLES,
  VIEW_PLANS_ROLES,
} from './inspectionPlansPermissions';

export default function InspectionPlanRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_PLANS_ROLES}
        path='/inspection-plans'
        exact
        render={(props) => <InspectionPlansListPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_PLANS_ROLES}
        path='/inspection-plans/:id(\d+)'
        exact
        render={(props) => <InspectionPlansDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_PLANS_ROLES}
        path='/inspection-plans/:id(\d+)/print'
        exact
        render={(props) => <InspectionPlansPrintPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_PLANS_ROLES}
        path='/inspection-plans/add'
        exact
        render={(props) => <InspectionPlansAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_PLANS_ROLES}
        path='/inspection-plans/add/results'
        exact
        render={(props) => <InspectionPlansBulkResultsPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_PLANS_ROLES}
        path='/inspection-plans/:id(\d+)/edit'
        exact
        render={(props) => <InspectionPlansEditPage {...props} />}
      />
    </>
  );
}
