import { useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';

import { downloadResponse } from 'lib/download';
import useLanguages from 'lib/languageService';
import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryParams from 'lib/useQueryParams';

import { ValidatorError } from 'lib/validator';

import {
  reducer,
  initialState,
  SETTINGS_ANSWERS_ACTIONS,
} from './settingsAnswersListReducer';

import settingsService from './settingsService';
import settingsAnswersFormValidator from './settingsAnswersFormValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const fetchSettingsAnswers = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return settingsService
    .getSettingsAnswers(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useSettingsAnswersList = ({ location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setParams, getParams } = useQueryParams(location, history);

  const { languages } = useLanguages();

  useNavigationPrompt(state.editField?.isDirty || state.addAnswer?.isDirty);

  useEffect(() => {
    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: getParams(),
    });
  }, []);

  const userSetsLanguage = (code) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_SETS_LANGUAGE,
      payload: code,
    });
  };

  useEffect(() => {
    // Pre-select first language on langauges loaded
    if (languages.length && !state.language) {
      dispatch({
        type: SETTINGS_ANSWERS_ACTIONS.USER_SETS_LANGUAGE,
        payload: languages[0].code,
      });
    }
  }, [languages, state.language]);

  const settingsAnswersParams = {
    order: {
      [state.sortBy]: state.sortOrder,
    },
    search: state.search,
    page: state.page,
    pageSize: state.pageSize,
  };
  useEffect(() => {
    fetchSettingsAnswers(settingsAnswersParams, dispatch);
  }, [state.sortOrder, state.sortBy, state.search, state.page, state.pageSize]);

  const addAnswer = (e) => {
    e.preventDefault();
    settingsAnswersFormValidator(state.addAnswer)
      .then((data) =>
        settingsService.saveAnswer(data).then(() => {
          fetchSettingsAnswers(settingsAnswersParams, dispatch);
          dispatch({ type: SETTINGS_ANSWERS_ACTIONS.USER_CANCELS_INPUT_MODAL });
        }),
      )
      .catch((err) => {
        if (err instanceof ValidatorError) {
          dispatch({
            type: SETTINGS_ANSWERS_ACTIONS.RESET_STATE,
            payload: {
              addAnswer: err.payload,
            },
          });
        } else {
          dispatch({
            type: SETTINGS_ANSWERS_ACTIONS.RESET_STATE,
            payload: {
              errors: ['Could not save answer'],
            },
          });
        }
      });
  };

  const userEditsAnswer = (e) => {
    e.preventDefault();
    settingsAnswersFormValidator(state.editField)
      .then((data) => settingsService.saveAnswer(data))
      .then(() => {
        fetchSettingsAnswers(settingsAnswersParams, dispatch);
        dispatch({ type: SETTINGS_ANSWERS_ACTIONS.CLEAR_EDIT_ANSWER });
      })
      .catch((e) => {
        dispatch({
          type: SETTINGS_ANSWERS_ACTIONS.RESET_STATE,
          payload: {
            editField: e.payload,
          },
        });
      });
  };

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  const userSelectsEditAnswer = (name, id) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.SET_EDIT_ANSWER,
      payload: {
        name,
        id,
      },
    });
  };

  const userClearsEditAnswer = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.CLEAR_EDIT_ANSWER,
    });
  };

  const userTypesAnswerName = (e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_CHANGES_INPUT,
      payload: e.target.value,
    });
  };

  const userAddsNewAnswer = (e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_ADDS_NEW_ANSWER,
      payload: e.target.value,
    });
  };

  const userOpensInputModal = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_OPENS_INPUT_MODAL,
    });
  };
  const userCancelsInputModal = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_CANCELS_INPUT_MODAL,
    });
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_CANCELS_INPUT_MODAL,
    });
  };
  const userSubmitsAnswer = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_SUBMITS_ANSWER,
    });
  };

  const userEditsOptionAnswersName = (id, e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_EDITS_OPTIONS_NAME,
      payload: {
        id,
        value: e.target.value,
      },
    });
  };

  const userEditsAction = (id, e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_EDITS_ACTION,
      payload: {
        id,
        value: e.value,
      },
    });
  };

  const addAnswerOption = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.ADD_ANSWER_OPTION,
    });
  };

  const userAddsAnswerOptionName = (id, e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_ADDS_ANSWER_OPTION_NAME,
      payload: {
        id,
        value: e.target.value,
      },
    });
  };

  const userAddsNewAnswerOption = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.ADD_NEW_ANSWER_OPTION,
    });
  };

  const userSelectAction = (id, e) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.USER_SELECTS_ACTION,
      payload: {
        id,
        value: e,
      },
    });
  };

  const getDefaultAction = (meaning) =>
    state.actions.find((action) => action.value === meaning);

  const getDefaultModalAction = (meaning) =>
    state.addAnswer.actions.find((action) => action.value === meaning);

  const clearOptionInModal = (id) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.CLEAR_OPTION_IN_MODAL,
      payload: id,
    });
  };

  const clearOptionInEdit = (id) => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.CLEAR_OPTION_IN_EDIT,
      payload: id,
    });
  };

  const exportAnswers = () => {
    dispatch({
      type: SETTINGS_ANSWERS_ACTIONS.APP_STARTS_EXPORTING_ANSWERS,
    });
    settingsService
      .exportSettingsAnswers()
      .then((data) => {
        downloadResponse(data, 'answers.csv');
      })
      .finally(() =>
        dispatch({
          type: SETTINGS_ANSWERS_ACTIONS.APP_FINISHES_EXPORTING_ANSWERS,
        }),
      );
  };

  return {
    state,
    dispatch,
    sortBy,
    setPage,
    setPageSize,
    setSearch,

    userSelectsEditAnswer,
    userClearsEditAnswer,
    userTypesAnswerName,
    userOpensInputModal,
    userCancelsInputModal,
    userSubmitsAnswer,
    userAddsNewAnswer,
    userEditsOptionAnswersName,
    addAnswerOption,
    getDefaultAction,
    getDefaultModalAction,
    userAddsAnswerOptionName,
    addAnswer,
    userEditsAnswer,
    userEditsAction,
    userAddsNewAnswerOption,
    userSelectAction,
    clearOptionInModal,
    clearOptionInEdit,
    exportAnswers,
    userSetsLanguage,
    languages,
  };
};

export default useSettingsAnswersList;
