const testId = {
  container: 'QuestionForm.container',
  summary: 'QuestionForm.summaryContainer',
  body: 'QuestionForm.bodyContainer',

  toggle: 'toggle',
  actions: 'QuestionActions.container',
  id: 'QuestionForm.id',
  nameInput: 'QuestionForm.nameInput',

  keywordsInput: 'QuestionForm.keywordsInput',
  questionTypeInput: 'QuestionForm.questionTypeInput',
  questionWeight: 'QuestionForm.questionWeight',

  sampleSizeGroup: 'QuestionForm.sampleSizeGroup',
  useAqlLevelSwitch: 'QuestionForm.useAqlLevelSwitch',
  aqlLevelSelect: 'QuestionForm.aqlLevelSelect',
  aqlMajorSelect: 'QuestionForm.aqlMajorSelect',
  aqlMinorSelect: 'QuestionForm.aqlMinorSelect',
  aqlCriticalInput: 'QuestionForm.aqlCriticalInput',
  aqlCriticalRule: 'QuestionForm.aqlCriticalRule',
  customSampleSizeInput: 'QuestionForm.customSampleSizeInput',
  customSampleRuleSwitch: 'QuestionForm.customSampleRuleSwitch',

  answerType: 'QuestionForm.answerType',
  answerChoice: 'QuestionForm.answerChoice',
  answerUOM: 'QuestionForm.answerUOM',

  dynamicActionSelect: 'QuestionForm.dynamicActionSelect',
  dynamicCriteriaInput: 'QuestionForm.dynamicCriteriaInput',
  dynamicRuleSelect: 'QuestionForm.dynamicRuleSelect',

  dependencyActionSelect: 'QuestionForm.dependencyActionSelect',
  dependencyCriteriaSelect: 'QuestionForm.dependencyCriteriaSelect',
  dependencyQuestionSelect: 'QuestionForm.dependencyQuestionSelect',

  expectedMeasureResultSelect: 'QuestionForm.expectedMeasureResultSelect',
  expectedMeasureUOMSelect: 'QuestionForm.expectedMeasureUOMSelect',
  customMeasureInput: 'QuestionForm.customMeasureInput',
  customMeasureUOMSelect: 'QuestionForm.customMeasureUOMSelect',
  upperToleranceInput: 'QuestionForm.upperToleranceInput',
  upperToleranceRuleInput: 'QuestionForm.upperToleranceRuleInput',
  upperToleranceWeightSelect: 'QuestionForm.upperToleranceWeightSelect',
  lowerToleranceInput: 'QuestionForm.lowerToleranceInput',
  lowerToleranceRuleInput: 'QuestionForm.lowerToleranceRuleInput',
  lowerToleranceWeightSelect: 'QuestionForm.lowerToleranceWeightSelect',
};

export default testId;
