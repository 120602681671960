import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'components/SystemComponents/ProgressBar';
import './CsvImport.scss';

const units = ['b', 'Kb', 'Mb', 'Gb'];

function StateUpload({ fileName, uploadedBytes, totalBytes }) {
  // progress text
  const [progressMb, setProgressMb] = useState('0 / 0 Mb');
  const [total, setTotal] = useState(0);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  // based on the unit if we need to divide bytes to get Kb, Mb or Gb
  const [divider, setDivider] = useState(1);

  // calculate total in the best unit (Kb, Mb etc)
  useEffect(() => {
    let total = 0;
    let divider = 0;

    if (totalBytes > 0) {
      total = totalBytes;

      while (total > 1024 && units.length > divider) {
        total = total / 1024;
        ++divider;
      }
    }

    setTotal(total);
    setDivider(divider);
  }, [totalBytes]);

  useEffect(() => {
    const unit = units[divider];
    let finished = 0;
    if (divider > 0) {
      finished = uploadedBytes / Math.pow(1024, divider);
    }
    if (total) {
      setUploadedPercent(Math.round(finished || 0 / total));
    } else {
      setUploadedPercent(0);
    }
    setProgressMb(`${finished.toFixed(1)} / ${total.toFixed(1)} ${unit}`);
  }, [uploadedBytes, divider, total]);

  return (
    <div className='stateUpload'>
      <div className='title'>
        Uploading <span className='fileName'>{fileName}</span>
      </div>
      <div className='mb'>{progressMb}</div>
      <div className='progressContainer'>
        <ProgressBar percent={uploadedPercent} />
      </div>
      <div className='percent'>{uploadedPercent}%</div>
    </div>
  );
}

StateUpload.propTypes = {
  fileName: PropTypes.string.isRequired,
  uploadedBytes: PropTypes.number,
  totalBytes: PropTypes.number,
};

StateUpload.defaultProps = {
  uploadedBytes: 0,
  totalBytes: 0,
};

export default StateUpload;
