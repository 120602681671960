import classNames from 'classnames';
import PropTypes from 'prop-types';
import './TabsItem.scss';

const TabsItem = ({ active, children, disabled, className, ...props }) => {
  return (
    <button
      {...props}
      type='button'
      className={classNames('tabs-item', className, {
        active,
        disabled,
      })}
      disabled={disabled}
      onClick={props.onClick}
    >
      {children}
    </button>
  );
};

TabsItem.defaultProps = {
  disabled: false,
  active: false,
};

TabsItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TabsItem;
