const SOURCE_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

const SOURCE_STATUS_TO_LABEL = {
  [SOURCE_STATUS.DRAFT]: 'Draft',
  [SOURCE_STATUS.ACTIVE]: 'Active',
  [SOURCE_STATUS.DEACTIVATED]: 'Deactivated',
};

export const getSourceStatusLabel = (status) =>
  SOURCE_STATUS_TO_LABEL[status] || '';

export default SOURCE_STATUS;
