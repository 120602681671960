import {
  VIEW_ALL_WORK_OBJECTS_ROLES,
  VIEW_OWN_WORK_OBJECT_ROLES,
  ADD_WORK_OBJECT_ROLES,
  IMPORT_WORK_OBJECT_ROLES,
} from './workObjectsPermissions';
import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import WorkObjectsListPage from './components/work-objects-list-page/WorkObjectsListPage';
import WorkObjectsAddPage from './components/work-objects-add-page/WorkObjectsAddPage';
import WorkObjectsDetailPage from './components/work-objects-detail-page/WorkObjectsDetailPage';
import WorkObjectsEditPage from './components/work-objects-edit-page/WorkObjectsEditPage';
import WorkObjectsImportPage from './components/work-objects-import-page/WorkObjectsImportPage';

export default function WorkObjectRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={[
          ...VIEW_ALL_WORK_OBJECTS_ROLES,
          ...VIEW_OWN_WORK_OBJECT_ROLES,
        ]}
        path='/work-objects'
        exact
        render={(props) => <WorkObjectsListPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_WORK_OBJECT_ROLES}
        path='/work-objects/add'
        exact
        render={(props) => <WorkObjectsAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={IMPORT_WORK_OBJECT_ROLES}
        path='/work-objects/import'
        exact
        render={(props) => <WorkObjectsImportPage {...props} />}
      />
      <ProtectedRoute
        allowed={[
          ...VIEW_ALL_WORK_OBJECTS_ROLES,
          ...VIEW_OWN_WORK_OBJECT_ROLES,
        ]}
        path='/work-objects/:id(\d+)'
        exact
        render={(props) => <WorkObjectsDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_WORK_OBJECT_ROLES}
        path='/work-objects/:id(\d+)/edit'
        exact
        render={(props) => <WorkObjectsEditPage {...props} />}
      />
    </>
  );
}
