import React, { useCallback, useEffect, useRef, useState } from 'react';

import Input from 'lib/components/input/Input';
import _debounce from 'lodash.debounce';
import _isEqual from 'lodash/isEqual';
import ReactSelect from 'react-select';

const optionStyles = (base, state) => {
  const changes = {
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.06)' : '#ffffff',
    color: '#161616',
  };
  return Object.assign(base, changes);
};

const menuStyles = (base) => {
  const changes = {
    border: 'none',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    marginBottom: '14px',
    marginTop: '14px',
  };
  return Object.assign(base, changes);
};

const menuPortalStyles = (base) => {
  const changes = {
    zIndex: 101,
  };
  return Object.assign(base, changes);
};

// React Select Control override component to render only a standard
// text input field and manage RS control events
const ControlComponent = React.memo(
  ({ reactSelectProps }) => {
    // TODO: When more props and flexibility is needed we should extract the input
    //       as a child component with a HOC with these inner props exposed.
    const fwdProps = reactSelectProps.selectProps.innerProps;

    return (
      <Input
        // It's useful to try load data on initial focus to see whether the text
        // already entered is an item in the autocomplete or just free text
        onFocus={() => {
          fwdProps.setIsInputFocused(true);
          fwdProps.doLoadData();
        }}
        // XXX: We want to hide the autocomplete dropdown on input field blur, but
        //      RS doesn't seem to provide a control to exclude the event selecting
        //      an item from the dropdown, so we need a little time for the select
        //      event to execute before hiding.
        onBlur={() => {
          fwdProps.setIsInputFocused(false);
          setTimeout(() => {
            typeof fwdProps.setIsVisible === 'function' &&
              fwdProps.setIsVisible(false);
          }, 100);
        }}
        value={fwdProps.value}
        errors={fwdProps.errors}
        onKeyDown={(e) => {
          // Default RS Escape action is to select the 1st element of the autocomplete
          // and we want to just hide the dropdown instead.
          e.key === 'Escape' && fwdProps.setIsVisible(false);

          // Override RS key behavior
          [' ', 'Home', 'End'].includes(e.key) && e.stopPropagation();
        }}
        onChange={(e) => fwdProps.onChange(e.target.value)}
        autoComplete='off'
        {...fwdProps.inputProps}
      />
    );
  },
  (a, b) => _isEqual(a, b),
);

/**
 * Debounce-wrapped data load function to retrieve autocomplete data
 * using the props-provided data loader function. If the search string
 * is empty, we set the data to an empty array w/o calling the API.
 *
 * @param {String} searchStr Autosuggest search string
 * @param {function} loadDataFunc props-provided data loader function
 * @param {function} setData state update setter
 */
const doLoadData = _debounce(
  (searchStr, loadDataFunc, setDataFunc) => {
    if (loadDataFunc && setDataFunc) {
      if (searchStr?.length) {
        loadDataFunc({
          searchStr,
          setData: setDataFunc,
        });
      } else {
        setDataFunc([]);
      }
    }
  },
  300,
  { trailing: true },
);

/**
 * Data loading function
 *
 * @name LoadDataFunc
 * @function
 * @param {String} searchStr Autosuggest search string
 * @param {function} setData Result data setter function
 */

/**
 * Autosuggest text input component
 *
 * @param {string} value Input value
 * @param {Array} errors Errors list
 * @param {function} onChange Value change handler
 * @param {LoadDataFunc} loadDataFunc Data loading function
 * @param {Array} excludeItems Autocomplete items to skip from showing
 * @param {Object} inputProps Additional props to forward to Input field
 * @returns {React.ReactElement} React Component
 */
const SelectAutosuggest = React.memo(
  ({ value, errors, onChange, loadDataFunc, excludeItems, inputProps }) => {
    const [suggestionsData, setSuggestionsData] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    // Flags whether last selected value was typed or selected from the dropdown
    const isLastValueFromSelect = useRef(false);
    const isInputFocused = useRef(false);

    // Cb when autocomplete data is fetched sets state filtered by excludeItems
    const onLoadedData = useCallback(
      (data) => {
        const filteredData = excludeItems?.length
          ? data.filter((item) => !excludeItems.includes(item))
          : data;
        setIsVisible(!!filteredData?.length);
        setSuggestionsData(filteredData);
      },
      [JSON.stringify(excludeItems)],
    );

    // Creates React Select Control component depending on select props.
    // Needs the useCallback cache for the debounce to work properly
    const getReactSelectControlComponent = useCallback(
      (reactSelectProps) => (
        <ControlComponent reactSelectProps={reactSelectProps} />
      ),
      [],
    );

    // Trigger data loading when input value changes
    useEffect(() => {
      // Ignore event if last value change was triggered by selecting a value dropdown.
      if (!isLastValueFromSelect.current && !!isInputFocused.current) {
        doLoadData(value, loadDataFunc, onLoadedData);
      }
    }, [value, loadDataFunc, setSuggestionsData, onLoadedData]);

    // XXX: We want to completely change behavior of React Select's input component
    //      so it just displays a regular text input field by default.
    //      RS is tightly connected to its input component(the overridden Control component)
    //      and we have to disable all of the functionalities we don't need like
    //      specific key events(esc, space, tab, backspace).
    //      Also we want to make the RS state controlled which RS doesn't like too much,
    //      which actually makes the value option unneeded and redundant, but still required.
    return (
      <ReactSelect
        isClearable={false}
        isMulti={false}
        backspaceRemovesValue={false}
        menuIsOpen={isVisible}
        options={suggestionsData.map((v) => ({
          value: v,
          label: v,
        }))}
        styles={{
          menu: menuStyles,
          option: optionStyles,
          menuPortal: menuPortalStyles,
        }}
        tabSelectsValue={false}
        escapeClearsValue={false}
        autoFocus={false}
        openMenuOnFocus={false}
        onChange={(e) => {
          isLastValueFromSelect.current = true;
          onChange(e.value);
          setIsVisible(false);
        }}
        value={{ value, label: value }}
        innerProps={{
          value,
          errors,
          onChange: (v) => {
            isLastValueFromSelect.current = false;
            onChange(v);
          },
          setIsVisible,
          doLoadData: () => doLoadData(value, loadDataFunc, onLoadedData),
          setIsInputFocused: (isFocused) => {
            isInputFocused.current = isFocused;
          },
          inputProps,
        }}
        components={{
          Control: getReactSelectControlComponent,
        }}
      />
    );
  },
  (a, b) => _isEqual(a, b),
);

export { SelectAutosuggest };
