import Button from 'lib/components/button/Button';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';
import { VIEW_SETTINGS_ROLES } from 'modules/settings/settingsPermissions';
import useSettingsAnswersList from '../../useSettingsAnswersList';
import SettingsAnswersTable from '../settings-answers-table/SettingsAnswersTable';
import SettingsAnswersInputModal from '../settings-answers-input-modal/SettingsAnswersInputModal';

import './SettingsAnswersListPage.scss';

export default function SettingsAnswersListPage(props) {
  const { ...list } = useSettingsAnswersList(props);

  return (
    <div className='settings-answers-list-page'>
      <div className='settings-header'>
        <h4>Answers</h4>
        <Button
          data-testid='SettingsDefectListPage.importAnswers'
          className='medium-button'
          onClick={() => props.history.push('/settings/answers/import')}
        >
          Import
        </Button>
        <Button
          data-testid='SettingsDefectListPage.exportAnswers'
          className='medium-button'
          disabled={list.state.isExportingAnswers}
          isLoading={list.state.isExportingAnswers}
          onClick={list.exportAnswers}
        >
          Export
        </Button>
      </div>
      <p className='form-info'>
        The list of answers that can be selected for each question is defined
        here.
      </p>

      <div className='row answer-filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='SettingsAnswersListPage.search'
          />
        </div>
        <ProtectedComponent allowed={VIEW_SETTINGS_ROLES}>
          <Button
            data-testid='SettingsAnswersListPage.addNewAnswer'
            className='medium-button'
            onClick={list.userOpensInputModal}
          >
            Add new answer
          </Button>
        </ProtectedComponent>
      </div>
      <div className='settings-Answer-table'>
        <SettingsAnswersTable
          editField={list.state.editField}
          data={list.state.data}
          setPageSize={list.setPageSize}
          sortBy={list.state.sortBy}
          sortOrder={list.state.sortOrder}
          setSortBy={list.sortBy}
          page={list.state.page}
          count={list.state.count}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          actions={list.state.actions}
          setEdit={list.userSelectsEditAnswer}
          cancelEdit={list.userClearsEditAnswer}
          onChange={list.userTypesAnswerName}
          addAnswerName={list.userEditsOptionAnswersName}
          addAnswerOption={list.addAnswerOption}
          userEditsAction={list.userEditsAction}
          getDefaultAction={list.getDefaultAction}
          clearOptionInEdit={list.clearOptionInEdit}
          onSave={list.userEditsAnswer}
          languages={list.languages}
          language={list.state.language}
          onLanguageChange={list.userSetsLanguage}
        />
      </div>
      <SettingsAnswersInputModal
        data={list.state.addAnswer}
        isOpen={list.state.addAnswer.isModalOpen}
        userSelectAction={list.userSelectAction}
        userAddsNewAnswer={list.userAddsNewAnswer}
        userWritesOptionName={list.userAddsAnswerOptionName}
        onRequestClose={list.userCancelsInputModal}
        onSubmit={list.userSubmitsAnswer}
        userAddsNewAnswerOption={list.userAddsNewAnswerOption}
        getDefaultModalAction={list.getDefaultModalAction}
        addAnswer={list.addAnswer}
        clearOptionInModal={list.clearOptionInModal}
        languages={list.languages}
        language={list.state.language}
        onLanguageChange={list.userSetsLanguage}
      />
    </div>
  );
}
