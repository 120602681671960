import * as yup from 'yup';

import { ERROR_TEMPLATES, API_ERROR_CODES } from 'config/errorCodes';

import validator, {
  yupNumber,
  inputShape,
  requiredFormObject,
} from 'lib/validator';
import { makeAPIBodyErrorProcessor, parseTemplate } from 'lib/errorHelpers';

const ERRORS = {
  ...ERROR_TEMPLATES,
  nonNegative: 'Value must not be negative',
  integer: 'Value must be an integer',
  maxPercent: parseTemplate(ERROR_TEMPLATES.exceeded, { max: '100%' }),
};

const bulkParamValidationSchema = yup.object().shape({
  type: requiredFormObject(),
  aqlLevel: yup.object(),
  aqlMajor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlMinor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlFunctional: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  criticalDefect: yup
    .object()
    .when('aqlLevel', {
      is: (level) => level.value !== null,
      then: inputShape(
        yupNumber
          .integer(ERRORS.integer)
          .min(0, ERRORS.nonNegative)
          .required(ERRORS.required),
      ),
    })
    .when('criticalDefectRule', {
      is: (rule) => rule.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
    }),
});

export const autoBuildPlanFormValidationSchema = yup.object().shape({
  linkedResources: yup
    .object()
    .shape({
      assets: yup.object().shape({
        totalSelected: yup.number(),
      }),
      sources: yup.object().shape({
        totalSelected: yup.number(),
      }),
    })
    .test('match', `Resource is required `, function (linkedResources) {
      if (
        linkedResources.sources?.totalSelected > 0 ||
        linkedResources.assets?.totalSelected > 0
      ) {
        return true;
      }
      return this.createError({
        path: 'linkedResources.linkedResources',
        message: `Target resource is required`,
      });
    }),
  bulkParams: yup.array().of(bulkParamValidationSchema),
});

const inspectionPlansErrors = {
  ...API_ERROR_CODES,
};

export const processAutoBuildPlanAPIError = makeAPIBodyErrorProcessor({
  errorCodes: inspectionPlansErrors,
});

const autoBuildPlanFormValidator = (state) =>
  validator(state, autoBuildPlanFormValidationSchema);

export default autoBuildPlanFormValidator;
