import { cloneDeep, uniq } from 'lodash';
import { useState } from 'react';

const useSelectableTable = (
  initialItemsSelected = [],
  initialPagesSelected = [],
  initialIsAllSelected = false,
) => {
  const [selectedItems, setSelectedItems] = useState(initialItemsSelected);
  const [selectedPages, setSelectedPages] = useState(initialPagesSelected);
  const [isAllSelected, setIsAllSelected] = useState(initialIsAllSelected);
  const [allSelectedCount, setAllSelectedCount] = useState(0);

  const toggleSelectItem = (itemId) => {
    const itemIndex = selectedItems.findIndex((i) => i === itemId);

    if (itemIndex >= 0) {
      const newValues = cloneDeep(selectedItems);
      newValues.splice(itemIndex, 1);
      setSelectedItems(newValues);
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const toggleSelectCurrentPage = (itemIds, page) => {
    const pageIndex = selectedPages.findIndex((i) => i === page);

    if (pageIndex < 0) {
      setSelectedPages([...selectedPages, page]);
      setSelectedItems(uniq([...selectedItems, ...itemIds]));
    } else {
      const newPageValues = cloneDeep(selectedPages);
      const newSelectedValues = cloneDeep(selectedItems);

      newPageValues.splice(pageIndex, 1);
      setSelectedPages(newPageValues);

      itemIds.forEach((item) => {
        const itemIndex = newSelectedValues.findIndex((i) => i === item);

        if (itemIndex >= 0) {
          newSelectedValues.splice(itemIndex, 1);
        }
      });
      setSelectedItems(newSelectedValues);
    }
  };

  const toggleSelectAll = (totalItems) => {
    setIsAllSelected(!isAllSelected);
    setAllSelectedCount(totalItems);
  };

  const resetChanges = () => {
    setSelectedItems(initialItemsSelected);
    setSelectedPages(initialPagesSelected);
    setIsAllSelected(initialIsAllSelected);
  };

  const totalItemsSelected = isAllSelected
    ? allSelectedCount
    : selectedItems.length;

  const clearSelection = () => {
    setSelectedItems([]);
    setSelectedPages([]);
    setIsAllSelected(false);
  };

  return {
    selectedItems,
    selectedPages,
    isAllSelected,
    totalItemsSelected,
    setSelectedItems,
    setSelectedPages,
    setIsAllSelected,
    setAllSelectedCount,
    toggleSelectItem,
    toggleSelectCurrentPage,
    toggleSelectAll,
    resetChanges,
    clearSelection,
  };
};

export default useSelectableTable;
