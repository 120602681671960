export const NEW_PASSWORD_ACTIONS = {
  USER_TYPES_PASSWORD: 'USER_TYPES_PASSWORD',
  USER_TYPES_CONFIRM_PASSWORD: 'USER_TYPES_CONFIRM_PASSWORD',
  RESET_STATE: 'RESET_STATE',
  FORM_LOADING: 'FORM_LOADING',
};

export const INITIAL_NEW_PASSWORD_STATE = {
  password: {
    value: '',
    errors: [],
  },
  confirmPassword: {
    value: '',
    errors: [],
  },
  loading: false,
  messages: [],
  formErrors: [],
};

export const resetPasswordReducer = (state, action) => {
  switch (action.type) {
    case NEW_PASSWORD_ACTIONS.USER_TYPES_PASSWORD:
      return {
        ...state,
        password: {
          value: action.payload || '',
          errors: [],
        },
      };
    case NEW_PASSWORD_ACTIONS.USER_TYPES_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: {
          value: action.payload || '',
          errors: [],
        },
      };
    case NEW_PASSWORD_ACTIONS.RESET_STATE:
      return { ...state, ...action.payload };
    case NEW_PASSWORD_ACTIONS.FORM_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default resetPasswordReducer;
