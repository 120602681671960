import useInspectionEditPage from 'modules/inspections/useInspectionsEditPage';
import InspectionThirdPartyForm from '../inspection-third-party-form/InspectionThirdPartyForm';
import InspectionsForm from '../inspections-form/InspectionsForm';
import './InspectionsEditPage.scss';

const InspectionsEditPage = (props) => {
  const { inspection, loading } = useInspectionEditPage(props);
  if (!inspection) {
    return null;
  }

  if (loading) {
    return null;
  }

  const { isThirdParty } = inspection;
  return (
    <div className='inspections-edit-page container-m'>
      <div className='row'>
        {isThirdParty ? (
          <h2>Edit third party inspection</h2>
        ) : (
          <h2>Edit inspection</h2>
        )}
      </div>
      <div className='row'>
        {isThirdParty ? (
          <InspectionThirdPartyForm {...props} inspection={inspection} />
        ) : (
          <InspectionsForm {...props} inspection={inspection} />
        )}
      </div>
    </div>
  );
};

export default InspectionsEditPage;
