import request from 'lib/request';

const defaultInspectionsListParams = {
  page: 1,
  pageSize: 5,
  select: [
    'assetId',
    'asset.name',
    'asset.externalId',
    'sourceId',
    'source.name',
    'source.externalId',
    'inspectionType.name',
    'inspector.name',
    'statusOrResult',
    'inspectionDate',
    'finishedAt',
    'isThirdParty',
    'inspectorType',
    'thirdPartyInspectorName',
    'thirdPartyInspectingCompany',
    'workObjectExternalId',
    'workObjectDeadline',
  ],
  relations: ['asset', 'source', 'inspectionType', 'inspector.profilePhoto'],
};

const getInspections = (params) => {
  return request.get('/inspections', {
    params: {
      ...defaultInspectionsListParams,
      ...params,
    },
  });
};

const defaultWorkObjectListParams = {
  page: 1,
  pageSize: 5,
  select: [
    'id',
    'asset',
    'quantity',
    'source',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
    'workObject.externalId',
  ],
  relations: ['assetImg', 'sourceImg'],
};
const getWorkObjects = (params) => {
  return request.get('/work-objects', {
    params: {
      ...defaultWorkObjectListParams,
      ...params,
    },
  });
};

const defaultAssetsListParams = {
  page: 1,
  pageSize: 5,
  select: ['name', 'externalId', 'shortDescription', 'measureTable', 'showId'],
  relations: ['status', 'type', 'images'],
};
const getAssets = (params) => {
  return request.get('/assets', {
    params: {
      ...defaultAssetsListParams,
      ...params,
    },
  });
};

const getMetrics = (params) => {
  return request.get('/dashboard', params);
};

const dashboardService = {
  getInspections,
  getAssets,
  getWorkObjects,
  getMetrics,
};

export default dashboardService;
