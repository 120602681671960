import IconButton from '../icon-button/IconButton';

const AddButton = ({ children, ...props }) => {
  return (
    <IconButton prefix='+' className='add-button' {...props}>
      {children}
    </IconButton>
  );
};

export default AddButton;
