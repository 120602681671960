const testId = {
  container: 'SourcesForm.container',
  name: 'SourcesForm.name',
  externalId: 'SourcesForm.externalId',
  type: 'SourcesForm.type',
  status: 'SourcesForm.status',
  address: 'SourcesForm.address',
  postalCode: 'SourcesForm.postalCode',
  district: 'SourcesForm.district',
  city: 'SourcesForm.city',
  state: 'SourcesForm.state',
  country: 'SourcesForm.country',
  tags: 'SourcesForm.tags',
  removeImage: 'SourcesForm.removeImage',
  uploadImage: 'SourcesForm.uploadImage',
  cancelImageChanges: 'SourcesForm.cancelImageChanges',
  removeOwner: 'SourcesForm.removeOwner',
  assignOwner: 'SourcesForm.assignOwner',
  cancelOwnerChanges: 'SourcesForm.cancelOwnerChanges',
  submit: 'SourcesForm.submit',
};

export default testId;
