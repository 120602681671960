import ProgressBar from 'components/SystemComponents/ProgressBar';
import Table from 'lib/components/table/Table';
import WarningPill from 'lib/components/warning-pill/WarningPill';
import { relativeDate, formatDate } from 'lib/dateHelpers';
import ImageFallback from '../image-fallback/ImageFallback';
import './WorkObjectsTable.scss';

const workObjectListColumns = [
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <span className='warning-id'>
        {record.hasMissingInspectionPlan && (
          <WarningPill overlay='Missing inspection plan' />
        )}
        <span>
          {record.id}
        </span>
      </span>
    ),
  },
  {
    title: 'External ID',
    sortId: 'workObject.externalId',
    className: 'external-id-cell',
    render: (record) => (
      <span>
        {!!record['workObject.externalId'] && record['workObject.externalId']}
      </span>
    ),
  },
  {
    title: 'Target',
    sortId: 'asset',
    className: 'name-cell',
    render: (record) => (
      <div className='image-name'>
        {record.asset ? (
          <>
            {record.assetImg && record.assetImg.url && (
              <div className='image-container'>
                <ImageFallback src={record.assetImg.url} alt='' fallback='' />
              </div>
            )}
            <div>
              <div className='primary-text'>{record.asset.name}</div>
              <div className='secondary-text'>
                {record.asset.externalId ? (
                  <>Ext. ID: {record.asset.externalId}</>
                ) : (
                  <>ID: {record.asset.id}</>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {record.sourceImg && record.sourceImg.url && (
              <div className='image-container'>
                <ImageFallback src={record.sourceImg.url} alt='' fallback='' />
              </div>
            )}
            <div>
              <div className='primary-text'>{record.source.name}</div>
              <div className='secondary-text'>
                {record.source.externalId ? (
                  <>Ext. ID: {record.source.externalId}</>
                ) : (
                  <>ID: {record.source.id}</>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    ),
  },
  {
    title: 'Quantity',
    sortId: 'quantity',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.quantity}</div>
      </div>
    ),
  },
  {
    title: 'Source',
    sortId: 'defaultSource',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.defaultSource}</div>
        {!!record.defaultSourceExternalId && (
          <div className='secondary-text'>
            Ext. ID: {record.defaultSourceExternalId}
          </div>
        )}
        <div className='secondary-text'>{record.sourceLocation}</div>
      </div>
    ),
  },
  {
    title: 'Destination',
    sortId: 'defaultDestination',
    className: 'name-cell',
    render: (record) => (
      <div>
        <div className='primary-text'>{record.defaultDestination}</div>
        {!!record.defaultDestinationExternalId && (
          <div className='secondary-text'>
            Ext. ID: {record.defaultDestinationExternalId}
          </div>
        )}
        <div className='secondary-text'>{record.destinationLocation}</div>
      </div>
    ),
  },
  {
    title: 'Vendor',
    sortId: 'vendor',
    className: 'name-cell',
    render: (record) =>
      record?.vendor?.id ? (
        <div>
          <div className='primary-text'>{record?.vendor?.name}</div>
          <div className='secondary-text'>
            {record?.vendor?.externalId ? (
              <>Ext. ID: {record?.vendor?.externalId}</>
            ) : (
              <>ID: {record?.vendor?.id}</>
            )}
          </div>
        </div>
      ) : null,
  },
  {
    title: 'Workflow',
    sortId: 'workflow',
    headerClassName: 'workflow-cell',
    className: 'workflow-cell',
    render: (record) => record?.workflow,
  },
  {
    title: 'Progress',
    sortId: 'progress',
    className: 'name-cell',
    render: (record) => (
      <div className='progress-row'>
        <ProgressBar percent={+record.progress} />
        <span className='percent'>{parseInt(record.progress)}%</span>
      </div>
    ),
  },
  {
    title: 'Target Date',
    sortId: 'deadline',
    headerClassName: 'target-date-cell',
    className: 'target-date-cell no-wrap',
    textAlign: 'right',
    render: (record) => formatDate(new Date(record?.deadline)),
  },
  {
    title: 'Last Activity',
    sortId: 'lastActivity',
    className: 'name-cell no-wrap',
    textAlign: 'right',
    render: (record) => relativeDate(new Date(record.lastActivity)),
  },
];

const WorkObjectsTable = ({ userClicksOnRow, ...props }) => {
  return (
    <Table
      columns={workObjectListColumns}
      onRowClick={(record) => userClicksOnRow(record.id)}
      className='work-objects-table'
      {...props}
    />
  );
};

export default WorkObjectsTable;
