import {
  tokenStorage,
  userStorage,
  clearAll,
  companyStorage,
} from 'lib/localStorageService';
import USER_EVENTS from 'events/user-events';
import AUTH_EVENTS from 'events/auth-events';
import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import appReducer, { APP_ACTIONS, INITIAL_APP_STATE } from './appReducer';
import appBus from './lib/appBus';
import authService from './lib/authService';

export default function useApp() {
  const [state, dispatch] = useReducer(appReducer, INITIAL_APP_STATE);
  const history = useHistory();

  useEffect(function () {
    function handleUserLoggedIn(authData) {
      tokenStorage.set(authData.token);
      userStorage.set(authData.currentUser);
      companyStorage.set(authData.company);

      dispatch({
        type: APP_ACTIONS.USER_LOGS_IN,
        payload: authData,
      });

      history.push('/');
    }

    function handleUserLoggedOut() {
      clearAll();
      dispatch({
        type: APP_ACTIONS.USER_LOGS_OUT,
      });
    }

    function handleCompanyChanged(company) {
      const currentCompany = companyStorage.get();
      companyStorage.set(company);

      dispatch({
        type: APP_ACTIONS.USER_CHANGES_COMPANY,
        payload: company,
      });

      if (
        (currentCompany === null && company !== null) ||
        (currentCompany !== null && company === null)
      ) {
        // When we log in or out of a company redirect to default page
        history.push('/');
      } else {
        // When we switch companies, keep the current page but
        // force a browser refresh so that the current page is reloaded
        // TODO: investigate ways to unmount/remount current route without
        // loading everything from scratch again.
        window.location.reload();
      }
    }

    function handleProfileChanged(user) {
      userStorage.set({
        ...userStorage.get(),
        ...user,
      });
      dispatch({
        type: APP_ACTIONS.USER_CHANGES_PROFILE,
        payload: user,
      });
    }

    function handleOtherTabStorageChange() {
      dispatch({
        type: APP_ACTIONS.OTHER_TAB_CHANGES_STORAGE,
      });
    }

    appBus.sub(AUTH_EVENTS.USER_LOGGED_IN, handleUserLoggedIn);
    appBus.sub(AUTH_EVENTS.USER_LOGGED_OUT, handleUserLoggedOut);

    appBus.sub(AUTH_EVENTS.COMPANY_CHANGED, handleCompanyChanged);
    appBus.sub(USER_EVENTS.PROFILE_CHANGED, handleProfileChanged);

    window.addEventListener('storage', handleOtherTabStorageChange);

    return function () {
      appBus.unsub(AUTH_EVENTS.USER_LOGGED_IN, handleUserLoggedIn);
      appBus.unsub(AUTH_EVENTS.USER_LOGGED_OUT, handleUserLoggedOut);

      appBus.unsub(USER_EVENTS.PROFILE_CHANGED, handleProfileChanged);
      appBus.unsub(AUTH_EVENTS.COMPANY_CHANGED, handleCompanyChanged);

      window.removeEventListener('storage', handleOtherTabStorageChange);
    };
  });

  const isLoggedIn = () => !!(state.token && state.currentUser);
  const isUnsubscribeLink = useRouteMatch('/unsubscribe');

  const logout = authService.logout;

  return { appState: state, isLoggedIn, isUnsubscribeLink, logout };
}
