import ReactModal from 'react-modal';
import classNames from 'classnames';
import { noop } from 'lib/funcHelpers';

import { ReactComponent as CloseOutline } from 'assets/images/close-outline.svg';

import './Modal.scss';

const CONFIRM_MESSAGE =
  'You have unsaved changes. Are you sure you want to leave?';

const Modal = ({
  actions = null,
  title = '',
  children,
  className,
  header,
  onRequestClose = noop,
  confirmClose = false,
  ...props
}) => {
  const onClose = () =>
    confirmClose
      ? window.confirm(CONFIRM_MESSAGE) && onRequestClose()
      : onRequestClose();
  return (
    <ReactModal
      className={classNames('qm-modal', className)}
      overlayClassName='qm-modal-overlay'
      portalClassName='qm-modal-portal'
      bodyOpenClassName='qm-modal-open'
      onRequestClose={onClose}
      ariaHideApp={false}
      {...props}
    >
      <div className='header'>
        <div className='title'>
          <h2>{title}</h2>
          <button className='close-button' onClick={onClose}>
            <CloseOutline />
          </button>
        </div>
        {header}
      </div>
      <div className='body'>{children}</div>
      <div className='footer'>{actions}</div>
    </ReactModal>
  );
};

export default Modal;
