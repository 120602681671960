import React from 'react';
import classNames from 'classnames';

import './Card.scss';

export default function Card({
  title,
  subtitle,
  description,
  image,
  className,
  ...props
}) {
  return (
    <div className={classNames('qm-card', className)} {...props}>
      {!!image && (
        <div className='image-container'>
          <img src={image} alt='' />
        </div>
      )}
      <div className='body'>
        <div className='body-title'>{title}</div>
        {subtitle && <div className='body-subtitle'>{subtitle}</div>}
        {description && <div className='body-description'>{description}</div>}
      </div>
    </div>
  );
}
