import classNames from 'classnames';
import React, { useState } from 'react';
import { isFragment } from 'react-is';
import Dropdown from '../dropdown/Dropdown';

import './DropdownMenu.scss';

/* Recursively remove fragments from a children array */
const flattenChildren = (children) => {
  const result = [];
  children.forEach((child) => {
    if (isFragment(child)) {
      result.push(...flattenChildren(child.props.children));
    } else {
      result.push(child);
    }
  });
  return result;
};

export const MenuItem = ({
  hide,
  disabled,
  onClick,
  label,
  icon,
  ...props
}) => {
  const wrappedOnClick = (ev) => {
    typeof hide === 'function' && hide();
    typeof onClick === 'function' && onClick(ev);
  };
  return (
    <div
      onClick={disabled ? undefined : wrappedOnClick}
      className={classNames('qm-dropdown-menu-item', { disabled })}
      {...props}
    >
      <div className='menu-item-icon'>{icon}</div>
      <div className='menu-item-label'>{label}</div>
    </div>
  );
};

const DropdownMenu = ({
  children,
  className,
  containerClassName,
  items,
  ...props
}) => {
  const [tippyInstance, setTippyInstance] = useState({});

  const flat = flattenChildren(Array.isArray(items) ? items : [items]);

  // Attach the tippy `hide()` function to auto-hide menu on menuitem click
  const itemsWithHide = flat.map((child, idx) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { hide: tippyInstance.hide, key: idx });
    }
    return child;
  });

  const hasAtLeastOneIcon = flat.some((child) => !!child.props?.icon);

  return (
    <Dropdown
      onCreate={setTippyInstance}
      content={
        <div
          className={classNames('qm-dropdown-menu-items-container', {
            'has-icons': hasAtLeastOneIcon,
          })}
        >
          {itemsWithHide}
        </div>
      }
      className={classNames('qm-dropdown-menu', className)}
      containerClassName={classNames('qm-dropdown-menu', containerClassName)}
      {...props}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownMenu;
