import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderIcon } from '../timelineHelpers';
import './TimelineSubitem.scss';

const TimelineSubitem = ({ className, ...props }) => {
  return (
    <ul className={classNames('timeline-subitem', className)}>
      <li>
        <span
          className={classNames('subitem-icon', {
            completed: props.completed,
            failed: props.failed,
            'custom-icon': !!props?.icon,
          })}
        >
          {renderIcon(props)}
        </span>
        {props.children}
      </li>
    </ul>
  );
};

TimelineSubitem.defaultProps = {
  completed: false,
  failed: false,
};

TimelineSubitem.propTypes = {
  completed: PropTypes.bool,
  failed: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TimelineSubitem;
