import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';

import './ImportQuestionDocumentsPage.scss';

const columnLabels = {
  id: 'Question ID',
  externalId: 'Question External ID',
  name: 'Document name',
  url: 'Document URL',
};

const defectErrorValidations = {
  question_template: {
    'question.notFound': () => 'Could not find question',
  },
  name: {
    'string.alphanum': () =>
      'Document name can only contain alphanumeric and space characters',
    'string.max': () => 'Document name must have at most 40 characters',
  },
  file: {
    'url.unreachable': () => 'URL is unreachable',
    'url.invalid': () => 'URL is invalid',
    'string.uri': () => 'URL is invalid',
    'file.max_length_exceeded': () => 'Maximum file size of 5MB exceeded',
  },
};

export default function ImportQuestionDocumentsPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-question-documents-page'>
      <h4>Import question documents</h4>
      <p className='form-info'>
        Before importing documents, please make sure you structure your data
        like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a
          href='documents/sample-question-documents.csv'
          target='_blank'
          download
        >
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport
        type='question-reference-documents'
        onUploadInfo={list.uploadInfo}
      />
      <ImportResult
        list={list}
        errorValidations={defectErrorValidations}
        columnLabels={columnLabels}
      />
    </div>
  );
}
