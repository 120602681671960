import { useEffect, useState } from 'react';
import request from 'lib/request';

export const UOM_TYPES = {
  LENGTH: 'length',
  WEIGHT: 'weight',
  VOLUME: 'volume',
  ALL: 'all',
};

// retrieved all units from the backend
let allUnits;
// filtered units by type
const unitsByType = {};

// busy if we are waiting for data from the server
let isBusy = false;
// promises called while our loading process is active
const onHoldRequests = [];

function setBusy(value, units) {
  isBusy = value;
  // if we are not busy anymore, invoke all promise functions
  // that are on hold
  if (!value) {
    while (onHoldRequests.length > 0) {
      const promise = onHoldRequests.shift();
      // if we have data call resolve, in other case reject
      if (units) {
        promise.resolve(units);
      } else {
        promise.reject();
      }
    }
  }
}

/**
 * Load measurement data from the backend
 * @returns
 */
function load() {
  return new Promise((resolve, reject) => {
    if (allUnits) {
      // we already have cached data, return it
      resolve(allUnits);
    } else if (isBusy) {
      // we are waiting for server response. Don't make another call
      onHoldRequests.push({
        resolve,
        reject,
      });
    } else {
      // fetch data from the backend
      setBusy(true);
      request
        .get('/measurement-units?select=["label", "type"]')
        .then((response) => {
          if (response?.data) {
            return response.data;
          } else {
            return [];
          }
        })
        .then((units) => {
          allUnits = units;
          if (Array.isArray(allUnits)) {
            allUnits.sort((a, b) => {
              const first = a?.label?.toUpperCase();
              const second = b?.label?.toUpperCase();
              if (first && second) {
                if (first > second) return 1;
                if (first < second) return -1;
              }
              return 0;
            });
          }
          resolve(allUnits);
          setBusy(false, allUnits);
        })
        .catch(() => {
          reject();
          setBusy(false);
        });
    }
  });
}

/**
 * Get units from the server (if we don't have them)
 * filter them, prepare for Select object structure and return it.
 * @param {string} type One of predefined unit types
 * @returns Promise with array prepared for Select component
 */
function getUnits(type = UOM_TYPES.ALL) {
  return new Promise((resolve) => {
    if (unitsByType.hasOwnProperty(type)) {
      resolve(unitsByType[type]);
    } else {
      load()
        .then((units) => {
          let selectedUnits = [];
          if (Array.isArray(units)) {
            // filter if we need it
            selectedUnits = (type === UOM_TYPES.ALL
              ? units
              : units.filter((unit) => unit.type === type)
            ).map((record) => ({
              label: record.label,
              value: record.label,
              id: record.id,
            }));
          }
          unitsByType[type] = selectedUnits;
          resolve(selectedUnits);
        })
        .catch(() => {
          resolve([]);
        });
    }
  });
}

export function clear() {
  allUnits = null;
  Object.keys(unitsByType).forEach((key) => delete unitsByType[key]);
}

export function useUnits(type = UOM_TYPES.ALL) {
  const [unitsList, setUnitsList] = useState([]);

  useEffect(() => {
    getUnits(type).then((data) => {
      setUnitsList(data);
    });
  }, []);

  return unitsList;
}
