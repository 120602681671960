import useUnsubscribe from 'modules/unsubscribe/useUnsubscribe';
import './UnsubscribePage.scss';

const UnsubscribePage = (props) => {
  const { state } = useUnsubscribe(props);

  if (state.loading) {
    return null;
  }

  return (
    <div className='unsubscribe-page'>
      <p className='message'>{state.message}</p>
    </div>
  );
};

export default UnsubscribePage;
