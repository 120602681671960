import SettingsQuestionsForm from '../settings-questions-form/SettingsQuestionsForm';

const SettingsQuestionsEditPage = (props) => {
  return (
    <div className='settings-question-edit-page'>
      <div className='row'>
        <h4>Edit question</h4>
      </div>
      <div className='row'>
        <SettingsQuestionsForm {...props} />
      </div>
    </div>
  );
};

export default SettingsQuestionsEditPage;
