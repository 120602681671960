import React from 'react';
import classNames from 'classnames';
import ErrorBag from '../error-bag/ErrorBag';
import './Input.scss';

const Input = React.forwardRef((props, ref) => {
  const {
    errors = [],
    label,
    charsLeft,
    focused,
    renderLeftInsert,
    className,
    containerClassName,
    ...inputProps
  } = props;

  const classes = classNames('qm-input', className, {
    'is-invalid': errors.length,
    'with-left-insert': renderLeftInsert,
  });

  const containerClasses = classNames('qm-input-container', containerClassName);

  return (
    <div className={containerClasses}>
      <div className='input-info'>
        {label && (
          <label className='form-label' htmlFor={inputProps.name}>
            {label}
          </label>
        )}
        {charsLeft !== undefined && (
          <span className='chars-left'>{charsLeft} characters left</span>
        )}
      </div>

      {renderLeftInsert && (
        <span className='insert left-insert'>{renderLeftInsert()}</span>
      )}
      <input
        ref={ref}
        data-testid='input'
        className={classes}
        {...inputProps}
      />

      <ErrorBag errors={errors} />
    </div>
  );
});

export default Input;
