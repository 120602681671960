/**
 * List of string templates for different error codes that we can receive from the backend.
 * List of all codes can be found in this document
 * https://docs.google.com/spreadsheets/d/1oYxeeyVpNwMWoZAQN-N3Pp4phd5Tf0gBhwVi-lSZYX0
 */

// TODO: Move module-specific errors to their respective module
export const API_ERROR_CODES = {
  unique: 'Value needs to be unique',
  'asset.unique': 'Asset %%name%% already exists. Please use another name.',
  'string.alphanumWithSpaces': 'Please use only letters, numbers and spaces',
  required: 'This field is required',
  pattern: 'Value is invalid for this field',
  'number.integer': 'Value must be a whole number',
  'number.positive': 'Value must be greater than 0',
  'number.base': 'Value must be a number',
  'string.base': 'Value must be a string',
  'date.base': 'Value is not a valid date',
  'any.only': 'Value must be one of specified options',
};

export const ERROR_TEMPLATES = {
  required: 'This field is required',
  number: 'Input is not a valid number',
  alphanumeric: 'Please use only alphanumeric characters',
  alphanumWithSpaces: 'Please use only letters, numbers and spaces',
  large: '%%field=Value%% is too large',
  exceeded: '%%field=Value%% cannot be greater than %%max%%',
  minimum: '%%field=Value%% must be greater than %%min%%',
};
