import { Link } from 'react-router-dom';
import ResetPasswordForm from '../reset-password-form/ResetPasswordForm';

import './ResetPasswordPage.scss';

const ResetPasswordPage = () => {
  return (
    <div className='reset-password-page'>
      <div className='reset-password-form-container'>
        <h3>Reset your password</h3>
        <p className='instructions'>
          Enter the email associated with your account and we’ll send an email
          with instructions to reset it.
        </p>
        <ResetPasswordForm />
      </div>
      <div className='footer-container'>
        Remembered your password? <Link to='/'>Login</Link>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
