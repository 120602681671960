import classNames from 'classnames';

import { ReactComponent as EditOutline } from 'assets/images/edit-outline.svg';

import './SettingsQuestionsList.scss';
import { HeaderSortButton } from 'lib/components/table/Table';

const QUESTION_COLUMNS = [
  {
    title: <EditOutline />,
    className: 'edit-cell',
    textAlign: 'center',
  },
  {
    title: 'ID / External ID',
    className: 'id-cell',
    textAlign: 'left',
    sortId: 'id',
  },
  {
    title: 'Question',
    className: 'question-cell',
    textAlign: 'left',
    sortId: 'name',
  },
  {
    title: 'Question Type',
    className: 'question-type-cell',
    textAlign: 'left',
  },
  {
    title: 'Sample Size',
    className: 'sample-size-cell',
    textAlign: 'right',
  },
  {
    title: '',
    className: 'toggle-cell',
    textAlign: 'right',
  },
];

export default function SettingsQuestionsList({
  sortOrder,
  setSortBy,
  sortBy,
  children,
}) {
  return (
    <div className='settings-questions-list'>
      <div className={classNames('table-row table-header')}>
        {QUESTION_COLUMNS.map((col, idx) => (
          <div
            key={idx}
            className={classNames('table-cell', col.className, {
              [`align-${col.textAlign}`]: col.textAlign,
            })}
          >
            {col.sortId ? (
              <HeaderSortButton
                label={col.title}
                sortId={col.sortId}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                sortBy={sortBy}
              />
            ) : (
              col.title
            )}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}
