import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StateSelect from './StateSelect';
import StateUpload from './StateUpload';
import StateAnalyzing from './StateAnalyzing';
import states from './CsvImportStates';
import request from 'lib/request';
import './CsvImport.scss';

function CsvImport({ type, propName, onUploadInfo }) {
  const [state, setState] = useState(states.STATE_3_ANALYZING);
  const [uploadingFile, setUploadingFile] = useState('');
  const [uploadProps, setUploadProps] = useState({});

  function onUploadProgress(progressEvent) {
    setUploadProps({
      fileName: uploadingFile,
      uploadedBytes: progressEvent.loaded,
      totalBytes: progressEvent.total,
    });
  }

  function uploadSelected(files) {
    var formData = new FormData();
    formData.append(propName, files[0]);
    const fileName = files[0].name;
    setUploadingFile(fileName);
    setUploadProps({
      fileName,
      uploadedBytes: 0,
      totalBytes: 0,
    });
    setState(states.STATE_2_UPLOAD);
    // reset upload Info
    onUploadInfo();

    request
      .post(`/imports/${type}/upload-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      })
      .then(({ job }) => {
        // Upload finished
        setState(states.STATE_3_ANALYZING);
        if (job?.file) {
          onUploadInfo({
            file: job.file,
          });
        }
      })
      .catch(() => {
        // Uploaded error
        setState(states.STATE_1_SELECT);
        onUploadInfo({
          errors: 'Error uploading file',
        });
      });
  }

  function onAnalyzingFinished(data) {
    setState(states.STATE_1_SELECT);
    if (data) {
      onUploadInfo({
        globalError: data.globalError,
        errors: data.errors,
        done: data.successCount,
        timestamps: {
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        },
        file: data.file,
      });
    }
  }

  return (
    <div className='CSVPlaceholder'>
      {state === states.STATE_1_SELECT && (
        <StateSelect onUploadSelected={uploadSelected} />
      )}
      {state === states.STATE_2_UPLOAD && <StateUpload {...uploadProps} />}
      {state === states.STATE_3_ANALYZING && (
        <StateAnalyzing type={type} onFinished={onAnalyzingFinished} />
      )}
    </div>
  );
}

CsvImport.propTypes = {
  type: PropTypes.string.isRequired,
  onUploadInfo: PropTypes.func.isRequired,
  propName: PropTypes.string,
};

CsvImport.defaultProps = {
  propName: 'file',
};

export default CsvImport;
