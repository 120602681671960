import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import QuestionGroup from 'lib/components/question-group/QuestionGroup';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';

import './InspectionPlansForm.scss';

import './InspectionPlansForm.scss';
import InspectionPlansFormNewQuestion from './InspectionPlansFormNewQuestion';

const InspectionPlansFormNewGroups = ({ vm }) => {
  if (!vm?.planForm) {
    return null;
  }

  const { doOpenQuestionGroupModal } = vm.modals;

  // TODO: Refactor along with error model
  const questionHasErrors = (data) =>
    !!Object.keys(data || {})
      .map((key) =>
        Array.isArray(data[key])
          ? data[key].map((i) => i.errors || [])
          : data[key]?.errors || [],
      )
      .flat(2).length;

  return (
    <>
      <h3>Questions</h3>
      <div className='questions-subtitle'>
        You can only override or add questions for this plan here. To change
        questions across all inspection plans, please go to the settings page.
        <Button
          type='button'
          className='medium-button'
          data-testid='InspectionPlansForm.addQuestionGroupButton'
          onClick={() => doOpenQuestionGroupModal()}
        >
          Add question group
        </Button>
      </div>
      {!!vm.planForm?.questionGroups?.length && (
        <Tabs>
          {vm?.languages.map((lang) => (
            <TabsItem
              key={lang.code}
              active={vm.language === lang.code}
              onClick={() => vm.setLanguage(lang.code)}
            >
              {lang.label}
            </TabsItem>
          ))}
        </Tabs>
      )}
      <div className='question-groups-container'>
        {vm.planForm?.questionGroups?.map((group, gIndex) => {
          const linkBtn = !!group.isCustom ? (
            <Button
              type='button'
              className='medium-button'
              onClick={() => vm.doRelinkGroup(gIndex)}
            >
              Re-Link
            </Button>
          ) : (
            <Button
              type='button'
              className='medium-button'
              onClick={() => vm.doUnlinkGroup(gIndex)}
            >
              Unlink
            </Button>
          );
          const groupActions = (
            <>
              <Button
                type='button'
                className='medium-button'
                onClick={() => vm.doAddCustomQuestionToGroup(gIndex)}
              >
                Add question
              </Button>
              {linkBtn}
              <DeleteButton
                type='button'
                className='medium-button outline-button remove-question-group-button'
                onClick={() => vm.doRemoveQuestionGroup(gIndex)}
              >
                Remove group
              </DeleteButton>
            </>
          );
          const groupHasErrors = group.questions.some((q) =>
            questionHasErrors(q),
          );
          return (
            <QuestionGroup
              data-testid='InspectionPlansForm.questionGroup'
              key={gIndex}
              data={group}
              editable={true}
              hasErrors={groupHasErrors}
              actions={groupActions}
              showHeaderDependency={true}
            >
              {group.questions?.map((question, qIndex) => (
                <InspectionPlansFormNewQuestion
                  key={qIndex}
                  gIndex={gIndex}
                  qIndex={qIndex}
                  vm={vm}
                />
              ))}
            </QuestionGroup>
          );
        })}
      </div>
    </>
  );
};
export default InspectionPlansFormNewGroups;
