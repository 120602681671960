import * as yup from 'yup';
import _set from 'lodash.set';
import _get from 'lodash.get';

import validator from 'lib/validator';

// validate if value is unique in array
yup.addMethod(yup.array, 'unique', function (message, path, key) {
  return this.test('unique', message, function (list) {
    const mapper = (item) => _get(item, path)?.toLowerCase();
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const fieldIndex = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `${key}[${fieldIndex}].${path}`,
      message,
    });
  });
});

const companiesCommonFields = {
  name: yup.object().shape({
    value: yup.string().required('Name is a required field'),
  }),
  status: yup.object().shape({
    value: yup.string().required('Status is a required field'),
  }),

  phones: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
      }),
    )
    .unique('Phone needs to be unique', 'value', 'phones'),
  emails: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
      }),
    )
    .unique('Email needs to be unique', 'value', 'emails'),
  customConfig: yup.object().shape({
    value: yup.string().test('is-json-string', 'Not valid JSON', (value) => {
      if (!value?.trim()?.length) {
        return true;
      }
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    }),
  }),
};

export const newCompaniesFormValidationSchema = yup.object().shape({
  ...companiesCommonFields,
  adminName: yup.object().shape({
    value: yup.string().required('User name is a required field'),
  }),
  adminEmail: yup.object().shape({
    value: yup
      .string()
      .email('User email needs to be a valid email')
      .required('User email is a required field'),
  }),
});

export const editCompaniesFormValidationSchema = yup.object().shape({
  ...companiesCommonFields,
});

export const processCompaniesFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    const path = curr.path.split('.').slice(0, -1).join('.');

    let originalValue, valueIndex;

    if (Array.isArray(curr.params.originalValue)) {
      valueIndex = Number(curr.path.match(/\[(.*?)\]/)[1]);
      originalValue = curr.params.originalValue[valueIndex].value;
    } else {
      originalValue = curr.params.originalValue;
    }

    _set(acc, path, {
      value: originalValue,
      errors: curr.errors,
    });
    return acc;
  }, input);
};

export const processAPIError = (errorDetails, company) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => path);
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, company);
};

const companiesFormValidator = (state) => {
  const validationSchema = state?.id
    ? editCompaniesFormValidationSchema
    : newCompaniesFormValidationSchema;
  return validator(state, validationSchema, processCompaniesFormError);
};

export default companiesFormValidator;
