export const DEFECT_WEIGHT = {
  MINOR: 'minor',
  MAJOR: 'major',
  CRITICAL: 'critical',
  SEVERE: 'severe',
  INFORMATIONAL: 'informational',
  FUNCTIONAL: 'functional',
};

export const DEFECT_LABEL = {
  [DEFECT_WEIGHT.MINOR]: 'Minor',
  [DEFECT_WEIGHT.MAJOR]: 'Major',
  [DEFECT_WEIGHT.CRITICAL]: 'Critical',
  [DEFECT_WEIGHT.SEVERE]: 'Severe',
  [DEFECT_WEIGHT.INFORMATIONAL]: 'Informational',
  [DEFECT_WEIGHT.FUNCTIONAL]: 'Functional',
};

export const SYSTEM_DEFECTS = {
  LOWER_TOLERANCE: 'Lower tolerance exceeded',
  UPPER_TOLERANCE: 'Upper tolerance exceeded',
};
