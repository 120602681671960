import classNames from 'classnames';
import React from 'react';

import Button from '../button/Button';
import DeleteButton from '../delete-button/DeleteButton';
import ErrorBag from '../error-bag/ErrorBag';
import File from '../file/File';
import Input from '../input/Input';
import UploadButton from '../upload-button/UploadButton';

import './DocumentUpload.scss';

/**
 * @param {{
 *   file: File,
 *   errors?: string[],
 *   index?: number,
 *   value?: string,
 *   onChange: (ev: React.FormEvent<HTMLInputElement>, index:number) => any,
 *   onDelete: (index: number) => any,
 *   onUpload: (files: FileList) => any
 *   disabled: boolean
 * }} props
 */
const DocumentUploadItem = ({
  file,
  errors = [],
  value = '',
  index = 0,
  onChange,
  onDelete,
  disabled = false,
  customInput,
}) => {
  return (
    <div className='document-item' key={`document-item-${index}`}>
      <div className='form-group'>
        <File iconOnly data={file} />
        {typeof customInput === 'function' ? (
          customInput({ value, index, onChange, errors })
        ) : (
          <Input
            name='name'
            type='text'
            value={value}
            className={!!errors.length && 'is-invalid'}
            onChange={(e) => onChange(e, index)}
            data-testid={`DocumentUpload.document-${index}`}
          />
        )}
        {!disabled && (
          <DeleteButton
            className='delete-custom-field'
            onClick={() => onDelete(index)}
          />
        )}
      </div>
      <ErrorBag errors={errors} />
    </div>
  );
};

/**
 * @param {{
 *   data: {value: string, errors: string[], file: File}[],
 *   errors?: string[];
 *   onChange: (ev: React.FormEvent<HTMLInputElement>, index:number) => any,
 *   onDelete: (index: number) => any,
 *   onUpload?: (files: FileList) => any,
 *   onCancel?: () => any,
 *   disabled?: boolean,
 *   hasChanges?: boolean,
 *   label?: string,
 * }} props
 */
const DocumentUpload = ({
  data = [],
  errors = [],
  onChange,
  onDelete,
  onUpload,
  onCancel,
  disabled,
  hasChanges = false,
  className = '',
  label,
  customInput,
}) => {
  return (
    <div className={classNames('qm-document-upload', className)}>
      {label && <label className='form-label'>{label}</label>}
      <div className='document-list'>
        {data.map((item, index) => (
          <DocumentUploadItem
            file={item.file}
            value={item.value}
            errors={item.errors}
            index={index}
            onChange={onChange}
            onDelete={onDelete}
            disabled={disabled}
            customInput={customInput}
          />
        ))}
      </div>
      <div className='document-upload-actions'>
        {onUpload && (
          <UploadButton
            options={{ multiple: true }}
            onUpload={onUpload}
            disabled={disabled}
          >
            Upload
          </UploadButton>
        )}
        {onCancel && hasChanges && (
          <Button
            className='medium-button'
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel changes
          </Button>
        )}
      </div>
      <ErrorBag errors={errors} />
    </div>
  );
};

export default DocumentUpload;
