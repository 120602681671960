import classNames from 'classnames';
import './AnswerDetail.scss';
import { AnswerDetailValuesFunctions } from './AnswerDetailValuesFunctions';

export default function AnswerDetailValues({ question, inspection }) {
  if (question?.answerType !== 'quantitative_input') {
    return null;
  }
  const rawAnswersArray = AnswerDetailValuesFunctions.getAnswerValuesArray(
    question,
    inspection,
  );
  const answerValuesArray = AnswerDetailValuesFunctions.convertRawAnswers(
    rawAnswersArray,
    question,
  );

  if (!answerValuesArray?.length) {
    return null;
  }

  return (
    <>
      {(() => {
        let lastAnswerIndex = 0;
        let dimIdx = -1;

        return answerValuesArray?.map((av, avIdx) => {
          const tv = AnswerDetailValuesFunctions.getTargetValueData(
            question,
            inspection,
            av,
          );
          const diff = AnswerDetailValuesFunctions.getAnswerDeviation(av, tv);

          if (av.answerIndex === lastAnswerIndex) {
            dimIdx++;
          } else {
            dimIdx = 0;
          }

          lastAnswerIndex = av.answerIndex;

          const renderDimensionLabel = () =>
            av.answerExpectedMeasureTableResult === 'Dimensions' ? (
              <>
                <span className='answer-dimension'>
                  {av.answerExpectedMeasureTableResultDimension}
                </span>
                <span className='answer-dimension-equals'> = </span>
              </>
            ) : null;

          const renderAnswerValue = () => (
            <span className='answer-value'>{av.answerValue}</span>
          );

          const renderAnswerUom = () => {
            const answerUom = av.answerUnit ?? tv.targetValueUnit;
            return answerUom?.length ? (
              <span className='answer-uom'>{answerUom}</span>
            ) : null;
          };

          const renderAnswerDeviation = () => {
            let output = null;

            if (Number.isFinite(diff.dAbsolute) && diff.dAbsolute !== 0) {
              const diffSign = diff.dAbsolute > 0 ? '+' : '-';

              output = (
                <span
                  className={classNames('answer-deviation', {
                    'exceeds-tolerance': !!diff.exceedsTolerance,
                  })}
                >
                  ({diffSign}
                  {Math.abs(diff.dAbsolute)}
                  {av.answerUnit ?? tv.targetValueUnit}
                  {Number.isFinite(diff.dPercentage) && `/${diff.dPercentage}%`}
                  {' of expected '}
                  {tv.targetValue}
                  {av.answerUnit ?? tv.targetValueUnit})
                </span>
              );
            }

            return output;
          };

          const output = (
            <div className='answer-value-item' key={avIdx}>
              {renderDimensionLabel()}
              {renderAnswerValue()}
              {renderAnswerUom()} {renderAnswerDeviation()}
            </div>
          );

          return output;
        });
      })()}
    </>
  );
}
