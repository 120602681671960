import INSPECTION_ACTIVITIES from 'config/activities/inspections.activities';

const RENDER_INSPECTIONS = {
  [INSPECTION_ACTIVITIES.INSPECTION_STARTED]: (author, data) => {
    const source = <strong>{data?.actions?.source}</strong>;
    const inspectionType = <strong>{data?.actions?.inspectionType}</strong>;
    return (
      <>
        {author} has started a {inspectionType} at {source}
      </>
    );
  },
  [INSPECTION_ACTIVITIES.INSPECTION_FINISHED]: (author, data) => {
    const inspectionResult = <strong>{data?.actions?.result}</strong>;
    const inspectionType = <strong>{data?.actions?.inspectionType}</strong>;
    const source = <strong>{data?.actions?.source}</strong>;
    return (
      <>
        {author} has finished a {inspectionType} at {source}. The inspection has{' '}
        {inspectionResult}
      </>
    );
  },
  [INSPECTION_ACTIVITIES.INSPECTION_NEW_INSPECTOR]: (author, data) => {
    const inspectionType = <strong>{data?.actions?.inspectionType}</strong>;
    const source = <strong>{data?.actions?.source}</strong>;
    const assignedInspector = (
      <strong>{data?.actions?.assignedInspector}</strong>
    );
    return (
      <>
        {author} has assigned {assignedInspector} to a {inspectionType} at{' '}
        {source}
      </>
    );
  },
  [INSPECTION_ACTIVITIES.INSPECTION_UNASSIGNED_INSPECTOR]: (author, data) => {
    const inspectionType = <strong>{data?.actions?.inspectionType}</strong>;
    const source = <strong>{data?.actions?.source}</strong>;
    const oldInspector = <strong>{data?.actions?.oldInspectorName}</strong>;
    return (
      <>
        {author} has unassigned {oldInspector} from a {inspectionType} at{' '}
        {source}
      </>
    );
  },
  [INSPECTION_ACTIVITIES.INSPECTION_MANUAL_INSPECTION_CREATED]: (
    author,
    data,
  ) => {
    const inspectionType = <strong>{data?.actions?.inspectionType}</strong>;
    const source = <strong>{data?.actions?.source}</strong>;
    return (
      <>
        {author} has added a {inspectionType} at {source}
      </>
    );
  },
};

export default RENDER_INSPECTIONS;
