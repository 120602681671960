import InspectionPlansFormNew from '../inspection-plans-form/InspectionPlansFormNew';
import './InspectionPlansEditPage.scss';

const InspectionPlansEditPage = (props) => {
  return (
    <div className='inspection-plans-edit-page container-m'>
      <div className='row'>
        <h2>Edit inspection plan</h2>
      </div>
      <div className='row'>
        <InspectionPlansFormNew {...props} />
      </div>
    </div>
  );
};

export default InspectionPlansEditPage;
