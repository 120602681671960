import { INSPECTION_TARGET, INSPECTOR_TYPE } from 'config/inspectionConfig';
import {
  fileListToFormObject,
  fileToFormObject,
} from 'lib/components/document-upload/dataTransform';
import makeReducer from 'lib/makeReducer';

export const MAX_FILE_SIZE = 50 * 1024 * 1024;

const INITIAL_FORM_STATE = {
  id: null,
  inspectorType: {
    value: INSPECTOR_TYPE.INTERNAL,
    errors: [],
  },
  inspector: {
    value: null,
    errors: [],
  },
  thirdPartyInspectorName: {
    value: '',
    errors: [],
  },
  thirdPartyInspectingCompany: {
    value: '',
    errors: [],
  },
  inspectionTarget: {
    value: INSPECTION_TARGET.ASSET,
    errors: [],
  },
  inspectionDate: {
    value: '',
    errors: [],
  },
  inspectionWindow: {
    value: ['', ''],
    errors: [],
  },
  validUntil: {
    value: '',
    errors: [],
  },
  inspectionResult: {
    value: null,
    errors: [],
  },
  inspectionReport: {
    file: null,
    value: '',
    errors: [],
  },
  initialInspectionReport: null,
  asset: {
    value: null,
    errors: [],
  },
  source: {
    value: null,
    errors: [],
  },
  inspectionType: {
    value: null,
    errors: [],
  },
  documents: {
    list: [],
    errors: [],
  },
  partial: null,
  initialDocuments: [],
  isSubmitting: false,
  isLoading: false,
  inspectionTypeOptions: [],
  isAttachedInspection: false,
  isDirty: false,
};

export const INSPECTION_EXTERNAL_FORM_ACTIONS = {
  APP_LOADS_INSPECTION: 'APP_LOADS_INSPECTION',
  APP_LOADS_TYPE_OPTIONS: 'APP_LOADS_TYPE_OPTIONS',
  APP_LOADS_WORK_OBJECT_ATTACHED_DATA: 'APP_LOADS_WORK_OBJECT_ATTACHED_DATA',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  APP_SETS_ERRORS: 'APP_SETS_ERRORS',

  USER_SETS_INPUT: 'USER_SETS_INPUT',
  USER_SETS_INSPECTION_TARGET: 'USER_SETS_INSPECTION_TARGET',
  USER_SETS_INSPECTOR_TYPE: 'USER_SETS_INSPECTOR_TYPE',

  USER_TYPES_DOCUMENT_NAME: 'USER_TYPES_DOCUMENT_NAME',
  USER_UPLOADS_DOCUMENTS: 'USER_UPLOADS_DOCUMENTS',
  USER_DELETES_DOCUMENT: 'USER_DELETES_DOCUMENT',
  USER_RESETS_DOCUMENTS: 'USER_RESETS_DOCUMENTS',

  USER_UPLOADS_REPORT: 'USER_UPLOADS_REPORT',
  USER_RESETS_REPORT: 'USER_RESETS_REPORT',

  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
};

const INSPECTION_EXTERNAL_REDUCER_CONFIG = {
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INPUT]: (state, action) => {
    return {
      ...state,
      [action.key]: {
        value: action.payload,
        errors: [],
      },
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_TYPES_DOCUMENT_NAME]: (
    state,
    action,
  ) => {
    const { key, payload } = action;
    const newList = [...state.documents.list];
    newList[key] = {
      value: payload,
      file: newList[key],
      errors: [],
    };
    return {
      ...state,
      documents: {
        ...state.documents,
        list: newList,
      },
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_UPLOADS_DOCUMENTS]: (
    state,
    action,
  ) => {
    const { list, errors } = action.payload;
    const newDocuments = fileListToFormObject(list);
    return {
      ...state,
      documents: {
        list: [...state.documents.list, ...newDocuments],
        errors,
      },
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_DELETES_DOCUMENT]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      documents: {
        ...state.documents,
        list: state.documents.list.filter((_, index) => index !== payload),
      },
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_RESETS_DOCUMENTS]: (state) => {
    return {
      ...state,
      documents: {
        list: state.initialDocuments,
        errors: [],
      },
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_RESETS_REPORT]: (state) => {
    return {
      ...state,
      inspectionReport: state.initialInspectionReport,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_INSPECTION]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_WORK_OBJECT_ATTACHED_DATA]: (
    state,
    action,
  ) => {
    return {
      ...state,
      ...action.payload,
      isAttachedInspection: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INSPECTION_TARGET]: (
    state,
    action,
  ) => {
    return {
      ...state,
      inspectionTarget: {
        value: action.payload,
        errors: [],
      },
      inspectionType: {
        value: null,
        errors: [],
      },
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_UPLOADS_REPORT]: (state, action) => {
    const file = action.payload;

    if (file.size >= MAX_FILE_SIZE) {
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          errors: ['Maximum allowed size for documents is 50MB'],
        },
      };
    }

    return {
      ...state,
      inspectionReport: fileToFormObject(file),
      isDirty: true,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      isSubmitting: true,
      isDirty: false,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.APP_SETS_ERRORS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isSubmitting: false,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      isSubmitting: false,
      isDirty: false,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.APP_LOADS_TYPE_OPTIONS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      inspectionTypeOptions: action.payload,
    };
  },
  [INSPECTION_EXTERNAL_FORM_ACTIONS.USER_SETS_INSPECTOR_TYPE]: (
    state,
    action,
  ) => {
    const type = action.payload;
    return {
      ...state,
      inspectorType: {
        value: type,
        errors: [],
      },
      thirdPartyInspectorName: {
        value:
          type === INSPECTOR_TYPE.INTERNAL
            ? ''
            : state.thirdPartyInspectorName.value,
        errors: [],
      },
      thirdPartyInspectingCompany: {
        value:
          type === INSPECTOR_TYPE.INTERNAL
            ? ''
            : state.thirdPartyInspectingCompany.value,
        errors: [],
      },
      inspector: {
        value: type === INSPECTOR_TYPE.EXTERNAL ? null : state.inspector.value,
        errors: [],
      },
      isDirty: true,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_EXTERNAL_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
