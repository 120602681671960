import AssetsForm from '../assets-form/AssetsForm';
import 'react-dropzone-uploader/dist/styles.css';
import { MeasurementCollection } from 'modules/measurement-table/MeasurementTableContext';
import './AssetsEditPage.scss';

const AssetsEditPage = (props) => {
  return (
    <div
      className='assets-edit-page container-m'
      data-testid='AssetsEditPage.container'
    >
      <div className='row'>
        <h2>Edit Asset</h2>
      </div>
      <MeasurementCollection>
        <AssetsForm {...props} />
      </MeasurementCollection>
    </div>
  );
};

export default AssetsEditPage;
