import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DEFECT_WEIGHT, DEFECT_LABEL } from 'config/defects';

import './Defect.scss';

function Defect({
  weight,
  count,
  label,
  iconOnly = false,
  isAqlRejected = false,
  ...props
}) {
  const defectString = count > 1 ? 'defects' : 'defect';
  const countDigitsNum = `${count}`.length;

  return (
    <div
      className={classNames('qm-defect', `count-digits-${countDigitsNum}`)}
      {...props}
    >
      <div
        className={classNames(
          'pill',
          `is-${isAqlRejected ? 'rejected' : 'accepted'}`,
        )}
      >
        <span className='value'>{count ?? '!'}</span>
      </div>
      {!iconOnly && !!weight?.length ? (
        <div className='description'>
          {label ?? DEFECT_LABEL[weight]} {defectString}
        </div>
      ) : null}
    </div>
  );
}

Defect.propTypes = {
  weight: PropTypes.oneOf(Object.values(DEFECT_WEIGHT)),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconOnly: PropTypes.bool,
};

export default Defect;
