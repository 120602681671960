import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Toggle from '../toggle/Toggle';
import ErrorBag from '../error-bag/ErrorBag';
import ImageFallback from '../image-fallback/ImageFallback';

import { ReactComponent as ClearCircle } from 'assets/images/remove.svg';
import './ResourceSelectButton.scss';

const ResourceSelectButton = React.forwardRef(
  (
    {
      placeholder = 'Select...',
      thumbUrl,
      thumbnail,
      title,
      subtitle,
      onClick,
      className,
      name,
      label,
      errors,
      disabled,
      isClearable = false,
      onClear,
      ...props
    },
    ref,
  ) => {
    const buttonClasses = classNames('qm-resource-select-button', className, {
      'is-invalid': errors?.length,
      disabled,
    });
    const showClear = isClearable && !!title && typeof onClear === 'function';
    return (
      <div className='qm-resource-select-button-container'>
        <div className='input-info'>
          {label && (
            <label className='form-label' htmlFor={name}>
              {label}
            </label>
          )}
        </div>
        <div
          className={buttonClasses}
          data-testid='resource-select-button'
          onClick={!disabled ? onClick : undefined}
          ref={ref}
          {...props}
        >
          {thumbnail
            ? thumbnail
            : thumbUrl && (
                <ImageFallback
                  src={thumbUrl}
                  className='thumbnail'
                  fallback={null}
                />
              )}
          <div className='text'>
            {!title ? (
              <div className='placeholder'>{placeholder}</div>
            ) : (
              <>
                {subtitle && <div className='subtitle'>{subtitle}</div>}
                <div className='title'>{title}</div>
              </>
            )}
          </div>
          {showClear && (
            <ClearCircle
              className='clear-icon'
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                onClear();
              }}
            />
          )}
          <Toggle direction='down' />
        </div>
        <ErrorBag errors={errors} />
      </div>
    );
  },
);

ResourceSelectButton.displayName = 'ResourceSelectButton';

export default ResourceSelectButton;
