import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import WorkflowsListPage from './components/workflows-list-page/WorkflowsListPage';
import { VIEW_ALL_ROLES, ADD_WORKFLOW_ROLES } from './workflowsPermissions';
import WorkflowsAddPage from './components/workflows-add-page/WorkflowsAddPage';
import WorkflowsDetailPage from './components/workflows-detail-page/WorkflowsDetailPage';
import WorkflowsEditPage from './components/workflows-edit-page/WorkflowsEditPage';

export default function WorkflowsRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/workflows'
        exact
        render={(props) => <WorkflowsListPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_WORKFLOW_ROLES}
        path='/workflows/add'
        exact
        render={(props) => <WorkflowsAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/workflows/:id(\d+)'
        exact
        render={(props) => <WorkflowsDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_WORKFLOW_ROLES}
        path='/workflows/:id(\d+)/edit'
        exact
        render={(props) => <WorkflowsEditPage {...props} />}
      />
    </>
  );
}
