import Table from 'lib/components/table/Table';
import SettingsAnswersForm from '../settings-answers-form/SettingsAnswersForm';
import SettingsAnswersItem from '../settings-answers-item/SettingsAnswersItem';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';

import './SettingsAnswersTable.scss';

const settingsAnswersTable = ({
  userClicksOnRow,
  actions,
  editField,
  languages,
  language,
  onLanguageChange,
  addAnswerName,
  onChange,
  addAnswerOption,
  cancelEdit,
  getDefaultAction,
  userEditsAction,
  clearOptionInEdit,
  onSave,
  setEdit,
  ...props
}) => {
  const renderAnswerCell = (record) => {
    if (editField?.id === record.id) {
      return (
        <>
          <Tabs>
            {languages.map((lang) => (
              <TabsItem
                key={lang.code}
                active={language === lang.code}
                onClick={() => onLanguageChange(lang.code)}
              >
                {lang.label}
              </TabsItem>
            ))}
          </Tabs>
          <SettingsAnswersForm
            answer={editField}
            addAnswerName={addAnswerName}
            onChange={onChange}
            actions={actions}
            addAnswerOption={addAnswerOption}
            cancelEdit={cancelEdit}
            getDefaultAction={getDefaultAction}
            userEditsAction={userEditsAction}
            clearOptionInEdit={clearOptionInEdit}
            onSave={onSave}
            language={language}
          />
        </>
      );
    } else {
      return (
        <SettingsAnswersItem
          setEdit={() => setEdit(record.name, record.id)}
          name={record.name}
          editing={editField.editing}
        />
      );
    }
  };

  const settingsAnswersTableColumns = [
    {
      title: 'ID / External ID',
      sortId: 'id',
      headerClassName: 'id-cell',
      className: 'id-cell',
      render: (record) =>
        [record.id, record.externalId].filter((id) => id).join(' / '),
    },
    {
      title: 'Answer groups',
      sortId: 'name',
      headerClassName: 'name-cell',
      render: (record) => renderAnswerCell(record),
    },
  ];

  return (
    <Table
      columns={settingsAnswersTableColumns}
      className='answers-table'
      {...props}
    />
  );
};

export default settingsAnswersTable;
