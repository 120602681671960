import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import INSPECTION_STATUS, {
  INSPECTION_RESULT,
  getInspectionStatusLabel,
} from 'config/inspectionStatus';

import { ReactComponent as CrossOutline } from 'assets/images/x.svg';
import { ReactComponent as PauseOutline } from 'assets/images/pause.svg';
import { ReactComponent as CheckOutline } from 'assets/images/check.svg';

const INSPECTION_TO_PILL_VARIANT = {
  [INSPECTION_RESULT.IN_PROGRESS]: STATUS_PILL_VARIANT.NOTIFY,
  [INSPECTION_RESULT.PASSED]: STATUS_PILL_VARIANT.SUCCESS,
  [INSPECTION_RESULT.FAILED]: STATUS_PILL_VARIANT.ERROR,
  [INSPECTION_RESULT.SKIPPED]: STATUS_PILL_VARIANT.WARNING,
  [INSPECTION_STATUS.ON_HOLD]: STATUS_PILL_VARIANT.WARNING,
};

const INSPECTIONS_ICON = {
  [INSPECTION_RESULT.FAILED]: <CrossOutline />,
  [INSPECTION_RESULT.PASSED]: <CheckOutline />,
  [INSPECTION_STATUS.ON_HOLD]: <PauseOutline />,
};

const InspectionsResultPill = ({ status, ...props }) => {
  const variant = INSPECTION_TO_PILL_VARIANT[status];
  const icon = INSPECTIONS_ICON[status] || null;

  return (
    <StatusPill
      icon={icon}
      className='inspection-result-pill'
      variant={variant}
      {...props}
    >
      {getInspectionStatusLabel(status)}
    </StatusPill>
  );
};

export default InspectionsResultPill;
