import Input from 'lib/components/input/Input';
import Modal from 'lib/components/modal/Modal';
import Button from 'lib/components/button/Button';

import './SettingsDefectsInputModal.scss';
import LanguageSelect from 'lib/components/language-select/LanguageSelect';
import { getTranslation } from 'lib/dataTransform';

const SettingsDefectsInputModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  sortOrder,
  setSortBy,
  sortBy,
  onRequestClose,
  language,
  onLanguageChange,
  ...props
}) => {
  const { translation, fallback } = getTranslation(data.name.value, language);
  return (
    <Modal
      title='Add new defect'
      className='settings-input-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      data-testid='question-groups-modal'
      {...props}
    >
      <LanguageSelect language={language} onChange={onLanguageChange} />
      <Input
        label='Defect name'
        type='text'
        value={translation}
        placeholder={fallback}
        errors={data.name.errors}
        autoComplete='off'
        onChange={props.onChange}
        className='defects-input'
        data-testid='SettingsInputModal.name'
      />
      <div className='flex'>
        <Button
          type='button'
          data-testid='SettingsInputModal.saveButton'
          className='medium-button'
          onClick={props.addDefect}
        >
          Save
        </Button>
        <p onClick={onRequestClose} className='link-button'>
          Cancel
        </p>
      </div>
    </Modal>
  );
};

export default SettingsDefectsInputModal;
