import { Link } from 'react-router-dom';
import './LinkText.scss';

const LinkButton = ({ name, link, ...props }) => {
  return (
    <Link to={link} className='link' {...props}>
      {name}
    </Link>
  );
};

export default LinkButton;
