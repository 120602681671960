const WORK_OBJECT_STATUS = {
  active: 'Active',
  deactivated: 'Deactivated',
  draft: 'Draft',
  finished: 'Finished',
  pending: 'Pending',
  in_progress: 'In progress',
  canceled: 'Canceled',
};

export default WORK_OBJECT_STATUS;
