import AddButton from 'lib/components/add-button/AddButton';
import EditButton from 'lib/components/edit-button/EditButton';
import DefectsModal from 'lib/components/defects-modal/DefectsModal';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';
import QuestionForm from 'lib/components/question-form/QuestionForm';

import Button from 'lib/components/button/Button';
import useSettingsQuestionsForm from 'modules/settings/useSettingsQuestionsForm';
import './SettingsQuestionsForm.scss';
import Select from 'lib/components/select/Select';
import { useEffect, useState } from 'react';
import inspectionPlansService from 'modules/inspection-plans/inspectionPlansService';

const SettingsQuestionsForm = (props) => {
  const vm = useSettingsQuestionsForm(props);

  const [dependentQGList, setDependentQGList] = useState(null);
  const [dependentQGCount, setDependentQGCount] = useState(null);
  const [dependentQGShow, setDependentQGShow] = useState(false);

  useEffect(() => {
    const qtId = parseInt(props.match?.params?.id);
    if (!Number.isFinite(qtId)) {
      setDependentQGList(null);
      setDependentQGCount(null);
      return;
    }

    inspectionPlansService
      .getQuestionGroupTemplates({
        filters: {
          dependencyQuestionTemplateId: qtId, //1877,
        },
      })
      .then((res) => {
        if (res?.data) {
          setDependentQGList(res.data);
          setDependentQGCount(res.count);
        }
      })
      .catch(() => {
        setDependentQGList(null);
        setDependentQGCount(null);
        console.error('An error occured while fetching groups');
      });
  }, [props.match?.params?.id]);

  const SubmitButton = props.match?.params?.id ? EditButton : AddButton;
  const submitText = props.match?.params?.id
    ? 'Save changes'
    : 'Create question';

  const depQCount = vm.state.form.dependentQuestionsCount;
  return (
    <div className='qm-settings-question-form'>
      {dependentQGCount > 0 && (
        <div className='dependency-notice'>
          <p className='dependency-notice-text'>
            This Question is a dependency of <br />
            <strong>{depQCount}</strong> Question
            {depQCount > 1 && 's'} in <strong>{dependentQGCount}</strong>{' '}
            Question Group
            {dependentQGCount > 1 && 's'}:
          </p>
          {dependentQGCount > 5 && (
            <p className='dependency-notice-actions'>
              <Button
                className='medium-button'
                onClick={() => setDependentQGShow(!dependentQGShow)}
              >
                {dependentQGShow ? 'Hide' : 'Show'} Groups
              </Button>
            </p>
          )}
          {(dependentQGCount <= 5 || dependentQGShow) && (
            <ul class='qg-list'>
              {dependentQGList?.map((item, idx) => (
                <li key={idx}>{item.name}</li>
              ))}
            </ul>
          )}
        </div>
      )}
      <Tabs>
        {vm.languages.map((lang) => (
          <TabsItem
            key={lang.code}
            active={vm.state.language === lang.code}
            onClick={() => vm.userSetsLanguage(lang.code)}
          >
            {lang.label}
          </TabsItem>
        ))}
      </Tabs>
      <div className='form-label'>Question</div>
      <QuestionForm
        data={vm.state.form}
        isCollapsible={false}
        isNarrow={true}
        showKeywords={true}
        questionOptions={vm.getQuestionOptions()}
        onChange={vm.userChangesQuestionInput}
        onAddTool={vm.userAddsTool}
        onRemoveTool={vm.userRemovesTool}
        onAddAssetReferenceDocument={vm.userAddsAssetReferenceDocument}
        onRemoveAssetReferenceDocument={vm.userRemovesAssetReferenceDocument}
        onEditDefect={vm.userOpensDefectsModal}
        onRemoveDefect={vm.userRemovesDefect}
        onAddDocuments={vm.userAddsDocuments}
        onRemoveDocument={vm.userRemovesDocument}
        isAnswerTypeDisabled={
          depQCount > 0
            ? 'This cannot be changed because other questions have a dependency on it.'
            : false
        }
        onResetDocuments={vm.userResetsDocuments}
        language={vm.state.language}
        languageList={vm.languages}
      />
      <SubmitButton
        disabled={vm.state.processing}
        onClick={vm.userSavesQuestionForm}
      >
        {submitText}
      </SubmitButton>
      <DefectsModal
        data-testid='SettingsQuestionList.defectsModal'
        data={vm.getDefectsList()}
        count={vm.state.availableDefects.count}
        isCreatable
        onCreateClick={vm.userClicksCreateDefect}
        isOpen={vm.state.availableDefects.isModalOpen}
        onRequestClose={vm.userCancelsDefectsModal}
        onRowClick={vm.userTogglesDefect}
        onSubmit={vm.userEditsDefectList}
        onSearchChange={vm.userSearchesDefects}
        search={vm.state.availableDefects.search}
        setSortBy={vm.userSortsDefects}
        errors={vm.state.availableDefects.errors}
        page={vm.state.availableDefects.page}
        pageSize={vm.state.availableDefects.pageSize}
        setPage={vm.userSetsDefectsPage}
        sortBy={vm.state.availableDefects.sortBy}
        sortOrder={vm.state.availableDefects.sortOrder}
      />
    </div>
  );
};

export default SettingsQuestionsForm;
