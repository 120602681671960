import inspectionsService from './inspectionsService';

const { useState, useEffect } = require('react');

const useInspectionEditPage = (props) => {
  const [inspection, setInspection] = useState(null);
  const [loading, setLoading] = useState(true);
  const id = props.match?.params.id;

  useEffect(() => {
    if (id) {
      inspectionsService
        .getInspection(id)
        .then((inspection) => {
          setInspection(inspection);
          setLoading(false);
        })
        .catch(() => {
          props.history.push('/inspections');
        });
    }
  }, [props.match?.params.id]);

  return {
    inspection,
    loading,
  };
};

export default useInspectionEditPage;
