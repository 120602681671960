import { useEffect, useReducer, useState } from 'react';
import debounce from 'lodash.debounce';
import { cloneDeep } from 'lodash';

import {
  reducer,
  initialState,
  WORK_OBJECTS_FORM_ACTIONS,
} from './workObjectsFormReducer';
import workObjectsService from './workObjectsService';
import { workObjectsToFormState } from './dataTransform';
import workObjectsFormValidator from './workObjectsFormValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'Draft', value: 'draft' },
  { label: 'Finished', value: 'finished' },
  { label: 'Pending', value: 'pending' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Canceled', value: 'canceled' },
];
const INITIAL_RESOURCES = {
  assets: [],
  assetsCount: 0,
  sources: [],
  sourcesCount: 0,
};
// eslint-disable-next-line max-lines-per-function
const useWorkObjectsForm = (props) => {
  const [resourcesOptions, setResourcesOptions] = useState({
    ...INITIAL_RESOURCES,
  });
  const [workflowsOptions, setWorkflowsOptions] = useState({
    list: [],
    count: 0,
  });
  const [sourcesOptions, setSourcesOptions] = useState([]);

  const [state, dispatch] = useReducer(reducer, initialState);
  useNavigationPrompt(state.isDirty);

  const sourcesParams = {
    order: {
      [state.availableResources.sortBy]: state.availableResources.sortOrder,
    },
    search: state.availableResources.search,
    filters: { status: 'active' },
    page: state.availableResources.page,
    pageSize: state.availableResources.pageSize,
  };
  const workflowsParams = {
    order: {
      [state.availableWorkflows.sortBy]: state.availableWorkflows.sortOrder,
    },
    relations: ['workflowSteps'],
    search: state.availableWorkflows.search,
    filters: {
      assignableToSource:
        state.currentResource?.type === 'sources' ? true : undefined,
    },
    page: state.availableWorkflows.page,
    pageSize: state.availableWorkflows.pageSize,
  };
  const defaultSourceFilters = {
    order: {
      [state.availableSources.sortBy]: state.availableSources.sortOrder,
    },
    search: state.availableSources.search,
    page: state.availableSources.page,
    pageSize: state.availableSources.pageSize,
    filters: { status: 'active' },
  };
  const defaultDestinationFilters = {
    order: {
      [state.availableDestinations.sortBy]:
        state.availableDestinations.sortOrder,
    },
    search: state.availableDestinations.search,
    page: state.availableDestinations.page,
    pageSize: state.availableDestinations.pageSize,
    filters: { status: 'active' },
  };
  const defaultVendorFilters = {
    order: {
      [state.availableVendors.sortBy]: state.availableVendors.sortOrder,
    },
    search: state.availableVendors.search,
    page: state.availableVendors.page,
    pageSize: state.availableVendors.pageSize,
    filters: { status: 'active' },
  };

  const getWorkObject = () => {
    const id = props.match?.params?.id;
    return id
      ? workObjectsService
        .getWorkObject({ id })
        .then((workObject) => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: workObjectsToFormState(workObject),
          });
        })
        .catch((e) => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
      : Promise.resolve();
  };

  const getSources = debounce(
    (params) => {
      return workObjectsService
        .getSources(params)
        .then((response) => {
          setSourcesOptions(response.data);
          setResourcesOptions((prevState) => ({
            ...prevState,
            sources: response.data,
            sourcesCount: response.count,
          }));
        })
        .catch(() => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: {
              errors: ['An error occured while fetching sources'],
            },
          });
        });
    },
    400,
    { leading: true },
  );
  const getAssets = debounce(
    (params) => {
      return workObjectsService
        .getAssets(params)
        .then((response) => {
          setResourcesOptions((prevState) => ({
            ...prevState,
            assets: response.data,
            assetsCount: response.count,
          }));
        })
        .catch(() => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: {
              errors: ['An error occured while fetching assets'],
            },
          });
        });
    },
    400,
    { leading: true },
  );
  const getWorkflows = debounce(
    (params) => {
      return workObjectsService
        .getWorkflows(params)
        .then((response) => {
          setWorkflowsOptions({ list: response.data, count: response.count });
        })
        .catch(() => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: {
              errors: ['An error occured while fetching workflows'],
            },
          });
        });
    },
    400,
    { leading: true },
  );
  const getWorkflow = debounce(
    (params) => {
      return workObjectsService
        .getWorkflow(params)
        .then((response) => response)
        .catch(() => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: {
              errors: ['An error occured while fetching workflow'],
            },
          });
        });
    },
    400,
    { leading: true },
  );

  useEffect(() => {
    getWorkObject();
    //cleanup state after unmounting
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.INITIAL_STATE,
    });
  }, []);

  useEffect(() => {
    if (
      state.availableResources.tab === 'assets' &&
      state.isResourcesModalOpen
    ) {
      getAssets(sourcesParams);
    }
  }, [
    state.isResourcesModalOpen,
    state.availableResources.tab,
    state.availableResources.sortBy,
    state.availableResources.sortOrder,
    state.availableResources.search,
    state.availableResources.page,
  ]);

  useEffect(() => {
    if (
      state.availableResources.tab === 'sources' &&
      state.isResourcesModalOpen
    ) {
      getSources(sourcesParams);
    }
  }, [
    state.isResourcesModalOpen,
    state.availableResources.tab,
    state.availableResources.sortBy,
    state.availableResources.sortOrder,
    state.availableResources.search,
    state.availableResources.page,
  ]);

  useEffect(() => {
    getWorkflows(workflowsParams);
  }, [
    state.availableWorkflows.sortBy,
    state.availableWorkflows.sortOrder,
    state.availableWorkflows.search,
    state.availableWorkflows.page,
    state.currentResource.type,
  ]);

  useEffect(() => {
    getSources(defaultSourceFilters);
  }, [
    state.availableSources.sortBy,
    state.availableSources.sortOrder,
    state.availableSources.search,
    state.availableSources.page,
  ]);

  useEffect(() => {
    getSources(defaultDestinationFilters);
  }, [
    state.availableDestinations.sortBy,
    state.availableDestinations.sortOrder,
    state.availableDestinations.search,
    state.availableDestinations.page,
  ]);

  useEffect(() => {
    getSources(defaultVendorFilters);
  }, [
    state.availableVendors.sortBy,
    state.availableVendors.sortOrder,
    state.availableVendors.search,
    state.availableVendors.page,
  ]);

  const dispatchInput = (key, payload) =>
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CHANGES_INPUT,
      key,
      payload,
    });

  const userTypesQuantity = (e) => {
    return dispatchInput('quantity', e.target.value || '');
  };

  const userTypesExternalId = (e) => {
    return dispatchInput('externalId', e.target.value || '');
  };

  const userSetsStatus = (option) => {
    return dispatchInput('status', option);
  };

  const userTypesDeadline = (date) => {
    return dispatchInput('deadline', date || '');
  };

  const userAddsCustomField = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_ADDS_CUSTOM_FIELD,
    });
  };

  const userRemovesCustomField = (index) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_REMOVES_CUSTOM_FIELD,
      payload: index,
    });
  };

  const userTypesCustomFieldName = (e, index) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_NAME,
      payload: { value: e.target.value, index },
    });
  };

  const userTypesCustomFieldData = (e, index) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_DATA,
      payload: { value: e.target.value, index },
    });
  };

  const userSetsResource = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_RESOURCE,
    });
  };

  const userSetsLinkedResourcePage = (page) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_RESOURCE_PAGE,
      payload: page,
    });
  };

  const userSetsWorkflow = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_WORKFLOW,
    });
  };
  const userSetsSource = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_SOURCE,
    });
  };
  const userSetsDestination = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_DESTINATION,
    });
  };

  const userSetsVendor = () => {
    // TODO: Is this used? onSubmit on modal?
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_VENDOR,
    });
  };

  const isDestinationDifferent =
    state.originalDestination.value &&
    state.originalDestination.value?.value !== state.destination.value?.value;

  const isSourceDifferent =
    state.originalSource.value &&
    state.originalSource.value.value !== state.source.value?.value;

  const userSwitchesSourceToDefault = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SWITCHES_SOURCE_TO_DEFAULT,
    });
  };

  const userSwitchesDestinationToDefault = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SWITCHES_DESTINATION_TO_DEFAULT,
    });
  };

  const userOpensResourcesModal = (ev) => {
    ev.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL,
    });
  };
  const userOpensWorkflowsModal = (ev) => {
    ev.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_WORKFLOWS_MODAL,
    });
  };

  const userOpensSourcesModal = (ev) => {
    ev.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL,
    });
  };

  const userOpensDestinationsModal = (ev) => {
    ev.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_DESTINATIONS_MODAL,
    });
  };

  const userOpensVendorsModal = (ev) => {
    ev.preventDefault();
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_OPENS_VENDORS_MODAL,
    });
  };

  const userTogglesResource = (resource) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_RESOURCE,
      payload: resource,
    });
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_RESOURCE,
    });
  };

  const userTogglesWorkflow = (workflow) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_WORKFLOW,
      payload: workflow,
    });

    return Promise.all([
      appLoadsWorkflowData({ id: workflow.id }),
      dispatch({
        type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_WORKFLOW,
      }),
    ]);
  };

  const userTogglesSource = (source) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_SOURCE,
      payload: source,
    });
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_SOURCE,
    });
  };

  const userTogglesDestination = (destination) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_DESTINATION,
      payload: destination,
    });
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_DESTINATION,
    });
  };

  const userTogglesVendor = (vendor) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_TOGGLES_VENDOR,
      payload: vendor,
    });
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_VENDOR,
    });
  };

  const userChangesResourcesTab = (tab) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CHANGES_RESOURCES_TAB,
      payload: tab,
    });
  };

  const userCancelsResourcesModal = () => {
    setResourcesOptions({ ...INITIAL_RESOURCES });
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL,
    });
  };
  const userCancelsWorkflowsModal = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_WORKFLOWS_MODAL,
    });
  };

  const userCancelsSourcesModal = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL,
    });
  };

  const userCancelsDestinationsModal = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_DESTINATIONS_MODAL,
    });
  };

  const userCancelsVendorsModal = () => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_CANCELS_VENDORS_MODAL,
    });
  };

  const userSearchesResources = (ev) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_RESOURCES,
      payload: ev.target.value,
    });
  };
  const userSearchesWorkflows = (ev) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_WORKFLOWS,
      payload: ev.target.value,
    });
  };

  const userSearchesSources = (ev) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_SOURCES,
      payload: ev.target.value,
    });
  };

  const userSearchesDestinations = (ev) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_DESTINATIONS,
      payload: ev.target.value,
    });
  };

  const userSearchesVendors = (ev) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SEARCHES_VENDORS,
      payload: ev.target.value,
    });
  };

  const userSortsResources = (col, order) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_RESOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };
  const userSortsWorkflows = (col, order) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_WORKFLOWS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSortsSources = (col, order) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_SOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSetsWorkflowPage = (page) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_WORKFLOW_PAGE,
      payload: page,
    });
  };

  const userSortsDestinations = (col, order) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_DESTINATIONS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userSortsVendors = (col, order) => {
    return dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SORTS_VENDORS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const appLoadsWorkflowData = (filters) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.APP_IS_LOADING_WORKFLOW_DATA,
    });
    return getWorkflow(filters).then((result) =>
      dispatch({
        type: WORK_OBJECTS_FORM_ACTIONS.APP_LOADS_WORKFLOW_DATA,
        payload: result,
      }),
    );
  };

  const userSubmitsForm = () => {
    return dispatch({ type: WORK_OBJECTS_FORM_ACTIONS.USER_SUBMITS_FORM });
  };
  const userSetsSourcesPage = (page) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE,
      payload: page,
    });
  };
  const userSetsDestinationsPage = (page) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_DESTINATIONS_PAGE,
      payload: page,
    });
  };
  const userSetsVendorsPage = (page) => {
    dispatch({
      type: WORK_OBJECTS_FORM_ACTIONS.USER_SETS_VENDORS_PAGE,
      payload: page,
    });
  };
  useEffect(() => {
    if (state.loading) {
      const stateClone = cloneDeep(state);
      workObjectsFormValidator(stateClone)
        .then((data) =>
          workObjectsService
            .saveWorkObject(data)
            .then((response) =>
              props.history.push(`/work-objects/${response.id}`),
            ),
        )
        .catch((e) => {
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: WORK_OBJECTS_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.loading]);

  const isSourceWarningVisible = state.currentResource?.type === 'sources';
  const isWorkflowSingleStep = state.workflow.value?.stepCount === 1;

  return {
    state,
    getSources,
    getAssets,
    getWorkflows,
    getWorkflow,
    isWorkflowSingleStep,
    resourcesOptions,
    workflowsOptions,
    sourcesOptions,
    statusOptions,
    userTypesQuantity,
    userTypesExternalId,
    userSetsStatus,
    userTypesDeadline,
    userAddsCustomField,
    userRemovesCustomField,
    userTypesCustomFieldName,
    userTypesCustomFieldData,
    userSetsResource,
    userSetsLinkedResourcePage,
    userSetsWorkflow,
    userSetsSource,
    userSetsDestination,
    userSetsVendor,
    userSwitchesSourceToDefault,
    userSwitchesDestinationToDefault,
    userOpensResourcesModal,
    userOpensWorkflowsModal,
    userOpensSourcesModal,
    userOpensDestinationsModal,
    userOpensVendorsModal,
    userTogglesResource,
    userTogglesWorkflow,
    userTogglesSource,
    userTogglesDestination,
    userTogglesVendor,
    userChangesResourcesTab,
    userCancelsResourcesModal,
    userCancelsWorkflowsModal,
    userCancelsSourcesModal,
    userSetsSourcesPage,
    userSetsDestinationsPage,
    userSetsVendorsPage,
    userCancelsDestinationsModal,
    userCancelsVendorsModal,
    userSearchesResources,
    userSearchesWorkflows,
    userSearchesSources,
    userSearchesDestinations,
    userSearchesVendors,
    userSortsResources,
    userSortsWorkflows,
    userSortsSources,
    userSortsDestinations,
    userSortsVendors,
    isSourceDifferent,
    isDestinationDifferent,
    userSetsWorkflowPage,
    appLoadsWorkflowData,
    userSubmitsForm,
    isSourceWarningVisible,
    dispatchInput,
  };
};
export default useWorkObjectsForm;
