import { cloneDeep } from 'lodash';
import { ANSWER_TYPE } from 'config/questionOptions';
import makeReducer from 'lib/makeReducer';

const INITIAL_FORM_STATE = {
  inspection: null,
  errors: [],
  apiErrors: false,
  loading: true,
  isSubmitting: false,
  isDirty: false,
};

export const MAX_PHOTO_NUMBER = 4;
export const ALLOWED_MIMETYPES = ['image/png', 'image/jpeg'];
export const MAX_PHOTO_MB = 5;
export const MAX_DOC_MB = 50;
export const MAX_FIELD_CHARS = { summary: 1000 };

export const PERFORM_INSPECTION_ACTIONS = {
  APP_LOADS_INSPECTION: 'APP_LOADS_INSPECTION',
  APP_SUBMITS_INSPECTION: 'APP_SUBMITS_INSPECTION',
  APP_FINISHES_SUBMITTING_INSPECTION: 'APP_FINISHES_SUBMITTING_INSPECTION',
  RESET_STATE: 'RESET_STATE',
  USER_CHANGES_QUESTION_INPUT: 'USER_CHANGES_QUESTION_INPUT',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_UPLOADS_PHOTO: 'USER_UPLOADS_PHOTO',
  USER_REMOVES_PHOTO: 'USER_REMOVES_PHOTO',
  USER_UPLOADS_DOCUMENT: 'USER_UPLOADS_DOCUMENT',
  USER_CHANGES_DOCUMENT_NAME: 'USER_CHANGES_DOCUMENT_NAME',
  USER_REMOVES_DOCUMENT: 'USER_REMOVES_DOCUMENT',
};

function getQuestion(state, groupId, questionId) {
  const newGroups = cloneDeep(state.inspection.questionGroups);

  const gIndex = newGroups.findIndex((g) => g.id === groupId);
  const qIndex = newGroups[gIndex].questions.findIndex(
    (q) => q.id === questionId,
  );
  return [newGroups, newGroups[gIndex].questions[qIndex]];
}

const PERFORM_INSPECTION_REDUCER_CONFIG = {
  [PERFORM_INSPECTION_ACTIONS.APP_LOADS_INSPECTION]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.APP_SUBMITS_INSPECTION]: (state) => {
    return {
      ...state,
      isSubmitting: true,
      isDirty: false,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.APP_FINISHES_SUBMITTING_INSPECTION]: (state) => {
    return {
      ...state,
      isSubmitting: false,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_CHANGES_QUESTION_INPUT]: (state, action) => {
    const { groupId, questionId, field, value, index } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    if (index !== undefined) {
      question[field][index] = {
        ...question[field][index],
        value,
        errors: [],
      };
    } else {
      question[field] = {
        ...question[field],
        value,
        errors: [],
      };
    }

    if (
      field === 'defectiveUnits' &&
      Number(question.defectiveUnits.value) > Number(question.defectCount.value)
    ) {
      question.defectCount.value = question.defectiveUnits.value;
    }

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_UPLOADS_PHOTO]: (state, action) => {
    const { groupId, questionId, files, errors = [] } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    question.photos.value = [...question.photos.value, ...files];
    question.photos.errors = errors;

    if (
      question.photos.value.length &&
      question.answerType === ANSWER_TYPE.DEVICE_IMAGE_CAPTURE
    ) {
      // Image capture questions only have the photo upload input
      // available so we hardcode the actualAnswer to some value
      // to make sure that it won't get mis-labeled as 'Not answered' later
      question.actualAnswer = {
        value: 'Image capture',
        errors: [],
      };
    }

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_REMOVES_PHOTO]: (state, action) => {
    const { groupId, questionId, index } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    question.photos.value.splice(index, 1);
    question.photos.errors = [];

    if (
      !question.photos.value.length &&
      question.answerType === ANSWER_TYPE.DEVICE_IMAGE_CAPTURE
    ) {
      // Image capture questions only have the photo upload input
      // available so we remove the hardcoded actualAnswer
      // to make sure that it won't get mis-labeled as 'Answered' later
      question.actualAnswer = {
        value: '',
        errors: [],
      };
    }

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_UPLOADS_DOCUMENT]: (state, action) => {
    const { groupId, questionId, files = [], errors = [] } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    question.actualDocuments.value = [
      ...question.actualDocuments.value,
      ...Array.from(files).map((f) => ({
        value: f.name.split('.').slice(0, -1).join('.'),
        file: f,
        errors: [],
      })),
    ];
    question.actualDocuments.errors = errors;

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_REMOVES_DOCUMENT]: (state, action) => {
    const { groupId, questionId, index } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    question.actualDocuments.value.splice(index, 1);

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_CHANGES_DOCUMENT_NAME]: (state, action) => {
    const { groupId, questionId, docIdx, value } = action.payload;

    const [newGroups, question] = getQuestion(state, groupId, questionId);

    question.actualDocuments.value[docIdx] = {
      ...question.actualDocuments.value[docIdx],
      value,
    };

    return {
      ...state,
      inspection: {
        ...state.inspection,
        questionGroups: newGroups,
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
  [PERFORM_INSPECTION_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    const { field, value } = action.payload;
    const max = MAX_FIELD_CHARS[field];
    if (max && value.length > max) {
      return state;
    }

    const newInspection = cloneDeep(state.inspection);

    return {
      ...state,
      inspection: {
        ...newInspection,
        [field]: {
          value,
          errors: [],
          charsLeft: max && max - value.length,
        },
      },
      errors: [],
      apiErrors: false,
      isDirty: true,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  PERFORM_INSPECTION_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
