import { RESULT_STATUS } from 'modules/inspection-plans/inspectionPlansBulkResultsReducer';
import StatusPill from 'lib/components/status-pill/StatusPill';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';
import { ReactComponent as CloseCircleOutline } from 'assets/images/circle-delete.svg';
import { ReactComponent as WarningCircleSolid } from 'assets/images/warning-circle-solid.svg';
import { ReactComponent as WarningCircleOutline } from 'assets/images/warning-circle-outline.svg';

import './BulkResultsHeader.scss';

const STATUS_ICON = {
  [RESULT_STATUS.UNKNOWN]: <WarningCircleSolid />,
  [RESULT_STATUS.SUCCESS]: <CheckCircleSolid />,
  [RESULT_STATUS.FAILED]: <CloseCircleOutline />,
  [RESULT_STATUS.ERROR]: <CloseCircleOutline />,
  [RESULT_STATUS.SKIPPED]: <WarningCircleOutline />,
  [RESULT_STATUS.NO_TAGS]: <WarningCircleOutline />,
  [RESULT_STATUS.AUTO_PLAN]: <WarningCircleOutline />,
  [RESULT_STATUS.USER_PLAN]: <WarningCircleOutline />,
  [RESULT_STATUS.WRONG_TYPE]: <WarningCircleOutline />,
};

const BulkResultsHeader = ({ totals }) => (
  <div className='bulk-inspection-plans-results-header'>
    {!!totals[RESULT_STATUS.SUCCESS] && (
      <div className={RESULT_STATUS.SUCCESS}>
        {STATUS_ICON[RESULT_STATUS.SUCCESS]} {totals[RESULT_STATUS.SUCCESS]}
        {` inspection plans created`}
      </div>
    )}
    {(!!totals[RESULT_STATUS.SKIPPED] || !!totals[RESULT_STATUS.FAILED]) && (
      <div className='errors-container'>
        {!!totals[RESULT_STATUS.SKIPPED] && (
          <div className={RESULT_STATUS.SKIPPED}>
            <StatusPill
              variant='warning'
              icon={STATUS_ICON[RESULT_STATUS.SKIPPED]}
            >
              Skipped
            </StatusPill>
            <span> - {totals[RESULT_STATUS.SKIPPED]} plans</span>
          </div>
        )}
        {!!totals[RESULT_STATUS.FAILED] && (
          <div className={RESULT_STATUS.FAILED}>
            <StatusPill
              variant='error'
              icon={STATUS_ICON[RESULT_STATUS.FAILED]}
            >
              Failed
            </StatusPill>
            <span> - {totals[RESULT_STATUS.FAILED]} plans</span>
          </div>
        )}
      </div>
    )}
  </div>
);

export default BulkResultsHeader;
