import INSPECTION_STATUS, {
  CREATABLE_STATUS_LIST,
  getInspectionStatusLabel,
} from 'config/inspectionStatus';
import { useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';

import useQueryParams from 'lib/useQueryParams';
import { entityToSelectOption, reduceLocation } from 'lib/dataTransform';
import useNavigationPrompt from 'lib/useNavigationPrompt';

import Avatar from 'lib/components/avatar/Avatar';

import {
  reducer,
  initialState,
  INSPECTIONS_FORM_ACTIONS,
} from './inspectionsFormReducer';
import inspectionsService from './inspectionsService';
import {
  resourceToFormState,
  inspectionToFormState,
  workObjectAtachedToFormState,
} from './dataTransform';
import inspectionsFormValidator from './inspectionsFormValidator';

const getAvailableAssets = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getAssets(params)
      .then((response) => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_ASSETS,
          payload: {
            data: response.data.map((asset) =>
              resourceToFormState(asset, 'asset'),
            ),
            assetsCount: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching assets'],
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);
const getAvailableSources = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getSources(params)
      .then((response) => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_SOURCES,
          payload: {
            data: response.data.map((source) =>
              resourceToFormState(source, 'source'),
            ),
            sourcesCount: response.count,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching sources'],
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getAvailablePlans = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getInspectionPlans(params)
      .then((response) => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_PLANS,
          payload: { data: response.data, count: response.count },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching inspection plans'],
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getAvailableInspectors = debounce(
  (params, dispatch) => {
    return inspectionsService
      .getInspectors(params)
      .then((response) => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_INSPECTORS,
          payload: { data: response.data, count: response.count },
        });
      })
      .catch(() => {
        dispatch({
          type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
          payload: {
            errors: ['An error occured while fetching inspectors'],
          },
        });
      });
  },
  400,
  { leading: true, trailing: true },
);

const getInspectionTypes = debounce((params, dispatch) => {
  return inspectionsService
    .getInspectionTypes(params)
    .then((response) => {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_TYPES,
        payload: response.data.map(entityToSelectOption('name', 'id')),
      });
    })
    .catch(() => {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching inspection types'],
        },
      });
    });
}, 400);

const getWorkObject = debounce((params, dispatch) => {
  return inspectionsService
    .getWorkObject(params.id)
    .then((response) => {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_WORK_OBJECT_ATTACHED_DATA,
        payload: workObjectAtachedToFormState(
          response,
          params.partialId,
          params.stepId,
        ),
      });
    })
    .catch(() => {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
        payload: {
          errors: ['An error occured while fetching work objects'],
        },
      });
    });
}, 400);

const useInspectionsForm = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getParams } = useQueryParams(props.location, props.history);

  useNavigationPrompt(state.isDirty);

  const getInspection = (dispatch) => {
    const inspection = props.inspection;
    const id = props.match?.params?.id;
    if (inspection) {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_INSPECTION,
        payload: inspectionToFormState(inspection),
      });
    } else if (id) {
      return inspectionsService
        .getInspection(id)
        .then((results) => {
          dispatch({
            type: INSPECTIONS_FORM_ACTIONS.APP_LOADS_INSPECTION,
            payload: inspectionToFormState(results),
          });
        })
        .catch((e) => {
          if (e.response?.status === 403) {
            props.history.push(`/inspections`);
          } else {
            dispatch({
              type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
              payload: e.payload,
            });
          }
        });
    }
  };

  const userChangesWindow = (window) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CHANGES_WINDOW,
      payload: window,
    });
  };

  const userClearsWindow = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CHANGES_WINDOW,
      payload: ['', ''],
    });
  };

  const userChangesDate = (date) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CHANGES_DATE,
      payload: date,
    });
  };

  const userChangesStatus = (value) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CHANGES_STATUS,
      payload: value,
    });
  };

  /* Linkable Resources */
  const getLinkableResourcesData = () => ({
    assets: state.linkableResources.assets,
    assetsCount: state.linkableResources.assetsCount,
    sources: state.linkableResources.sources,
    sourcesCount: state.linkableResources.sourcesCount,
  });

  const getResourceSelectButtonProps = () => {
    if (state.linkedResource.value === null) {
      return {};
    }
    if (state.linkedResource.value.resourceType === 'asset') {
      return {
        title: state.linkedResource.value.name,
        thumbUrl: state.linkedResource.value.images?.[0]?.url,
      };
    } else {
      return {
        title: state.linkedResource.value.name,
        subtitle: reduceLocation(
          state.linkedResource.value.location,
          'city',
          'country',
        ),
      };
    }
  };

  const userClicksResourceSelect = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL,
    });
  };

  const userCancelsResourcesModal = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL,
    });
  };

  const userSearchesResources = (ev) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_RESOURCES,
      payload: ev.target.value,
    });
  };

  const userSelectsResource = (resource) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_RESOURCE,
      payload: resource,
    });
  };

  const userSortsResources = (col, order) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SORTS_RESOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const userChangesResourcesModalTab = (tab) => {
    return dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CHANGES_RESOURCES_MODAL_TAB,
      payload: tab,
    });
  };

  const userSetsResourcesPage = (page) => {
    return dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SETS_RESOURCES_PAGE,
      payload: page,
    });
  };

  const availableResourcesParams = {
    search: state.linkableResources.search,
    order: {
      [state.linkableResources.sortBy]: state.linkableResources.sortOrder,
    },
    pageSize: state.linkableResources.pageSize,
    page: state.linkableResources.page,
  };
  useEffect(() => {
    if (state.linkableResources.isModalOpen) {
      state.linkableResources.tab === 'assets'
        ? getAvailableAssets(availableResourcesParams, dispatch)
        : getAvailableSources(availableResourcesParams, dispatch);
    }
  }, [
    state.linkableResources.search,
    state.linkableResources.sortBy,
    state.linkableResources.sortOrder,
    state.linkableResources.isModalOpen,
    state.linkableResources.tab,
    state.linkableResources.page,
  ]);
  /* End Linkable Resources */

  /* Source */
  const getSourceSelectButtonProps = () =>
    state.source.value
      ? {
          title: state.source.value.name,
          subtitle: reduceLocation(
            state.source.value.location,
            'city',
            'country',
          ),
        }
      : {};

  const userClicksSourceSelect = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL,
    });
  };

  const userCancelsSourcesModal = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL,
    });
  };

  const userSearchesSources = (ev) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_SOURCES,
      payload: ev.target.value,
    });
  };

  const userSelectsSource = (source) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_SOURCE,
      payload: source,
    });
  };

  const userSortsSources = (col, order) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SORTS_SOURCES,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };

  const availableSourcesParams = {
    search: state.availableSources.search,
    sortBy: state.availableSources.sortBy,
    sortOrder: state.availableSources.sortOrder,
    page: state.availableSources.page,
    pageSize: state.availableSources.pageSize,
  };

  useEffect(() => {
    if (state.availableSources.isModalOpen) {
      getAvailableSources(availableSourcesParams, dispatch);
    }
  }, [
    state.availableSources.search,
    state.availableSources.sortBy,
    state.availableSources.sortOrder,
    state.availableSources.isModalOpen,
    state.availableSources.page,
  ]);
  /* End Source */

  /* Inspection Type */
  const userSelectsInspectionType = (type) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_INSPECTION_TYPE,
      payload: type,
    });
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_PLAN,
      payload: null,
    });
  };

  useEffect(() => {
    getInspection(dispatch);
    if (getParams()?.workObjectId) {
      const { workObjectId, partialId, stepId } = getParams();
      getWorkObject({ id: workObjectId, partialId, stepId }, dispatch);
    }

    return () => {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
      });
    };
  }, []);

  useEffect(() => {
    if (state?.linkedResource?.value?.resourceType) {
      getInspectionTypes(
        { filters: { type: state?.linkedResource?.value?.resourceType } },
        dispatch,
      );
    }
  }, [state?.linkedResource?.value?.resourceType]);
  /* End Inspection Type */

  /* Inspection Plans */
  const getPlanSelectButtonProps = () => ({
    title: state.plan.value?.name,
    subtitle: state.plan.value && `ID: ${state.plan.value.id}`,
  });
  const userClicksPlanSelect = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_OPENS_PLANS_MODAL,
    });
  };
  const userCancelsPlansModal = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CANCELS_PLANS_MODAL,
    });
  };
  const userSearchesPlans = (ev) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_PLANS,
      payload: ev.target.value,
    });
  };
  const userSelectsPlan = (plan) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_PLAN,
      payload: plan,
    });
  };
  const userSortsPlans = (col, order) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SORTS_PLANS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };
  const userSetsPlansPage = (page) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SETS_PLANS_PAGE,
      payload: page,
    });
  };

  const availablePlansParams = {
    search: state.availablePlans.search,
    order: {
      [state.availablePlans.sortBy]: state.availablePlans.sortOrder,
    },
    filters: {
      inspectionType: Number(state.type.value?.value),
      status: 'published',
    },
    page: state.availablePlans.page,
    pageSize: state.availablePlans.pageSize,
  };
  useEffect(() => {
    if (state.availablePlans.isModalOpen) {
      getAvailablePlans(availablePlansParams, dispatch);
    }
  }, [
    state.availablePlans.search,
    state.availablePlans.page,
    state.availablePlans.sortBy,
    state.availablePlans.sortOrder,
    state.availablePlans.isModalOpen,
  ]);
  /* End Inspection Plans */

  /* Inspectors */
  const getInspectorSelectButtonProps = () => ({
    title: state.inspector.value?.name,
    subtitle: state.inspector.value?.companyName,
    thumbnail: state.inspector.value && (
      <Avatar
        url={state.inspector.value?.profilePhoto?.url}
        placeholder={state.inspector.value?.name}
      />
    ),
  });
  const userClicksInspectorSelect = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_OPENS_INSPECTORS_MODAL,
    });
  };
  const userCancelsInspectorsModal = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CANCELS_INSPECTORS_MODAL,
    });
  };
  const userSearchesInspectors = (ev) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SEARCHES_INSPECTORS,
      payload: ev.target.value,
    });
  };
  const userSelectsInspector = (inspector) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SELECTS_INSPECTOR,
      payload: inspector,
    });
  };
  const userSortsInspectors = (col, order) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SORTS_INSPECTORS,
      payload: {
        sortBy: col,
        sortOrder: order,
      },
    });
  };
  const userSetsInspectorsPage = (page) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SETS_INSPECTORS_PAGE,
      payload: page,
    });
  };
  const userSetsSourcesPage = (page) => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE,
      payload: page,
    });
  };

  const sourceId =
    state.linkedResource.value?.resourceType === 'source'
      ? state.linkedResource.value.id
      : state.source.value?.id;

  const availableInspectorsParams = {
    select: ['name', 'companyName', 'city', 'country', 'numLinkedSources'],
    search: state.availableInspectors.search,
    filters: {
      sourceIds: [sourceId],
      status: 'active',
    },
    order: {
      [state.availableInspectors.sortBy]: state.availableInspectors.sortOrder,
    },
    page: state.availableInspectors.page,
    pageSize: state.availableInspectors.pageSize,
  };

  useEffect(() => {
    // Reloads inspectors' list either in the selection modal or
    // for automatic inspector suggestion
    if (
      state.availableInspectors.isModalOpen ||
      (sourceId && !state.inspector?.value?.id)
    ) {
      getAvailableInspectors(availableInspectorsParams, dispatch);
    }
  }, [
    state.availableInspectors.page,
    state.availableInspectors.search,
    state.availableInspectors.sortBy,
    state.availableInspectors.sortOrder,
    state.availableInspectors.isModalOpen,
    sourceId,
    state.inspector?.value?.id,
  ]);

  useEffect(() => {
    // Automatically set inspector on new inspections
    // if there is a single inspector available for that source
    if (
      state.inspectorAutoset.shouldAutoset &&
      sourceId &&
      !state.id &&
      !state.inspector?.value?.id &&
      state.availableInspectors?.list?.length === 1
    ) {
      dispatch({
        type: INSPECTIONS_FORM_ACTIONS.APP_AUTOSETS_INSPECTOR,
        payload: state.availableInspectors?.list[0],
      });
    }
  }, [
    sourceId,
    JSON.stringify(state.availableInspectors?.list),
    state.id,
    state.inspector?.value?.id,
    state.inspectorAutoset.shouldAutoset,
  ]);
  /* End Inspectors */

  const userSubmitsForm = () => {
    return dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_SUBMITS_FORM,
    });
  };

  useEffect(() => {
    if (state.loading) {
      const { partialId, stepId, order } = getParams();
      const request = partialId ? 'saveAttachedInspection' : 'saveInspection';

      inspectionsFormValidator(state)
        .then((data) =>
          inspectionsService[request](
            partialId ? { inspection: data, partialId, stepId, order } : data,
          ).then((response) => {
            return partialId
              ? props.history.goBack()
              : props.history.push(`/inspections/${response.id}`);
          }),
        )
        .catch((e) => {
          dispatch({
            type: INSPECTIONS_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: INSPECTIONS_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.loading]);

  const userClearsInspector = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CLEARS_INSPECTOR,
    });
  };

  const userClearsDate = () => {
    dispatch({
      type: INSPECTIONS_FORM_ACTIONS.USER_CLEARS_DATE,
    });
  };

  const isResourceSelectDisabled =
    state.isAttachedInspection || !!state.workObjectId;

  /** When adding an inspection to a work object, an actual inspection entity
   * is not immediately created and so some fields are unavailable.
   */
  const isScheduledInspection = state.isAttachedInspection && !state.id;

  const isInspectorSelectDisabled = isScheduledInspection || !sourceId;
  const isDateSelectDisabled = isScheduledInspection;

  const inspectionStatusOptions = [
    ...CREATABLE_STATUS_LIST,
    ...(state.initialStatus === INSPECTION_STATUS.NEEDS_REVIEW
      ? [INSPECTION_STATUS.NEEDS_REVIEW]
      : []),
    ...(state.initialStatus === INSPECTION_STATUS.ON_HOLD
      ? [INSPECTION_STATUS.ON_HOLD]
      : []),
  ].map((status) => ({
    label: getInspectionStatusLabel(status),
    value: status,
  }));

  return {
    state,
    dispatch,
    isResourceSelectDisabled,
    isScheduledInspection,
    isInspectorSelectDisabled,
    isDateSelectDisabled,

    userSubmitsForm,

    inspectionStatusOptions,
    userChangesStatus,

    userChangesWindow,
    userChangesDate,
    userSelectsInspectionType,

    getLinkableResourcesData,
    getResourceSelectButtonProps,
    userClicksResourceSelect,
    userCancelsResourcesModal,
    userSearchesResources,
    userSelectsResource,
    userSortsResources,
    userChangesResourcesModalTab,
    userSetsResourcesPage,
    userSetsInspectorsPage,

    getSourceSelectButtonProps,
    userClicksSourceSelect,
    userCancelsSourcesModal,
    userSearchesSources,
    userSelectsSource,
    userSortsSources,
    userSetsSourcesPage,

    getPlanSelectButtonProps,
    userClicksPlanSelect,
    userCancelsPlansModal,
    userSearchesPlans,
    userSelectsPlan,
    userSortsPlans,
    userSetsPlansPage,

    getInspectorSelectButtonProps,
    userClicksInspectorSelect,
    userCancelsInspectorsModal,
    userSearchesInspectors,
    userSelectsInspector,
    userSortsInspectors,

    userClearsInspector,
    userClearsWindow,
    userClearsDate,
  };
};

export default useInspectionsForm;
