import { useEffect, useState } from 'react';
import request from 'lib/request';

let cache;
let isBusy = false;
let onHoldRequests = [];

function setBusy(value, err) {
  isBusy = value;
  if (isBusy) {
    return;
  }

  if (cache) {
    onHoldRequests.forEach((req) => req.resolve(cache));
  } else {
    onHoldRequests.forEach((req) => req.reject(err));
  }

  onHoldRequests = [];
}

function uomToFormData(data) {
  if (Array.isArray(data)) {
    return data.map((uom) => ({ label: uom?.uom, value: uom?.uom }));
  }
  return [];
}

function getUOMs() {
  return new Promise((resolve, reject) => {
    if (isBusy) {
      onHoldRequests.push({ resolve, reject });
    } else {
      setBusy(true);
      request
        .get('/settings/uoms')
        .then((data) => {
          cache = uomToFormData(data);
          resolve(cache);
          setBusy(false);
        })
        .catch((err) => {
          reject(err);
          setBusy(false, err);
        });
    }
  });
}

export default function useUOMs() {
  const [uoms, setUOMs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getUOMs()
      .then((data) => {
        setUOMs(data);
      })
      .catch((error) => {
        setUOMs([]);
        setError(error);
      });
  }, []);

  return { uoms, error };
}
