import { DEFAULT_LANGUAGE, LANGUAGES, LANGUAGE_LABELS } from 'config/languages';

import Button from 'lib/components/button/Button';
import classNames from 'classnames';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import EditButton from 'lib/components/edit-button/EditButton';
import IconButton from 'lib/components/icon-button/IconButton';
import InfoBox, { INFO_BOX_VARIANT } from 'lib/components/info-box/InfoBox';
import InspectionPlanStatusPill from 'lib/components/inspection-plans-status-pill/InspectionPlansStatusPill';
import NotesModal from 'lib/components/notes-modal/NotesModal';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import QuestionGroup from 'lib/components/question-group/QuestionGroup';
import Tooltip from 'lib/components/tooltip/Tooltip';
import WarningPill from 'lib/components/warning-pill/WarningPill';
import { formatQuestionOrder } from 'lib/components/question-detail/dataTransform';

import {
  getIsGroupedQuestionForPlanOnly,
  getIsGroupedQuestionOverridden,
  getIsQuestionGroupOverridden,
} from '../../dataTransform';
import useInspectionPlansDetail from '../../useInspectionPlansDetail';
import {
  ADD_PLANS_ROLES,
  REVIEW_PLANS_ROLES,
} from '../../inspectionPlansPermissions';

import { ReactComponent as LinkBrokenOutline } from 'assets/images/link-broken-outline.svg';
import { ReactComponent as PrintOutline } from 'assets/images/print-outline.svg';
import { ReactComponent as NotesOutline } from 'assets/images/notes-outline.svg';

import './InspectionPlansDetailPage.scss';

const InspectionPlansDetailPage = (props) => {
  const {
    state,
    isNeedsReviewVisible,

    userClicksOnEdit,
    userClicksOnPrint,

    userClicksOnNeedsReview,
    userSubmitsNotesModal,
    userCancelsNotesModal,
    userTypesReviewNotes,
  } = useInspectionPlansDetail(props);

  const { loading, errors, plan, notesModal } = state;

  const {
    validityValue,
    validityRange,
    criticalDefectValue,
    criticalDefectRule,
  } = plan;

  if (loading) {
    return null;
  }

  if (errors.length) {
    return null;
  }

  return (
    <div
      className={
        classNames(
          'inspection-plans-detail-page',
          { 'is-vendor-survey': plan?.inspectionType?.isSurvey }
        )
      }
    >
      <div className='plan-header container-m row'>
        <div className='details'>
          <h1 className='name' data-testid='InspectionPlansDetailPage.name'>
            {plan.name}
          </h1>
          <div className='info'>
            <span className='id' data-testid='InspectionPlansDetailPage.id'>
              ID: {plan.id}
            </span>
            <InspectionPlanStatusPill
              data-testid='InspectionPlansDetailPage.status'
              status={plan.status}
            />
          </div>
        </div>
        <div className='actions'>
          {isNeedsReviewVisible && (
            <ProtectedComponent allowed={REVIEW_PLANS_ROLES}>
              <Button
                data-testid='InspectionPlansDetailPage.needsReview'
                onClick={userClicksOnNeedsReview}
              >
                Needs review
              </Button>
            </ProtectedComponent>
          )}
          <ProtectedComponent allowed={ADD_PLANS_ROLES}>
            <EditButton
              data-testid='InspectionPlansDetailPage.edit'
              onClick={userClicksOnEdit}
            >
              Edit
            </EditButton>
          </ProtectedComponent>
          <DropdownMenu
            items={LANGUAGES.map((lang) => (
              <MenuItem
                label={LANGUAGE_LABELS[lang]}
                onClick={() => userClicksOnPrint(lang)}
              />
            ))}
          >
            <IconButton
              data-testid='InspectionsResultPage.printButton'
              prefix={<PrintOutline />}
            >
              Print
            </IconButton>
          </DropdownMenu>
        </div>
      </div>
      {plan.notes && (
        <div className='plan-notes container-m'>
          <Tooltip overlay='Review notes'>
            <InfoBox
              data-testid='InspectionPlansDetailPage.notes'
              icon={<NotesOutline />}
              variant={INFO_BOX_VARIANT.INFO}
            >
              {plan.notes}
            </InfoBox>
          </Tooltip>
        </div>
      )}
      <div className='plan-description container-m'>
        <p data-testid='InspectionPlansDetailPage.description'>
          {plan.description}
        </p>
      </div>
      <div className='plan-details container-m'>
        {validityValue && validityRange ? (
          <div className='attribute plan-validity'>
            <div className='label'>Validity</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.validity'
            >
              {plan.validityValue} {plan.validityRange}
            </div>
          </div>
        ) : null}
        {plan.aqlLevel ? (
          <div className='attribute plan-aql-level'>
            <div className='label'>AQL Level</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.aqlLevel'
            >
              {plan.aqlLevel.label}
            </div>
          </div>
        ) : null}
        {plan.aqlLevel && plan.aqlMajor ? (
          <div className='attribute plan-aql-major'>
            <div className='label'>Major defects</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.aqlMajor'
            >
              {plan.aqlMajor.label}
            </div>
          </div>
        ) : null}
        {plan.aqlLevel && plan.aqlMinor ? (
          <div className='attribute plan-aql-minor'>
            <div className='label'>Minor defects</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.aqlMinor'
            >
              {plan.aqlMinor.label}
            </div>
          </div>
        ) : null}
        {plan.aqlLevel && plan.aqlFunctional ? (
          <div className='attribute plan-aql-functional'>
            <div className='label'>Functional defects</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.aqlFunctional'
            >
              {plan.aqlFunctional.label}
            </div>
          </div>
        ) : null}
        {plan.aqlLevel && criticalDefectValue !== null && criticalDefectRule ? (
          <div className='attribute plan-aql-critical'>
            <div className='label'>Critical defects</div>
            <div
              className='value'
              data-testid='InspectionPlansDetailPage.criticalDefects'
            >
              {criticalDefectValue}
              {criticalDefectRule === '%' ? '%' : ''} allowed
            </div>
          </div>
        ) : null}
      </div>
      {(plan.questionGroups || []).length > 0 && (
        <div
          className='question-groups container-m'
          data-testid='InspectionPlansDetailPage.questionGroupContainer'
        >
          <h2 className='questions-heading'>Questions</h2>
          {(plan.questionGroups || [])
            .sort((a, b) => a.order - b.order)
            .map((group, gIndex) => (
              <QuestionGroup
                showHeaderDependency={true}
                key={gIndex}
                data={group}
                editable={false}
                title={
                  <>
                    {group.name}
                    {getIsQuestionGroupOverridden(group) && (
                      <WarningPill
                        className='group-unlinked-icon'
                        data-testid='InspectionPlansDetailPage.unlinkedIcon'
                        icon={<LinkBrokenOutline />}
                        overlay='Question group unlinked from settings'
                      />
                    )}
                  </>
                }
              >
                {group.questions
                  .sort((a, b) => a.order - b.order)
                  .map((question, qIndex) => {
                    const warningIcon = getIsGroupedQuestionOverridden(
                      question,
                    ) ? (
                      <WarningPill
                        className='broken-link-question'
                        icon={<LinkBrokenOutline />}
                        overlay='Question unlinked from settings'
                      />
                    ) : getIsGroupedQuestionForPlanOnly(question) ? (
                      <WarningPill
                        className='broken-link-question'
                        icon={<LinkBrokenOutline />}
                        overlay='Question is for this plan only'
                      />
                    ) : null;

                    const formattedId = (
                      <>
                        {formatQuestionOrder(question)}
                        {warningIcon}
                      </>
                    );

                    return (
                      <QuestionGroup.Question
                        key={`${gIndex}-${qIndex}`}
                        data={question}
                        id={formattedId}
                        language={DEFAULT_LANGUAGE}
                        showHeaderDependency={true}
                      />
                    );
                  })}
              </QuestionGroup>
            ))}
        </div>
      )}
      <NotesModal
        isOpen={notesModal.isModalOpen}
        value={notesModal.notes}
        errors={notesModal.errors}
        charsLeft={notesModal.charsLeft}
        onCancel={userCancelsNotesModal}
        onSubmit={userSubmitsNotesModal}
        onChange={userTypesReviewNotes}
        className='inspections-plans-detail-page--review-notes-modal'
        description='Please provide some information about the problem.'
      />
    </div>
  );
};

export default InspectionPlansDetailPage;
