import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ExcelIcon from 'assets/images/excel-blue.svg';
import './CsvImport.scss';

function StateSelect({ onUploadSelected }) {
  const [isDragOver, setIsDragOver] = useState(false);

  function filesDrop(event) {
    event.preventDefault();
    onUploadSelected(event.dataTransfer.files);
  }

  function dragOver(event) {
    event.preventDefault();
    setIsDragOver(true);
  }

  function dragLeave() {
    setIsDragOver(false);
  }

  function onInputSelect(e) {
    onUploadSelected(e.target.files);
  }

  return (
    <div
      className={classnames('state-select', { dragging: isDragOver })}
      onDrop={filesDrop}
      onDragOver={dragOver}
      onDragLeave={dragLeave}
    >
      <form>
        <div className='iconWrapper'>
          <img src={ExcelIcon} alt='csv' />
        </div>
        <div>Drag &amp; drop CSV here to upload or</div>
        <input
          type='file'
          name='files[]'
          id='file'
          onChange={onInputSelect}
          accept='.csv'
        />
        <label htmlFor='file'>
          <div className='browse'>Browse files</div>
        </label>
      </form>
    </div>
  );
}

StateSelect.propTypes = {
  onUploadSelected: PropTypes.func.isRequired,
};

export default StateSelect;
