import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import CompaniesAddPage from './components/companies-add-page/CompaniesAddPage';
import CompaniesEditPage from './components/companies-edit-page/CompaniesEditPage';
import CompaniesListPage from './components/companies-list-page/CompaniesListPage';
import CompaniesDetailPage from './components/companies-detail-page/CompanyDetailPage';

import {
  ADD_COMPANIES_ROLES,
  VIEW_ALL_COMPANIES_ROLES,
} from './companiesPermissions';

export default function CompaniesRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_ALL_COMPANIES_ROLES}
        path='/companies'
        exact
        render={(props) => <CompaniesListPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_COMPANIES_ROLES}
        path='/companies/:id(\d+)'
        exact
        render={(props) => <CompaniesDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_COMPANIES_ROLES}
        path='/companies/add'
        exact
        render={(props) => <CompaniesAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_COMPANIES_ROLES}
        path='/companies/:id(\d+)/edit'
        exact
        render={(props) => <CompaniesEditPage {...props} />}
      />
    </>
  );
}
