import makeLogger from 'lib/makeLogger';
import request from 'lib/request';

const log = makeLogger('systemSettingsService');

const getMaintenanceMode = () => {
  log('Attempting to fetch Maintenance Mode Status');

  return request
    .get('/global-configs/maintenance_mode')
    .then((response) => {
      log('Maintenance Mode Status successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the Maintenance Mode Status for the required params',
        e,
      );
      return Promise.reject(e);
    });
};

const setMaintenanceMode = ({ isEnabled }) => {
  log('Attempting to set Maintenance Mode.');

  return request.put('/global-configs/maintenance-mode', {
    isEnabled: !!isEnabled
  })
    .then((response) => {
      log('Successfully set Maintenance Mode.', response);
      return response;
    })
    .catch((e) => {
      log('There was an error setting Maintenance Mode.', e);
      return Promise.reject({
        payload: { errors: ['There was an error in uploading the photo'] },
      });
    });
};

const systemSettingsService = {
  getMaintenanceMode,
  setMaintenanceMode,
};

export default systemSettingsService;
