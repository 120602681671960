import classNames from 'classnames';

import { reduceLocation } from 'lib/dataTransform';

import DatePicker from 'lib/components/date-picker/DatePicker';
import Modal from 'lib/components/modal/Modal';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import Pagination from 'lib/components/pagination/Pagination';
import SearchInput from 'lib/components/search-input/SearchInput';
import Table from 'lib/components/table/Table';

import { ReactComponent as StarIcon } from 'assets/images/star.svg';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';

import './InspectorsModal.scss';

const inspectorsTableColumns = [
  {
    title: 'Name / Company',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <>
        <CheckCircleSolid className='check-selected' />
        <NamedAvatar user={record} />
        {record?.numLinkedSources === 1 && (
          <div className='inspector-starred-icon'>
            <StarIcon />
          </div>
        )}
      </>
    ),
  },
  {
    title: 'Location',
    sortId: 'fullAddress',
    render: (record) => reduceLocation(record, 'city', 'country'),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const InspectorsModal = ({
  isOpen,
  search,
  onSearchChange,
  inspectionDate,
  onDateChange,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  className,
  rowClassName,
  sortBy,
  setPage,
  page,
  pageSize,
  count,
  emptyState,
  isLoading,
  ...props
}) => {
  const hasPagination = setPage && pageSize && page && count;
  return (
    <Modal
      title='Select inspector'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name, location or ID...'
            data-testid='InspectorsModal.search'
          />
          {inspectionDate !== undefined && onDateChange && (
            <span className='inspection-date-container'>
              <span>Inspection date:</span>
              <DatePicker
                data-testid='InspectorsModal.inspectionDate'
                onChange={onDateChange}
                value={inspectionDate}
              />
            </span>
          )}
        </div>
      }
      actions={
        !!hasPagination && (
          <Pagination
            setPage={setPage}
            page={page}
            pageSize={pageSize}
            count={count}
            threshold={3}
          />
        )
      }
      className={classNames('inspectors-modal', className)}
      isOpen={isOpen}
      ariaHideApp={false}
      data-testid='InspectorsModal.dialog'
      {...props}
    >
      <Table
        data={data}
        columns={inspectorsTableColumns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
        rowClassName={rowClassName}
        emptyState={emptyState}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default InspectorsModal;
