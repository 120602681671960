import React, { useContext, useEffect, useState } from 'react';

import { useUnits, UOM_TYPES } from 'lib/unitsService';

import MeasurementTableForm from './MeasurementTableForm';
import { uploadCSV } from './editAssetServices';
import CustomFieldInput from './CustomFieldInput';
import {
  MeasurementContext,
  MEASUREMENT_ACTIONS,
} from './MeasurementTableContext';

import { ReactComponent as RemoveIcon } from 'assets/images/remove.svg';
import './MeasureTable.scss';

function MeasureTable() {
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [customMeasures, setCustomMeasures] = useState([]);
  const [error, setError] = useState(null);
  const [data, dispatch] = useContext(MeasurementContext);
  const lengthUoms = useUnits(UOM_TYPES.LENGTH);
  const weightUoms = useUnits(UOM_TYPES.WEIGHT);
  const volumeUoms = useUnits(UOM_TYPES.VOLUME);

  useEffect(() => {
    const { columnNames } = data || {};
    setColumns(
      columnNames?.map((name) => {
        switch (name?.toUpperCase()) {
          case 'UNIT':
            return {
              name,
              type: 'select',
              options: lengthUoms,
            };
          case 'W UNIT':
            return {
              name,
              type: 'select',
              options: weightUoms,
            };
          case 'V UNIT':
            return {
              name,
              type: 'select',
              options: volumeUoms,
            };
          case 'UOM':
          case 'EAN/UPC':
          case 'EAN CATEGORY':
            return {
              name,
              type: 'text',
            };
          default:
            return {
              name,
              type: 'text',
              validation: /^[\d.]*$/,
            };
        }
      }) || [],
    );
  }, [data.columnNames, lengthUoms, weightUoms, volumeUoms]);

  useEffect(() => {
    setCustomMeasures(data.customMeasurements);
  }, [data.customMeasurements]);

  useEffect(() => {
    const { columnValues } = data || {};
    const rowsData = [];
    if (columns && columnValues) {
      columns.forEach((column, columnIndex) => {
        const { name } = column;
        // parse table data for this row
        if (columnValues.hasOwnProperty(name)) {
          const property = columnValues[name];
          if (Array.isArray(property)) {
            while (rowsData.length < property.length) {
              rowsData.push([]);
            }
            property.forEach(
              (prop, propIndex) => (rowsData[propIndex][columnIndex] = prop),
            );
          }
        }
      });
    }
    setTableData(rowsData);
  }, [data.columnValues, columns]);

  function onInputSelect(e) {
    if (e.target.files && e.target.files.length > 0) {
      setError(null);
      uploadCSV(e.target.files[0]).then((res) => {
        if (res) {
          if (res.measureTable) {
            dispatch({
              type: MEASUREMENT_ACTIONS.SET_DATA,
              data: {
                ...data,
                columnNames: res.measureTable?.columnNames ?? [],
                columnValues: res.measureTable?.columnValues ?? {},
              },
            });
          }
          if (res.errors) {
            setError(
              'File import failed, please refer to the sample measure table for csv structure.',
            );
          }
        } else {
          setError(
            'File import failed, please refer to the sample measure table for csv structure.',
          );
        }
      });
    }
  }

  function deleteCustomMeasure(index) {
    if (customMeasures && customMeasures.length > index) {
      const newMeasurements = [...customMeasures];
      newMeasurements.splice(index, 1);
      dispatch({
        type: MEASUREMENT_ACTIONS.SET_CUSTOM_MEASUREMENT,
        data: newMeasurements,
      });
    }
  }

  function addCustomMeasure() {
    const measurement = {
      measure: '',
      value: '',
    };
    dispatch({
      type: MEASUREMENT_ACTIONS.SET_CUSTOM_MEASUREMENT,
      data: customMeasures ? [...customMeasures, measurement] : [measurement],
    });
  }

  function onCustomChanged(index, data) {
    if (customMeasures && customMeasures.length > index) {
      const newMeasurements = [...customMeasures];
      newMeasurements[index] = data;
      dispatch({
        type: MEASUREMENT_ACTIONS.SET_CUSTOM_MEASUREMENT,
        data: newMeasurements,
      });
    }
  }

  return (
    <div className='measure-table'>
      <h2>Measure Table</h2>
      <p className='description'>
        For importing the measure table please refer to the&nbsp;
        <a href='documents/measurement-table.csv' target='_blank' download>
          sample measure table document
        </a>
      </p>
      <input
        type='file'
        name='files[]'
        id='file'
        onChange={onInputSelect}
        accept='.csv'
      />
      <label htmlFor='file'>
        <div className='qm-button'>Import measure table</div>
      </label>
      {error && (
        <div className='error'>
          <RemoveIcon />
          <p>{error}</p>
        </div>
      )}
      <div className='table-container'>
        <MeasurementTableForm columns={columns} data={tableData} />
      </div>
      <h2>Custom measures</h2>
      <p className='description'>
        Custom measures are used to match with quantitative input questions and
        assemble inspection plans automatically.
      </p>
      {customMeasures && (
        <div className='custom-container'>
          {customMeasures.map((field, index) => (
            <CustomFieldInput
              key={index}
              index={index}
              data={field}
              onChange={onCustomChanged}
              onDelete={deleteCustomMeasure}
            />
          ))}
        </div>
      )}
      <div className='qm-button' onClick={addCustomMeasure}>
        Add measure
      </div>
    </div>
  );
}

export default MeasureTable;
