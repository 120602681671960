import makeLogger from 'lib/makeLogger';
import request from 'lib/request';
import INSPECTION_TYPE_STATUS from 'config/inspectionTypeStatus';
import { workflowStateToPOSTParams } from './dataTransform';
import { processAPIError } from './workflowsFormValidator';
import { SORT_ORDER } from 'lib/list-helper/makeListReducer';

const log = makeLogger('workflowsService');

const defaultWorkflowsListParams = {
  page: 1,
  pageSize: 10,
  select: [
    'name',
    'description',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'workObjectsDone',
    'externalId',
  ],
};
const defaultRelatedWorkObjectsParams = {
  page: 1,
  pageSize: 5,
  order: { lastActivity: SORT_ORDER.DESC },
  select: [
    'id',
    'asset',
    'source',
    'quantity',
    'defaultSource',
    'defaultDestination',
    'defaultSourceExternalId',
    'defaultDestinationExternalId',
    'sourceLocation',
    'destinationLocation',
    'progress',
    'hasMissingInspectionPlan',
    'lastActivity',
    'workObject.externalId',
  ],
  relations: ['assetImg'],
};

const defaultWorkflowsDetailsParams = {
  select: ['name', 'description', 'externalId'],
  relations: ['workflowSteps'],
};

const defaultFieldParams = {
  select: ['name'],
};

const defaultSourcesListParams = {
  ...defaultFieldParams,
  select: [...defaultFieldParams.select, 'externalId'],
  relations: ['location'],
};

const defaultInspectionTypesListParams = {
  ...defaultFieldParams,
  filters: { status: INSPECTION_TYPE_STATUS.ACTIVE, onlyWithUD: true },
};

const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  return params;
};

const getWorkflows = (params) => {
  log('Attempting to fetch workflows with params', params);
  return request
    .get('/workflows', {
      params: queryParamsToGETParams({
        ...defaultWorkflowsListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Workflows successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching workflows!', params, err);
      return Promise.reject(err);
    });
};

const getWorkflow = ({ id, ...params }) => {
  log('Attempting to fetch workflow with params', { id, ...params });

  return request
    .get(`/workflows/${id}`, {
      params: {
        ...defaultWorkflowsDetailsParams,
        ...params,
      },
    })
    .then((response) => {
      log('Workflow successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the workflow for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getRelatedWorkObjects = ({ workflowId, ...params }) => {
  log('Attempting to fetch related work objects with params', params);
  const filters = { workflowId };
  return request
    .get('/work-objects/', {
      params: queryParamsToGETParams({
        filters,
        ...defaultRelatedWorkObjectsParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Related work objects successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching related work objects!', params, err);
      return Promise.reject(err);
    });
};

const getInspectionTypes = (params) => {
  log('Attempting to fetch inspection types with params', params);

  return request
    .get('/inspection-types', {
      params: queryParamsToGETParams({
        ...defaultInspectionTypesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspection types successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection types for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const getSources = (params) => {
  log('Attempting to fetch sources with params', params);
  return request
    .get('/sources', {
      params: queryParamsToGETParams({
        ...defaultSourcesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Sources successfully fetched', response);
      return response;
    })
    .catch((err) => {
      log('Error fetching sources!', params, err);
      return Promise.reject(err);
    });
};

const getInspectionPlans = (params) => {
  log('Attempting to fetch inspection plans with params', params);

  return request
    .get('/inspection-plans', {
      params: queryParamsToGETParams({
        ...defaultFieldParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Inspection plans successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the inspection plans for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveWorkflow = (workflow) => {
  log('Attempting to save workflow.');

  const query = workflow.id ? `/workflows/${workflow.id}` : '/workflows';
  const method = workflow.id ? 'put' : 'post';

  return request[method](query, {
    ...workflowStateToPOSTParams(workflow),
  })
    .then((response) => {
      log('Workflow successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in saving workflow', e.response.data.message);
      switch (e.response.data.errorCode) {
        case 'entity_body_001':
          // validation error
          const error = processAPIError(e.response.data.details, workflow);
          return Promise.reject({ payload: error });
        default:
          return Promise.reject({
            payload: {
              errors: [
                'An error has occured while performing this operation. Please try again',
              ],
            },
          });
      }
    });
};

const workflowsService = {
  getWorkflows,
  getWorkflow,
  getRelatedWorkObjects,
  getInspectionTypes,
  getSources,
  getInspectionPlans,
  saveWorkflow,
};

export default workflowsService;
