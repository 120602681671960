import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Switch.scss';

const Switch = React.forwardRef(
  (
    {
      onLabel = 'on',
      offLabel = 'off',
      checked = false,
      onChange,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <label className={classNames('qm-switch', className)}>
        <input
          ref={ref}
          data-testid='switch'
          type='checkbox'
          onChange={onChange}
          checked={checked}
          {...props}
        />
        <div className='switch-control'>
          <div className='off'>{offLabel}</div>
          <div className='on'>{onLabel}</div>
        </div>
      </label>
    );
  },
);

Switch.propTypes = {
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default Switch;
