import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import INSPECTION_TYPE_STATUS, {
  getStatusLabel,
} from 'config/inspectionTypeStatus';

const INSPECTION_TYPE_TO_PILL_VARIANT = {
  [INSPECTION_TYPE_STATUS.ACTIVE]: STATUS_PILL_VARIANT.SUCCESS,
  [INSPECTION_TYPE_STATUS.DISABLED]: STATUS_PILL_VARIANT.ERROR,
};

const InspectionTypesStatusPill = ({ status }) => {
  const variant = INSPECTION_TYPE_TO_PILL_VARIANT[status];

  return <StatusPill variant={variant}>{getStatusLabel(status)}</StatusPill>;
};

export default InspectionTypesStatusPill;
