import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import {
  reducer,
  initialState,
  USER_LIST_PAGE_ACTIONS,
} from './usersListReducer';
import usersService from './usersService';
import debounce from 'lodash.debounce';
import { downloadResponse } from 'lib/download';

const fetchUsers = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return usersService
    .getUsers(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useUsersList = ({ location, history }) => {
  const { state, dispatch, ...list } = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchUsers,
  );

  const userClicksOnRow = (id) => {
    history.push(`/users/${id}`);
  };

  const userClicksExportUsers = () => {
    dispatch({
      type: USER_LIST_PAGE_ACTIONS.USER_STARTS_EXPORT_USERS,
    });

    usersService
      .exportUsers()
      .then((data) => {
        downloadResponse(data, 'users.csv');
      })
      .finally(() => {
        dispatch({
          type: USER_LIST_PAGE_ACTIONS.APP_FINISHES_EXPORT_USERS,
        });
      });
  };

  return { state, ...list, userClicksOnRow, userClicksExportUsers };
};

export default useUsersList;
