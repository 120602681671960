import React from 'react';

import EditButton from 'lib/components/edit-button/EditButton';
import IconButton from 'lib/components/icon-button/IconButton';
import File from 'lib/components/file/File';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import Button from 'lib/components/button/Button';
import WorkObjectsTable from 'lib/components/work-objects-table/WorkObjectsTable';
import InspectionsTable from 'lib/components/inspections-table/InspectionsTable';
import InspectionPlansTable from 'lib/components/inspection-plans-table/InspectionPlansTable';
import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import ImageGallery from 'lib/components/image-gallery/ImageGallery';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Tooltip from 'lib/components/tooltip/Tooltip';
import {
  ASSIGN_PERMISSIONS,
  ADD_ASSET_ROLES,
  VIEW_ASSOCIATED_WORK_OBJECTS,
} from '../../assetsPermissions';
import AssetsDetailCondensedTable from '../assets-detail-condensed-table/AssetsDetailCondensedTable';
import useAssetsDetail from '../../useAssetsDetail';

import { ReactComponent as AlertTriangle } from 'assets/images/alert-triangle.svg';
import 'react-image-gallery/styles/css/image-gallery.css';

import './AssetsDetailPage.scss';

const PREVIEW_COUNT = 5;

const AssetsDetailsPage = (props) => {
  const { state, ...vm } = useAssetsDetail(props);

  if (state.loading) {
    return null;
  }

  if (!state.asset) {
    return null;
  }

  return (
    <div className='assets-details-page'>
      <div className='container-m'>
        <div className='row assets-header'>
          <div className='column'>
            <h2 className='margin-bottom-m'>
              {state.asset.name}
              {state.inspectionPlans.count === 0 && (
                <StatusPill
                  variant={STATUS_PILL_VARIANT.ALERT}
                  icon={<AlertTriangle />}
                  inline
                >
                  Plan missing
                </StatusPill>
              )}
            </h2>
            <div className='short-data'>
              {
                <StatusPill
                  variant={STATUS_PILL_VARIANT.NOTIFY}
                  className='margin-right'
                >
                  {state.asset.type.name}
                </StatusPill>
              }
              <div className='id'>ID: {state.asset.id}</div>
              {state.asset.externalId && (
                <div className='id'>External ID: {state.asset.externalId}</div>
              )}
              <div className='id'>Status: {state.asset.status.name}</div>
            </div>
          </div>
          <div className='row'>
            <ProtectedComponent allowed={ADD_ASSET_ROLES}>
              <EditButton
                data-testid='AssetsDetailsPage.editAsset'
                className='margin-right'
                onClick={() =>
                  props.history.push(`/assets/${state.asset.id}/edit`)
                }
              >
                Edit
              </EditButton>
            </ProtectedComponent>
            <ProtectedComponent allowed={ASSIGN_PERMISSIONS}>
              <IconButton
                data-testid='AssetsDetailsPage.assignInspectionPlan'
                prefix={<LinkIcon />}
                disabled={!vm.isAssetActive}
                onClick={() =>
                  props.history.push(
                    `/assets/${props.match.params.id}/linked-inspection-plans`,
                  )
                }
                className='no-wrap'
              >
                {vm.isAssetActive ? (
                  'Linked inspection plans'
                ) : (
                  <Tooltip placement='top' overlay={'Asset is not active'}>
                    Linked inspection plans
                  </Tooltip>
                )}
              </IconButton>
            </ProtectedComponent>
          </div>
        </div>
        <div className='row margin-bottom-xl assets-media'>
          <div className='column data-desc'>
            <div className='asset-desc margin-bottom-l'>
              {state.asset.shortDescription}
            </div>
            <div className='custom-fields-container margin-bottom-xl'>
              {state.asset.companyFields
                .filter(
                  (companyField) =>
                    companyField?.attribute && companyField?.option,
                )
                .map((companyField) => (
                  <div className='custom-field'>
                    <span className='field-label'>
                      {companyField.attribute?.name}
                    </span>
                    {
                      <span className='field-value'>
                        {companyField.option?.name}
                      </span>
                    }
                  </div>
                ))}
              {Object.keys(state.asset.customFields)
                .filter((key) => state.asset.customFields[key].value)
                .map((key) => (
                  <div className='custom-field'>
                    <span className='field-label'>
                      {state.asset.customFields[key]?.name}
                    </span>
                    {
                      <span className='field-value'>
                        {state.asset.customFields[key]?.value}
                      </span>
                    }
                  </div>
                ))}
            </div>
            <h5 className='margin-bottom-m'>Reference Documents</h5>
            <div className='reference-documents margin-bottom-xl'>
              {state.asset.referenceDocuments.length ? (
                state.asset.referenceDocuments.map((document) => (
                  <a
                    href={document.url}
                    target='_blank'
                    rel='noreferrer'
                    key={document.id}
                  >
                    <File data={document} />
                  </a>
                ))
              ) : (
                <div className='empty'>There are no reference documents</div>
              )}
            </div>
          </div>
          {!!state.asset?.images?.length && (
            <div className='gallery-container'>
              <ImageGallery
                items={state.asset.images.map((i) => ({
                  original: i.url,
                  thumbnail: i.url,
                }))}
              />
            </div>
          )}
        </div>
      </div>
      <div
        data-testid='AssetsDetailsPage.measureTableContainer'
        className='margin-bottom-xl padding-container'
      >
        <h5>Measure table</h5>
        {state.asset.measureTable?.dataTable?.length ? (
          <AssetsDetailCondensedTable data={state.asset.measureTable} />
        ) : (
          <div className='empty'>There are no measures for this asset</div>
        )}
        <h5>Custom measures</h5>
        {state.asset.customMeasurements && (
          <div className='custom-measurements'>
            {state.asset.customMeasurements.map((measure) => (
              <p>
                {measure.measure}:{' '}
                <span className='bold'>
                  {measure.value} {measure.unit}
                </span>
              </p>
            ))}
          </div>
        )}
      </div>

      <div className='owners-container info-block padding-container'>
        <div className='block-title'>Asset Owners</div>
        <div className='block-body user-list '>
          {state.asset.owners.length ? (
            state.asset.owners.map((owner) => (
              <NamedAvatar
                onClick={() => vm.userClicksOnOwner(owner)}
                large
                user={owner}
                key={owner.id}
              />
            ))
          ) : (
            <div className='empty'>There are no owners for this asset yet.</div>
          )}
        </div>
      </div>
      <div className='associated-table-title'>
        <h2>Associated inspection plans</h2>
        {state.inspectionPlans?.count > PREVIEW_COUNT && (
          <Button
            type='button'
            className='medium-button'
            onClick={() =>
              props.history.push(
                `/inspection-plans/?byAssetId=${state?.asset?.id}`,
              )
            }
            data-testid='AssetsDetailPage.viewAllInspectionPlans'
          >
            View all
          </Button>
        )}
      </div>
      <InspectionPlansTable
        data={state.inspectionPlans?.data}
        data-testid='AssetsDetailPage.InspectionPlansTable'
        userClicksOnRow={vm.userClicksOnInspectionPlanRow}
      />
      {!state.inspectionPlans?.count && (
        <p className='info-message'>No associated inspection plans found</p>
      )}
      <ProtectedComponent allowed={VIEW_ASSOCIATED_WORK_OBJECTS}>
        <div className='associated-table-title'>
          <h2>Associated work objects</h2>
          {state.workObjects?.count > PREVIEW_COUNT && (
            <Button
              type='button'
              className='medium-button'
              onClick={() =>
                props.history.push(
                  `/work-objects/?byAssetId=${state?.asset?.id}`,
                )
              }
              data-testid='AssetsDetailPage.viewAllWorkObjects'
            >
              View all
            </Button>
          )}
        </div>
        <WorkObjectsTable
          data={state.workObjects?.data}
          data-testid='AssetsDetailPage.WorkObjectsTable'
          userClicksOnRow={vm.userClicksOnWorkObjectRow}
        />
        {!state.workObjects?.count && (
          <p className='info-message'>No associated work objects found</p>
        )}
      </ProtectedComponent>
      <div className='associated-table-title'>
        <h2>Associated inspections</h2>
        {state.inspections?.count > PREVIEW_COUNT && (
          <Button
            type='button'
            className='medium-button'
            onClick={() =>
              props.history.push(`/inspections/?byAssetId=${state?.asset?.id}`)
            }
            data-testid='AssetsDetailPage.viewAllInspections'
          >
            View all
          </Button>
        )}
      </div>
      <InspectionsTable
        data={state.inspections?.data}
        data-testid='AssetsDetailPage.InspectionsTable'
        userClicksOnRow={vm.userClicksOnInspectionRow}
      />
      {!state.inspections?.count && (
        <p className='info-message'>No associated inspections found</p>
      )}
    </div>
  );
};

export default AssetsDetailsPage;
