import { Link } from 'react-router-dom';

import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import DocumentUpload from 'lib/components/document-upload/DocumentUpload';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import OwnersModal from 'lib/components/owners-modal/OwnersModal';
import Select from 'lib/components/select/Select';
import Textarea from 'lib/components/textarea/Textarea';

import useAssetsForm from '../../useAssetsForm';
import MeasureTable from 'modules/measurement-table/MeasureTable';

import { ReactComponent as CloseCircleSolid } from 'assets/images/remove.svg';
import { ReactComponent as CloseCircleOutline } from 'assets/images/circle-delete.svg';

import './AssetsForm.scss';
import classNames from 'classnames';
import AssetFormRefDocs from './AssetFormAssetRefDocs';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { ADD_ASSET_ROLES } from 'modules/assets/assetsPermissions';
import ROLES from 'config/roles';

const AssetsForm = (props) => {
  const { state, ...vm } = useAssetsForm(props);
  const isEditExistingAsset = props.match?.params?.id;
  const SubmitButton = isEditExistingAsset ? EditButton : AddButton;
  const submitText = isEditExistingAsset ? 'Save changes' : 'Create asset';

  const isAssetTypeAvailable = !!state.typeOptions.list.length;

  return (
    <div className='assets-form'>
      <form noValidate data-testid='AssetsForm.form'>
        <div className='form-row description-container'>
          <Input
            name='name'
            type='text'
            charsLeft={state.name.charsLeft}
            label='Name'
            value={state.name.value}
            errors={state.name.errors}
            autoComplete='off'
            onChange={vm.userTypesName}
            data-testid='AssetsForm.name'
          />
        </div>
        <div className='form-row description-container'>
          <Input
            name='externalId'
            type='text'
            charsLeft={
              state.isExternalIdEditable
                ? state.externalId.charsLeft
                : undefined
            }
            label='External ID'
            value={state.externalId.value}
            errors={state.externalId.errors}
            autoComplete='off'
            onChange={vm.userTypesExternalId}
            data-testid='AssetsForm.externalId'
            disabled={!state.isExternalIdEditable}
          />
        </div>
        <div className='form-row description-container'>
          <Textarea
            name='description'
            charsLeft={state.description.charsLeft}
            label='Description'
            value={state.description.value}
            errors={state.description.errors}
            autoComplete='off'
            onChange={vm.userTypesDescription}
            data-testid='AssetsForm.description'
          />
        </div>
        <div className='form-row images-input-container'>
          <p className='section-label'>Asset Images</p>
          <p className='field-description'>
            Please make sure that each image size is at least 1000 x 1000 pixels
            to avoid pixelization.
            <br />
            <br />
            Images are cropped and scaled automatically for mobile and tablet
            views.
          </p>
          <div className='image-preview-container'>
            <input {...vm.getImageInputProps()} />
            {state.images.values.map((image, index) => (
              <div
                className='asset-image'
                key={`asset-img-${image.path || image.id}`}
              >
                <img alt={index} src={vm.getImagePreview(image)} />
                <button
                  type='button'
                  onClick={() => vm.userRemovesImage(index)}
                  className='remove-button'
                >
                  <CloseCircleSolid />
                </button>
              </div>
            ))}
          </div>
          <div className='list-controls'>
            {state.images.values.length < 5 && (
              <Button
                type='button'
                onClick={vm.userClicksUploadImage}
                className='medium-button'
              >
                Upload images
              </Button>
            )}
            {vm.isImageListChanged() && (
              <Button
                type='button'
                onClick={vm.userCancelsImageChanges}
                className='medium-button'
              >
                Cancel changes
              </Button>
            )}
          </div>
          <ErrorBag errors={state.images.errors} />
        </div>
        <div className='form-row type-input-container'>
          <label
            className={classNames('form-label', {
              'is-invalid': !isAssetTypeAvailable,
            })}
          >
            Asset Type
          </label>
          {isAssetTypeAvailable ? (
            <CollapsibleRadio
              name='type'
              value={vm.getProductType}
              errors={state.type.errors}
              options={state.typeOptions.list}
              threshold={vm.treshold}
              onChange={vm.userSelectsProductType}
            />
          ) : (
            <ErrorBag
              errors={[
                <span>
                  There are no asset types defined. Please add them in{' '}
                  <Link to='/settings/asset-types'>settings</Link>!
                </span>,
              ]}
            />
          )}
        </div>
        <div className='form-row status-input-container'>
          <CollapsibleRadio
            label='Status'
            name='status'
            value={vm.getProductStatus}
            errors={state.status.errors}
            options={state.statusOptions.list}
            threshold={vm.treshold}
            onChange={vm.userSelectsStatus}
          />
        </div>
        <div className='form-row company-attributes-container'>
          <p className='section-label'>Company attributes</p>
          <p className='field-description'>
            Company attributes are used to match against question group tags and
            assemble inspection plans automatically.
          </p>
          {!!state.availableCompanyAttributes?.length &&
            state.availableCompanyAttributes.map(
              (field) =>
                field.options.length > 0 && (
                  <div className='input-row form-row' key={field.id}>
                    <Select
                      label={field.label}
                      onChange={(e) =>
                        vm.userSelectsCompanyAttribute(field.id, e)
                      }
                      value={state.companyAttributes[field.id]}
                      options={field.options}
                      isClearable={true}
                      data-testid={`AssetsForm.companyAtributes-${field.id}`}
                    />
                  </div>
                ),
            )}
        </div>

        <div className='form-row custom-field-container'>
          <p className='section-label'>Custom attributes</p>
          <p className='field-description'>
            These attributes are specific for this asset only. Company
            attributes for all assets are managed in the settings page.
          </p>
          {!!state.customFields.list.length &&
            state.customFields.list.map((field, index) => (
              <div
                className='custom-field-item'
                key={field.id || `field-${index}`}
              >
                <Input
                  type='text'
                  value={field.name.value}
                  errors={field.name.errors}
                  onChange={(e) => vm.userTypesCustomFieldName(e, index)}
                  label='Name'
                  data-testid={`AssetsForm.name${index}`}
                />
                <Input
                  type='text'
                  value={field.value.value}
                  errors={field.value.errors}
                  onChange={(e) => vm.userTypesCustomFieldValue(e, index)}
                  label='Value'
                  data-testid={`AssetsForm.value${index}`}
                />
                <DeleteButton
                  className='delete-custom-field'
                  onClick={() => vm.userDeletesCustomField(index)}
                  data-testid='AssetsForm.deleteCustomField'
                >
                  Delete
                </DeleteButton>
              </div>
            ))}

          <Button
            type='button'
            className='medium-button add-custom-field'
            onClick={vm.userAddsCustomField}
            data-testid='AssetsForm.addField'
          >
            Add new field
          </Button>
        </div>
        <div className='form-row owners-container'>
          <p className='section-label'>Asset owners</p>
          {!!state.owners.list.length && (
            <div className='owners-list'>
              {state.owners.list.map((owner) => (
                <div className='owner' key={`asset-owner-${owner.id}`}>
                  <NamedAvatar user={owner} />

                  <ProtectedComponent
                    allowed={ADD_ASSET_ROLES.filter(
                      (role) => role !== ROLES.ASSET_OWNER,
                    )}
                  >
                    <button
                      type='button'
                      onClick={() => vm.userRemovesOwner(owner)}
                      className='remove-button'
                    >
                      <CloseCircleOutline />
                    </button>
                  </ProtectedComponent>
                </div>
              ))}
            </div>
          )}
          <ProtectedComponent
            allowed={ADD_ASSET_ROLES.filter(
              (role) => role !== ROLES.ASSET_OWNER,
            )}
          >
            <div className='list-controls'>
              <Button
                type='button'
                className='medium-button'
                onClick={vm.userClicksOwnerSelect}
                data-testid='AssetsForm.addNewOwner'
              >
                Assign new owner
              </Button>
              {vm.isOwnerListChanged() && (
                <Button
                  type='button'
                  onClick={vm.userCancelsOwnerChanges}
                  className='medium-button'
                >
                  Cancel changes
                </Button>
              )}
            </div>
          </ProtectedComponent>
        </div>
        <AssetFormRefDocs
          data={state.referenceDocuments.list}
          onChange={vm.userTypesDocumentName}
          onDelete={vm.userDeletesDocument}
          onUpload={vm.userUploadDocument}
        />
        <MeasureTable />
        <div className='buttons-container'>
          <SubmitButton
            type='button'
            data-testid='AssetsForm.submit'
            onClick={vm.saveAsset}
            disabled={state.isSaving}
          >
            {submitText}
          </SubmitButton>
          <Button
            type='button'
            className='link-button'
            onClick={props.history.goBack}
          >
            Cancel
          </Button>
        </div>
      </form>
      <OwnersModal
        data-testid='AssetsForm.OwnersModal'
        data={vm.getOwnersForDisplay()}
        selectedCount={state.availableOwners.selected.length}
        count={state.availableOwners.count}
        isOpen={state.availableOwners.isModalOpen}
        onRequestClose={vm.userCancelsOwnersModal}
        onRowClick={vm.userTogglesNewOwner}
        onSubmit={vm.userAssignsOwners}
        onSearchChange={vm.userSearchesOwners}
        search={state.availableOwners.search}
        setSortBy={vm.userSortsOwners}
        sortBy={state.availableOwners.sortBy}
        sortOrder={state.availableOwners.sortOrder}
        page={state.availableOwners.page}
        pageSize={state.availableOwners.pageSize}
        setPage={vm.userSetsOwnersPage}
        title='Select Owners'
      />
    </div>
  );
};
export default AssetsForm;
