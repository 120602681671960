import React from 'react';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './Select.scss';
import ErrorBag from '../error-bag/ErrorBag';

const indicatorSeparatorStyles = (base) => {
  const changes = {
    display: 'none',
  };
  return Object.assign(base, changes);
};

const controlStyles = (base, state) => {
  const borderColor = state.menuIsOpen ? '#6320ee' : 'transparent';
  const changes = {
    border: `1px solid ${borderColor}`,
    minHeight: '44px',
    borderRadius: '6px',
    backgroundColor: '#f4f4f4',
    paddingLeft: '8px',
    paddingRight: '4px',
    boxShadow: 'none',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
      borderColor: '#6320ee',
    },
    ':focus': {
      borderColor: '#6320ee',
    },
  };
  return Object.assign(base, changes);
};

const optionStyles = (base, state) => {
  const changes = {
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.06)' : '#ffffff',
    color: '#161616',
  };
  return Object.assign(base, changes);
};

const menuStyles = (base) => {
  const changes = {
    border: 'none',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    marginBottom: '14px',
    marginTop: '14px',
  };
  return Object.assign(base, changes);
};

const menuPortalStyles = (base) => {
  const changes = {
    zIndex: 101,
  };
  return Object.assign(base, changes);
};

const multiValueStyles = (base) => {
  const changes = {
    height: '24px',
    borderRadius: '18px',
    backgroundColor: '#d0e2ff',
    padding: '0px 2px',
  };

  return Object.assign(base, changes);
};

const multiValueLabelStyles = (base) => {
  const changes = {
    color: '#0043ce',
    fontFamily: 'MaisonNeue-Medium',
    fontSize: '12px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0px',
    paddingRight: '0px',
  };

  return Object.assign(base, changes);
};

const multiValueRemoveStyles = (base) => {
  const changes = {
    color: '#0043ce',
    ':hover': {
      backgroundColor: 'transparent',
    },
    cursor: 'pointer',
  };

  return Object.assign(base, changes);
};

const DropdownIndicator = (props) => {
  return props.isMulti ? null : (
    <components.DropdownIndicator {...props}>
      <div className='dropdown-indicator'>
        <FontAwesomeIcon style={{ color: '#6320ee' }} icon={faAngleDown} />
      </div>
    </components.DropdownIndicator>
  );
};
const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <span>
        {props.selectProps?.isGrouped ? `${props?.data?.groupLabel}:` : ''}{' '}
        {props.selectProps?.isGrouped ? (
          <strong>{props?.data?.label}</strong>
        ) : (
          props?.data?.label
        )}
      </span>
    </components.MultiValueLabel>
  );
};

/**
 * Create a component for main Selection field, with injected testid
 * @param {string} testid data-testid value
 * @returns
 */
const injectTestID = (testid) => ({ children, innerProps, ...props }) => {
  return (
    <components.SelectContainer
      innerProps={{ ...innerProps, ...{ 'data-testid': testid } }}
      {...props}
    >
      {children}
    </components.SelectContainer>
  );
};

const Select = React.forwardRef(
  (
    {
      isCreatable,
      isInModal,
      className,
      label,
      errors,
      components = {},
      ...props
    },
    ref,
  ) => {
    const classes = classNames('select-input', className, {
      'is-invalid': errors?.length,
    });

    const Component = isCreatable ? CreatableSelect : ReactSelect;

    const componentsToOverride = {};

    componentsToOverride.SelectContainer = injectTestID(
      props['data-testid'] ?? 'Select.container',
    );

    const modalProps = isInModal
      ? {
          menuShouldBlockScroll: true,
          menuPortalTarget: document.body,
          menuPlacement: 'auto',
        }
      : {};

    return (
      <>
        {label && (
          <label className='form-label' htmlFor={props.name}>
            {label}
          </label>
        )}
        <Component
          styles={{
            control: controlStyles,
            menu: menuStyles,
            indicatorSeparator: indicatorSeparatorStyles,
            option: optionStyles,
            multiValue: multiValueStyles,
            multiValueLabel: multiValueLabelStyles,
            multiValueRemove: multiValueRemoveStyles,
            menuPortal: menuPortalStyles,
          }}
          classNamePrefix='qm-select'
          ref={ref}
          components={{
            DropdownIndicator,
            MultiValueLabel,
            ...componentsToOverride,
            ...components,
          }}
          {...modalProps}
          className={classes}
          formatCreateLabel={(value) => {
            return (
              <div className='select-input-create-label'>
                + Create{' '}
                <span className='select-input-create-label-value'>{value}</span>
              </div>
            );
          }}
          {...props}
        />
        <ErrorBag errors={errors} />
      </>
    );
  },
);

export default Select;
