import classNames from 'classnames';

import { ReactComponent as HomeOutline } from 'assets/images/home-outline.svg';
import { ReactComponent as ClipboardOutline } from 'assets/images/clipboard-outline.svg';
import { ReactComponent as UserOutline } from 'assets/images/user-outline.svg';
import { ReactComponent as ShuffleOutline } from 'assets/images/shuffle-outline.svg';

import './TaggableEntity.scss';

const ICON_MAP = {
  inspector: ClipboardOutline,
  source: HomeOutline,
  coordinator: UserOutline,
  vendor: ShuffleOutline,
};

export default function TaggableEntity({
  type,
  id,
  posX,
  posY,
  onClick,
  selectedId,
}) {
  const IconComponent = ICON_MAP[type];
  return (
    <g
      draggable={true}
      data-id={id}
      className={classNames('qm-taggable-entity', {
        selected: selectedId === id,
      })}
      onMouseDown={() => onClick(id)}
    >
      <ellipse cx={posX} cy={posY} rx={20} ry={20} />
      <IconComponent
        className='icon'
        x={posX - 10}
        y={posY - 10}
        width={20}
        height={20}
      />
    </g>
  );
}
