import Button from 'lib/components/button/Button';
import Input from 'lib/components/input/Input';
import { getTranslation } from 'lib/dataTransform';
import React from 'react';

import './SettingsDefectsForm.scss';

export default function SettingsDefectsForm(props) {
  const { translation, fallback } = getTranslation(
    props.defect.name.value,
    props.language,
    props.languageList,
  );
  return (
    <form className='settings-defect-form'>
      <div>
        <Input
          type='text'
          value={translation}
          placeholder={fallback}
          errors={props.defect.name.errors}
          autoComplete='off'
          onChange={props.onChange}
          data-testid='DefectForm.defectValue'
        />
      </div>
      <Button
        type='button'
        data-testid='DefectForm.save'
        className='medium-button'
        onClick={props.editDefect}
      >
        Save
      </Button>
      <p onClick={props.clearEdit} className='link-button'>
        Cancel
      </p>
    </form>
  );
}
