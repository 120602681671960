import UsersListPage from './components/users-list-page/UsersListPage';
import UsersAddPage from './components/users-add-page/UsersAddPage';
import UsersEditPage from './components/users-edit-page/UsersEditPage';
import UsersDetailPage from './components/users-detail-page/UsersDetailPage';
import UsersImportPage from './components/users-import-page/UsersImportPage';
import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import { ADD_USER_ROLES, VIEW_USER_ROLES } from './usersPermissions';
import { Route } from 'react-router';

export default function UsersRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_USER_ROLES}
        path='/users'
        exact
        render={(props) => <UsersListPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_USER_ROLES}
        path='/users/add'
        exact
        render={(props) => <UsersAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_USER_ROLES}
        path='/users/import'
        exact
        render={(props) => <UsersImportPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_USER_ROLES}
        path='/users/:id(\d+)'
        exact
        render={(props) => <UsersDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_USER_ROLES}
        path='/users/:id(\d+)/edit'
        exact
        render={(props) => <UsersEditPage {...props} />}
      />
      <Route
        path='/my-profile'
        exact
        render={(props) => <UsersDetailPage self {...props} />}
      />
      <Route
        path='/my-profile/edit'
        exact
        render={(props) => <UsersEditPage self {...props} />}
      />
    </>
  );
}
