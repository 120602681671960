import React from 'react';
import { getTranslation } from 'lib/dataTransform';
import Tooltip from 'lib/components/tooltip/Tooltip';

import { ReactComponent as PencilIcon } from 'assets/images/pencil-outline.svg';
import './SettingsDefectsItem.scss';

export default function SettingsDefectsItem(props) {
  const { translation, fallback } = getTranslation(props.name, props.language);
  return (
    <div className='defect-item'>
      {translation || <span className='no-translation'>{fallback}</span>}
      {!props.editing && (
        <>
          <Tooltip overlay='Edit defect' placement='top'>
            <PencilIcon onClick={props.setEdit} />
          </Tooltip>
        </>
      )}
    </div>
  );
}
