import WORK_OBJECT_ACTIVITIES from 'config/activities/work-object.activities';

import { castDateTimeToSimpleDate, formatDate } from 'lib/dateHelpers';

function formatTypeName(name) {
  const inspectionTypeIncludesInspection =
    name.toLowerCase().indexOf('inspection') !== -1;
  return `${name} ${inspectionTypeIncludesInspection ? '' : 'inspection'}`;
}

const RENDER_WORK_OBJECTS = {
  [WORK_OBJECT_ACTIVITIES.WORK_OBJECT_CREATED]: (author, data) => {
    const resourceType = data?.actions?.asset ? 'asset' : 'source';
    return (
      <>
        {author} has created a <strong>work object</strong> for the{' '}
        {resourceType} <strong>{data?.actions[resourceType]}</strong> with a{' '}
        <strong>quantity of {data?.actions?.quantity}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.WORK_OBJECT_IMPORTED]: (author, data) => {
    const resourceType = data?.actions?.asset ? 'asset' : 'source';
    return (
      <>
        {author} has imported a <strong>work object</strong> for the{' '}
        {resourceType} <strong>{data?.actions[resourceType]}</strong> with a{' '}
        <strong>quantity of {data?.actions?.quantity}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_SUBMITTED]: (author, data) => {
    return (
      <>
        {author} has submitted a request to create a{' '}
        <strong>work object</strong> with a{' '}
        <strong>quantity of {data?.actions?.quantity}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_ACCEPTED]: (author, data) => {
    return (
      <>
        {author} has accepted a <strong>work object</strong> with a{' '}
        <strong>quantity of {data?.actions?.quantity}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_REJECTED]: (author, data) => {
    return (
      <>
        {author} has rejected a <strong>work object</strong> with a{' '}
        <strong>quantity of {data?.actions?.quantity}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_DEADLINE_UPDATED]: (
    author,
    data,
  ) => {
    const deadline = <strong>{formatDate(data?.actions?.deadline)}</strong>;
    const oldDeadline = (
      <strong>{formatDate(data?.actions?.oldDeadline)}</strong>
    );

    return (
      <>
        {author} has modified a <strong>work object deadline</strong> from{' '}
        {oldDeadline} to {deadline}
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_READY_FOR_INSPECTION_CHANGE]: (
    author,
    data,
  ) => {
    const ready =
      data.actions.version === '1.0' || data.actions.isReadyForInspection ? (
        <strong>ready for inspection</strong>
      ) : (
        <strong>not ready for inspection</strong>
      );
    return (
      <>
        {author} has marked a partial as {ready}
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_INSPECTION_PUBLISHED]: (
    author,
    data,
  ) => {
    const { inspectionType, source } = data.actions;

    return (
      <>
        <strong>{author}</strong> has published the{' '}
        <strong>{formatTypeName(inspectionType)}</strong> at{' '}
        <strong>{source}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_INSPECTION_DATE_CHANGED]: (
    author,
    data,
  ) => {
    const {
      oldInspectionDate,
      inspectionDate,
      inspectionType,
      source,
    } = data.actions;

    let action;

    if (!inspectionDate) {
      action = (
        <>
          has removed the inspection date for the{' '}
          <strong>{formatTypeName(inspectionType)}</strong> at{' '}
          <strong>{source}</strong>
        </>
      );
    } else {
      if (!oldInspectionDate) {
        action = (
          <>
            has set the inspection date to{' '}
            <strong>
              {formatDate(castDateTimeToSimpleDate(inspectionDate))}
            </strong>{' '}
            for the <strong>{formatTypeName(inspectionType)}</strong> at{' '}
            <strong>{source}</strong>
          </>
        );
      } else {
        action = (
          <>
            has changed the inspection date from{' '}
            <strong>
              {formatDate(castDateTimeToSimpleDate(oldInspectionDate))}
            </strong>{' '}
            to{' '}
            <strong>
              {formatDate(castDateTimeToSimpleDate(inspectionDate))}
            </strong>
          </>
        );
      }
    }

    return (
      <>
        <strong>{author}</strong> {action} for the{' '}
        <strong>{formatTypeName(inspectionType)}</strong> at{' '}
        <strong>{source}</strong>
      </>
    );
  },
  [WORK_OBJECT_ACTIVITIES.PARTIAL_WORK_OBJECT_WINDOW_UPDATED]: (
    author,
    data,
  ) => {
    const {
      inspectionWindowStartDate,
      inspectionWindowEndDate,
      source,
      inspectionType,
    } = data.actions;
    const hasDate = inspectionWindowStartDate && inspectionWindowEndDate;

    return hasDate ? (
      <>
        {author} has set the inspection window for the{' '}
        <strong>{formatTypeName(inspectionType)}</strong> at{' '}
        <strong>{source}</strong> between{' '}
        <strong>
          {formatDate(castDateTimeToSimpleDate(inspectionWindowStartDate))}
        </strong>{' '}
        and{' '}
        <strong>
          {formatDate(castDateTimeToSimpleDate(inspectionWindowEndDate))}
        </strong>
      </>
    ) : (
      <>
        {author} has removed the inspection window for the{' '}
        <strong>{formatTypeName(inspectionType)}</strong> at{' '}
        <strong>{source}</strong>
      </>
    );
  },
};

export default RENDER_WORK_OBJECTS;
