import makeReducer from 'lib/makeReducer';

const INITIAL_INSPECTION_TYPES_DETAILS_STATE = {
  inspectionType: {},
  loading: true,
  errors: [],
};

export const INSPECTION_TYPES_DETAILS_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
};

const INSPECTION_TYPES_DETAILS_REDUCER_CONFIG = {
  [INSPECTION_TYPES_DETAILS_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_TYPES_DETAILS_REDUCER_CONFIG,
  INITIAL_INSPECTION_TYPES_DETAILS_STATE,
);
