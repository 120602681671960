import request from 'lib/request';
import makeLogger from 'lib/makeLogger';
import { companyStateToPOSTParams } from './dataTransform';
import { processAPIError } from './companiesFormValidator';

const log = makeLogger('companiesService');

const queryParamsToGETParams = (params) => {
  if (params.sortBy && params.sortOrder) {
    params.order = { [params.sortBy]: params.sortOrder };
    delete params.sortBy;
    delete params.sortOrder;
  }

  if (params?.status) {
    params.filters = { ...params?.filters, status: params.status };
    delete params.status;
  }

  return params;
};

const defaultCompanyDetailParams = {
  select: ['name', 'about', 'status', 'customConfig'],
  relations: ['phones', 'emails', 'location', 'clientAdmins'],
};

const defaultCompaniesListParams = {
  page: 1,
  pageSize: 10,
  select: ['id', 'name', 'createdAt', 'status', 'phones.phone', 'emails.email'],
  relations: ['phones', 'emails'],
};

const getCompany = ({ id, ...params }) => {
  log('Attempting to fetch company with params', params);

  return request
    .get(`/companies/${id}`, {
      params: {
        ...defaultCompanyDetailParams,
        ...params,
      },
    })
    .then((response) => {
      log('Company successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the company for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};
const getCompanies = (params) => {
  log('Attempting to fetch companies with params', params);

  return request
    .get(`/companies/`, {
      params: queryParamsToGETParams({
        ...defaultCompaniesListParams,
        ...params,
      }),
    })
    .then((response) => {
      log('Companies successfully fetched.', response);
      return response;
    })
    .catch((e) => {
      log(
        'There was an issue in fetching the companies for the required params',
        params,
        e,
      );
      return Promise.reject(e);
    });
};

const saveCompany = (company) => {
  log('Attempting to save company.');

  const query = company.id ? `/companies/${company.id}` : '/companies';
  const method = company.id ? 'put' : 'post';

  return request[method](query, {
    ...companyStateToPOSTParams(company),
  })
    .then((response) => {
      log('Company successfully saved.', response);
      return response;
    })
    .catch((e) => {
      log('There was an issue in saving company', e.response.data.message);
      if (e.response.data.errorCode === 'entity_body_001') {
        // validation error
        const error = processAPIError(e.response.data.details, company);
        return Promise.reject({ payload: error });
      } else {
        return Promise.reject({
          payload: {
            errors: [
              'An error has occured while performing this operation. Please try again',
            ],
          },
        });
      }
    });
};

const companiesService = {
  getCompany,
  getCompanies,
  saveCompany,
};

export default companiesService;
