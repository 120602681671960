import Table from 'lib/components/table/Table';
import WarningPill from 'lib/components/warning-pill/WarningPill';
import SourceStatusPill from '../source-status-pill/SourceStatusPill';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';

import './SourcesTable.scss';

const sourcesTableColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <>
        {!record.hasCoordinators && (
          <WarningPill overlay='Missing assigned coordinator' />
        )}
        <span>
          {record.id} {!!record?.externalId && `/ ${record.externalId}`}
        </span>
      </>
    ),
  },
  {
    title: 'Name',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Location',
    sortId: 'location.reducedLocation',
    render: ({ location }) => (
      <>
        {!!location?.address && (
          <>
            {location.address}
            <br />
          </>
        )}
        {[location?.city, location?.country].filter((i) => !!i).join(', ')}
      </>
    ),
  },
  {
    title: 'Type',
    sortId: 'type.name',
    className: 'pill-cell',
    textAlign: 'left',
    render: (record) => (
      <span className='pill-container'>
        <StatusPill variant={STATUS_PILL_VARIANT.NOTIFY}>
          {record.type?.name}
        </StatusPill>
      </span>
    ),
  },
  {
    title: 'Status',
    sortId: 'status',
    className: 'pill-cell',
    textAlign: 'right',
    render: (record) => (
      <span className='pill-container'>
        <SourceStatusPill status={record.status} />
      </span>
    ),
  },
];

const SourcesTable = ({ userClicksOnRow, ...props }) => (
  <Table
    columns={sourcesTableColumns}
    onRowClick={(record) => userClicksOnRow(record.id)}
    className='sources-table'
    {...props}
  />
);

export default SourcesTable;
