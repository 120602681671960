import INSPECTION_STATUS from 'config/inspectionStatus';
import { formatDate } from 'lib/dateHelpers';

import AnswerForm from '../answer-form/AnswerForm';
import Button from 'lib/components/button/Button';
import EditButton from 'lib/components/edit-button/EditButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import InspectionsResultPill from 'lib/components/inspections-result-pill/InspectionsResultPill';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import QuestionGroup from 'lib/components/question-group/QuestionGroup';
import Textarea from 'lib/components/textarea/Textarea';
import classNames from 'classnames';

import testId from './fixtures/testid';
import usePerformInspection from '../../usePerformInspection';

import './PerformInspectionPage.scss';

export default function PerformInspectionPage(props) {
  const vm = usePerformInspection(props);

  if (vm.state.loading) {
    return (
      <div
        data-testid={testId.loading}
        className='perform-inspection-page container-m'
      >
        Loading
      </div>
    );
  }

  if (!vm.isPerformAllowed) {
    props.history.push('/');
    return null;
  }

  return (
    <div
      data-testid={testId.container}
      className={
        classNames(
          'perform-inspection-page',
          'container-m',
          { 'is-vendor-survey': vm?.state?.inspection?.inspectionType?.isSurvey }
        )
      }
    >
      <h2>
        Perform {vm.state.inspection?.inspectionType?.name || 'inspection'}
      </h2>
      <div className='inspection-details'>
        <div>
          <div className='label'>Status:</div>
          <div className='value'>
            <InspectionsResultPill status={vm.state.inspection?.status} />
          </div>
        </div>
        <div>
          <div className='label'>Inspection date:</div>
          <div className='value'>
            {vm.state.inspection?.inspectionDate
              ? formatDate(vm.state.inspection.inspectionDate)
              : 'None'}
          </div>
        </div>
        <div>
          <div className='label'>Inspector:</div>
          <div className='value'>
            {vm.state.inspection?.inspector ? (
              <NamedAvatar user={vm.state.inspection?.inspector} />
            ) : (
              'Unassigned'
            )}
          </div>
        </div>
      </div>
      {[INSPECTION_STATUS.PUBLISHED, INSPECTION_STATUS.DRAFT].includes(
        vm.state.inspection?.status,
      ) && (
          <div className='inspection-actions'>
            <Button
              data-testid={testId.startButton}
              onClick={vm.userStartsInspection}
            >
              Start
            </Button>
          </div>
        )}
      {vm.state.inspection?.status === INSPECTION_STATUS.IN_PROGRESS && (
        <div className='perform-inspection-form'>
          <h4>Questions</h4>
          {vm.state.inspection?.questionGroups
            .sort((a, b) => a.order - b.order)
            .map((group) => (
              <QuestionGroup
                key={group.id}
                data={group}
                data-testid={testId.answerGroup}
              >
                {group.questions
                  .sort((a, b) => a.order - b.order)
                  .map((question) => (
                    <AnswerForm
                      key={question.id}
                      onPhotoUpload={(files) =>
                        vm.onPhotoUpload(group.id, question.id, files)
                      }
                      onPhotoRemove={(index) =>
                        vm.onPhotoRemove(group.id, question.id, index)
                      }
                      onDocumentInputChange={(index, value) => {
                        vm.onDocumentInputChange(
                          group.id,
                          question.id,
                          index,
                          value,
                        );
                      }}
                      onDocumentUpload={(files) =>
                        vm.onDocumentUpload(group.id, question.id, files)
                      }
                      onDocumentRemove={(index) =>
                        vm.onDocumentRemove(group.id, question.id, index)
                      }
                      onInputChange={(field, val, index) =>
                        vm.onQuestionInputChange(
                          group.id,
                          question.id,
                          field,
                          val,
                          index,
                        )
                      }
                      isHidden={vm.isQuestionHidden(
                        group,
                        question,
                        vm.state.inspection.asset,
                      )}
                      data={question}
                    />
                  ))}
              </QuestionGroup>
            ))}
          <div className='form-row inspection-summary-row'>
            <h4>Inspection summary</h4>
            <Textarea
              data-testid={testId.summary}
              rows={4}
              className='inspection-summary'
              charsLeft={vm.state?.inspection?.summary?.charsLeft}
              value={vm.state?.inspection?.summary?.value || ''}
              errors={vm.state?.inspection?.summary?.errors}
              onChange={(ev) => vm.onInputChange('summary', ev.target.value)}
            />
          </div>
          <div className='form-row perform-inspection-actions'>
            <ErrorBag errors={vm.state.errors} />
            <EditButton
              onClick={vm.userClicksFinishInspection}
              disabled={vm.state?.isSubmitting}
            >
              Finish inspection
            </EditButton>
          </div>
        </div>
      )}
    </div>
  );
}
