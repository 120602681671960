import { id } from 'lib/funcHelpers';
import makeLogger from './makeLogger';
const log = makeLogger('reducer');

const makeReducer = (reducerConfig = {}, initialState = {}) => {
  const reducer = (state, action) => {
    log(action.type, action.payload, state);
    return (reducerConfig[action.type] || id)(state, action);
  };

  return {
    reducer,
    initialState,
  };
};

export default makeReducer;
