import React, { useContext } from 'react';
import ProfileMenu from './ProfileMenu';
import { AppContext } from '../../App';
import './Header.scss';
import Avatar, { AVATAR_SIZES } from 'lib/components/avatar/Avatar';

function Header() {
  const { appState } = useContext(AppContext);

  return (
    <div className='bx--row'>
      <div className='userControls bx--col-lg-16 bx--col-mg-12 bx--col-sm-16'>
        <Avatar
          url={appState.currentUser.profilePhoto?.url}
          placeholder={appState.currentUser.name}
          size={AVATAR_SIZES.M}
        />
        <ProfileMenu />
      </div>
    </div>
  );
}

export default Header;
