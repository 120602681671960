import PropTypes from 'prop-types';
import classNames from 'classnames';
import TabsItem from './tabs-item/TabsItem';

import './Tabs.scss';

const Tabs = (props) => {
  return (
    <div
      className={classNames(
        'tabs-container',
        {
          'tabs-separator': props.showSeparator,
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

Tabs.Item = TabsItem;

Tabs.defaultProps = {
  showSeparator: false,
};

Tabs.propTypes = {
  children: PropTypes.node,
  showSeparator: PropTypes.bool,
};

export default Tabs;
