import LoginPage from './components/login-page/LoginPage';
import ResetPasswordPage from './components/reset-password-page/ResetPasswordPage';
import NewPasswordPage from './components/new-password-page/NewPasswordPage';
import { Route, Redirect, Switch } from 'react-router-dom';

export default function AuthRoutes() {
  return (
    <Switch>
      <Route path='/' exact render={(props) => <LoginPage {...props} />} />
      <Route
        path='/reset-password'
        render={(props) => <ResetPasswordPage {...props} />}
      />
      <Route
        path='/new-password'
        render={(props) => <NewPasswordPage {...props} />}
      />
      <Route path='*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  );
}
