import * as yup from 'yup';

import { ERROR_TEMPLATES, API_ERROR_CODES } from 'config/errorCodes';
import {
  ANSWER_TYPE,
  MULTIPLE_CHOICE_ANSWER_TYPES,
} from 'config/questionOptions';

import validator, {
  yupNumber,
  inputShape,
  requiredFormObject,
  requiredFormString,
  uniqueArrayStrValuesSchema,
} from 'lib/validator';
import { makeAPIBodyErrorProcessor, parseTemplate } from 'lib/errorHelpers';
import _cloneDeep from 'lodash/cloneDeep';

const ERRORS = {
  ...ERROR_TEMPLATES,
  nonNegative: 'Value must not be negative',
  integer: 'Value must be an integer',
  nameLength: parseTemplate(ERROR_TEMPLATES.exceeded, {
    name: 'Name',
    max: '40 characters',
  }),
  fileSize: 'Maximum allowed size for documents is 5MB',
  defectWeight: 'Defect weight is required',
  maxPercent: parseTemplate(ERROR_TEMPLATES.exceeded, { max: '100%' }),
  lowerToleranceWeight: 'Lower tolerance weight is required',
  upperToleranceWeight: 'Upper tolerance weight is required',
  criteriaMin: parseTemplate(ERROR_TEMPLATES.minimum, {
    field: 'Dynamic criteria',
    min: '0',
  }),
};

// eslint-disable-next-line no-unused-vars
const questionDocumentValidationSchema = yup.object().shape({
  value: yup
    .string()
    .max(40, ERRORS.nameLength)
    .matches(/^([a-zA-Z0-9 ]+)$/, ERRORS.alphanumeric)
    .required(parseTemplate(ERRORS.required)),
  file: yup
    .mixed()
    .test('fileSize', ERRORS.fileSize, (file) => {
      return file?.url || file?.size <= 5 * 1024 * 1024;
    })
    .required(parseTemplate(ERRORS.required)),
});

// eslint-disable-next-line no-unused-vars
const questionToolValidationSchema = yup.object().shape({
  value: yup.string().matches(/^([a-zA-Z0-9 _]+)$/, ERRORS.alphanumeric),
});

const questionAssetRefDocValidationSchema = yup.object().shape({
  value: yup
    .string()
    .matches(/^([a-zA-Z0-9 _]+)$/, ERRORS.alphanumeric)
    .max(
      40,
      parseTemplate(ERROR_TEMPLATES.exceeded, {
        field: 'Document Name',
        max: '50 characters',
      }),
    ),
});

// eslint-disable-next-line no-unused-vars
const questionDefectValidationSchema = yup.object().shape({
  weight: yup.object().nullable().required(ERRORS.defectWeight),
});

const isEditForm = (isOverridden, isCustom) => isOverridden || isCustom;

const questionFormValidationSchema = yup.object().shape({
  name: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: inputShape(
        yup
          .array()
          .nullable()
          .test('atLeastOneTranslation', ERRORS.required, (translations) => {
            return !!translations.filter((t) => t.text !== '').length;
          }),
      ),
    }),
  type: yup.object().when(['isOverridden', 'isCustom'], {
    is: isEditForm,
    then: requiredFormObject(),
  }),
  questionWeight: yup.object().when(['isOverridden', 'isCustom'], {
    is: isEditForm,
    then: requiredFormObject(),
  }),
  answerType: yup.object().when(['isOverridden', 'isCustom'], {
    is: isEditForm,
    then: requiredFormObject(),
  }),
  answer: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType'], {
      is: (_o, _c, _t) =>
        isEditForm(_o, _c) &&
        MULTIPLE_CHOICE_ANSWER_TYPES.includes(_t.value?.value),
      then: requiredFormObject(),
    }),
  expectedBarcodeUOM: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType'], {
      is: (_o, _c, _t) =>
        isEditForm(_o, _c) && _t.value?.value === ANSWER_TYPE.BARCODE_INPUT,
      then: requiredFormObject(),
    }),
  sampleQty: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'useAqlLevel'], {
      is: (_o, _c, _a) => isEditForm(_o, _c) && _a?.value === false,
      then: yup
        .object()
        .nullable()
        .when(['sampleRule'], {
          is: (_r) => _r?.value === '%',
          then: inputShape(
            yupNumber.required(ERRORS.required).max(100, ERRORS.maxPercent),
          ),
          otherwise: inputShape(
            yupNumber.required(ERRORS.required).integer(ERRORS.integer),
          ),
        }),
    }),
  sampleRule: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: inputShape(yup.string()),
    }),
  aqlMajor: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'aqlLevel'], {
      is: (_o, _c, _l) => isEditForm(_o, _c) && _l.value !== null,
      then: requiredFormObject(),
    }),
  aqlMinor: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'aqlLevel'], {
      is: (_o, _c, _l) => isEditForm(_o, _c) && _l.value !== null,
      then: requiredFormObject(),
    }),
  aqlFunctional: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'aqlLevel'], {
      is: (_o, _c, _l) => isEditForm(_o, _c) && _l.value !== null,
      then: requiredFormObject(),
    }),
  criticalDefect: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'aqlLevel'], {
      is: (_o, _c, _a) => isEditForm(_o, _c) && _a.value !== null,
      then: inputShape(
        yupNumber
          .integer(ERRORS.integer)
          .min(0, ERRORS.nonNegative)
          .required(ERRORS.required),
      ),
    })
    .when(['criticalDefectRule'], {
      is: (rule) => rule?.value === '%',
      then: inputShape(
        yupNumber.required(ERRORS.required).max(100, ERRORS.maxPercent),
      ),
    }),
  photoRequired: yup.object().nullable().when(['isOverridden', 'isCustom'], {
    is: isEditForm,
    then: requiredFormString(),
  }),
  printOnReport: yup.object().nullable().when(['isOverridden', 'isCustom'], {
    is: isEditForm,
    then: requiredFormObject(),
  }),
  dependencyQuestion: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'dependencyAction'], {
      is: (_o, _c, _a) => isEditForm(_o, _c) && _a?.value?.value !== false,
      then: requiredFormObject(),
    }),
  dependencyCriteria: yup
    .string()
    .nullable()
    .when(['isOverridden', 'isCustom', 'dependencyAction'], {
      is: (_o, _c, _a) => isEditForm(_o, _c) && _a?.value?.value !== false,
      then: yup.string().nullable().required(ERROR_TEMPLATES.required),
    }),
  dynamicRule: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'dynamicAction'], {
      is: (_o, _c, _a) =>
        isEditForm(_o, _c) &&
        ![null, false, undefined].includes(_a?.value?.value),
      then: requiredFormObject(),
    }),
  dynamicCriteria: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'dynamicAction', 'dynamicRule'], {
      is: (_o, _c, _a, _r) =>
        isEditForm(_o, _c) &&
        ![null, false, undefined].includes(_a.value?.value) &&
        _r.value?.value !== 'first_inspection',
      then: inputShape(
        yupNumber
          .positive(ERRORS.criteriaMin)
          .integer(ERRORS.integer)
          .required(ERRORS.required),
      ),
    }),
  upperTolerance: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType'], {
      is: (_o, _c, _a) =>
        isEditForm(_o, _c) && _a?.value?.value === 'quantitative_input',
      then: inputShape(yupNumber.min(0, ERRORS.nonNegative)),
    })
    .when(['upperToleranceRule'], {
      is: (rule) => rule?.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
    }),
  upperToleranceWeight: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType', 'upperTolerance'], {
      is: (_o, _c, _typ, _tol) =>
        isEditForm(_o, _c) &&
        _typ?.value?.value === 'quantitative_input' &&
        _tol?.value !== undefined,
      then: requiredFormObject(ERRORS.upperToleranceWeight),
    }),
  lowerTolerance: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType'], {
      is: (_o, _c, _a) =>
        isEditForm(_o, _c) && _a?.value?.value === 'quantitative_input',
      then: inputShape(yupNumber.min(0, ERRORS.nonNegative)),
    })
    .when(['lowerToleranceRule'], {
      is: (rule) => rule?.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
    }),
  lowerToleranceWeight: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'answerType', 'lowerTolerance'], {
      is: (_o, _c, _typ, _tol) =>
        isEditForm(_o, _c) &&
        _typ?.value?.value === 'quantitative_input' &&
        _tol?.value !== undefined,
      then: requiredFormObject(ERRORS.lowerToleranceWeight),
    }),
  customExpectedMeasureValue: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'expectedMeasureTableResult'], {
      is: (_o, _c, _r) => isEditForm(_o, _c) && _r.value?.value === 'Custom',
      then: inputShape(yupNumber.required(ERRORS.required)),
    }),
  customExpectedUom: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'expectedMeasureTableResult'], {
      is: (_o, _c, _r) => isEditForm(_o, _c) && _r.value?.value === 'Custom',
      then: requiredFormObject(),
    }),
  otherMeasure: yup
    .object()
    .nullable()
    .when(['isOverridden', 'isCustom', 'expectedMeasureTableResult'], {
      is: (_o, _c, _r) => isEditForm(_o, _c) && _r.value?.value === 'Other',
      then: requiredFormObject(),
    }),
  documents: yup
    .mixed()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: uniqueArrayStrValuesSchema()
        .nullable()
        .of(questionDocumentValidationSchema),
    }),
  tools: yup
    .mixed()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: uniqueArrayStrValuesSchema()
        .nullable()
        .of(questionToolValidationSchema),
    }),
  assetReferenceDocNames: yup
    .mixed()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: uniqueArrayStrValuesSchema()
        .nullable()
        .of(questionAssetRefDocValidationSchema),
    }),
  defects: yup
    .mixed()
    .nullable()
    .when(['isOverridden', 'isCustom'], {
      is: isEditForm,
      then: yup.array().nullable().of(questionDefectValidationSchema),
    }),
});

const questionGroupValidationSchema = yup.object().shape({
  questions: yup.array().of(questionFormValidationSchema),
});

export const inspectionPlansFormValidationSchema = yup.object().shape({
  name: requiredFormString(),
  status: requiredFormString(),
  type: requiredFormObject(),
  aqlLevel: yup.object(),
  aqlMajor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlMinor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlFunctional: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  criticalDefect: yup
    .object()
    .when('aqlLevel', {
      is: (level) => level.value !== null,
      then: inputShape(
        yupNumber
          .integer(ERRORS.integer)
          .min(0, ERRORS.nonNegative)
          .required(ERRORS.required),
      ),
    })
    .when('criticalDefectRule', {
      is: (rule) => rule.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
    }),
  questionGroups: yup.array().of(questionGroupValidationSchema),
});

const apiPathToFormPath = (path) =>
  path
    .replace(/^(questionGroups\.\d+\.questions\.\d+\.)(body\.)/, '$1')
    .replace(/(tools|documents\.[0-9]+).*/, '$1')
    .replace(/^(questionGroups.[0-9]+.questions.[0-9]+)$/, '$1.name')
    .replace(/(defects.[0-9]+).weight$/, '$1')
    .replace(/((?:lower|upper)Tolerance)Value/, '$1');

const inspectionPlansErrors = {
  ...API_ERROR_CODES,
  'array.unique': 'Questions must be unique within the company',
  'any.required': 'This field is required',
  'status.missingAqlOrSampleSize':
    'To publish an inspection plan without AQL, please edit all AQL questions to  have custom sample size.',
};

export const processInspectionPlansAPIError = makeAPIBodyErrorProcessor({
  errorCodes: inspectionPlansErrors,
  transformPath: apiPathToFormPath,
});

const resetValidation = (o) => {
  if (Array.isArray(o) && o?.length) {
    o.forEach((oItem) => resetValidation(oItem));
  } else if (o && typeof o === 'object') {
    if ('value' in o && Array.isArray(o?.errors) && o?.errors?.length) {
      o.errors = [];
    }

    Object.keys(o)?.forEach((oKey) => resetValidation(o[oKey]));
  }
};

const inspectionPlansFormValidatorNew = (state) => {
  const noErrState = _cloneDeep(state);

  resetValidation(noErrState);

  const rslt = validator(noErrState, inspectionPlansFormValidationSchema);
  return rslt;
};

export default inspectionPlansFormValidatorNew;
