import { SYSTEM_DEFECTS } from 'config/defects';
import './AnswerDetail.scss';
import Decimal from 'decimal.js';

/**
 * Extracts value and unit from a raw string answer value.
 *
 * @param {String} v Raw answer string
 * @returns answerValue-answerUnit pair
 */
const extractSingleValueAndUnit = (v) => {
  if (v?.length) {
    const vMatches = v.match(/^(-?(\d+\.)?\d+)(.*)$/);
    if (
      vMatches?.length === 4 &&
      vMatches[1]?.length &&
      Number.isFinite(Number(vMatches[1]))
    ) {
      return {
        answerValue: Number(vMatches[1]),
        answerUnit: vMatches[3]?.length ? vMatches[3] : null,
      };
    }
  }

  return null;
};

/**
 * Converts a single raw answer into a list of structured item/s, depending on whether
 * the answer is simple(contains 1 value) or complex(contains multiple dimensions).
 *
 * Multidimensional answers will be split to their dimensions.
 *
 * The output object structure will contain:
 *
 * answerExpectedMeasureTableResult:
 * The UOM row label on the Asset Measure Table where we would look for the target value.
 * For example - Dimensions, Volume, Net Weight, etc.
 * Empty for Custom/Other answers.
 *
 * answerExpectedMeasureTableResultDimension:
 * The Dimension column for complex answers. For example, Dimensions answer has L, W, H
 * separate dimensions. For single-dimension answers, it will be equal to the UOM label.
 *
 * answerValue:
 * Parsed numeric value for the answer.
 *
 * answerUnit:
 * Parsed answer UOM string.
 *
 *
 * @param {String} rawAns Raw answer data from question
 * @param {Object} question
 * @returns Array of answer dimensions in structured form.
 */
const convertRawAnswer = (rawAns, question) => {
  let output = null;

  switch (question.expectedMeasureTableResult) {
    case 'Dimensions': {
      // Dimensions answers are complex - they contain L, W, H dimensions
      // that we first split-out from the answer string, then send one by one
      // to the extractSingleValueAndUnit() to extract each value and unit.
      const dimArray = rawAns.split(', ');
      const dimArrayConverted = dimArray
        ?.map((dim) => {
          if (dim?.length) {
            const dimMatches = dim.match(/^([LWH])=(.*)$/);
            if (dimMatches?.length === 3) {
              return {
                answerExpectedMeasureTableResult:
                  question.expectedMeasureTableResult,
                answerExpectedMeasureTableResultDimension: dimMatches[1],
                ...extractSingleValueAndUnit(dimMatches[2]),
              };
            }
          }
          return null;
        })
        .filter((dim) => dim);
      if (dimArrayConverted?.length) {
        output = dimArrayConverted;
      }
      break;
    }
    case 'Gross W':
    case 'Net W':
    case 'Volume':
    case 'Other':
    case 'Custom': {
      // All other answers for now contain a single dimension, so we just need to
      // extract the value-unit pair.
      output = [
        {
          answerExpectedMeasureTableResult: question.expectedMeasureTableResult,
          answerExpectedMeasureTableResultDimension:
            question.expectedMeasureTableResult,
          ...extractSingleValueAndUnit(rawAns),
        },
      ];
      break;
    }

    default:
      console.error(
        'Unknown answer type',
        question.expectedMeasureTableResult,
        question,
      );
  }
  return output;
};

/**
 * A question can hold the actual answer either in .actualAnswer or .singleMeasurements.
 * Depending on where the answer is, we extract it and put it into an array.
 * An answer item itself can be in various formats and can contain multiple dimensions,
 * but here we deal only with creating a unified list of answers.
 *
 * @param {Object} question
 * @returns Array of raw answers
 */
const getAnswerValuesArray = (question) => {
  let output = [];

  if (![null, ''].includes(question?.actualAnswer ?? null)) {
    // If the answer is kept under .actualAnswer, use this as a single-element array.
    output = [question.actualAnswer];
  } else {
    // If the answers are in .singleMeasurements, extract them from there.
    output = question?.singleMeasurements
      ?.map((m) => m.value ?? null)
      ?.filter((m) => ![null, ''].includes(m));
  }

  return output;
};

/**
 * Iterates through raw answers list and generates a list of structured data items.
 * Complex answers will also be split. For exmaple the Dimension answer will generate 3 separate
 * dimension items - L, W, H.
 *
 * @param {Array} rawAnswersList List of raw answer values from question. Can be in various formats
 * @param {Object} question
 * @returns Array of answer items
 */
const convertRawAnswers = (rawAnswersList, question) => {
  const output = [];

  if (rawAnswersList?.length) {
    rawAnswersList.forEach((rawAns, rawAnsIdx) => {
      const convertedAnswerArray = convertRawAnswer(rawAns, question);
      convertedAnswerArray?.forEach((convAns) => {
        output.push({
          answerIndex: rawAnsIdx,
          ...convAns,
        });
      });
    });
  }

  return output;
};

/**
 * Calculates absolute tolerance value from question config and target value.
 *
 * @param {*} v Target value
 * @param {*} dv deltaV tolerance can be either positive/upper(>0) or negative/lower(<0)
 * @param {*} isPercentage Flag whether dV is an absolute value or a percentage
 * @returns Absolute tolerance value.
 */
const getAbsToleranceValue = (v, dv, isPercentage) => {
  const vNum = Number.isFinite(Number(v)) ? Number(v) : null;
  const dvNum = Number.isFinite(Number(dv)) ? Number(dv) : null;

  let output = vNum;

  if (vNum !== null && dvNum !== null && dvNum !== 0) {
    output = isPercentage ? v + (v * dv) / 100 : v + dv;
  }

  return output;
};

/**
 * For a given answerValueItem(answer dimension), generate a structure that contains:
 *
 * targetValue:
 * Reference answer value from question/asset depending on question setup.
 *
 * targetValueUnit:
 * Reference answer value unit.
 *
 * targetValueMin:
 * Calculated absolute lower tolerance value.
 *
 * targetValueMax:
 * Calculated absolute upper tolerance value.
 *
 * @param {Object} question
 * @param {Object} inspection
 * @param {Object} answerValueItem
 * @returns Target value data structure
 */
const getTargetValueData = (question, inspection, answerValueItem) => {
  const output = {
    targetValue: null,
    targetValueUnit: null,
    targetValueMin: null,
    targetValueMax: null,
  };

  const inspectionAsset =
    inspection?.inProgressAssetSnapshot ?? inspection?.asset ?? null;

  if (
    ['Dimensions', 'Gross W', 'Net W', 'Volume'].includes(
      answerValueItem.answerExpectedMeasureTableResult,
    )
  ) {
    const mtUomIdx =
      inspectionAsset?.measureTable?.columnValues?.UOM?.findIndex(
        (_uom) => _uom && _uom === question?.expectedBarcodeUOM,
      );
    if (mtUomIdx > -1) {
      if (answerValueItem.answerExpectedMeasureTableResult === 'Dimensions') {
        output.targetValue =
          inspectionAsset?.measureTable?.columnValues?.[
            answerValueItem.answerExpectedMeasureTableResultDimension
          ]?.[mtUomIdx];
        output.targetValueUnit =
          inspectionAsset?.measureTable?.columnValues?.Unit?.[mtUomIdx];
      } else if (
        answerValueItem.answerExpectedMeasureTableResult === 'Gross W'
      ) {
        output.targetValue =
          inspectionAsset?.measureTable?.columnValues?.['Gross W']?.[mtUomIdx];
        output.targetValueUnit =
          inspectionAsset?.measureTable?.columnValues?.['W Unit']?.[mtUomIdx];
      } else if (answerValueItem.answerExpectedMeasureTableResult === 'Net W') {
        output.targetValue =
          inspectionAsset?.measureTable?.columnValues?.['Net W']?.[mtUomIdx];
        output.targetValueUnit =
          inspectionAsset?.measureTable?.columnValues?.['W Unit']?.[mtUomIdx];
      } else if (
        answerValueItem.answerExpectedMeasureTableResult === 'Volume'
      ) {
        output.targetValue =
          inspectionAsset?.measureTable?.columnValues?.['Volume']?.[mtUomIdx];
        output.targetValueUnit =
          inspectionAsset?.measureTable?.columnValues?.['V Unit']?.[mtUomIdx];
      }
    }
  } else if (answerValueItem.answerExpectedMeasureTableResult === 'Other') {
    if (question?.otherMeasure?.length) {
      const cm = inspectionAsset?.customMeasurements?.find(
        (m) => m?.measure === question.otherMeasure,
      );
      output.targetValue = Number.isFinite(Number(cm?.value))
        ? Number(cm.value)
        : null;
      output.targetValueUnit = cm?.unit?.length ? cm?.unit : null;
    }
  } else if (answerValueItem.answerExpectedMeasureTableResult === 'Custom') {
    output.targetValue = Number.isFinite(
      Number(question?.customExpectedMeasureValue),
    )
      ? Number(question.customExpectedMeasureValue)
      : null;
    output.targetValueUnit = question.customExpectedUom?.label?.length
      ? question.customExpectedUom?.label
      : null;
  }

  if (Number.isFinite(output.targetValue)) {
    output.targetValueMin = getAbsToleranceValue(
      output.targetValue,
      -question?.lowerToleranceValue,
      question?.lowerToleranceRule !== 'Exact',
    );
    output.targetValueMax = getAbsToleranceValue(
      output.targetValue,
      question?.upperToleranceValue,
      question?.upperToleranceRule !== 'Exact',
    );
  }

  return output;
};

/**
 * Calculate answer deviation against the target value and tolerances.
 *
 * The returned structure contains:
 *
 * dAbsolute:
 * Absolute deviation
 *
 * dPercentage:
 * Deviation in percentage relative to the target value
 *
 * diffMsg:
 * Deviation status message, if calculation is successful - defect message.
 *
 * @param {Object} answerValueItem
 * @param {Object} targetValueItem
 * @returns Deviation data structure
 */
const getAnswerDeviation = (answerValueItem, targetValueItem) => {
  const output = {
    dAbsolute: null,
    dPercentage: null,
    diffMsg: null,
    errMsg: null,
    exceedsTolerance: false,
  };

  const { answerUnit, answerValue } = answerValueItem;
  const { targetValue, targetValueMax, targetValueMin, targetValueUnit } =
    targetValueItem;

  // Validate params and bail out if we cannot calculate the deviation.
  if (answerUnit !== targetValueUnit) output.errMsg = 'Inconsistent UOM';
  else if (!Number.isFinite(answerValue))
    output.errMsg = 'Missing answer value';
  else if (!Number.isFinite(targetValue))
    output.errMsg = 'Missing target value';

  if (Number.isFinite(answerValue) && Number.isFinite(targetValue)) {
    // Set decimal precision based on what the answer and target value precision is, with a minimum of 2
    const dPrecision = Math.max(
      new Decimal(answerValue).dp(),
      new Decimal(targetValue).dp(),
      2,
    );

    // Calculate absolute deviation
    output.dAbsolute =
      answerValue !== targetValue
        ? new Decimal(answerValue)
            .sub(new Decimal(targetValue))
            .toDP(dPrecision)
            .toNumber()
        : 0;

    // Calculate percentage deviation if we have a non-zero target value.
    if (targetValue !== 0)
      output.dPercentage =
        Number.isFinite(output.dAbsolute) && output.dAbsolute !== 0
          ? new Decimal(output.dAbsolute)
              .div(new Decimal(targetValue))
              .mul(new Decimal(100))
              .toDP(dPrecision)
              .toNumber()
          : 0;

    // Add lower/upper tolerance defect message
    if (Number.isFinite(answerValue)) {
      if (Number.isFinite(targetValueMax) && answerValue > targetValueMax) {
        output.exceedsTolerance = true;
        output.diffMsg = SYSTEM_DEFECTS.UPPER_TOLERANCE;
      } else if (Number.isFinite(answerValue) && answerValue < targetValueMin) {
        output.exceedsTolerance = true;
        output.diffMsg = SYSTEM_DEFECTS.LOWER_TOLERANCE;
      }
    }
  }

  return output;
};

/**
 * - Get actual answer(s) and convert them to an array of { label: index/W/H/L, value, unit }
 * - Get target value(inverse - get target value for actual answer - for example - dimensions call this 3 times)
 * - Get target unit
 * - Get absolute lower and upper tolerance
 * - Get absolute and relative difference between the upper/lower tolerance and the actual answer for each answer
 * - Augment array object above
 * - Render in tabular or single data depending on whether single measurement or multiple(or dimensions).
 */

export const AnswerDetailValuesFunctions = {
  getAnswerValuesArray,
  convertRawAnswers,
  getTargetValueData,
  getAnswerDeviation,
};
