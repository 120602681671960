import request from 'lib/request';

export const uploadCSV = function uploadCSV(file) {
  var formData = new FormData();
  formData.append('file', file);

  return request
    .post('/assets/parse-measure-table', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (res && res.measureTable) {
        return res;
      }
      throw new Error('Error Parsing this file');
    })
    .catch((err) => {
      if (err) {
        return {
          errors: err.message,
        };
      }
      return null;
    });
};
