import QuestionGroup from 'lib/components/question-group/QuestionGroup';

import { memo } from 'react';
import { entityToSelectOption } from '../../dataTransform';

import _isEqual from 'lodash/isEqual';

import './InspectionPlansForm.scss';

import { DEPENDENCY } from 'config/questionOptions';
import { formatQuestionCriteria } from 'lib/dataTransform';
import './InspectionPlansForm.scss';

const InspectionPlansFormNewQuestion = memo(
  ({ vm, gIndex, qIndex }) => {
    const group = vm?.planForm?.questionGroups?.length
      ? vm?.planForm?.questionGroups[gIndex]
      : null;
    const question = group?.questions?.length ? group.questions[qIndex] : null;
    if (!(vm?.planForm && group && question)) {
      return null;
    }

    // XXX: Debug, print overridden/custom q
    (question.isCustom || question.isOverridden) &&
      console.debug('cq/oq', question);

    // TODO: Refactor along with error model
    const questionHasErrors = (data) =>
      !!Object.keys(data || {})
        .map((key) =>
          Array.isArray(data[key])
            ? data[key].map((i) => i.errors || [])
            : data[key]?.errors || [],
        )
        .flat(2).length;

    const isQuestionHasErrors = questionHasErrors(question);

    const { doOpenDefectsModal } = vm.modals;

    // TODO: Memoize
    const canMoveQuestion = (dir) => {
      const rslt = vm.findMoveQuestionAllowedIndex(gIndex, qIndex, dir);
      return Number.isFinite(rslt);
    };

    const dependentQuestions = vm.getDependentQuestions(gIndex, qIndex);

    const canDeleteQuestion = question.isCustom && !dependentQuestions?.length;

    const possibleDependencies = group.questions
      .filter((_q, _qIndex) => _qIndex < qIndex)
      .map((_q, _qIndex) => ({
        value: _qIndex,
        label: `#${_qIndex + 1}`,
      }));

    const dependencyAnswerOptions = vm.getDependencyAnswerOptions(
      gIndex,
      qIndex,
    );

    const getDependentQuestionsNotice = () => {
      if (!group.isCustom) {
        return 'You must unlink the question group before changing answer type';
      }

      if (dependentQuestions?.length) {
        const isMany = dependentQuestions?.length > 1;
        const qStrList = dependentQuestions
          .map((_q) => `#${_q.order + 1}`)
          .join(', ');

        return `Question${isMany ? 's' : ''} ${qStrList} ${
          isMany ? 'are' : 'is'
        } dependent on this answer type. Please remove the dependenc${
          isMany ? 'ies' : 'y'
        } before changing this value.`;
      }

      return null;
    };

    const getDependencyDisabledNotice = () => {
      if (!group.isCustom) {
        return 'You must unlink the question group before changing dependency';
      }

      return null;
    };

    const answerOptions = vm.planAnswers?.map(
      entityToSelectOption('name', 'id'),
    );

    const isDocumentListChanged = () => {
      const initDocs = question?.initial?.documents;
      const docs = question?.documents;
      if (!docs?.length) {
        return false;
      } else if (initDocs?.length !== docs?.length) {
        return true;
      }

      return docs?.some((_d, _dIndex) => {
        const f = _d?.file;
        const initF = initDocs[_dIndex]?.file;

        if (!f?.id || !f?.url) return true;

        if (
          initF?.id !== f?.id ||
          initF?.url !== f?.url ||
          initDocs[_dIndex]?.value !== _d?.value
        )
          return true;

        return false;
      });
    };

    const questionOptions = (isDetail) => {
      if (isDetail) {
        return vm.questionOptions;
      } else {
        return {
          ...vm.questionOptions,
          isDocumentListChanged: isDocumentListChanged(),
          possibleDependencies,
          answerOptions,
          dependencyAnswerOptions,
          defaultPlanAql: vm.planForm?.aqlLevel.value?.label,
          otherMeasureOptions: vm.assetCustomMeasures,
        };
      }
    };

    // XXX: Just translated old implementation, looks too complex and can be greatly optimized
    const transformQuestionDependencyCriteriaField = (q, isDetail) => {
      const qDepAction =
        q.dependencyAction?.value?.value ?? q.dependencyAction?.value ?? null;
      const qDepCriteria = q.dependencyCriteria ?? null;
      const qDepOrder =
        q.dependencyQuestion?.value?.value ??
        q.dependencyQuestion?.value ??
        null;
      const qDepAnswerOptionOrder = q.dependencyAnswerOptionOrder ?? null;

      // const qDepCriteria = 'specific_answer';
      // const qDepAnswerOptionOrder = '1';

      let newDepCriteriaValue = null;

      if (qDepAction !== null && qDepOrder !== null && qDepCriteria !== null) {
        if (
          qDepCriteria === DEPENDENCY.SPECIFIC_ANSWER &&
          qDepAnswerOptionOrder !== null
        ) {
          const qDependency = group.questions.find(
            (_q, _qIndex) => _qIndex === qDepOrder,
          );

          const qDependencyAnswerId =
            qDependency?.answer?.value?.value ??
            qDependency?.answer?.value ??
            null;
          if (qDependencyAnswerId !== null) {
            const qDepAnswerOptions = vm.planAnswers?.find(
              (_pa) => Number(_pa.id) === Number(qDependencyAnswerId),
            )?.options;
            const selectedAnswerOption = qDepAnswerOptions?.find(
              (_ao) => _ao.order === Number(qDepAnswerOptionOrder),
            );
            newDepCriteriaValue = {
              label: formatQuestionCriteria(
                qDepCriteria,
                selectedAnswerOption?.label,
              ),
              value: Number(qDepAnswerOptionOrder),
            };
          }
        }
      }

      if (newDepCriteriaValue === null && qDepCriteria !== null) {
        newDepCriteriaValue = {
          label: formatQuestionCriteria(qDepCriteria),
          value: qDepCriteria,
        };
      }

      // XXX: If we can reliably find the option in the static list
      //      we might not need all the above generation.
      const foundSelectOption = newDepCriteriaValue
        ? dependencyAnswerOptions.find(
            (_ao) => _ao.value === newDepCriteriaValue?.value,
          )
        : null;

      if (foundSelectOption !== null) newDepCriteriaValue = foundSelectOption;

      const newQ = {
        ...q,
        dependencyCriteria: isDetail
          ? newDepCriteriaValue
          : {
              value: newDepCriteriaValue,
              errors: [],
            },
      };

      return newQ;
    };

    const transformQuestionAttrs = (q, isDetail) => {
      if (isDetail) {
        const { aqlMajor, aqlMinor, aqlFunctional, ...newQ } = {
          ...q,

          // TODO: Refactor detail view component to rely on data model, not form, but conflicts with other components
          // Workaround to show aql labels in Detail View
          majorDefect: q.aqlMajor,
          minorDefect: q.aqlMinor,
          functionalDefect: q.aqlFunctional,

          // TODO: Append useDependency in data-to-form transformation, along with other use[Stuff], so we are compliant with legacy model
          // Workaround to force show dependency in view mode
          useDependency: !!q.dependencyAction,

          tools: q.tools?.map((_t) => ({ name: _t })),
          assetReferenceDocNames: q.assetReferenceDocNames?.map((_d) => ({
            name: _d,
          })),
          lowerToleranceValue: q?.lowerTolerance,
          upperToleranceValue: q?.upperTolerance,

          useDynamic: !!q.dynamicAction?.value,
        };
        return newQ;
      } else {
        return q;
      }
    };

    // TODO: Memoize, these transformation should become less and less needed as we moved to the original data model
    const getTransformedQ = (q, isDetail) => {
      let rslt = q;
      rslt = transformQuestionDependencyCriteriaField(rslt, isDetail);
      rslt = transformQuestionAttrs(rslt, isDetail);

      return rslt;
    };

    // Override legacy dependency label that depended on the complex logic above
    const formatDependencyLabel = (q) => {
      const actionLabel = q.dependencyAction?.label;
      const depOrderLabel = q?.dependencyQuestion?.label;
      const criteriaLabel = q?.dependencyCriteria?.label;

      return actionLabel?.length &&
        depOrderLabel?.length &&
        criteriaLabel?.length
        ? `${actionLabel} ${depOrderLabel} ${criteriaLabel}`
        : null;
    };

    const formatExpectedResult = (q) => {
      let rslt = null;
      const emtrVal = q?.expectedMeasureTableResult?.value;

      if (emtrVal === 'Custom')
        rslt = `${q.customExpectedMeasureValue} ${q.customExpectedUom?.label}`;
      else if (emtrVal === 'Other') rslt = q.otherMeasure.value;
      else
        rslt = `${q.expectedMeasureTableResult.value}${
          q.expectedBarcodeUOM ? ` (${q.expectedBarcodeUOM.label})` : ''
        }`;

      return rslt;
    };

    return (
      <>
        {question.isCustom || question.isOverridden ? (
          <QuestionGroup.QuestionForm
            data={getTransformedQ(question)}
            language={vm.language}
            editVariant={question.isCustom ? 'none' : 'checkbox'}
            questionOptions={questionOptions()}
            isAnswerTypeDisabled={getDependentQuestionsNotice()} // XXX: calc here
            isDependencyDisabled={getDependencyDisabledNotice()} // XXX: calc here
            planOptions={vm.planOptions}
            // TODO: Dependencies are now separate from the question templates,
            //       so how do we handle "restore" - we don't keep the original dependency
            //       data in the overridden question - only a body.
            isUndoEditDisabled={!question.isOverridden}
            onEditChange={() => vm.doRelinkQuestion(gIndex, qIndex)}
            onAddTool={() => vm.doAddToolToQuestion(gIndex, qIndex)}
            onRemoveTool={(tIndex) =>
              vm.doRemoveToolFromQuestion(gIndex, qIndex, tIndex)
            }
            onAddAssetReferenceDocument={() =>
              vm.doAddAssetRefDocToQuestion(gIndex, qIndex)
            }
            onRemoveAssetReferenceDocument={(dIndex) =>
              vm.doRemoveAssetRefDocToQuestion(gIndex, qIndex, dIndex)
            }
            onRemoveDefect={(defect) =>
              vm.doRemoveDefect(gIndex, qIndex, defect)
            }
            hasErrors={isQuestionHasErrors}
            onAddDocuments={(files) => {
              vm.doAddQuestionDocuments(gIndex, qIndex, files);
            }}
            onRemoveDocument={(dIndex) =>
              vm.doRemoveQuestionDocument(gIndex, qIndex, dIndex)
            }
            onResetDocuments={() => vm.doResetQuestionDocuments(gIndex, qIndex)}
            isMoveUpDisabled={!canMoveQuestion('up')}
            isMoveDownDisabled={!canMoveQuestion('down')}
            onMoveUp={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'up')}
            onMoveTop={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'top')}
            onMoveDown={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'down')}
            onMoveBottom={() =>
              vm.doMoveQuestionInGroup(gIndex, qIndex, 'bottom')
            }
            isDeleteDisabled={!canDeleteQuestion}
            onDelete={() => vm.doDeleteQuestionInGroup(gIndex, qIndex)}
            onEditDefect={() => doOpenDefectsModal(gIndex, qIndex)}
            onChange={(k, v, i) =>
              vm.onChangeQuestionInput(gIndex, qIndex, k, v, i)
            }
            showHeaderDependency={true}
          />
        ) : (
          // TODO: When generating the plan form, maybe generate edit form for custom/overridden OR detail view for unedited. Then edit calls generation of q form and revert question calls generation for detail view
          <QuestionGroup.Question
            language={vm.language}
            id={`#${question.order + 1}`}
            data={getTransformedQ(question, true)}
            isMoveUpDisabled={!canMoveQuestion('up')}
            isMoveDownDisabled={!canMoveQuestion('down')}
            onMoveUp={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'up')}
            onMoveTop={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'top')}
            onMoveDown={() => vm.doMoveQuestionInGroup(gIndex, qIndex, 'down')}
            onMoveBottom={() =>
              vm.doMoveQuestionInGroup(gIndex, qIndex, 'bottom')
            }
            questionOptions={questionOptions(true)}
            planOptions={vm.planOptions}
            editVariant='checkbox'
            showHeaderDependency={true}
            onEditChange={() => vm.doUnlinkQuestion(gIndex, qIndex)}
            dependencyFormatter={(q) => formatDependencyLabel(q)}
            expectedResultFormatter={(q) => formatExpectedResult(q)}
          />
        )}
      </>
    );
  },
  (a, b) => _isEqual(a.vm, b.vm),
);
export default InspectionPlansFormNewQuestion;
