import React from 'react';
import './ProgressBar.scss';

function ProgressBar({ percent = 0 }) {
  const percentArray = Array.isArray(percent) && percent?.length ? [...percent] : [percent];

  return (
    <div className='progressBar'>
      {percentArray?.map((v, i) => (
        <div
          key={i}
          className='selected'
          style={{
            width: `${v}%`,
          }}
        />
      ))}
    </div>
  );
}

export default ProgressBar;
