const ROLES = {
  ASSET_OWNER: '1',
  INTERNAL_SOURCE_OWNER: '2',
  EXTERNAL_SOURCE_OWNER: '3',
  INSPECTOR: '4',
  COORDINATOR: '5',
  CLIENT_ADMIN: '7',
  QM_ADMIN: '8',
  VENDOR: '9',
  ASSET_MANAGER: '10',
  SOURCE_MANAGER: '11',
};

export default ROLES;
