import ROLES from 'config/roles';

export const DASHBOARD_METRICS = {
  ASSETS_MISSING_PLANS: 'asset_missing_plans',
  PASS_RATE_30_DAYS: 'pass_rate',
  UNASSIGNED_INSPECTIONS: 'unassigned_inspections',
  PENDING_USAGE_DECISIONS: 'pending_usage_decisions',
  PENDING_INSPECTIONS: 'pending_inspections',
  NEW_SOURCES: 'sources',
  NEW_ASSETS: 'assets',
  NEW_INSPECTION_PLANS: 'inspection_plans',
  NEW_WORK_OBJECTS: 'work_objects',
};
export const METRICS_VIEW_CONFIG = {
  [DASHBOARD_METRICS.ASSETS_MISSING_PLANS]: [
    ROLES.CLIENT_ADMIN,
    ROLES.ASSET_OWNER,
    ROLES.COORDINATOR,
  ],
  [DASHBOARD_METRICS.PASS_RATE_30_DAYS]: [
    ROLES.CLIENT_ADMIN,
    ROLES.ASSET_MANAGER,
    ROLES.SOURCE_MANAGER,
    ROLES.COORDINATOR,
    ROLES.ASSET_OWNER,
    ROLES.EXTERNAL_SOURCE_OWNER,
    ROLES.INTERNAL_SOURCE_OWNER,
    ROLES.VENDOR,
  ],
  [DASHBOARD_METRICS.UNASSIGNED_INSPECTIONS]: [
    ROLES.CLIENT_ADMIN,
    ROLES.COORDINATOR,
    ROLES.EXTERNAL_SOURCE_OWNER,
    ROLES.INTERNAL_SOURCE_OWNER,
    ROLES.VENDOR,
  ],
  [DASHBOARD_METRICS.PENDING_INSPECTIONS]: [ROLES.ASSET_OWNER],
  [DASHBOARD_METRICS.PENDING_USAGE_DECISIONS]: [
    ROLES.CLIENT_ADMIN,
    ROLES.ASSET_MANAGER,
    ROLES.SOURCE_MANAGER,
    ROLES.COORDINATOR,
    ROLES.ASSET_OWNER,
    ROLES.EXTERNAL_SOURCE_OWNER,
    ROLES.INTERNAL_SOURCE_OWNER,
    ROLES.VENDOR,
  ],
  [DASHBOARD_METRICS.NEW_SOURCES]: [ROLES.QM_ADMIN],
  [DASHBOARD_METRICS.NEW_ASSETS]: [ROLES.QM_ADMIN],
  [DASHBOARD_METRICS.NEW_INSPECTION_PLANS]: [ROLES.QM_ADMIN],
  [DASHBOARD_METRICS.NEW_WORK_OBJECTS]: [ROLES.QM_ADMIN],
};

export const DASHBOARD_TABLES = {
  UPCOMING_INSPECTIONS: 'UPCOMING_INSPECTIONS',
  LATEST_INSPECTIONS: 'LATEST_INSPECTIONS',
  TIME_PER_UNIT_INSPECTIONS: 'TIME_PER_UNIT_INSPECTIONS',
  PENDING_USAGE_DECISIONS: 'PENDING_USAGE_DECISIONS',
  ASSETS_MISSING_PLANS: 'ASSETS_MISSING_PLANS',
};

export const TABLES_VIEW_CONFIG = {
  [DASHBOARD_TABLES.UPCOMING_INSPECTIONS]: [
    ROLES.CLIENT_ADMIN,
    ROLES.ASSET_MANAGER,
    ROLES.SOURCE_MANAGER,
    ROLES.ASSET_OWNER,
    ROLES.EXTERNAL_SOURCE_OWNER,
    ROLES.INTERNAL_SOURCE_OWNER,
    ROLES.COORDINATOR,
    ROLES.VENDOR,
    ROLES.INSPECTOR,
  ],
  [DASHBOARD_TABLES.LATEST_INSPECTIONS]: [
    ROLES.QM_ADMIN,
    ROLES.CLIENT_ADMIN,
    ROLES.ASSET_MANAGER,
    ROLES.SOURCE_MANAGER,
    ROLES.EXTERNAL_SOURCE_OWNER,
    ROLES.INTERNAL_SOURCE_OWNER,
    ROLES.VENDOR,
    ROLES.ASSET_OWNER,
    ROLES.COORDINATOR,
    ROLES.INSPECTOR,
  ],
  [DASHBOARD_TABLES.PENDING_USAGE_DECISIONS]: [ROLES.ASSET_OWNER],
  [DASHBOARD_TABLES.ASSETS_MISSING_PLANS]: [ROLES.ASSET_OWNER],
  [DASHBOARD_TABLES.TIME_PER_UNIT_INSPECTIONS]: [ROLES.INSPECTOR],
};
