import React from 'react';
import DBIcon from 'assets/images/database-done.svg';
import FileIcon from 'assets/images/clear-file.svg';
import ImportError from '../import-error/ImportError';
import File from '../file/File';
import { formatDateTime } from 'lib/dateHelpers';

import './ImportResult.scss';

export default function ImportResult({ list, errorValidations, columnLabels }) {
  return (
    <div className='qm-import-result done' data-testid='ImportResult'>
      {list.file && (
        <div className='file-download'>
          <a href={list.file.location}>
            <File name={list.file.originalname} type='text/csv' />
          </a>
        </div>
      )}
      {list.done && (
        <div className='success' data-testid='ImportResult.successContainer'>
          <div className='result-icon'>
            <img
              src={DBIcon}
              alt='success'
              data-testid='ImportResult.successIcon'
            />
          </div>
          <span>
            {list.done} {list.done > 1 ? 'records' : 'record'} imported
            successfully
          </span>
        </div>
      )}
      {list.hasErrors && (
        <div className='error' data-testid='ImportResult.errorContainer'>
          <div className='result-icon'>
            <img
              src={FileIcon}
              alt='error'
              data-testid='ImportResult.errorIcon'
            />
          </div>
          <div className='messages' data-testid='ImportResult.errorMessages'>
            <p>File import failed, please fix errors and try again</p>
            {list.globalError && (
              <ImportError
                error={list.globalError}
                errorGenerator={errorValidations}
              />
            )}
            {list.errors
              .sort((a, b) => a.line - b.line)
              .map((error, index) => (
                <ImportError
                  key={index}
                  error={error}
                  errorGenerator={errorValidations}
                  columnLabels={columnLabels}
                />
              ))}
          </div>
        </div>
      )}
      {list.timestamps && (
        <div
          className='settings-timestamps'
          data-testid='ImportResult.timestampContainer'
        >
          Import date:{' '}
          {formatDateTime(
            list.timestamps.createdAt || list.timestamps.updatedAt,
          )}
        </div>
      )}
    </div>
  );
}
