import React, { useContext, useEffect, useState } from 'react';
import Input from 'lib/components/input/Input';
import Select from 'lib/components/select/Select';
import {
  MeasurementContext,
  MEASUREMENT_ACTIONS,
} from 'modules/measurement-table/MeasurementTableContext';
import { ReactComponent as AlertIcon } from '../../assets/images/alert-circle.svg';
import classnames from 'classnames';

function MeasurementTableField({ row, col, value, props }) {
  const { type, options, validation } = props;

  const [, dispatch] = useContext(MeasurementContext);
  const [selectedValue, setSelectedValue] = useState();
  const [textValue, setText] = useState(value);

  useEffect(() => {
    if (options && type === 'select') {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].value === value) {
          setSelectedValue(options[i]);
          break;
        }
      }
    } else {
      setText(value && value !== ' ' ? value : '');
    }
  }, [options, value]);

  function onChange(e) {
    const { value } = e.target;
    // if we don't have validation, or validation is passed update field
    if (!validation || String(value).match(validation) !== null) {
      // Hack to prevent jumping cursor to the end
      setText(value);
      dispatch({
        type: MEASUREMENT_ACTIONS.SET_TABLE_VALUE,
        data: {
          row,
          col,
          value,
        },
      });
    }
  }

  function onSelectChange(e) {
    dispatch({
      type: MEASUREMENT_ACTIONS.SET_TABLE_VALUE,
      data: {
        row,
        col,
        value: e.value,
      },
    });
  }

  return (
    <div
      className={classnames('input-placeholder', {
        'error-field': value === null,
      })}
    >
      {type === 'select' ? (
        <Select
          options={options}
          menuPortalTarget={document.body}
          onChange={onSelectChange}
          isSearchable={false}
          placeholder='Select'
          menuPlacement='auto'
          value={selectedValue}
        />
      ) : (
        <Input onChange={onChange} value={textValue} />
      )}
      {value === null && (
        <div className='error-icon'>
          <AlertIcon />
        </div>
      )}
    </div>
  );
}

export default MeasurementTableField;
