import ROLES from 'config/roles';

export const VIEW_ALL_ROLES = [
  ROLES.ASSET_OWNER,
  ROLES.CLIENT_ADMIN,
  ROLES.COORDINATOR,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
  ROLES.QM_ADMIN,
];

export const ADD_WORKFLOW_ROLES = [
  ROLES.QM_ADMIN,
  ROLES.CLIENT_ADMIN,
  ROLES.ASSET_MANAGER,
  ROLES.SOURCE_MANAGER,
];
