import * as yup from 'yup';
import {
  validator,
  requiredFormObject,
  requiredFormString,
  inputShape,
} from 'lib/validator';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';
import { API_ERROR_CODES, ERROR_TEMPLATES } from 'config/errorCodes';
import { INSPECTION_TARGET, INSPECTOR_TYPE } from 'config/inspectionConfig';
import { INSPECTION_RESULT } from 'config/inspectionStatus';

const inspectionsThirdPartyFormValidationSchema = yup.object().shape({
  inspectorType: requiredFormString(),
  inspector: yup.object().when(['inspectorType'], {
    is: (type) => type.value === INSPECTOR_TYPE.INTERNAL,
    then: inputShape(),
  }),
  thirdPartyInspectorName: yup.object().when(['inspectorType'], {
    is: (type) => type.value === INSPECTOR_TYPE.EXTERNAL,
    then: inputShape(
      yup
        .string()
        .nullable()
        .max(255, 'Inspector name must be at most 255 characters'),
    ),
  }),
  thirdPartyInspectingCompany: yup.object().when(['inspectorType'], {
    is: (type) => type.value === INSPECTOR_TYPE.EXTERNAL,
    then: inputShape(
      yup
        .string()
        .nullable()
        .max(255, 'Inspecting company must be at most 255 characters'),
    ),
  }),
  inspectionTarget: requiredFormString(),
  asset: yup.object().when(['inspectionTarget'], {
    is: (type) => type.value === INSPECTION_TARGET.ASSET,
    then: requiredFormObject(),
  }),

  documents: yup.object().shape({
    list: yup.array().of(
      inputShape(
        yup
          .string()
          .label('Document name')
          .max(50)
          .matches(/^([a-zA-Z0-9 ]+)$/, ERROR_TEMPLATES.alphanumWithSpaces)
          .required(),
      ),
    ),
  }),
  source: requiredFormObject(),
  inspectionDate: inputShape(),
  inspectionWindow: inputShape(
    yup
      .array()
      .test(
        'inspectionWindow',
        'Invalid date range',
        (val) => !!val[0] === !!val[1],
      ),
  ),
  validUntil: yup.object().when(['inspectionDate', 'inspectionResult'], {
    is: (date, result) => !!date.value && result === INSPECTION_RESULT.PASSED,
    then: inputShape(
      yup
        .string()
        .test(
          'minValidDate',
          'Valid until cannot be earlier than inspection date',
          (value, context) =>
            !value ||
            new Date(value) >=
              new Date(context.from[1].value.inspectionDate.value),
        ),
    ),
  }),
  inspectionResult: inputShape(yup.string().nullable()),
  inspectionReport: inputShape(),
  inspectionType: requiredFormObject(),
});

const apiPathToFormPath = (path) => path.replace('result', 'inspectionResult');

const inspectionThirdPartyErrors = {
  ...API_ERROR_CODES,
  'inspections.planAndTypeDoesNotMatch':
    'Inspection plan does not match selected inspection type',
};

export const processThirdPartyInspectionFormAPIError = makeAPIBodyErrorProcessor(
  {
    errorCodes: inspectionThirdPartyErrors,
    transformPath: apiPathToFormPath,
  },
);

const inspectionsThirdPartyFormValidator = (state) =>
  validator(state, inspectionsThirdPartyFormValidationSchema);

export default inspectionsThirdPartyFormValidator;
