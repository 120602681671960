import Select from '../select/Select';
import testId from './fixtures/testid';

function DependencyWidget({
  data,
  isInModal,
  isDisabled,
  questionOptions,
  onChange,
}) {
  return (
    <>
      <div className='form-label'>Dependency</div>
      <div className='flex'>
        <div>
          <Select
            data-testid={testId.dependencyActionSelect}
            isInModal={isInModal}
            value={data.dependencyAction.value}
            isDisabled={isDisabled}
            onChange={(value) => onChange('dependencyAction', value)}
            options={questionOptions.dependencyAction}
            className='dependency-action'
          />
        </div>
        {data.dependencyAction?.value?.value !== false && (
          <>
            <div className='dependency-question-container'>
              <Select
                data-testid={testId.dependencyQuestionSelect}
                isInModal={isInModal}
                className='dependency-question'
                value={data.dependencyQuestion.value}
                isDisabled={isDisabled}
                errors={data.dependencyQuestion.errors}
                options={questionOptions.possibleDependencies}
                onChange={(value) => onChange('dependencyQuestion', value)}
              />
            </div>
            <div>
              <Select
                data-testid={testId.dependencyCriteriaSelect}
                isInModal={isInModal}
                className='dependency-criteria'
                value={data.dependencyCriteria?.value}
                isDisabled={isDisabled}
                errors={data.dependencyCriteria?.errors}
                options={questionOptions.dependencyAnswerOptions}
                onChange={(value) => onChange('dependencyCriteria', value)}
              />
            </div>
          </>
        )}
      </div>
      {typeof isDisabled === 'string' && (
        <p className='field-description'>{isDisabled}</p>
      )}
    </>
  );
}

export default DependencyWidget;
