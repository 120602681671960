import { AppContext } from 'App';
import FloatingCard from 'modules/dashboard/components/floating-card/FloatingCard';
import { useContext, useMemo } from 'react';

const SystemSettingsPageUsersSummary = ({ usersData }) => {
  const {
    appState: { currentUser },
  } = useContext(AppContext);

  const summaryStats = useMemo(() => {

    return (usersData && currentUser?.id) ? {
      usersCount: usersData?.length ?? null,
      jobsCount: usersData?.reduce((jc, usr) => (jc += usr?.activeJobs?.length ?? 0), 0) ?? null,
      actionsCount: usersData?.filter((usr) => usr.userId !== currentUser.id)?.reduce((ac, usr) =>
        ac += usr?.latestActions?.filter((la) =>
          (Date.now() - Date.parse(la.performedAt)) < 600e3)?.length ?? 0
        , 0) ?? null,
    } : null;
  }, [JSON.stringify(usersData), currentUser?.id]);

  return (
    <div className='system-settings-users-summary'>
      <FloatingCard
        value={summaryStats?.usersCount ?? 'N/A'}
        description="Active Users"
      />
      <FloatingCard
        value={summaryStats?.actionsCount ?? 'N/A'}
        description="Actions in last 10 min."
      />
      <FloatingCard
        value={summaryStats?.jobsCount ?? 'N/A'}
        description="Active Jobs"
      />
    </div>
  );
}

export { SystemSettingsPageUsersSummary };
