import PropTypes from 'prop-types';
import Tooltip from 'lib/components/tooltip/Tooltip';

import { ReactComponent as AddIcon } from 'assets/images/plus-solid.svg';

import classNames from 'classnames';
import './TimelineNewSubitem.scss';

const TimelineNewSubitem = (props) => {
  return (
    <div className={classNames('timeline-new-subitem', props.className)}>
      <Tooltip placement='top' overlay={props.label}>
        <button
          type='button'
          className='timeline-new-subitem-button'
          onClick={props.onClick}
        >
          <AddIcon className='new-subitem-icon' />
        </button>
      </Tooltip>
    </div>
  );
};

TimelineNewSubitem.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default TimelineNewSubitem;
