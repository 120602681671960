import Table from 'lib/components/table/Table';
import SettingsTypeItem from 'modules/settings/components/settings-type-item/SettingsTypeItem';
import SettingsTypesForm from 'modules/settings/components/settings-types-form/SettingsTypesForm';

import './SettingsAssetTypesTable.scss';

const SettingsAssetTypesTable = ({ userClicksOnRow, ...props }) => {
  const renderAssetTypeCell = (record) => {
    if (props.editField?.id === record.id) {
      return (
        <SettingsTypesForm
          cancelForm={props.clearEdit}
          addType={props.editField}
          onChange={props.onChange}
          onSubmit={props.editAssetType}
        />
      );
    } else {
      return (
        <SettingsTypeItem
          setEdit={() => props.setEdit(record.name, record.id)}
          label={record.name}
          editing={props.editField.editing}
          typeName='asset type'
        />
      );
    }
  };

  const settingsAssetTypesTableColumns = [
    {
      title: 'ID',
      headerClassName: 'id-cell',
      sortId: 'id',
      render: (record) => record.id,
    },
    {
      title: 'Asset type',
      headerClassName: 'name-cell',
      sortId: 'name',
      render: (record) => renderAssetTypeCell(record),
    },
  ];

  return (
    <Table
      columns={settingsAssetTypesTableColumns}
      className='settings-asset-types-table'
      {...props}
    />
  );
};

export default SettingsAssetTypesTable;
