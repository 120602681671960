import { DEFAULT_LANGUAGE } from 'config/languages';
import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const SETTINGS_DEFECT_ACTIONS = {
  SET_EDIT_DEFECT: 'SET_EDIT_DEFECT',
  CLEAR_EDIT_DEFECT: 'CLEAR_EDIT_DEFECT',
  USER_CHANGES_INPUT_EDIT: 'USER_CHANGES_INPUT_EDIT',
  USER_CHANGES_INPUT_ADD: 'USER_CHANGES_INPUT_ADD',
  USER_OPENS_INPUT_MODAL: 'USER_OPENS_INPUT_MODAL',
  USER_CANCELS_INPUT_MODAL: 'USER_CANCELS_INPUT_MODAL',
  USER_SETS_LANGUAGE: 'USER_SETS_LANGUAGE',
  APP_STARTS_EXPORTING_DEFECTS: 'APP_STARTS_EXPORTING_DEFECTS',
  APP_FINISHES_EXPORTING_DEFECTS: 'APP_FINISHES_EXPORTING_DEFECTS',
  RESET_STATE: 'RESET_STATE',
};

const getInitialModalState = () => ({
  name: {
    value: [],
    errors: [],
  },
  errors: [],
  isModalOpen: false,
});

const INITIAL_DEFECTS_STATE = {
  editField: {
    name: {
      value: [],
      errors: [],
    },
    id: null,
    editing: false,
    errors: [],
  },
  language: DEFAULT_LANGUAGE,
  addDefect: getInitialModalState(),
  isExportingDefects: false,
};

const SETTINGS_DEFECTS_REDUCER_CONFIG = {
  [SETTINGS_DEFECT_ACTIONS.SET_EDIT_DEFECT]: (state, action) => {
    return {
      ...state,
      editField: {
        name: {
          value: action.payload.name,
          errors: [],
        },
        id: action.payload.id,
        editing: true,
        errors: [],
        isDirty: false,
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.CLEAR_EDIT_DEFECT]: (state) => {
    return {
      ...state,
      editField: {
        name: {
          value: [],
          errors: [],
        },
        id: null,
        editing: false,
        isDirty: false,
        errors: [],
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.USER_CHANGES_INPUT_ADD]: (state, action) => {
    return {
      ...state,
      addDefect: {
        ...state.addDefect,
        name: {
          value: action.payload,
          errors: [],
        },
        isDirty: true,
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.USER_CHANGES_INPUT_EDIT]: (state, action) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        name: {
          value: action.payload,
          errors: [],
        },
        isDirty: true,
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.USER_OPENS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addDefect: {
        ...state.addDefect,
        isModalOpen: true,
        isDirty: false,
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.USER_CANCELS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addDefect: {
        ...state.addDefect,
        name: { value: [], errors: [] },
        errors: [],
        isModalOpen: false,
        isDirty: false,
      },
    };
  },
  [SETTINGS_DEFECT_ACTIONS.USER_SETS_LANGUAGE]: (state, action) => {
    return {
      ...state,
      language: action.payload,
    };
  },
  [SETTINGS_DEFECT_ACTIONS.APP_STARTS_EXPORTING_DEFECTS]: (state) => {
    return {
      ...state,
      isExportingDefects: true,
    };
  },
  [SETTINGS_DEFECT_ACTIONS.APP_FINISHES_EXPORTING_DEFECTS]: (state) => {
    return {
      ...state,
      isExportingDefects: false,
    };
  },
  [SETTINGS_DEFECT_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  SETTINGS_DEFECTS_REDUCER_CONFIG,
  INITIAL_DEFECTS_STATE,
);
