import React from 'react';
import { ReactComponent as PencilIcon } from 'assets/images/pencil-outline.svg';
import Tooltip from 'lib/components/tooltip/Tooltip';
import Button from 'lib/components/button/Button';

import './SettingsTypeItem.scss';

export default function SettingsTypeItem(props) {
  return (
    <div className='settings-type-item'>
      <p data-testid='SettingsTypeItem.label'>{props.label}</p>
      {!props.editing && (
        <Tooltip overlay={`Edit ${props.typeName}`} placement='top'>
          <Button
            type='button'
            className='link-button'
            onClick={props.setEdit}
            data-testid='SettingsTypeItem.editButton'
          >
            <PencilIcon />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
