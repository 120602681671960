import makeReducer from 'lib/makeReducer';

export const COMPANIES_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_STATE = {
  company: null,
  errors: [],
  loading: true,
};

const COMPANIES_DETAIL_REDUCER_CONFIG = {
  [COMPANIES_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      company: action.payload,
      loading: false,
    };
  },
  [COMPANIES_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  COMPANIES_DETAIL_REDUCER_CONFIG,
  INITIAL_STATE,
);
