import { entityToSelectOption } from 'lib/dataTransform';
import { LIST_ACTIONS, makeListReducer } from 'lib/list-helper/makeListReducer';

export const FILTER_ACTIONS = {
  LOAD_FILTERS: 'LOAD_FILTERS',
  USER_SETS_TYPE_FILTER: 'USER_SETS_TYPE_FILTER',
  USER_SETS_INSPECTION_FILTER: 'USER_SETS_INSPECTION_FILTER',
  USER_SETS_INSPECTOR_FILTER: 'USER_SETS_INSPECTOR_FILTER',
  USER_SETS_STATUS_FILTER: 'USER_SETS_STATUS_FILTER',
  USER_SETS_SOURCE_FILTER: 'USER_SETS_SOURCE_FILTER',
  USER_SETS_CITY_FILTER: 'USER_SETS_CITY_FILTER',
  USER_SETS_LAST_USAGE_DECISION_FILTER: 'USER_SETS_LAST_USAGE_DECISION_FILTER',
  USER_SETS_IS_THIRD_PARTY_FILTER: 'USER_SETS_IS_THIRD_PARTY_FILTER',
  USER_SETS_INSPECTION_DATE_FILTER: 'USER_SETS_INSPECTION_DATE_FILTER',
  USER_SETS_INSPECTION_WINDOW_FILTER: 'USER_SETS_INSPECTION_WINDOW_FILTER',
};

export const PAGE_ACTIONS = {
  USER_TOGGLES_INSPECTION: 'USER_TOGGLES_INSPECTION',
  USER_TOGGLES_INSPECTIONS_HEADER: 'USER_TOGGLES_INSPECTIONS_HEADER',

  APP_STARTS_LOADING_INSPECTORS: 'APP_STARTS_LOADING_INSPECTORS',
  APP_LOADS_INSPECTORS: 'APP_LOADS_INSPECTORS',
  USER_OPENS_INSPECTORS_MODAL: 'USER_OPENS_INSPECTORS_MODAL',
  USER_CANCELS_INSPECTORS_MODAL: 'USER_CANCELS_INSPECTORS_MODAL',
  USER_SORTS_INSPECTORS: 'USER_SORTS_INSPECTORS',
  USER_SEARCHES_INSPECTORS: 'USER_SEARCHES_INSPECTORS',
  USER_SETS_INSPECTORS_PAGE: 'USER_SETS_INSPECTORS_PAGE',
  USER_SELECTS_INSPECTOR: 'USER_SELECTS_INSPECTOR',
  USER_ASSIGNS_INSPECTOR: 'USER_ASSIGNS_INSPECTOR',

  USER_OPENS_DATE_MODAL: 'USER_OPENS_DATE_MODAL',
  USER_CANCELS_DATE_MODAL: 'USER_CANCELS_DATE_MODAL',
  USER_CHANGES_INSPECTION_DATE: 'USER_CHANGES_INSPECTION_DATE',

  SET_BULK_UPDATE_RESULT_MODAL: 'SET_BULK_UPDATE_RESULT_MODAL',
  RESET_BULK_UPDATE_RESULT_MODAL: 'RESET_BULK_UPDATE_RESULT_MODAL',

  SET_ERRORS: 'SET_ERRORS',
};

const INITIAL_INSPECTIONS_STATE = {
  errors: [],
  filters: {
    atSource: '',
    target: '',
    inspection: '',
    inspector: '',
    status: '',
    city: '',
    lastUsageDecision: '',
    inspectionDateStart: '',
    inspectionDateEnd: '',
    inspectionWindowStart: '',
    inspectionWindowEnd: '',
    validUntil: '',
    isThirdParty: '',
  },
  filterOptions: {
    atSource: [],
    target: [],
    inspection: [],
    status: [],
    inspector: [],
    city: [],
    lastUsageDecision: [],
    isThirdParty: [],
  },
  selectedInspections: [],
  availableInspectors: {
    list: [],
    isModalOpen: false,
    search: '',
    page: 1,
    pageSize: 10,
    count: false,
    sortOrder: 'ASC',
    sortBy: 'id',
    selectedInspector: null,
    isLoading: true,
  },
  inspectionDate: '',
  isDateModalOpen: false,
  bulkUpdateResultModal: {
    isOpen: false,
    inspectionDate: null,
    selectedInspector: null,
    selectedInspections: null,
  },
};

const INSPECTIONS_REDUCER_CONFIG = {
  [LIST_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    // We need to override the default action
    // in order to clear the selected list on change
    return {
      ...state,
      ...action.payload,
      selectedInspections: [],
      availableInspectors: {
        ...state.availableInspectors,
        selectedInspector: null,
        isModalOpen: false,
      },
      errors: [],
      inspectionDate: '',
      isDateModalOpen: false,
    };
  },
  [PAGE_ACTIONS.SET_ERRORS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      selectedInspections: [],
      availableInspectors: {
        ...state.availableInspectors,
        selectedInspector: null,
        isModalOpen: false,
      },
      inspectionDate: '',
      isDateModalOpen: false,
    };
  },
  [FILTER_ACTIONS.LOAD_FILTERS]: (state, action) => {
    const sourceToSelectOption = (s) =>
      s?.name
        ? {
            label: `${s.name}${s.externalId ? ` (${s.externalId})` : ''}`,
            value: s.id,
          }
        : null;
    const target = (action.payload.types || []).map(
      entityToSelectOption('label', 'id'),
    );
    const status = (action.payload.statuses || []).map(
      entityToSelectOption('label', 'id'),
    );
    const inspection = (action.payload.inspections || []).map(
      entityToSelectOption('name', 'id'),
    );
    const inspector = (action.payload.inspectors || []).map(
      entityToSelectOption('name', 'id'),
    );
    const atSource = (action.payload.atSource || []).map(sourceToSelectOption);
    const city = (action.payload.city || []).map(
      entityToSelectOption('name', 'id'),
    );
    const lastUsageDecision = (action.payload.lastUsageDecision || []).map(
      entityToSelectOption('name', 'name'),
    );
    return {
      ...state,
      filterOptions: {
        target: [{ value: '', label: 'All' }, ...target],
        status: [{ value: '', label: 'All' }, ...status],
        inspection: [{ value: '', label: 'All' }, ...inspection],
        atSource: [{ value: '', label: 'All' }, ...atSource],
        city: [{ value: '', label: 'All' }, ...city],
        lastUsageDecision: [{ value: '', label: 'All' }, ...lastUsageDecision],
        inspector: [
          { value: '', label: 'All' },
          { value: 'unassigned', label: 'Unassigned' },
          ...inspector,
        ],
        isThirdParty: [
          { value: undefined, label: 'All' },
          ...action.payload.isThirdParty,
        ],
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_TYPE_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        target: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_SOURCE_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        atSource: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_CITY_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        city: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_LAST_USAGE_DECISION_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        lastUsageDecision: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_IS_THIRD_PARTY_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        isThirdParty: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_INSPECTION_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        inspection: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_INSPECTOR_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        inspector: action.payload,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_INSPECTION_DATE_FILTER]: (state, action) => {
    const [startDate, endDate] = action.payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        inspectionDateStart: startDate,
        inspectionDateEnd: endDate,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_INSPECTION_WINDOW_FILTER]: (state, action) => {
    const [startDate, endDate] = action.payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        inspectionWindowStart: startDate,
        inspectionWindowEnd: endDate,
      },
    };
  },
  [FILTER_ACTIONS.USER_SETS_STATUS_FILTER]: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        status: action.payload,
      },
    };
  },

  [PAGE_ACTIONS.USER_TOGGLES_INSPECTION]: (state, action) => {
    const list = [...state.selectedInspections];
    const idIdx = list.findIndex(({ id }) => id === action.payload.id);
    if (idIdx !== -1) {
      list.splice(idIdx, 1);
    } else {
      list.push(action.payload);
    }

    return {
      ...state,
      selectedInspections: list,
    };
  },
  [PAGE_ACTIONS.USER_TOGGLES_INSPECTIONS_HEADER]: (state, action) => {
    return {
      ...state,
      selectedInspections: action.payload,
    };
  },
  [PAGE_ACTIONS.APP_LOADS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        list: action.payload.data,
        count: action.payload.count,
        selectedInspector: null,
        isLoading: false,
      },
    };
  },
  [PAGE_ACTIONS.APP_STARTS_LOADING_INSPECTORS]: (state) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        isLoading: true,
      },
    };
  },
  [PAGE_ACTIONS.USER_OPENS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        isModalOpen: true,
      },
    };
  },
  [PAGE_ACTIONS.USER_CANCELS_INSPECTORS_MODAL]: (state) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        list: [],
        selectedInspector: null,
        search: '',
        page: 1,
        isModalOpen: false,
      },
    };
  },
  [PAGE_ACTIONS.USER_SEARCHES_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        search: action.payload,
        page: 1,
      },
    };
  },
  [PAGE_ACTIONS.USER_SORTS_INSPECTORS]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        ...action.payload,
      },
    };
  },
  [PAGE_ACTIONS.USER_SETS_INSPECTORS_PAGE]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        page: action.payload,
      },
    };
  },
  [PAGE_ACTIONS.USER_SELECTS_INSPECTOR]: (state, action) => {
    return {
      ...state,
      availableInspectors: {
        ...state.availableInspectors,
        selectedInspector: action.payload,
      },
    };
  },
  [PAGE_ACTIONS.USER_OPENS_DATE_MODAL]: (state) => {
    return {
      ...state,
      isDateModalOpen: true,
    };
  },
  [PAGE_ACTIONS.USER_CANCELS_DATE_MODAL]: (state) => {
    return {
      ...state,
      isDateModalOpen: false,
      inspectionDate: '',
    };
  },
  [PAGE_ACTIONS.USER_CHANGES_INSPECTION_DATE]: (state, action) => {
    return {
      ...state,
      inspectionDate: action.payload,
    };
  },
  [PAGE_ACTIONS.SET_BULK_UPDATE_RESULT_MODAL]: (state, action) => {
    return {
      ...state,
      bulkUpdateResultModal: {
        ...state.bulkUpdateResultModal,
        ...action.payload,
      },
    };
  },
  [PAGE_ACTIONS.RESET_BULK_UPDATE_RESULT_MODAL]: (state, action) => {
    return {
      ...state,
      bulkUpdateResultModal: {
        ...initialState.bulkUpdateResultModal,
        ...action.payload,
      },
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  INSPECTIONS_REDUCER_CONFIG,
  INITIAL_INSPECTIONS_STATE,
);
