import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';

import './ImportQuestionTranslationsPage.scss';

const columnLabels = {
  questionTemplateId: 'Question ID',
  questionTemplateExternalId: 'Question External ID',
  text: 'Name',
  language: 'Language',
};

const translationErrorValidations = {
  question: {
    'question.notFound': () => 'Could not find question',
  },
};

export default function ImportQuestionTranslationsPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-question-translations-page'>
      <h4>Import question translations</h4>
      <p className='form-info'>
        Before importing translations, please make sure you structure your data like
        in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a
          href='documents/sample-question-translations.csv'
          target='_blank'
          download
        >
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='question-translations' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={translationErrorValidations}
        columnLabels={columnLabels}
      />
    </div>
  );
}
