export const INSPECTOR_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'third_party',
};
export const INSPECTOR_TYPE_LABEL = {
  [INSPECTOR_TYPE.INTERNAL]: 'Internal',
  [INSPECTOR_TYPE.EXTERNAL]: 'Third party',
};

export const INSPECTION_ORIGIN = {
  INTERNAL: 'internal',
  EXTERNAL: 'third_party',
};
export const INSPECTION_ORIGIN_LABEL = {
  [INSPECTION_ORIGIN.INTERNAL]: 'Internal',
  [INSPECTION_ORIGIN.EXTERNAL]: 'Third party',
};
export const INSPECTION_ORIGIN_OPTIONS = [
  {
    label: INSPECTION_ORIGIN_LABEL[INSPECTION_ORIGIN.INTERNAL],
    value: INSPECTION_ORIGIN.INTERNAL,
  },
  {
    label: INSPECTION_ORIGIN_LABEL[INSPECTION_ORIGIN.EXTERNAL],
    value: INSPECTION_ORIGIN.EXTERNAL,
  },
];

export const INSPECTION_TARGET = {
  ASSET: 'asset',
  SOURCE: 'source',
};
export const INSPECTION_TARGET_LABEL = {
  [INSPECTION_TARGET.ASSET]: 'Asset',
  [INSPECTION_TARGET.SOURCE]: 'Source',
};
