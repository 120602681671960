import { INSPECTION_PLAN_STATUS } from 'config/inspectionPlanStatus';
import InspectionPlanStatusPill from 'lib/components/inspection-plans-status-pill/InspectionPlansStatusPill';

export const unlinkOnAddDialog = () => ({
  body: (
    <p>
      If you add a question to this group, it will no longer receive updates
      from settings. You can link it to settings again by clicking the Re-link
      button.
    </p>
  ),
  title: 'Add question?',
  confirmText: 'Add question',
});

export const unlinkOnReorderDialog = () => ({
  body: (
    <p>
      If you reorder questions in this group, it will no longer receive updates
      from settings. You can link it to settings again by clicking the Re-link
      button.
    </p>
  ),
  title: 'Move question?',
  confirmText: 'Move question',
});

export const publishOnHoldInspectionsDialog = ({ count }) => ({
  body: (
    <>
      Changing the status from{' '}
      <InspectionPlanStatusPill
        status={INSPECTION_PLAN_STATUS.NEEDS_REVIEW}
        inline
      />{' '}
      to{' '}
      <InspectionPlanStatusPill
        status={INSPECTION_PLAN_STATUS.PUBLISHED}
        inline
      />{' '}
      will publish <strong>{count}</strong> on-hold inspections.
    </>
  ),
  title: 'Apply changes?',
  confirmText: 'Apply changes',
});
