const USER_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

const USER_STATUS_TO_LABEL = {
  [USER_STATUS.DRAFT]: 'Draft',
  [USER_STATUS.ACTIVE]: 'Active',
  [USER_STATUS.DEACTIVATED]: 'Deactivated',
};

export const getUserStatusLabel = (status) =>
  USER_STATUS_TO_LABEL[status] || '';

export default USER_STATUS;
