import Modal from 'lib/components/modal/Modal';
import Button from 'lib/components/button/Button';
import Select from 'lib/components/select/Select';

import './ReInspectModal.scss';

const ReInspectModal = ({
  isOpen,
  onAqlChange,
  data = {},
  onSubmit,
  ...props
}) => {
  const renderOptions = () =>
    (data?.aqlOptions || []).map((option) => ({
      value: option?.id,
      label: option?.name,
    }));

  return (
    <Modal
      title='Re-inspect'
      actions={
        <Button onClick={onSubmit} data-testid='ReInspectModal.submit'>
          Ok
        </Button>
      }
      className='re-inspect-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <form noValidate className='re-inspect-form'>
        <p>Select one of the options to perform the re-inspection</p>
        <Select
          name='aql'
          isSearchable={false}
          options={renderOptions()}
          onChange={onAqlChange}
          placeholder='Select'
          label='AQL'
          value={data.reInspectAql.value}
          errors={data.reInspectAql.errors}
          data-testid='ReInspectModal.aql'
        />
      </form>
    </Modal>
  );
};

export default ReInspectModal;
