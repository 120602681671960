import { useEffect, useReducer } from 'react';
import { workflowToDetailState } from './dataTransform';
import {
  reducer,
  initialState,
  WORKFLOWS_DETAIL_ACTIONS,
} from './workflowsDetailReducer';
import workflowsService from './workflowsService';

const useWorkflowsDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getWorkflow = () => {
    const id = props.match?.params?.id;
    if (id) {
      workflowsService
        .getWorkflow({ id })
        .then((workflow) => {
          dispatch({
            type: WORKFLOWS_DETAIL_ACTIONS.APP_LOADS_DATA,
            payload: workflowToDetailState(workflow),
          });
        })
        .catch((e) => {
          dispatch({
            type: WORKFLOWS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  const getRelatedWorkObjects = () => {
    const workflowId = props.match?.params?.id;
    if (workflowId) {
      workflowsService
        .getRelatedWorkObjects({ workflowId })
        .then((workObjects) => {
          dispatch({
            type: WORKFLOWS_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS,
            payload: workObjects,
          });
        })
        .catch((e) => {
          dispatch({
            type: WORKFLOWS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  const userClicksOnRow = (id) => {
    props.history.push(`/work-objects/${id}`);
  };

  useEffect(() => {
    Promise.all([getWorkflow(), getRelatedWorkObjects()]);
  }, []);

  return {
    state,
    userClicksOnRow,
  };
};

export default useWorkflowsDetail;
