export const QUESTION_TYPE = {
  INFORMATIONAL_NON_AQL: 'informational_non_aql',
  INFORMATIONAL_AQL: 'informational_aql',
  VISUAL: 'visual',
  CONSTRUCTION: 'construction',
  FUNCTION: 'function',
};
export const QUESTION_TYPE_LABEL = {
  [QUESTION_TYPE.INFORMATIONAL_NON_AQL]: 'Informational non-AQL',
  [QUESTION_TYPE.INFORMATIONAL_AQL]: 'Informational AQL',
  [QUESTION_TYPE.VISUAL]: 'Visual',
  [QUESTION_TYPE.CONSTRUCTION]: 'Construction',
  [QUESTION_TYPE.FUNCTION]: 'Function',
};

export const ANSWER_TYPE = {
  MULTIPLE_CHOICE: 'multiple_choice',
  DEVICE_IMAGE_CAPTURE: 'device_image_capture',
  BARCODE_INPUT: 'barcode_input',
  DATE_SELECTION: 'date_selection',
  TEXT_AREA_INPUT: 'text_area_input',
  COLOR_INPUT: 'color_input',
  QUANTITATIVE_INPUT: 'quantitative_input',
};
export const ANSWER_TYPE_LABEL = {
  [ANSWER_TYPE.MULTIPLE_CHOICE]: 'Multiple Choice',
  [ANSWER_TYPE.DEVICE_IMAGE_CAPTURE]: 'Device Image Capture',
  [ANSWER_TYPE.BARCODE_INPUT]: 'Barcode Input',
  [ANSWER_TYPE.DATE_SELECTION]: 'Date Selection',
  [ANSWER_TYPE.TEXT_AREA_INPUT]: 'Text Area Input',
  [ANSWER_TYPE.COLOR_INPUT]: 'Color Input',
  [ANSWER_TYPE.QUANTITATIVE_INPUT]: 'Quantitative Input',
};

export const DEPENDENCY = {
  HAS_DEFECTS: 'has_defects',
  NO_DEFECTS: 'no_defects',
  IS_ANSWERED: 'is_answered',
  IS_NOT_ANSWERED: 'not_answered',
  SPECIFIC_ANSWER: 'specific_answer',
};

export const DEPENDENCY_LABEL = {
  [DEPENDENCY.HAS_DEFECTS]: 'Has defect(s)',
  [DEPENDENCY.NO_DEFECTS]: "Doesn't have defect(s)",
  [DEPENDENCY.IS_ANSWERED]: 'Is answered',
  [DEPENDENCY.IS_NOT_ANSWERED]: 'Is not answered',
  [DEPENDENCY.SPECIFIC_ANSWER]: 'Has specific answer',
};

/**
 * Dependency options available for all question types
 */
export const ALL_DEPENDENCY_OPTIONS = [
  {
    label: DEPENDENCY_LABEL[DEPENDENCY.HAS_DEFECTS],
    value: DEPENDENCY.HAS_DEFECTS,
  },
  {
    label: DEPENDENCY_LABEL[DEPENDENCY.NO_DEFECTS],
    value: DEPENDENCY.NO_DEFECTS,
  },
];
/**
 * Dependency options only available to
 * non-multiple-choice questions
 */
export const SIMPLE_DEPENDENCY_OPTIONS = [
  {
    label: DEPENDENCY_LABEL[DEPENDENCY.IS_ANSWERED],
    value: DEPENDENCY.IS_ANSWERED,
  },
  {
    label: DEPENDENCY_LABEL[DEPENDENCY.IS_NOT_ANSWERED],
    value: DEPENDENCY.IS_NOT_ANSWERED,
  },
];

export const PHOTO_REQUIRED = {
  NOT_ALLOWED: 'not_allowed',
  ALLOWED: 'allowed',
  REQUIRED: 'required',
};
export const PHOTO_REQUIRED_LABEL = {
  [PHOTO_REQUIRED.NOT_ALLOWED]: 'Not allowed',
  [PHOTO_REQUIRED.ALLOWED]: 'Allowed',
  [PHOTO_REQUIRED.REQUIRED]: 'Required',
};

export const PRINT_ON_REPORT = {
  ALWAYS: 'print_always',
  ON_FAIL: 'print_upon_failure',
  NEVER: 'never_print',
};
export const PRINT_ON_REPORT_LABEL = {
  [PRINT_ON_REPORT.ALWAYS]: 'Always',
  [PRINT_ON_REPORT.ON_FAIL]: 'Upon failure',
  [PRINT_ON_REPORT.NEVER]: 'Never',
};

export const MULTIPLE_CHOICE_ANSWER_TYPES = [
  ANSWER_TYPE.MULTIPLE_CHOICE,
  ANSWER_TYPE.BARCODE_INPUT,
];

/**
 * Hard-coded UOMs available in the asset measure table. This may
 * be dynamic / per-asset in the future.
 */
export const MEASURE_TABLE_UOM_OPTIONS = [
  {
    label: 'EA', // Each
    value: 'EA',
  },
  {
    label: 'GB', // Gift box
    value: 'GB',
  },
  {
    label: 'IP', // Inner pack
    value: 'IP',
  },
  {
    label: 'MP', // Master pack
    value: 'MP',
  },
];

export const RESULT_RECORDING_TYPE = {
  AVERAGE: false,
  INDIVIDUAL: true,
};
export const DEFAULT_RESULT_RECORDING_TYPE = RESULT_RECORDING_TYPE.AVERAGE;
export const RESULT_RECORDING_TYPE_LABEL = {
  [RESULT_RECORDING_TYPE.AVERAGE]: 'Average',
  [RESULT_RECORDING_TYPE.INDIVIDUAL]: 'Individual',
};

export const RESULT_RECORDING_OPTIONS = [
  {
    label: RESULT_RECORDING_TYPE_LABEL[RESULT_RECORDING_TYPE.AVERAGE],
    value: RESULT_RECORDING_TYPE.AVERAGE,
  },
  {
    label: RESULT_RECORDING_TYPE_LABEL[RESULT_RECORDING_TYPE.INDIVIDUAL],
    value: RESULT_RECORDING_TYPE.INDIVIDUAL,
  },
];

export const ANSWER_MEANING = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  SKIP: 'skip',
};

export const ANSWER_MEANING_LABEL = {
  [ANSWER_MEANING.ACCEPT]: 'Accept',
  [ANSWER_MEANING.REJECT]: 'Reject',
  [ANSWER_MEANING.SKIP]: 'N/A',
};

export const QUESTION_GROUP_STATUS = {
  /** Available to be used in inspection plans */
  PUBLISHED: 'published',
  /** Not available to be used in inspection plans */
  DRAFT: 'draft',
};

export const DEFAULT_QUESTION_GROUP_STATUS = QUESTION_GROUP_STATUS.PUBLISHED;

export const QUESTION_GROUP_STATUS_LABEL = {
  [QUESTION_GROUP_STATUS.PUBLISHED]: 'Published',
  [QUESTION_GROUP_STATUS.DRAFT]: 'Draft',
};

export const QUESTION_GROUP_STATUS_OPTIONS = [
  {
    value: QUESTION_GROUP_STATUS.DRAFT,
    label: QUESTION_GROUP_STATUS_LABEL[QUESTION_GROUP_STATUS.DRAFT],
  },
  {
    value: QUESTION_GROUP_STATUS.PUBLISHED,
    label: QUESTION_GROUP_STATUS_LABEL[QUESTION_GROUP_STATUS.PUBLISHED],
  },
];
