const TOKEN_KEY = 'qm_token';
const USER_KEY = 'qm_user';
const COMPANY_KEY = 'qm_company';

export const tokenStorage = {
  get: () => JSON.parse(localStorage.getItem(TOKEN_KEY)),
  set: (token) => localStorage.setItem(TOKEN_KEY, JSON.stringify(token)),
  clear: () => localStorage.clear(TOKEN_KEY),
};

export const userStorage = {
  get: () => JSON.parse(localStorage.getItem(USER_KEY)),
  set: (user) => localStorage.setItem(USER_KEY, JSON.stringify(user)),
  clear: () => localStorage.clear(USER_KEY),
};

export const companyStorage = {
  get: () => JSON.parse(localStorage.getItem(COMPANY_KEY)),
  set: (company) => localStorage.setItem(COMPANY_KEY, JSON.stringify(company)),
  clear: () => localStorage.clear(COMPANY_KEY),
};

export const companyToAuthState = (company) =>
  company
    ? {
        id: Number(company.id),
        name: company.name,
        location: company.location,
        emails: company.emails,
        phones: company.phones,
        customConfig: company.customConfig,
      }
    : null;

export const clearAll = () => {
  tokenStorage.clear();
  userStorage.clear();
  companyStorage.clear();
};

const localStorageService = {
  tokenStorage,
  userStorage,
  companyStorage,
  clearAll,
};

export default localStorageService;
