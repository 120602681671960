import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import Toggle from 'lib/components/toggle/Toggle';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../App';
import './ProfileMenu.scss';

function ProfileMenu() {
  const {
    logout,
    appState: { currentUser },
  } = useContext(AppContext);
  const history = useHistory();

  return (
    <div className='profileMenu' data-testid='ProfileMenu.container'>
      <DropdownMenu
        containerClassName='profile-menu-dropdown-container'
        items={[
          <MenuItem
            label='My profile'
            onClick={() => history.push('/my-profile')}
          />,
          <MenuItem label='Logout' onClick={() => logout()} />,
        ]}
      >
        {currentUser.name}
        <Toggle className='company-toggle' direction='down' />
      </DropdownMenu>
    </div>
  );
}

export default ProfileMenu;
