import { useCallback, useEffect, useReducer } from 'react';
import { LIST_ACTIONS } from './makeListReducer';
import debounce from 'lodash.debounce';

/**
 * Hook to provide pagination, sorting and searching for lists/tables
 * @param {*} reducer Reducer function
 * @param {*} initialData Initial reducer state
 * @param {(
 *   search: string,
 *   pageSize: number,
 *   page: number,
 *   sortBy: string,
 *   sortOrder: string
 * ) => Promise<any>} fetchData Optional function to fetch list data;
 * leave undefined if you need to manage state manually.
 * @returns
 */
const useList = (reducer, initialData, fetchData) => {
  const [state, dispatch] = useReducer(reducer, initialData);
  const debouncedFetch = useCallback(
    debounce(
      (fetchData, state, dispatch) => {
        dispatch({
          type: LIST_ACTIONS.SET_LOADING,
          payload: true,
        });
        fetchData(
          state.search,
          state.pageSize,
          state.page,
          state.sortBy,
          state.sortOrder,
        )
          .then((res) => {
            dispatch({
              type: LIST_ACTIONS.APP_LOADS_DATA,
              payload: res,
            });
          })
          .catch(() => {
            dispatch({
              type: LIST_ACTIONS.SET_ERRORS,
              payload: ['Could not load data'],
            });
          })
          .finally(() => {
            dispatch({
              type: LIST_ACTIONS.SET_LOADING,
              payload: false,
            });
          });
      },
      400,
      { leading: true },
    ),
    [fetchData, state, dispatch],
  );
  useEffect(() => {
    if (fetchData) {
      debouncedFetch(fetchData, state, dispatch);
    }
  }, [
    fetchData,
    state.pageSize,
    state.page,
    state.search,
    state.sortBy,
    state.sortOrder,
  ]);

  const sortBy = (column, order) => {
    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  return {
    state,
    dispatch,
    sortBy,
    setPageSize,
    setPage,
    setSearch,
  };
};

export default useList;
