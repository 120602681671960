import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import InspectionsListPage from './components/inpsections-list-page/InspectionsListPage';
import InspectionReportPrint from './components/inspection-report-print/InspectionReportPrint';
import InspectionsAddExternalPage from './components/inspections-add-external-page/InspectionsAddExternalPage';
import InspectionsAddPage from './components/inspections-add-page/InspectionsAddPage';
import InspectionsDetailPage from './components/inspections-detail-page/InspectionsDetailPage';
import InspectionsEditPage from './components/inspections-edit-page/InspectionsEditPage';
import InspectionsResultPageWrap from './components/inspections-result-page/InspectionsResultPageWrap';
import PerformInspectionPage from './components/perform-inspection-page/PerformInspectionPage';
import {
  ADD_INSPECTIONS_ROLES,
  PERFORM_INSPECTION_ROLES,
  VIEW_ALL_ROLES,
  VIEW_INSPECTION_ROLES,
} from './inspectionsPermissions';

export default function InspectionsRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/inspections'
        exact
        render={(props) => <InspectionsListPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_INSPECTIONS_ROLES}
        path='/inspections/add'
        exact
        render={(props) => <InspectionsAddPage {...props} />}
      />
      <ProtectedRoute
        allowed={ADD_INSPECTIONS_ROLES}
        path='/inspections/add-third-party'
        exact
        render={(props) => <InspectionsAddExternalPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/inspections/:id(\d+)'
        exact
        render={(props) => <InspectionsDetailPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/inspections/:id(\d+)/results'
        exact
        render={() => <InspectionsResultPageWrap />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/inspections/results'
        exact
        render={() => <InspectionsResultPageWrap />}
      />
      <ProtectedRoute
        allowed={VIEW_ALL_ROLES}
        path='/inspections/:id(\d+)/results/print'
        exact
        render={(props) => <InspectionReportPrint {...props} />}
      />
      <ProtectedRoute
        allowed={PERFORM_INSPECTION_ROLES}
        path='/inspections/:id(\d+)/perform'
        exact
        render={(props) => <PerformInspectionPage {...props} />}
      />
      <ProtectedRoute
        allowed={VIEW_INSPECTION_ROLES}
        path='/inspections/:id(\d+)/edit'
        exact
        render={(props) => <InspectionsEditPage {...props} />}
      />
    </>
  );
}
