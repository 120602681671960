import { entityToSelectOption } from 'lib/dataTransform';
import makeReducer from 'lib/makeReducer';
import { cloneDeep } from 'lodash';

const MAX_FIELD_CHARS = {
  name: 250,
  description: 250,
  externalId: 50,
};

const getInitialModalState = () => ({
  list: [],
  count: 0,
  page: 1,
  pageSize: 10,
  search: '',
  sortBy: 'id',
  sortOrder: 'ASC',
  isModalOpen: false,
  errors: [],
  selected: [],
});

const INITIAL_FORM_STATE = {
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  externalId: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.externalId,
  },
  description: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.description,
  },
  images: { values: [], errors: [] },
  initialImages: [],
  type: {
    value: '',
    errors: [],
  },
  status: {
    value: '',
    errors: [],
  },
  owners: {
    list: [],
    errors: [],
  },
  initialOwners: [],
  typeOptions: {
    list: [],
    errors: [],
  },
  statusOptions: {
    list: [],
    errors: [],
  },
  companyAttributes: {},
  availableCompanyAttributes: null,
  customFields: {
    list: [],
    errors: [],
  },
  measureTable: {
    value: null,
    errors: [],
  },
  availableOwners: getInitialModalState(),
  referenceDocuments: {
    list: [],
    errors: [],
  },
  errors: [],
  isDirty: false,
  isSaving: false,
  isExternalIdEditable: false,
};

export const ASSETS_FORM_ACTIONS = {
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_ADDS_IMAGE: 'USER_ADDS_IMAGE',
  USER_REMOVES_IMAGE: 'USER_REMOVES_IMAGE',
  USER_CANCELS_IMAGE_CHANGES: 'USER_CANCELS_IMAGE_CHANGES',
  APP_FAILS_IMAGE_UPLOAD: 'APP_FAILS_IMAGE_UPLOAD',

  APP_LOADS_TYPE_OPTIONS: 'APP_LOADS_TYPE_OPTIONS',
  APP_LOADS_STATUS_OPTIONS: 'APP_LOADS_STATUS_OPTIONS',
  APP_LOADS_AVAILABLE_OWNERS: 'APP_LOADS_AVAILABLE_OWNERS',
  APP_LOADS_COMPANY_ATTRIBUTES: 'APP_LOADS_COMPANY_ATTRIBUTES',
  APP_LOADS_MEASURE_TABLE: 'APP_LOADS_MEASURE_TABLE',

  USER_SELECTS_PRODUCT_TYPE: 'USER_SELECTS_PRODUCT_TYPE',
  USER_SELECTS_STATUS: 'USER_SELECTS_STATUS',

  USER_ADDS_DOCUMENT: 'USER_ADDS_DOCUMENT',
  USER_DELETES_DOCUMENT: 'USER_DELETES_DOCUMENT',
  USER_TYPES_DOCUMENT_NAME: 'USER_TYPES_DOCUMENT_NAME',

  USER_OPENS_OWNERS_MODAL: 'USER_OPENS_OWNERS_MODAL',
  USER_CANCELS_OWNERS_MODAL: 'USER_CANCELS_OWNERS_MODAL',
  USER_SELECTS_OWNERS: 'USER_SELECTS_OWNERS',
  USER_SEARCHES_OWNERS: 'USER_SEARCHES_OWNERS',
  USER_SORTS_OWNERS: 'USER_SORTS_OWNERS',
  USER_TOGGLES_NEW_OWNER: 'USER_TOGGLES_NEW_OWNER',
  USER_CANCELS_OWNER_CHANGES: 'USER_CANCELS_OWNER_CHANGES',
  USER_ASSIGNS_NEW_OWNERS: 'USER_ASSIGNS_NEW_OWNERS',
  USER_REMOVES_OWNER: 'USER_REMOVES_OWNER',
  USER_SETS_OWNERS_PAGE: 'USER_SETS_OWNERS_PAGE',

  USER_ADDS_CUSTOM_FIELD: 'USER_ADDS_CUSTOM_FIELD',
  USER_TYPES_CUSTOM_FIELD_NAME: 'USER_TYPES_CUSTOM_FIELD_NAME',
  USER_TYPES_CUSTOM_FIELD_VALUE: 'USER_TYPES_CUSTOM_FIELD_VALUE',
  USER_DELETES_CUSTOM_FIELD: 'USER_DELETES_CUSTOM_FIELD',

  USER_SELECTS_COMPANY_ATTRIBUTE: 'USER_SELECTS_COMPANY_ATTRIBUTE',
  USER_SELECTS_CUSTOM_ATTRIBUTE: 'USER_SELECTS_CUSTOM_ATTRIBUTE',

  RESET_STATE: 'RESET_STATE',
};

const setStringValue = (key, state, payload) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      ...state[key],
      value: payload,
      errors: [],
      charsLeft: max && max - payload.length,
    },
    isDirty: true,
  };
};

const setSelectValue = (key, state, action) => {
  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
    },
    isDirty: true,
  };
};

const setSelectOwners = (state, action) => {
  return {
    ...state,
    owners: {
      ...state.owners,
      list: [...state.owners.list, action.payload],
    },
    isDirty: true,
  };
};

const setSelectOptions = (key, state, action) => {
  const options = action.payload.map(entityToSelectOption('name', 'id'));
  return {
    ...state,
    [key]: {
      ...state[key],
      list: options,
    },
  };
};

const setSelectStatusesOptions = (key, state, action) => {
  const options = action.payload.map((status) => ({
    label: status.name,
    value: status.id,
    meaning: status.meaning,
  }));
  return {
    ...state,
    [key]: {
      ...state[key],
      list: options,
    },
  };
};

const ASSETS_REDUCER_CONFIG = {
  [ASSETS_FORM_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return setStringValue(action.key, state, action.payload);
  },
  [ASSETS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      isSaving: true,
      isDirty: false,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_ADDS_IMAGE]: (state, action) => {
    return { ...state, images: { ...action.payload }, isDirty: true };
  },
  [ASSETS_FORM_ACTIONS.USER_REMOVES_IMAGE]: (state, action) => {
    return { ...state, images: { ...action.payload }, isDirty: true };
  },
  [ASSETS_FORM_ACTIONS.USER_CANCELS_IMAGE_CHANGES]: (state) => {
    return {
      ...state,
      images: {
        values: state.initialImages,
        errors: [],
      },
    };
  },
  [ASSETS_FORM_ACTIONS.APP_FAILS_IMAGE_UPLOAD]: (state) => {
    return {
      ...state,
      images: {
        values: state.images.values,
        errors: [
          'An error occured while uploading the images. Please try again.',
        ],
      },
    };
  },
  [ASSETS_FORM_ACTIONS.APP_LOADS_TYPE_OPTIONS]: (state, action) => {
    return setSelectOptions('typeOptions', state, action);
  },
  [ASSETS_FORM_ACTIONS.APP_LOADS_STATUS_OPTIONS]: (state, action) => {
    return setSelectStatusesOptions('statusOptions', state, action);
  },
  [ASSETS_FORM_ACTIONS.APP_LOADS_COMPANY_ATTRIBUTES]: (state, action) => {
    return {
      ...state,
      availableCompanyAttributes: action.payload,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SELECTS_CUSTOM_ATTRIBUTE]: (state, action) => {
    const customAttributes = state.customAttributes.list.map((field) =>
      field.id === action.payload.id
        ? { ...field, value: action.payload.value?.value }
        : field,
    );
    return {
      ...state,
      customAttributes: {
        list: customAttributes,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SELECTS_COMPANY_ATTRIBUTE]: (state, action) => {
    const { id, value } = action.payload;

    return {
      ...state,
      companyAttributes: {
        ...state.companyAttributes,
        [id]: value,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.APP_LOADS_AVAILABLE_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SELECTS_PRODUCT_TYPE]: (state, action) => {
    return setSelectValue('type', state, action);
  },
  [ASSETS_FORM_ACTIONS.USER_SELECTS_STATUS]: (state, action) => {
    return setSelectValue('status', state, action);
  },
  [ASSETS_FORM_ACTIONS.USER_SELECTS_OWNERS]: (state, action) => {
    return setSelectOwners(state, action.payload);
  },
  [ASSETS_FORM_ACTIONS.USER_OPENS_OWNERS_MODAL]: (state) => {
    const selected = cloneDeep(state.owners.list);
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        isModalOpen: true,
        selected,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_CANCELS_OWNERS_MODAL]: (state) => {
    return {
      ...state,
      availableOwners: getInitialModalState(),
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SEARCHES_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        search: action.payload,
        page: 1,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SORTS_OWNERS]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        ...action.payload,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_TOGGLES_NEW_OWNER]: (state, action) => {
    const newSelected = cloneDeep(state.availableOwners.selected);
    const idx = newSelected.findIndex(
      (owner) => owner.id === action.payload.id,
    );

    if (idx !== -1) {
      newSelected.splice(idx, 1);
    } else {
      newSelected.push(action.payload);
    }

    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        selected: newSelected,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_ASSIGNS_NEW_OWNERS]: (state) => {
    const newOwners = cloneDeep(state.availableOwners.selected);
    return {
      ...state,
      owners: {
        list: newOwners,
        errors: [],
      },
      availableOwners: getInitialModalState(),
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_CANCELS_OWNER_CHANGES]: (state) => {
    return {
      ...state,
      owners: {
        ...state.owners,
        list: state.initialOwners,
      },
      availableOwners: getInitialModalState(),
    };
  },
  [ASSETS_FORM_ACTIONS.USER_REMOVES_OWNER]: (state, action) => {
    return {
      ...state,
      owners: {
        ...state.owners,
        list: state.owners.list.filter(
          (owner) => owner.id !== action.payload.id,
        ),
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_SETS_OWNERS_PAGE]: (state, action) => {
    return {
      ...state,
      availableOwners: {
        ...state.availableOwners,
        page: action.payload,
      },
    };
  },
  [ASSETS_FORM_ACTIONS.USER_ADDS_DOCUMENT]: (state, action) => {
    let newForm = cloneDeep(state.referenceDocuments);
    const newDocs = Array.from(action.payload).map((file) => ({
      file: file,
      value: file.name.split('.').slice(0, -1).join('.'),
      errors: [],
    }));
    newForm = [...newForm.list, ...newDocs];

    return {
      ...state,
      referenceDocuments: {
        ...state.referenceDocuments,
        list: newForm,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_DELETES_DOCUMENT]: (state, action) => {
    return {
      ...state,
      referenceDocuments: {
        ...state.referenceDocuments,
        list: state.referenceDocuments.list.filter(
          (_, index) => index !== action.payload,
        ),
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_TYPES_DOCUMENT_NAME]: (state, action) => {
    const newArray = state.referenceDocuments.list.map((option, index) => {
      if (index === action.payload.id) {
        return {
          ...option,
          value: action.payload.value,
        };
      }

      return option;
    });
    return {
      ...state,
      referenceDocuments: {
        ...state.referenceDocuments,
        list: newArray,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_ADDS_CUSTOM_FIELD]: (state) => {
    return {
      ...state,
      customFields: {
        ...state.customFields,
        list: [
          ...state.customFields.list,
          {
            name: { value: '', errors: [] },
            value: { value: '', errors: [] },
          },
        ],
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_NAME]: (state, action) => {
    const newArray = state.customFields.list.map((field, index) => {
      if (index === action.payload.id) {
        return {
          ...field,
          name: { value: action.payload.value, errors: [] },
        };
      }

      return field;
    });
    return {
      ...state,
      customFields: {
        ...state.customFields,
        list: newArray,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_TYPES_CUSTOM_FIELD_VALUE]: (state, action) => {
    const newArray = state.customFields.list.map((field, index) => {
      if (index === action.payload.id) {
        return {
          ...field,
          value: { value: action.payload.value, errors: [] },
        };
      }

      return field;
    });
    return {
      ...state,
      customFields: {
        ...state.customFields,
        list: newArray,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.USER_DELETES_CUSTOM_FIELD]: (state, action) => {
    const newArray = state.customFields.list.filter(
      (_, index) => index !== action.payload,
    );
    return {
      ...state,
      customFields: {
        ...state.customFields,
        list: newArray,
      },
      isDirty: true,
    };
  },
  [ASSETS_FORM_ACTIONS.APP_LOADS_MEASURE_TABLE]: (state, action) => {
    return {
      ...state,
      measureTable: {
        value: action.payload,
        errors: [],
      },
    };
  },
  [ASSETS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isExternalIdEditable: `${action?.payload?.id ?? ''}`.length
        ? !`${action?.payload?.externalId?.value ?? ''}`.length
        : true,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  ASSETS_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
