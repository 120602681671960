import { useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';

import COMPANY_STATUS, { getStatusLabel } from 'config/companyStatus';
import COUNTRIES from 'config/countries.json';

import useNavigationPrompt from 'lib/useNavigationPrompt';

import {
  reducer,
  initialState,
  COMPANIES_FORM_ACTIONS,
} from './companiesFormReducer';
import companiesService from './companiesService';
import companiesFormValidator from './companiesFormValidator';
import { companiesToFormState } from './dataTransform';

const statusOptions = [
  {
    value: COMPANY_STATUS.ACTIVE,
    label: getStatusLabel(COMPANY_STATUS.ACTIVE),
  },
];

const countryOptions = COUNTRIES.map((country) => ({
  label: country,
  value: country,
}));

const useCompaniesForm = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useNavigationPrompt(state.isDirty);

  const getCompany = () => {
    const id = props.match?.params?.id;
    if (id) {
      return companiesService
        .getCompany({ id })
        .then((company) => {
          dispatch({
            type: COMPANIES_FORM_ACTIONS.RESET_STATE,
            payload: companiesToFormState(company),
          });
        })
        .catch((e) => {
          dispatch({
            type: COMPANIES_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  const dispatchInput = (key, payload) =>
    dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_CHANGES_INPUT,
      key,
      payload,
    });

  const userTypesName = (e) => {
    return dispatchInput('name', e.target.value || '');
  };

  const userSetsStatus = (option) => {
    return dispatchInput('status', option);
  };

  const userTypesAbout = (e) => {
    return dispatchInput('about', e.target.value || '');
  };
  const userTypesAddress = (e) => {
    return dispatchInput('address', e.target.value || '');
  };
  const userTypesCity = (e) => {
    return dispatchInput('city', e.target.value || '');
  };
  const userTypesZipCode = (e) => {
    return dispatchInput('zipCode', e.target.value || '');
  };
  const userTypesState = (e) => {
    return dispatchInput('state', e.target.value || '');
  };

  const userSetsCountry = (option) => {
    return dispatchInput('country', option || '');
  };

  const userAddsPhone = () => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_ADDS_PHONE,
    });
  };
  const userTypesCustomConfig = (e) => {
    return dispatchInput('customConfig', e.target.value || '');
  };

  const userAddsEmail = () => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_ADDS_EMAIL,
    });
  };

  const userRemovesPhone = (index) => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_REMOVES_PHONE,
      payload: index,
    });
  };

  const userRemovesEmail = (index) => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_REMOVES_EMAIL,
      payload: index,
    });
  };

  const userTypesPhone = (e, index) => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_TYPES_PHONE,
      payload: { value: e.target.value, index },
    });
  };

  const userTypesEmail = (e, index) => {
    return dispatch({
      type: COMPANIES_FORM_ACTIONS.USER_TYPES_EMAIL,
      payload: { value: e.target.value, index },
    });
  };

  const userTypesAdminName = (e) => {
    return dispatchInput('adminName', e.target.value || '');
  };

  const userTypesAdminEmail = (e) => {
    return dispatchInput('adminEmail', e.target.value || '');
  };

  const userSubmitsForm = (e) => {
    e.preventDefault();
    return dispatch({ type: COMPANIES_FORM_ACTIONS.USER_SUBMITS_FORM });
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (state.loading) {
      const stateClone = cloneDeep(state);
      companiesFormValidator(stateClone)
        .then((data) =>
          companiesService
            .saveCompany(data)
            .then((response) =>
              props.history.push(`/companies/${response.id}`),
            ),
        )
        .catch((e) => {
          dispatch({
            type: COMPANIES_FORM_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
        .finally(() =>
          dispatch({
            type: COMPANIES_FORM_ACTIONS.APP_FINISHES_SUBMISSION,
          }),
        );
    } else {
      document.getElementsByClassName('is-invalid')[0]?.scrollIntoView();
    }
  }, [state.loading]);

  return {
    state,
    statusOptions,
    countryOptions,
    userTypesName,
    userSetsStatus,
    userTypesAbout,
    userTypesAddress,
    userTypesCity,
    userTypesZipCode,
    userTypesState,
    userSetsCountry,
    userAddsPhone,
    userAddsEmail,
    userRemovesPhone,
    userRemovesEmail,
    userTypesPhone,
    userTypesEmail,
    userTypesAdminName,
    userTypesAdminEmail,
    userTypesCustomConfig,
    userSubmitsForm,
  };
};
export default useCompaniesForm;
