import { noop } from './funcHelpers';

const isProd = ['demo', 'prod'].includes(process.env.QM_ENV);
const isTest = process.env.NODE_ENV === 'test';

const defaultLogger = console.log;

const makeLogger = (namespace, logger = defaultLogger) => {
  const logFn = isProd || isTest ? noop : logger;

  return function (...args) {
    logFn.apply(this, [
      `[${new Date().toISOString()}] - ${namespace}`,
      ...args,
    ]);
  };
};

export default makeLogger;
