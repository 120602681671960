/**
 * Transform a file from an upload event to a form representation
 * @param {File} file
 * @returns {{
 * file: File,
 * value: string,
 * errors: string[]
 * }} Form representation of input file
 */
export const fileToFormObject = (file) => ({
  file: file,
  value: file.name
    .split('.')
    .slice(0, -1)
    .join(' ')
    .replace(/[^0-9a-zA-Z ]/g, ' ')
    .substring(0, 50),
  errors: [],
});

/**
 * Transform a file array from an upload event to a form representation
 * @param {FileList} fileList
 * @returns {{
 * file: File,
 * value: string,
 * errors: string[]
 * }[]} Form representation of input file array
 */
export const fileListToFormObject = (fileList) =>
  Array.from(fileList).map(fileToFormObject);
