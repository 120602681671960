import classNames from 'classnames';
import { formatDetailQuestionSampleSize } from 'lib/components/question-detail/dataTransform';
import { DEFAULT_LANGUAGE } from 'config/languages';
import { getTranslation } from 'lib/dataTransform';

import Button from 'lib/components/button/Button';
import Modal from 'lib/components/modal/Modal';
import Pagination from 'lib/components/pagination/Pagination';
import SearchInput from 'lib/components/search-input/SearchInput';
import Table from 'lib/components/table/Table';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';

import './SettingsQuestionsModal.scss';

const questionsTableColumns = [
  {
    title: 'ID / External ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => (
      <>
        <div>
          {record.selected && <CheckCircleSolid className='check-selected' />}
        </div>
        <div className='id'>
          {record.questionTemplateId}
          {!!record.externalId && ` / ${record.externalId}`}
        </div>
      </>
    ),
  },
  {
    title: 'Question',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <p>{getTranslation(record.name, DEFAULT_LANGUAGE).display}</p>
    ),
  },
  {
    title: 'Question Type',
    sortId: 'type',
    render: (record) => record.type?.label,
  },
  {
    title: 'Sample size',
    textAlign: 'right',
    render: (record) => formatDetailQuestionSampleSize(record),
  },
];

const QuestionsModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  setPage,
  pageSize,
  page,
  sortBy,
  count,
  sortOrder,
  setSortBy,
  className,
  onSubmit,
  selectedCount,
  ...props
}) => {
  const numSelected = selectedCount ?? data.filter((r) => r.selected).length;
  const isPaginationAvailable = !!page && !!pageSize && !!count && !!setPage;

  return (
    <Modal
      title='Select question'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name, type or ID...'
            data-testid='QuestionsModal.search'
          />
        </div>
      }
      actions={
        <>
          <Button onClick={onSubmit}>{`Assign questions${
            numSelected ? ` (${numSelected})` : ''
          }`}</Button>
          {isPaginationAvailable && (
            <Pagination
              threshold={3}
              setPage={setPage}
              pageSize={pageSize}
              page={page}
              count={count}
            />
          )}
        </>
      }
      className={classNames('settings-questions-modal', className)}
      isOpen={isOpen}
      ariaHideApp={false}
      data-testid='QuestionsModal.dialog'
      {...props}
    >
      <Table
        data={data}
        columns={questionsTableColumns}
        rowClassName={(record) => record.selected && 'row-selected'}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        sortBy={sortBy}
        setSortBy={setSortBy}
        data-testid='QuestionsModal.table'
      />
    </Modal>
  );
};

export default QuestionsModal;
