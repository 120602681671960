import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import InspectionsResultPageMulti from './InspectionsResultPageMulti';
import InspectionsResultPage from './InspectionsResultPage';

const getIdArray = (params, location) => {
  const ids = [];

  if (params?.id?.length && /\d+/.test(params.id)) {
    ids.push(Number(params.id));
  }

  const queryIds = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });

  if (Array.isArray(queryIds?.id)) {
    queryIds.id.forEach((qId) => {
      if (qId && /\d+/.test(qId) && !ids.includes(Number(qId))) {
        ids.push(Number(qId));
      }
    });
  } else if (/\d+/.test(queryIds?.id)) {
    ids.push(Number(queryIds.id));
  }

  return ids;
};

export default function InspectionsResultPageWrap() {
  const params = useParams();
  const location = useLocation();

  let ids = getIdArray(params, location);

  return ids?.length > 1 ? (
    <InspectionsResultPageMulti ids={ids} />
  ) : (
    <InspectionsResultPage id={ids?.length === 1 ? ids[0] : null} />
  );
}
