import React from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import './Tooltip.scss';

const Tooltip = React.forwardRef(
  ({ children, overlay, className, ...props }, ref) => {
    return (
      <Tippy className='qm-tooltip' content={overlay} {...props}>
        <span
          className={classNames('qm-tooltip-container', className)}
          data-testid='Tooltip.container'
          ref={ref}
        >
          {children}
        </span>
      </Tippy>
    );
  },
);

export default Tooltip;
