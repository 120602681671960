import React from 'react';
import LoginForm from '../login-form/LoginForm';
import './LoginPage.scss';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  return (
    <div className='login-page'>
      <div className='login-form-container'>
        <h3>Sign in to Exacture</h3>
        <p className='instructions'>Enter your details below</p>
        <LoginForm />
      </div>
      <div className='footer-container'>
        Forgot your password? <Link to='/reset-password'>Reset password</Link>
      </div>
      <div className='patent-container'>
        US Patent Number: 11,587,038; 11,263,586; 10,878,380; 10,127,523
      </div>
    </div>
  );
};

export default LoginPage;
