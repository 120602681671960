import { MAX_FIELD_CHARS } from './inspectionPlansFormReducer';
import * as _get from 'lodash.get';

import {
  DEFAULT_RESULT_RECORDING_TYPE,
  DEPENDENCY,
} from 'config/questionOptions';

import { questionToDetailState } from 'lib/components/question-detail/dataTransform';
import {
  formatQuestionCriteria,
  getTranslation,
  normalizeWhitespace,
} from 'lib/dataTransform';
import { pipe, id } from 'lib/funcHelpers';
import { RESULT_STATUS } from './inspectionPlansBulkResultsReducer';

export const setStringValue = (responseKey, data, formKey = responseKey) => {
  const value = _get(data, responseKey) ?? '';
  return {
    value,
    errors: [],
    charsLeft: MAX_FIELD_CHARS[formKey] - value.length,
  };
};

/**
 * Prepare a map function that transforms a given object to
 * a select option
 * @param {string} label object property to be used as label
 * @param {string} value object property to be used as value
 * @returns {function} function that maps an object to a select option
 */
export const entityToSelectOption = (label, value) => (entity) => ({
  label: entity[label],
  value: entity[value],
});

/**
 * Transform inspection plan options received from API to objects that
 * Select components can consume
 * @param {object} response Inspection api response
 * @returns {object} Inspection plan form Select options
 */
export const optionsToFormState = (response) =>
  Object.entries(response).reduce((acc, [key, values]) => {
    if (key === 'questionOptions') return acc;
    acc[key] = values.map(entityToSelectOption('label', 'id'));
    return acc;
  }, {});

/**
 * Transform question group options received from API to objects that
 * Select components can consume
 * @param {object} response Inspection plan api response
 * @returns {object} Question group form Select options
 */
export const questionOptionsToFormState = (response) => {
  const opts = optionsToFormState(response.questionOptions);

  opts.dynamicAction = [
    {
      label: 'None',
      value: false,
    },
    ...opts.dynamicAction,
  ];

  opts.dependencyAction = [
    {
      label: 'None',
      value: false,
    },
    ...opts.dependencyAction,
  ];

  return opts;
};

export const defectToFormState = (defect, weightOptions) => {
  return {
    ...defect,
    weight: weightOptions.find((opt) => opt.value === defect.weight) || null,
  };
};

export const inspectionPlanToFormState = (
  response,
  planOptions,
  questionOptions,
) => {
  const optionByValue = (planKey, optionsKey = planKey) =>
    (planOptions[optionsKey] || []).find(
      (opt) => opt.value === response[planKey],
    ) ?? null;
  const questionEditFlags = {};
  response.questionGroups.map((group) =>
    group.questions.forEach((question, idx) => {
      // XXX: Refactored part
      if (getIsGroupedQuestionOverridden(question))
        questionEditFlags[`${group.originalTemplateId}-${idx}`] = true;
    }),
  );

  const mapped = {
    id: response.id,
    name: setStringValue('name', response),
    description: setStringValue('description', response),
    status: {
      value: response.status || '',
      errors: [],
    },
    initialStatus: response.status || '',
    validity: {
      value: response.validityValue || '',
      errors: [],
    },
    validityRange: {
      value: optionByValue('validityRange'),
      errors: [],
    },
    aqlLevel: {
      value: optionByValue('aqlLevel'),
      errors: [],
    },
    aqlMajor: {
      value: optionByValue('majorDefect'),
      errors: [],
    },
    aqlMinor: {
      value: optionByValue('minorDefect'),
      errors: [],
    },
    aqlFunctional: {
      value: optionByValue('functionalDefect'),
      errors: [],
    },
    criticalDefect: {
      value: response.criticalDefectValue,
      errors: [],
    },
    criticalDefectRule: {
      value: response.criticalDefectRule || 'Exact',
      errors: [],
    },
    linkedResource: response.linkedResource
      ? {
        ...response.linkedResource,
        resourceType: response.linkedResource.type,
      }
      : null,
    initialLinkedResourceId: response.linkedResource?.id || null,
    type: {
      value: entityToSelectOption('name', 'id')(response.inspectionType),
      errors: [],
    },
    initialType: entityToSelectOption('name', 'id')(response.inspectionType),
    questionGroups: (response.questionGroups || []).map((group) =>
      // XXX: Mapping each qg
      questionGroupToFormState(group, questionOptions),
    ),
    initialQuestionGroups: (response.questionGroups || []).map((group) =>
      questionGroupToFormDetailState(group, questionOptions),
    ),
    questionEditFlags,
  };
  return mapped;
};

export const groupedQuestionToFormState = (
  { body, ...dependency },
  questionOptions,
  // siblingQuestions,
) => {
  const question = {
    ...body,
    ...dependency,
    isCustom: !!body.isCustom, // XXX: Why dependency sets isCustom false?
  };

  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === question[questionKey],
    ) ?? null;

  const isCustomAnswer =
    question.dependencyCriteria === DEPENDENCY.SPECIFIC_ANSWER;
  const customAnswer = isCustomAnswer
    ? question.dependencyQuestion?.answer?.options?.find(
      (opt) => opt.order === question.dependencyAnswerOption,
    )
    : null;

  const dependencyCriteriaValue = isCustomAnswer
    ? {
      label: formatQuestionCriteria(
        question.dependencyCriteria,
        customAnswer?.label,
      ),
      value: question.dependencyAnswerOption,
    }
    : {
      label: formatQuestionCriteria(question.dependencyCriteria),
      value: question.dependencyCriteria,
    };

  const rslt = {
    id: question.id,
    templateId: question.templateId, // XXX: is it used anywhere?
    isCustom: question.isCustom,
    originalTemplateId: question.originalTemplateId,
    originalTemplate:
      !!question.originalTemplate &&
      questionToDetailState(
        {
          ...question.originalTemplate,
          id: question.id,
          templateId: question.originalTemplateId, // XXX: is it used anywhere?
          order: question.order,
        },
        questionOptions,
      ),
    order: question.order,
    name: {
      value: question.name || [],
      errors: [],
    },
    type: {
      value: optionByValue('type'),
      errors: [],
    },

    useAqlLevel: {
      value: question.useAqlLevel ?? true,
      errors: [],
    },
    aqlLevel: {
      value: optionByValue('aqlLevel'),
      errors: [],
    },
    aqlMajor: {
      value: optionByValue('majorDefect'),
      errors: [],
    },
    aqlMinor: {
      value: optionByValue('minorDefect'),
      errors: [],
    },
    aqlFunctional: {
      value: optionByValue('functionalDefect'),
      errors: [],
    },
    criticalDefect: {
      value: question.criticalDefectValue,
      errors: [],
    },
    criticalDefectRule: {
      value: question.criticalDefectRule || 'Exact',
      errors: [],
    },
    sampleQty: setStringValue('sampleQty', question),
    sampleRule: {
      value: question.sampleRule || 'Exact',
      errors: [],
    },

    questionWeight: {
      value: optionByValue('questionWeight'),
      errors: [],
    },
    answerType: {
      value: optionByValue('answerType'),
      errors: [],
    },
    answer: {
      value: question.answer
        ? {
          label: question.answer?.name,
          value: question.answer?.id,
        }
        : null,
      errors: [],
    },
    expectedBarcodeUOM: {
      value: optionByValue('expectedBarcodeUOM'),
      errors: [],
    },
    answerOptions: (question.answer?.options || []).map((opt) => ({
      label: getTranslation(opt.label).display,
      value: opt.order,
    })),
    photoRequired: {
      value: question.photoRequired,
      errors: [],
    },
    printOnReport: {
      value: optionByValue('printOnReport'),
      errors: [],
    },
    tools: (question.tools || []).map((tool) => ({
      value: tool.name,
      errors: [],
    })),
    assetReferenceDocNames: (question.assetReferenceDocNames || []).map(
      (doc) => ({
        value: doc.name,
        errors: [],
      }),
    ),
    documents: (question.documents || []).map((doc) => ({
      file: doc,
      value: doc.documentName || doc.name || '',
      errors: [],
    })),
    defects:
      (question.defects || []).map((d) =>
        defectToFormState(d, questionOptions.questionWeight),
      ) || [],

    dependencyAction: {
      value: question.useDependency
        ? optionByValue('dependencyAction')
        : {
          value: false,
          label: 'None',
        },
      errors: [],
    },
    dependencyCriteria: {
      value: dependencyCriteriaValue,
      errors: [],
    },
    dependencyQuestion: {
      value: question.useDependency
        ? {
          label: `#${question.dependencyQuestionTemplateOrder + 1}`,
          value: question.dependencyQuestionTemplateOrder,
        }
        : null,
      errors: [],
    },

    dynamicAction: {
      value: question.useDynamic
        ? optionByValue('dynamicAction')
        : {
          value: false,
          label: 'None',
        },
      errors: [],
    },
    dynamicCriteria: {
      value: question.dynamicCriteria || '',
      errors: [],
    },
    dynamicRule: {
      value: optionByValue('dynamicRule'),
      errors: [],
    },

    expectedMeasureTableResult: {
      value: optionByValue('expectedMeasureTableResult'),
      errors: [],
    },

    individualMeasurementsRequired: {
      value:
        question.individualMeasurementsRequired ??
        DEFAULT_RESULT_RECORDING_TYPE,
      errors: [],
    },

    customExpectedMeasureValue: {
      value: question.customExpectedMeasureValue ?? '',
      errors: [],
    },
    otherMeasure: {
      value: question.otherMeasure
        ? {
          label: question.otherMeasure,
          value: question.otherMeasure,
        }
        : null,
      errors: [],
    },

    customExpectedUom: {
      value: question.customExpectedUom
        ? {
          label: question.customExpectedUom.label,
          value: question.customExpectedUom.id,
        }
        : null,
      errors: [],
    },

    lowerTolerance: {
      value: question.lowerToleranceValue ?? '',
      errors: [],
    },
    lowerToleranceRule: {
      value: question.lowerToleranceRule || 'Exact',
      errors: [],
    },
    lowerToleranceWeight: {
      value: optionByValue('lowerToleranceWeight', 'questionWeight'),
      errors: [],
    },

    upperTolerance: {
      value: question.upperToleranceValue ?? '',
      errors: [],
    },
    upperToleranceRule: {
      value: question.upperToleranceRule || 'Exact',
      errors: [],
    },
    upperToleranceWeight: {
      value: optionByValue('upperToleranceWeight', 'questionWeight'),
      errors: [],
    },
  };

  return rslt;
};

const groupedQuestionToDetailState = (
  question,
  siblingQuestions = [],
  questionOptions,
) => {
  const optionByValue = (questionKey, optionsKey = questionKey) =>
    (questionOptions[optionsKey] || []).find(
      (opt) => opt.value === question[questionKey],
    ) ?? null;

  const dep = siblingQuestions.find(
    (dep) => question.dependencyQuestionTemplateOrder === dep.order,
  );

  return {
    ...questionToDetailState(question.body, questionOptions),
    order: question.order,
    useDependency: dep ? true : false,
    dependencyAction: dep ? optionByValue('dependencyAction') : null,
    dependencyCriteria: dep ? question.dependencyCriteria : null,
    dependencyAnswerOptionOrder: dep
      ? question.dependencyAnswerOptionOrder
      : null,
    dependencyQuestion: dep ? { ...dep.body, ...dep } : null,
  };
};

export const questionGroupToDetailState = (
  group,
  questionOptions,
  overrideQuestions = [],
) => {
  return {
    ...group,
    questions: group.questions.map((question) => {
      const overriddenQuestion = overrideQuestions.find(
        (candidate) =>
          Number(candidate.questionTemplateId) === Number(question.body.id) &&
          Number(candidate.originalGroupTemplateId) ===
          Number(group.originalGroupTemplateId),
      );
      const displayQuestion = overriddenQuestion
        ? {
          ...question,
          body: {
            ...overriddenQuestion.body,
            originalTemplate: question,
          },
        }
        : question;
      return groupedQuestionToDetailState(
        displayQuestion,
        group.questions,
        questionOptions,
      );
    }),
  };
};

/** Returns `true` if the question template inside the group has been
 * added only for this plan and there *doesn't exist* an original
 * template we can reset to.
 */
export const getIsGroupedQuestionForPlanOnly = (question) => {
  return question.isCustom && !question.originalTemplate;
};

/** Returns `true` if the question template inside the group has been
 * edited inside the plan but there *exists* an original template
 * we can reset to.
 */
export const getIsGroupedQuestionOverridden = (question = { body: {} }) => {
  return !!question.originalTemplate;
};

/** Returns `true` if the question group template details are edited for this
 * plan only.
 */
export const getIsQuestionGroupOverridden = (group) => {
  return !!group.isCustom && group.originalGroupTemplateId;
};

/** Returns `true` if the question group template is created for this
 * plan only.
 */
export const getIsQuestionGroupForThisPlanOnly = (group) => {
  return !!group.isCustom && !group.originalGroupTemplateId;
};

export const questionGroupToFormDetailState = (group, questionOptions) => ({
  ...group,
  questions: group.questions.map((question) =>
    groupedQuestionToDetailState(
      question.originalTemplate
        ? {
          ...question.originalTemplate,
          dependencyQuestionId: question.dependencyQuestionId,
          dependencyQuestion: question.dependencyQuestion,
          id: question.id,
          templateId: question.originalTemplateId, // XXX: Used anywhere?
          order: question.order,
        }
        : question,
      group.questions,
      questionOptions,
    ),
  ),
});

export const inspectionPlanToDetailState = (
  response,
  planOptions,
  questionOptions,
) => {
  const optionByValue = (planKey, optionsKey = planKey) =>
    (planOptions[optionsKey] || []).find(
      (opt) => opt.value === response[planKey],
    ) ?? null;
  return {
    ...response,
    aqlLevel: optionByValue('aqlLevel'),
    aqlMajor: optionByValue('majorDefect'),
    aqlMinor: optionByValue('minorDefect'),
    aqlFunctional: optionByValue('functionalDefect'),
    questionGroups: response.questionGroups.map((group) =>
      questionGroupToDetailState(
        group,
        questionOptions,
        response.overriddenQuestions,
      ),
    ),
  };
};

const formQuestionToPOSTParams = (question) => {
  const useAqlLevel = question.useAqlLevel.value;
  const isHardcodedAQL =
    useAqlLevel && question.aqlLevel?.value?.value !== null;
  const useDependency =
    question.dependencyAction.value?.value !== false && question.order > 0;
  const useCustomAnswer = !Object.values(DEPENDENCY).includes(
    question.dependencyCriteria?.value?.value,
  );
  const criteriaValue = question.dependencyCriteria?.value?.value;

  const mapped = {
    id: question.id?.indexOf('new_question') === -1 ? question.id : undefined,
    templateId: question.isEditing ? null : question.originalTemplateId, // XXX: Used anywhere?
    originalTemplateId: question.originalTemplateId,
    order: question.order,
    name: question.name?.value,
    type: question.type.value?.value,

    useAqlLevel: useAqlLevel ?? true,
    aqlLevel: question.aqlLevel?.value?.value || null,
    majorDefect: isHardcodedAQL ? question.aqlMajor?.value?.value : null,
    minorDefect: isHardcodedAQL ? question.aqlMinor?.value?.value : null,
    functionalDefect: isHardcodedAQL
      ? question.aqlFunctional?.value?.value
      : null,
    criticalDefectValue: isHardcodedAQL ? question.criticalDefect?.value : null,
    criticalDefectRule: isHardcodedAQL
      ? question.criticalDefectRule?.value
      : null,

    sampleQty: !useAqlLevel ? question.sampleQty?.value : null,
    sampleRule: !useAqlLevel ? question.sampleRule?.value : null,

    questionWeight: question.questionWeight?.value?.value,
    answerType: question.answerType?.value?.value || null,
    answer: question.answer.value ? { id: question.answer.value.value } : null,
    expectedBarcodeUOM: question.expectedBarcodeUOM.value?.value ?? null,
    photoRequired: question.photoRequired?.value,
    printOnReport: question.printOnReport?.value?.value,
    tools: question.tools?.map((tool) => ({ name: tool.value })),
    assetReferenceDocNames: question.assetReferenceDocNames?.map((doc) => ({
      name: doc.value,
    })),
    defects: question.defects.map((defect) => ({
      id: defect.id,
      weight: defect.weight?.value,
    })),
    documents: question.documents
      .filter((doc) => doc.file.url)
      .map((doc) => ({
        id: doc.file.id,
        documentName: doc.value,
      })),

    useDependency,
    dependencyAction: question.dependencyAction?.value?.value || null,
    dependencyCriteria:
      (useCustomAnswer ? DEPENDENCY.SPECIFIC_ANSWER : criteriaValue) || null,
    dependencyAnswerOption: useCustomAnswer ? criteriaValue : null,
    dependencyQuestion:
      question.dependencyAction.value?.value !== false
        ? {
          order: question.dependencyQuestion?.value?.value,
        }
        : null,

    useDynamic: question.dynamicAction.value?.value !== false,
    dynamicAction: question.dynamicAction?.value?.value || null,
    dynamicCriteria: question.dynamicCriteria
      ? question.dynamicCriteria.value
      : null,
    dynamicRule: question.dynamicRule?.value?.value || null,

    expectedMeasureTableResult:
      question.answerType.value?.value === 'quantitative_input'
        ? question.expectedMeasureTableResult?.value?.value || null
        : null,
    individualMeasurementsRequired:
      question.answerType.value?.value === 'quantitative_input' &&
        (!question.expectedMeasureTableResult?.value?.value ||
          question.expectedMeasureTableResult?.value?.value === 'Custom' ||
          question.expectedMeasureTableResult?.value?.value === 'Other')
        ? question.individualMeasurementsRequired.value
        : undefined,
    customExpectedMeasureValue:
      question.answerType.value?.value === 'quantitative_input' &&
        question.expectedMeasureTableResult?.value?.value === 'Custom'
        ? question.customExpectedMeasureValue?.value
        : null,

    customExpectedUom:
      question.answerType.value?.value === 'quantitative_input' &&
        question.expectedMeasureTableResult?.value?.value === 'Custom'
        ? {
          id: question.customExpectedUom?.value?.value,
        }
        : null,
    otherMeasure:
      question.answerType.value?.value === 'quantitative_input' &&
        question.expectedMeasureTableResult?.value?.value === 'Other'
        ? question.otherMeasure?.value?.value
        : null,

    lowerToleranceValue:
      question.answerType.value?.value === 'quantitative_input' &&
        question.lowerTolerance.value !== undefined
        ? question.lowerTolerance.value
        : null,
    lowerToleranceRule:
      question.answerType.value?.value === 'quantitative_input' &&
        question.lowerTolerance.value !== undefined
        ? question.lowerToleranceRule.value
        : null,
    lowerToleranceWeight:
      question.answerType.value?.value === 'quantitative_input' &&
        question.lowerTolerance.value !== undefined
        ? question.lowerToleranceWeight.value?.value
        : null,

    upperToleranceValue:
      question.answerType.value?.value === 'quantitative_input' &&
        question.upperTolerance.value !== undefined
        ? question.upperTolerance.value
        : null,
    upperToleranceRule:
      question.answerType.value?.value === 'quantitative_input' &&
        question.upperTolerance.value !== undefined
        ? question.upperToleranceRule.value
        : null,
    upperToleranceWeight:
      question.answerType.value?.value === 'quantitative_input' &&
        question.upperTolerance.value !== undefined
        ? question.upperToleranceWeight.value?.value
        : null,
  };
  return mapped;
};

const detailQuestionToPOSTParams = (question) => {
  const mapped = {
    ...question,
    type: question.type.value,
    aqlLevel: question.aqlLevel?.value,
    answerType: question.answerType.value,
    answer: question.answer?.value ? { id: question.answer.value } : null,
    dynamicAction: question.dynamicAction?.value || null,
    expectedBarcodeUOM: question.expectedBarcodeUOM?.value || null,
    dynamicRule: question.dynamicRule?.value || null,
    dependencyAction: question.dependencyAction?.value || null,
    printOnReport: question.printOnReport?.value,
    expectedMeasureTableResult:
      question.expectedMeasureTableResult?.value || null,
    documents: question.documents.map((doc) => ({
      ...doc,
      documentName: doc.documentName ?? doc.name,
    })),
    questionWeight: question.questionWeight?.value || null,
    defects: question.defects.map((defect) => ({
      ...defect,
      weight: defect.weight.value,
    })),
  };
  return mapped;
};

export const questionGroupToFormState = (group, questionOptions) => ({
  ...group,
  questions: group.questions.map((q) =>
    groupedQuestionToFormState(q, questionOptions),
  ),
});

export const questionGroupTemplateToFormState = (group) => ({
  ...group,
  questions: group.questions.map((q) => ({
    ...q,
    // TODO: Merge the new transform func in the use...form here
  })),
  //  originalTemplateId: group.templateId,
});

const questionGroupToPOSTParams = (group, initialGroup, editFlags) => {
  const rslt = {
    ...group,
    templateId: group.isLinked ? group.originalTemplateId : null, // XXX: Used anywhere?
    questions: group.questions.map((question, idx) =>
      editFlags.includes(idx) || !question.originalTemplateId
        ? formQuestionToPOSTParams(question)
        : detailQuestionToPOSTParams(initialGroup.questions[idx]),
    ),
  };
  return rslt;
};

// XXX: formStateToPOSTParams
// Extract overridden q's
// Split qt to gqt
// XXX: Relink 2nd time tries to get group from server with ID null
export const formStateToPOSTParams = (state) => {
  const getGroupQuestionEditFlags = (groupOriginalTemplateId) => {
    return Object.entries(state.questionEditFlags).reduce(
      (acc, [idx, editFlag]) => {
        const [flagGroupId, flagQuestionIdx] = idx.split('-').map(Number);
        if (flagGroupId === groupOriginalTemplateId && editFlag === true) {
          return [...acc, flagQuestionIdx];
        }
        return acc;
      },
      [],
    );
  };
  const hasAqlLevel = !!state.aqlLevel?.value?.value;
  return {
    name: pipe(normalizeWhitespace)(state.name.value),
    description: pipe(normalizeWhitespace)(state.description.value),
    inspectionType: { id: state.type.value.value },
    status: state.status.value,
    validityValue: state.validity.value || null,
    validityRange: state.validityRange?.value?.value || null,
    aqlLevel: state.aqlLevel?.value?.value || null,
    majorDefect: hasAqlLevel ? state.aqlMajor?.value?.value : null,
    minorDefect: hasAqlLevel ? state.aqlMinor?.value?.value : null,
    functionalDefect: hasAqlLevel ? state.aqlFunctional?.value?.value : null,
    criticalDefectValue: hasAqlLevel ? state.criticalDefect.value : undefined,
    criticalDefectRule: hasAqlLevel
      ? state.criticalDefectRule.value
      : undefined,
    linkedResource: state.linkedResource
      ? {
        id: state.linkedResource.id,
        type: state.linkedResource.resourceType,
      }
      : null,
    useDependency: false,
    useDynamic: false,
    useAqlLevel: !!(
      state.aqlLevel.value &&
      state.aqlMajor.value &&
      state.aqlMinor.value
    ),
    questionGroups: state.questionGroups.map((group, idx) => {
      const flags = getGroupQuestionEditFlags(Number(group.originalTemplateId));
      const initialGroup = state.initialQuestionGroups[idx];

      return questionGroupToPOSTParams(group, initialGroup, flags);
    }),
  };
};

function parseResultStatus(status) {
  return Object.values(RESULT_STATUS).includes(status)
    ? status
    : RESULT_STATUS.UNKNOWN;
}

const compileStatusTotals = (item) =>
  !!item ? Object.keys(item)
    .map((key) =>
      Object.keys(item[key].inspectionTypes).map(
        (type) => item[key].inspectionTypes[type],
      ),
    )
    .flat()
    .reduce(
      (acc, t) => ({
        ...acc,
        [t.result]: acc[t.result] ? acc[t.result] + 1 : 1,
      }),
      {},
    ) : {};

export function bulkPlansToDetailState(res) {
  const { asset, source } = res;
  return {
    assetTotal: {
      [RESULT_STATUS.SUCCESS]: asset?.successCount,
      [RESULT_STATUS.FAILED]: asset?.failedCount,
      [RESULT_STATUS.SKIPPED]: asset?.skippedCount,
      ...compileStatusTotals(asset?.items),
    },
    sourceTotal: {
      [RESULT_STATUS.SUCCESS]: source?.successCount,
      [RESULT_STATUS.FAILED]: source?.failedCount,
      [RESULT_STATUS.SKIPPED]: source?.skippedCount,
      ...compileStatusTotals(source?.items),
    },
    assets: Object.keys(asset?.items ?? {}).map((k) => ({
      name: asset.items[k].name,
      link: `/assets/${k}`,
      types: Object.keys(asset.items[k].inspectionTypes).map((t) => {
        const type = asset.items[k].inspectionTypes[t];
        return {
          name: type.name,
          status: parseResultStatus(type.result),
          link: type.inspectionPlanId
            ? `/inspection-plans/${type.inspectionPlanId}`
            : false,
        };
      }),
    })),
    sources: Object.keys(source?.items ?? {}).map((k) => ({
      name: source.items[k].name,
      link: `/sources/${k}`,
      types: Object.keys(source.items[k].inspectionTypes).map((t) => {
        const type = source.items[k].inspectionTypes[t];
        return {
          name: type.name,
          status: parseResultStatus(type.result),
          link: type.inspectionPlanId
            ? `/inspection-plans/${type.inspectionPlanId}`
            : false,
        };
      }),
    })),
  };
}

const pruneFilter = (array, transform = id) =>
  !!array.length ? array.map(transform) : undefined;

const csvTextToArrayFilter = (textStr) => {
  const output = textStr?.length ? textStr.split(",").map(v => v.trim()).filter(v => v.length) : [];

  return output?.length ? output : undefined;
}

export const getAssetFilters = (filters) => ({
  search: csvTextToArrayFilter(filters.assetTextSearch),
  type: pruneFilter(filters.assetType, (f) => f.label),
  customAttributes: !!filters.customAttribute.length
    ? filters.customAttribute.reduce(
      (acc, key) => ({
        ...acc,
        [key.groupLabel]:
          key.value === null
            ? true
            : [...(acc[key.groupLabel] || []), key.label],
      }),
      {},
    )
    : undefined,
  companyAttributes: !!filters.companyAttribute.length
    ? filters.companyAttribute.reduce(
      (acc, key) => ({
        ...acc,
        [key.groupLabel]:
          key.value === null
            ? true
            : [...(acc[key.groupLabel] || []), key.label],
      }),
      {},
    )
    : undefined,
  status: pruneFilter(filters.assetStatus, (f) => f.value),
  missingPlans: filters.assetMissingPlans || undefined,
  inspectionType:
    pruneFilter(filters.assetInspectionType, (f) => f.label) ?? undefined,
  sourceInspectionType: undefined,
  assetInspectionType: undefined,
});

export const getSourceFilters = (filters) => ({
  search: csvTextToArrayFilter(filters.sourceTextSearch),
  country: pruneFilter(filters.sourceCountry, (f) => f.value),
  tag: pruneFilter(filters.sourceTag, (f) => f.label),
  type: pruneFilter(filters.sourceType, (f) => f.label),
  missingPlans: filters.sourceMissingPlans || undefined,
  inspectionType:
    pruneFilter(filters.sourceInspectionType, (f) => f.label) ?? undefined,
  sourceInspectionType: undefined,
  assetInspectionType: undefined,
});

export const autoBuildPlanToPOSTParams = (state) => {
  const isAllAssets = state.linkedResources.assets.isAllSelected;
  const isAllSources = state.linkedResources.sources.isAllSelected;

  return {
    assetIds: isAllAssets ? 'all' : state.linkedResources.assets.selected,
    assetFilters: isAllAssets ? getAssetFilters(state.filters) : undefined,

    sourceIds: isAllSources ? 'all' : state.linkedResources.sources.selected,
    sourceFilters: isAllSources ? getSourceFilters(state.filters) : undefined,

    overrideExistingPlans: state.overridePlans.value,
    overrideUserEditedPlans:
      state.overridePlans.value && state.overrideUserPlans.value,
    inspectionPlanParams: state.bulkParams.map((param) => ({
      inspectionTypeId: param.type.value?.value,
      validityValue: param.validity.value || undefined,
      validityRange: param.validity.value
        ? param.validityRange.value?.value
        : undefined,
      aqlLevel: param.aqlLevel.value?.value,
      majorDefect: param.aqlMajor.value?.value,
      minorDefect: param.aqlMinor.value?.value,
      functionalDefect: param.aqlFunctional.value?.value,
      criticalDefectValue: param.criticalDefect.value,
      criticalDefectRule: param.criticalDefectRule.value,
    })),
  };
};
