import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';

import {
  PHOTO_REQUIRED_LABEL,
  MULTIPLE_CHOICE_ANSWER_TYPES,
  RESULT_RECORDING_TYPE,
  RESULT_RECORDING_TYPE_LABEL,
} from 'config/questionOptions';
import { getTranslation } from 'lib/dataTransform';
import {
  formatDependency,
  formatDetailQuestionSampleSize,
  formatDynamicModifier,
  formatExpectedResult,
} from './dataTransform';

import Toggle from 'lib/components/toggle/Toggle';
import File from 'lib/components/file/File';
import QuestionActions from './QuestionActions';

import './QuestionDetail.scss';

export const QuestionDetail = React.memo(
  ({
    data,
    children,
    onEditChange,
    editVariant = 'none',
    id = '',
    hasErrors = false,
    hideDependency = false,
    dependencyFormatter = formatDependency,
    expectedResultFormatter = formatExpectedResult,
    showHeaderDependency = false,
    language = null,
    ...props
  }) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
      if (hasErrors && !isOpen) {
        setOpen(true);
      }
    }, [hasErrors]);

    if (!data) {
      return null;
    }

    const canShowDependency =
      data.useDependency &&
      data.dependencyAction &&
      data.dependencyCriteria &&
      data.dependencyQuestion &&
      !hideDependency;

    const canShowDynamicModifier =
      data.useDynamic &&
      data.dynamicAction &&
      data.dynamicCriteria &&
      data.dynamicRule;

    const isValidCustomExpectedMeasureValue =
      data.customExpectedMeasureValue !== null &&
      data.customExpectedMeasureValue !== undefined;

    const isExpectedResultVisible = data.expectedMeasureTableResult?.value
      ? data.expectedMeasureTableResult.value === 'Custom'
        ? isValidCustomExpectedMeasureValue && data.customExpectedUom
        : true
      : false;

    const isQuestionMultipleChoice = MULTIPLE_CHOICE_ANSWER_TYPES.includes(
      data.answerType?.value,
    );

    const isResultRecordingVisible =
      Object.values(RESULT_RECORDING_TYPE).includes(
        data.individualMeasurementsRequired,
      ) &&
      [undefined, 'Custom', 'Other'].includes(
        data.expectedMeasureTableResult?.value,
      );

    const { translation, fallback } = getTranslation(data.name, language);

    return (
      <div className={classNames('qm-question-detail', { 'is-open': isOpen })}>
        <div className='table-row question-summary'>
          <div className='table-cell edit-cell'>
            <QuestionActions
              editType={editVariant}
              onEdit={onEditChange}
              onMoveUp={props.onMoveUp}
              onMoveTop={props.onMoveTop}
              isMoveUpDisabled={props.isMoveUpDisabled}
              onMoveDown={props.onMoveDown}
              onMoveBottom={props.onMoveBottom}
              isMoveDownDisabled={props.isMoveDownDisabled}
              onDelete={props.onDelete}
              isDeleteDisabled={props.questionOptions?.isDeleteDisabled}
            />
          </div>
          <div className='table-cell id-cell'>{id}</div>
          <div className='table-cell question-cell'>
            <p>
              {translation || (
                <span className='no-translation'>{fallback}</span>
              )}
            </p>
          </div>
          <div className='table-cell question-type-cell'>
            {data.type?.label}
          </div>
          {showHeaderDependency && (
            <div className='table-cell dependency-cell'>
              {canShowDependency && dependencyFormatter(data)}
            </div>
          )}
          <div className='table-cell align-right sample-size-cell'>
            <span className="value">{formatDetailQuestionSampleSize(data)}</span>
          </div>
          <div className='table-cell align-right toggle-cell'>
            <Toggle
              disabled={hasErrors}
              direction={isOpen ? 'up' : 'down'}
              onClick={() => setOpen(!isOpen)}
            />
          </div>
        </div>
        {(isOpen || hasErrors) && (
          <div className='table-row question-body'>
            <div className='table-cell body-cell'>
              <div className='attributes-container'>
                <div className='attribute'>
                  <div className='label'>Question weight</div>
                  <div className='value'>{data.questionWeight?.label}</div>
                </div>
                {isQuestionMultipleChoice ? (
                  <div className='attribute'>
                    <div className='label'>Answers</div>
                    <div className='value'>{data.answer?.label}</div>
                  </div>
                ) : (
                  <div className='attribute'>
                    <div className='label'>Answer</div>
                    <div className='value'>{data.answerType?.label}</div>
                  </div>
                )}
                <div className='attribute'>
                  <div className='label'>Photo</div>
                  <div className='value'>
                    {PHOTO_REQUIRED_LABEL[data.photoRequired]}
                  </div>
                </div>

                {!showHeaderDependency && canShowDependency && (
                  <div className='attribute'>
                    <div className='label'>Dependency</div>
                    <div className='value'>{dependencyFormatter(data)}</div>
                  </div>
                )}
                {canShowDynamicModifier && (
                  <div className='attribute'>
                    <div className='label'>Dynamic modifier</div>
                    <div className='value'>{formatDynamicModifier(data)}</div>
                  </div>
                )}
                {data.documents.length > 0 && (
                  <div className='attribute file-list-container'>
                    <div className='label'>Reference documents</div>
                    <div className='file-list'>
                      {data.documents.map((doc, idx) => (
                        <a
                          rel='noreferrer'
                          href={doc.url}
                          target='_blank'
                          key={idx}
                        >
                          <File data={doc} name={doc.documentName} />
                        </a>
                      ))}
                    </div>
                  </div>
                )}
                {!!data.assetReferenceDocNames?.length && (
                  <div className='attribute file-list-container'>
                    <div className='label'>Asset Reference Documents</div>
                    <div className='file-list'>
                      {data.assetReferenceDocNames.map((docName, idx) => {
                        const assetDoc =
                          data.assetReferenceDocs?.find(
                            (doc) => doc?.documentName === docName.name,
                          ) ?? null;

                        return assetDoc ? (
                          <a
                            className='document-link'
                            rel='noreferrer'
                            href={assetDoc.url}
                            target='_blank'
                            key={idx}
                          >
                            <File
                              data={assetDoc}
                              name={assetDoc.documentName}
                            />
                          </a>
                        ) : (
                          <span className='document-link disabled' key={idx}>
                            <File data={docName} name={docName.name} />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
                {data.tools.length > 0 && (
                  <div className='attribute'>
                    <div className='label'>Tools needed</div>
                    <div className='value'>
                      {data.tools.map((tool, idx) => (
                        <div key={idx}>{tool.name}</div>
                      ))}
                    </div>
                  </div>
                )}
                {data.answerType.value === 'quantitative_input' && (
                  <>
                    {isExpectedResultVisible && (
                      <div className='attribute'>
                        <div className='label'>Expected result</div>
                        <div className='value'>
                          {expectedResultFormatter(data)}
                        </div>
                      </div>
                    )}
                    {isResultRecordingVisible && (
                      <div className='attribute'>
                        <div className='label'>Result recording</div>
                        <div className='value'>
                          {
                            RESULT_RECORDING_TYPE_LABEL[
                            data.individualMeasurementsRequired
                            ]
                          }
                        </div>
                      </div>
                    )}
                    {data.upperToleranceValue !== null && (
                      <div className='attribute'>
                        <div className='label'>Upper tolerance</div>
                        <div className='value'>
                          {data.upperToleranceValue}
                          {data.upperToleranceRule === '%'
                            ? '%'
                            : ` ${data.customExpectedUom?.label || ''}`}
                        </div>
                      </div>
                    )}
                    {data.lowerToleranceValue !== null && (
                      <div className='attribute'>
                        <div className='label'>Lower tolerance</div>
                        <div className='value'>
                          {data.lowerToleranceValue}
                          {data.lowerToleranceRule === '%'
                            ? '%'
                            : ` ${data.customExpectedUom?.label || ''}`}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {data.defects.length > 0 && (
                  <div className='attribute'>
                    <div className='label'>Defect list</div>
                    <div className='value'>
                      {data.defects.map((defect, idx) => (
                        <div key={idx}>
                          {getTranslation(defect.name).display} -{' '}
                          {defect.weight?.label}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {children}
            </div>
          </div>
        )}
      </div>
    );
  },
  (a, b) => _isEqual(a, b),
);

QuestionDetail.propTypes = {
  data: PropTypes.object,
};

export default QuestionDetail;
