import { useEffect, useReducer } from 'react';
import debounce from 'lodash.debounce';
import {
  reducer,
  initialState,
  SETTINGS_DEFECT_ACTIONS,
} from './settingsDefectsListReducer';
import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryParams from 'lib/useQueryParams';
import useLanguages from 'lib/languageService';
import settingsService from './settingsService';
import settingsDefectsFormValidator from './settingsDefectsFormValidator';
import { upsertTranslation } from 'lib/dataTransform';
import { downloadResponse } from 'lib/download';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const fetchSettingsDefects = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return settingsService
    .getSettingsDefects(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useSettingsDefectsList = ({ location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setParams, getParams } = useQueryParams(location, history);
  const { languages } = useLanguages();

  useNavigationPrompt(state.addDefect?.isDirty || state.editField?.isDirty);

  const defectParams = {
    order: {
      [state.sortBy]: state.sortOrder,
    },
    search: state.search,
    page: state.page,
    pageSize: state.pageSize,
  };

  const addDefect = () => {
    settingsDefectsFormValidator(state.addDefect)
      .then((data) =>
        settingsService.saveDefect(data).then(() => {
          fetchSettingsDefects(defectParams, dispatch);
          dispatch({
            type: SETTINGS_DEFECT_ACTIONS.USER_CANCELS_INPUT_MODAL,
          });
        }),
      )
      .catch((e) => {
        dispatch({
          type: SETTINGS_DEFECT_ACTIONS.RESET_STATE,
          payload: {
            addDefect: e.payload,
          },
        });
      });
  };

  const editDefect = () => {
    settingsDefectsFormValidator(state.editField)
      .then((data) =>
        settingsService.saveDefect(data).then(() => {
          fetchSettingsDefects(defectParams, dispatch);
          dispatch({
            type: SETTINGS_DEFECT_ACTIONS.CLEAR_EDIT_DEFECT,
          });
        }),
      )
      .catch((e) => {
        dispatch({
          type: SETTINGS_DEFECT_ACTIONS.RESET_STATE,
          payload: {
            editField: e.payload,
          },
        });
      });
  };
  const exportDefects = () => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.APP_STARTS_EXPORTING_DEFECTS,
    });
    settingsService
      .exportSettingsDefects()
      .then((data) => {
        downloadResponse(data, 'defects.csv');
      })
      .finally(() =>
        dispatch({
          type: SETTINGS_DEFECT_ACTIONS.APP_FINISHES_EXPORTING_DEFECTS,
        }),
      );
  };

  useEffect(async () => {
    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: getParams(),
    });
  }, []);

  useEffect(() => {
    fetchSettingsDefects(defectParams, dispatch);
  }, [state.sortBy, state.sortOrder, state.search, state.page, state.pageSize]);

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  const userSelectsEditDefect = (name, id) => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.SET_EDIT_DEFECT,
      payload: {
        name,
        id,
      },
    });
  };

  const userClearsEditDefect = () => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.CLEAR_EDIT_DEFECT,
    });
  };

  const userTypesDefectName = (e) => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_CHANGES_INPUT_EDIT,
      payload: upsertTranslation(
        state.editField.name.value,
        e.target.value,
        state.language,
      ).filter((t) => languages.find((l) => l.code === t.language)),
    });
  };

  const userTypesNewDefect = (e) => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_CHANGES_INPUT_ADD,
      payload: upsertTranslation(
        state.addDefect.name.value,
        e.target.value,
        state.language,
      ),
    });
  };

  const userOpensInputModal = () => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_OPENS_INPUT_MODAL,
    });
  };
  const userCancelsInputModal = () => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_CANCELS_INPUT_MODAL,
    });
  };
  const userSubmitsDefect = () => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_SUBMITS_DEFECT,
    });
  };

  const userSetsLanguage = (code) => {
    dispatch({
      type: SETTINGS_DEFECT_ACTIONS.USER_SETS_LANGUAGE,
      payload: code,
    });
  };

  return {
    state,
    dispatch,
    languages,
    userSetsLanguage,
    userSelectsEditDefect,
    userClearsEditDefect,
    sortBy,
    setPage,
    setPageSize,
    setSearch,
    userTypesDefectName,
    userOpensInputModal,
    userCancelsInputModal,
    userSubmitsDefect,
    userTypesNewDefect,
    addDefect,
    editDefect,
    exportDefects,
  };
};

export default useSettingsDefectsList;
