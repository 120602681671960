import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportCompanyAttributesPage.scss';

export default function ImportCompanyAttributesPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='settings-import-company-attributes-page'>
      <h4>Import company attributes</h4>
      <p className='form-info'>
        Before importing company attributes, please make sure you structure your
        data like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a
          href='documents/sample-company-attributes.csv'
          target='_blank'
          download
        >
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='custom-attributes' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={{
          name: {
            'asset.unique': () => 'Company attribute already exists',
            'customAttributes.unique': () => 'Company attribute already exists',
          },
          label: {
            'any.required': () => 'Company attribute name is required',
          },
          options: {
            'any.required': () => 'There should be at least one option',
          },
        }}
      />
    </div>
  );
}
