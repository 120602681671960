import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const SETTINGS_ASSET_STATUSES_ACTIONS = {
  SET_EDIT_ASSET_STATUS: 'SET_EDIT_ASSET_STATUS',
  CLEAR_EDIT_ASSET_STATUS: 'CLEAR_EDIT_ASSET_STATUS',
  USER_CHANGES_INPUT_EDIT: 'USER_CHANGES_INPUT_EDIT',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_ASSET_STATUSES_STATE = {
  editField: {
    name: {
      value: '',
      errors: [],
    },
    id: null,
    editing: false,
    errors: [],
    isDirty: false,
  },
};

const SETTINGS_ASSET_STATUSES_REDUCER_CONFIG = {
  [SETTINGS_ASSET_STATUSES_ACTIONS.SET_EDIT_ASSET_STATUS]: (state, action) => {
    return {
      ...state,
      editField: {
        name: {
          value: action.payload.name,
          errors: [],
        },
        id: action.payload.id,
        editing: true,
        errors: [],
        isDirty: false,
      },
    };
  },
  [SETTINGS_ASSET_STATUSES_ACTIONS.CLEAR_EDIT_ASSET_STATUS]: (state) => {
    return {
      ...state,
      editField: {
        name: {
          value: '',
          errors: [],
        },
        id: null,
        editing: false,
        errors: [],
        isDirty: false,
      },
    };
  },
  [SETTINGS_ASSET_STATUSES_ACTIONS.USER_CHANGES_INPUT_EDIT]: (
    state,
    action,
  ) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        name: { value: action.payload },
        errors: [],
        isDirty: true,
      },
    };
  },
  [SETTINGS_ASSET_STATUSES_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  SETTINGS_ASSET_STATUSES_REDUCER_CONFIG,
  INITIAL_ASSET_STATUSES_STATE,
);
