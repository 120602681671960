import classNames from 'classnames';
import { useHistory } from 'react-router';

import './FloatingCard.scss';

export default function FloatingCard({ icon, description, value, link }) {
  const history = useHistory();

  return (
    <div
      className={classNames('floating-card', {
        clickable: !!link?.length,
      })}
      onClick={() => !!link?.length && history.push(link)}
    >
      {icon && <div className='card-icon'>{icon}</div>}
      {typeof value !== undefined && <div className='card-value'>{value}</div>}
      {description && <div className='card-description'>{description}</div>}
    </div>
  );
}
