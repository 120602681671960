import ProgressBar from 'components/SystemComponents/ProgressBar';
import { useMemo, useRef } from 'react';
import './StatusProgressPanel.scss';

const getProcessedCount = (importProgressData) => {
  const rslt =
    (importProgressData?.failedCount ?? 0)
    + (importProgressData?.warningCount ?? 0)
    + (importProgressData?.successCount ?? 0);

  return isFinite(rslt) && rslt >= 0 ? rslt : 0;
}

const getProgressPercentages = (importProgressData) => {
  const counts = [importProgressData?.failedCount ?? 0, importProgressData?.warningCount ?? 0, importProgressData?.successCount ?? 0];

  let totalCount = Math.max((importProgressData?.totalCount ?? 0), (counts[0] + counts[1] + counts[2]));

  const percentList = counts.map(v => v > 0 && totalCount > 0 ? (v / totalCount * 100).toFixed(1) : 0);

  return percentList;
}

const calcSpeed = (list, i1, i2) => {
  const timeDiff = list[i2].time - list[i1].time;
  const itemDiff = list[i2].items - list[i1].items;
  return timeDiff > 0 ? itemDiff / timeDiff : 0;
};

const updateSpeedData = (speedData, processedCount) => {
  if (!Array.isArray(speedData)) return;

  speedData.push({
    time: Date.now() / 1000,
    items: processedCount,
  });

  if (speedData.length > 10)
    speedData.shift();
}

const getAvgSpeed = (speedData) => {
  if (!Array.isArray(speedData)) return 0;

  // We need min 2 points to calc speed and min 3 to avg between 2 pointst to prevent initial number jumps
  if (speedData?.length < 3) return 0;

  let speeds = [];

  for (let i = 1; i < speedData?.length; i++) {
    speeds.push(calcSpeed(speedData, i - 1, i));
  }

  const avgSpeed = speeds.reduce((acc, speed) => acc + speed, 0) / speeds.length;
  return avgSpeed;
}

function StatusProgressPanel({ statusData }) {
  const speedData = useRef([]);

  const importProgressData = useMemo(() => {
    const newImportProgressData = {
      status: statusData.status ?? null,
      totalCount: statusData?.totalCount ?? 0,
      successCount: statusData?.successCount ?? 0,
      warningCount: statusData?.warningCount ?? 0,
      failedCount: statusData?.failedCount ?? 0,
      processedCount: 0,
      processedSpeed: 0,
      isImporting: false,
    };

    newImportProgressData.processedCount = getProcessedCount(newImportProgressData);

    updateSpeedData(speedData.current, newImportProgressData.processedCount);

    newImportProgressData.processedSpeed = getAvgSpeed(speedData.current);

    return newImportProgressData;

  }, [JSON.stringify(statusData)]);

  return (
    <div className='status-progress-panel'>
      <div className='progressContainer'>
        <ProgressBar percent={getProgressPercentages(importProgressData)} />
      </div>
      <div className='progress-info'>
        {/* <strong>{importProgressData?.processedCount}</strong> of <strong>{importProgressData?.totalCount}</strong> items */}
        <strong>{importProgressData?.processedCount}</strong> items processed
        (<strong>{importProgressData?.successCount}</strong> successful{
          importProgressData?.warningCount > 0 && (
            <>,<strong>{importProgressData?.warningCount}</strong> warnings</>
          )
        }{
          importProgressData?.failedCount > 0 && (
            <>, <strong>{importProgressData?.failedCount}</strong> errors</>
          )
        })
        {importProgressData?.processedSpeed >= 0.1 && (
          <>,{' '}<strong>{importProgressData?.processedSpeed?.toFixed(importProgressData?.processedSpeed < 2 ? 1 : 0)}</strong> items per second</>
        )}
      </div>
    </div>
  );
}

export default StatusProgressPanel;
