import React from 'react';
import SearchInput from 'lib/components/search-input/SearchInput';
import AddButton from 'lib/components/add-button/AddButton';
import InspectionPlansTable from 'lib/components/inspection-plans-table/InspectionPlansTable';
import useInspectionPlansList from '../useInspectionPlansList';

import './InspectionPlansListPage.scss';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { ADD_PLANS_ROLES } from '../inspectionPlansPermissions';

export default function InspectionPlansListPage(props) {
  const list = useInspectionPlansList(props);
  return (
    <div className='inspection-plans-list-page content'>
      <div className='row inspection-plans-list-header container-m'>
        <h2>Inspection plans</h2>

        <div className='add-new-container'>
          <ProtectedComponent allowed={ADD_PLANS_ROLES}>
            <AddButton
              onClick={list.userClicksOnAdd}
              data-testid='InspectionPlansListPage.add'
            >
              New inspection plan
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='InspectionPlansListPage.search'
          />
        </div>
      </div>
      <div className='inspection-plans-table'>
        <InspectionPlansTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
          overflowX
        />
      </div>
    </div>
  );
}
