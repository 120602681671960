import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const SETTINGS_ASSET_TYPES_ACTIONS = {
  SET_EDIT_ASSET_TYPE: 'SET_EDIT_ASSET_TYPE',
  CLEAR_EDIT_ASSET_TYPE: 'CLEAR_EDIT_ASSET_TYPE',
  USER_CHANGES_INPUT_EDIT: 'USER_CHANGES_INPUT_EDIT',
  USER_CHANGES_INPUT_ADD: 'USER_CHANGES_INPUT_ADD',
  USER_OPENS_INPUT_MODAL: 'USER_OPENS_INPUT_MODAL',
  USER_CANCELS_INPUT_MODAL: 'USER_CANCELS_INPUT_MODAL',
  RESET_STATE: 'RESET_STATE',
};

const getInitialModalState = {
  name: { value: '', errors: [] },
  isModalOpen: false,
  isDirty: false,
};

const INITIAL_ASSET_TYPES_STATE = {
  editField: {
    name: {
      value: '',
      errors: [],
    },
    id: null,
    editing: false,
    isDirty: false,
  },
  addAssetType: getInitialModalState,
};

const SETTINGS_ASSET_TYPES_REDUCER_CONFIG = {
  [SETTINGS_ASSET_TYPES_ACTIONS.SET_EDIT_ASSET_TYPE]: (state, action) => {
    return {
      ...state,
      editField: {
        name: { value: action.payload.label, errors: [] },
        id: action.payload.value,
        editing: true,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.CLEAR_EDIT_ASSET_TYPE]: (state) => {
    return {
      ...state,
      editField: {
        name: { value: '', errors: [] },
        id: null,
        editing: false,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.USER_CHANGES_INPUT_ADD]: (state, action) => {
    return {
      ...state,
      addAssetType: {
        ...state.addAssetType,
        name: { value: action.payload, errors: [] },
        isDirty: true,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.USER_CHANGES_INPUT_EDIT]: (state, action) => {
    return {
      ...state,
      editField: {
        ...state.editField,
        name: { value: action.payload, errors: [] },
        isDirty: true,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.USER_OPENS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addAssetType: {
        ...state.addAssetType,
        isModalOpen: true,
        isDirty: false,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.USER_CANCELS_INPUT_MODAL]: (state) => {
    return {
      ...state,
      addAssetType: {
        ...state.addAssetType,
        name: { value: '', errors: [] },
        isModalOpen: false,
        isDirty: false,
      },
    };
  },
  [SETTINGS_ASSET_TYPES_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export const { reducer, initialState } = makeListReducer(
  SETTINGS_ASSET_TYPES_REDUCER_CONFIG,
  INITIAL_ASSET_TYPES_STATE,
);
