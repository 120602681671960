import Button from 'lib/components/button/Button';
import Modal from '../modal/Modal';

import useConfirm from './useConfirm';

import './ConfirmationDialog.scss';

const ConfirmationDialog = () => {
  const { onConfirm, onCancel, confirmState } = useConfirm();
  const { body, title, confirm, cancel, isOpen } = confirmState;
  return (
    <Modal
      className='qm-confirmation-dialog'
      isOpen={isOpen}
      onRequestClose={onCancel}
      actions={
        <>
          <Button className='medium-button' onClick={onConfirm}>
            {confirm}
          </Button>
          <Button className='link-button' onClick={onCancel}>
            {cancel}
          </Button>
        </>
      }
      title={title}
    >
      {body}
    </Modal>
  );
};

export default ConfirmationDialog;
