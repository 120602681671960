import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './ImportAssets.scss';

const assetLabels = {
  assetOwners: 'Asset Owner',
  id: 'ID',
  externalId: 'External ID',
  images: 'Asset Image',
  name: 'Name',
  status: 'Asset Status',
  shortDescription: 'Short Description',
  type: 'Asset Type',
};

const errorGenerator = {
  __root__: {
    'csv_row.less': () => 'The line have less columns than the expected',
    'csv_row.greater': () => 'The line have more columns than the expected',
  },
  asset: {
    'asset.notFound': () => 'ID not found',
    'asset.idMismatch': () => 'ID and External ID do not match',
  },
  externalId: {
    'string.max': () => 'External ID must have less than 50 characters',
    'string.empty': () => 'External ID is required',
    'asset.unique': () => 'Asset External ID already exists',
  },
  name: {
    'asset.imageError': () => 'Error uploading asset image',
    'any.required': () => 'Name is required',
    'string.empty': () => 'Name is required',
    'string.max': () => 'Name must have less than 50 characters',
  },
  status: {
    'any.only': () => 'Asset Status must be a valid one',
  },
  shortDescription: {
    'string.max': () =>
      'The assets description must have less than 250 characters',
    'string.empty': () => 'Short Description is required',
  },
  type: {
    'any.required': () => 'Asset Type is required',
    'string.empty': () => 'Asset Type is required',
  },
  images: {
    'url.unreachable': () => 'Asset Image contains an unreachable URL',
    'file.invalid_mimetype': () => 'Asset Image must be jpeg or png',
    'file.max_length_exceeded': () => 'Asset Image must not exceed 5MB',
    'string.uri': () => 'Asset Image must be a valid URL',
  },
  assetOwners: {
    'assetOwners.notFound': () =>
      'Asset Owner must be a valid user with Asset Owner role assigned to your company',
  },
  __default__: {
    'object.unknown': ({ field }) => `${field} is not a valid column`,
    'string.max': ({ field }) =>
      `Column '${field}' must have less than 250 characters`,
    'string.empty': ({ line, label }) =>
      line === 1 ? 'File has an empty column header' : `${label} is required`,
    __default__: ({ field }) => `${field} has an unknown error`,
  },
};

export default function ImportAssets(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='import-assets-page'>
      <h2>Import assets</h2>
      <p className='form-info'>
        Before importing assets, please make sure you structure your data like
        in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/assets.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='assets' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={errorGenerator}
        columnLabels={assetLabels}
      />
    </div>
  );
}
