import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import {
  reducer,
  initialState,
  SETTINGS_ASSET_TYPES_ACTIONS,
} from './settingsAssetTypesListReducer';
import debounce from 'lodash.debounce';
import settingsService from './settingsService';
import { useEffect, useReducer } from 'react';
import useQueryParams from 'lib/useQueryParams';
import settingsAssetTypesFormValidator from './settingsTypesFormValidator';
import useNavigationPrompt from 'lib/useNavigationPrompt';

const fetchSettingsAssetTypes = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return settingsService
    .getSettingsAssetTypes(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: results,
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useSettingsAssetTypesList = ({ location, history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setParams, getParams } = useQueryParams(location, history);

  useNavigationPrompt(state.addAssetType?.isDirty || state.editField?.isDirty);

  const userSubmitsNewAssetType = () => {
    settingsAssetTypesFormValidator(state.addAssetType, 'Asset type')
      .then((data) =>
        settingsService.saveAssetType(data).then(() => {
          fetchSettingsAssetTypes(
            { page: state.page, pageSize: state.pageSize },
            dispatch,
          );
          dispatch({
            type: SETTINGS_ASSET_TYPES_ACTIONS.USER_CANCELS_INPUT_MODAL,
          });
        }),
      )
      .catch((e) => {
        console.log(e.payload);
        dispatch({
          type: SETTINGS_ASSET_TYPES_ACTIONS.RESET_STATE,
          payload: {
            addAssetType: e.payload,
          },
        });
      });
  };

  const editSubmitsEditAssetType = () => {
    settingsAssetTypesFormValidator(state.editField, 'Asset type')
      .then((data) =>
        settingsService.saveAssetType(data).then(() => {
          fetchSettingsAssetTypes(
            { page: state.page, pageSize: state.pageSize },
            dispatch,
          );
          dispatch({
            type: SETTINGS_ASSET_TYPES_ACTIONS.CLEAR_EDIT_ASSET_TYPE,
          });
        }),
      )
      .catch((e) => {
        console.log(e);
        dispatch({
          type: SETTINGS_ASSET_TYPES_ACTIONS.RESET_STATE,
          payload: {
            editField: e.payload,
          },
        });
      });
  };

  useEffect(async () => {
    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: getParams(),
    });
  }, []);

  useEffect(() => {
    fetchSettingsAssetTypes(
      {
        order: {
          [state.sortBy]: state.sortOrder,
        },
        search: state.search,
        page: state.page,
        pageSize: state.pageSize,
      },
      dispatch,
    );
  }, [state.sortBy, state.sortOrder, state.search, state.page, state.pageSize]);

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  const userSelectsEditAssetType = (label, value) => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.SET_EDIT_ASSET_TYPE,
      payload: {
        label,
        value,
      },
    });
  };

  const userClearsEditAssetType = () => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.CLEAR_EDIT_ASSET_TYPE,
    });
  };

  const userTypesAssetTypeName = (e) => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.USER_CHANGES_INPUT_EDIT,
      payload: e.target.value,
    });
  };

  const userTypesNewAssetTypeLabel = (e) => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.USER_CHANGES_INPUT_ADD,
      payload: e.target.value,
    });
  };

  const userOpensInputModal = () => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.USER_OPENS_INPUT_MODAL,
    });
  };
  const userCancelsInputModal = () => {
    dispatch({
      type: SETTINGS_ASSET_TYPES_ACTIONS.USER_CANCELS_INPUT_MODAL,
    });
  };

  return {
    state,
    dispatch,
    userSelectsEditAssetType,
    userClearsEditAssetType,
    sortBy,
    setPage,
    setPageSize,
    setSearch,
    userTypesAssetTypeName,
    userOpensInputModal,
    userCancelsInputModal,
    userTypesNewAssetTypeLabel,
    userSubmitsNewAssetType,
    editSubmitsEditAssetType,
  };
};

export default useSettingsAssetTypesList;
