import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import SearchInput from 'lib/components/search-input/SearchInput';

import './QuestionGroupsModal.scss';
import Pagination from 'lib/components/pagination/Pagination';

const groupsTableColumns = [
  {
    title: 'Name',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Questions',
    className: 'type-cell',
    render: (record) => record.questions.length,
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const QuestionGroupsModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  sortOrder,
  setSortBy,
  sortBy,
  setPage,
  pageSize,
  page,
  count,
  onRequestClose,
  ...props
}) => {
  const hasPagination = setPage && page && pageSize && count > 0;
  return (
    <Modal
      title='Add question group'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name or ID...'
          />
        </div>
      }
      actions={
        hasPagination && (
          <Pagination
            pageSize={pageSize}
            setPage={setPage}
            page={page}
            count={count}
            threshold={3}
          />
        )
      }
      className='qm-question-groups-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      data-testid='question-groups-modal'
      {...props}
    >
      <Table
        data={data}
        columns={groupsTableColumns}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </Modal>
  );
};

export default QuestionGroupsModal;
