import * as yup from 'yup';
import { startOfDay, subDays } from 'date-fns/fp';
import { parseISO } from 'date-fns';
import {
  validator,
  requiredFormObject,
  requiredFormString,
  inputShape,
} from 'lib/validator';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';
import { pipe } from 'lib/funcHelpers';
import { API_ERROR_CODES } from 'config/errorCodes';

const inspectionsFormValidationSchema = yup.object().shape({
  linkedResource: requiredFormObject(),
  source: yup.object().when('linkedResource', {
    is: (res) => res.value?.resourceType === 'asset',
    then: requiredFormObject(),
  }),
  date: inputShape(
    yup
      .string()
      .nullable()
      .test(
        'inspectionDate',
        'Date cannot be in the past',
        (val) =>
          !val || parseISO(val) >= pipe(startOfDay, subDays(2))(new Date()),
      ),
  ),
  inspectionWindow: inputShape(
    yup
      .array()
      .test(
        'inspectionWindow',
        'Invalid date range',
        (val) => !!val[0] === !!val[1],
      ),
  ),
  status: requiredFormString(),
  type: requiredFormObject(),
  plan: requiredFormObject(),
});

const apiPathToFormPath = (path) =>
  path
    .replace('inspectionDate', 'date')
    .replace('inspectionType', 'type')
    .replace('inspectionPlan', 'plan')
    .replace(/locationSource\..*/, 'source');

const inspectionErrors = {
  ...API_ERROR_CODES,
  'inspections.planAndTypeDoesNotMatch':
    'Inspection plan does not match selected inspection type',
};

export const processInspectionFormAPIError = makeAPIBodyErrorProcessor({
  transformPath: apiPathToFormPath,
  errorCodes: inspectionErrors,
});

const inspectionsFormValidator = (state) =>
  validator(state, inspectionsFormValidationSchema);

export default inspectionsFormValidator;
