import classNames from 'classnames';
import Tooltip from '../tooltip/Tooltip';
import DropdownMenu, { MenuItem } from '../dropdown-menu/DropdownMenu';
import Button from '../button/Button';

import { ReactComponent as EditOutline } from 'assets/images/edit-outline.svg';
import { ReactComponent as MoreHorizontal } from 'assets/images/more-horizontal.svg';
import { ReactComponent as BinOutline } from 'assets/images/bin-outline.svg';
import { ReactComponent as ChevronsUp } from 'assets/images/chevrons-up.svg';
import { ReactComponent as ChevronUp } from 'assets/images/chevron-up.svg';
import { ReactComponent as LinkBrokenOutline } from 'assets/images/link-broken-outline.svg';
import { ReactComponent as LinkOutline } from 'assets/images/link-outline.svg';

import './QuestionActions.scss';

const QuestionActions = ({
  editType,
  isEditDisabled,
  onEdit,
  isDeleteDisabled,
  isEditing = false,
  ...props
}) => {
  let editButton;
  const isOrderAvailable =
    props.onMoveUp && props.onMoveTop && props.onMoveDown && props.onMoveBottom;

  switch (editType) {
    case 'icon':
      editButton = (
        <button
          type='button'
          onClick={isEditDisabled ? undefined : onEdit}
          className={classNames('edit-question-button', {
            disabled: isEditDisabled,
          })}
        >
          <Tooltip placement='top' overlay='Edit question'>
            <EditOutline />
          </Tooltip>
        </button>
      );
      break;
    case 'none':
    default:
      editButton = null;
  }
  const actions =
    isOrderAvailable || !!props.onDelete ? (
      <DropdownMenu
        items={
          <>
            {isOrderAvailable && (
              <>
                <MenuItem
                  onClick={props.onMoveTop}
                  disabled={props.isMoveUpDisabled}
                  label='Move to top'
                  icon={<ChevronsUp className='move-top-icon' />}
                />
                <MenuItem
                  onClick={props.onMoveUp}
                  disabled={props.isMoveUpDisabled}
                  label='Move up'
                  icon={<ChevronUp className='move-up-icon' />}
                />
                <MenuItem
                  onClick={props.onMoveDown}
                  disabled={props.isMoveDownDisabled}
                  label='Move down'
                  icon={<ChevronUp className='move-down-icon' />}
                />
                <MenuItem
                  onClick={props.onMoveBottom}
                  disabled={props.isMoveDownDisabled}
                  label='Move to bottom'
                  icon={<ChevronsUp className='move-bottom-icon' />}
                />
              </>
            )}
            {!!onEdit &&
              editType !== 'none' &&
              (!isEditing ? (
                <MenuItem
                  onClick={onEdit}
                  label='Override'
                  icon={<LinkBrokenOutline className='unlink-icon' />}
                />
              ) : (
                <MenuItem
                  onClick={onEdit}
                  label='Re-link'
                  disabled={isEditDisabled}
                  icon={<LinkOutline className='link-icon' />}
                />
              ))}
            {!!props.onDelete && (
              <MenuItem
                onClick={props.onDelete}
                label='Delete'
                icon={<BinOutline className='delete-icon' />}
                disabled={isDeleteDisabled}
              />
            )}
          </>
        }
      >
        <Button type='button' className='question-actions-trigger-button'>
          <MoreHorizontal />
        </Button>
      </DropdownMenu>
    ) : null;
  return (
    <div className='question-actions' data-testid={'QuestionActions.container'}>
      {editButton}
      {actions}
    </div>
  );
};

export default QuestionActions;
