import classNames from 'classnames';

import {
  MULTIPLE_CHOICE_ANSWER_TYPES,
  PHOTO_REQUIRED_LABEL,
  RESULT_RECORDING_TYPE,
  RESULT_RECORDING_TYPE_LABEL,
} from 'config/questionOptions';
import { getInspectionPlanStatusLabel } from 'config/inspectionPlanStatus';

import {
  formatDependency,
  formatDetailQuestionSampleSize,
  formatDynamicModifier,
  formatExpectedResult,
  formatQuestionOrder,
} from 'lib/components/question-detail/dataTransform';
import { getTranslation, reduceLocation } from 'lib/dataTransform';

import ErrorBag from 'lib/components/error-bag/ErrorBag';

import useInspectionPlanPrint from '../../useInspectionPlanPrint';

import './InspectionPlansPrintPage.scss';
import { LANGUAGE_LABELS } from 'config/languages';

function Question({ data, language }) {
  const isQuestionMultipleChoice = MULTIPLE_CHOICE_ANSWER_TYPES.includes(
    data.answerType.value,
  );

  const showDefects = data.defects.length > 0;
  const showDocuments = data.documents.length > 0;
  const showTools = data.tools.length > 0;
  const showAssetReferenceDocuments = data.assetReferenceDocNames.length > 0;
  const showMiddle = /* showDefects || showDocuments || showTools */ true;

  const showDependency = data.dependencyAction && data.dependencyCriteria;
  const showDynamicModifier =
    data.useDynamic &&
    data.dynamicAction &&
    data.dynamicCriteria &&
    data.dynamicRule;
  const showRight = /* showDependency || showDynamicModifier */ true;

  const showQuantitativeAttributes =
    data.answerType.value === 'quantitative_input';
  const isValidCustomExpectedMeasureValue =
    data.customExpectedMeasureValue !== null &&
    data.customExpectedMeasureValue !== undefined;
  const showExpectedResult = data.expectedMeasureTableResult?.value
    ? data.expectedMeasureTableResult.value === 'Custom'
      ? isValidCustomExpectedMeasureValue && data.customExpectedUom
      : true
    : false;
  const showResultRecording =
    Object.values(RESULT_RECORDING_TYPE).includes(
      data.individualMeasurementsRequired,
    ) &&
    [undefined, 'Custom', 'Other'].includes(
      data.expectedMeasureTableResult?.value,
    );
  const showUpperTolerance = data.upperToleranceValue !== null;
  const showLowerTolerance = data.lowerToleranceValue !== null;

  const questionName = getTranslation(data.name, language);

  return (
    <>
      <tr className='question-summary'>
        <td className='question-order'>{formatQuestionOrder(data)}</td>
        <td
          className={classNames('question-name', {
            'missing-translation': !questionName.translation,
          })}
        >
          <p>{questionName.display}</p>
        </td>
        <td className='question-aql'>
          <label>Sample Size: </label>
          <strong>{formatDetailQuestionSampleSize(data)}</strong>
        </td>
      </tr>
      <tr className='question-body'>
        <td></td>
        <td colSpan={2}>
          <div className='attribute-list'>
            <div className='primary-attributes'>
              <div className='attribute'>
                <label>Weight: </label>
                <strong>{data.questionWeight?.label}</strong>
              </div>
              <div className='attribute'>
                <label>Type: </label>
                <strong>{data.type?.label}</strong>
              </div>
              {isQuestionMultipleChoice ? (
                <div className='attribute'>
                  <label>Answers: </label>
                  <strong>{data.answer?.label}</strong>
                </div>
              ) : (
                <div className='attribute'>
                  <label>Answer: </label>
                  <strong>{data.answerType?.label}</strong>
                </div>
              )}
              <div className='attribute'>
                <label>Print on report: </label>
                <strong>{data.printOnReport.label}</strong>
              </div>
              <div className='attribute'>
                <label>Photo: </label>
                <strong>{PHOTO_REQUIRED_LABEL[data.photoRequired]}</strong>
              </div>
              {showQuantitativeAttributes && (
                <>
                  {showExpectedResult && (
                    <div className='attribute'>
                      <label>Expected result: </label>
                      <strong>{formatExpectedResult(data)}</strong>
                    </div>
                  )}
                  {showResultRecording && (
                    <div className='attribute'>
                      <label>Result recording: </label>
                      <strong>
                        {
                          RESULT_RECORDING_TYPE_LABEL[
                            data.individualMeasurementsRequired
                          ]
                        }
                      </strong>
                    </div>
                  )}
                  {showUpperTolerance && (
                    <div className='attribute'>
                      <label>Upper tolerance: </label>
                      <strong>
                        {data.upperToleranceValue}
                        {data.upperToleranceRule === '%'
                          ? '%'
                          : ` ${data.customExpectedUom?.label || ''}`}
                      </strong>
                    </div>
                  )}
                  {showLowerTolerance && (
                    <div className='attribute'>
                      <label>Lower tolerance: </label>
                      <strong>
                        {data.lowerToleranceValue}
                        {data.lowerToleranceRule === '%'
                          ? '%'
                          : ` ${data.customExpectedUom?.label || ''}`}
                      </strong>
                    </div>
                  )}
                </>
              )}
            </div>
            {showMiddle && (
              <div className='secondary-attributes'>
                {showDefects && (
                  <div className='attribute'>
                    <label>Defect list: </label>
                    <strong>
                      <ul>
                        {data.defects.map((defect, idx) => (
                          <li key={idx}>
                            {getTranslation(defect.name, language).display} -{' '}
                            {defect.weight?.label}
                          </li>
                        ))}
                      </ul>
                    </strong>
                  </div>
                )}
                {showDocuments && (
                  <div className='attribute file-list-container'>
                    <label>Reference documents: </label>
                    <strong>
                      <ul>
                        {data.documents.map((doc, idx) => (
                          <li key={idx}>{doc.documentName}</li>
                        ))}
                      </ul>
                    </strong>
                  </div>
                )}
                {showTools && (
                  <div className='attribute'>
                    <label>Tools needed: </label>
                    <strong>
                      <ul>
                        {data.tools.map((tool, idx) => (
                          <li key={idx}>{tool.name}</li>
                        ))}
                      </ul>
                    </strong>
                  </div>
                )}
                {showAssetReferenceDocuments && (
                  <div className='attribute'>
                    <label>Asset Reference Documents: </label>
                    <strong>
                      <ul>
                        {data.assetReferenceDocNames.map((doc, idx) => (
                          <li key={idx}>{doc.name}</li>
                        ))}
                      </ul>
                    </strong>
                  </div>
                )}
              </div>
            )}
            {showRight && (
              <div className='tertiary-attributes'>
                {showDynamicModifier && (
                  <div className='attribute column'>
                    <label>Dynamic modifier: </label>
                    <strong>{formatDynamicModifier(data)}</strong>
                  </div>
                )}
                {showDependency && (
                  <div className='attribute column'>
                    <label>Dependency: </label>
                    <strong>{formatDependency(data)}</strong>
                  </div>
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}

function QuestionGroup({ data, language }) {
  return (
    <table className='question-group'>
      <tbody>
        <tr>
          <td colSpan='3'>
            <h4>
              {data.name}
              <span>
                {data.questions.length}{' '}
                {data.questions.length === 1 ? 'question' : 'questions'}
              </span>
            </h4>
          </td>
        </tr>
        {data.questions.map((question) => (
          <Question key={question.id} data={question} language={language} />
        ))}
      </tbody>
    </table>
  );
}

export default function InspectionPlansPrintPage(props) {
  const { plan, company, errors, language } = useInspectionPlanPrint(props);

  if (errors) {
    return (
      <div className='inspection-plan-print-page container-m'>
        <ErrorBag errors={errors} />
      </div>
    );
  }

  if (!plan) {
    return null;
  }

  const companyInfo = {
    addr1: company.location?.address,
    addr2: reduceLocation(company.location, 'city', 'state'),
    addr3: reduceLocation(company.location, 'zipCode', 'country'),
    phone: company.phones?.find((p) => p.isPreferred)?.phone,
    email: company.emails?.find((p) => p.isPreferred)?.email,
  };

  const {
    validityValue,
    validityRange,

    aqlLevel,
    aqlFunctional,
    aqlMinor,
    aqlMajor,
    criticalDefectValue,
    criticalDefectRule,
  } = plan;

  return (
    <div className='inspection-plan-print-page container-m'>
      <article className='inspection-plan'>
        <header>
          <h3 className='company-name'>{company.name}</h3>
          <div className='company-address'>
            {!!companyInfo.addr1 && <div>{companyInfo.addr1}</div>}
            {!!companyInfo.addr2 && <div>{companyInfo.addr2}</div>}
            {!!companyInfo.addr3 && <div>{companyInfo.addr3}</div>}
          </div>
          <div className='company-contact'>
            {!!companyInfo.phone && (
              <div className='company-phone'>{companyInfo.phone}</div>
            )}
            {!!companyInfo.email && (
              <div className='company-email'>{companyInfo.email}</div>
            )}
          </div>
        </header>
        <div className='strike-through'>
          <h2>Inspection Plan</h2>
        </div>
        <div className='boxed-section'>
          <h2 className='plan-title'>{plan.name}</h2>
          <p className='plan-description'>{plan.description}</p>
          <div className='plan-subtitle'>
            <span>
              <label>ID:</label>
              <strong>{plan.id}</strong>
            </span>
            <span>
              <label>Status:</label>
              <strong>{getInspectionPlanStatusLabel(plan.status)}</strong>
            </span>
          </div>
        </div>
        {plan.aqlLevel && (
          <div className='plan-attributes'>
            {validityValue && validityRange ? (
              <div className='attribute plan-validity'>
                <label>Validity</label>
                <strong>
                  {validityValue} {validityRange}
                </strong>
              </div>
            ) : null}
            <div className='attribute plan-aql-level'>
              <label>AQL Level</label>
              <strong>{aqlLevel.label}</strong>
            </div>
            {aqlLevel && aqlMajor ? (
              <div className='attribute plan-aql-major'>
                <label>Major defects</label>
                <strong>{aqlMajor.label}</strong>
              </div>
            ) : null}
            {aqlLevel && aqlMinor ? (
              <div className='attribute plan-aql-minor'>
                <label>Minor defects</label>
                <strong>{aqlMinor.label}</strong>
              </div>
            ) : null}
            {aqlLevel && aqlFunctional ? (
              <div className='attribute plan-aql-functional'>
                <label>Functional defects</label>
                <strong>{aqlFunctional.label}</strong>
              </div>
            ) : null}
            {aqlLevel && criticalDefectValue !== null && criticalDefectRule ? (
              <div className='attribute plan-aql-critical'>
                <label>Critical defects</label>
                <strong>
                  {criticalDefectValue}
                  {criticalDefectRule === '%' ? '%' : ''} allowed
                </strong>
              </div>
            ) : null}
          </div>
        )}
        <div className='strike-through'>
          <h2>Questions</h2>
        </div>
        <div className='attribute plan-language'>
          <label>Language: </label>
          <strong>{LANGUAGE_LABELS[language]}</strong>
        </div>
        <div className='question-section'>
          {plan.questionGroups.map((group) => (
            <QuestionGroup data={group} language={language} />
          ))}
        </div>
      </article>
    </div>
  );
}
