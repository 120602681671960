import UnsubscribePage from './components/unsubscribe-page/UnsubscribePage';

import { Route, Switch } from 'react-router-dom';

export default function UnsubscribeRoutes() {
  return (
    <Switch>
      <Route
        path='/unsubscribe'
        render={(props) => <UnsubscribePage {...props} />}
      />
    </Switch>
  );
}
