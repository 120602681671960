const pubSub = () => {
  let subscribers = {};

  const sub = (eventName, fn) => {
    if (!subscribers[eventName]) {
      subscribers[eventName] = [];
    }

    subscribers[eventName].push(fn);
  };

  const unsub = (eventName, fn) => {
    if (!subscribers[eventName]) {
      return;
    }

    subscribers[eventName] = subscribers[eventName].filter(
      (curr) => curr !== fn,
    );
  };

  const pub = (eventName, value) => {
    if (!subscribers[eventName]) {
      return;
    }
    subscribers[eventName].forEach((fn) => fn(value));
  };

  return {
    pub,
    sub,
    unsub,
  };
};

export default pubSub;
