import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import COMPANY_STATUS, { getStatusLabel } from 'config/companyStatus';
import { reducer, initialState } from './companiesListReducer';
import companiesService from './companiesService';
import debounce from 'lodash.debounce';

const fetchCompanies = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });
  return companiesService
    .getCompanies(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useCompaniesList = ({ location, history }) => {
  const list = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchCompanies,
  );

  const userClicksOnRow = (id) => {
    history.push(`/companies/${id}`);
  };

  const statusOptions = [
    {
      label: 'All',
      value: '',
    },
    {
      label: getStatusLabel(COMPANY_STATUS.ACTIVE),
      value: COMPANY_STATUS.ACTIVE,
    },
    // TODO: Allow disabled status on filter when needed
    // {label: getStatusLabel(COMPANY_STATUS.DISABLED), value: COMPANY_STATUS.DISABLED},
  ];

  const filters = {
    status: {
      value: list.getParams()?.status || '',
      label: getStatusLabel(list.getParams()?.status) || 'All',
    },
  };

  return { ...list, userClicksOnRow, statusOptions, filters };
};

export default useCompaniesList;
