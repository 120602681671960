import { cloneDeep } from 'lodash';

import { INSPECTION_ORIGIN } from 'config/inspectionConfig';

import makeReducer from 'lib/makeReducer';

export const MAX_FIELD_CHARS = {
  name: 50,
  description: 255,
  externalId: 50,
};

const INITIAL_NEW_INSPECTION_PAIRS = () => ({
  isRequired: {
    value: false,
    errors: [],
  },
  isScheduled: {
    value: false,
    errors: [],
  },
  inspectionType: {
    value: { value: '', label: '' },
    errors: [],
  },
  inspectionOrigin: {
    value: INSPECTION_ORIGIN.INTERNAL,
    errors: [],
  },
  inspectionPlan: {
    options: [{ label: 'Inherited from Asset', value: '' }],
    value: { value: '', label: 'Inherited from Asset' },
    errors: [],
    loading: false,
  },
  customInspectionQty: {
    value: null,
    errors: [],
  },
  customInspectionQtyIsRelative: {
    value: false,
    errors: [],
  }
});

const INITIAL_SOURCE_VALUE = () => ({
  value: '',
  externalId: '',
  label: '',
  location: '',
});

const INITIAL_NEW_SOURCE = () => ({
  source: {
    value: INITIAL_SOURCE_VALUE(),
    errors: [],
  },
  inspectionPairs: [{ ...INITIAL_NEW_INSPECTION_PAIRS() }],
});

const INITIAL_FORM_STATE = () => ({
  id: null,
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  externalId: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.externalId,
  },
  description: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.description,
  },
  workflowSteps: [INITIAL_NEW_SOURCE()],
  availableSources: {
    sortBy: 'id',
    sortOrder: 'ASC',
    search: '',
    page: 1,
    pageSize: 10,
  },
  currentSource: {
    index: 0,
    value: { value: '', externalId: '', label: '', location: '' },
  },
  errors: [],
  loading: false,
  isSourcesModalOpen: false,
  isDirty: false,
});

export const WORKFLOWS_FORM_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  USER_TYPES_NAME: 'USER_TYPES_NAME',
  USER_TYPES_EXTERNAL_ID: 'USER_TYPES_EXTERNAL_ID',
  USER_TYPES_DESCRIPTION: 'USER_TYPES_DESCRIPTION',
  USER_ADDS_SOURCE: 'USER_ADDS_SOURCE',
  USER_REMOVES_SOURCE: 'USER_REMOVES_SOURCE',
  USER_ADDS_INSPECTION_PAIR: 'USER_ADDS_INSPECTION_PAIR',
  USER_REMOVES_INSPECTION_PAIR: 'USER_REMOVES_INSPECTION_PAIR',
  USER_SETS_SOURCE: 'USER_SETS_SOURCE',
  USER_CLEARS_SOURCE: 'USER_CLEARS_SOURCE',
  USER_SETS_SOURCE_INSPECTION_PLAN: 'USER_SETS_SOURCE_INSPECTION_PLAN',
  USER_SETS_SOURCE_INSPECTION_TYPE: 'USER_SETS_SOURCE_INSPECTION_TYPE',
  USER_SETS_SOURCE_INSPECTION_ORIGIN: 'USER_SETS_SOURCE_INSPECTION_ORIGIN',
  USER_SETS_SOURCE_REQUIRED_INSPECTION: 'USER_SETS_SOURCE_REQUIRED_INSPECTION',
  USER_SETS_SOURCE_SCHEDULED_INSPECTION: 'USER_SETS_SOURCE_SCHEDULED_INSPECTION',
  USER_SETS_CUSTOM_INSPECTION_QUANTITY: 'USER_SETS_CUSTOM_INSPECTION_QUANTITY',
  USER_SETS_CUSTOM_INSPECTION_QUANTITY_IS_RELATIVE: 'USER_SETS_CUSTOM_INSPECTION_QUANTITY_IS_RELATIVE',
  USER_OPENS_SOURCES_MODAL: 'USER_OPENS_SOURCES_MODAL',
  USER_TOGGLES_SOURCE: 'USER_TOGGLES_SOURCE',
  USER_SEARCHES_SOURCES: 'USER_SEARCHES_SOURCES',
  USER_CANCELS_SOURCES_MODAL: 'USER_CANCELS_SOURCES_MODAL',
  USER_SORTS_SOURCES: 'USER_SORTS_SOURCES',
  USER_SETS_SOURCES_PAGE: 'USER_SETS_SOURCES_PAGE',
  APP_IS_LOADING_INSPECTION_PLANS: 'APP_IS_LOADING_INSPECTION_PLANS',
  APP_LOADS_INSPECTION_PLANS: 'APP_LOADS_INSPECTION_PLANS',
  INITIAL_STATE: 'INITIAL_STATE',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
};

const setValue = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: max && max - action.payload.length,
    },
    isDirty: true,
  };
};

const removeArrayValue = (key, state, action) => {
  const newValues = cloneDeep(state[key]);
  newValues.splice(action.payload, 1);

  return {
    ...state,
    [key]: newValues,
    isDirty: true,
  };
};

const removeInspectionPairs = (state, action) => {
  const newValues = cloneDeep(state.workflowSteps);
  newValues[action.payload.stepIndex].inspectionPairs.splice(
    action.payload.inspectionIndex,
    1,
  );

  return {
    ...state,
    workflowSteps: newValues,
    isDirty: true,
  };
};

const addInspectionPairs = (state, action) => {
  const newValues = cloneDeep(state.workflowSteps);
  newValues[action.payload].inspectionPairs = [
    ...newValues[action.payload].inspectionPairs,
    { ...INITIAL_NEW_INSPECTION_PAIRS() },
  ];

  return {
    ...state,
    workflowSteps: newValues,
    errors: [],
    isDirty: true,
  };
};

const userSetsSource = (state, index, value) => {
  return {
    ...state,
    isDirty: true,
    workflowSteps: [
      ...state.workflowSteps.map((element, i) => {
        if (i === index) {
          return {
            ...element,
            source: {
              errors: [],
              value,
            },
          };
        }

        return element;
      }),
    ],
    isSourcesModalOpen: false,
    availableSources: INITIAL_FORM_STATE().availableSources,
  };
};

const userSetsInspectionPair = (key, state, action) => {
  const newValues = cloneDeep(state.workflowSteps);
  const path =
    newValues[action.payload.stepIndex].inspectionPairs[
    action.payload.inspectionIndex
    ];
  path[key].value = action.payload[key];
  path[key].errors = [];

  // reset inspection plan when inspection type changes
  if (key === 'inspectionType') {
    path.inspectionPlan = INITIAL_NEW_INSPECTION_PAIRS().inspectionPlan;
  }

  return {
    ...state,
    workflowSteps: newValues,
    isDirty: true,
  };
};

const appLoadsInspectionPlans = (state, action) => {
  const newValues = cloneDeep(state.workflowSteps);
  const path =
    newValues[action.payload.stepIndex].inspectionPairs[
      action.payload.inspectionIndex
    ].inspectionPlan;

  path.loading = false;
  path.options = [
    ...INITIAL_NEW_INSPECTION_PAIRS().inspectionPlan.options,
    ...action.payload.inspectionPlans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    })),
  ];

  return {
    ...state,
    workflowSteps: newValues,
  };
};

const WORKFLOWS_FORM_REDUCER_CONFIG = {
  [WORKFLOWS_FORM_ACTIONS.USER_TYPES_NAME]: (state, action) => {
    return setValue('name', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_TYPES_EXTERNAL_ID]: (state, action) => {
    return setValue('externalId', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_TYPES_DESCRIPTION]: (state, action) => {
    return setValue('description', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_ADDS_SOURCE]: (state) => {
    return {
      ...state,
      workflowSteps: [...state.workflowSteps, INITIAL_NEW_SOURCE()],
      isDirty: true,
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_REMOVES_SOURCE]: (state, action) => {
    return removeArrayValue('workflowSteps', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_ADDS_INSPECTION_PAIR]: (state, action) => {
    return addInspectionPairs(state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_REMOVES_INSPECTION_PAIR]: (state, action) => {
    return removeInspectionPairs(state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE]: (state) => {
    return userSetsSource(
      state,
      state.currentSource.index,
      state.currentSource,
    );
  },
  [WORKFLOWS_FORM_ACTIONS.USER_CLEARS_SOURCE]: (state, action) => {
    return userSetsSource(state, action.payload, INITIAL_SOURCE_VALUE());
  },
  [WORKFLOWS_FORM_ACTIONS.USER_TOGGLES_SOURCE]: (state, action) => {
    return {
      ...state,
      currentSource: {
        ...state.currentSource,
        value: action.payload.id,
        externalId: action.payload.externalId,
        label: action.payload.name,
        location: [
          action.payload.location?.city,
          action.payload.location?.country,
        ].join(', '),
      },
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_PLAN]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('inspectionPlan', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_TYPE]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('inspectionType', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_INSPECTION_ORIGIN]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('inspectionOrigin', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_REQUIRED_INSPECTION]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('isRequired', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCE_SCHEDULED_INSPECTION]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('isScheduled', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_CUSTOM_INSPECTION_QUANTITY]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('customInspectionQty', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_CUSTOM_INSPECTION_QUANTITY_IS_RELATIVE]: (
    state,
    action,
  ) => {
    return userSetsInspectionPair('customInspectionQtyIsRelative', state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_OPENS_SOURCES_MODAL]: (state, action) => {
    return {
      ...state,
      isSourcesModalOpen: true,
      currentSource: { ...state.currentSource, index: action.payload },
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_CANCELS_SOURCES_MODAL]: (state) => {
    return {
      ...state,
      currentSource: INITIAL_FORM_STATE().currentSource,
      isSourcesModalOpen: false,
      availableSources: INITIAL_FORM_STATE().availableSources,
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SEARCHES_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SORTS_SOURCES]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        ...action.payload,
      },
    };
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SETS_SOURCES_PAGE]: (state, action) => {
    return {
      ...state,
      availableSources: {
        ...state.availableSources,
        page: action.payload,
      },
    };
  },

  [WORKFLOWS_FORM_ACTIONS.APP_IS_LOADING_INSPECTION_PLANS]: (state, action) => {
    const newValues = cloneDeep(state.workflowSteps);
    newValues[action.payload.stepIndex].inspectionPairs[
      action.payload.inspectionIndex
    ].inspectionPlan.loading = true;
    return { ...state, workflowSteps: newValues };
  },
  [WORKFLOWS_FORM_ACTIONS.APP_LOADS_INSPECTION_PLANS]: (state, action) => {
    return appLoadsInspectionPlans(state, action);
  },
  [WORKFLOWS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return { ...state, loading: true, isDirty: false };
  },
  [WORKFLOWS_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [WORKFLOWS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [WORKFLOWS_FORM_ACTIONS.INITIAL_STATE]: () => {
    return INITIAL_FORM_STATE();
  },
};

export const { reducer, initialState } = makeReducer(
  WORKFLOWS_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE(),
);
