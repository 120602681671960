import Modal from 'lib/components/modal/Modal';
import Table from 'lib/components/table/Table';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import Button from 'lib/components/button/Button';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import SearchInput from 'lib/components/search-input/SearchInput';

import { ReactComponent as CheckCircleSolid } from 'assets/images/check-circle-solid.svg';

import './OwnersModal.scss';
import Pagination from 'lib/components/pagination/Pagination';

const ownersTableColumns = [
  {
    title: 'Name / Company',
    className: 'name-cell',
    sortId: 'name',
    render: (record) => (
      <>
        {record.selected && <CheckCircleSolid className='check-selected' />}
        <NamedAvatar user={record} />
      </>
    ),
  },
  {
    title: 'Roles',
    render: (record) => (
      <div className='roles-cell-container'>
        {(record.roles || []).map((role, index) => {
          return (
            <StatusPill key={index} variant={STATUS_PILL_VARIANT.NOTIFY}>
              {role?.role}
            </StatusPill>
          );
        })}
      </div>
    ),
  },
  {
    title: 'ID',
    sortId: 'id',
    className: 'id-cell',
    render: (record) => record.id,
  },
];

const OwnersModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  onSubmit,
  sortOrder,
  setSortBy,
  sortBy,
  count,
  setPage,
  pageSize,
  page,
  selectedCount,
  ...props
}) => {
  const numSelected = selectedCount ?? data.filter((r) => r.selected).length;
  const hasPagination = pageSize && setPage && page && count > 0;

  return (
    <Modal
      title='Assign new owner'
      header={
        <div className='search-container'>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            placeholder='Search by name or ID...'
          />
        </div>
      }
      actions={
        <>
          <Button
            onClick={onSubmit}
            data-testid='OwnersModal.submit'
          >{`Assign owners${numSelected ? ` (${numSelected})` : ''}`}</Button>
          {hasPagination && (
            <Pagination
              pageSize={pageSize}
              setPage={setPage}
              page={page}
              count={count}
              threshold={3}
            />
          )}
        </>
      }
      className='owners-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      {...props}
    >
      <Table
        data={data}
        columns={ownersTableColumns}
        rowClassName={(record) => record.selected && 'row-selected'}
        onRowClick={onRowClick}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </Modal>
  );
};

export default OwnersModal;
