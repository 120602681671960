import ROLES from 'config/roles';
import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import SystemSettingsPage from './components/system-settings-page/SystemSettingsPage';

export default function UsersRoutes() {
  return (
    <>
      <ProtectedRoute
        allowed={[ROLES.QM_ADMIN]}
        path='/system-settings'
        exact
        render={() => <SystemSettingsPage />}
      />
    </>
  );
}
