import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';

import './ImportAnswersPage.scss';

const answerColumnLabels = {
  id: 'ID',
  externalId: 'External ID',
  name: 'Name',
  order: 'Order',
  meaning: 'Option action',
  language: 'Option language',
  text: 'Option translation',
};

const answerErrorValidations = {
  id: {
    'answer.unknown': () => 'Answer with provided Exacture ID does not exist',
  },
  name: {
    'answer.unique': () => 'Answer group name already exists',
    'answers.unique': () => 'Answer group name already exists',
    'answer.name.unique': () => 'Answer group name already exists',
    'any.required': () => 'Answer group name field is required',
    'language.unique': () => 'Name in this language was already imported',
    'language.duplicate': () => 'Name in this language was already imported',
    '0.inconsistent': () => 'Answer name is not consistent across options',
  },
  order: {
    'number.min': () => 'Order must start at 1 and increase consecutively',
    'option.nonConsecutive': () =>
      'Order must start at 1 and increase consecutively',
  },
  externalId: {
    'string.max': () => 'External ID must be at most 50 characters',
  },
  language: {
    'option.duplicated': () =>
      'Answer option label for this language was already imported',
  },
  meaning: {
    'option.meaningNotEditable': () =>
      'Previously defined option action can not be changed',
    '0.inconsistent': () =>
      'Option action is not consistent across translations',
  },
  text: {
    'option.duplicated': () =>
      'A different option has the same translation in this language',
  },
};

export default function ImportAnswersPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='answers-import'>
      <h4>Import Answers</h4>
      <p>
        Before importing answers, please make sure you structure your data like
        in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/sample-answers.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='answers' onUploadInfo={list.uploadInfo} />
      <ImportResult
        list={list}
        errorValidations={answerErrorValidations}
        columnLabels={answerColumnLabels}
      />
    </div>
  );
}
