import { useEffect, useReducer } from 'react';
import useQueryParams from 'lib/useQueryParams';
import {
  reducer,
  initialState,
  UNSUBSCRIBE_ACTIONS,
} from './unsubscribeReducer';
import unsubscribeService from './unsubscribeService';

const useUnsubscribe = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getParams } = useQueryParams(props.location, props.history);
  const { token } = getParams();

  const unsubscribe = () => {
    if (token) {
      unsubscribeService
        .unsubscribe(token)
        .then(() =>
          dispatch({
            type: UNSUBSCRIBE_ACTIONS.APP_SAVES_DATA,
          }),
        )
        .catch((e) => {
          dispatch({
            type: UNSUBSCRIBE_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        });
    }
  };

  useEffect(() => {
    unsubscribe();
  }, [token]);

  return {
    state,
  };
};

export default useUnsubscribe;
