import { makeListReducer, SORT_ORDER } from 'lib/list-helper/makeListReducer';

const INITIAL_STATE = {
  sortBy: 'id',
  pageSize: 10,
  sortOrder: SORT_ORDER.DESC,
  isOpen: false,
  selected: undefined,
  errors: [],
};

export const SELECT_MODAL_ACTIONS = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  USER_SETS_SELECTED: 'USER_SETS_SELECTED',
};

const MODAL_REDUCER_CONFIG = {
  [SELECT_MODAL_ACTIONS.OPEN_MODAL]: (state) => ({
    ...state,
    isOpen: true,
  }),
  [SELECT_MODAL_ACTIONS.CLOSE_MODAL]: (state) => ({
    ...state,
    isOpen: false,
    page: 1,
    search: '',
  }),
  [SELECT_MODAL_ACTIONS.USER_SETS_SELECTED]: (state, action) => ({
    ...state,
    selected: action.payload,
  }),
};

export const { reducer, initialState } = makeListReducer(
  MODAL_REDUCER_CONFIG,
  INITIAL_STATE,
);
