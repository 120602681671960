import ImageGallery from 'lib/components/image-gallery/ImageGallery';
import Modal from 'lib/components/modal/Modal';

import './PhotosModal.scss';

const PhotosModal = (props) => {
  return (
    <Modal
      className='photos-modal'
      isOpen={props.isOpen}
      onRequestClose={props.onCancel}
    >
      <div className='photos-container'>
        <ImageGallery
          startIndex={props.data.initialIndex}
          items={(props?.data?.photos || []).map((i) => ({
            original: i.url,
            thumbnail: i.url,
          }))}
        />
      </div>
    </Modal>
  );
};

export default PhotosModal;
