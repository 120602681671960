import FloatingCard from '../floating-card/FloatingCard.js';
import Button from 'lib/components/button/Button.js';
import InspectionsTable from 'lib/components/inspections-table/InspectionsTable.js';
import useDashboard from 'modules/dashboard/useDashboard.js';

import { sub, format } from 'date-fns';

import { DASHBOARD_METRICS, DASHBOARD_TABLES } from '../../dashboardConfig.js';

import { ReactComponent as DocumentOutline } from 'assets/images/document.svg';
import { ReactComponent as SearchOutline } from 'assets/images/search-outline.svg';
import { ReactComponent as CheckCircleOutline2 } from 'assets/images/check-circle-outline-2.svg';
import { ReactComponent as ShuffleOutline } from 'assets/images/shuffle-outline.svg';
import { ReactComponent as ClipboardOutline } from 'assets/images/clipboard-outline.svg';
import { ReactComponent as HomeOutline } from 'assets/images/home-outline.svg';
import { ReactComponent as PackageOutline } from 'assets/images/package-outline.svg';
import { ReactComponent as UmbrellaOutline } from 'assets/images/umbrella-outline.svg';

import './DashboardPage.scss';
import WorkObjectsTable from 'lib/components/work-objects-table/WorkObjectsTable.js';
import AssetsTable from 'lib/components/assets-table/AssetsTable.js';

const get30DaysRangeFilterString = () => {
  const fmt = 'yyyy-MM-dd';
  const toDate = new Date();
  const fromDate = sub(toDate, { days: 30 });

  return `inspectionDateStart=${format(
    fromDate,
    fmt,
  )}&inspectionDateEnd=${format(toDate, fmt)}`;
};

const metricsCardConfig = {
  [DASHBOARD_METRICS.ASSETS_MISSING_PLANS]: {
    icon: <DocumentOutline />,
    description: 'Assets missing plans',
    link: '/assets?sortBy=id&sortOrder=DESC&page=1&missingPlans=true',
  },
  [DASHBOARD_METRICS.UNASSIGNED_INSPECTIONS]: {
    icon: <SearchOutline />,
    description: 'Unassigned inspections',
    link:
      '/inspections?sortBy=id&sortOrder=DESC&page=1&inspectorId=unassigned&status=published&isThirdParty=false',
  },
  [DASHBOARD_METRICS.PASS_RATE_30_DAYS]: {
    icon: <CheckCircleOutline2 />,
    description: '30 day pass rate',
    format: (val) => `${Number(val).toFixed(0)}%`,
    link: `/inspections?sortBy=id&sortOrder=DESC&page=1&status=passed&${get30DaysRangeFilterString()}&inspectorId=_all`,
  },
  [DASHBOARD_METRICS.PENDING_USAGE_DECISIONS]: {
    icon: <ShuffleOutline />,
    description: 'Pending usage decisions',
    link: `/inspections?sortBy=id&sortOrder=DESC&page=1&status=awaiting_decision&inspectorId=_all`,
  },
  [DASHBOARD_METRICS.PENDING_INSPECTIONS]: {
    icon: <ClipboardOutline />,
    description: 'Pending inspections',
    link: `/inspections?sortBy=id&sortOrder=DESC&page=1&status=published&inspectorId=_all`,
  },
  [DASHBOARD_METRICS.NEW_SOURCES]: {
    icon: <HomeOutline />,
    description: (
      <>
        <div>New sources</div>
        <div className='small'>in the last 30 days</div>
      </>
    ),
  },
  [DASHBOARD_METRICS.NEW_ASSETS]: {
    icon: <UmbrellaOutline />,
    description: (
      <>
        <div>New assets</div>
        <div className='small'>in the last 30 days</div>
      </>
    ),
  },
  [DASHBOARD_METRICS.NEW_INSPECTION_PLANS]: {
    icon: <ClipboardOutline />,
    description: (
      <>
        <div>New inspection plans</div>
        <div className='small'>in the last 30 days</div>
      </>
    ),
  },
  [DASHBOARD_METRICS.NEW_WORK_OBJECTS]: {
    icon: <PackageOutline />,
    description: (
      <>
        <div>New work objects</div>
        <div className='small'>in the last 30 days</div>
      </>
    ),
  },
  __default__: {
    icon: '?',
    description: undefined,
  },
};

function renderCard(type, value) {
  const config = metricsCardConfig[type] ?? metricsCardConfig.__default__;
  const formatted =
    typeof value !== 'undefined' && value !== null
      ? typeof config.format === 'function'
        ? config.format(value)
        : value
      : '-';
  return (
    <FloatingCard
      value={formatted}
      description={config.description || type}
      icon={config.icon || null}
      key={type}
      link={config?.link ?? null}
    />
  );
}

const SectionTitle = ({ count, onViewMore, title }) => (
  <div className='section-title'>
    <h4>{title}</h4>
    {count > 5 && (
      <Button onClick={onViewMore} className='medium-button'>
        View all
      </Button>
    )}
  </div>
);

export default function DashboardPage(params) {
  const { state, isTableVisible, displayMetrics, ...vm } = useDashboard(params);
  const metrics = vm.getMetrics();
  return (
    <>
      <div className='dashboard-page-header floating row'>
        <h2>Dashboard</h2>
        {!!displayMetrics.length && (
          <div className='card-container'>
            {displayMetrics.map((key) => renderCard(key, metrics[key]))}
          </div>
        )}
      </div>
      <div className='dashboard-page'>
        {isTableVisible(DASHBOARD_TABLES.UPCOMING_INSPECTIONS) && (
          <>
            <SectionTitle
              title='Pending inspections'
              count={state.upcomingInspections.count}
              onViewMore={vm.userClicksViewUpcomingInspections}
            />
            {state.upcomingInspections.count === 0 ? (
              <div className='empty'>No pending inspections</div>
            ) : (
              <InspectionsTable
                className='hide-result'
                data={state.upcomingInspections.data}
                onRowClick={vm.userClicksInspectionRow}
              />
            )}
          </>
        )}
        {isTableVisible(DASHBOARD_TABLES.LATEST_INSPECTIONS) && (
          <>
            <SectionTitle
              title='Latest inspections'
              count={state.latestInspections.count}
              onViewMore={vm.userClicksViewLatestInspections}
            />
            {state.latestInspections.count === 0 ? (
              <div className='empty'>No latest inspections</div>
            ) : (
              <InspectionsTable
                onRowClick={vm.userClicksInspectionRow}
                data={state.latestInspections.data}
              />
            )}
          </>
        )}
        {isTableVisible(DASHBOARD_TABLES.TIME_PER_UNIT_INSPECTIONS) && (
          <>
            <SectionTitle
              title='Time per unit inspected'
              count={state.timePerUnitInspections.count}
              onViewMore={vm.userClicksViewTimePerUnitInspections}
            />
            {state.timePerUnitInspections.count === 0 ? (
              <div className='empty'>No inspections available</div>
            ) : (
              <InspectionsTable
                className='hide-result show-time-per-unit'
                onRowClick={vm.userClicksInspectionRow}
                data={state.timePerUnitInspections.data}
              />
            )}
          </>
        )}
        {isTableVisible(DASHBOARD_TABLES.PENDING_USAGE_DECISIONS) && (
          <>
            <SectionTitle
              title='Pending usage decisions'
              count={state.pendingUsageDecisions.count}
              onViewMore={vm.userClicksViewPendingUsageDecisions}
            />
            {state.pendingUsageDecisions.count === 0 ? (
              <div className='empty'>No pending decisions</div>
            ) : (
              <WorkObjectsTable
                onRowClick={vm.userClicksWorkObjectRow}
                data={state.pendingUsageDecisions.data}
              />
            )}
          </>
        )}
        {isTableVisible(DASHBOARD_TABLES.ASSETS_MISSING_PLANS) && (
          <>
            <SectionTitle
              title='Assets missing plans'
              count={state.assetsMissingPlans.count}
              onViewMore={vm.userClicksViewAssetsMissingPlans}
            />
            {state.assetsMissingPlans.count === 0 ? (
              <div className='empty'>No assets missing plans</div>
            ) : (
              <AssetsTable
                onRowClick={vm.userClicksAssetRow}
                data={state.assetsMissingPlans.data}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
