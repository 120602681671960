import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';

import { HashRouter } from 'react-router-dom';

const Application = () => {
  const routedApp = (
    <HashRouter>
      <App />
    </HashRouter>
  );

  return process.env.REACT_APP_GLOBAL_STRICT_MODE === 'true' ? (
    <React.StrictMode>{routedApp}</React.StrictMode>
  ) : (
    routedApp
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<Application />, rootElement);
