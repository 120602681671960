import Button from 'lib/components/button/Button';
import usersService from 'modules/users/usersService';
import { useEffect, useState } from 'react';
import './SystemSettingsPage.scss';
import { SystemSettingsPageUsersActivityList } from './SystemSettingsUsersActivityList';
import { SystemSettingsPageUsersSummary } from './SystemSettingsUsersSummary';
import systemSettingsService from 'modules/system-settings/systemSettingsService';
import Modal from 'lib/components/modal/Modal';
import Input from 'lib/components/input/Input';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import InfoBox, { INFO_BOX_VARIANT } from 'lib/components/info-box/InfoBox';
import { ReactComponent as AlertTriangle } from 'assets/images/alert-triangle.svg';
import { SystemSettingsDbStats } from './SystemSettingsDbStats';

const maintenanceModeModalStateInitial = {
  isOpen: false,
  confirmFieldValue: '',
  errors: [],
}

export default function SystemSettingsPage() {
  const [statsData, setStatsData] = useState(null);
  const [statsDataStatic, setStatsDataStatic] = useState(null);
  const [maintenanceModeStatus, setMaintenanceModeStatus] = useState(null);
  const [maintenanceModeModalState, setMaintenanceModeModalState] = useState({ ...maintenanceModeModalStateInitial })

  useEffect(() => {
    let isMounted = true;

    const doGetStatsData = () => {
      if (isMounted) {
        usersService.getActiveUsers().then((res) => {
          if (isMounted && res) {
            setStatsData(res);
            setTimeout(doGetStatsData, 5000);
          }
        });
      }
    }

    doGetStatsData();
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    if (statsData !== null && statsDataStatic === null) {
      setStatsDataStatic(statsData);
    }
  }, [JSON.stringify([statsData, statsDataStatic])]);

  const getMaintenanceModeStatus = () => {
    systemSettingsService.getMaintenanceMode().then((res) => {
      setMaintenanceModeStatus(res);
    });
  }

  useEffect(() => {
    getMaintenanceModeStatus();
  }, []);

  const isMaintenanceMode = !!maintenanceModeStatus?.value?.isEnabled;

  const onMaintenanceModeModalCancel = () => {
    setMaintenanceModeModalState({ ...maintenanceModeModalStateInitial });
  }

  const onMaintenanceModeModalConfirm = () => {
    if (`${maintenanceModeModalState?.confirmFieldValue}`.toLowerCase() !== 'yes') {
      setMaintenanceModeModalState({
        ...maintenanceModeModalState,
        errors: ['You need to type "yes" in the field if you want to proceed.']
      });
      return;
    }
    systemSettingsService.setMaintenanceMode({ isEnabled: !isMaintenanceMode }).then((res) => {
      getMaintenanceModeStatus();
      setMaintenanceModeModalState({ ...maintenanceModeModalStateInitial });
    }).catch((e) => {
      console.error(e);
      getMaintenanceModeStatus();
      setMaintenanceModeModalState({
        ...maintenanceModeModalState,
        errors: ['There was an error setting Maintenance Mode.']
      })
    });
  }

  return (
    <div className='system-settings-page'>
      <div className='row row-header'>
        <h2>System Settings</h2>
        <div className='header-actions'>
          <Button
            className='btn-refresh-stats' onClick={() => setMaintenanceModeModalState({
              ...maintenanceModeModalStateInitial,
              isOpen: true,
            })}>
            {isMaintenanceMode ? 'Disable' : 'Enable'} Maintenance mode
          </Button>
          <Button
            className='btn-refresh-stats' onClick={() => console.log('X')} disabled>
            Restart backend
          </Button>
        </div>
      </div>
      <div className='row row-info'>
        {!!isMaintenanceMode && (
          <InfoBox
            icon={<AlertTriangle />}
            variant={INFO_BOX_VARIANT.ERROR}
          >
            <p className='warn-note'>
              <strong>WARNING:</strong> Maintenance Mode is enabled!
            </p>
            <p>Maintenance Mode prevents access to the system for all users.</p>
          </InfoBox>
        )}
      </div>
      <div className='row row-summary'>
        <SystemSettingsPageUsersSummary usersData={statsData?.activeUsers} />
      </div>
      <div className='row row-details'>
        <h3>User Activity{' '}
          <Button
            className='btn-refresh-stats medium-button' onClick={() => setStatsDataStatic(statsData)}>
            Refresh
          </Button></h3>
        <SystemSettingsPageUsersActivityList usersData={statsDataStatic?.activeUsers} />
      </div>
      <div className='row row-db-stats'>
        <h3>Database Stats</h3>
        <SystemSettingsDbStats dbStatsData={statsDataStatic?.dbConnectionStats?.rows?.length ? statsDataStatic?.dbConnectionStats?.rows : []} />
      </div>
      <Modal
        title={`${isMaintenanceMode ? 'Disable' : 'Enable'} Maintenance Mode?`}
        actions={
          <>
            <Button onClick={onMaintenanceModeModalCancel} className='link-button'>
              Cancel
            </Button>
            <Button onClick={onMaintenanceModeModalConfirm}><strong>{isMaintenanceMode ? 'Disable' : 'Enable'}</strong>&nbsp;Maintenance Mode</Button>
          </>
        }
        isOpen={maintenanceModeModalState.isOpen}
        onRequestClose={onMaintenanceModeModalCancel}
        className='set-maintenance-mode-modal'
      >
        <p className='warn-note'>
          <strong>WARNING:</strong> You are about to <strong>{isMaintenanceMode ? 'DISABLE' : 'ENABLE'}</strong> Maintenance Mode!
        </p>
        {isMaintenanceMode ? (
          <p>Disabling Maintenance Mode will restore access to the system for all users.</p>
        ) : (
          <p>Enabling Maintenance Mode will immediately revoke access to the system for all users.</p>
        )}
        <p>Type "yes" in the field below if you want to proceed.</p>
        <Input
          className="maintenance-mode-confirm-field"
          value={maintenanceModeModalState?.confirmFieldValue || ''}
          onChange={(e) => setMaintenanceModeModalState({ ...maintenanceModeModalState, confirmFieldValue: e.target.value })}
        />
        <ErrorBag errors={maintenanceModeModalState.errors} />
      </Modal>
    </div>
  );
}
