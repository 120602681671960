import { makeListReducer } from 'lib/list-helper/makeListReducer';

export const FILTER_ACTIONS = {
  APP_LOADS_FILTERS: 'APP_LOADS_FILTERS',
  APP_FAILS_LOAD_FILTERS: 'APP_FAILS_LOAD_FILTERS',
};

const FILTER_INITIAL_STATE = {
  filterOptions: {},
};

const FILTER_CONFIG = {
  [FILTER_ACTIONS.APP_LOADS_FILTERS]: (state, action) => ({
    ...state,
    filterOptions: action.payload,
  }),
  [FILTER_ACTIONS.APP_FAILS_LOAD_FILTERS]: (state, action) => ({
    ...state,
    errors: action.payload,
  }),
};

const REDUCER_CONFIG = {
  ...FILTER_CONFIG,
};
const INITIAL_STATE = {
  ...FILTER_INITIAL_STATE,
};

export const { reducer, initialState } = makeListReducer(
  REDUCER_CONFIG,
  INITIAL_STATE,
);
