import classNames from 'classnames';
import ImageFallback from '../image-fallback/ImageFallback';
import './Avatar.scss';

export const AVATAR_SIZES = {
  S: 'avatar-s',
  M: 'avatar-m',
  L: 'avatar-l',
  XL: 'avatar-xl',
};

const Avatar = ({
  url,
  placeholder,
  size = AVATAR_SIZES.M,
  className,
  ...props
}) => {
  const classes = classNames('avatar', className, size);

  const getPlaceholder = () => {
    if (!placeholder || placeholder.length < 2) {
      return 'QM';
    }

    const parts = placeholder.split(' ').filter((part) => part !== '');
    // if we have at least two parts, get the first letter of each placeholder
    // if not, get the first two letters of the placeholder
    const letters =
      parts.length > 1
        ? `${parts[0].charAt(0)}${parts[1].charAt(0)}`
        : `${parts[0].charAt(0)}${parts[0].charAt(1)}`;

    return letters.length < 2 ? 'QM' : letters.toUpperCase();
  };

  const placeholderEl = <div className='placeholder'>{getPlaceholder()}</div>;

  return (
    <div className={classes} {...props}>
      {url ? (
        <ImageFallback src={url} alt='Avatar' fallback={placeholderEl} />
      ) : (
        placeholderEl
      )}
    </div>
  );
};

export default Avatar;
