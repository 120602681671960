import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const ImageFallback = ({ src, alt = '', fallback, ...props }) => {
  const imageRef = useRef(null);
  const [error, setError] = useState(false);

  const onImageError = () => setError(true);

  const imageEl = <img ref={imageRef} src={src} alt={alt} {...props} />;
  const showFallback = !src || (fallback !== undefined && error);

  useEffect(() => {
    if (imageRef?.current) {
      imageRef.current.onerror = onImageError;
    }
  }, []);

  return showFallback ? fallback : imageEl;
};

ImageFallback.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  fallback: PropTypes.node,
};
export default ImageFallback;
