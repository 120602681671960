import makeReducer from 'lib/makeReducer';

export const WORKFLOWS_DETAIL_ACTIONS = {
  APP_LOADS_DATA: 'APP_LOADS_DATA',
  APP_LOADS_RELATED_WORK_OBJECTS: 'APP_LOADS_RELATED_WORK_OBJECTS',
  RESET_STATE: 'RESET_STATE',
};

const INITIAL_DETAIL_STATE = {
  workflow: {},
  workObjects: { count: 0, data: [] },
  errors: [],
  loading: true,
};

const WORKFLOWS_DETAIL_REDUCER_CONFIG = {
  [WORKFLOWS_DETAIL_ACTIONS.APP_LOADS_DATA]: (state, action) => {
    return {
      ...state,
      workflow: action.payload,
      loading: false,
    };
  },
  [WORKFLOWS_DETAIL_ACTIONS.APP_LOADS_RELATED_WORK_OBJECTS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      workObjects: action.payload,
      loading: false,
    };
  },
  [WORKFLOWS_DETAIL_ACTIONS.RESET_STATE]: (state, action) => {
    return {
      ...state,
      workflow: action.payload,
      loading: false,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  WORKFLOWS_DETAIL_REDUCER_CONFIG,
  INITIAL_DETAIL_STATE,
);
