import { useEffect, useReducer, useContext } from 'react';
import { AppContext } from 'App';
import ROLES from 'config/roles';
import {
  reducer,
  initialState,
  USERS_DETAIL_ACTIONS,
} from './usersDetailReducer';
import usersService from './usersService';
import useRoles from 'lib/useRoles';

const usersDetailParams = {
  select: [
    'name',
    'companyName',
    'status',
    'companyPosition',
    'city',
    'country',
  ],
};
const useUsersDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    appState: { currentUser },
  } = useContext(AppContext);
  const { isAllowed } = useRoles();

  const isViewingOwnProfile = currentUser.id === state.user.id;

  const getUser = () => {
    const id = props.self ? currentUser.id : props.match?.params?.id;
    return (
      id &&
      usersService
        .getUser(id, usersDetailParams)
        .then((user) => {
          dispatch({
            type: USERS_DETAIL_ACTIONS.APP_LOADS_DATA,
            payload: user,
          });
        })
        .catch((e) => {
          dispatch({
            type: USERS_DETAIL_ACTIONS.RESET_STATE,
            payload: e.payload,
          });
        })
    );
  };

  const userClicksResendPassword = () => {
    const email = state.user.emails?.[0].email;
    dispatch({
      type: USERS_DETAIL_ACTIONS.USER_CLICKS_RESEND_PASSWORD,
    });
    return (
      email &&
      usersService
        .sendResetPasswordEmail(email)
        .then(() => {
          dispatch({
            type: USERS_DETAIL_ACTIONS.APP_SENT_PASSWORD,
          });
        })
        .catch(() => {
          dispatch({
            type: USERS_DETAIL_ACTIONS.APP_FAILED_RESEND_PASSWORD,
          });
        })
    );
  };

  useEffect(() => {
    getUser();
  }, [props.match?.params?.id]);

  const isEditVisible = () => {
    let canEditUser = false;

    const isCurrentUserAdmin = isAllowed([ROLES.CLIENT_ADMIN, ROLES.QM_ADMIN]);
    const isCurrentUserManager = isAllowed([
      ROLES.ASSET_MANAGER,
      ROLES.SOURCE_MANAGER,
    ]);

    if (isCurrentUserAdmin || isCurrentUserManager) {
      canEditUser = true;
    }

    const isUserManager = state.user.roles.some((role) =>
      [ROLES.ASSET_MANAGER, ROLES.SOURCE_MANAGER].includes(role.id),
    );

    if (isCurrentUserManager && isUserManager) {
      // A manager cannot edit another manager
      canEditUser = false;
    }

    canEditUser = !!isViewingOwnProfile;

    canEditUser = !!state.user.isEditable;

    return canEditUser;
  };

  const userClicksEditUser = () =>
    isViewingOwnProfile
      ? props.history.push(`/my-profile/edit`)
      : props.history.push(`/users/${state.user.id}/edit`);

  const userClicksCoordinator = (coordinator) =>
    props.history.push(`/users/${coordinator?.id}`);

  return {
    state,
    userClicksResendPassword,
    isEditVisible,
    userClicksEditUser,
    userClicksCoordinator,
    currentUser,
  };
};

export default useUsersDetail;
