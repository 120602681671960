import SearchInput from 'lib/components/search-input/SearchInput';
import useSettingsAssetStatusesList from '../../useSettingsAssetStatusesList';
import SettingsAssetStatusesTable from '../settings-asset-statuses-table/SettingsAssetStatusesTable';

import './SettingsAssetStatusesListPage.scss';

export default function SettingsAssetStatusesListPage(props) {
  const { ...list } = useSettingsAssetStatusesList(props);

  return (
    <div className='settings-asset-statuses-list-page'>
      <div className='settings-header'>
        <h4>Asset statuses</h4>
      </div>
      <p className='form-info'>
        The list of statuses that can be selected for each asset are displayed
        here.
      </p>
      <div className='row asset-statuses-filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='SettingsAssetStatusesListPage.search'
          />
        </div>
      </div>
      <div className='settings-asset-statuses-table'>
        <SettingsAssetStatusesTable
          editField={list.state.editField}
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          sortBy={list.state.sortBy}
          sortOrder={list.state.sortOrder}
          setSortBy={list.sortBy}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setEdit={list.userSelectsEditAssetStatus}
          clearEdit={list.userClearsEditAssetStatus}
          onChange={list.userTypesAssetStatusLabel}
          editAssetStatus={list.editAssetStatus}
        />
      </div>
    </div>
  );
}
