import CsvImport from 'lib/components/csv-import/CsvImport';
import File from 'lib/components/file/File';
import ImportResult from 'lib/components/import-error/ImportResult';
import useCsvImport from 'lib/useCsvImport';

import './ImportReferenceDocumentsPage.scss';

const columnLabels = {
  id: 'ID',
  externalId: 'External ID',
};

const errorGenerator = {
  asset: {
    'asset.notFound': () => 'Could not find asset',
  },
  name: {
    'string.alphanum': () =>
      'Document name can only contain alphanumeric and space characters',
    'string.max': () => 'Document name must have at most 40 characters',
  },
  file: {
    'url.unreachable': () => 'URL is unreachable',
    'url.invalid': () => 'URL is invalid',
    'string.uri': () => 'URL is invalid',
    'file.max_length_exceeded': () => 'Maximum file size of 5MB exceeded',
  },
};

export default function ImportMeasureTablePage(props) {
  const vm = useCsvImport(props);

  return (
    <div className='import-asset-reference-documents-page container-m'>
      <h2>Import reference documents</h2>
      <p className='form-info'>
        Before importing reference documents, please make sure you structure
        your data like in the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/sample-asset-documents.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport
        type='asset-reference-documents'
        onUploadInfo={vm.uploadInfo}
      />
      <ImportResult
        list={vm}
        errorValidations={errorGenerator}
        columnLabels={columnLabels}
      />
    </div>
  );
}
