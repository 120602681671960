import Table from 'lib/components/table/Table';
import SettingsTypeItem from 'modules/settings/components/settings-type-item/SettingsTypeItem';
import SettingsTypesForm from 'modules/settings/components/settings-types-form/SettingsTypesForm';

import './SettingsSourceTypesTable.scss';

const SettingsSourceTypesTable = ({ userClicksOnRow, ...props }) => {
  const renderSourceTypeCell = (record) => {
    if (props.editField?.id === record.id) {
      return (
        <SettingsTypesForm
          cancelForm={props.clearEdit}
          addType={props.editField}
          onChange={props.onChange}
          onSubmit={props.editSourceType}
        />
      );
    } else {
      return (
        <SettingsTypeItem
          setEdit={() => props.setEdit(record.name, record.id)}
          label={record.name}
          editing={props.editField.editing}
          typeName='source type'
        />
      );
    }
  };

  const settingsSourceTypesTableColumns = [
    {
      title: 'ID',
      headerClassName: 'id-cell',
      sortId: 'id',
      render: (record) => record.id,
    },
    {
      title: 'Source type',
      headerClassName: 'name-cell',
      sortId: 'name',
      render: (record) => renderSourceTypeCell(record),
    },
  ];

  return (
    <Table
      columns={settingsSourceTypesTableColumns}
      className='settings-source-types-table'
      {...props}
    />
  );
};

export default SettingsSourceTypesTable;
