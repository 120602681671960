import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import COMPANY_STATUS from 'config/companyStatus';

const COMPANY_STATUS_TO_PILL_VARIANT = {
  [COMPANY_STATUS.ACTIVE]: STATUS_PILL_VARIANT.SUCCESS,
  [COMPANY_STATUS.DISABLED]: STATUS_PILL_VARIANT.ERROR,
};

const CompaniesStatusPill = ({ status }) => {
  const variant = COMPANY_STATUS_TO_PILL_VARIANT[status];

  return <StatusPill variant={variant}>{status}</StatusPill>;
};

export default CompaniesStatusPill;
