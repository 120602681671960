import IconButton from '../icon-button/IconButton';
import { ReactComponent as Pencil } from 'assets/images/pencil.svg';

const EditButton = ({ children, ...props }) => {
  return (
    <IconButton prefix={<Pencil />} className='edit-button' {...props}>
      {children}
    </IconButton>
  );
};

export default EditButton;
