import { INSPECTION_PLAN_STATUS } from 'config/inspectionPlanStatus';
import makeReducer from 'lib/makeReducer';
import { cloneDeep } from 'lodash';
import {
  getIsGroupedQuestionForPlanOnly,
  getIsGroupedQuestionOverridden,
} from './dataTransform';

export const MAX_FIELD_CHARS = {
  name: 250,
  description: 1000,
};

const getInitialResourcesModalState = () => ({
  assets: [],
  sources: [],
  sortBy: 'id',
  sortOrder: 'ASC',
  search: '',
  isModalOpen: false,
  errors: [],
  page: 1,
  pageSize: 10,
  assetsCount: 0,
  sourcesCount: 0,
  tab: 'assets',
});

const getInitialQuestionGroupModalState = () => ({
  list: [],
  sortBy: 'name',
  sortOrder: 'ASC',
  search: '',
  page: 1,
  pageSize: 10,
  count: 0,
  isModalOpen: false,
  errors: [],
});

const getInitialDefectsModalState = () => ({
  list: [],
  count: 0,
  sortBy: 'id',
  sortOrder: 'ASC',
  page: 1,
  pageSize: 10,
  search: '',
  isModalOpen: false,
  errors: [],
  selected: [],
  groupIndex: undefined,
  questionIndex: undefined,
});

const INITIAL_FORM_STATE = {
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  description: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.description,
  },
  status: {
    value: INSPECTION_PLAN_STATUS.DRAFT,
    errors: [],
  },
  initialStatus: INSPECTION_PLAN_STATUS.DRAFT,
  validity: {
    value: '',
    errors: [],
  },
  validityRange: {
    value: null,
    errors: [],
  },
  aqlLevel: {
    value: null,
    errors: [],
  },
  aqlMajor: {
    value: null,
    errors: [],
  },
  aqlMinor: {
    value: null,
    errors: [],
  },
  aqlFunctional: {
    value: null,
    errors: [],
  },
  criticalDefect: {
    value: 0,
    errors: [],
  },
  criticalDefectRule: {
    value: 'Exact',
    errors: [],
  },
  type: {
    value: null,
    errors: [],
  },
  initialType: null,
  typeSearch: {
    value: '',
    errors: [],
  },
  answerOptions: [],
  typeOptions: [],
  questionGroups: [],
  initialQuestionGroups: [],
  planOptions: false,
  questionOptions: false,
  questionEditFlags: [],
  linkedResource: null,
  language: null,
  linkableResources: getInitialResourcesModalState(),
  availableQuestionGroups: getInitialQuestionGroupModalState(),
  availableDefects: getInitialDefectsModalState(),
  otherMeasureOptions: [],
  loading: false,
  isDirty: false,
};

export const INSPECTION_PLANS_FORM_ACTIONS = {
  APP_LOADS_TYPES: 'APP_LOADS_TYPES',
  APP_LOADS_OPTIONS: 'APP_LOADS_OPTIONS',
  APP_LOADS_ANSWERS: 'APP_LOADS_ANSWERS',
  APP_LOADS_ASSET_CUSTOM_MEASURES: 'APP_LOADS_ASSET_CUSTOM_MEASURES',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  RESET_STATE: 'RESET_STATE',

  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',

  APP_LOADS_RESOURCES: 'APP_LOADS_RESOURCES',
  USER_OPENS_RESOURCES_MODAL: 'USER_OPENS_RESOURCES_MODAL',
  USER_CANCELS_RESOURCES_MODAL: 'USER_CANCELS_RESOURCES_MODAL',
  USER_SELECTS_RESOURCE: 'USER_SELECTS_RESOURCE',
  USER_SEARCHES_RESOURCES: 'USER_SEARCHES_RESOURCES',
  USER_SORTS_RESOURCES: 'USER_SORTS_RESOURCES',
  USER_CHANGES_RESOURCES_PAGE: 'USER_CHANGES_RESOURCES_PAGE',
  USER_CHANGES_RESOURCES_MODAL_TAB: 'USER_CHANGES_RESOURCES_MODAL_TAB',
  APP_LOADS_QUESTION_GROUPS: 'APP_LOADS_QUESTION_GROUPS',
  USER_UNLINKS_GROUP: 'USER_UNLINKS_GROUP',

  APP_RESETS_SELECTED_DEFECTS: 'APP_RESETS_SELECTED_DEFECTS',
  APP_LOADS_DEFECTS: 'APP_LOADS_DEFECTS',
  USER_OPENS_DEFECTS_MODAL: 'USER_OPENS_DEFECT_MODAL',
  USER_TOGGLES_DEFECT: 'USER_TOGGLES_DEFECT',
  USER_CANCELS_DEFECTS_MODAL: 'USER_CANCELS_DEFECT_MODAL',
  USER_SEARCHES_DEFECTS: 'USER_SEARCHES_DEFECTS',
  USER_SORTS_DEFECTS: 'USER_SORTS_DEFECTS',
  USER_SETS_PAGE_FOR_DEFECTS: 'USER_SETS_PAGE_FOR_DEFECTS',
  USER_EDITS_DEFECTS_LIST: 'USER_EDITS_DEFECTS_LIST',

  APP_LOADS_GROUP_MODAL_DATA: 'APP_LOADS_GROUP_MODAL_DATA',
  USER_OPENS_ADD_GROUP_MODAL: 'USER_OPENS_ADD_GROUP_MODAL',
  USER_CANCELS_ADD_GROUP_MODAL: 'USER_CANCELS_ADD_GROUP_MODAL',
  USER_ADDS_QUESTION_GROUP: 'USER_ADDS_QUESTION_GROUP',
  APP_RELINKS_QUESTION_GROUP: 'APP_RELINKS_QUESTION_GROUP',
  USER_REMOVES_QUESTION_GROUP: 'USER_REMOVES_QUESTION_GROUP',
  USER_SEARCHES_QUESTION_GROUPS: 'USER_SEARCHES_QUESTION_GROUPS',
  USER_SORTS_QUESTION_GROUPS: 'USER_SORTS_QUESTION_GROUPS',
  USER_SETS_QUESTION_GROUPS_PAGE: 'USER_SETS_QUESTION_GROUPS_PAGE',

  USER_TOGGLES_QUESTION_EDIT: 'USER_TOGGLES_QUESTION_EDIT',
  USER_ADDS_QUESTION: 'USER_ADDS_QUESTION',
  USER_DELETES_QUESTION: 'USER_DELETES_QUESTION',
  USER_MOVES_QUESTION: 'USER_MOVES_QUESTION',
  USER_CHANGES_QUESTION_INPUT: 'USER_CHANGES_QUESTION_INPUT',
  USER_ADDS_QUESTION_TOOL: 'USER_ADDS_QUESTION_TOOL',
  USER_REMOVES_QUESTION_TOOL: 'USER_REMOVES_QUESTION_TOOL',
  USER_REMOVES_QUESTION_DEFECT: 'USER_REMOVES_QUESTION_DEFECT',

  USER_ADDS_QUESTION_DOCUMENTS: 'USER_ADDS_QUESTION_DOCUMENTS',
  USER_REMOVES_QUESTION_DOCUMENT: 'USER_REMOVES_QUESTION_DOCUMENT',
  USER_RESETS_QUESTION_DOCUMENTS: 'USER_RESETS_QUESTION_DOCUMENTS',
};

const setStringValue = (key, state, payload) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      ...state[key],
      value: payload,
      errors: [],
      charsLeft: max && max - payload.length,
    },
    isDirty: true,
  };
};

export const getIsQuestionEdited = (state, gIndex, qIndex) => {
  const groupTemplateId = state.questionGroups[gIndex]?.originalTemplateId;
  const hasEditFlag =
    state.questionEditFlags[`${groupTemplateId}-${qIndex}`] === true;
  const isOriginalIdDifferent = getIsGroupedQuestionOverridden(
    state.questionGroups[gIndex]?.questions[qIndex],
  );
  const isForThisPlanOnly = getIsGroupedQuestionForPlanOnly(
    state.questionGroups[gIndex].questions[qIndex],
  );

  return hasEditFlag || isOriginalIdDifferent || isForThisPlanOnly;
};

export const getQuestionDependencies = (state, gIndex, qIndex) => {
  const editGroup = state.questionGroups[gIndex];
  const initialGroup = state.initialQuestionGroups[gIndex];

  const editQuestion = editGroup.questions[qIndex];
  const isQuestionEdited = getIsQuestionEdited(state, gIndex, qIndex);

  const editDependency = editGroup.questions.find(
    (q) =>
      Number(q.order) ===
      Number(editQuestion?.dependencyQuestion?.value?.value),
  );
  const initialDependency = initialGroup.questions[qIndex]?.dependencyQuestion;
  const dependencyOrder = isQuestionEdited
    ? editDependency?.order
    : initialDependency?.order;

  // Get the earliest currently-edited question that has a dependency on us
  const firstEditDependent = editGroup.questions
    .filter((_, qIdx) => getIsQuestionEdited(state, gIndex, qIdx))
    .sort((a, b) => a.order - b.order)
    .find(
      (q) =>
        Number(q.dependencyQuestion?.value?.value) ===
        Number(editQuestion.order),
    );

  // Get the earliest currently-NOT-edited question that has a dependency
  const firstInitialDependent = initialGroup.questions
    .filter((_, qIdx) => !getIsQuestionEdited(state, gIndex, qIdx))
    .sort((a, b) => a.order - b.order)
    .find(
      (q) => Number(q.dependencyQuestion?.order) === Number(editQuestion.order),
    );

  // Return the lowest order
  const firstDependentOrder = Math.min(
    firstEditDependent?.order || Infinity,
    firstInitialDependent?.order || Infinity,
  );

  return {
    firstDependentOrder,
    dependencyOrder,
  };
};

/**
 * Swap the order of the question above/below the specified index.
 *
 *
 * To revert to the initial question info when cancelling edit but also have
 * the ability to keep the draft edit in our form state, we don't change
 * the actual index of objects in the state's arrays, but only swap the `order`
 * property when an added question moves up/down.
 *
 * All new (added for this plan only) questions are added (and kept) at the
 * end of the `questionGroups.questions` state array.
 *
 * @param {object} state Form state
 * @param {number} gIdx Question group index
 * @param {number} qIdx Question index within group
 * @param {string} dir Direction to move question (up/down)
 * @returns {object} New form state
 */
const swapQuestionOrders = (state, gIdx, qIdx, dir) => {
  let targetOrder;

  const newEditGroups = cloneDeep(state.questionGroups);
  const newInitialGroups = cloneDeep(state.initialQuestionGroups);
  const question = state.questionGroups[gIdx].questions[qIdx];
  const maxOrder = state.questionGroups[gIdx].questions.reduce(
    (acc, q) => (q.order > acc ? q.order : acc),
    0,
  );

  const { firstDependentOrder, dependencyOrder } = getQuestionDependencies(
    state,
    gIdx,
    qIdx,
  );

  switch (dir) {
    case 'top':
      // If the current question has a dependency on another, we cannot
      // move before that dependency. Aditionally, the dependency can change
      // if we are currently editing the question.
      targetOrder = dependencyOrder !== undefined ? dependencyOrder + 1 : 0;
      break;
    case 'up':
      targetOrder = question.order - 1;
      break;
    case 'down':
      targetOrder = question.order + 1;
      break;
    case 'bottom': {
      // If the current question is a dependency for other questions, we
      // cannot move after any of them. Aditionally, the dependents can change
      // if those questions are in an editing state.
      targetOrder =
        firstDependentOrder && firstDependentOrder !== Infinity
          ? firstDependentOrder - 1
          : maxOrder;
      break;
    }
    default:
      throw new Error(`Undefined direction ${dir}`);
  }

  if (targetOrder < 0 || targetOrder > maxOrder) {
    return state;
  }

  // Get the range of affected questions
  const start = Math.min(targetOrder, question.order);
  const end = Math.max(targetOrder, question.order);

  // Get the direction in which their order will be shifted
  const increment = dir === 'up' || dir === 'top' ? 1 : -1;

  // Loop through both the initial and the edited questions and shuffle
  // the orders so that our question can fit in thG `targetOrder` slot.
  // We only go through `newEditQuestions` because
  // newEditQuestions.length > newInitialQuestions.length and their orders
  // are synchronized always.
  newEditGroups[gIdx].questions.forEach((q, idx) => {
    if (q.order >= start && q.order <= end) {
      q.order += increment;

      if (newInitialGroups[gIdx].questions[idx]) {
        newInitialGroups[gIdx].questions[idx].order += increment;
      }
    }

    // Update orders in dependencies. Keep in mind that
    // the question we're moving is going up while others are going down
    // and vice-versa.
    const editDependency =
      newEditGroups[gIdx].questions[idx].dependencyQuestion.value;
    const initialDependency =
      newInitialGroups[gIdx].questions[idx]?.dependencyQuestion;

    const editDepOrder = editDependency?.value;
    if (editDepOrder >= start && editDepOrder <= end) {
      // Update the label in the dependency drop-down.
      const newOrder =
        editDepOrder !== question.order
          ? editDepOrder + increment
          : targetOrder;
      newEditGroups[gIdx].questions[idx].dependencyQuestion.value = {
        value: newOrder,
        label: `#${newOrder + 1}`,
      };
    }

    if (initialDependency?.order >= start && initialDependency?.order <= end) {
      // Update the text in the dependency
      const newOrder =
        initialDependency.order !== question.order
          ? initialDependency.order + increment
          : targetOrder;

      newInitialGroups[gIdx].questions[idx].dependencyQuestion = {
        ...newInitialGroups[gIdx].questions[idx].dependencyQuestion,
        order: newOrder,
      };
    }

    // If we edited the question and gave it a dependency,
    // But afterwards we unchecked edit and moved the question before its
    // editing dependency, clear that dependency
    if (targetOrder <= editDepOrder && idx === qIdx) {
      newEditGroups[gIdx].questions[idx].dependencyCriteria.value = null;
      newEditGroups[gIdx].questions[idx].dependencyQuestion.value = null;
      newEditGroups[gIdx].questions[idx].dependencyAction.value = {
        label: 'None',
        value: false,
      };
    }
  });

  // Put our question in the cleared spot
  newEditGroups[gIdx].questions[qIdx].order = targetOrder;
  if (newInitialGroups[gIdx].questions[qIdx]) {
    newInitialGroups[gIdx].questions[qIdx].order = targetOrder;
  }

  return {
    ...state,
    questionGroups: newEditGroups,
    initialQuestionGroups: newInitialGroups,
    isDirty: true,
  };
};

const INSPECTION_PLANS_REDUCER_CONFIG = {
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return setStringValue(action.key, state, action.payload);
  },
  [INSPECTION_PLANS_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_TYPES]: (state, action) => {
    return {
      ...state,
      typeOptions:
        state.initialType &&
        state.initialLinkedResourceId !== null &&
        state.initialLinkedResourceId === state.linkedResource?.id &&
        !action.payload.some((p) => p.value === state.initialType.value)
          ? [state.initialType, ...action.payload]
          : action.payload,
      typeSearch: { ...state.typeSearch, errors: [] },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_ANSWERS]: (state, action) => {
    return {
      ...state,
      answerOptions: action.payload,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_ASSET_CUSTOM_MEASURES]: (
    state,
    action,
  ) => {
    return {
      ...state,
      otherMeasureOptions: action.payload,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_TOGGLES_QUESTION_EDIT]: (
    state,
    action,
  ) => {
    const key = action.payload;
    return {
      ...state,
      questionEditFlags: {
        ...state.questionEditFlags,
        [key]: !state.questionEditFlags[key],
      },
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        isModalOpen: true,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SEARCHES_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SORTS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        ...action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CHANGES_RESOURCES_PAGE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        page: action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: getInitialResourcesModalState(),
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CHANGES_RESOURCES_MODAL_TAB]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: '',
        sortBy: 'id',
        sortOrder: 'ASC',
        tab: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SELECTS_RESOURCE]: (state, action) => {
    return {
      ...state,
      linkedResource: action.payload,
      linkableResources: getInitialResourcesModalState(),
      questionGroups: [],
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        ...action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_OPENS_ADD_GROUP_MODAL]: (state) => {
    return {
      ...state,
      availableQuestionGroups: {
        ...state.availableQuestionGroups,
        isModalOpen: true,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SEARCHES_QUESTION_GROUPS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableQuestionGroups: {
        ...state.availableQuestionGroups,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SORTS_QUESTION_GROUPS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableQuestionGroups: {
        ...state.availableQuestionGroups,
        ...action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SETS_QUESTION_GROUPS_PAGE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableQuestionGroups: {
        ...state.availableQuestionGroups,
        page: action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CANCELS_ADD_GROUP_MODAL]: (state) => {
    return {
      ...state,
      availableQuestionGroups: getInitialQuestionGroupModalState(),
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_ADDS_QUESTION_GROUP]: (state, action) => {
    const { form, detail } = action.payload;

    return {
      ...state,
      questionGroups: [...state.questionGroups, form],
      initialQuestionGroups: [...state.initialQuestionGroups, detail],
      availableQuestionGroups: getInitialQuestionGroupModalState(),
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_RELINKS_QUESTION_GROUP]: (
    state,
    action,
  ) => {
    const { form, detail, gIdx } = action.payload;
    const newGroups = [...state.questionGroups];
    newGroups[gIdx] = form;

    const newInitialGroups = [...state.initialQuestionGroups];
    newInitialGroups[gIdx] = detail;

    const newQuestionEditFlags = Object.keys(state.questionEditFlags)
      .filter((k) => k.split('-')[0] !== newGroups[gIdx].originalTemplateId)
      .reduce((acc, k) => ({ ...acc, [k]: state.questionEditFlags[k] }), {});

    return {
      ...state,
      questionGroups: newGroups,
      initialQuestionGroups: newInitialGroups,
      availableQuestionGroups: getInitialQuestionGroupModalState(),
      questionEditFlags: newQuestionEditFlags,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_UNLINKS_GROUP]: (state, action) => {
    const gIdx = action.payload;
    const newGroup = cloneDeep(state.questionGroups[gIdx]);
    const newGroups = [...state.questionGroups];
    newGroups[gIdx] = {
      ...newGroup,
      isCustom: true,
      templateId: null, // XXX: Used anywhere?
    };

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_REMOVES_QUESTION_GROUP]: (
    state,
    action,
  ) => {
    const deletedGroup = state.questionGroups[action.payload];
    const newGroups = cloneDeep(state.questionGroups);
    newGroups.splice(action.payload, 1);
    const newInitialGroups = cloneDeep(state.initialQuestionGroups);
    newInitialGroups.splice(action.payload, 1);

    let newEditFlags = {};
    Object.keys(state.questionEditFlags).forEach(
      (k) =>
        k.split('-')[0] === deletedGroup.id &&
        newEditFlags.push(state.questionEditFlags(k)),
    );
    return {
      ...state,
      questionGroups: newGroups,
      initialQuestionGroups: newInitialGroups,
      questionEditFlags: newEditFlags,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_GROUP_MODAL_DATA]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableQuestionGroups: {
        ...state.availableQuestionGroups,
        list: action.payload.data,
        count: action.payload.count,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_OPENS_DEFECTS_MODAL]: (state, action) => {
    const { gIdx, qIdx } = action.payload;
    const selected = cloneDeep(
      state.questionGroups[gIdx].questions[qIdx].defects,
    );
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        isModalOpen: true,
        questionIndex: qIdx,
        groupIndex: gIdx,
        selected,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SEARCHES_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        search: action.payload,
        page: 1,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SORTS_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        ...action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_RESETS_SELECTED_DEFECTS]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);

    newGroups[gIdx].questions[qIdx].defects = [];

    return {
      ...state,
      questionGroups: newGroups,
      availableDefects: getInitialDefectsModalState(),
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SETS_PAGE_FOR_DEFECTS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        page: action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CANCELS_DEFECTS_MODAL]: (state) => {
    return {
      ...state,
      availableDefects: getInitialDefectsModalState(),
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_TOGGLES_DEFECT]: (state, action) => {
    const newSelected = cloneDeep(state.availableDefects.selected);
    const defect = action.payload;
    const idx = newSelected.findIndex((sel) => sel.id === defect.id);

    if (idx !== -1) {
      newSelected.splice(idx, 1);
    } else {
      newSelected.push(defect);
    }

    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        selected: newSelected,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_EDITS_DEFECTS_LIST]: (state) => {
    const { groupIndex, questionIndex } = state.availableDefects;
    const newGroups = cloneDeep(state.questionGroups);

    newGroups[groupIndex].questions[questionIndex].defects = cloneDeep(
      state.availableDefects.selected,
    );

    return {
      ...state,
      questionGroups: newGroups,
      availableDefects: getInitialDefectsModalState(),
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_DEFECTS]: (state, action) => {
    return {
      ...state,
      availableDefects: {
        ...state.availableDefects,
        ...action.payload,
        errors: [],
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      loading: true,
      isDirty: false,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_LINKABLE_RESOURCES]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        list: action.payload,
      },
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_OPTIONS]: (state, action) => {
    return { ...state, ...action.payload, loading: false };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.APP_LOADS_QUESTION_GROUPS]: (
    state,
    action,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_ADDS_QUESTION]: (state, action) => {
    const newGroups = cloneDeep(state.questionGroups);
    const gId = newGroups[action.key].templateId;
    const editKey = `${gId}-${newGroups[action.key].questions.length}`;
    newGroups[action.key].questions.push(action.payload);

    return {
      ...state,
      questionGroups: newGroups,
      questionEditFlags: {
        ...state.questionEditFlags,
        [editKey]: true,
      },
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_ADDS_QUESTION_TOOL]: (state, action) => {
    const { gIdx, qIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);

    newGroups[gIdx].questions[qIdx].tools.push({
      value: '',
      errors: [],
    });

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_REMOVES_QUESTION_TOOL]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx, tIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);

    newGroups[gIdx].questions[qIdx].tools.splice(tIdx, 1);

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_REMOVES_QUESTION_DEFECT]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx, defect } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);
    const dIdx = newGroups[gIdx].questions[qIdx].defects.findIndex(
      (d) => d.id === defect.id,
    );
    newGroups[gIdx].questions[qIdx].defects.splice(dIdx, 1);

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_DELETES_QUESTION]: (state, action) => {
    const { gIdx, qIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);
    const questions = newGroups[gIdx].questions;
    const newInitialGroups = cloneDeep(state.initialQuestionGroups);
    const initialQuestions = newInitialGroups[gIdx].questions;
    const qOrder = questions[qIdx].order;

    questions.splice(qIdx, 1);
    questions.forEach((q) => {
      const dependencyOrder = q.dependencyQuestion.value?.value;
      if (q.order > qOrder) q.order = q.order - 1;
      if (dependencyOrder > qOrder) {
        const newOrder = dependencyOrder - 1;

        q.dependencyQuestion.value = {
          label: `#${newOrder + 1}`,
          value: newOrder,
        };
      }
    });

    if (
      getIsGroupedQuestionForPlanOnly(
        state.questionGroups[gIdx].questions[qIdx],
      )
    ) {
      const initialQIdx = initialQuestions.findIndex((q) => q.order === qOrder);
      if (initialQIdx !== -1) {
        initialQuestions.splice(initialQIdx, 1);
      }
    }

    initialQuestions.forEach((q) => {
      if (q.order > qOrder) q.order = q.order - 1;
      if (q.dependencyQuestion?.order > qOrder)
        q.dependencyQuestionTemplateOrder =
          q.dependencyQuestionTemplateOrder - 1;
    });

    return {
      ...state,
      questionGroups: newGroups,
      initialQuestionGroups: newInitialGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_MOVES_QUESTION]: (state, action) => {
    const { gIdx, qIdx, dir } = action.payload;
    return swapQuestionOrders(state, gIdx, qIdx, dir);
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_CHANGES_QUESTION_INPUT]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx, key, value, arrayIdx } = action.payload;
    const newQuestion = { ...state.questionGroups[gIdx].questions[qIdx] };
    const [questionKey, field = 'value'] = key.split('.');

    if (arrayIdx !== false) {
      newQuestion[questionKey][arrayIdx] = {
        ...newQuestion[questionKey][arrayIdx],
        [field]: value,
        errors: [],
      };
    } else {
      newQuestion[questionKey] = {
        ...newQuestion[questionKey],
        [field]: value,
        errors: [],
      };
    }

    const newGroup = { ...state.questionGroups[gIdx] };
    newGroup.questions = [...newGroup.questions];
    newGroup.questions[qIdx] = newQuestion;

    const newGroups = [...state.questionGroups];
    newGroups[gIdx] = newGroup;
    return { ...state, questionGroups: newGroups, isDirty: true };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_ADDS_QUESTION_DOCUMENTS]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx, payload } = action;
    const newGroups = cloneDeep(state.questionGroups);
    const newDocs = Array.from(payload).map((file) => ({
      file: file,
      value: file.name.split('.').slice(0, -1).join('.'),
      errors: [],
    }));

    newGroups[gIdx].questions[qIdx].documents = [
      ...newGroups[gIdx].questions[qIdx].documents,
      ...newDocs,
    ];

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_REMOVES_QUESTION_DOCUMENT]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx, docIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);

    newGroups[gIdx].questions[qIdx].documents.splice(docIdx, 1);

    return {
      ...state,
      questionGroups: newGroups,
      isDirty: true,
    };
  },
  [INSPECTION_PLANS_FORM_ACTIONS.USER_RESETS_QUESTION_DOCUMENTS]: (
    state,
    action,
  ) => {
    const { gIdx, qIdx } = action.payload;
    const newGroups = cloneDeep(state.questionGroups);
    const newDocs = cloneDeep(
      state.initialQuestionGroups[gIdx].questions[qIdx].documents,
    );
    newGroups[gIdx].questions[qIdx].documents = newDocs.map((doc) => ({
      errors: [],
      value: doc.documentName,
      file: doc,
    }));

    return {
      ...state,
      questionGroups: newGroups,
    };
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_PLANS_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
