import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryList from 'lib/list-helper/useQueryList';
import { reducer, initialState } from './assetsListReducer';
import assetsService from './assetsService';
import debounce from 'lodash.debounce';

const fetchAssets = debounce((params, _, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return assetsService
    .getAssets(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const useAssetsList = ({ location, history }) => {
  const list = useQueryList(
    location,
    history,
    reducer,
    initialState,
    fetchAssets,
  );

  const userClicksOnRow = (id) => {
    history.push(`/assets/${id}`);
  };

  return { ...list, userClicksOnRow };
};

export default useAssetsList;
