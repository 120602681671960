import WorkObjectsForm from '../work-objects-form/WorkObjectsForm';
import './WorkObjectsEditPage.scss';

const WorkObjectsEditPage = (props) => {
  return (
    <div
      className='work-objects-edit-page container-m'
      data-testid='WorkObjectsEditPage.container'
    >
      <div className='row'>
        <h1>Edit work object</h1>
      </div>
      <div className='row'>
        <WorkObjectsForm {...props} />
      </div>
    </div>
  );
};

export default WorkObjectsEditPage;
