import React, { useState } from 'react';

import { ADD_ASSET_ROLES } from 'modules/assets/assetsPermissions';

import AddButton from 'lib/components/add-button/AddButton';
import AssetsTable from 'lib/components/assets-table/AssetsTable';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import { ReactComponent as ExportIcon } from 'assets/images/download.svg';
import IconButton from 'lib/components/icon-button/IconButton';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import SearchInput from 'lib/components/search-input/SearchInput';

import useAssetsList from '../../useAssetsList';

import { ReactComponent as ImportIcon } from 'assets/images/upload.svg';
import './AssetsListPage.scss';
import assetsService from 'modules/assets/assetsService';
import { downloadResponse } from 'lib/download';

export default function AssetsListPage(props) {
  const [isExportAssetsBusy, setIsExportAssetsBusy] = useState(false);
  const [isExportRefDocsBusy, setIsExportRefDocsBusy] = useState(false);

  const doExportAssets = () => {
    setIsExportAssetsBusy(true);

    assetsService
      .exportAssets()
      .then((data) => {
        downloadResponse(data, 'assets.csv');
      })
      .finally(() => {
        setIsExportAssetsBusy(false);
      });
  };

  const doExportRefDocs = () => {
    setIsExportRefDocsBusy(true);

    assetsService
      .exportAssetRefDocs()
      .then((data) => {
        downloadResponse(data, 'asset-reference-documents.csv');
      })
      .finally(() => {
        setIsExportRefDocsBusy(false);
      });
  };

  const list = useAssetsList(props);

  return (
    <div className='assets-list-page'>
      <div className='row assets-header container-m'>
        <h2>Assets</h2>

        <div className='row'>
          <ProtectedComponent allowed={ADD_ASSET_ROLES}>
            <DropdownMenu
              items={[
                <MenuItem label='Assets' onClick={doExportAssets} />,
                <MenuItem
                  label='Reference Documents'
                  onClick={doExportRefDocs}
                />,
              ]}
            >
              <IconButton
                prefix={<ExportIcon />}
                data-testid='AssetsListPage.importAssetsButton'
                className='margin-right'
                disabled={isExportAssetsBusy | isExportRefDocsBusy}
              >
                Export
              </IconButton>
            </DropdownMenu>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_ASSET_ROLES}>
            <DropdownMenu
              items={[
                <MenuItem
                  label='Assets'
                  onClick={() => props.history.push('/assets/import')}
                />,
                <MenuItem
                  label='Measurement Tables'
                  onClick={() =>
                    props.history.push('/assets/import-measurement-tables')
                  }
                />,
                <MenuItem
                  label='Reference Documents'
                  onClick={() =>
                    props.history.push('/assets/import-reference-documents')
                  }
                />,
              ]}
            >
              <IconButton
                prefix={<ImportIcon />}
                data-testid='AssetsListPage.importAssetsButton'
                className='margin-right'
              >
                Import
              </IconButton>
            </DropdownMenu>
          </ProtectedComponent>
          <ProtectedComponent allowed={ADD_ASSET_ROLES}>
            <AddButton
              prefix='+'
              onClick={() => props.history.push('/assets/add')}
              data-testid='AssetsListPage.addNew'
            >
              New asset
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='AssetsListPage.search'
          />
        </div>
      </div>
      <div className='assets-table'>
        <AssetsTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
        />
      </div>
    </div>
  );
}
