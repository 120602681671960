import React from 'react';

import Input from 'lib/components/input/Input';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Button from 'lib/components/button/Button';
import useLoginForm from '../../useLoginForm';

import { ReactComponent as MailIcon } from 'assets/images/mail.svg';
import { ReactComponent as LockIcon } from 'assets/images/lock.svg';

function LoginForm() {
  const { state, onChangePassword, onChangeEmail, onSubmit } = useLoginForm();

  return (
    <div className='login-form' data-testid='LoginForm.container'>
      <form onSubmit={onSubmit} noValidate={true}>
        <Input
          type='email'
          name='email'
          value={state.email.value}
          errors={state.email.errors}
          onChange={onChangeEmail}
          placeholder='Email'
          data-testid='LoginForm.email'
          renderLeftInsert={() => <MailIcon fill='#6320ee' />}
        />
        <Input
          type='password'
          name='password'
          value={state.password.value}
          errors={state.password.errors}
          onChange={onChangePassword}
          data-testid='LoginForm.password'
          placeholder='Password'
          renderLeftInsert={() => <LockIcon fill='#6320ee' />}
        />

        <ErrorBag errors={state.formErrors} />

        <Button
          type='submit'
          disabled={state.loading}
          data-testid='LoginForm.submit'
        >
          Login
        </Button>
      </form>
    </div>
  );
}

export default LoginForm;
