import classNames from 'classnames';
import Input from 'lib/components/input/Input';
import Checkbox from 'lib/components/checkbox/Checkbox';
import CollapsibleRadio from 'lib/components/collapsible-radio/CollapsibleRadio';
import Button from 'lib/components/button/Button';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import AddButton from 'lib/components/add-button/AddButton';
import EditButton from 'lib/components/edit-button/EditButton';
import useInspectionTypesForm from 'modules/inspection-types/useInspectionTypesForm';
import InspectionTypesDecisionItem from '../inspection-types-decision-item/InspectionTypesDecisionItem';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import Tooltip from 'lib/components/tooltip/Tooltip';

import './InspectionTypesForm.scss';

const InspectionTypesForm = (props) => {
  const vm = useInspectionTypesForm(props);
  const SubmitButton = vm.state.id ? EditButton : AddButton;
  const submitText = vm.state.id ? 'Save changes' : 'Create inspection type';

  const isPassDecisionsEmpty = !vm.state.passDecisions.length;
  const isFailDecisionsEmpty = !vm.state.failDecisions.length;
  // We don't need to check the Add case because isLinkedToWorkflow will be falsy
  const isEditDisabled =
    (vm.state.isLinkedToWorkflow && isPassDecisionsEmpty) ||
    (vm.state.isLinkedToWorkflow && isFailDecisionsEmpty);
  const isSubmitDisabled = vm.state.loading || isEditDisabled;

  if (props.match?.params?.id && !vm.state.id && !vm.state.errors.length) {
    // Prevent displaying incomplete state while
    // loading existing inspection type
    return null;
  }

  const emptyUsageDecisionsMessage = isEditDisabled
    ? `This inspection type is used inside a workflow and \
must contain at least one usage decision`
    : `Inspection type has no usage decisions, and \
will not be available inside workflows`;

  return (
    <div
      className='inspection-types-form'
      data-testid='InspectionTypesForm.container'
    >
      <form noValidate data-testid='InspectionTypesForm.form'>
        <div className='form-row name-input-container'>
          <Input
            name='name'
            type='text'
            charsLeft={vm.state.name.charsLeft}
            label='Name'
            value={vm.state.name.value}
            errors={vm.state.name.errors}
            autoComplete='off'
            onChange={vm.userTypesName}
            data-testid='InspectionTypesForm.name'
          />
        </div>
        <div className='form-row is-survey-input-container'>
          <Checkbox
            checked={vm.state.isSurvey.value}
            label='Survey'
            onChange={() => vm.userSetsIsSurvey(!vm.state.isSurvey.value)}
            className='is-survey'
          />
        </div>
        <div className='form-row status-input-container'>
          <CollapsibleRadio
            label='Status'
            options={vm.statusOptions}
            value={vm.state.status.value}
            onChange={vm.userSetsStatus}
            errors={vm.state.status.errors}
            data-testid='InspectionTypesForm.status'
          />
        </div>
        <div className='form-row type-input-container'>
          <CollapsibleRadio
            label='Target of inspection'
            value={vm.state.type.value}
            onChange={vm.userSetsType}
            options={vm.typeOptions}
            errors={vm.state.type.errors}
            data-testid={'InspectionTypesForm.type'}
          />
        </div>
        <div className='form-row usage-decisions-container'>
          <h4 className='title'>Inspection pass usage decisions</h4>

          <div
            className={classNames('usage-decisions-table table', {
              'is-invalid-decision':
                vm.state.decisionErrors.passDecisions?.errors?.length,
            })}
            data-testid='InspectionTypesForm.passUsageDecisionsTable'
          >
            <Checkbox
              checked={vm.state.automaticPass.value}
              label='Automatic decision'
              onChange={vm.userSetsAutomaticPass}
              className='automatic-decision'
              data-testid='InspectionTypesForm.automaticPass'
            />
            {isPassDecisionsEmpty ? (
              <p data-testid='InspectionTypesForm.usageDecisionsEmpty'>
                {emptyUsageDecisionsMessage}
              </p>
            ) : (
              <>
                <div className='table-row table-header'>
                  <div className='table-cell'>Decision Name</div>
                  <div className='table-cell'>Criteria / Person</div>
                  <div className='table-cell'>Action</div>
                  <div className='table-cell'>Notify</div>
                  <div className='table-cell'></div>
                </div>
                {vm.state.passDecisions.map((decision, index) => (
                  <InspectionTypesDecisionItem
                    key={`decision-item-${index}`}
                    data-index={index}
                    name={{
                      value: decision.name.value,
                      onChange: (e) => {
                        return vm.userTypesPassDecisionName(e, index);
                      },
                      errors: decision.name.errors,
                      charsLeft: decision.name.charsLeft,
                      index,
                    }}
                    criteria={{
                      value: decision.criteria.value,
                      options: vm.criteriasOptions,
                      onChange: (e) =>
                        vm.userSetsPassDecisionCriteria(e, index),
                      errors: decision.criteria.errors,
                    }}
                    action={{
                      value: decision.action.value,
                      options: vm.actionsOptions,
                      onChange: (e) => vm.userSetsPassDecisionAction(e, index),
                      errors: decision.action.errors,
                    }}
                    notifyTo={{
                      value: decision.notifyTo.value,
                      options: vm.notifyOptions,
                      onChange: (e) => vm.userSetsPassDecisionNotify(e, index),
                      errors: decision.notifyTo.errors,
                    }}
                    isDefaultDecision={vm.state.automaticPass.value}
                    onDelete={() => vm.userRemovesPassDecisionItem(index)}
                  />
                ))}
              </>
            )}
          </div>
          <ErrorBag errors={vm.state.decisionErrors.passDecisions?.errors} />
          {!vm.state.automaticPass.value && (
            <Button
              type='button'
              className='medium-button'
              onClick={vm.userAddsPassDecisionItem}
              data-testid='InspectionTypesForm.addPassDecisionItem'
            >
              Add decision
            </Button>
          )}
        </div>
        <div className='form-row usage-decisions-container'>
          <h4 className='title'>Inspection fail usage decisions</h4>
          <div
            className={classNames('usage-decisions-table table', {
              'is-invalid-decision':
                vm.state.decisionErrors.failDecisions?.errors?.length,
            })}
            data-testid='InspectionTypesForm.failUsageDecisionsTable'
          >
            <Checkbox
              checked={vm.state.automaticFail.value}
              label='Automatic decision'
              className='automatic-decision'
              onChange={vm.userSetsAutomaticFail}
              data-testid='InspectionTypesForm.automaticFail'
            />
            {isFailDecisionsEmpty ? (
              <p data-testid='InspectionTypesForm.usageDecisionsEmpty'>
                {emptyUsageDecisionsMessage}
              </p>
            ) : (
              <>
                <div className='table-row table-header'>
                  <div className='table-cell'>Decision Name</div>
                  <div className='table-cell'>Criteria / Person</div>
                  <div className='table-cell'>Action</div>
                  <div className='table-cell'>Notify</div>
                  <div className='table-cell'></div>
                </div>
                {vm.state.failDecisions.map((decision, index) => (
                  <InspectionTypesDecisionItem
                    key={`decision-item-${index}`}
                    data-index={index}
                    name={{
                      value: decision.name.value,
                      onChange: (e) => {
                        return vm.userTypesFailDecisionName(e, index);
                      },
                      errors: decision.name.errors,
                      charsLeft: decision.name.charsLeft,
                      index,
                    }}
                    criteria={{
                      value: decision.criteria.value,
                      options: vm.criteriasOptions,
                      onChange: (e) =>
                        vm.userSetsFailDecisionCriteria(e, index),
                      errors: decision.criteria.errors,
                    }}
                    action={{
                      value: decision.action.value,
                      options: vm.actionsOptions,
                      onChange: (e) => vm.userSetsFailDecisionAction(e, index),
                      errors: decision.action.errors,
                    }}
                    notifyTo={{
                      value: decision.notifyTo.value,
                      options: vm.notifyOptions,
                      onChange: (e) => vm.userSetsFailDecisionNotify(e, index),
                      errors: decision.notifyTo.errors,
                    }}
                    isDefaultDecision={vm.state.automaticFail.value}
                    onDelete={() => vm.userRemovesFailDecisionItem(index)}
                  />
                ))}
              </>
            )}
          </div>
          <ErrorBag errors={vm.state.decisionErrors.failDecisions?.errors} />
          {!vm.state.automaticFail.value && (
            <Button
              type='button'
              className='medium-button'
              onClick={vm.userAddsFailDecisionItem}
              data-testid='InspectionTypesForm.addFailDecisionItem'
            >
              Add decision
            </Button>
          )}
        </div>
        <h4 className='title'>Decision groups</h4>
        <p className='form-info'>
          Decision groups are used in cases where a decision cannot be made on
          first review and responsibility must be forwarded to a different role.
        </p>
        {vm.state.decisionGroups.map((group, groupIndex) => (
          <div className='form-row usage-decisions-container' key={groupIndex}>
            <div className='group-header'>
              <h4 className='title'>Decision group {groupIndex + 1}</h4>
              {vm.hasLinkedGroups ? (
                <Tooltip
                  placement='top'
                  overlay='To delete a group remove all references to groups in the decision actions above'
                >
                  <DeleteButton
                    className='remove-group'
                    disabled={vm.hasLinkedGroups}
                    onClick={() => vm.userRemovesDecisionGroup(groupIndex)}
                  />
                </Tooltip>
              ) : (
                <DeleteButton
                  className='remove-group'
                  disabled={vm.hasLinkedGroups}
                  onClick={() => vm.userRemovesDecisionGroup(groupIndex)}
                />
              )}
            </div>
            <div
              className='usage-decisions-table table'
              data-testid='InspectionTypesForm.GroupDecisionsTable'
            >
              <div className='table-row table-header'>
                <div className='table-cell'>Decision Name</div>
                <div className='table-cell'>Criteria / Person</div>
                <div className='table-cell'>Action</div>
                <div className='table-cell'>Notify</div>
                <div className='table-cell'></div>
              </div>
              {group.map((decision, decisionIndex) => (
                <InspectionTypesDecisionItem
                  key={`decision-item-${decisionIndex}`}
                  data-index={decisionIndex}
                  name={{
                    value: decision.name.value,
                    onChange: (e) => {
                      return vm.userTypesGroupDecisionName(
                        e,
                        groupIndex,
                        decisionIndex,
                      );
                    },
                    errors: decision.name.errors,
                    charsLeft: decision.name.charsLeft,
                    decisionIndex,
                  }}
                  criteria={{
                    value: decision.criteria.value,
                    options: vm.criteriasOptions,
                    onChange: (e) =>
                      vm.userSetsGroupDecisionCriteria(
                        e,
                        groupIndex,
                        decisionIndex,
                      ),
                    errors: decision.criteria.errors,
                  }}
                  action={{
                    value: decision.action.value,
                    options: vm.getGroupForwardOptions(groupIndex),
                    onChange: (e) =>
                      vm.userSetsGroupDecisionAction(
                        e,
                        groupIndex,
                        decisionIndex,
                      ),
                    errors: decision.action.errors,
                  }}
                  notifyTo={{
                    value: decision.notifyTo.value,
                    options: vm.notifyOptions,
                    onChange: (e) =>
                      vm.userSetsGroupDecisionNotify(
                        e,
                        groupIndex,
                        decisionIndex,
                      ),
                    errors: decision.notifyTo.errors,
                  }}
                  onDelete={() =>
                    vm.userRemovesGroupDecisionItem(groupIndex, decisionIndex)
                  }
                  showDeleteButton={decisionIndex > 0}
                />
              ))}
            </div>
            <Button
              type='button'
              className='medium-button'
              onClick={() => vm.userAddsGroupDecisionItem(groupIndex)}
              data-testid='InspectionTypesForm.addGroupDecisionItem'
            >
              Add decision
            </Button>
          </div>
        ))}
        <div className='form-row'>
          <Button
            type='button'
            className='medium-button'
            onClick={vm.userAddsDecisionGroup}
            data-testid='InspectionTypesForm.addDecisionGroup'
          >
            Add decision group
          </Button>
        </div>

        <div className='form-row submit'>
          <ErrorBag errors={vm.state.errors} />

          <SubmitButton
            type='submit'
            disabled={isSubmitDisabled}
            onClick={vm.userSubmitsForm}
            data-testid='InspectionTypesForm.submit'
          >
            {submitText}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};
export default InspectionTypesForm;
