import classNames from 'classnames';

import Button from '../button/Button';
import Modal from '../modal/Modal';
import Textarea from '../textarea/Textarea';

import testId from './fixtures/testId';

import './NotesModal.scss';

const NotesModal = ({
  title = 'Add Notes',
  submitText = 'Submit',
  cancelText = 'Cancel',
  description = null,
  isOpen = false,
  onCancel = () => {},
  onSubmit = () => {},
  className,
  value = '',
  errors = [],
  onChange = () => {},
  charsLeft,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onCancel}
    title={title}
    className={classNames('qm-notes-modal', className)}
    data-testid={testId.container}
    actions={
      <>
        <Button
          onClick={onSubmit}
          data-testid={testId.submit}
          className='button'
        >
          {submitText}
        </Button>
        <Button
          onClick={onCancel}
          data-testid={testId.cancel}
          className='button link-button'
        >
          {cancelText}
        </Button>
      </>
    }
  >
    {description && <p>{description}</p>}
    <Textarea
      charsLeft={charsLeft}
      value={value}
      errors={errors}
      data-testid={testId.textarea}
      onChange={onChange}
      rows={8}
    />
  </Modal>
);

export default NotesModal;
