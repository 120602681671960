import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import ROLES from 'config/roles';

import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';

import './SettingsMenu.scss';

const menuItems = [
  {
    label: 'Company attributes',
    link: '/settings/company-attributes',
  },
  {
    label: 'Defect list',
    link: '/settings/defects',
  },
  {
    label: 'Question groups',
    link: '/settings/question-groups',
  },
  {
    label: 'Questions',
    link: '/settings/questions',
  },
  {
    label: 'Answers',
    link: '/settings/answers',
  },
  {
    label: 'Asset types',
    link: '/settings/asset-types',
  },
  { label: 'Asset statuses', link: '/settings/asset-statuses' },
  {
    label: 'Source types',
    link: '/settings/source-types',
  },
];

function SettingsMenu() {
  return (
    <div className='settings-menu'>
      {menuItems.map((menu) => {
        const classes = classNames('settings-menu-button', {
          disabled: menu.disabled,
        });
        const allowed = menu.allowed || Object.values(ROLES);

        return (
          <ProtectedComponent allowed={allowed} key={menu.link}>
            <NavLink
              className={classes}
              activeClassName='selected'
              to={menu.link}
            >
              {menu.label}
            </NavLink>
          </ProtectedComponent>
        );
      })}
    </div>
  );
}

export default withRouter(SettingsMenu);
