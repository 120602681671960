import _set from 'lodash.set';
import _get from 'lodash.get';
import { cloneDeep } from 'lodash';
import { API_ERROR_CODES } from 'config/errorCodes';
import { id } from './funcHelpers';

/**
 * Check if we have set template for this code and returns it.
 * If nothing is set, returns empty string.
 * @param {string} errorCode First we will check exact error code. If we don't have mapping for this
 * we will check for any of the parameters separated with ".", starting from the last.
 * @returns return template code for predefined message
 */
function getErrorTemplate(errorCode) {
  // TODO: refactor with code from `processAPIBodyError`
  if (errorCode) {
    // first check exact code
    if (API_ERROR_CODES.hasOwnProperty(errorCode)) {
      return API_ERROR_CODES[errorCode];
    } else {
      // if code don't exist check splitted parts
      const split = errorCode?.split('.');
      if (split) {
        while (split.length > 0) {
          const key = split.pop();
          if (API_ERROR_CODES.hasOwnProperty(key)) {
            return API_ERROR_CODES[key];
          }
        }
      }
    }
    // if nothing is found return error code
    return errorCode;
  }
  return '';
}

/**
 * @deprecated
 * Get Error object from the server and generate a Formik-style error object.
 * Function will check all errors and inject string error templates if we have any
 * @param {object} data Error object retrieved from server
 * @returns parsed object
 */
export function parseError(data) {
  let errorResponse = null;
  if (data) {
    const { errorCode, message, details } = data;
    let errors = null;

    if (details) {
      errors = Object.keys(details).map((key) => {
        const response = {
          field: key,
        };
        const errorDetail = details[key];
        if (Array.isArray(errorDetail)) {
          response.errors = errorDetail.map((error) => ({
            error,
            template: getErrorTemplate(error),
          }));
        }
        return response;
      });
    }

    errorResponse = {
      errorCode,
      message,
      errors,
    };
  }
  return errorResponse;
}
/**
 * Replace each occurence of `%%key=default%%` in the `template` string with
 * the either the value in `props[key]` or with `default` if it exists.
 * @param {string} template
 * @param {object} props
 * @throws {TypeError}
 * @returns processed string template
 */
export function parseTemplate(template, props = {}) {
  const regex = /%%(?<key>[a-zA-Z0-9_-]+)(?:=(?<default>.*?))?%%/g;

  let match;
  while ((match = regex.exec(template))) {
    const value = props[match.groups.key] || match.groups.default;

    if (!value) {
      throw new Error(
        `Property ${match.groups.key} required for template "${template}"`,
      );
    }

    template = template.replace(match[0], value);
  }

  return template;
}

/**
 * Get list of errors, parse every template with object props that we provide,
 * and return joined string with all templates
 * @param {array} errors array of errors objects in the format {error, template}
 * @param {object} props properties that can be replaced in the template
 * @param {string} delimiter string used when we are joining multiple error messages
 * @returns concatenated all error parsed messages
 */
export function parseTemplates(errors, props, delimiter = ', ') {
  if (Array.isArray(errors)) {
    return errors
      .map((error) =>
        error.template ? parseTemplate(error.template, props) : '',
      )
      .join(delimiter);
  }
  return '';
}

/** Transform and attach an `error_body_001` QM API response provided in
 * `errorBody` to the provided `state` parameter, mutating it.
 *
 * The api response expected is in the form
 * ```
 * IErrorBody {
 *   [objectPath: string]: Array<string>
 * }
 * ```
 *
 * `config.errorCodes` is a map of `[errorCode]` recevied from API to `string`
 *
 * `config.transformPath` is a function that can change where on the `state`
 * object the current error will be attached using `lodash._set`
 *
 * `config.transformTemplates` is a map of `template: string` to a function
 * with the signature `(template: string, state: object) => string`
 * that allows for filling template variables with state information
 *
 * @param {object} errorBody Object
 * @param {object} state Object on which to add formatted errors
 * @param {{
 *  errorCodes?: {[key]: string},
 *  transformPath?: function,
 *  transformTemplates?: {[key]: function(string,object):string}}
 * } config configuration object
 * @returns new state object
 */
export function processAPIBodyError(errorBody, state, config) {
  const {
    errorCodes = API_ERROR_CODES,
    transformPath = id,
    transformTemplates = {},
  } = config;
  const newState = cloneDeep(state);

  return Object.keys(errorBody).reduce((acc, currPath) => {
    const errors = errorBody[currPath].map((apiError) => {
      if (errorCodes[apiError]) return errorCodes[apiError];
      // TODO: Find out if the error string can ever be longer
      // than 'errorClass.specificError'. If not, the following
      // can be replaced with a single string.split('.') style
      const key = Object.keys(errorCodes).find(
        (key) =>
          apiError.length >= key.length &&
          apiError.indexOf(key) === apiError.length - key.length,
      );

      if (key) {
        return transformTemplates[key]
          ? transformTemplates[key](errorCodes[key], state)
          : errorCodes[key];
      }

      return apiError;
    });

    const processedPath = transformPath(currPath, state);
    return _set(acc, processedPath, {
      ..._get(acc, processedPath),
      errors,
    });
  }, newState);
}

export const makeAPIBodyErrorProcessor = (config) => (error, state) =>
  processAPIBodyError(error, state, config);
