import { Route } from 'react-router';
import { VIEW_SETTINGS_ROLES } from './settingsPermissions.js';
import ProtectedRoute from 'lib/components/protected-route/ProtectedRoute';
import SettingsPage from './components/settings-page/SettingsPage';
import SettingsQuestionsListPage from './components/settings-questions-list-page/SettingsQuestionsListPage';
import SettingsQuestionsAddPage from './components/settings-questions-add-page/SettingsQuestionsAddPage';
import SettingsQuestionsEditPage from './components/settings-questions-edit-page/SettingsQuestionsEditPage';
import SettingsQuestionGroupsListPage from './components/settings-question-groups-list-page/SettingsQuestionGroupsListPage.js';
import SettingsAnswersListPage from './components/settings-answers-list-page/SettingsAnswersListPage';
import SettingsDefectsListPage from './components/settings-defects-list-page/SettingsDefectsListPage';
import SettingsCompanyAttributesListPage from './components/settings-company-attributes-list-page/SettingsCompanyAttributesListPage';
import ImportDefectsPage from './components/import-defects-page/ImportDefectsPage';
import ImportAnswersPage from './components/import-answers-page/ImportAnswersPage';
import ImportCompanyAttributesPage from './components/import-company-attributes-page/ImportCompanyAttributesPage';
import ImportQuestionsPage from './components/import-questions-page/ImportQuestionsPage';
import ImportQuestionGroupsPage from './components/import-question-groups-page/ImportQuestionGroupsPage';
import SettingsAssetTypesListPage from './components/settings-asset-types-list-page/SettingsAssetTypesListPage.js';
import SettingsSourceTypesListPage from './components/settings-source-types-list-page/SettingsSourceTypesListPage.js';
import SettingsAssetStatusesListPage from './components/settings-asset-statuses-list-page/SettingsAssetStatusesListPage.js';
import ImportQuestionGroupAttribtutesPage from './components/import-question-group-attributes-page/ImportQuestionGroupAttributesPage.js';
import ImportQuestionTranslationsPage from './components/import-question-translations-page/ImportQuestionTranslationsPage.js';
import ImportQuestionDefectsPage from './components/import-question-defects-page/ImportQuestionDefectsPage.js';
import ImportQuestionDocumentsPage from './components/import-question-documents-page/ImportQuestionDocumentsPage.js';

export default function SettingsRoutes() {
  return (
    <ProtectedRoute allowed={VIEW_SETTINGS_ROLES} path='/settings'>
      <SettingsPage>
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/company-attributes'
          exact
          render={(props) => <SettingsCompanyAttributesListPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/company-attributes/import'
          exact
          render={(props) => <ImportCompanyAttributesPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/defects'
          exact
          render={(props) => <SettingsDefectsListPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/defects/import'
          exact
          render={(props) => <ImportDefectsPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/answers/import'
          exact
          render={(props) => <ImportAnswersPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/asset-statuses'
          exact
          render={(props) => <SettingsAssetStatusesListPage {...props} />}
        />
        <Route
          path='/settings/question-groups'
          exact
          render={(props) => <SettingsQuestionGroupsListPage {...props} />}
        />
        <Route
          path='/settings/question-groups/import'
          exact
          render={(props) => <ImportQuestionGroupsPage {...props} />}
        />
        <Route
          path='/settings/question-groups/import-attributes'
          exact
          render={(props) => <ImportQuestionGroupAttribtutesPage {...props} />}
        />
        <Route
          path='/settings/questions'
          exact
          render={(props) => <SettingsQuestionsListPage {...props} />}
        />
        <Route
          path='/settings/questions/add'
          exact
          render={(props) => <SettingsQuestionsAddPage {...props} />}
        />
        <Route
          path='/settings/questions/:id/edit'
          exact
          render={(props) => <SettingsQuestionsEditPage {...props} />}
        />
        <Route
          path='/settings/questions/import'
          exact
          render={(props) => <ImportQuestionsPage {...props} />}
        />
        <Route
          path='/settings/questions/import-translations'
          exact
          render={(props) => <ImportQuestionTranslationsPage {...props} />}
        />
        <Route
          path='/settings/questions/import-defects'
          exact
          render={(props) => <ImportQuestionDefectsPage {...props} />}
        />
        <Route
          path='/settings/questions/import-documents'
          exact
          render={(props) => <ImportQuestionDocumentsPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/answers'
          exact
          render={(props) => <SettingsAnswersListPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/asset-types'
          exact
          render={(props) => <SettingsAssetTypesListPage {...props} />}
        />
        <ProtectedRoute
          allowed={VIEW_SETTINGS_ROLES}
          path='/settings/source-types'
          exact
          render={(props) => <SettingsSourceTypesListPage {...props} />}
        />
        <Route
          path='/settings'
          exact
          render={({ history }) => {
            history.push('/settings/company-attributes');
            return null;
          }}
        />
      </SettingsPage>
    </ProtectedRoute>
  );
}
