import { Route } from 'react-router';
import DashboardPage from './components/dashboard-page/DashboardPage';

export default function DashboardRoutes() {
  return (
    <Route
      exact
      path='/dashboard'
      render={(props) => <DashboardPage {...props} />}
    />
  );
}
