import { DEFAULT_LANGUAGE } from 'config/languages';
import { DEPENDENCY, DEPENDENCY_LABEL } from 'config/questionOptions';
import makeLogger from 'lib/makeLogger';
const logErr = makeLogger('lib/dataTransform', console.error);

/**
 * Prepare a map function that transforms a given object to
 * a select option
 * @param {string} label object property to be used as label
 * @param {string} value object property to be used as value
 * @returns {function} function that maps an object to a select option
 */
export const entityToSelectOption = (label, value) => (entity) => ({
  label: entity[label],
  value: entity[value],
});

/**
 * Prepare a map function that transforms a given object to
 * a grouped select option
 * @param {string} label object property to be used as label
 * @param {string} value object property to be used as value
 * @param {string} options object property to be used as options
 * @returns {function} function that maps an object to a grouped select option
 */
export const entityToGroupedSelect = (label, value, options) => (entity) => ({
  label: entity[label],
  value: entity[value],
  options: entity[options]?.map((option) => ({
    groupLabel: entity[label],
    groupValue: entity[value],
    label: option[label],
    value: option[value],
  })),
});

/** Transform a Location object to a string based on
 * provided object keys
 * @param {object} location Location object
 * @param {...string} keys Location object keys to include
 * @returns
 */
export const reduceLocation = (location, ...keys) =>
  location
    ? keys
        .map((key) => location[key])
        .filter((i) => !!i)
        .join(', ')
    : '';

/**
 * Transform a given question dependency criteria to a human readable form
 * @param {string} criteria Question criteria value
 * @param {string | Array<{language: string, label: string}>} answer option label or array of translations
 * @param {string} language
 * @returns string Human readable criteria
 */
export const formatQuestionCriteria = (
  criteria,
  label,
  language = DEFAULT_LANGUAGE,
) => {
  if (criteria === DEPENDENCY.SPECIFIC_ANSWER) {
    if (typeof label === 'string') {
      return `Is answered ${label}`;
    }
    if (Array.isArray(label)) {
      return `Is answered ${getTranslation(label, language).display}`;
    }
  }

  return DEPENDENCY_LABEL[criteria] ?? 'Unknown';
};

/**
 * Get the `lang` translation from the provided `array`.
 * @param {[{language: string, text: string}]} array Array of translations
 * @param {string} lang Desired language
 * @param {[{code: string}]} order array with lagunages order to display the fallback
 * @returns {{
 *     translation: string,
 *     fallback: string,
 *     display: string
 *   }} Object containing translated strings.
 *
 *   `translation` contains target language,
 *
 *   `fallback` contains the first language that isn't an empty string
 *
 *   `display` contains target language text or, if empty, the fallback text.
 */
export const getTranslation = (array, lang, order = null) => {
  if (!Array.isArray(array)) {
    logErr('getTranslation: provided param is not an array!', array);
    return {
      translation: '',
      fallback: '',
      display: '',
    };
  }
  const arrayClone = array.map((item) => ({ ...item }));
  if (order) {
    const codeOrder = order.map((item) => item.code);
    arrayClone.sort(
      (a, b) => codeOrder.indexOf(a.language) - codeOrder.indexOf(b.language),
    );
  }
  const translation =
    arrayClone?.find(
      (n) =>
        n.language?.toLowerCase() === (lang ?? DEFAULT_LANGUAGE).toLowerCase(),
    )?.text || '';
  const fallback = arrayClone?.find((n) => n.text !== '')?.text || '';
  const display = translation || fallback;

  return {
    translation,
    fallback,
    display,
  };
};

/**
 * Set the `lang` translation to `value` for the provided `array`.
 * @param {[{language: string, text: string}]} array Array of translations
 * @param {string} lang Desired language
 * @param {string} value New translation
 * @returns {[{language: string, text: string}]}
 */
export const upsertTranslation = (array, value, language) => {
  const ret = array.map((t) => ({ ...t }));
  const idx = ret.findIndex(
    (n) => n.language.toLowerCase() === language.toLowerCase(),
  );
  if (idx === -1) {
    ret.push({
      language,
      text: value,
    });
  } else {
    ret[idx].text = value;
  }
  return ret;
};

/**
 * Replace multiple spaces with a single space and trim lead/trailing spaces
 * @param {string | undefined} string input string
 * @returns {string} string with normalized whitespace
 */
export function normalizeWhitespace(string) {
  return (string || '').replace(/\s+/g, ' ').trim();
}

export function formatAqlLevelSummary(level, major, minor, functional) {
  return `${level} / ${major} / ${minor} / ${functional}`;
}
