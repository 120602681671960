import { LIST_ACTIONS } from 'lib/list-helper/makeListReducer';
import useQueryParams from 'lib/useQueryParams';
import {
  reducer,
  initialState,
  PAGE_ACTIONS,
} from './inspectionPlansToAssetListReducer';
import assetsService from './assetsService';
import debounce from 'lodash.debounce';
import { useReducer, useEffect } from 'react';

const getInspectionPlans = debounce((params, dispatch) => {
  dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: true });

  return assetsService
    .getInspectionPlans(params)
    .then((results) => {
      dispatch({
        type: LIST_ACTIONS.APP_LOADS_DATA,
        payload: { ...results },
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: LIST_ACTIONS.SHOW_ERROR,
        payload: e.message,
      });
    })
    .then(() => {
      dispatch({ type: LIST_ACTIONS.SET_LOADING, payload: false });
    });
}, 400);

const getAsset = (id, dispatch) => {
  assetsService
    .getAssetDetail(id)
    .then((results) => {
      dispatch({
        type: PAGE_ACTIONS.APP_LOADS_ASSET_DETAIL,
        payload: results,
      });
      return results;
    })
    .catch((e) => {
      dispatch({
        type: PAGE_ACTIONS.RESET_STATE,
        payload: e.payload,
      });
    });
};

const useInspectionPlansToAssetList = ({ location, history, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: PAGE_ACTIONS.APP_LOADS_ASSET_DETAIL,
      payload: location.asset,
    });
  }, []);

  const { setParams, getParams } = useQueryParams(location, history);

  const id = props.match?.params?.id;

  useEffect(() => {
    getAsset(id, dispatch);
  }, []);

  useEffect(() => {
    if (state.asset) {
      getInspectionPlans(
        {
          filters: {
            canAssignTo: +state.asset.id,
            byAssetId: +state.asset.id,
            status: ['draft', 'published'],
          },
          search: state.search,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          page: state.page,
          pageSize: state.pageSize,
        },
        dispatch,
      );
    }
  }, [
    state.asset,
    state.search,
    state.sortBy,
    state.sortOrder,
    state.page,
    state.pageSize,
  ]);

  const userAssignsInspectionPlan = (planId) => {
    assetsService
      .assignInspectionPlanToAsset(planId, state.asset.id)
      .then(() => {
        getAsset(state.asset.id, dispatch);
      })
      .catch((e) => {
        dispatch({
          type: PAGE_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
      });
  };

  const userUnassignsInspectionPlan = (planId) => {
    assetsService
      .assignInspectionPlanToAsset(planId, null)
      .then(() => {
        getAsset(state.asset.id, dispatch);
      })
      .catch((e) => {
        dispatch({
          type: PAGE_ACTIONS.RESET_STATE,
          payload: e.payload,
        });
      });
  };

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  return {
    state,
    dispatch,
    sortBy,
    setPageSize,
    setPage,
    setSearch,
    getParams,
    userAssignsInspectionPlan,
    userUnassignsInspectionPlan,
  };
};

export default useInspectionPlansToAssetList;
