import Input from 'lib/components/input/Input';
import './DatePicker.scss';

const DatePicker = ({ onChange, ...props }) => {
  return (
    <Input
      {...props}
      containerClassName='qm-date-picker-container'
      type='date'
      onChange={(ev) => onChange(ev.target.value)}
    />
  );
};

export default DatePicker;
