import { useEffect, useReducer } from 'react';
import { LIST_ACTIONS } from './makeListReducer';
import useQueryParams from 'lib/useQueryParams';
import { noop } from 'lib/funcHelpers';

/**
 * Hook to provide pagination, sorting and searching for lists/tables
 * with data stored in URL query parameters
 * TODO: Switch data storage from reducer to completely in URL
 * @param {*} location React Router Location
 * @param {*} history React Router History
 * @param {*} reducer Reducer function
 * @param {*} initialData Initial reducer state
 * @param {*} fetchData Optional function to fetch list data
 * @returns
 */
const useQueryList = (
  location,
  history,
  reducer,
  initialData,
  fetchData = noop,
) => {
  const [state, dispatch] = useReducer(reducer, initialData);

  const { setParams, getParams } = useQueryParams(location, history, (params) =>
    fetchData(params, state, dispatch),
  );

  const queryParams = getParams();
  const queryParamsStr = JSON.stringify(queryParams);

  useEffect(() => {
    // Synchronize query params with initial state
    setParams({
      page: queryParams.page ?? state.page,
      sortBy: queryParams.sortBy ?? state.sortBy,
      sortOrder: queryParams.sortOrder ?? state.sortOrder,
    });
  }, []);

  useEffect(() => {
    // as we now have combination of filters we need to run this everytime queryParams change
    dispatch({
      type: LIST_ACTIONS.APP_LOADS_DATA,
      payload: getParams(),
    });
  }, [queryParamsStr]);

  const sortBy = (column, order) => {
    setParams({ sortBy: column, sortOrder: order, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SORTS_BY,
      payload: { column, order },
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setPage = (page) => {
    setParams({ page });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: page,
    });
  };

  const setPageSize = (pageSize) => {
    setParams({ pageSize, page: 1 });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE_SIZE,
      payload: pageSize,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });
  };

  const setSearch = (e) => {
    const { value } = e.target;

    dispatch({
      type: LIST_ACTIONS.USER_TYPES_IN_SEARCH_BAR,
      payload: value,
    });

    dispatch({
      type: LIST_ACTIONS.USER_SETS_PAGE,
      payload: 1,
    });

    setParams({ search: value, page: 1 });
  };

  return {
    state,
    dispatch,
    sortBy,
    setPageSize,
    setPage,
    setSearch,
    getParams,
    setParams,
  };
};

export default useQueryList;
