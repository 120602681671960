import React from 'react';
import { ReactComponent as PencilIcon } from 'assets/images/pencil-outline.svg';
import Tooltip from 'lib/components/tooltip/Tooltip';

import './SettingsAnswersItem.scss';

export default function SettingsAnswersItem(props) {
  return (
    <div className='answer-item'>
      <p>{props.name}</p>
      {!props.editing && (
        <>
          <Tooltip overlay='Edit answer' placement='top'>
            <PencilIcon onClick={props.setEdit} />
          </Tooltip>
        </>
      )}
    </div>
  );
}
