export const INSPECTION_STATUS = {
  /** Inspection is not yet complete and ready to be performed */
  DRAFT: 'draft',
  /** Inspection is ready to be performed */
  PUBLISHED: 'published',
  /** Inspection is downloaded to the iPad app or is in progress */
  IN_PROGRESS: 'in_progress',
  /** Inspection is finished but results need review */
  AWAITING_DECISION: 'awaiting_decision',
  /** Inspection is complete */
  FINISHED: 'finished',
  /** Inspection was skipped */
  SKIPPED: 'skipped',
  /** Inspection is cancelled because it contains wrong data */
  NEEDS_REVIEW: 'needs_review',
  /** Inspection is on hold for reasons not directly related to it */
  ON_HOLD: 'on_hold',
};

/** Statuses available on inspection creation */
export const CREATABLE_STATUS_LIST = [
  INSPECTION_STATUS.DRAFT,
  INSPECTION_STATUS.PUBLISHED,
];

/** Statuses which can be edited */
export const EDITABLE_STATUS_LIST = [
  INSPECTION_STATUS.DRAFT,
  INSPECTION_STATUS.PUBLISHED,
  INSPECTION_STATUS.NEEDS_REVIEW,
  INSPECTION_STATUS.ON_HOLD,
];

/** Statuses for which inspector or date can be reassigned */
export const REASSIGNABLE_STATUS_LIST = [
  INSPECTION_STATUS.DRAFT,
  INSPECTION_STATUS.PUBLISHED,
  INSPECTION_STATUS.NEEDS_REVIEW,
  INSPECTION_STATUS.IN_PROGRESS,
  INSPECTION_STATUS.ON_HOLD,
];

/** Statuses for which inspector can perform and upload results */
export const PERFORMABLE_STATUS_LIST = [
  INSPECTION_STATUS.PUBLISHED,
  INSPECTION_STATUS.IN_PROGRESS,
];

export const INSPECTION_RESULT = {
  /** Inspection is in progress */
  IN_PROGRESS: 'in_progress',
  /** Inspection has passed successfully */
  PASSED: 'passed',
  /** Inspection has defects and was failed */
  FAILED: 'failed',
  /** Inspection was skipped by a user */
  SKIPPED: 'skipped',
  /** Inspection was skipped because another of same
   * type is still within validity for current target */
  VALIDITY_PERIOD: 'validity_period',
  /** Inspection is on hold waiting for further details */
  ON_HOLD: 'on_hold',
};

const INSPECTION_STATUS_LABEL = {
  [INSPECTION_STATUS.DRAFT]: 'Draft',
  [INSPECTION_STATUS.IN_PROGRESS]: 'In progress',
  [INSPECTION_STATUS.AWAITING_DECISION]: 'Awaiting decision',
  [INSPECTION_STATUS.PUBLISHED]: 'Published',
  [INSPECTION_STATUS.NEEDS_REVIEW]: 'Needs review',
  [INSPECTION_STATUS.FINISHED]: 'Finished',
  [INSPECTION_STATUS.SKIPPED]: 'Skipped',
  [INSPECTION_STATUS.ON_HOLD]: 'On hold',

  [INSPECTION_RESULT.FAILED]: 'Failed',
  [INSPECTION_RESULT.PASSED]: 'Passed',
};

/**
 * Get formatted inspection status label or `Unknown`
 * @param {INSPECTION_STATUS} status Inspection status enum
 * @returns {string} Readable inspection status
 */
export const getInspectionStatusLabel = (status) =>
  INSPECTION_STATUS_LABEL[status] || 'Unknown';

export default INSPECTION_STATUS;
