import React from 'react';
import useCompaniesList from '../../useCompaniesList';
import AddButton from 'lib/components/add-button/AddButton';
import SearchInput from 'lib/components/search-input/SearchInput';
import CompaniesTable from '../companies-table/CompaniesTable';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Select from 'lib/components/select/Select';
import { ADD_COMPANIES_ROLES } from 'modules/companies/companiesPermissions';

import './CompaniesListPage.scss';

export default function CompaniesListPage(props) {
  const list = useCompaniesList(props);

  return (
    <div className='companies-list-page'>
      <div className='row companies-list-header container-m'>
        <h2>Companies</h2>

        <div className='add-new-container'>
          <ProtectedComponent allowed={ADD_COMPANIES_ROLES}>
            <AddButton
              onClick={() => props.history.push('/companies/add')}
              data-testid='CompaniesListPage.addNew'
            >
              New company
            </AddButton>
          </ProtectedComponent>
        </div>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput value={list.state.search} onChange={list.setSearch} />
        </div>
        <div className='filter-item'>
          Status:
          <Select
            name='status'
            options={list.statusOptions}
            onChange={(e) => list.setParams({ status: e.value })}
            placeholder='Select'
            value={list.filters.status}
            data-testid='CompaniesListPage.statusFilter'
          />
        </div>
      </div>
      <div className='companies-table'>
        <CompaniesTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          userClicksOnRow={list.userClicksOnRow}
        />
      </div>
    </div>
  );
}
