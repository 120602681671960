import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';

export function CompanyAttributeNameForm(props) {
  return (
    <div className='company-attribute-name-form'>
      <div className='form-label'>Attribute name</div>
      <Input {...props} />
    </div>
  );
}

export function CompanyAttributeOptionForm({
  options,
  onChange,
  onSave,
  onCancel,
  onAddOption,
  onRemoveOption,
  errors,
}) {
  const inputs = options.map((option, idx, array) => (
    <div className='form-row flex'>
      <Input
        key={option.id ?? idx}
        value={option.name}
        errors={option.errors}
        onChange={(ev) => {
          onChange('options', ev.target.value, idx);
        }}
      />
      {option.isRemovable && (idx > 0 || array.length > 1) && (
        <DeleteButton
          className='delete-option-button'
          onClick={() => onRemoveOption(idx)}
        />
      )}
      {idx === 0 && (
        <>
          <Button onClick={onSave} className='save-form-button medium-button'>
            Save
          </Button>
          <Button onClick={onCancel} className='cancel-form-button link-button'>
            Cancel
          </Button>
        </>
      )}
    </div>
  ));

  return (
    <div className='company-attribute-option-form'>
      <div className='form-label'>Options</div>
      {inputs}
      <Button onClick={onAddOption} className='outline-button medium-button'>
        Add new option
      </Button>
      <ErrorBag errors={errors} />
    </div>
  );
}
