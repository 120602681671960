import classNames from 'classnames';
import { ReactComponent as BinSolid } from 'assets/images/bin-solid.svg';
import './DeleteButton.scss';

const DeleteButton = ({ children, disabled = false, className, ...props }) => {
  return (
    <button
      type='button'
      className={classNames('secondary delete-button', className, {
        disabled,
      })}
      disabled={disabled}
      data-testid='DeleteButton.container'
      {...props}
    >
      <BinSolid />
      {children}
    </button>
  );
};

export default DeleteButton;
