import { Link } from 'react-router-dom';
import NewPasswordForm from '../new-password-form/NewPasswordForm';

import './NewPasswordPage.scss';

const NewPasswordPage = (props) => {
  return (
    <div className='new-password-page'>
      <div className='new-password-form-container'>
        <h3>Create a new password</h3>
        <p className='instructions'>
          Please fill out the form below to create a new password for your
          account.
        </p>
        <NewPasswordForm {...props} />
      </div>
      <div className='footer-container'>
        Remembered your password? <Link to='/'>Login</Link>
      </div>
    </div>
  );
};

export default NewPasswordPage;
