import PropTypes from 'prop-types';
import TimelineItem from '../timeline-item/TimelineItem';

import './TimelineNewItem.scss';

import addIcon from 'assets/images/plus-circle-solid.svg';

const TimelineNewItem = (props) => {
  const icon = <img className='new-item-icon' src={addIcon} alt='add new' />;
  return (
    <TimelineItem icon={icon}>
      <div
        className='timeline-new-item'
        data-testid='TimelineNewItem.container'
      >
        <button
          type='button'
          onClick={props.onAddNew}
          data-testid='TimelineNewItem.button'
        >
          {props.text}
        </button>
      </div>
    </TimelineItem>
  );
};

TimelineNewItem.propTypes = {
  text: PropTypes.string.isRequired,
  onAddNew: PropTypes.func.isRequired,
};

export default TimelineNewItem;
