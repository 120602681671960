import { getTranslation } from 'lib/dataTransform';

import Input from 'lib/components/input/Input';
import Modal from 'lib/components/modal/Modal';
import Button from 'lib/components/button/Button';
import Select from 'lib/components/select/Select';
import Tabs from 'lib/components/tabs/Tabs';
import TabsItem from 'lib/components/tabs/tabs-item/TabsItem';
import DeleteButton from 'lib/components/delete-button/DeleteButton';

import './SettingsAnswersInputModal.scss';
import ErrorBag from 'lib/components/error-bag/ErrorBag';

const SettingsInputModal = ({
  isOpen,
  search,
  onSearchChange,
  data = [],
  onRowClick,
  sortOrder,
  setSortBy,
  sortBy,
  onRequestClose,
  ...props
}) => {
  return (
    <Modal
      title='Add new answer'
      className='settings-answers-input-modal'
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onRequestClose}
      data-testid='settings-answers-modal'
      {...props}
    >
      <Tabs>
        {props.languages.map((lang) => (
          <TabsItem
            key={lang.code}
            active={props.language === lang.code}
            onClick={() => props.onLanguageChange(lang.code)}
          >
            {lang.label}
          </TabsItem>
        ))}
      </Tabs>
      <form className='settings-answer-add-form'>
        <Input
          label='Answer group name'
          type='text'
          value={data.name.value}
          errors={data.name.errors}
          autoComplete='off'
          onChange={props.userAddsNewAnswer}
          className='answers-group'
          data-testid='AnswerAddForm.answerValue'
        />

        <div className='column'>
          <div className='row'>
            <div className='column'>
              <div className='label-container'>
                <p>Answers name</p>
                <p>Action</p>
              </div>
              {data.options.map((option, index) => {
                const { translation, fallback } = getTranslation(
                  option.label.value,
                  props.language,
                );
                return (
                  <div className='answer-data' key={`${index}`}>
                    <Input
                      type='text'
                      value={translation ?? ''}
                      placeholder={fallback}
                      errors={option.label.errors}
                      autoComplete='off'
                      onChange={(e) => props.userWritesOptionName(index, e)}
                      className='answers-name'
                      data-testid='AnswerAddForm.answerValue'
                    />
                    <div className='select-action'>
                      <Select
                        value={props.getDefaultModalAction(
                          option.meaning.value,
                        )}
                        errors={option.meaning.errors}
                        placeholder='Select...'
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        menuPlacement={'auto'}
                        options={data.actions}
                        onChange={(e) => props.userSelectAction(index, e)}
                        className='actions-name'
                        data-testid='AnswerAddForm.resourceSelectButton'
                      />
                    </div>
                    {data.options.length > 1 && (
                      <DeleteButton
                        onClick={() => props.clearOptionInModal(index)}
                        data-testid='AnswerAddForm.clearOption'
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              data-testid='AnswerAddForm.save'
              className='medium-button'
              onClick={props.addAnswer}
            >
              Save
            </Button>
            <p className='link-button' onClick={onRequestClose}>
              Cancel
            </p>
          </div>
          <Button
            type='button'
            className='medium-button outline-button'
            onClick={props.userAddsNewAnswerOption}
          >
            Add new field
          </Button>
          <ErrorBag errors={data.errors} />
        </div>
      </form>
    </Modal>
  );
};

export default SettingsInputModal;
