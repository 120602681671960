import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';

export const ASSETS_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

const ASSETS_TO_PILL_VARIANT = {
  [ASSETS_STATUS.DRAFT]: STATUS_PILL_VARIANT.WARNING,
  [ASSETS_STATUS.ACTIVE]: STATUS_PILL_VARIANT.SUCCESS,
  [ASSETS_STATUS.DEACTIVATED]: STATUS_PILL_VARIANT.ERROR,
};

const DEFAULT_STATUS = {
  name: ASSETS_STATUS.DRAFT,
  meaning: ASSETS_STATUS.DRAFT,
};

const AssetsStatusPill = ({ status = DEFAULT_STATUS }) => {
  const variant = ASSETS_TO_PILL_VARIANT[status?.meaning?.toLowerCase()];

  return <StatusPill variant={variant}>{status?.name}</StatusPill>;
};

export default AssetsStatusPill;
