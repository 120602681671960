import React from 'react';
import { ReactComponent as PencilIcon } from 'assets/images/pencil-outline.svg';
import Tooltip from 'lib/components/tooltip/Tooltip';

import './SettingsAssetStatusesItem.scss';
import Button from 'lib/components/button/Button';

export default function SettingsAssetStatusesItem(props) {
  return (
    <div className='asset-statuses-item'>
      <p>{props.name}</p>
      {!props.editing && (
        <>
          <Tooltip overlay='Edit asset status' placement='top'>
            <Button
              className='link-button'
              data-testid='SettingsAssetStatusesItem.editButton'
              onClick={props.setEdit}
            >
              <PencilIcon />
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
}
