import axios from 'axios';
import { API_URL } from 'config/api';
import authService from './authService';
import { tokenStorage, companyStorage } from './localStorageService';
import makeLogger from './makeLogger';
import queryString from 'query-string';
import appBus from './appBus';
import NETWORK_EVENTS from 'events/network-events';
import { formatISO } from 'date-fns';

const log = makeLogger('request');

const isArray = Array.isArray;
const isObject = (value) => typeof value === 'object' && value !== null;

const paramsSerializer = (params) => {
  return queryString.stringify(
    Object.keys(params).reduce((acc, curr) => {
      const value = params[curr];

      // remove undefined values
      if (value === undefined || value === null) {
        return acc;
      }

      // serialize array and object values
      if (isArray(value) || isObject(value)) {
        acc[curr] = JSON.stringify(value);
        return acc;
      }

      acc[curr] = value;
      return acc;
    }, {}),
  );
};

const requestInterceptor = (config) => {
  config.headers['X-Client-Time'] = formatISO(new Date());

  // If we don't specifically say we want an unsigned request
  if (!config.makeUnsigned) {
    // We attempt to take our token from localStorage and add it to the header
    const token = tokenStorage.get();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Get the currentCompany from localStorage and add it to the query params
    const company = companyStorage.get();
    if (company) {
      config.params = {
        ...(config.params || {}),
        companyId: company.id,
      };
    }
  }
  log('Making request with config:', config);
  return config;
};

const responseSuccessInterceptor = (response) => {
  log(
    `Request successful with code ${response.status}:`,
    response.config,
    response,
  );
  return response.data;
};

const responseErrorInterceptor = (error) => {
  if (error.response) {
    // Request was made but server responded with something other than 2xx
    log(`Request failed with code ${error.response.status}:`, error.response);
    if (error.response.status === 401 && !error.config.makeUnsigned) {
      // Token expired and we get an unauthorized
      authService.logout();
      return;
    }
    if (error.response.status === 404) {
      appBus.pub(NETWORK_EVENTS.NOT_FOUND, error);
    }
    if (error.response.status === 403) {
      appBus.pub(NETWORK_EVENTS.FORBIDDEN, error);
    }
  } else {
    // Something else happened while setting up the request
    log(`Request failed with message ${error.message}:`, error);
  }

  return Promise.reject(error);
};

const client = axios.create({ baseURL: API_URL, paramsSerializer });

client.interceptors.request.use(requestInterceptor);

client.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

const request = {
  get: client.get,
  post: client.post,
  put: client.put,
  delete: client.delete,
  makeRequest: client,
};

export default request;
