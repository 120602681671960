import React from 'react';
import Button from 'lib/components/button/Button';
import AssignInspectionPlanToAssetTable from '../assign-inspection-plan-to-asset-table/AssignInspectionPlanToAssetTable';
import useInspectionPlansToAssetList from '../../useInspectionPlansToAssetList';
import SearchInput from 'lib/components/search-input/SearchInput';
import './AssignInspectionPlanToAsset.scss';
import { breadcrumbsTranformData } from '../../dataTransform';
import Breadcrumbs from 'lib/components/breadcrumbs/Breadcrumbs';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import { BUILD_PLAN_ROLES } from 'modules/assets/assetsPermissions';

export default function AssignInspectionPlanToAsset(props) {
  const list = useInspectionPlansToAssetList(props);

  return (
    <div className='assign-inspection-plan-list-page'>
      <div className='inspection-plans-list-header container-m'>
        <Breadcrumbs
          data={breadcrumbsTranformData(
            list.state.asset?.name,
            props.match.params.id,
          )}
        />
        <h2 className='margin-bottom'>Linked inspection plans</h2>

        <p className='margin-bottom'>
          Link or unlink inspection plans from the list below or build a new
          plan for this asset. A new plan will automatically choose questions by
          matching asset attributes to question group tags.
        </p>
        <ProtectedComponent allowed={BUILD_PLAN_ROLES}>
          <Button
            onClick={() => {
              const search = new URLSearchParams();
              search.append('linkedResourceType', 'asset');
              search.append('linkedResourceId', props.match.params.id);
              search.append('redirect', `/assets/${props.match.params.id}`);
              props.history.push(`/inspection-plans/add?${search.toString()}`);
            }}
            data-testid='AssignInspectionPlanToAsset.submit'
            className='medium-button'
          >
            Build plan
          </Button>
        </ProtectedComponent>
      </div>
      <div className='row container-m filter-container'>
        <div className='search-container'>
          <SearchInput
            value={list.state.search}
            onChange={list.setSearch}
            data-testid='AssignInspectionPlanToAsset.search'
          />
        </div>
      </div>
      <div className='assign-inspection-plan-to-an-asset-table'>
        <AssignInspectionPlanToAssetTable
          data={list.state.data}
          setPageSize={list.setPageSize}
          pageSize={list.state.pageSize}
          setPage={list.setPage}
          page={list.state.page}
          count={list.state.count}
          setSortBy={list.sortBy}
          sortOrder={list.state.sortOrder}
          sortBy={list.state.sortBy}
          onAssignClick={list.userAssignsInspectionPlan}
          onUnassignClick={list.userUnassignsInspectionPlan}
        />
      </div>
    </div>
  );
}
