import PropTypes from 'prop-types';
import useRoles from 'lib/useRoles';

const ProtectedComponent = ({
  children,
  allowed = [],
  renderNotAllowed = () => null,
}) => {
  const { isAllowed, currentUserRoles } = useRoles();

  const render = Array.isArray(allowed) ? isAllowed(allowed) : allowed;

  return <>{render ? children : renderNotAllowed(allowed, currentUserRoles)}</>;
};

ProtectedComponent.propTypes = {
  children: PropTypes.node,
  allowed: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  renderNotAllowed: PropTypes.func,
};

export default ProtectedComponent;
