import * as yup from 'yup';
import _set from 'lodash.set';

import validator, { inputShape, qmTagsSchema } from 'lib/validator';
import { makeAPIBodyErrorProcessor } from 'lib/errorHelpers';

export const usersFormValidationSchema = yup.object().shape({
  name: yup
    .object()
    .shape({ value: yup.string().required('Name is a required field') }),
  roles: yup
    .object()
    .shape({ value: yup.array().min(1, 'Roles is a required field') }),
  emails: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    }),
  ),
  phones: yup.array().of(
    yup.object().shape({
      value: yup.string().max(50, 'Phone is too long'),
    }),
  ),
  tags: inputShape(qmTagsSchema),
});

export const processAPIError = (errorDetails, user) => {
  return Object.keys(errorDetails).reduce((acc, curr) => {
    const processedPath = curr.split('.').map((path) => {
      switch (path) {
        case 'phoneNumbers':
          return 'phones';
        case 'companyPosition':
          return 'position';
        case 'companyName':
          return 'company';
        default:
          return path;
      }
    });
    // get only error message, we already have the key
    const values = errorDetails[curr]
      .map((error) => error.split('.')[1])
      .map((error) => {
        switch (error) {
          case 'invalid':
            return 'This value is invalid for this field';
          case 'unique':
            return 'This value needs to be unique';
          default:
            return error;
        }
      });

    let path = processedPath;

    if (processedPath.length > 1) {
      path = [processedPath[0], processedPath[1]];
    }

    path.push('errors');

    return _set(acc, path, values);
  }, user);
};

const apiPathToFormPath = (path) =>
  path
    .replace('phoneNumbers', 'phones')
    .replace('companyPosition', 'position')
    .replace('companyName', 'company')
    .replace(/emails\.([0-9]+)\.email/g, 'emails.$1');

const usersFormValidator = (state) =>
  validator(state, usersFormValidationSchema);

export const processUserFormAPIError = makeAPIBodyErrorProcessor({
  transformPath: apiPathToFormPath,
});

export default usersFormValidator;
