import {
  relativeDate
} from 'lib/dateHelpers';
import { useMemo } from 'react';

const getTimestamp = (dateStr) => {
  const ts = Date.parse(dateStr);

  return ts ? relativeDate(ts) : 'N/A';
}

const renderLatestActions = (laList) => {
  const sortedLaList = laList?.length ? [...laList].sort((a, b) => {
    const _a = Date.parse(a?.performedAt ?? 0);
    const _b = Date.parse(b?.performedAt ?? 0);

    if (_a < _b) return 1;
    if (_a > _b) return -1;
    return 0;
  }) : null;

  const elList = sortedLaList?.map((la, i) => (
    <li key={i} className="user-action-item">
      <span className="url">{la?.url?.length && la?.url?.split('?')[0]}</span>{' '}
      <span className="timestamp">({getTimestamp(la?.performedAt)})</span>
    </li>
  ));

  return elList?.length ? (
    <ol>
      {elList}
    </ol>
  ) : (
    <div>
      No recent actions.
    </div>
  );
}

const renderActiveJobs = (ajList) => {

  const sortedAjList = ajList?.length ? [...ajList].sort((a, b) => {
    const _a = Date.parse(a?.updatedAt ?? 0);
    const _b = Date.parse(b?.updatedAt ?? 0);

    if (_a < _b) return 1;
    if (_a > _b) return -1;
    return 0;
  }) : null;

  const elList = sortedAjList?.map((aj, i) => (
    <li key={i} className="user-job-item">
      <div className="info">
        ID: {aj?.id}, Type: {aj?.jobType},{' '}
        Status: {aj?.status},{' '}
        Processed: {aj?.successCount ?? 0 + aj?.failedCount ?? 0 + aj?.warningCount ?? 0}
      </div>
      <div className="timestamp">
        Created: {getTimestamp(aj?.createdAt)}, Updated: {getTimestamp(aj?.updatedAt)}
      </div>
    </li>
  ));

  return elList?.length ? (
    <ol>
      {elList}
    </ol>
  ) : (
    <div>
      No recent jobs.
    </div>
  );
}

const renderUserItem = (user, idx) => {
  return <div className="user-item">
    <div className="user-item-header"><h4>#{user?.userId ?? '?'} {user?.email}{user?.companyId ? ` (#${user?.companyId} ${user?.companyName})` : null}</h4></div>
    <div className="user-item-details">
      <div className="user-item-block user-item-block-latest-actions">
        <h5>Latest Actions</h5>
        {renderLatestActions(user?.latestActions)}
      </div>
      <div className="user-item-block user-item-block-active-jobs">
        <h5>Active Jobs</h5>
        {renderActiveJobs(user?.activeJobs)}
      </div>
    </div>
  </div>
}

const SystemSettingsPageUsersActivityList = ({ usersData }) => {

  const usersListBlock = useMemo(() => {
    const sortedUsersData = usersData?.length ? [...usersData].sort((a, b) => {
      const _a = parseInt(a?.userId ?? 0);
      const _b = parseInt(b?.userId ?? 0);

      if (_a > _b) return 1;
      if (_a < _b) return -1;
      return 0;
    }) : null;

    let elList = sortedUsersData?.map((u, i) => {
      return (
        <li key={i}>
          {renderUserItem(u, i)}
        </li>
      )
    });

    return elList?.length ? (
      <ol>
        {elList}
      </ol>
    ) : (
      <div>
        No active users.
      </div>
    );
  }, [JSON.stringify(usersData)])

  return (
    <div className='system-settings-users-activity-list'>
      {usersListBlock}
    </div>
  );
}

export { SystemSettingsPageUsersActivityList };
