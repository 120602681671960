import * as yup from 'yup';
import validator from 'lib/validator';

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

const processResetPasswordFormError = (e, input) => {
  return e.inner.reduce((acc, curr) => {
    acc[curr.path] = {
      value: input[curr.path] || '',
      errors: curr.errors,
    };

    return acc;
  }, {});
};

const resetPasswordValidator = (credentials) =>
  validator(
    credentials,
    resetPasswordValidationSchema,
    processResetPasswordFormError,
  );

export default resetPasswordValidator;
