import React from 'react';
import File from 'lib/components/file/File';
import CsvImport from 'lib/components/csv-import/CsvImport';
import useCsvImport from 'lib/useCsvImport';
import ImportResult from 'lib/components/import-error/ImportResult';
import './UsersImportPage.scss';

const errors = {
  name: {
    'string.empty': () => 'Name is required',
    'string.max': () => 'Name length needs to be less than 50 characters',
  },
  'emails.0.email': {
    'string.empty': () => 'Email is required',
    'string.max': () => 'Email length needs to be less than 320 characters',
  },
  'phoneNumbers.0.phone': {
    'string.empty': () => 'Primary Phone Number is required',
    'phoneNumber.invalid': () => 'Primary Phone Number has invalid characters',
    'string.max': () =>
      'Primary Phone Number length needs to be less than 25 characters',
  },
  companyName: {
    'string.max': () =>
      'Company Name length needs to be less than 500 characters',
  },
  companyPosition: {
    'string.max': () =>
      'Company Position length needs to be less than 50 characters',
  },
  users: {
    'user.role.tag': () => 'Role is not taggable',
    'user.tag': () => 'Tag does not exist',
  },
  roles: {
    'array.min': () => 'Role is required',
  },
  'roles.0.id': {
    'number.base': ({ fieldIndices }) =>
      `Role at position ${++fieldIndices[0]} is invalid`,
  },
  __default__: {
    'string.max': ({ field }) =>
      `${field} length needs to be less than 50 characters`,
  },
};

export default function UsersImportPage(props) {
  const { ...list } = useCsvImport(props);

  return (
    <div className='users-import-page container-m'>
      <h2>Import users</h2>
      <p className='form-info'>
        Before importing users, please make sure you structure your data like in
        the example CSV to minimize importing errors.
      </p>
      <div className='document'>
        <a href='documents/users.csv' target='_blank' download>
          <File data={{ name: 'Example CSV', type: 'text/csv' }} />
        </a>
      </div>
      <CsvImport type='users' onUploadInfo={list.uploadInfo} />
      <ImportResult list={list} errorValidations={errors} />
    </div>
  );
}
