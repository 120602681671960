import { memo, useCallback, useMemo } from 'react';

import _isEqual from 'lodash/isEqual';

import Button from 'lib/components/button/Button';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import Input from 'lib/components/input/Input';
import { SelectAutosuggest } from '../select-autosuggest/SelectAutosuggest';
import request from 'lib/request';

const loadDataFunc = async ({ searchStr, setData }) => {
  if (searchStr?.length) {
    await request
      .get('/assets/reference-documents', {
        params: {
          search: searchStr,
          limit: 5,
        },
      })
      .then((res) => {
        if (res?.data?.length) {
          setData(
            res.data
              .map((item) =>
                item?.documentName?.length ? item.documentName : null,
              )
              .filter((item) => !!item),
          );
        } else {
          setData([]);
        }
      })
      .catch(() => {
        setData([]);
      });
  } else {
    setData([]);
  }
};

const QuestionFormAssetRefDocs = memo(
  ({
    data,
    onChange,
    onAddAssetReferenceDocument,
    onRemoveAssetReferenceDocument,
  }) => {
    // Do not include values already set in the question
    const excludeItems = useMemo(
      () => data.assetReferenceDocNames.map((doc) => doc.value),
      [JSON.stringify(data.assetReferenceDocNames)],
    );

    if (!data) {
      return null;
    }

    return (
      <div className='asset-reference-documents-container'>
        <div className='form-label'>Asset Reference Documents</div>
        <p className='field-description'>
          You can add names of Reference Documents of the Inspection's Asset so
          they appear on the question.
        </p>
        {(data.assetReferenceDocNames || []).map((doc, idx) => (
          <div key={idx} className='input-group'>
            <SelectAutosuggest
              value={doc.value}
              errors={doc.errors}
              onChange={(v) => {
                onChange('assetReferenceDocNames', v, idx);
              }}
              excludeItems={
                // Current field value shouldn't be in excludeItems
                excludeItems.filter((item) => item !== doc.value)
              }
              loadDataFunc={loadDataFunc}
            />
            <DeleteButton onClick={() => onRemoveAssetReferenceDocument(idx)} />
          </div>
        ))}
        <Button
          type='button'
          className='medium-button'
          onClick={onAddAssetReferenceDocument}
        >
          Add new
        </Button>
      </div>
    );
  },
  (a, b) => {
    return _isEqual(a, b);
  },
);

export default QuestionFormAssetRefDocs;
