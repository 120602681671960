import { useContext } from 'react';

import { AppContext } from 'App';
import appBus from 'lib/appBus';
import AUTH_EVENTS from 'events/auth-events';
import { companyToAuthState } from 'lib/localStorageService';

import Toggle from 'lib/components/toggle/Toggle';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';

import { ReactComponent as LogoutOutline } from 'assets/images/logout-outline.svg';

import './CompanySelectDropdown.scss';

const CompanySelectDropdown = ({ logOut = false }) => {
  const {
    appState: { company, currentUser },
  } = useContext(AppContext);

  const menuItems = currentUser.companies
    .filter((other) => Number(other.id) !== Number(company?.id))
    .map((company) => (
      <MenuItem
        key={`company-item-${company.id}`}
        label={company.name}
        onClick={() =>
          appBus.pub(AUTH_EVENTS.COMPANY_CHANGED, companyToAuthState(company))
        }
      />
    ));

  const onLogOut = () => {
    appBus.pub(AUTH_EVENTS.COMPANY_CHANGED, null);
  };

  const button = (
    <>
      <div className='selected-company'>
        <div className='selected-company-title'>
          {logOut ? 'Log out of' : 'Company'}
        </div>
        <div className='selected-company-label'>{company?.name}</div>
      </div>
      {logOut ? (
        <LogoutOutline className='company-logout-icon' />
      ) : (
        <Toggle className='company-toggle' direction='down' />
      )}
    </>
  );

  if (logOut) {
    return (
      <div onClick={onLogOut} className='qm-company-select-dropdown-container'>
        {button}
      </div>
    );
  }

  return menuItems.length ? (
    <DropdownMenu
      containerClassName='qm-company-select-dropdown-container'
      items={menuItems}
    >
      {button}
    </DropdownMenu>
  ) : null;
};

export default CompanySelectDropdown;
