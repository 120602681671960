import WorkflowsForm from '../workflows-form/WorkflowsForm';
import './WorkflowsAddPage.scss';

const WorkflowsAddPage = (props) => {
  return (
    <div
      className='workflows-add-page container-m'
      data-testid='WorkflowsAddPage.container'
    >
      <div className='row'>
        <h1>New workflow</h1>
      </div>
      <div className='row'>
        <WorkflowsForm {...props} />
      </div>
    </div>
  );
};

export default WorkflowsAddPage;
