export const RESET_PASSWORD_ACTIONS = {
  USER_TYPES_EMAIL: 'USER_TYPES_EMAIL',
  RESET_STATE: 'RESET_STATE',
  FORM_LOADING: 'FORM_LOADING',
};

export const INITIAL_RESET_PASSWORD_STATE = {
  email: {
    value: '',
    errors: [],
  },
  loading: false,
  messages: [],
  formErrors: [],
};

export const resetPasswordReducer = (state, action) => {
  switch (action.type) {
    case RESET_PASSWORD_ACTIONS.USER_TYPES_EMAIL:
      return {
        ...state,
        email: {
          value: action.payload || '',
          errors: [],
        },
      };
    case RESET_PASSWORD_ACTIONS.RESET_STATE:
      return { ...state, ...action.payload };
    case RESET_PASSWORD_ACTIONS.FORM_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default resetPasswordReducer;
