import StatusPill from '../status-pill/StatusPill';
import Tooltip from '../tooltip/Tooltip';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';

import './ThirdPartyPill.scss';

const ThirdPartyPill = () => (
  <Tooltip overlay='Third party'>
    <StatusPill className={'qm-third-party-pill'} variant='warning'>
      <DownloadIcon />
    </StatusPill>
  </Tooltip>
);

export default ThirdPartyPill;
