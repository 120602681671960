import { AppContext } from 'App';
import { formatDate } from 'lib/dateHelpers';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as WarningCircleOutline } from 'assets/images/warning-circle-outline.svg';
import { ReactComponent as LinkOutline } from 'assets/images/link-outline.svg';
import classNames from 'classnames';

import Breadcrumbs from 'lib/components/breadcrumbs/Breadcrumbs';
import Card from 'lib/components/card/Card';
import Defect from 'lib/components/defect/Defect';
import DropdownMenu, {
  MenuItem,
} from 'lib/components/dropdown-menu/DropdownMenu';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import IconButton from 'lib/components/icon-button/IconButton';
import InspectionUsageDecisions from 'lib/components/inspection-usage-decisions/InspectionUsageDecisions';
import InspectionsResultPill from 'lib/components/inspections-result-pill/InspectionsResultPill';
import NamedAvatar from 'lib/components/named-avatar/NamedAvatar';
import QuestionGroup from 'lib/components/question-group/QuestionGroup';
import Radio from 'lib/components/radio/Radio';
import { reduceLocation } from 'lib/dataTransform';
import InspectionPreviousDecisions from 'lib/inspection-previous-decisions/InspectionPreviousDecisions';
import useInspectionsResult from 'modules/inspections/useInspectionsResult';
import AnswerDetail from '../answer-detail/AnswerDetail';
import PhotosModal from '../photos-modal/PhotosModal';
import ReInspectionModal from '../re-inspection-modal/ReInspectionModal';
import UsageDecisionNotesModal from '../usage-decision-notes-modal/UsageDecisionNotesModal';

import { ReactComponent as PrintOutline } from 'assets/images/print-outline.svg';
import { ReactComponent as SpreadsheetSolid } from 'assets/images/spreadsheet-solid.svg';

import InspectionPlanStatusPill from 'lib/components/inspection-plans-status-pill/InspectionPlansStatusPill';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import { useHistory } from 'react-router';
import { WorkObjectLink } from '../WorkObjectLink';
import './InspectionsResultPage.scss';
import AqlOverviewTable from './AqlOverviewTable';
import InspectionUsageDecisionsOverride from 'lib/components/inspection-usage-decisions/InspectionUsageDecisionsOverride';
import { OVERRIDE_USAGE_DECISIONS } from 'modules/assets/assetsPermissions';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import InfoBox, { INFO_BOX_VARIANT } from 'lib/components/info-box/InfoBox';

// TODO: Export to utils(reused)
const getIsDefectAqlRejected = (inspection, defectType) => {
  let isReject = false;

  let allowedCount = null;
  let actualCount = null;

  if (['critical', 'major', 'minor', 'functional'].includes(defectType)) {
    allowedCount = inspection[`${defectType}DefectAllowed`];
    actualCount = inspection[`${defectType}DefectsCountAll`];
  } else if (defectType === 'functionalPlusMajor') {
    allowedCount =
      Number(inspection.majorDefectAllowed) +
      Number(inspection.functionalDefectAllowed) +
      1;
    actualCount =
      Number(inspection.majorDefectsCountAll) +
      Number(inspection.functionalDefectsCountAll);
  } else if (defectType === 'severe') {
    isReject = true;
  } else if (defectType === 'informational') {
    isReject = false;
  }

  if (allowedCount !== null && actualCount !== null) {
    isReject = actualCount > allowedCount;
  }

  return isReject;
};

export default function InspectionsResultPage({ id }) {
  const { appState } = useContext(AppContext);

  const history = useHistory();

  const isEnableInspectionFunctionalPlusMajor =
    !!appState?.company?.customConfig?.enableInspectionFunctionalPlusMajor;

  const isEnableInspectionUsageDecisionOverride =
    !!appState?.company?.customConfig?.enableInspectionUsageDecisionOverride;

  const { state, ...vm } = useInspectionsResult({ id });
  const renderOptions = () =>
    (state?.currentReInspectOptions || []).map((option) => ({
      value: option?.id,
      label: option?.name,
    }));

  const showAQLStandards =
    state?.inspection?.criticalDefectValue !== null &&
    state?.inspection?.majorDefect !== null &&
    state?.inspection?.minorDefect !== null &&
    state?.inspection?.functionalDefect !== null;

  const totalSampleSize = useMemo(() => {
    let rsltSampleSize = 0;

    state?.inspection?.questionGroups?.forEach((qg) =>
      qg?.questions?.forEach((q) => {
        if (Number.isFinite(q?.sampleSize) && q.sampleSize > rsltSampleSize) {
          rsltSampleSize = q.sampleSize;
        }
      }),
    );

    return rsltSampleSize;
  }, [JSON.stringify(state?.inspection?.questionGroups)]);

  const combinedInspectionResultIds = useMemo(() => {
    const output = [];
    if (
      Array.isArray(state?.inspection?.woCustomFields) &&
      state?.inspection?.woCustomFields?.length
    ) {
      const cfItem = state.inspection.woCustomFields.find(
        (item) => item?.name === 'Combined Inspection Result IDs',
      );
      if (cfItem?.data?.length) {
        const cfItemDataArray = cfItem.data.split(',');
        cfItemDataArray?.forEach((item) => {
          if (/^\d+$/?.test(item?.trim())) {
            output.push(parseInt(item));
          }
        });
      }
    }

    return output;
  }, [JSON.stringify(state?.inspection?.woCustomFields)]);

  if (state.loading) {
    return (
      <div
        data-testid='InspectionsResultPage.loading'
        className='inspection-results-page container-m'
      >
        <ErrorBag errors={state.errors} />
      </div>
    );
  }

  if (state.errors.length) {
    return (
      <div
        data-testid='InspectionsResultPage.errors'
        className='inspection-results-page container-m'
      >
        <ErrorBag errors={state.errors} />
      </div>
    );
  }

  const vendor = state?.inspection?.stepPair?.partialStep?.partialWorkObject
    ?.parentWorkObject?.vendor?.id
    ? state?.inspection?.stepPair?.partialStep?.partialWorkObject
      ?.parentWorkObject?.vendor
    : null;

  // TODO: implement breadcrumbs hook to attach crumbs to react-router
  const breadcrumbs = [
    {
      name: 'Inspections',
      link: '/inspections',
    },
    {
      name: `${state.inspection.inspectionType.name} ID: ${state.inspection.id}`,
      link: `/inspections/${state.inspection.id}`,
    },
  ];

  return (
    <div
      className={
        classNames(
          'inspection-results-page',
          'inspection-results-page-single',
          'container-m',
          { 'is-vendor-survey': state?.inspection?.inspectionType?.isSurvey }
        )
      }
      data-testid='InspectionsResultPage.container'
    >
      <Breadcrumbs data={breadcrumbs} />
      <div className='page-header-container'>
        <div className='page-header'>
          <h2>
            Inspection results
            {!!state?.inspection?.parentInspectionId && (
              <StatusPill variant={STATUS_PILL_VARIANT.WARNING}>
                Reinspection
              </StatusPill>
            )}
            {!!state.inspection.result && (
              <InspectionsResultPill
                data-testid='InspectionsResultPage.result'
                status={state.inspection.result}
              />
            )}
          </h2>
          <div className='defect-summary'>
            {!!state.inspection.criticalDefectsCountAll && (
              <Defect
                data-testid='InspectionsResultPage.criticalErrors'
                count={state.inspection.criticalDefectsCountAll}
                weight={'critical'}
                isAqlRejected={getIsDefectAqlRejected(
                  state.inspection,
                  'critical',
                )}
              />
            )}
            {!!state.inspection.majorDefectsCountAll && (
              <Defect
                data-testid='InspectionsResultPage.majorErrors'
                count={state.inspection.majorDefectsCountAll}
                weight={'major'}
                isAqlRejected={getIsDefectAqlRejected(
                  state.inspection,
                  'major',
                )}
              />
            )}
            {!!state.inspection.minorDefectsCountAll && (
              <Defect
                data-testid='InspectionsResultPage.minorErrors'
                count={state.inspection.minorDefectsCountAll}
                weight={'minor'}
                isAqlRejected={getIsDefectAqlRejected(
                  state.inspection,
                  'minor',
                )}
              />
            )}
            {!!state.inspection.functionalDefectsCountAll && (
              <Defect
                data-testid='InspectionsResultPage.functionalErrors'
                count={state.inspection.functionalDefectsCountAll}
                weight={'functional'}
                isAqlRejected={getIsDefectAqlRejected(
                  state.inspection,
                  'functional',
                )}
              />
            )}
            {isEnableInspectionFunctionalPlusMajor &&
              (!!state.inspection.functionalDefectsCountAll ||
                !!state.inspection.majorDefectsCountAll) && (
                <Defect
                  data-testid='InspectionsResultPage.functionalPlusMajorErrors'
                  count={
                    (state.inspection.functionalDefectsCountAll ?? 0) +
                    (state.inspection.majorDefectsCountAll ?? 0)
                  }
                  weight={'major'}
                  label='Functional & Major'
                  isAqlRejected={getIsDefectAqlRejected(
                    state.inspection,
                    'functionalPlusMajor',
                  )}
                />
              )}
          </div>
        </div>
        <div className='inspection-actions-container'>
          <DropdownMenu
            data-testid='InspectionsResultPage.downloadDropdown'
            items={[
              <MenuItem
                label='Inspection results'
                data-testid='InspectionsResultPage.exportQuestionGroups'
                onClick={vm.userClicksDownloadCSV}
              />,
              <MenuItem
                label='Corrective Action Form'
                data-testid='InspectionsResultPage.exportQuestionGroupAttributes'
                onClick={vm.userClicksDownloadCAP}
              />,
            ]}
          >
            <IconButton
              data-testid='InspectionsResultPage.downloadButton'
              prefix={<SpreadsheetSolid />}
            >
              Download
            </IconButton>
          </DropdownMenu>
          <IconButton
            data-testid='InspectionsResultPage.printButton'
            onClick={vm.userClicksPrint}
            prefix={<PrintOutline />}
          >
            Print
          </IconButton>
        </div>
      </div>

      {!!combinedInspectionResultIds?.length && (
        <InfoBox
          className='combined-inspection-results-info'
          icon={<LinkOutline />}
          variant={INFO_BOX_VARIANT.INFO}
        >
          This inspection is part of a combined inspection results group.
          <br />
          Click{' '}
          <Link
            target='_blank'
            to={`/inspections/results?id=${combinedInspectionResultIds.join(
              ',',
            )}`}
          >
            here
          </Link>{' '}
          to view the combined results.
        </InfoBox>
      )}

      <div className='inspeciton-plan-info'>
        <div className='inspection-plan-summary'>
          {!!state.inspection.validityValue && (
            <div className='attribute'>
              <div className='label'>Validity</div>
              <div className='value'>
                {state.inspection.validityValue}{' '}
                {state.inspection.validityRange}
              </div>
            </div>
          )}
          {state.inspection.validityValue && state.inspection.validUntil && (
            <div className='attribute'>
              <div className='label'>Valid until</div>
              <div className='value'>
                {formatDate(state.inspection.validUntil)}
              </div>
            </div>
          )}
          {!!state.inspection.aqlLevel && (
            <div className='attribute'>
              <div className='label'>AQL Level</div>
              <div className='value'>{state.inspection.aqlLevel}</div>
            </div>
          )}
          {!!state.inspection.majorDefect && (
            <div className='attribute'>
              <div className='label'>Major defects</div>
              <div className='value'>{state.inspection.majorDefect}</div>
            </div>
          )}
          {!!state.inspection.minorDefect && (
            <div className='attribute'>
              <div className='label'>Minor defects</div>
              <div className='value'>{state.inspection.minorDefect}</div>
            </div>
          )}
          {!!state.inspection.functionalDefect && (
            <div className='attribute'>
              <div className='label'>Functional defects</div>
              <div className='value'>{state.inspection.functionalDefect}</div>
            </div>
          )}
          <div className='attribute'>
            <div className='label'>Critical defects</div>
            <div className='value'>
              {state.inspection.criticalDefectValue}
              {state.inspection.criticalDefectRule === '%' ? '%' : ''}
            </div>
          </div>
          <div className='attribute'>
            <div className='label'>Sample Size</div>
            <div className='value'>
              {totalSampleSize}
              {state?.inspection?.quantityUOM?.length
                ? ` ${state?.inspection?.quantityUOM}`
                : null}
            </div>
          </div>
        </div>

        <AqlOverviewTable
          inspection={state?.inspection}
          showAQLStandards={showAQLStandards}
          isEnableInspectionFunctionalPlusMajor={
            isEnableInspectionFunctionalPlusMajor
          }
        />

        <div className='inspection-summary'>
          <div className='cards'>
            <div className='card-column'>
              <h5>Target of inspection</h5>
              {state.inspection.asset ? (
                <Link to={`/assets/${state.inspection.asset.id}`}>
                  <Card
                    title={state.inspection.asset.name}
                    subtitle={
                      <>
                        ID: <strong>{state.inspection.asset.id}</strong>
                        {state.inspection.asset.externalId && (
                          <>
                            {' '}
                            External ID:{' '}
                            <strong>{state.inspection.asset.externalId}</strong>
                          </>
                        )}
                      </>
                    }
                    description={'Asset'}
                    image={state.inspection.asset.images?.[0]?.url}
                  />
                </Link>
              ) : state.inspection.source ? (
                <Link to={`/sources/${state.inspection.source.id}`}>
                  <Card
                    title={state.inspection.source.name}
                    subtitle={
                      <>
                        ID: {state.inspection.source.id}
                        {state.inspection.source.externalId && (
                          <>
                            {' '}
                            External ID: {state.inspection.source.externalId}
                          </>
                        )}
                      </>
                    }
                    description={'Source'}
                    image={state.inspection.source.images?.[0]?.url}
                  />
                </Link>
              ) : null}
            </div>
            {!state.inspection.isThirdParty && (
              <div className='card-column'>
                <h5 className='header-with-actions'>
                  <span>Inspection plan</span>
                </h5>
                <Link
                  to={`/inspection-plans/${state.inspection.inspectionPlan.id}`}
                >
                  <Card
                    title={state.inspection.inspectionPlan.name}
                    subtitle={
                      <>
                        {`ID: ${state.inspection.inspectionPlan.id} `}
                        <InspectionPlanStatusPill
                          inline
                          status={state.inspection.inspectionPlan.status}
                        />
                      </>
                    }
                  />
                </Link>
              </div>
            )}
          </div>
          <div className='attributes'>
            {!!state.inspection.workObjectId && (
              <div className='attribute workobject-attribute'>
                <div className='attribute-title'>Work object ID</div>
                <div className='attribute-value'>
                  <WorkObjectLink inspection={state.inspection} />
                </div>
              </div>
            )}
            {!!state.inspection.asset && (
              <div className='attribute source-attribute'>
                <div className='attribute-title'>Source</div>
                <div className='attribute-value'>
                  <div className='source-name'>
                    <Link to={`/sources/${state.inspection.locationSource.id}`}>
                      {state.inspection.locationSource.name}
                    </Link>
                  </div>
                  <div className='source-external-id'>
                    External ID: {state.inspection.locationSource.externalId}
                  </div>
                  <div className='source-location'>
                    {reduceLocation(
                      state.inspection.locationSource.location,
                      'city',
                      'country',
                    )}
                  </div>
                </div>
              </div>
            )}
            {!!vendor && (
              <div className='attribute vendor-attribute'>
                <div className='attribute-title'>Vendor</div>
                <div className='attribute-value'>
                  <div className='source-name'>
                    <Link to={`/sources/${vendor.id}`}>{vendor.name}</Link>
                  </div>
                  <div className='source-external-id'>
                    External ID: {vendor.externalId}
                  </div>
                </div>
              </div>
            )}
            <div className='attribute inspector-attribute'>
              <div className='attribute-title'>Inspector</div>
              <div className='attribute-value'>
                <NamedAvatar
                  user={state.inspection?.inspector}
                  subtitle={`ID: ${state.inspection?.inspector?.id}`}
                  onClick={() =>
                    history.push(`/users/${state.inspection?.inspector?.id}`)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!!state.inspection?.summary && (
        <div className='inspection-summary-container'>
          <h4>Inspection summary</h4>
          <p>{state.inspection.summary}</p>
        </div>
      )}
      <h4>Questions</h4>
      <Radio
        options={vm.filterOptions}
        onChange={vm.userChangesFilter}
        value={state.defectFilter}
        hideCheckbox
      />

      {!!state?.inspection?.asset &&
        !state?.inspection?.inProgressAssetSnapshot && (
          <InfoBox
            className='no-asset-snapshot-warning'
            icon={<WarningCircleOutline />}
            variant={INFO_BOX_VARIANT.WARNING}
          >
            This inspection doesn't contain the historical record of the Asset
            at the time the inspection was started. Quantitative answer
            differences will be shown compared to the current Asset values.
          </InfoBox>
        )}
      <div className='questions-container'>
        {vm.getDisplayQuestionGroups().map((group) => (
          <QuestionGroup key={group.id} hideOrder data={group}>
            {group.questions.map((question) => (
              <AnswerDetail
                initialOpen={true}
                key={question.id}
                data={question}
                inspection={state.inspection}
                onPhotoClick={vm.userClicksPhoto}
              />
            ))}
          </QuestionGroup>
        ))}
      </div>
      <div className='section section-usage-decisions'>
        <div className='section-header'>
          <div className='section-header-title'>
            <h4>Decisions</h4>
          </div>
          <div className='section-header-actions'></div>
        </div>
        {!!state?.inspection?.actualDecisions?.length && (
          <InspectionPreviousDecisions inspection={state.inspection} />
        )}
        {!!state?.inspection?.usageDecisions?.length && (
          <InspectionUsageDecisions
            actions={vm}
            inspection={state.inspection}
          />
        )}
      </div>
      {!!isEnableInspectionUsageDecisionOverride && (
        <ProtectedComponent allowed={OVERRIDE_USAGE_DECISIONS}>
          <div className='section section-usage-decisions-override'>
            <div className='section-header'>
              <div className='section-header-title'>
                <h4>Decision Override</h4>
              </div>
            </div>
            <p>
              You can override the current state of the Workflow by selecting
              any of the available decisions below.
            </p>
            <InspectionUsageDecisionsOverride
              actions={vm}
              inspection={state.inspection}
            />
          </div>
        </ProtectedComponent>
      )}
      <UsageDecisionNotesModal
        data={state.takeDecisionModal}
        onSubmit={vm.userConfirmsInspectionAction}
        onCancel={vm.userCancelsInspectionAction}
        onChange={vm.userTypesActionNote}
        confirmClose={state.takeDecisionModal.isDirty}
      />
      <ReInspectionModal
        onSubmit={vm.userSubmitsReInspect}
        onCancel={vm.userCancelsReInspectModal}
        onChange={vm.userSetsReInspectAql}
        data={state.reInspectAql}
        isOpen={state.isReInspectModalOpen}
        options={renderOptions()}
      />
      <PhotosModal
        onCancel={vm.userClosesPhotosModal}
        isOpen={state.isPhotosModalOpen}
        data={state.photosModal}
      />
    </div>
  );
}
