import * as yup from 'yup';

import { ERROR_TEMPLATES, API_ERROR_CODES } from 'config/errorCodes';
import {
  ANSWER_TYPE,
  MULTIPLE_CHOICE_ANSWER_TYPES,
} from 'config/questionOptions';

import validator, {
  yupNumber,
  inputShape,
  requiredFormObject,
  requiredFormString,
} from 'lib/validator';
import { makeAPIBodyErrorProcessor, parseTemplate } from 'lib/errorHelpers';

const ERRORS = {
  ...ERROR_TEMPLATES,
  nonNegative: 'Value must not be negative',
  integer: 'Value must be an integer',
  nameLength: parseTemplate(ERROR_TEMPLATES.exceeded, {
    name: 'Name',
    max: '40 characters',
  }),
  fileSize: 'Maximum allowed size for documents is 5MB',
  defectWeight: 'Defect weight is required',
  maxPercent: parseTemplate(ERROR_TEMPLATES.exceeded, { max: '100%' }),
  lowerToleranceWeight: 'Lower tolerance weight is required',
  upperToleranceWeight: 'Upper tolerance weight is required',
  criteriaMin: parseTemplate(ERROR_TEMPLATES.minimum, {
    field: 'Dynamic criteria',
    min: '0',
  }),
};

const questionDocumentValidationSchema = yup.object().shape({
  value: yup
    .string()
    .max(40, ERRORS.nameLength)
    .matches(/^([a-zA-Z0-9 ]+)$/, ERRORS.alphanumeric)
    .required(parseTemplate(ERRORS.required)),
  file: yup
    .mixed()
    .test('fileSize', ERRORS.fileSize, (file) => {
      return file?.url || file?.size <= 5 * 1024 * 1024;
    })
    .required(parseTemplate(ERRORS.required)),
});

const questionToolValidationSchema = yup.object().shape({
  value: yup.string().matches(/^([a-zA-Z0-9 _]+)$/, ERRORS.alphanumeric),
});

const questionDefectValidationSchema = yup.object().shape({
  weight: yup.object().nullable().required(ERRORS.defectWeight),
});

const questionFormValidationSchema = yup.object().shape({
  name: yup.object().when('isEditing', {
    is: true,
    then: inputShape(
      yup
        .array()
        .test(
          'atLeastOneTranslation',
          ERRORS.required,
          (translations) => !!translations.filter((t) => t.text !== '').length,
        ),
    ),
  }),
  type: yup
    .object()
    .when('isEditing', { is: true, then: requiredFormObject() }),
  questionWeight: yup
    .object()
    .when('isEditing', { is: true, then: requiredFormObject() }),
  answerType: yup
    .object()
    .when('isEditing', { is: true, then: requiredFormObject() }),
  answer: yup.object().when(['isEditing', 'answerType'], {
    is: (edit, typ) =>
      edit && MULTIPLE_CHOICE_ANSWER_TYPES.includes(typ.value?.value),
    then: requiredFormObject(),
  }),
  expectedBarcodeUOM: yup.object().when(['isEditing', 'answerType'], {
    is: (edit, typ) => edit && typ.value?.value === ANSWER_TYPE.BARCODE_INPUT,
    then: requiredFormObject(),
  }),

  useAqlLevel: yup
    .object()
    .when('isEditing', { is: true, then: inputShape(yup.boolean()) }),
  sampleQty: yup.object().when(['isEditing', 'useAqlLevel'], {
    is: (edit, aql) => edit && aql.value === false,
    then: yup.object().when(['sampleRule'], {
      is: (rule) => rule.value === '%',
      then: inputShape(
        yupNumber.required(ERRORS.required).max(100, ERRORS.maxPercent),
      ),
      otherwise: inputShape(
        yupNumber.required(ERRORS.required).integer(ERRORS.integer),
      ),
    }),
  }),
  sampleRule: inputShape(yup.string()),
  aqlMajor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlMinor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlFunctional: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  criticalDefect: yup
    .object()
    .when(['isEditing', 'aqlLevel'], {
      is: (edit, level) => edit && level.value !== null,
      then: inputShape(
        yupNumber
          .integer(ERRORS.integer)
          .min(0, ERRORS.nonNegative)
          .required(ERRORS.required),
      ),
    })
    .when(['criticalDefectRule'], {
      is: (rule) => rule.value === '%',
      then: inputShape(
        yupNumber.required(ERRORS.required).max(100, ERRORS.maxPercent),
      ),
    }),

  photoRequired: yup.object().when('isEditing', {
    is: true,
    then: requiredFormString(ERRORS.required),
  }),
  printOnReport: yup
    .object()
    .when('isEditing', { is: true, then: requiredFormObject() }),

  dependencyQuestion: yup.object().when(['isEditing', 'dependencyAction'], {
    is: (edit, act) => edit && act.value?.value !== false,
    then: requiredFormObject(),
  }),
  dependencyCriteria: yup.object().when(['isEditing', 'dependencyAction'], {
    is: (edit, act) => edit && act.value?.value !== false,
    then: requiredFormObject(),
  }),

  dynamicRule: yup.object().when(['isEditing', 'dynamicAction'], {
    is: (edit, act) => edit && act.value?.value !== false,
    then: requiredFormObject(),
  }),
  dynamicCriteria: yup
    .object()
    .when(['isEditing', 'dynamicAction', 'dynamicRule'], {
      is: (edit, act, rule) =>
        edit &&
        act.value?.value !== false &&
        rule.value?.value !== 'first_inspection',
      then: inputShape(
        yupNumber.positive(ERRORS.criteriaMin).required(ERRORS.required),
      ),
    }),
  upperTolerance: yup
    .object()
    .when(['isEditing', 'answerType'], {
      is: (edit, typ) => edit && typ.value?.value === 'quantitative_input',
      then: yup.object(),
    })
    .when(['upperToleranceRule'], {
      is: (rule) => rule.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
      otherwise: inputShape(yupNumber),
    }),
  upperToleranceWeight: yup
    .object()
    .when(['isEditing', 'answerType', 'upperTolerance'], {
      is: (edit, typ, tol) =>
        edit &&
        typ.value?.value === 'quantitative_input' &&
        tol.value !== undefined,
      then: requiredFormObject(ERRORS.upperToleranceWeight),
    }),
  lowerTolerance: yup
    .object()
    .when(['isEditing', 'answerType'], {
      is: (edit, typ) => edit && typ.value?.value === 'quantitative_input',
      then: yup.object(),
    })
    .when(['lowerToleranceRule'], {
      is: (rule) => rule.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
      otherwise: inputShape(yupNumber),
    }),
  lowerToleranceWeight: yup
    .object()
    .when(['isEditing', 'answerType', 'lowerTolerance'], {
      is: (edit, typ, tol) =>
        edit &&
        typ.value?.value === 'quantitative_input' &&
        tol.value !== undefined,
      then: requiredFormObject(ERRORS.lowerToleranceWeight),
    }),

  customExpectedMeasureValue: yup
    .object()
    .when(['isEditing', 'expectedMeasureTableResult'], {
      is: (edit, res) => edit && res.value?.value === 'Custom',
      then: inputShape(yupNumber.required(ERRORS.required)),
    }),
  customExpectedUom: yup
    .object()
    .when(['isEditing', 'expectedMeasureTableResult'], {
      is: (edit, res) => edit && res.value?.value === 'Custom',
      then: requiredFormObject(),
    }),
  otherMeasure: yup.object().when(['isEditing', 'expectedMeasureTableResult'], {
    is: (edit, res) => edit && res.value?.value === 'Other',
    then: requiredFormObject(),
  }),

  documents: yup.array().of(questionDocumentValidationSchema),
  tools: yup.array().of(questionToolValidationSchema),
  defects: yup.mixed().when('isEditing', {
    is: (edit) => edit,
    then: yup.array().of(questionDefectValidationSchema),
  }),
});

const questionGroupValidationSchema = yup.object().shape({
  questions: yup.array().of(questionFormValidationSchema),
});

export const inspectionPlansFormValidationSchema = yup.object().shape({
  name: requiredFormString(),
  status: requiredFormString(),
  type: requiredFormObject(),
  aqlLevel: yup.object(),
  aqlMajor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlMinor: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  aqlFunctional: yup.object().when('aqlLevel', {
    is: (level) => level.value !== null,
    then: requiredFormObject(),
  }),
  criticalDefect: yup
    .object()
    .when('aqlLevel', {
      is: (level) => level.value !== null,
      then: inputShape(
        yupNumber
          .integer(ERRORS.integer)
          .min(0, ERRORS.nonNegative)
          .required(ERRORS.required),
      ),
    })
    .when('criticalDefectRule', {
      is: (rule) => rule.value === '%',
      then: inputShape(yupNumber.max(100, ERRORS.maxPercent)),
    }),
  questionGroups: yup.array().of(questionGroupValidationSchema),
});

const apiPathToFormPath = (path) =>
  path
    .replace(/(tools|documents\.[0-9]+).*/, '$1')
    .replace(/^(questionGroups.[0-9]+.questions.[0-9]+)$/, '$1.name')
    .replace(/(defects.[0-9]+).weight$/, '$1')
    .replace(/((?:lower|upper)Tolerance)Value/, '$1');

const inspectionPlansErrors = {
  ...API_ERROR_CODES,
  'array.unique': 'Questions must be unique within the company',
  'status.missingAqlOrSampleSize':
    'To publish an inspection plan without AQL, please edit all AQL questions to  have custom sample size.',
};

export const processInspectionPlansAPIError = makeAPIBodyErrorProcessor({
  errorCodes: inspectionPlansErrors,
  transformPath: apiPathToFormPath,
});

const inspectionPlansFormValidator = (state) =>
  validator(state, inspectionPlansFormValidationSchema);

export default inspectionPlansFormValidator;
