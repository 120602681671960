const STATE_1_SELECT = 'state1';
const STATE_2_UPLOAD = 'state2';
const STATE_3_ANALYZING = 'state3';

const states = {
  STATE_1_SELECT,
  STATE_2_UPLOAD,
  STATE_3_ANALYZING,
};

export default states;
