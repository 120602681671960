import { cloneDeep } from 'lodash';
import makeReducer from 'lib/makeReducer';
import INSPECTION_TYPE_TYPE from 'config/inspectionTypeType';

const MAX_CHARS = 50;

export const MAX_FIELD_CHARS = {
  name: MAX_CHARS,
  decisionName: MAX_CHARS,
};

const INITIAL_NEW_DECISION_ITEM = () => ({
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.decisionName,
  },
  action: {
    value: { value: '', label: '', forwardIndex: null },
    errors: [],
  },
  criteria: {
    value: { value: '', label: '' },
    errors: [],
  },
  notifyTo: { value: [], errors: [] },
  isDefault: false,
});

const AUTOMATIC_DECISIONS = {
  passDecisions: [
    {
      name: {
        value: 'Automatic inspection pass',
        errors: [],
        charsLeft: MAX_FIELD_CHARS.decisionName,
      },
      action: {
        value: { value: '', label: '', forwardIndex: null },
        errors: [],
      },
      criteria: {
        value: { value: '0', label: 'Inspection pass' },
        errors: [],
      },
      notifyTo: { value: [], errors: [] },
    },
  ],
  failDecisions: [
    {
      name: {
        value: 'Automatic inspection fail',
        errors: [],
        charsLeft: MAX_FIELD_CHARS.decisionName,
      },
      action: {
        value: { value: '', label: '', forwardIndex: null },
        errors: [],
      },
      criteria: {
        value: { value: '1', label: 'Inspection fail' },
        errors: [],
      },
      notifyTo: { value: [], errors: [] },
    },
  ],
};

const INITIAL_DECISION_ERRORS = {
  failDecisions: { errors: [] },
  passDecisions: { errors: [] },
};

const INITIAL_FORM_STATE = () => ({
  name: {
    value: '',
    errors: [],
    charsLeft: MAX_FIELD_CHARS.name,
  },
  status: {
    value: 'active',
    errors: [],
  },
  type: {
    value: INSPECTION_TYPE_TYPE.ASSET,
    errors: [],
  },
  isSurvey: { value: false, errors: [] },
  automaticPass: { value: false, errors: [] },
  automaticFail: { value: false, errors: [] },
  passDecisions: [INITIAL_NEW_DECISION_ITEM()],
  passDecisionsBkp: [],
  failDecisions: [INITIAL_NEW_DECISION_ITEM()],
  failDecisionsBkp: [],
  decisionGroups: [],
  forwardOption: { id: '', name: '' },
  decisionErrors: { ...INITIAL_DECISION_ERRORS },
  errors: [],
  loading: false,
  id: null,
  isDirty: false,
});

export const INSPECTION_TYPES_FORM_ACTIONS = {
  RESET_STATE: 'RESET_STATE',
  USER_TYPES_NAME: 'USER_TYPES_NAME',
  USER_SETS_STATUS: 'USER_SETS_STATUS',
  USER_SETS_TYPE: 'USER_SETS_TYPE',
  USER_SETS_IS_SURVEY: 'USER_SETS_IS_SURVEY',
  USER_SETS_AUTOMATIC_DECISION: 'USER_SETS_AUTOMATIC_DECISION',
  USER_ADDS_DECISION_ITEM: 'USER_ADDS_DECISION_ITEM',
  USER_ADDS_GROUP_DECISION_ITEM: 'USER_ADDS_GROUP_DECISION_ITEM',
  USER_REMOVES_DECISION_ITEM: 'USER_REMOVES_DECISION_ITEM',
  USER_REMOVES_GROUP_DECISION_ITEM: 'USER_REMOVES_GROUP_DECISION_ITEM',
  USER_ADDS_DECISION_GROUP: 'USER_ADDS_DECISION_GROUP',
  USER_REMOVES_DECISION_GROUP: 'USER_REMOVES_DECISION_GROUP',
  USER_TYPES_DECISION_ITEM_NAME: 'USER_TYPES_DECISION_ITEM_NAME',
  USER_TYPES_GROUP_DECISION_ITEM_NAME: 'USER_TYPES_GROUP_DECISION_ITEM_NAME',
  USER_SETS_DECISION_ITEM_CRITERIA: 'USER_SETS_DECISION_ITEM_CRITERIA',
  USER_SETS_GROUP_DECISION_ITEM_CRITERIA:
    'USER_SETS_GROUP_DECISION_ITEM_CRITERIA',
  USER_SETS_DECISION_ITEM_ACTION: 'USER_SETS_DECISION_ITEM_ACTION',
  USER_SETS_GROUP_DECISION_ITEM_ACTION: 'USER_SETS_GROUP_DECISION_ITEM_ACTION',
  USER_SETS_DECISION_ITEM_NOTIFY: 'USER_SETS_DECISION_ITEM_NOTIFY',
  USER_SETS_GROUP_DECISION_ITEM_NOTIFY: 'USER_SETS_GROUP_DECISION_ITEM_NOTIFY',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  INITIAL_STATE: 'INITIAL_STATE',
  APP_SETS_FORWARD_OPTION: 'APP_SETS_FORWARD_OPTION',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
};

const setValue = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.length > max) {
    return state;
  }

  return {
    ...state,
    [key]: {
      value: action.payload,
      errors: [],
      charsLeft: max && max - action.payload.length,
    },
    isDirty: true,
  };
};
const removeArrayValue = (state, action) => {
  const newValues = cloneDeep(state[action.payload.arrayKey]);
  newValues.splice(action.payload.index, 1);

  return {
    ...state,
    [action.payload.arrayKey]: newValues,
    isDirty: true,
  };
};
const removeTwoDimensionArrayValue = (state, action) => {
  const newValues = cloneDeep(state[action.payload.arrayKey]);
  const groupClone = cloneDeep(
    state[action.payload.arrayKey][action.payload.groupIndex],
  );
  groupClone.splice(action.payload.decisionIndex, 1);
  newValues[action.payload.groupIndex] = groupClone;

  return {
    ...state,
    [action.payload.arrayKey]: newValues,
    isDirty: true,
  };
};

const removeEmptyArrayValues = (key, state) => {
  const sanitizedValues = state[key]?.filter(
    (element) =>
      element.name?.value !== '' ||
      element.action?.value !== '' ||
      element.criteria?.value !== '' ||
      element.notifyTo?.value.length > 0,
  );
  return sanitizedValues;
};

const userTypesInArrayObject = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.value.length > max) {
    return state;
  }

  return {
    ...state,
    [action.payload.key]: [
      ...state[action.payload.key].map((element, index) => {
        if (index === action.payload.index) {
          return {
            ...element,
            [key]: {
              value: action.payload.value,
              errors: [],
              charsLeft: max && max - action.payload.value.length,
            },
          };
        }

        return {
          ...element,
          [key]: {
            value: element[key].value,
            errors: element[key].errors,
            charsLeft: element[key].charsLeft,
          },
        };
      }),
    ],
    isDirty: true,
  };
};

const userSetsInArrayObject = (key, state, action) => {
  return {
    ...state,
    [action.payload.arrayKey]: [
      ...state[action.payload.arrayKey].map((element, index) => {
        if (index === action.payload.index) {
          return {
            ...element,
            [key]: {
              value: action.payload[key],
              errors: [],
            },
          };
        }

        return {
          ...element,
          [key]: {
            value: element[key].value,
            errors: element[key].errors,
          },
        };
      }),
    ],
    isDirty: true,
  };
};

const userTypesInTwoDimensionArray = (key, state, action) => {
  const max = MAX_FIELD_CHARS[key];
  if (max && action.payload.value.length > max) {
    return state;
  }
  const arrayClone = cloneDeep(state[action.payload.arrayKey]);
  arrayClone[action.payload.groupIndex][action.payload.index][key] = {
    value: action.payload.value,
    errors: [],
    charsLeft: max && max - action.payload.value.length,
  };

  return {
    ...state,
    [action.payload.arrayKey]: arrayClone,
    isDirty: true,
  };
};

const userSetsTwoDimensionArray = (key, state, action) => {
  const arrayClone = cloneDeep(state[action.payload.arrayKey]);
  arrayClone[action.payload.groupIndex][action.payload.decisionIndex][key] = {
    value: action.payload[key],
    errors: [],
  };

  return {
    ...state,
    [action.payload.arrayKey]: arrayClone,
    isDirty: true,
  };
};

const INSPECTION_TYPES_FORM_REDUCER_CONFIG = {
  [INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_NAME]: (state, action) => {
    return setValue('name', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_STATUS]: (state, action) => {
    return {
      ...state,
      status: { value: action.payload, errors: [] },
      isDirty: true,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_TYPE]: (state, action) => {
    return {
      ...state,
      type: { value: action.payload, errors: [] },
      isDirty: true,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_IS_SURVEY]: (state, action) => {
    return {
      ...state,
      isSurvey: { value: action.payload, errors: [] },
      isDirty: true,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_AUTOMATIC_DECISION]: (
    state,
    action,
  ) => {
    const arrayClone = cloneDeep(state[action.payload.arrayKey]);
    if (action.payload.value) {
      return {
        ...state,
        decisionErrors: { ...INITIAL_DECISION_ERRORS },
        [action.payload.key]: { value: action.payload.value, errors: [] },
        [action.payload.arrayKey]: [
          {
            ...AUTOMATIC_DECISIONS[action.payload.arrayKey][0],
            action: state[`${action.payload.arrayKey}Bkp`][0]?.action?.value
              ?.value
              ? state[`${action.payload.arrayKey}Bkp`][0].action
              : AUTOMATIC_DECISIONS[action.payload.arrayKey][0].action,
            notifyTo: state[`${action.payload.arrayKey}Bkp`][0]?.notifyTo?.value
              ?.length
              ? state[`${action.payload.arrayKey}Bkp`][0].notifyTo
              : AUTOMATIC_DECISIONS[action.payload.arrayKey][0].notifyTo,
          },
        ],
        [`${action.payload.arrayKey}Bkp`]: arrayClone,
        isDirty: true,
      };
    }
    return {
      ...state,
      decisionErrors: { ...INITIAL_DECISION_ERRORS },
      [action.payload.key]: { value: action.payload.value, errors: [] },
      [action.payload.arrayKey]: cloneDeep(
        state[`${action.payload.arrayKey}Bkp`],
      ),
      [`${action.payload.arrayKey}Bkp`]: arrayClone,
      isDirty: true,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_DECISION_ITEM]: (state, action) => {
    return {
      ...state,
      [action.payload]: [...state[action.payload], INITIAL_NEW_DECISION_ITEM()],
      decisionErrors: { ...INITIAL_DECISION_ERRORS },
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_GROUP_DECISION_ITEM]: (
    state,
    action,
  ) => {
    const arrayClone = cloneDeep(state[action.payload.arrayKey]);
    arrayClone[action.payload.groupIndex] = [
      ...arrayClone[action.payload.groupIndex],
      INITIAL_NEW_DECISION_ITEM(),
    ];
    return {
      ...state,
      [action.payload.arrayKey]: arrayClone,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_DECISION_ITEM]: (
    state,
    action,
  ) => {
    const newValues = removeArrayValue(state, action);
    return {
      ...newValues,
      decisionErrors: { ...INITIAL_DECISION_ERRORS },
      isDirty: true,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_GROUP_DECISION_ITEM]: (
    state,
    action,
  ) => {
    return removeTwoDimensionArrayValue(state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_ADDS_DECISION_GROUP]: (state) => {
    return {
      ...state,
      decisionGroups: [...state.decisionGroups, [INITIAL_NEW_DECISION_ITEM()]],
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_REMOVES_DECISION_GROUP]: (
    state,
    action,
  ) => {
    return removeArrayValue(state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_DECISION_ITEM_NAME]: (
    state,
    action,
  ) => {
    return userTypesInArrayObject('name', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_TYPES_GROUP_DECISION_ITEM_NAME]: (
    state,
    action,
  ) => {
    return userTypesInTwoDimensionArray('name', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_CRITERIA]: (
    state,
    action,
  ) => {
    return userSetsInArrayObject('criteria', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_CRITERIA]: (
    state,
    action,
  ) => {
    return userSetsTwoDimensionArray('criteria', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_ACTION]: (
    state,
    action,
  ) => {
    return userSetsInArrayObject('action', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_ACTION]: (
    state,
    action,
  ) => {
    return userSetsTwoDimensionArray('action', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_DECISION_ITEM_NOTIFY]: (
    state,
    action,
  ) => {
    return userSetsInArrayObject('notifyTo', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SETS_GROUP_DECISION_ITEM_NOTIFY]: (
    state,
    action,
  ) => {
    return userSetsTwoDimensionArray('notifyTo', state, action);
  },
  [INSPECTION_TYPES_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    const failDecisions = removeEmptyArrayValues('failDecisions', state);
    const passDecisions = removeEmptyArrayValues('passDecisions', state);

    return {
      ...state,
      failDecisions,
      passDecisions,
      loading: true,
      isDirty: false,
    };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.APP_SETS_FORWARD_OPTION]: (state, action) => {
    return { ...state, forwardOption: action.payload };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return { ...state, loading: false };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [INSPECTION_TYPES_FORM_ACTIONS.INITIAL_STATE]: () => {
    return INITIAL_FORM_STATE();
  },
};

export const { reducer, initialState } = makeReducer(
  INSPECTION_TYPES_FORM_REDUCER_CONFIG,
  INITIAL_FORM_STATE(),
);
