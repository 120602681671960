import classNames from 'classnames';
import { QUESTION_TYPE } from 'config/questionOptions';
import ErrorBag from '../error-bag/ErrorBag';
import Input from '../input/Input';
import Select from '../select/Select';
import Switch from '../switch/Switch';
import testId from './fixtures/testid';

function AqlWidget({
  data,
  isInModal,
  onChange,
  questionOptions,
  planOptions,
}) {
  const aqlLevelOptions = questionOptions.aqlLevel || planOptions?.aqlLevel;
  const aqlMinorOptions =
    questionOptions.minorDefect || planOptions?.minorDefect;
  const aqlMajorOptions =
    questionOptions.majorDefect || planOptions?.majorDefect;
  const aqlFunctionalOptions =
    questionOptions.functionalDefect || planOptions?.functionalDefect;

  const isSwitchDisabled =
    data.type?.value?.value === QUESTION_TYPE.INFORMATIONAL_NON_AQL;

  return (
    <>
      <div
        className='aql-container align-right'
        data-testid={testId.sampleSizeGroup}
      >
        <div>
          <div className='form-label'>Sample size</div>
          <Switch
            onChange={() => {
              onChange('useAqlLevel', !data.useAqlLevel?.value);
            }}
            disabled={isSwitchDisabled}
            label='Sample size'
            data-testid={testId.useAqlLevelSwitch}
            checked={!data.useAqlLevel?.value}
            offLabel='AQL'
            onLabel='Custom'
          />
        </div>
        {data.useAqlLevel?.value ? (
          <>
            <div>
              <Select
                data-testid={testId.aqlLevelSelect}
                isInModal={isInModal}
                className='aql-select question-aql'
                label='AQL Level'
                options={aqlLevelOptions}
                value={data.aqlLevel.value}
                placeholder={questionOptions.defaultPlanAql}
                isClearable
                onChange={(value) => {
                  onChange('aqlLevel', value);
                }}
                errors={data.aqlLevel.errors}
              />
            </div>
          </>
        ) : (
          <div className='aql-custom'>
            <div className='form-label'>&nbsp;</div>
            <div>
              <Input
                data-testid={testId.customSampleSizeInput}
                className={!!data.sampleQty?.errors?.length && 'is-invalid'}
                value={data.sampleQty?.value}
                onChange={(ev) => {
                  onChange('sampleQty', ev.target.value);
                }}
              />
              <Switch
                data-testid={testId.customSampleRuleSwitch}
                offLabel='#'
                onLabel='%'
                checked={data.sampleRule?.value === '%'}
                onChange={() => {
                  onChange(
                    'sampleRule',
                    data.sampleRule?.value === '%' ? 'Exact' : '%',
                  );
                }}
              />
            </div>
            <ErrorBag errors={data.sampleQty?.errors} />
          </div>
        )}
      </div>
      {data.aqlLevel?.value !== null && data.useAqlLevel.value && (
        <div className='aql-level-select-container'>
          <div>
            <Select
              data-testid={testId.aqlMajorSelect}
              isInModal={isInModal}
              className='aql-major question-aql'
              label='Major'
              options={aqlMajorOptions}
              value={data.aqlMajor?.value}
              onChange={(value) => {
                onChange('aqlMajor', value);
              }}
              errors={data.aqlMajor?.errors}
            />
          </div>
          <div>
            <Select
              data-testid={testId.aqlMinorSelect}
              isInModal={isInModal}
              className='aql-minor question-aql'
              label='Minor'
              options={aqlMinorOptions}
              value={data.aqlMinor?.value}
              onChange={(value) => {
                onChange('aqlMinor', value);
              }}
              errors={data.aqlMinor?.errors}
            />
          </div>
          <div>
            <Select
              data-testid={testId.aqlFunctionalSelect}
              isInModal={isInModal}
              className='aql-functional question-aql'
              label='Functional'
              options={aqlFunctionalOptions}
              value={data.aqlFunctional?.value}
              onChange={(value) => {
                onChange('aqlFunctional', value);
              }}
              errors={data.aqlFunctional?.errors}
            />
          </div>
          <div>
            <div className='form-label'>Critical</div>
            <div className='critical-defect-container'>
              <Input
                data-testid={testId.aqlCriticalInput}
                value={data.criticalDefect?.value}
                className={classNames('critical-input question-aql', {
                  'is-invalid': data.criticalDefect?.errors.length,
                })}
                onChange={(ev) => {
                  onChange('criticalDefect', ev.target.value);
                }}
              />
              <Switch
                onChange={() => {
                  onChange(
                    'criticalDefectRule',
                    data.criticalDefectRule?.value === '%' ? 'Exact' : '%',
                  );
                }}
                className='critical-switch question-aql'
                data-testid={testId.aqlCriticalRule}
                checked={data.criticalDefectRule?.value === '%'}
                offLabel='#'
                onLabel='%'
              />
            </div>
            <ErrorBag errors={data.criticalDefect?.errors} />
          </div>
        </div>
      )}
    </>
  );
}

export default AqlWidget;
