import { AppContext } from 'App';
import { useContext } from 'react';

const useRoles = () => {
  const { appState } = useContext(AppContext);

  /** @type {string[]} */
  const currentUserRoles = (appState?.currentUser?.roles || []).map(
    (role) => role.id,
  );

  /**
   * Check if the current user has at least one role present in `allowed`
   * @param {string[]} allowed List of roles that if present will return true
   * @param {string[]} currentRoles Optional override for current user roles
   * @returns {boolean} true if
   */
  const isAllowed = (allowed = [], currentRoles = currentUserRoles) =>
    currentRoles.some((element) => allowed.includes(element));

  return { isAllowed, currentUserRoles };
};

export default useRoles;
