import { id } from './funcHelpers';
import * as yup from 'yup';
import _set from 'lodash.set';
import _get from 'lodash.get';
import { cloneDeep } from 'lodash';
import { ERROR_TEMPLATES } from 'config/errorCodes';

export class ValidatorError extends Error {
  constructor({ message, payload }) {
    super(message);

    this.name = 'ValidatorError';
    this.payload = payload;
  }
}

/**
 * Attach validation errors to the `input` object.
 *
 * This function assumes the `errors` array is a sibling to
 * the key under test. i.e.
 * ```
 *  input = {
 *    anyNestedLevel: {
 *      myInputField: {
 *        value: 'invalid', // <-- This value was tested
 *        errors: [] // <-- Errors are put here
 *        otherValue: 'irelevant'
 *      }
 *    }
 *  }
 * ```
 * If custom behaviour is desired, override this function in the validator.
 * @param {import('yup').ValidationError} e Yup validation error object
 * @param {object} input Object on which validation was performed, usually state
 * @returns input object with errors added
 */
export const processFormError = (e, input) => {
  const newInput = cloneDeep(input);
  return e.inner.reduce((acc, curr) => {
    const parentPath = curr.path.split('.').slice(0, -1).join('.');
    const field = curr.path.split('.').slice(-1);

    if (parentPath === '') {
      return {
        ...acc,
        [field]: curr.params.originalValue,
        errors: curr.errors,
      };
    }

    _set(acc, parentPath, {
      ..._get(acc, parentPath),
      [field]: curr.params.originalValue,
      errors: curr.errors,
    });
    return acc;
  }, newInput);
};

export const validator = (
  input,
  schema,
  processError = processFormError,
  processSuccess = id,
  options,
) =>
  schema
    .validate(input, { abortEarly: false, ...options })
    .then(processSuccess)
    .catch((e) => {
      if (e instanceof yup.ValidationError) {
        throw new ValidatorError({
          message: 'Validation error',
          payload: processError(e, input),
        });
      }
      throw e;
    });

export const inputShape = (value) => yup.object().shape({ value: value });

export const requiredFormString = (msg = ERROR_TEMPLATES.required) =>
  inputShape(yup.string().required(msg));

export const requiredFormObject = (msg = ERROR_TEMPLATES.required) =>
  inputShape(yup.object().nullable().required(msg));

export const yupNumber = yup
  .number()
  .transform((cv, ov) => (ov === '' ? undefined : cv))
  .typeError(ERROR_TEMPLATES.number);

export default validator;

export const qmTagsSchema = yup
  .array()
  .test(
    'comma',
    'Tags must not contain a comma',
    (tags) => !tags.some((obj) => obj.label.includes(',')),
  );

export const makeTranslatableSchema = (msg = ERROR_TEMPLATES.required) =>
  inputShape(
    yup
      .array()
      .test(
        'atLeastOneTranslation',
        msg,
        (translations) => !!translations.filter((t) => t.text !== '').length,
      ),
  );

export const uniqueArrayStrValuesSchema = () =>
  yup
    .array()
    .test(
      'is-array-unique-items',
      'Values must be unique',
      (testVal, testCtx) => {
        const errArray = testVal
          ?.map((vItem, vIndex) => {
            if (vItem?.value) {
              const isFound = testVal.some(
                (_vItem, _vIndex) =>
                  vIndex !== _vIndex && vItem?.value === _vItem?.value,
              );
              if (isFound) {
                return testCtx.createError({
                  path: `${testCtx.path}[${vIndex}].value`,
                  message: 'No duplicate entries allowed',
                  params: {
                    originalValue: vItem?.value,
                  },
                });
              }
            }
            return null;
          })
          ?.filter((vItem) => vItem);

        const err = errArray?.length ? new yup.ValidationError(errArray) : null;

        return err ?? true;
      },
    );
